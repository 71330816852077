import React, { useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import PrimaryButton from '../components/PrimaryButton';
import { Currency } from '../models';
import { AuthContext } from '../contexts/AuthContext';
import { fetchCurrencies } from '../utils/fetchData';
import EditRateDialog from '../components/rates/EditRateDialog';
import SearchRateDialog from '../components/rates/SearchRateDialog';
import { Rate } from '../API';
import { useSearchRates } from '../hooks/useSearchRates';

function RateManagement() {
  const columns: GridColDef[] = [
    { field: 'seq_id', headerName: 'レートコード', width: 190 },
    {
      field: 'source_currency_id',
      headerName: '換算前通貨',
      width: 190,
      renderCell: ({ row }) => {
        return currencies.find((currency) => currency.id == row?.['source_currency_id'])?.code_three_char || '';
      },
    },
    {
      field: 'converted_currency_id',
      headerName: '換算後通貨',
      width: 190,
      renderCell: ({ row }) => {
        return currencies.find((currency) => currency.id == row?.['converted_currency_id'])?.code_three_char || '';
      },
    },
    {
      field: 'from_date',
      headerName: '有効開始日',
      width: 190,
      valueFormatter: (params: any) => {
        return params.value?.replaceAll("-", "/");
      },
    },
    {
      field: 'to_date',
      headerName: '有効終了日',
      width: 190,
      valueFormatter: (params: any) => {
        return params.value?.replaceAll("-", "/");
      },
    },
    // MEMO: exchange_rate: Floatだからsortできないっぽいので画面上でソートできないようにする
    {
      field: 'exchange_rate',
      headerName: '換算レート',
      width: 190,
      sortable: false
    },
    {
      field: 'company_name',
      headerName: '会社名',
      width: 190,
    },
    {
      field: 'usage',
      headerName: '用途',
      width: 190,
    },
    {
      field: 'description',
      headerName: '説明',
      width: 190,
    },
    {
      field: 'exchange_rate_type',
      headerName: '換算レート type',
      width: 190,
    },
    {
      field: 'update_reason',
      headerName: '更新理由',
      width: 190,
    },
    {
      field: 'updated_by',
      headerName: '最終更新者',
      width: 190,
      valueGetter: (params) => {
        return params.row.updated_by?.name;
      },
    },
    {
      field: 'updatedAt',
      headerName: '最終更新日時',
      width: 190,
      valueFormatter: (params: any) => {
        return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
      },
    },
    {
      field: 'delete_flg',
      headerName: '削除済データ',
      width: 190,
      renderCell: (params: GridCellParams) => {
        return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
      },
    },
  ];
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [row, setRow] = useState<Rate | null>(null);
  const [isUploaded, setIsUploaded] = useState(false)
  const { user, roles } = useContext(AuthContext);
  const roleTypes = roles?.map((role) => role.role?.label);

  const { fetchRates, loading, total, pageSize, onChangePageSize, rates, onChangePage, page, onChangeSort, condition, expQueries } = useSearchRates()

  useEffect(() => {
    fetchRates({})
    fetchCurrencies(setCurrencies)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isUploaded) return
    fetchRates({})
    setIsUploaded(false)
    // eslint-disable-next-line
  }, [isUploaded])

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);


  if (!roleTypes) return <></>;
  console.log({ rates });
  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
          <ExportButton
            condition={expQueries}
            tableName="RATE"
            type='SEARCH'
          />
          <ImportButton tableName="RATE" setIsUploaded={setIsUploaded} />
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          rows={rates}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {row && user && (
        <EditRateDialog
          currencies={currencies}
          open={openEdit}
          row={row}
          setOpen={setOpenEdit}
          setRow={setRow}
        />
      )}
      <SearchRateDialog
        currencies={currencies}
        condition={condition}
        open={openSearch}
        setOpen={setOpenSearch}
        fetchRates={fetchRates}
      />
    </Box>
  );
}

export default RateManagement;
