export const SEARCH_TAG_BSP_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "product-history_operation-category",
    type: "select",
    placeholder: "操作種別を選択",
    optionsName: "operation_type",
  },
  {
    field: "bsp_id",
    label: "BSPマスターID",
    type: "text",
    placeholder: "BSPマスターIDを入力",
  },
  {
    field: "character_name",
    label: "キャラクター名",
    type: "text",
    placeholder: "キャラクター名を入力",
  },
  {
    field: "tag_name",
    label: "IPタグ（日本語）",
    type: "text",
    placeholder: "IPタグ（日本語）を入力",
  },
  {
    field: "tag_name_en",
    label: "IPタグ（英語）",
    type: "text",
    placeholder: "IPタグ（英語）を入力",
  },
  {
    field: "updated_user_name",
    label: "product_final-updater",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product_final-update-date" },
      { field: "to", label: "　" },
    ],
  },
  {
    field: "update_reason",
    label: "product_reason-for-update",
    type: "textarea",
    placeholder: "更新理由を入力",
  },
];

export const SEARCH_TAG_BSP_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  bsp_id: "",
  character_name: "",
  tag_name: "",
  tag_name_en: "",
  updated_user_name: "",
  updatedAt_from: null,
  updatedAt_to: null,
  update_reason: "",
};
