import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Box, Typography, FormHelperText, Chip } from '@mui/material';
import Select from '@mui/material/Select';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { Currency, Genre, Platform, ProductClass } from '../../models';

import { theme } from '../../assets/theme/options';
import { validationRules } from '../../types';
import createValidationRules from '../../hooks/createValidationRules';
import RequiredLabel from './RequiredLabel';
import { I18n } from 'aws-amplify/utils';

interface FormSelectChipProps {
  /** ラベル */
  label: string;
  /** 内容 */
  content: Genre[];
  /** インプット内容 */
  defaultValue?: any;
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** 選択中の genre */
  genres: genre[];
  /** setGenre */
  setGenres: Function;
  /** placeholder */
  placeholder?: string;
  /** ReactFookForm */
  control: any;
  errors?: any;
  /** バリデーションの内容 */
  validationRules?: validationRules[] | string[];
  /** 必須ラベルの有無 */
  required?: boolean;
  /** 空白オプションの有無 */
  isBlank?: boolean;
  /** UseFormSetValue */
  setValue?: any;
  /** 編集の時の初期値 */
}

interface genre {
  id: string;
  name: string;
}

function FormSelectChip(props: FormSelectChipProps) {
  useEffect(() => {
    if (props.defaultValue) {
      const defaultGenres: genre[] = [];
      props.defaultValue.map((id: string) => {
        const genre = props.content.find((_value: any) => _value.id === id);
        if (genre) {
          const data: any = {
            id: genre.id,
            name: genre.name,
          };
          props.setGenres((prev: genre[]) => [...prev, data]);
          defaultGenres.push(data);
        }
      });
      props.setValue ? props.setValue('genre_id', defaultGenres) : null;
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const addingValue = value.slice(-1)[0];
    const sameValue = value.filter((_value: genre) => _value.id === addingValue.id);
    if (sameValue.length > 1) {
      value.pop();
      const deleteIndex = value.findIndex((_value: genre) => _value.id === addingValue.id);
      value.splice(deleteIndex, 1);
    }
    props.setValue ? props.setValue('genre_id', value) : null;
    props.setGenres(() => value);
  };

  const getStyles = (input: any, array: genre[]) => {
    let colorTheme: any = { fontWeight: theme.typography.fontWeightRegular };
    if (array.some((value) => value.id === input.id)) {
      colorTheme['fontWeight'] = theme.typography.fontWeightBold;
      colorTheme['backgroundColor'] = theme.colors.selectChip;
    }
    return colorTheme;
  };

  return (
    <Box position={'relative'}>
      {props.required && <RequiredLabel />}
      <InputLabel sx={{ fontSize: '12px', marginBottom: '2px' }}>{I18n.get(props.label)}</InputLabel>
      <FormControl fullWidth size="small">
        <Controller
          name={props.field}
          control={props.control}
          defaultValue={props.defaultValue || ''}
          rules={props.validationRules ? createValidationRules(props.validationRules) : undefined}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              error={props.errors && !!props.errors[props.field]}
              disabled={props.disabled}
              displayEmpty
              multiple
              value={props.genres}
              onChange={handleChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.length > 0 ? (
                    selected.map((value: any) => <Chip key={value.id} label={value.name} />)
                  ) : (
                    <Typography color={theme.colors.placeholder}>{props.placeholder}</Typography>
                  )}
                </Box>
              )}
              sx={{ background: props.disabled ? theme.colors.disabled : '' }}
            >
              {props.content.map((option) => {
                return (
                  option.delete_flg != true && (
                    <MenuItem
                      key={option.name}
                      value={{ id: option.id, name: option.name } as any}
                      style={getStyles(option, props.genres)}
                    >
                      {option.name}
                    </MenuItem>
                  )
                );
              })}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  );
}

export default FormSelectChip;
