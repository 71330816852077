import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DataGridPro, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import NewTitleCodeDialog from '../components/titleCodes/NewTitleCodeDialog';
import SearchTitleCodeDialog from '../components/titleCodes/SearchTitleCodeDialog';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import EditTitleCodeDialog from '../components/titleCodes/EditTitleCodeDialog';
import {
  searchGenres,
  searchCurrencies,
  listDataSources,
  searchProductClasses,
  searchProductTypes,
  searchPlatforms,
  searchBusinessEntities
} from '../graphql/queries';
import { onUpdateGenre } from '../graphql/subscriptions';
import { AuthContext } from '../contexts/AuthContext';
import { sortByOrderIdAndCode } from '../utils/sortByOrderIdAndCode';
import { INITIAL_EXP_QUERIES_STATE } from '../consts/export';
import { filterColumnsForGeneralRole } from '../utils/filterColumns';
import { useSearchTitleCodes } from '../hooks/useSearchTitleCodes';
import { TitleCode, DataSource, Currency, Platform, ProductType, ProductClass, Genre, BusinessEntity } from '../models';
import { SearchConditionTitleCode } from '../types/form/titleCode';
import { SEARCH_TITLE_CODE_FIELDS_DEFAULT_VALUES } from '../consts/titleCode';
import { generateClient } from 'aws-amplify/api';
import {
  SearchableBusinessEntitySortInput,
  SearchableGenreSortInput,
  SearchablePlatformSortInput,
  SearchableProductClassSortInput,
  SearchableProductTypeSortInput
} from '../API';
const API = generateClient();

const tranceArrayText = (params: GridValueFormatterParams) => {
  if (params.value?.length > 0 && params.value[0]) {
    let arrayText = '';
    params.value.map((text: string) => {
      arrayText = `${arrayText}"${text}", `;
    });
    arrayText = arrayText.slice(0, -2);
    return `[${arrayText}]`;
  } else {
    return null;
  }
};

function TitleCodeList() {
  const columns: GridColDef[] = [
    {
      field: 'seq_id',
      headerName: 'タイトルコードID',
      width: 190
    },
    {
      field: 'tags',
      headerName: 'IPタグ',
      width: 210,
      valueGetter: (params) => {
        const latestTagParam = params.value.items.length
          ? params.value.items.sort((x, y) => new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime())[
              params.value.items.length - 1
            ]
          : undefined;
        return latestTagParam && !latestTagParam._deleted ? latestTagParam.tag.name_ja || '' : '';
      }
    },
    {
      field: 'title_code',
      headerName: 'タイトルコード',
      width: 210
    },
    {
      field: 'name_ja',
      headerName: 'タイトルコード名（日本語）',
      width: 210
    },
    {
      field: 'name_en',
      headerName: 'タイトルコード名（英語）',
      width: 210
    },
    {
      field: 'official_name_flg',
      headerName: 'タイトルコード名称 正式フラグ',
      width: 210,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    },
    {
      field: 'sap_title_code_name',
      headerName: 'SAPタイトルコード用名称',
      width: 210
    },
    {
      field: 'search_name',
      headerName: '検索用タイトルコード名称',
      width: 210
    },
    {
      field: 'project_cd',
      headerName: '関連プロジェクトコード',
      width: 210,
      valueFormatter: (params) => tranceArrayText(params)
    },
    {
      field: 'wbs',
      headerName: '関連WBS',
      width: 210,
      valueFormatter: (params) => tranceArrayText(params)
    },
    {
      field: 'bn_connect_title_ids',
      headerName: 'BN-ConnectタイトルID',
      width: 210,
      valueFormatter: (params) => tranceArrayText(params)
    },
    {
      field: 'ce_link_key_code',
      headerName: 'CE-LINKキーコード',
      width: 210
    },
    {
      field: 'ce_link_title_name_ja',
      headerName: 'CE-LINKキーコード名称（日本語）',
      width: 210
    },
    {
      field: 'ce_link_title_name_en',
      headerName: 'CE-LINKキーコード名称（英語）',
      width: 210
    },
    {
      field: 'genre_ids',
      headerName: 'ジャンル',
      width: 210,
      valueFormatter: (params) => {
        let genreNames: string[] = [];
        params.value?.forEach((v) => {
          const genre = genres.find((p) => p.id === v);
          if (genre) {
            genreNames.push(`"${genre.name}"`);
          }
        });
        return genreNames.length ? `[${genreNames}]` : null;
      }
    },
    {
      field: 'banasuke_title_id',
      headerName: 'バナスケタイトルID',
      width: 210
    },
    {
      field: 'banasuke_title_name_ja',
      headerName: 'バナスケタイトル名称（日本語）',
      width: 210
    },
    {
      field: 'bundle_source_title_code_ids',
      headerName: 'バンドル元タイトルコードID',
      width: 210,
      valueFormatter: (params) => tranceArrayText(params)
    },
    {
      field: 'porting_source_title_code_ids',
      headerName: '移植元タイトルコードID',
      width: 210,
      valueFormatter: (params) => tranceArrayText(params)
    },
    {
      field: 'platform_ids',
      headerName: 'PF（プラットフォーム）',
      width: 210,
      valueFormatter: (params) => {
        let platformNames: string[] = [];
        params.value?.forEach((v) => {
          const platform = platforms.find((p) => p.id === v);
          if (platform) {
            platformNames.push(`"${platform.name}"`);
          }
        });
        return platformNames.length ? `[${platformNames}]` : null;
      }
    },
    {
      field: 'valid_flg',
      headerName: '有効フラグ',
      width: 210,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    },
    {
      field: 'business_entity',
      headerName: '事業体',
      width: 210,
      valueGetter: (params) => {
        const currentBusinessEntity = businessEntity.find((v) => v.id === params.row.business_entity_id);
        return currentBusinessEntity?.name;
      }
    },
    {
      field: 'data_source_name',
      headerName: 'データソース名',
      width: 210,
      valueGetter: (params) => params.row.data_source?.name
    },
    {
      field: 'system_update_flg',
      headerName: '自動更新フラグ',
      width: 210,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    },
    {
      field: 'admin_check_flg',
      headerName: '管理者チェック済フラグ',
      width: 210,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    },
    {
      field: 'delete_flg',
      headerName: '削除済データ',
      width: 210,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    },
    {
      field: 'updated_by',
      headerName: '最終更新者',
      width: 210,
      valueGetter: (params) => params.row.updated_by?.name
    },
    {
      field: 'updatedAt',
      headerName: '最終更新日時',
      width: 210,
      valueFormatter: (params) => format(parseISO(params.value), 'yyyy/MM/dd HH:mm')
    }
  ];

  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
  const [row, setRow] = useState<TitleCode>();
  const [condition, setCondition] = useState<SearchConditionTitleCode>(SEARCH_TITLE_CODE_FIELDS_DEFAULT_VALUES);
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [productClasses, setProductClasses] = useState<ProductClass[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [genres, setGenres] = useState<Genre[]>([]);
  const [businessEntity, setBusinessEntity] = useState<BusinessEntity[]>([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [expQueries, setExpQueries] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >(INITIAL_EXP_QUERIES_STATE);
  const { user, roles } = useContext(AuthContext);
  const roleTypes = roles?.map((role) => role.role?.label);
  // 一般ユーザの時は削除済データのカラムを非表示にする
  const filteredColumns = filterColumnsForGeneralRole(roleTypes, columns, ['delete_flg']);

  const {
    searchTitleCodesFetch,
    onChangePageSize,
    onChangePage,
    onChangeSort,
    onChangeFilter,
    loading,
    total,
    pageSize,
    rows,
    page
  } = useSearchTitleCodes();

  const subscribeOnUpdateGenre = () => {
    const client = API.graphql({ query: onUpdateGenre });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const genre = data.onUpdateGenre;
          if (!genre.delete_flg) {
            genres.includes(genre.id) // 更新
              ? setGenres((genres) => genres.map((__genre) => (__genre.id === genre.id ? genre : __genre)))
              : setGenres((prev) => {
                  // 復元
                  const sorted = [...prev, genre].sort(
                    (a: any, b: any) => a.seq_id.replace(/[^0-9^\.]/g, '') - b.seq_id.replace(/[^0-9^\.]/g, '')
                  );
                  return sorted;
                });
          } else {
            // 削除
            setGenres((genres) => genres.filter((__genre) => __genre.id !== genre.id));
          }
        }
      });
      return () => subscription.unsubscribe();
    }
  };

  const fetchGenres = useCallback(async () => {
    try {
      const models: any = await API.graphql({
        query: searchGenres,
        variables: {
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' },
          limit: 500
        }
      });
      setGenres(models.data.searchGenres.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchDataSources = useCallback(async () => {
    try {
      const models: any = await API.graphql({ query: listDataSources });
      // memo: searchクエリがないのでフロントでseq_idを利用してsort
      const sorted = models.data.listDataSources.items.sort(
        (a: any, b: any) => a.seq_id.replace(/[^0-9^\.]/g, '') - b.seq_id.replace(/[^0-9^\.]/g, '')
      );
      setDataSources(models.data.listDataSources.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchCurrencies = useCallback(async () => {
    try {
      const models: any = await API.graphql({ query: searchCurrencies, variables: { limit: 500 } });
      const items = models?.data?.searchCurrencies?.items as Currency[];
      const result = sortByOrderIdAndCode<Currency>(items, 'code_three_char');
      setCurrencies(result);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchPlatforms = useCallback(async () => {
    try {
      const models: any = await API.graphql({
        query: searchPlatforms,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setPlatforms(models.data.searchPlatforms.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchProductClasses = useCallback(async () => {
    try {
      const models: any = await API.graphql({
        query: searchProductClasses,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setProductClasses(models.data.searchProductClasses.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchProductTypes = useCallback(async () => {
    try {
      const models: any = await API.graphql({
        query: searchProductTypes,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setProductTypes(models.data.searchProductTypes.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchBusinessEntity = useCallback(async () => {
    try {
      const models: any = await API.graphql({
        query: searchBusinessEntities,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setBusinessEntity(models.data.searchBusinessEntities.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    searchTitleCodesFetch({});
    fetchGenres();
    fetchDataSources();
    fetchCurrencies();
    fetchPlatforms();
    fetchProductClasses();
    fetchProductTypes();
    subscribeOnUpdateGenre();
    fetchBusinessEntity();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isUploaded) return;
    searchTitleCodesFetch({});
    setIsUploaded(false);
    // eslint-disable-next-line
  }, [isUploaded]);

  const handleOpenEdit = (params) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  if (!roleTypes) return <></>;

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, width: 900 }}>
          <PrimaryButton onClick={() => setOpenNew(true)}>新規作成</PrimaryButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: 800
            }}
          >
            <ExportButton condition={expQueries} tableName="TITLE_CODE" type="SEARCH" />
            <ImportButton tableName="TITLE_CODE" setIsUploaded={setIsUploaded} />
          </Box>
        </Box>
        <PrimaryButton onClick={() => setIsOpenSearchDialog(true)}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          rows={rows}
          columns={filteredColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={handleOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openNew && user && (
        <NewTitleCodeDialog
          dataSources={dataSources}
          genres={genres}
          platforms={platforms}
          businessEntity={businessEntity}
          setOpen={setOpenNew}
          condition={condition}
          setCondition={setCondition}
          onChangeFilter={onChangeFilter}
          setExpQueries={setExpQueries}
        />
      )}
      {openEdit && row && user && (
        <EditTitleCodeDialog
          currencies={currencies}
          dataSources={dataSources}
          genres={genres}
          isAdmin={roleTypes.includes('ADMIN')}
          open={openEdit}
          platforms={platforms}
          businessEntity={businessEntity}
          productClasses={productClasses}
          productTypes={productTypes}
          selectedRow={row}
          setOpen={setOpenEdit}
          updateUserId={user.id}
          condition={condition}
          setCondition={setCondition}
          onChangeFilter={onChangeFilter}
          setExpQueries={setExpQueries}
        />
      )}
      {isOpenSearchDialog && (
        <SearchTitleCodeDialog
          condition={condition}
          dataSources={dataSources}
          genres={genres}
          businessEntity={businessEntity}
          platforms={platforms}
          isAdmin={roleTypes.includes('ADMIN')}
          isDialogOpen={isOpenSearchDialog}
          setCondition={setCondition}
          onChangeFilter={onChangeFilter}
          setExpQueries={setExpQueries}
          closeFunc={() => setIsOpenSearchDialog(false)}
        />
      )}
    </Box>
  );
}

export default TitleCodeList;
