import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import git_version from '../../git-version';
import { useLocation } from 'react-router-dom';


export default function Footer() {
  const [version, setVersion] = useState('');
  useEffect(() => {
    (async () => {
      setVersion(git_version);
    })();
  });
  const location = useLocation();

  return (
    location.pathname == "/" ?
      <Box sx={{ color: "rgba(0, 0, 0, 0.6)", px: 2, py: 1, fontSize: 10 }}>
        <footer>{`Ver. ${version}`}</footer>
      </Box>
      :
      <Box sx={{ color: "rgba(0, 0, 0, 0.6)", px: 2, textAlign: "right", fontSize: 10 }}>
        <footer>{`Ver. ${version}`}</footer>
      </Box>
  );
}
