import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import FormAutocomplete from '../forms/FormAutocomplete';
import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { Product, User, Currency, DataSource, Platform, ProductClass, ProductType, Title } from '../../models';
import { EDIT_PRODUCT_PRODUCT_FIELDS, EDIT_PRODUCT_ADMIN_FIELDS } from '../../consts/product';
import { BANASUKE_SEQ_ID, MULCHI_SEQ_ID } from '../../consts/common';
import { ProductFields } from '../../types';
import { classificationValidatePrd } from '../../hooks/classification';
import { getProduct } from '../../graphql/queries';
import { updateProduct } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { fetchProducts, sleep } from '../../utils/fetchData';
import { ProductSearch } from '../../types/form/product';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  currencies: Currency[];
  dataSources: DataSource[];
  isAdmin: boolean | undefined;
  open: boolean;
  platforms: Platform[];
  productClasses: ProductClass[];
  productTypes: ProductType[];
  row: Product;
  setOpen: Function;
  setRow: Function;
  titles: Title[];
  user: User;
  condition: ProductSearch;
  setCondition: Function;
  onChangeFilter: Function;
  setExpQueries: Function;
};

type Record = {
  seq_id?: string;
  title?: Title;
  product_key?: string;
  name?: string;
  common_name?: string;
  product_type_id?: string;
  product_class_id?: string;
  platform_id?: string;
  market?: string;
  data_source_id?: string;
  base_price?: string;
  currency_id?: string;
  release_status?: string;
  info_release_date?: string;
  preorder_start_date?: string;
  release_date?: string;
  service_start_date?: string;
  service_end_date?: string;
  sku_number_apple?: string;
  app_id?: string;
  package_name_google?: string;
  bank_app_id?: string;
  updated_reason?: string;
  admin_check_flg?: boolean;
  updatedAt?: string;
};

function EditProductDialog(props: Props) {
  const {
    currencies,
    dataSources,
    isAdmin,
    open,
    platforms,
    productClasses,
    productTypes,
    row,
    setOpen,
    setRow,
    titles,
    user,
    condition,
    setCondition,
    onChangeFilter,
    setExpQueries,
  } = props;
  const [confirm, setConfirm] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isRel, setIsRel] = useState(false);
  const { checkAndRestore } = useRestore();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const fields = isAdmin ? EDIT_PRODUCT_ADMIN_FIELDS : EDIT_PRODUCT_PRODUCT_FIELDS;

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<Record> = async (data) => {
    console.log({ data });

    setRecord(data);
    setEditConfirm(true);
  };

  // 確認編集画面の「登録」の処理
  const onSubmit = () => {
    setCanSubmit(true);
  };

  const onUpdate = async () => {
    const isMultiTitle = record.title?.data_source.seq_id === MULCHI_SEQ_ID;
    try {
      const model: any = await API.graphql({ query: getProduct, variables: { id: row.id } });
      const product = model.data.getProduct;
      const filteredRecord = removeTitleInRecord(record);
      const formData = {
        ...filteredRecord,
        id: row.id,
        title_id: record.title?.id,
        base_price: parseFloat(record.base_price || ''),
        _version: product._version,
        updated_user_id: user.id,
        system_update_flg: false,
        data_source_id: isMultiTitle ? row.data_source_id : record.title?.data_source_id,
      };
      await API.graphql({ query: updateProduct, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'プロダクトの編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchProducts(condition, setCondition, onChangeFilter, setExpQueries);
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'プロダクトの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const removeTitleInRecord = (record: Record) => {
    const { title, seq_id, updatedAt, ...res } = record;
    return res;
  };

  const onDelete: SubmitHandler<Record> = async (data) => {
    try {
      const model: any = await API.graphql({ query: getProduct, variables: { id: row.id } });
      const product = model.data.getProduct;
      const record = {
        id: row.id,
        updated_reason: data.updated_reason,
        _version: product._version,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
        updated_user_id: user.id,
        system_update_flg: false,
      };
      await API.graphql({ query: updateProduct, variables: { input: record } });
      closeConfirm();
      onClose();
      addNotification({
        type: 'success',
        message: 'プロダクトの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchProducts(condition, setCondition, onChangeFilter, setExpQueries);
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'プロダクトの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const checkRelation = () => {
    // banasuke連携を確認
    const isRelation = row.data_source?.seq_id === BANASUKE_SEQ_ID;
    setIsRel(isRelation);

    if (isRelation) {
      setAlertMsg(`このプロダクトはバナスケ連携${'\n'}されているため削除できません`);
    } else {
      setAlertMsg(`このプロダクトを削除しても${'\n'}よろしいでしょうか？`);
    }
    setConfirm(true);
  };

  const restoreConfirm = () => {
    setAlertMsg(`このプロダクトを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('product', row);
      onClose();
      addNotification({
        type: 'success',
        message: 'プロダクトの復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchProducts(condition, setCondition, onChangeFilter, setExpQueries);
    } catch (e: any) {
      const message =
        e.message === '__TITLE_DELETE__'
          ? '親タイトルが削除済みの為、復元できませんでした'
          : 'プロダクトの復元に失敗しました';
      addNotification({
        type: 'error',
        message,
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const selectOptions = (name: string | undefined) => {
    switch (name) {
      case 'productType':
        return productTypes;
      case 'productClass':
        return productClasses;
      case 'platform':
        return platforms;
      case 'dataSource':
        return dataSources;
      case 'currency':
        return currencies;
      default:
        return [];
    }
  };

  const autoCompOptions = (name: string | undefined) => {
    switch (name) {
      case 'title':
        return titles;
      default:
        return [];
    }
  };

  type Field = (typeof fields)[0];
  const isDisable = (__row: Field) => {
    if (row.delete_flg) return true;
    if (__row?.disabledDataSources?.includes(row.data_source?.seq_id || '')) return true;
    if (row.data_source.seq_id === BANASUKE_SEQ_ID && row.title.data_source.seq_id === MULCHI_SEQ_ID) return true;
    return false;
  };

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="プロダクトの編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!row.delete_flg && handleSubmit(onPreSubmit)}
      onConfirm={row.delete_flg ? restoreConfirm : checkRelation}
      isRestore={row.delete_flg}
      height="inherit"
      isFullScreen
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 335 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 'inherit',
        }}
      >
        {fields.map((__row, idx) => (
          <React.Fragment key={idx}>
            {(__row.type === 'text' || __row.type === 'textarea') && (
              <FormTextField
                label={__row.label}
                placeholder={
                  //　disabledDataSourcesの条件の時にはplaceholderをカラ表示にする
                  !__row.disabledDataSources.includes(row.data_source?.seq_id || '') ? __row.placeholder : ''
                }
                field={__row.field}
                control={control}
                disabled={isDisable(__row)}
                errors={errors}
                validationRules={classificationValidatePrd(__row.field as ProductFields)}
                value={
                  row[
                    __row.field as keyof Pick<
                      Product,
                      | 'seq_id'
                      | 'product_key'
                      | 'name'
                      | 'common_name'
                      | 'market'
                      | 'base_price'
                      | 'release_status'
                      | 'sku_number_apple'
                      | 'app_id'
                      | 'package_name_google'
                      | 'bank_app_id'
                      | 'updated_user_id'
                      | 'updated_reason'
                    >
                  ]
                }
                required={
                  row.delete_flg
                    ? false
                    : (__row.requiredDataSources as string[]).includes(row.data_source?.seq_id || '')
                }
                isTextArea={__row.type === 'textarea'}
              />
            )}
            {(__row.type === 'datetime' || __row.type === 'date') && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={
                  row[
                    __row.field as keyof Pick<
                      Product,
                      | 'info_release_date'
                      | 'preorder_start_date'
                      | 'release_date'
                      | 'service_start_date'
                      | 'service_end_date'
                      | 'support_end_date'
                      | 'updatedAt'
                    >
                  ]
                }
                type={__row.type}
                disabled={isDisable(__row)}
                field={__row.field}
                control={control}
                placeholder={__row.placeholder}
                errors={errors}
                errorMessage={'日付形式が無効です'}
              />
            )}
            {__row.type === 'select' && (
              <FormSelectBox
                label={__row.label}
                content={selectOptions(__row.optionsName)}
                disabled={isDisable(__row)}
                defaultValue={
                  row[
                    __row.field as keyof Pick<
                      Product,
                      'product_type_id' | 'product_class_id' | 'platform_id' | 'data_source_id' | 'currency_id'
                    >
                  ]
                }
                field={__row.field}
                control={control}
                selectKey={__row.field === 'currency_id' ? 'code_three_char' : 'name'}
                placeholder={__row.placeholder}
                required={
                  row.delete_flg
                    ? false
                    : (__row.requiredDataSources as string[]).includes(row.data_source?.seq_id || '')
                }
                errors={errors}
                validationRules={classificationValidatePrd(__row.field as ProductFields)}
              />
            )}
            {__row.type === 'autoComplete' && (
              <FormAutocomplete
                label={__row.label}
                placeholder={__row.placeholder}
                field={__row.field}
                control={control}
                disabled={isDisable(__row)}
                errors={errors}
                validationRules={classificationValidatePrd(__row.field as ProductFields)}
                defaultValue={row[__row.field as keyof Pick<Product, 'title'>]}
                required={
                  row.delete_flg
                    ? false
                    : (__row.requiredDataSources as string[]).includes(row.data_source?.seq_id || '')
                }
                options={autoCompOptions(__row.optionsName)}
              />
            )}
            {__row.type === 'checkbox' && (
              <FormCheckBox
                field={__row.field}
                label={__row.label}
                control={control}
                defaultValue={row[__row.field as keyof Pick<Product, 'admin_check_flg'>] || false}
                disabled={isDisable(__row)}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <ConfirmDialog
        message={alertMsg}
        open={confirm}
        onCancel={isRel ? undefined : closeConfirm}
        onContinue={isRel ? closeConfirm : row.delete_flg ? onRestore : handleSubmit(onDelete)}
        type={isRel ? 'error' : 'warning'}
        updateReason={
          row.delete_flg ? (
            <></>
          ) : (
            <Box px={3} mb={1}>
              <FormTextField
                label="更新理由"
                placeholder="更新理由を入力"
                value={row.updated_reason?.toString() || ''}
                field="updated_reason"
                control={control}
                errors={errors}
                validationRules={classificationValidatePrd('updated_reason')}
              />
            </Box>
          )
        }
      />
      <EditConfirmDialog
        currencies={currencies}
        dataSources={dataSources}
        fields={fields}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        platforms={platforms}
        productClasses={productClasses}
        productTypes={productTypes}
        record={record}
        schemaName="プロダクト"
        beforeChangeValueData={row}
      />
    </FormDialog>
  );
}

export default EditProductDialog;
