import React, { useRef, useContext } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import FormTextField from '../forms/FormTextField';
import FormDialog from '../FormDialog';
import { createPlatform } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { CREATE_PLATFORM_FIELDS, VALIDATE_DUPLICATE_PLATFORM_FIELDS } from '../../consts/platform';
import { platformFields } from '../../types';
import { classificationValidatePlatform } from '../../hooks/classification';
import { User } from '../../models';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { sleep } from '../../utils/fetchData';
import { generateClient } from 'aws-amplify/api';
import { CreatePlatformInput } from '../../API';
const API = generateClient();

type Props = {
  open: boolean;
  showDeleted: boolean;
  setOpen: Function;
  fetchPlatforms: Function;
  user: User;
};

type Record = {
  name?: string;
  banasuke_name?: string;
};

function NewPlatformDialog(props: Props) {
  const { open, setOpen, user, fetchPlatforms, showDeleted } = props;
  const onUpdating = useRef(false); // 連打防止用フラグ
  const getNextId = useAutoIncrement('PFM');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    // 連打回避
    if (onUpdating.current) return;
    onUpdating.current = true;
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('PLATFORM', VALIDATE_DUPLICATE_PLATFORM_FIELDS, data, clearErrors, setError);
    // 重複なければ登録
    results.every((result) => !result) && onCreate(data);
    // 連打回避解除
    if (onUpdating.current) {
      setTimeout(() => {
        onUpdating.current = false;
      }, 2000);
    }
  };

  const onCreate = async (record: Record) => {
    try {
      const seq_id = await getNextId();
      const formData = { ...record, seq_id, delete_flg: false, updated_user_id: user.id } as CreatePlatformInput;
      await API.graphql({ query: createPlatform, variables: { input: formData } });
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'プラットフォームの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchPlatforms({ isShowDeleteFlg: showDeleted });
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'の削除に失敗しました',
      });
      console.log(e);
    }
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="プラットフォームマスタの新規登録"
      doText="作成"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onSubmit)}
      height={350}
      width="xs"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 300 },
        }}
        width={340}
      >
        {CREATE_PLATFORM_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidatePlatform(row.field as platformFields)}
                required={row.required}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormDialog>
  );
}

export default NewPlatformDialog;
