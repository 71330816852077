import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import EditUserDialog from '../components/users/EditUserDialog';
import { Role } from '../models';
import { listRoles } from '../graphql/queries';
import { useSearchUsers } from '../hooks/useSearchUsers';
import { User } from '../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'id',
    width: 223,
  },
  {
    field: 'email',
    headerName: 'メールアドレス',
    width: 223,
  },
  {
    field: 'hasUserRolesIP',
    headerName: '一般ユーザー（IPマスタ）',
    width: 223,
    renderCell: (params: GridCellParams) => {
      return params.row.roles.items.some((item: any) => item.role.label === 'IP' && !item.delete_flg) ? (
        <CheckBoxIcon />
      ) : (
        <CheckBoxOutlineBlankIcon />
      );
    },
    sortable: false,
  },
  {
    field: 'hasUserRolesPRODUCT',
    headerName: '一般ユーザー（商品マスタ）',
    width: 223,
    renderCell: (params: GridCellParams) => {
      return params.row.roles.items.some((item: any) => item.role.label === 'PRODUCT' && !item.delete_flg) ? (
        <CheckBoxIcon />
      ) : (
        <CheckBoxOutlineBlankIcon />
      );
    },
    sortable: false,
  },
  {
    field: 'hasUserRolesCOMPANY',
    headerName: '一般ユーザー（グループ会社マスタ）',
    width: 250,
    renderCell: (params: GridCellParams) => {
      return params.row.roles.items.some((item: any) => item.role.label === 'COMPANY' && !item.delete_flg) ? (
        <CheckBoxIcon />
      ) : (
        <CheckBoxOutlineBlankIcon />
      );
    },
    sortable: false,
  },
  {
    field: 'hasUserRolesADMIN',
    headerName: '管理者ユーザー',
    width: 223,
    renderCell: (params: GridCellParams) => {
      return params.row.roles.items.some((item: any) => item.role.label === 'ADMIN' && !item.delete_flg) ? (
        <CheckBoxIcon />
      ) : (
        <CheckBoxOutlineBlankIcon />
      );
    },
    sortable: false,
  },
  {
    field: 'updated_by',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    },
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 250,
    valueFormatter: (params: any) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function UserManagement() {
  const [roles, setRoles] = useState<Role[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [openEdit, setOpenEdit] = useState(false);

  const { fetchUsers, loading, total, pageSize, onChangePageSize, users, onChangePage, page, onChangeSort } =
    useSearchUsers();

  useEffect(() => {
    fetchUsers({});
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  const fetchRoles = async () => {
    try {
      const models: any = await API.graphql({ query: listRoles });
      setRoles(models.data.listRoles.items);
    } catch (e) {
      console.error(e);
    }
  };

  const doOpenEdit = (params: any) => {
    setUser(params.row);
    setOpenEdit(true);
  };

  const onCloseEdit = useCallback(() => {
    setUser(null);
    setOpenEdit(false);
  }, []);

  console.log({ users });
  return (
    <Box sx={{ p: 1 }}>
      <div
        style={{
          height: 'calc(100vh - 170px)',
          width: '100%',
          marginTop: '75px',
        }}
      >
        <DataGridPro
          rows={users}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openEdit && user && (
        <EditUserDialog
          currentUser={user}
          currentUserRoles={(user.roles as any).items}
          onClose={onCloseEdit}
          open={openEdit}
          roles={roles}
          fetchUsers={fetchUsers}
        />
      )}
    </Box>
  );
}

export default UserManagement;
