import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { EDIT_TAG_FIELDS, VALIDATE_DUPLICATE_TAG_FIELDS } from '../../consts/tag';
import { tagFields } from '../../types';
import { classificationValidateTag } from '../../hooks/classification';
import { useRestore } from '../../hooks/useRestore';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { NotificationContext } from '../../contexts/NotificationContext';
import { sleep } from '../../utils/fetchData';
import { updateTagWithoutRelations } from '../../graphql/custom-mutations';

import type { Tag } from '../../API';
import type { User } from '../../models';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  row: Tag;
  setOpen: Function;
  setRow: Function;
  user: User;
  fetchTags: Function;
};

type Record = {
  name_ja?: string;
  name_en?: string;
};

function EditTagDialog(props: Props) {
  const { open, row, setOpen, setRow, user, fetchTags } = props;
  const [confirm, setConfirm] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isRel, setIsRel] = useState(false);
  const { checkAndRestore } = useRestore();
  const { addNotification } = useContext(NotificationContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<any> = async (data) => {
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('TAG', VALIDATE_DUPLICATE_TAG_FIELDS, data, clearErrors, setError, row.id);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
  };

  // 確認編集画面の「登録」の処理
  const onSubmit = () => {
    setCanSubmit(true);
  };

  const onUpdate = async () => {
    try {
      const formData = {
        id: row.id,
        name_ja: record.name_ja,
        name_en: record.name_en,
        _version: row._version,
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateTagWithoutRelations, variables: { input: formData } });
      reset();
      setEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'IPタグの編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTags({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'IPタグの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete = async () => {
    try {
      const record = {
        id: row.id,
        _version: row._version,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateTagWithoutRelations, variables: { input: record } });
      closeConfirm();
      onClose();
      addNotification({
        type: 'success',
        message: 'IPタグの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTags({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'IPタグの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const checkTagTitleRelation = (row: Tag) => {
    const isRelation = (row.titles as any).items.some((item: any) => !item._deleted);
    return isRelation;
  };

  const checkTagBspRelation = (row: Tag) => {
    const isRelation = (row.bsps as any).items.some((item: any) => !item._deleted);
    return isRelation;
  };

  const checkRelation = () => {
    const isRelationTitle = checkTagTitleRelation(row);
    const isRelationBsp = checkTagBspRelation(row);
    const isRelation = isRelationTitle || isRelationBsp;
    setIsRel(isRelation);

    const relationText =
      isRelationTitle && isRelationBsp
        ? 'タイトル・BSPマスター'
        : isRelationTitle
        ? 'タイトル'
        : isRelationBsp
        ? 'BSPマスター'
        : '';

    if (isRelation) {
      setAlertMsg(`このタグは${relationText}と紐付け${'\n'}されているため削除できません`);
    } else {
      setAlertMsg(`このタグを削除しても${'\n'}よろしいでしょうか？`);
    }
    setConfirm(true);
  };

  const restoreConfirm = () => {
    setAlertMsg(`このタグを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('tag', row);
      onClose();
      addNotification({
        type: 'success',
        message: 'IPタグの復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTags({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'IPタグの復元に失敗しました',
      });
      console.log(e);
    }
  };

  const doContinue = () => {
    const isRelation = checkTagTitleRelation(row) || checkTagBspRelation(row);
    if (isRelation) {
      closeConfirm();
    } else {
      row.delete_flg ? onRestore() : onDelete();
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="IPタグの編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!row.delete_flg && handleSubmit(onPreSubmit)}
      onConfirm={row.delete_flg ? restoreConfirm : checkRelation}
      height={450}
      width="xs"
      isRestore={row.delete_flg}
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 280 },
        }}
        width={340}
      >
        {EDIT_TAG_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {__row.type === 'text' && (
              <FormTextField
                label={__row.label}
                placeholder={__row.placeholder}
                field={__row.field}
                control={control}
                disabled={row.delete_flg || __row.disabled}
                errors={errors}
                validationRules={classificationValidateTag(__row.field as tagFields)}
                value={row[__row.field as keyof Pick<Tag, 'seq_id' | 'name_ja' | 'name_en' | 'updated_user_id'>]}
                required={row.delete_flg ? false : __row.required}
              />
            )}
            {__row.type === 'datetime' && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<Tag, 'updatedAt'>]}
                type={__row.type}
                disabled={row.delete_flg || __row.disabled}
                field={__row.field}
                control={control}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <ConfirmDialog
        message={alertMsg}
        open={confirm}
        onCancel={isRel ? undefined : closeConfirm}
        onContinue={doContinue}
        type={isRel ? 'error' : 'warning'}
      />
      <EditConfirmDialog
        fields={EDIT_TAG_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="IPタグ"
        beforeChangeValueData={row}
      />
    </FormDialog>
  );
}

export default EditTagDialog;
