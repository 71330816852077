export const CREATE_BNAM_FIELDS = [
  {
    field: "ip_name",
    label: "IP名称",
    type: "text",
    placeholder: "IP名称を入力",
    required: true,
  },
  {
    field: "update_reason",
    label: "登録更新理由",
    type: "text",
    placeholder: "登録更新理由を入力",
  },
];

export const EDIT_BNAM_FIELDS = [
  {
    field: "seq_id",
    label: "BNAMマスターID",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "ip_name",
    label: "IP名称",
    type: "text",
    placeholder: "IP名称を入力",
    required: true,
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "最終更新日時",
    type: "datetime",
    placeholder: "",
    disabled: true,
  },
  {
    field: 'admin_check_flg',
    label: '管理者チェックフラグ',
    type: 'checkbox',
    column: 3
  },
];

export const SEARCH_BNAM_FIELDS = [
  {
    field: "seq_id",
    label: "BNAMマスターID",
    type: "text",
    placeholder: "BNAMマスターID",
  },
  {
    field: "ip_name",
    label: "IP名称",
    type: "text",
    placeholder: "IP名称を入力",
    required: false,
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'title_final-update-date' },
      { field: 'to', label: 'title_final-update-date' },
    ],
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
  {
    field: "delete_flg",
    label: "削除済データ",
    type: "select",
    optionsName: "delete_flg",
    isBlank: false,
  },
];

export const SEARCH_BNAM_ADMIN_FIELDS = [
  ...SEARCH_BNAM_FIELDS,
  {
    field: "admin_check_flg",
    label: "管理者チェックフラグ",
    type: "select",
    optionsName: "admin_check_flg",
    isBlank: false,
  },
]

export const SEARCH_BNAM_FIELDS_DEFAULT_VALUES = {
  seq_id: "",
  ip_name: "",
  update_reason: "",
  delete_flg: false,
  admin_check_flg: true,
  updatedAt_from: null,
  updatedAt_to: null,
  updated_user_id: "",
};

export const VALIDATE_DUPLICATE_BNAM_FIELDS = [];
