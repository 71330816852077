import React, { useContext, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import FormTextField from '../forms/FormTextField';
import FormDialog from '../FormDialog';
import { createBnml } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { BnmlFields } from '../../types';
import { classificationValidateBnml } from '../../hooks/classification';
import { Bnml, User } from '../../models';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { sleep } from '../../utils/fetchData';
import { CREATE_BNML_FIELDS, VALIDATE_DUPLICATE_BNML_FIELDS } from '../../consts/bnml';
import EditConfirmDialog from '../EditConfirmDialog';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  setOpen: Function;
  user: User;
  fetchBnmls: Function;
};

type Record = Partial<
  Pick<Bnml, 'mlics_commodity_name' | 'mlics_plan_cd_4char' | 'mlics_plan_cd_7char' | 'update_reason'>
>;

function NewBnmlDialog(props: Props) {
  const { open, setOpen, user, fetchBnmls } = props;
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const onUpdating = useRef(false); // 連打防止用フラグ
  const getNextId = useAutoIncrement('BNM');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onPreSubmit: SubmitHandler<any> = async (data) => {
    // 連打回避
    if (onUpdating.current) return;
    onUpdating.current = true;
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('BNML', VALIDATE_DUPLICATE_BNML_FIELDS, data, clearErrors, setError);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
    // 連打回避解除
    if (onUpdating.current) {
      setTimeout(() => {
        onUpdating.current = false;
      }, 2000);
    }
  };

  const onCreate = async () => {
    try {
      const seq_id = await getNextId();
      const formData = { ...record, seq_id, delete_flg: false, admin_check_flg: false, updated_user_id: user.id };
      if (!formData.mlics_plan_cd_4char) delete formData.mlics_plan_cd_4char;
      if (!formData.mlics_plan_cd_7char) delete formData.mlics_plan_cd_7char;
      await API.graphql({ query: createBnml, variables: { input: formData } });
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'BNMLの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchBnmls({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BNMLの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const onSubmit = () => {
    onCreate();
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BNMLマスターの新規登録"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      height={350}
      width="xs"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 280 },
        }}
        width={340}
      >
        {CREATE_BNML_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateBnml(row.field as BnmlFields)}
                required={row.required}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <EditConfirmDialog
        fields={CREATE_BNML_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="BNMLマスター"
      />
    </FormDialog>
  );
}

export default NewBnmlDialog;
