import { useState } from 'react';
import { searchCsvJobs } from '../graphql/queries';
import usePageSize from './usePageSize';
import { CsvJob } from '../models';
import { generateClient } from 'aws-amplify/api';
import { SearchableCsvJobSortInput } from '../API';
const API = generateClient();

const defaultSort = [{ direction: 'desc', field: 'createdAt' }];

export const useSearchTagBnfCsvImports = () => {
  const [csvJobs, setCsvJobs] = useState<CsvJob[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();

  const fetchCsvJobs = async ({
    nextToken,
    newLimit,
    newSort
  }: {
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const models: any = await API.graphql({
        query: searchCsvJobs,
        variables: {
          filter: { type: { eq: 'IMPORT' }, table_name: { eq: 'TAGBNF' } },
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });

      setTotal(models.data.searchCsvJobs.total);
      if (newLimit || newSort) {
        setNextTokens([models.data.searchCsvJobs.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchCsvJobs.nextToken])));
      }
      setCsvJobs([...models.data.searchCsvJobs.items]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchCsvJobs({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchCsvJobs({ newSort: defaultSort });
    } else {
      const newSort = {
        direction: sortModel[0].sort,
        field: sortModel[0].field === 'updated_by' ? 'updated_user_id' : sortModel[0].field
      };
      setSort({ ...newSort });
      fetchCsvJobs({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchCsvJobs({ nextToken });
  };

  return {
    fetchCsvJobs,
    loading,
    total,
    pageSize,
    onChangePageSize,
    csvJobs,
    setCsvJobs,
    onChangePage,
    page,
    onChangeSort
  };
};
