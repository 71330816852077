/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSequence = /* GraphQL */ `query GetSequence($id: ID!) {
  getSequence(id: $id) {
    id
    table_label
    current_number
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSequenceQueryVariables,
  APITypes.GetSequenceQuery
>;
export const listSequences = /* GraphQL */ `query ListSequences(
  $filter: ModelSequenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listSequences(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      table_label
      current_number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSequencesQueryVariables,
  APITypes.ListSequencesQuery
>;
export const syncSequences = /* GraphQL */ `query SyncSequences(
  $filter: ModelSequenceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSequences(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      table_label
      current_number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSequencesQueryVariables,
  APITypes.SyncSequencesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    cognito_user_id
    email
    name
    roles {
      items {
        id
        user_id
        role_id
        user {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role {
          id
          seq_id
          label
          name
          default
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const searchUsers = /* GraphQL */ `query SearchUsers(
  $filter: SearchableUserFilterInput
  $sort: [SearchableUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserAggregationInput]
) {
  searchUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchUsersQueryVariables,
  APITypes.SearchUsersQuery
>;
export const getRole = /* GraphQL */ `query GetRole($id: ID!) {
  getRole(id: $id) {
    id
    seq_id
    label
    name
    default
    users {
      items {
        id
        user_id
        role_id
        user {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role {
          id
          seq_id
          label
          name
          default
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoleQueryVariables, APITypes.GetRoleQuery>;
export const listRoles = /* GraphQL */ `query ListRoles(
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      label
      name
      default
      users {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRolesQueryVariables, APITypes.ListRolesQuery>;
export const syncRoles = /* GraphQL */ `query SyncRoles(
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRoles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      label
      name
      default
      users {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncRolesQueryVariables, APITypes.SyncRolesQuery>;
export const getUserRoles = /* GraphQL */ `query GetUserRoles($id: ID!) {
  getUserRoles(id: $id) {
    id
    user_id
    role_id
    user {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    role {
      id
      seq_id
      label
      name
      default
      users {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserRolesQueryVariables,
  APITypes.GetUserRolesQuery
>;
export const listUserRoles = /* GraphQL */ `query ListUserRoles(
  $filter: ModelUserRolesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      role_id
      user {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      role {
        id
        seq_id
        label
        name
        default
        users {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserRolesQueryVariables,
  APITypes.ListUserRolesQuery
>;
export const syncUserRoles = /* GraphQL */ `query SyncUserRoles(
  $filter: ModelUserRolesFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserRoles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      user_id
      role_id
      user {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      role {
        id
        seq_id
        label
        name
        default
        users {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserRolesQueryVariables,
  APITypes.SyncUserRolesQuery
>;
export const searchUserRoles = /* GraphQL */ `query SearchUserRoles(
  $filter: SearchableUserRolesFilterInput
  $sort: [SearchableUserRolesSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserRolesAggregationInput]
) {
  searchUserRoles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      user_id
      role_id
      user {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      role {
        id
        seq_id
        label
        name
        default
        users {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchUserRolesQueryVariables,
  APITypes.SearchUserRolesQuery
>;
export const getGenre = /* GraphQL */ `query GetGenre($id: ID!) {
  getGenre(id: $id) {
    id
    ce_link_id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGenreQueryVariables, APITypes.GetGenreQuery>;
export const listGenres = /* GraphQL */ `query ListGenres(
  $filter: ModelGenreFilterInput
  $limit: Int
  $nextToken: String
) {
  listGenres(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ce_link_id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGenresQueryVariables,
  APITypes.ListGenresQuery
>;
export const syncGenres = /* GraphQL */ `query SyncGenres(
  $filter: ModelGenreFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGenres(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      ce_link_id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGenresQueryVariables,
  APITypes.SyncGenresQuery
>;
export const searchGenres = /* GraphQL */ `query SearchGenres(
  $filter: SearchableGenreFilterInput
  $sort: [SearchableGenreSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableGenreAggregationInput]
) {
  searchGenres(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      ce_link_id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchGenresQueryVariables,
  APITypes.SearchGenresQuery
>;
export const getPlatform = /* GraphQL */ `query GetPlatform($id: ID!) {
  getPlatform(id: $id) {
    id
    seq_id
    name
    banasuke_name
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlatformQueryVariables,
  APITypes.GetPlatformQuery
>;
export const listPlatforms = /* GraphQL */ `query ListPlatforms(
  $filter: ModelPlatformFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlatforms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlatformsQueryVariables,
  APITypes.ListPlatformsQuery
>;
export const syncPlatforms = /* GraphQL */ `query SyncPlatforms(
  $filter: ModelPlatformFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPlatforms(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPlatformsQueryVariables,
  APITypes.SyncPlatformsQuery
>;
export const searchPlatforms = /* GraphQL */ `query SearchPlatforms(
  $filter: SearchablePlatformFilterInput
  $sort: [SearchablePlatformSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePlatformAggregationInput]
) {
  searchPlatforms(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPlatformsQueryVariables,
  APITypes.SearchPlatformsQuery
>;
export const getProductClass = /* GraphQL */ `query GetProductClass($id: ID!) {
  getProductClass(id: $id) {
    id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductClassQueryVariables,
  APITypes.GetProductClassQuery
>;
export const listProductClasses = /* GraphQL */ `query ListProductClasses(
  $filter: ModelProductClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductClassesQueryVariables,
  APITypes.ListProductClassesQuery
>;
export const syncProductClasses = /* GraphQL */ `query SyncProductClasses(
  $filter: ModelProductClassFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProductClasses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProductClassesQueryVariables,
  APITypes.SyncProductClassesQuery
>;
export const searchProductClasses = /* GraphQL */ `query SearchProductClasses(
  $filter: SearchableProductClassFilterInput
  $sort: [SearchableProductClassSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductClassAggregationInput]
) {
  searchProductClasses(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductClassesQueryVariables,
  APITypes.SearchProductClassesQuery
>;
export const getProductType = /* GraphQL */ `query GetProductType($id: ID!) {
  getProductType(id: $id) {
    id
    seq_id
    name
    name_ja
    banasuke_name
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductTypeQueryVariables,
  APITypes.GetProductTypeQuery
>;
export const listProductTypes = /* GraphQL */ `query ListProductTypes(
  $filter: ModelProductTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTypesQueryVariables,
  APITypes.ListProductTypesQuery
>;
export const syncProductTypes = /* GraphQL */ `query SyncProductTypes(
  $filter: ModelProductTypeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProductTypes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProductTypesQueryVariables,
  APITypes.SyncProductTypesQuery
>;
export const searchProductTypes = /* GraphQL */ `query SearchProductTypes(
  $filter: SearchableProductTypeFilterInput
  $sort: [SearchableProductTypeSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductTypeAggregationInput]
) {
  searchProductTypes(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductTypesQueryVariables,
  APITypes.SearchProductTypesQuery
>;
export const getCurrency = /* GraphQL */ `query GetCurrency($id: ID!) {
  getCurrency(id: $id) {
    id
    order_id
    currency_code
    code_three_char
    name
    name_en
    digit_number
    currency_symbol
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCurrencyQueryVariables,
  APITypes.GetCurrencyQuery
>;
export const listCurrencies = /* GraphQL */ `query ListCurrencies(
  $filter: ModelCurrencyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCurrencies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCurrenciesQueryVariables,
  APITypes.ListCurrenciesQuery
>;
export const syncCurrencies = /* GraphQL */ `query SyncCurrencies(
  $filter: ModelCurrencyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCurrencies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCurrenciesQueryVariables,
  APITypes.SyncCurrenciesQuery
>;
export const searchCurrencies = /* GraphQL */ `query SearchCurrencies(
  $filter: SearchableCurrencyFilterInput
  $sort: [SearchableCurrencySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCurrencyAggregationInput]
) {
  searchCurrencies(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCurrenciesQueryVariables,
  APITypes.SearchCurrenciesQuery
>;
export const getDataSource = /* GraphQL */ `query GetDataSource($id: ID!) {
  getDataSource(id: $id) {
    id
    seq_id
    name
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataSourceQueryVariables,
  APITypes.GetDataSourceQuery
>;
export const listDataSources = /* GraphQL */ `query ListDataSources(
  $filter: ModelDataSourceFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataSourcesQueryVariables,
  APITypes.ListDataSourcesQuery
>;
export const syncDataSources = /* GraphQL */ `query SyncDataSources(
  $filter: ModelDataSourceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDataSources(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDataSourcesQueryVariables,
  APITypes.SyncDataSourcesQuery
>;
export const searchDataSources = /* GraphQL */ `query SearchDataSources(
  $filter: SearchableDataSourceFilterInput
  $sort: [SearchableDataSourceSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDataSourceAggregationInput]
) {
  searchDataSources(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDataSourcesQueryVariables,
  APITypes.SearchDataSourcesQuery
>;
export const getTag = /* GraphQL */ `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    titles {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    title_codes {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bsps {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnams {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnmls {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnfs {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagQueryVariables, APITypes.GetTagQuery>;
export const listTags = /* GraphQL */ `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTagsQueryVariables, APITypes.ListTagsQuery>;
export const syncTags = /* GraphQL */ `query SyncTags(
  $filter: ModelTagFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncTagsQueryVariables, APITypes.SyncTagsQuery>;
export const searchTags = /* GraphQL */ `query SearchTags(
  $filter: SearchableTagFilterInput
  $sort: [SearchableTagSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagAggregationInput]
) {
  searchTags(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagsQueryVariables,
  APITypes.SearchTagsQuery
>;
export const getTagHistory = /* GraphQL */ `query GetTagHistory($id: ID!) {
  getTagHistory(id: $id) {
    id
    tag_id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagHistoryQueryVariables,
  APITypes.GetTagHistoryQuery
>;
export const listTagHistories = /* GraphQL */ `query ListTagHistories(
  $filter: ModelTagHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagHistoriesQueryVariables,
  APITypes.ListTagHistoriesQuery
>;
export const syncTagHistories = /* GraphQL */ `query SyncTagHistories(
  $filter: ModelTagHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagHistoriesQueryVariables,
  APITypes.SyncTagHistoriesQuery
>;
export const searchTagHistories = /* GraphQL */ `query SearchTagHistories(
  $filter: SearchableTagHistoryFilterInput
  $sort: [SearchableTagHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagHistoryAggregationInput]
) {
  searchTagHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagHistoriesQueryVariables,
  APITypes.SearchTagHistoriesQuery
>;
export const getTitle = /* GraphQL */ `query GetTitle($id: ID!) {
  getTitle(id: $id) {
    id
    seq_id
    name_ja
    name_en
    search_name
    sap_title_code
    bn_connect_title_id
    project_cd
    banasuke_title_id
    ce_link_key_code
    update_reason
    genre_id
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTitleQueryVariables, APITypes.GetTitleQuery>;
export const listTitles = /* GraphQL */ `query ListTitles(
  $filter: ModelTitleFilterInput
  $limit: Int
  $nextToken: String
) {
  listTitles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTitlesQueryVariables,
  APITypes.ListTitlesQuery
>;
export const syncTitles = /* GraphQL */ `query SyncTitles(
  $filter: ModelTitleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTitles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTitlesQueryVariables,
  APITypes.SyncTitlesQuery
>;
export const searchTitles = /* GraphQL */ `query SearchTitles(
  $filter: SearchableTitleFilterInput
  $sort: [SearchableTitleSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTitleAggregationInput]
) {
  searchTitles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTitlesQueryVariables,
  APITypes.SearchTitlesQuery
>;
export const getTitleCode = /* GraphQL */ `query GetTitleCode($id: ID!) {
  getTitleCode(id: $id) {
    id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    business_entity_id
    business_entity {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_ids
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    valid_flg
    update_reason
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTitleCodeQueryVariables,
  APITypes.GetTitleCodeQuery
>;
export const listTitleCodes = /* GraphQL */ `query ListTitleCodes(
  $filter: ModelTitleCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listTitleCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTitleCodesQueryVariables,
  APITypes.ListTitleCodesQuery
>;
export const syncTitleCodes = /* GraphQL */ `query SyncTitleCodes(
  $filter: ModelTitleCodeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTitleCodes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTitleCodesQueryVariables,
  APITypes.SyncTitleCodesQuery
>;
export const searchTitleCodes = /* GraphQL */ `query SearchTitleCodes(
  $filter: SearchableTitleCodeFilterInput
  $sort: [SearchableTitleCodeSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTitleCodeAggregationInput]
) {
  searchTitleCodes(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTitleCodesQueryVariables,
  APITypes.SearchTitleCodesQuery
>;
export const getTitleHistory = /* GraphQL */ `query GetTitleHistory($id: ID!) {
  getTitleHistory(id: $id) {
    id
    title_id
    seq_id
    name_ja
    name_en
    search_name
    genre_id
    genre_name
    sap_title_code
    project_cd
    bn_connect_title_id
    banasuke_title_id
    ce_link_key_code
    data_source_id
    data_source_name
    update_reason
    admin_check_flg
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    system_update_flg
    old_id
    old_title_id
    old_seq_id
    old_name_ja
    old_name_en
    old_search_name
    old_genre_id
    old_genre_name
    old_sap_title_code
    old_project_cd
    old_bn_connect_title_id
    old_banasuke_title_id
    old_ce_link_key_code
    old_data_source_id
    old_data_source_name
    old_update_reason
    old_admin_check_flg
    old_system_update_flg
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTitleHistoryQueryVariables,
  APITypes.GetTitleHistoryQuery
>;
export const listTitleHistories = /* GraphQL */ `query ListTitleHistories(
  $filter: ModelTitleHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTitleHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title_id
      seq_id
      name_ja
      name_en
      search_name
      genre_id
      genre_name
      sap_title_code
      project_cd
      bn_connect_title_id
      banasuke_title_id
      ce_link_key_code
      data_source_id
      data_source_name
      update_reason
      admin_check_flg
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      system_update_flg
      old_id
      old_title_id
      old_seq_id
      old_name_ja
      old_name_en
      old_search_name
      old_genre_id
      old_genre_name
      old_sap_title_code
      old_project_cd
      old_bn_connect_title_id
      old_banasuke_title_id
      old_ce_link_key_code
      old_data_source_id
      old_data_source_name
      old_update_reason
      old_admin_check_flg
      old_system_update_flg
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTitleHistoriesQueryVariables,
  APITypes.ListTitleHistoriesQuery
>;
export const syncTitleHistories = /* GraphQL */ `query SyncTitleHistories(
  $filter: ModelTitleHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTitleHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title_id
      seq_id
      name_ja
      name_en
      search_name
      genre_id
      genre_name
      sap_title_code
      project_cd
      bn_connect_title_id
      banasuke_title_id
      ce_link_key_code
      data_source_id
      data_source_name
      update_reason
      admin_check_flg
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      system_update_flg
      old_id
      old_title_id
      old_seq_id
      old_name_ja
      old_name_en
      old_search_name
      old_genre_id
      old_genre_name
      old_sap_title_code
      old_project_cd
      old_bn_connect_title_id
      old_banasuke_title_id
      old_ce_link_key_code
      old_data_source_id
      old_data_source_name
      old_update_reason
      old_admin_check_flg
      old_system_update_flg
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTitleHistoriesQueryVariables,
  APITypes.SyncTitleHistoriesQuery
>;
export const searchTitleHistories = /* GraphQL */ `query SearchTitleHistories(
  $filter: SearchableTitleHistoryFilterInput
  $sort: [SearchableTitleHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTitleHistoryAggregationInput]
) {
  searchTitleHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title_id
      seq_id
      name_ja
      name_en
      search_name
      genre_id
      genre_name
      sap_title_code
      project_cd
      bn_connect_title_id
      banasuke_title_id
      ce_link_key_code
      data_source_id
      data_source_name
      update_reason
      admin_check_flg
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      system_update_flg
      old_id
      old_title_id
      old_seq_id
      old_name_ja
      old_name_en
      old_search_name
      old_genre_id
      old_genre_name
      old_sap_title_code
      old_project_cd
      old_bn_connect_title_id
      old_banasuke_title_id
      old_ce_link_key_code
      old_data_source_id
      old_data_source_name
      old_update_reason
      old_admin_check_flg
      old_system_update_flg
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTitleHistoriesQueryVariables,
  APITypes.SearchTitleHistoriesQuery
>;
export const getTitleCodeHistory = /* GraphQL */ `query GetTitleCodeHistory($id: ID!) {
  getTitleCodeHistory(id: $id) {
    id
    title_code_id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    genre_names
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    platform_ids
    platform_names
    business_entity_id
    business_entity_name
    data_source_id
    data_source_name
    valid_flg
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    update_reason
    updated_user_id
    updated_user_name
    createdAt
    updatedAt
    operation_type
    old_id
    old_title_code_id
    old_seq_id
    old_title_code
    old_name_ja
    old_name_en
    old_official_name_flg
    old_sap_title_code_name
    old_search_name
    old_project_cd
    old_wbs
    old_bn_connect_title_ids
    old_ce_link_key_code
    old_ce_link_title_name_ja
    old_ce_link_title_name_en
    old_genre_ids
    old_genre_names
    old_banasuke_title_id
    old_banasuke_title_name_ja
    old_bundle_source_title_code_ids
    old_porting_source_title_code_ids
    old_platform_ids
    old_platform_names
    old_business_entity_id
    old_business_entity_name
    old_data_source_id
    old_data_source_name
    old_valid_flg
    old_system_update_flg
    old_admin_check_flg
    old_delete_flg
    old_deleted_at
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_createdAt
    old_updatedAt
    old_operation_type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTitleCodeHistoryQueryVariables,
  APITypes.GetTitleCodeHistoryQuery
>;
export const listTitleCodeHistories = /* GraphQL */ `query ListTitleCodeHistories(
  $filter: ModelTitleCodeHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTitleCodeHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title_code_id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      genre_names
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      platform_ids
      platform_names
      business_entity_id
      business_entity_name
      data_source_id
      data_source_name
      valid_flg
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      update_reason
      updated_user_id
      updated_user_name
      createdAt
      updatedAt
      operation_type
      old_id
      old_title_code_id
      old_seq_id
      old_title_code
      old_name_ja
      old_name_en
      old_official_name_flg
      old_sap_title_code_name
      old_search_name
      old_project_cd
      old_wbs
      old_bn_connect_title_ids
      old_ce_link_key_code
      old_ce_link_title_name_ja
      old_ce_link_title_name_en
      old_genre_ids
      old_genre_names
      old_banasuke_title_id
      old_banasuke_title_name_ja
      old_bundle_source_title_code_ids
      old_porting_source_title_code_ids
      old_platform_ids
      old_platform_names
      old_business_entity_id
      old_business_entity_name
      old_data_source_id
      old_data_source_name
      old_valid_flg
      old_system_update_flg
      old_admin_check_flg
      old_delete_flg
      old_deleted_at
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_createdAt
      old_updatedAt
      old_operation_type
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTitleCodeHistoriesQueryVariables,
  APITypes.ListTitleCodeHistoriesQuery
>;
export const syncTitleCodeHistories = /* GraphQL */ `query SyncTitleCodeHistories(
  $filter: ModelTitleCodeHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTitleCodeHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title_code_id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      genre_names
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      platform_ids
      platform_names
      business_entity_id
      business_entity_name
      data_source_id
      data_source_name
      valid_flg
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      update_reason
      updated_user_id
      updated_user_name
      createdAt
      updatedAt
      operation_type
      old_id
      old_title_code_id
      old_seq_id
      old_title_code
      old_name_ja
      old_name_en
      old_official_name_flg
      old_sap_title_code_name
      old_search_name
      old_project_cd
      old_wbs
      old_bn_connect_title_ids
      old_ce_link_key_code
      old_ce_link_title_name_ja
      old_ce_link_title_name_en
      old_genre_ids
      old_genre_names
      old_banasuke_title_id
      old_banasuke_title_name_ja
      old_bundle_source_title_code_ids
      old_porting_source_title_code_ids
      old_platform_ids
      old_platform_names
      old_business_entity_id
      old_business_entity_name
      old_data_source_id
      old_data_source_name
      old_valid_flg
      old_system_update_flg
      old_admin_check_flg
      old_delete_flg
      old_deleted_at
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_createdAt
      old_updatedAt
      old_operation_type
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTitleCodeHistoriesQueryVariables,
  APITypes.SyncTitleCodeHistoriesQuery
>;
export const searchTitleCodeHistories = /* GraphQL */ `query SearchTitleCodeHistories(
  $filter: SearchableTitleCodeHistoryFilterInput
  $sort: [SearchableTitleCodeHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTitleCodeHistoryAggregationInput]
) {
  searchTitleCodeHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title_code_id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      genre_names
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      platform_ids
      platform_names
      business_entity_id
      business_entity_name
      data_source_id
      data_source_name
      valid_flg
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      update_reason
      updated_user_id
      updated_user_name
      createdAt
      updatedAt
      operation_type
      old_id
      old_title_code_id
      old_seq_id
      old_title_code
      old_name_ja
      old_name_en
      old_official_name_flg
      old_sap_title_code_name
      old_search_name
      old_project_cd
      old_wbs
      old_bn_connect_title_ids
      old_ce_link_key_code
      old_ce_link_title_name_ja
      old_ce_link_title_name_en
      old_genre_ids
      old_genre_names
      old_banasuke_title_id
      old_banasuke_title_name_ja
      old_bundle_source_title_code_ids
      old_porting_source_title_code_ids
      old_platform_ids
      old_platform_names
      old_business_entity_id
      old_business_entity_name
      old_data_source_id
      old_data_source_name
      old_valid_flg
      old_system_update_flg
      old_admin_check_flg
      old_delete_flg
      old_deleted_at
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_createdAt
      old_updatedAt
      old_operation_type
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTitleCodeHistoriesQueryVariables,
  APITypes.SearchTitleCodeHistoriesQuery
>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    seq_id
    name
    common_name
    title_id
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updated_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name
      common_name
      title_id
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_type_id
      product_type {
        id
        seq_id
        name
        name_ja
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_class_id
      product_class {
        id
        seq_id
        name
        name_ja
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_id
      platform {
        id
        seq_id
        name
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      base_price
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      app_id
      market
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updated_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const syncProducts = /* GraphQL */ `query SyncProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProducts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name
      common_name
      title_id
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_type_id
      product_type {
        id
        seq_id
        name
        name_ja
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_class_id
      product_class {
        id
        seq_id
        name
        name_ja
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_id
      platform {
        id
        seq_id
        name
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      base_price
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      app_id
      market
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updated_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProductsQueryVariables,
  APITypes.SyncProductsQuery
>;
export const searchProducts = /* GraphQL */ `query SearchProducts(
  $filter: SearchableProductFilterInput
  $sort: [SearchableProductSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductAggregationInput]
) {
  searchProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name
      common_name
      title_id
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_type_id
      product_type {
        id
        seq_id
        name
        name_ja
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_class_id
      product_class {
        id
        seq_id
        name
        name_ja
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_id
      platform {
        id
        seq_id
        name
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      base_price
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      app_id
      market
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updated_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductsQueryVariables,
  APITypes.SearchProductsQuery
>;
export const getProductTcd = /* GraphQL */ `query GetProductTcd($id: ID!) {
  getProductTcd(id: $id) {
    id
    seq_id
    name
    common_name
    title_code_id
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    system_update_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductTcdQueryVariables,
  APITypes.GetProductTcdQuery
>;
export const listProductTcds = /* GraphQL */ `query ListProductTcds(
  $filter: ModelProductTcdFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTcds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name
      common_name
      title_code_id
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_type_id
      product_type {
        id
        seq_id
        name
        name_ja
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_class_id
      product_class {
        id
        seq_id
        name
        name_ja
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_id
      platform {
        id
        seq_id
        name
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      base_price
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      app_id
      market
      admin_check_flg
      system_update_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTcdsQueryVariables,
  APITypes.ListProductTcdsQuery
>;
export const syncProductTcds = /* GraphQL */ `query SyncProductTcds(
  $filter: ModelProductTcdFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProductTcds(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name
      common_name
      title_code_id
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_type_id
      product_type {
        id
        seq_id
        name
        name_ja
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_class_id
      product_class {
        id
        seq_id
        name
        name_ja
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_id
      platform {
        id
        seq_id
        name
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      base_price
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      app_id
      market
      admin_check_flg
      system_update_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProductTcdsQueryVariables,
  APITypes.SyncProductTcdsQuery
>;
export const searchProductTcds = /* GraphQL */ `query SearchProductTcds(
  $filter: SearchableProductTcdFilterInput
  $sort: [SearchableProductTcdSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductTcdAggregationInput]
) {
  searchProductTcds(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name
      common_name
      title_code_id
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_type_id
      product_type {
        id
        seq_id
        name
        name_ja
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      product_class_id
      product_class {
        id
        seq_id
        name
        name_ja
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_id
      platform {
        id
        seq_id
        name
        banasuke_name
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      base_price
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      app_id
      market
      admin_check_flg
      system_update_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductTcdsQueryVariables,
  APITypes.SearchProductTcdsQuery
>;
export const getProductHistory = /* GraphQL */ `query GetProductHistory($id: ID!) {
  getProductHistory(id: $id) {
    id
    product_id
    seq_id
    name
    common_name
    title_id
    title_name
    product_type_id
    product_type_name
    product_class_id
    product_class_name
    platform_id
    platform_name
    base_price
    currency_id
    currency_name
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    data_source_id
    data_source_name
    app_id
    market
    admin_check_flg
    system_update_flg
    updated_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    old_id
    old_product_id
    old_seq_id
    old_name
    old_common_name
    old_title_id
    old_title_name
    old_product_type_id
    old_product_type_name
    old_product_class_id
    old_product_class_name
    old_platform_id
    old_platform_name
    old_base_price
    old_currency_id
    old_currency_name
    old_info_release_date
    old_preorder_start_date
    old_release_date
    old_release_status
    old_service_start_date
    old_service_end_date
    old_support_end_date
    old_product_key
    old_sku_number_apple
    old_package_name_google
    old_bank_app_id
    old_data_source_id
    old_data_source_name
    old_app_id
    old_market
    old_admin_check_flg
    old_system_update_flg
    old_updated_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductHistoryQueryVariables,
  APITypes.GetProductHistoryQuery
>;
export const listProductHistories = /* GraphQL */ `query ListProductHistories(
  $filter: ModelProductHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      product_id
      seq_id
      name
      common_name
      title_id
      title_name
      product_type_id
      product_type_name
      product_class_id
      product_class_name
      platform_id
      platform_name
      base_price
      currency_id
      currency_name
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      data_source_id
      data_source_name
      app_id
      market
      admin_check_flg
      system_update_flg
      updated_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      old_id
      old_product_id
      old_seq_id
      old_name
      old_common_name
      old_title_id
      old_title_name
      old_product_type_id
      old_product_type_name
      old_product_class_id
      old_product_class_name
      old_platform_id
      old_platform_name
      old_base_price
      old_currency_id
      old_currency_name
      old_info_release_date
      old_preorder_start_date
      old_release_date
      old_release_status
      old_service_start_date
      old_service_end_date
      old_support_end_date
      old_product_key
      old_sku_number_apple
      old_package_name_google
      old_bank_app_id
      old_data_source_id
      old_data_source_name
      old_app_id
      old_market
      old_admin_check_flg
      old_system_update_flg
      old_updated_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductHistoriesQueryVariables,
  APITypes.ListProductHistoriesQuery
>;
export const syncProductHistories = /* GraphQL */ `query SyncProductHistories(
  $filter: ModelProductHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProductHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      product_id
      seq_id
      name
      common_name
      title_id
      title_name
      product_type_id
      product_type_name
      product_class_id
      product_class_name
      platform_id
      platform_name
      base_price
      currency_id
      currency_name
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      data_source_id
      data_source_name
      app_id
      market
      admin_check_flg
      system_update_flg
      updated_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      old_id
      old_product_id
      old_seq_id
      old_name
      old_common_name
      old_title_id
      old_title_name
      old_product_type_id
      old_product_type_name
      old_product_class_id
      old_product_class_name
      old_platform_id
      old_platform_name
      old_base_price
      old_currency_id
      old_currency_name
      old_info_release_date
      old_preorder_start_date
      old_release_date
      old_release_status
      old_service_start_date
      old_service_end_date
      old_support_end_date
      old_product_key
      old_sku_number_apple
      old_package_name_google
      old_bank_app_id
      old_data_source_id
      old_data_source_name
      old_app_id
      old_market
      old_admin_check_flg
      old_system_update_flg
      old_updated_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProductHistoriesQueryVariables,
  APITypes.SyncProductHistoriesQuery
>;
export const searchProductHistories = /* GraphQL */ `query SearchProductHistories(
  $filter: SearchableProductHistoryFilterInput
  $sort: [SearchableProductHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductHistoryAggregationInput]
) {
  searchProductHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      product_id
      seq_id
      name
      common_name
      title_id
      title_name
      product_type_id
      product_type_name
      product_class_id
      product_class_name
      platform_id
      platform_name
      base_price
      currency_id
      currency_name
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      data_source_id
      data_source_name
      app_id
      market
      admin_check_flg
      system_update_flg
      updated_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      operation_type
      createdAt
      updatedAt
      old_id
      old_product_id
      old_seq_id
      old_name
      old_common_name
      old_title_id
      old_title_name
      old_product_type_id
      old_product_type_name
      old_product_class_id
      old_product_class_name
      old_platform_id
      old_platform_name
      old_base_price
      old_currency_id
      old_currency_name
      old_info_release_date
      old_preorder_start_date
      old_release_date
      old_release_status
      old_service_start_date
      old_service_end_date
      old_support_end_date
      old_product_key
      old_sku_number_apple
      old_package_name_google
      old_bank_app_id
      old_data_source_id
      old_data_source_name
      old_app_id
      old_market
      old_admin_check_flg
      old_system_update_flg
      old_updated_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductHistoriesQueryVariables,
  APITypes.SearchProductHistoriesQuery
>;
export const getProductTcdHistory = /* GraphQL */ `query GetProductTcdHistory($id: ID!) {
  getProductTcdHistory(id: $id) {
    id
    product_tcd_id
    seq_id
    name
    common_name
    title_code_id
    title_code_name
    product_type_id
    product_type_name
    product_class_id
    product_class_name
    platform_id
    platform_name
    base_price
    currency_id
    currency_name
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    data_source_id
    data_source_name
    app_id
    market
    admin_check_flg
    system_update_flg
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    operation_type
    createdAt
    updatedAt
    deleted_at
    old_id
    old_product_tcd_id
    old_seq_id
    old_name
    old_common_name
    old_title_code_id
    old_title_code_name
    old_product_type_id
    old_product_type_name
    old_product_class_id
    old_product_class_name
    old_platform_id
    old_platform_name
    old_base_price
    old_currency_id
    old_currency_name
    old_info_release_date
    old_preorder_start_date
    old_release_date
    old_release_status
    old_service_start_date
    old_service_end_date
    old_support_end_date
    old_product_key
    old_sku_number_apple
    old_package_name_google
    old_bank_app_id
    old_data_source_id
    old_data_source_name
    old_app_id
    old_market
    old_admin_check_flg
    old_system_update_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductTcdHistoryQueryVariables,
  APITypes.GetProductTcdHistoryQuery
>;
export const listProductTcdHistories = /* GraphQL */ `query ListProductTcdHistories(
  $filter: ModelProductTcdHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductTcdHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      product_tcd_id
      seq_id
      name
      common_name
      title_code_id
      title_code_name
      product_type_id
      product_type_name
      product_class_id
      product_class_name
      platform_id
      platform_name
      base_price
      currency_id
      currency_name
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      data_source_id
      data_source_name
      app_id
      market
      admin_check_flg
      system_update_flg
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      operation_type
      createdAt
      updatedAt
      deleted_at
      old_id
      old_product_tcd_id
      old_seq_id
      old_name
      old_common_name
      old_title_code_id
      old_title_code_name
      old_product_type_id
      old_product_type_name
      old_product_class_id
      old_product_class_name
      old_platform_id
      old_platform_name
      old_base_price
      old_currency_id
      old_currency_name
      old_info_release_date
      old_preorder_start_date
      old_release_date
      old_release_status
      old_service_start_date
      old_service_end_date
      old_support_end_date
      old_product_key
      old_sku_number_apple
      old_package_name_google
      old_bank_app_id
      old_data_source_id
      old_data_source_name
      old_app_id
      old_market
      old_admin_check_flg
      old_system_update_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductTcdHistoriesQueryVariables,
  APITypes.ListProductTcdHistoriesQuery
>;
export const syncProductTcdHistories = /* GraphQL */ `query SyncProductTcdHistories(
  $filter: ModelProductTcdHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProductTcdHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      product_tcd_id
      seq_id
      name
      common_name
      title_code_id
      title_code_name
      product_type_id
      product_type_name
      product_class_id
      product_class_name
      platform_id
      platform_name
      base_price
      currency_id
      currency_name
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      data_source_id
      data_source_name
      app_id
      market
      admin_check_flg
      system_update_flg
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      operation_type
      createdAt
      updatedAt
      deleted_at
      old_id
      old_product_tcd_id
      old_seq_id
      old_name
      old_common_name
      old_title_code_id
      old_title_code_name
      old_product_type_id
      old_product_type_name
      old_product_class_id
      old_product_class_name
      old_platform_id
      old_platform_name
      old_base_price
      old_currency_id
      old_currency_name
      old_info_release_date
      old_preorder_start_date
      old_release_date
      old_release_status
      old_service_start_date
      old_service_end_date
      old_support_end_date
      old_product_key
      old_sku_number_apple
      old_package_name_google
      old_bank_app_id
      old_data_source_id
      old_data_source_name
      old_app_id
      old_market
      old_admin_check_flg
      old_system_update_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProductTcdHistoriesQueryVariables,
  APITypes.SyncProductTcdHistoriesQuery
>;
export const searchProductTcdHistories = /* GraphQL */ `query SearchProductTcdHistories(
  $filter: SearchableProductTcdHistoryFilterInput
  $sort: [SearchableProductTcdHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableProductTcdHistoryAggregationInput]
) {
  searchProductTcdHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      product_tcd_id
      seq_id
      name
      common_name
      title_code_id
      title_code_name
      product_type_id
      product_type_name
      product_class_id
      product_class_name
      platform_id
      platform_name
      base_price
      currency_id
      currency_name
      info_release_date
      preorder_start_date
      release_date
      release_status
      service_start_date
      service_end_date
      support_end_date
      product_key
      sku_number_apple
      package_name_google
      bank_app_id
      data_source_id
      data_source_name
      app_id
      market
      admin_check_flg
      system_update_flg
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      operation_type
      createdAt
      updatedAt
      deleted_at
      old_id
      old_product_tcd_id
      old_seq_id
      old_name
      old_common_name
      old_title_code_id
      old_title_code_name
      old_product_type_id
      old_product_type_name
      old_product_class_id
      old_product_class_name
      old_platform_id
      old_platform_name
      old_base_price
      old_currency_id
      old_currency_name
      old_info_release_date
      old_preorder_start_date
      old_release_date
      old_release_status
      old_service_start_date
      old_service_end_date
      old_support_end_date
      old_product_key
      old_sku_number_apple
      old_package_name_google
      old_bank_app_id
      old_data_source_id
      old_data_source_name
      old_app_id
      old_market
      old_admin_check_flg
      old_system_update_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_operation_type
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchProductTcdHistoriesQueryVariables,
  APITypes.SearchProductTcdHistoriesQuery
>;
export const getBnml = /* GraphQL */ `query GetBnml($id: ID!) {
  getBnml(id: $id) {
    id
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    tags {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBnmlQueryVariables, APITypes.GetBnmlQuery>;
export const listBnmls = /* GraphQL */ `query ListBnmls(
  $filter: ModelBnmlFilterInput
  $limit: Int
  $nextToken: String
) {
  listBnmls(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      tags {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBnmlsQueryVariables, APITypes.ListBnmlsQuery>;
export const syncBnmls = /* GraphQL */ `query SyncBnmls(
  $filter: ModelBnmlFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBnmls(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      tags {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncBnmlsQueryVariables, APITypes.SyncBnmlsQuery>;
export const searchBnmls = /* GraphQL */ `query SearchBnmls(
  $filter: SearchableBnmlFilterInput
  $sort: [SearchableBnmlSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBnmlAggregationInput]
) {
  searchBnmls(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      tags {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBnmlsQueryVariables,
  APITypes.SearchBnmlsQuery
>;
export const getBnmlHistory = /* GraphQL */ `query GetBnmlHistory($id: ID!) {
  getBnmlHistory(id: $id) {
    id
    bnml_id
    operation_type
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    system_update_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    old_id
    old_bnml_id
    old_operation_type
    old_seq_id
    old_mlics_plan_cd_4char
    old_mlics_plan_cd_7char
    old_mlics_commodity_name
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_system_update_flg
    old_admin_check_flg
    old_deleted_at
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBnmlHistoryQueryVariables,
  APITypes.GetBnmlHistoryQuery
>;
export const listBnmlHistories = /* GraphQL */ `query ListBnmlHistories(
  $filter: ModelBnmlHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBnmlHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bnml_id
      operation_type
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      system_update_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      old_id
      old_bnml_id
      old_operation_type
      old_seq_id
      old_mlics_plan_cd_4char
      old_mlics_plan_cd_7char
      old_mlics_commodity_name
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_system_update_flg
      old_admin_check_flg
      old_deleted_at
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBnmlHistoriesQueryVariables,
  APITypes.ListBnmlHistoriesQuery
>;
export const syncBnmlHistories = /* GraphQL */ `query SyncBnmlHistories(
  $filter: ModelBnmlHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBnmlHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      bnml_id
      operation_type
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      system_update_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      old_id
      old_bnml_id
      old_operation_type
      old_seq_id
      old_mlics_plan_cd_4char
      old_mlics_plan_cd_7char
      old_mlics_commodity_name
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_system_update_flg
      old_admin_check_flg
      old_deleted_at
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBnmlHistoriesQueryVariables,
  APITypes.SyncBnmlHistoriesQuery
>;
export const searchBnmlHistories = /* GraphQL */ `query SearchBnmlHistories(
  $filter: SearchableBnmlHistoryFilterInput
  $sort: [SearchableBnmlHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBnmlHistoryAggregationInput]
) {
  searchBnmlHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      bnml_id
      operation_type
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      system_update_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      old_id
      old_bnml_id
      old_operation_type
      old_seq_id
      old_mlics_plan_cd_4char
      old_mlics_plan_cd_7char
      old_mlics_commodity_name
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_system_update_flg
      old_admin_check_flg
      old_deleted_at
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBnmlHistoriesQueryVariables,
  APITypes.SearchBnmlHistoriesQuery
>;
export const getTagBnml = /* GraphQL */ `query GetTagBnml($id: ID!) {
  getTagBnml(id: $id) {
    id
    tag_id
    bnml_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnml {
      id
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      tags {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagBnmlQueryVariables,
  APITypes.GetTagBnmlQuery
>;
export const listTagBnmls = /* GraphQL */ `query ListTagBnmls(
  $filter: ModelTagBnmlFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagBnmls(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_id
      bnml_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnml {
        id
        seq_id
        mlics_plan_cd_4char
        mlics_plan_cd_7char
        mlics_commodity_name
        tags {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        update_reason
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        admin_check_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagBnmlsQueryVariables,
  APITypes.ListTagBnmlsQuery
>;
export const syncTagBnmls = /* GraphQL */ `query SyncTagBnmls(
  $filter: ModelTagBnmlFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagBnmls(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_id
      bnml_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnml {
        id
        seq_id
        mlics_plan_cd_4char
        mlics_plan_cd_7char
        mlics_commodity_name
        tags {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        update_reason
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        admin_check_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagBnmlsQueryVariables,
  APITypes.SyncTagBnmlsQuery
>;
export const searchTagBnmls = /* GraphQL */ `query SearchTagBnmls(
  $filter: SearchableTagBnmlFilterInput
  $sort: [SearchableTagBnmlSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagBnmlAggregationInput]
) {
  searchTagBnmls(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_id
      bnml_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnml {
        id
        seq_id
        mlics_plan_cd_4char
        mlics_plan_cd_7char
        mlics_commodity_name
        tags {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        update_reason
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        admin_check_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagBnmlsQueryVariables,
  APITypes.SearchTagBnmlsQuery
>;
export const getTagBnmlHistory = /* GraphQL */ `query GetTagBnmlHistory($id: ID!) {
  getTagBnmlHistory(id: $id) {
    id
    operation_type
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    bnml_id
    bnml_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_mlics_plan_cd_4char
    old_mlics_plan_cd_7char
    old_mlics_commodity_name
    old_bnml_id
    old_bnml_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagBnmlHistoryQueryVariables,
  APITypes.GetTagBnmlHistoryQuery
>;
export const listTagBnmlHistories = /* GraphQL */ `query ListTagBnmlHistories(
  $filter: ModelTagBnmlHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagBnmlHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      operation_type
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      bnml_id
      bnml_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_mlics_plan_cd_4char
      old_mlics_plan_cd_7char
      old_mlics_commodity_name
      old_bnml_id
      old_bnml_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagBnmlHistoriesQueryVariables,
  APITypes.ListTagBnmlHistoriesQuery
>;
export const syncTagBnmlHistories = /* GraphQL */ `query SyncTagBnmlHistories(
  $filter: ModelTagBnmlHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagBnmlHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      operation_type
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      bnml_id
      bnml_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_mlics_plan_cd_4char
      old_mlics_plan_cd_7char
      old_mlics_commodity_name
      old_bnml_id
      old_bnml_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagBnmlHistoriesQueryVariables,
  APITypes.SyncTagBnmlHistoriesQuery
>;
export const searchTagBnmlHistories = /* GraphQL */ `query SearchTagBnmlHistories(
  $filter: SearchableTagBnmlHistoryFilterInput
  $sort: [SearchableTagBnmlHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagBnmlHistoryAggregationInput]
) {
  searchTagBnmlHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      operation_type
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      bnml_id
      bnml_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_mlics_plan_cd_4char
      old_mlics_plan_cd_7char
      old_mlics_commodity_name
      old_bnml_id
      old_bnml_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagBnmlHistoriesQueryVariables,
  APITypes.SearchTagBnmlHistoriesQuery
>;
export const getTagBnf = /* GraphQL */ `query GetTagBnf($id: ID!) {
  getTagBnf(id: $id) {
    id
    tag_id
    bnf_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnf {
      id
      seq_id
      tags {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagBnfQueryVariables, APITypes.GetTagBnfQuery>;
export const listTagBnfs = /* GraphQL */ `query ListTagBnfs(
  $filter: ModelTagBnfFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagBnfs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_id
      bnf_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnf {
        id
        seq_id
        tags {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnf_data_source
        obic7_commodity_cd
        obic7_commodity_name
        bvics_plan_cd_4char
        bvics_plan_cd_7char
        bvics_product_name
        admin_check_flg
        delete_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagBnfsQueryVariables,
  APITypes.ListTagBnfsQuery
>;
export const syncTagBnfs = /* GraphQL */ `query SyncTagBnfs(
  $filter: ModelTagBnfFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagBnfs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_id
      bnf_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnf {
        id
        seq_id
        tags {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnf_data_source
        obic7_commodity_cd
        obic7_commodity_name
        bvics_plan_cd_4char
        bvics_plan_cd_7char
        bvics_product_name
        admin_check_flg
        delete_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagBnfsQueryVariables,
  APITypes.SyncTagBnfsQuery
>;
export const searchTagBnfs = /* GraphQL */ `query SearchTagBnfs(
  $filter: SearchableTagBnfFilterInput
  $sort: [SearchableTagBnfSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagBnfAggregationInput]
) {
  searchTagBnfs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_id
      bnf_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnf {
        id
        seq_id
        tags {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnf_data_source
        obic7_commodity_cd
        obic7_commodity_name
        bvics_plan_cd_4char
        bvics_plan_cd_7char
        bvics_product_name
        admin_check_flg
        delete_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagBnfsQueryVariables,
  APITypes.SearchTagBnfsQuery
>;
export const getTagBnfHistory = /* GraphQL */ `query GetTagBnfHistory($id: ID!) {
  getTagBnfHistory(id: $id) {
    id
    operation_type
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    bnf_id
    bnf_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_bnf_data_source
    old_obic7_commodity_cd
    old_obic7_commodity_name
    old_bvics_plan_cd_4char
    old_bvics_plan_cd_7char
    old_bvics_product_name
    old_bnf_id
    old_bnf_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagBnfHistoryQueryVariables,
  APITypes.GetTagBnfHistoryQuery
>;
export const listTagBnfHistories = /* GraphQL */ `query ListTagBnfHistories(
  $filter: ModelTagBnfHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagBnfHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      operation_type
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      bnf_id
      bnf_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_bnf_data_source
      old_obic7_commodity_cd
      old_obic7_commodity_name
      old_bvics_plan_cd_4char
      old_bvics_plan_cd_7char
      old_bvics_product_name
      old_bnf_id
      old_bnf_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagBnfHistoriesQueryVariables,
  APITypes.ListTagBnfHistoriesQuery
>;
export const syncTagBnfHistories = /* GraphQL */ `query SyncTagBnfHistories(
  $filter: ModelTagBnfHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagBnfHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      operation_type
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      bnf_id
      bnf_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_bnf_data_source
      old_obic7_commodity_cd
      old_obic7_commodity_name
      old_bvics_plan_cd_4char
      old_bvics_plan_cd_7char
      old_bvics_product_name
      old_bnf_id
      old_bnf_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagBnfHistoriesQueryVariables,
  APITypes.SyncTagBnfHistoriesQuery
>;
export const searchTagBnfHistories = /* GraphQL */ `query SearchTagBnfHistories(
  $filter: SearchableTagBnfHistoryFilterInput
  $sort: [SearchableTagBnfHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagBnfHistoryAggregationInput]
) {
  searchTagBnfHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      operation_type
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      bnf_id
      bnf_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_bnf_data_source
      old_obic7_commodity_cd
      old_obic7_commodity_name
      old_bvics_plan_cd_4char
      old_bvics_plan_cd_7char
      old_bvics_product_name
      old_bnf_id
      old_bnf_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagBnfHistoriesQueryVariables,
  APITypes.SearchTagBnfHistoriesQuery
>;
export const getBsp = /* GraphQL */ `query GetBsp($id: ID!) {
  getBsp(id: $id) {
    id
    seq_id
    character_name
    character_code
    character_series_name
    character_series_code
    tags {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBspQueryVariables, APITypes.GetBspQuery>;
export const listBsps = /* GraphQL */ `query ListBsps($filter: ModelBspFilterInput, $limit: Int, $nextToken: String) {
  listBsps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      character_name
      character_code
      character_series_name
      character_series_code
      tags {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBspsQueryVariables, APITypes.ListBspsQuery>;
export const syncBsps = /* GraphQL */ `query SyncBsps(
  $filter: ModelBspFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBsps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      character_name
      character_code
      character_series_name
      character_series_code
      tags {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncBspsQueryVariables, APITypes.SyncBspsQuery>;
export const searchBsps = /* GraphQL */ `query SearchBsps(
  $filter: SearchableBspFilterInput
  $sort: [SearchableBspSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBspAggregationInput]
) {
  searchBsps(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      character_name
      character_code
      character_series_name
      character_series_code
      tags {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBspsQueryVariables,
  APITypes.SearchBspsQuery
>;
export const getBspHistory = /* GraphQL */ `query GetBspHistory($id: ID!) {
  getBspHistory(id: $id) {
    id
    bsp_id
    seq_id
    operation_type
    character_name
    character_code
    character_series_name
    character_series_code
    system_update_flg
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    createdAt
    updatedAt
    old_id
    old_bsp_id
    old_seq_id
    old_operation_type
    old_character_name
    old_character_code
    old_character_series_name
    old_character_series_code
    old_system_update_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBspHistoryQueryVariables,
  APITypes.GetBspHistoryQuery
>;
export const listBspHistories = /* GraphQL */ `query ListBspHistories(
  $filter: ModelBspHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBspHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bsp_id
      seq_id
      operation_type
      character_name
      character_code
      character_series_name
      character_series_code
      system_update_flg
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      old_id
      old_bsp_id
      old_seq_id
      old_operation_type
      old_character_name
      old_character_code
      old_character_series_name
      old_character_series_code
      old_system_update_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBspHistoriesQueryVariables,
  APITypes.ListBspHistoriesQuery
>;
export const syncBspHistories = /* GraphQL */ `query SyncBspHistories(
  $filter: ModelBspHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBspHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      bsp_id
      seq_id
      operation_type
      character_name
      character_code
      character_series_name
      character_series_code
      system_update_flg
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      old_id
      old_bsp_id
      old_seq_id
      old_operation_type
      old_character_name
      old_character_code
      old_character_series_name
      old_character_series_code
      old_system_update_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBspHistoriesQueryVariables,
  APITypes.SyncBspHistoriesQuery
>;
export const searchBspHistories = /* GraphQL */ `query SearchBspHistories(
  $filter: SearchableBspHistoryFilterInput
  $sort: [SearchableBspHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBspHistoryAggregationInput]
) {
  searchBspHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      bsp_id
      seq_id
      operation_type
      character_name
      character_code
      character_series_name
      character_series_code
      system_update_flg
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      old_id
      old_bsp_id
      old_seq_id
      old_operation_type
      old_character_name
      old_character_code
      old_character_series_name
      old_character_series_code
      old_system_update_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBspHistoriesQueryVariables,
  APITypes.SearchBspHistoriesQuery
>;
export const getTagBsp = /* GraphQL */ `query GetTagBsp($id: ID!) {
  getTagBsp(id: $id) {
    id
    tag_id
    bsp_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bsp {
      id
      seq_id
      character_name
      character_code
      character_series_name
      character_series_code
      tags {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagBspQueryVariables, APITypes.GetTagBspQuery>;
export const listTagBsps = /* GraphQL */ `query ListTagBsps(
  $filter: ModelTagBspFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagBsps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_id
      bsp_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bsp {
        id
        seq_id
        character_name
        character_code
        character_series_name
        character_series_code
        tags {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagBspsQueryVariables,
  APITypes.ListTagBspsQuery
>;
export const syncTagBsps = /* GraphQL */ `query SyncTagBsps(
  $filter: ModelTagBspFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagBsps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_id
      bsp_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bsp {
        id
        seq_id
        character_name
        character_code
        character_series_name
        character_series_code
        tags {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagBspsQueryVariables,
  APITypes.SyncTagBspsQuery
>;
export const searchTagBsps = /* GraphQL */ `query SearchTagBsps(
  $filter: SearchableTagBspFilterInput
  $sort: [SearchableTagBspSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagBspAggregationInput]
) {
  searchTagBsps(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_id
      bsp_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bsp {
        id
        seq_id
        character_name
        character_code
        character_series_name
        character_series_code
        tags {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagBspsQueryVariables,
  APITypes.SearchTagBspsQuery
>;
export const getTagBspHistory = /* GraphQL */ `query GetTagBspHistory($id: ID!) {
  getTagBspHistory(id: $id) {
    id
    operation_type
    character_name
    bsp_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_character_name
    old_bsp_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagBspHistoryQueryVariables,
  APITypes.GetTagBspHistoryQuery
>;
export const listTagBspHistories = /* GraphQL */ `query ListTagBspHistories(
  $filter: ModelTagBspHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagBspHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      operation_type
      character_name
      bsp_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_character_name
      old_bsp_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagBspHistoriesQueryVariables,
  APITypes.ListTagBspHistoriesQuery
>;
export const syncTagBspHistories = /* GraphQL */ `query SyncTagBspHistories(
  $filter: ModelTagBspHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagBspHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      operation_type
      character_name
      bsp_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_character_name
      old_bsp_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagBspHistoriesQueryVariables,
  APITypes.SyncTagBspHistoriesQuery
>;
export const searchTagBspHistories = /* GraphQL */ `query SearchTagBspHistories(
  $filter: SearchableTagBspHistoryFilterInput
  $sort: [SearchableTagBspHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagBspHistoryAggregationInput]
) {
  searchTagBspHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      operation_type
      character_name
      bsp_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_character_name
      old_bsp_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagBspHistoriesQueryVariables,
  APITypes.SearchTagBspHistoriesQuery
>;
export const getBnam = /* GraphQL */ `query GetBnam($id: ID!) {
  getBnam(id: $id) {
    id
    seq_id
    ip_name
    tags {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBnamQueryVariables, APITypes.GetBnamQuery>;
export const listBnams = /* GraphQL */ `query ListBnams(
  $filter: ModelBnamFilterInput
  $limit: Int
  $nextToken: String
) {
  listBnams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      ip_name
      tags {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBnamsQueryVariables, APITypes.ListBnamsQuery>;
export const syncBnams = /* GraphQL */ `query SyncBnams(
  $filter: ModelBnamFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBnams(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      ip_name
      tags {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncBnamsQueryVariables, APITypes.SyncBnamsQuery>;
export const searchBnams = /* GraphQL */ `query SearchBnams(
  $filter: SearchableBnamFilterInput
  $sort: [SearchableBnamSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBnamAggregationInput]
) {
  searchBnams(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      ip_name
      tags {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBnamsQueryVariables,
  APITypes.SearchBnamsQuery
>;
export const getBnamHistory = /* GraphQL */ `query GetBnamHistory($id: ID!) {
  getBnamHistory(id: $id) {
    id
    bnam_id
    seq_id
    operation_type
    ip_name
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    old_id
    old_bnam_id
    old_seq_id
    old_operation_type
    old_ip_name
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_admin_check_flg
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBnamHistoryQueryVariables,
  APITypes.GetBnamHistoryQuery
>;
export const listBnamHistories = /* GraphQL */ `query ListBnamHistories(
  $filter: ModelBnamHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBnamHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bnam_id
      seq_id
      operation_type
      ip_name
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      old_id
      old_bnam_id
      old_seq_id
      old_operation_type
      old_ip_name
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_admin_check_flg
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBnamHistoriesQueryVariables,
  APITypes.ListBnamHistoriesQuery
>;
export const syncBnamHistories = /* GraphQL */ `query SyncBnamHistories(
  $filter: ModelBnamHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBnamHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      bnam_id
      seq_id
      operation_type
      ip_name
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      old_id
      old_bnam_id
      old_seq_id
      old_operation_type
      old_ip_name
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_admin_check_flg
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBnamHistoriesQueryVariables,
  APITypes.SyncBnamHistoriesQuery
>;
export const searchBnamHistories = /* GraphQL */ `query SearchBnamHistories(
  $filter: SearchableBnamHistoryFilterInput
  $sort: [SearchableBnamHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBnamHistoryAggregationInput]
) {
  searchBnamHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      bnam_id
      seq_id
      operation_type
      ip_name
      update_reason
      updated_user_id
      updated_user_name
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      old_id
      old_bnam_id
      old_seq_id
      old_operation_type
      old_ip_name
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_delete_flg
      old_deleted_at
      old_admin_check_flg
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBnamHistoriesQueryVariables,
  APITypes.SearchBnamHistoriesQuery
>;
export const getTagBnam = /* GraphQL */ `query GetTagBnam($id: ID!) {
  getTagBnam(id: $id) {
    id
    tag_id
    bnam_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnam {
      id
      seq_id
      ip_name
      tags {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagBnamQueryVariables,
  APITypes.GetTagBnamQuery
>;
export const listTagBnams = /* GraphQL */ `query ListTagBnams(
  $filter: ModelTagBnamFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagBnams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_id
      bnam_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnam {
        id
        seq_id
        ip_name
        tags {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        delete_flg
        deleted_at
        admin_check_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagBnamsQueryVariables,
  APITypes.ListTagBnamsQuery
>;
export const syncTagBnams = /* GraphQL */ `query SyncTagBnams(
  $filter: ModelTagBnamFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagBnams(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_id
      bnam_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnam {
        id
        seq_id
        ip_name
        tags {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        delete_flg
        deleted_at
        admin_check_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagBnamsQueryVariables,
  APITypes.SyncTagBnamsQuery
>;
export const searchTagBnams = /* GraphQL */ `query SearchTagBnams(
  $filter: SearchableTagBnamFilterInput
  $sort: [SearchableTagBnamSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagBnamAggregationInput]
) {
  searchTagBnams(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_id
      bnam_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bnam {
        id
        seq_id
        ip_name
        tags {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        delete_flg
        deleted_at
        admin_check_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagBnamsQueryVariables,
  APITypes.SearchTagBnamsQuery
>;
export const getTagBnamHistory = /* GraphQL */ `query GetTagBnamHistory($id: ID!) {
  getTagBnamHistory(id: $id) {
    id
    operation_type
    ip_name
    tag_bnam_id
    bnam_id
    bnam_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_ip_name
    old_tag_bnam_id
    old_bnam_id
    old_bnam_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagBnamHistoryQueryVariables,
  APITypes.GetTagBnamHistoryQuery
>;
export const listTagBnamHistories = /* GraphQL */ `query ListTagBnamHistories(
  $filter: ModelTagBnamHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagBnamHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      operation_type
      ip_name
      tag_bnam_id
      bnam_id
      bnam_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_ip_name
      old_tag_bnam_id
      old_bnam_id
      old_bnam_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagBnamHistoriesQueryVariables,
  APITypes.ListTagBnamHistoriesQuery
>;
export const syncTagBnamHistories = /* GraphQL */ `query SyncTagBnamHistories(
  $filter: ModelTagBnamHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagBnamHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      operation_type
      ip_name
      tag_bnam_id
      bnam_id
      bnam_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_ip_name
      old_tag_bnam_id
      old_bnam_id
      old_bnam_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagBnamHistoriesQueryVariables,
  APITypes.SyncTagBnamHistoriesQuery
>;
export const searchTagBnamHistories = /* GraphQL */ `query SearchTagBnamHistories(
  $filter: SearchableTagBnamHistoryFilterInput
  $sort: [SearchableTagBnamHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagBnamHistoryAggregationInput]
) {
  searchTagBnamHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      operation_type
      ip_name
      tag_bnam_id
      bnam_id
      bnam_seq_id
      tag_id
      tag_name
      tag_name_en
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      old_id
      old_operation_type
      old_ip_name
      old_tag_bnam_id
      old_bnam_id
      old_bnam_seq_id
      old_tag_id
      old_tag_name
      old_tag_name_en
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagBnamHistoriesQueryVariables,
  APITypes.SearchTagBnamHistoriesQuery
>;
export const getTagTitle = /* GraphQL */ `query GetTagTitle($id: ID!) {
  getTagTitle(id: $id) {
    id
    tag_id
    title_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagTitleQueryVariables,
  APITypes.GetTagTitleQuery
>;
export const listTagTitles = /* GraphQL */ `query ListTagTitles(
  $filter: ModelTagTitleFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagTitles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_id
      title_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagTitlesQueryVariables,
  APITypes.ListTagTitlesQuery
>;
export const syncTagTitles = /* GraphQL */ `query SyncTagTitles(
  $filter: ModelTagTitleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagTitles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_id
      title_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagTitlesQueryVariables,
  APITypes.SyncTagTitlesQuery
>;
export const searchTagTitles = /* GraphQL */ `query SearchTagTitles(
  $filter: SearchableTagTitleFilterInput
  $sort: [SearchableTagTitleSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagTitleAggregationInput]
) {
  searchTagTitles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_id
      title_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagTitlesQueryVariables,
  APITypes.SearchTagTitlesQuery
>;
export const getTagTitleCode = /* GraphQL */ `query GetTagTitleCode($id: ID!) {
  getTagTitleCode(id: $id) {
    id
    tag_id
    title_code_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagTitleCodeQueryVariables,
  APITypes.GetTagTitleCodeQuery
>;
export const listTagTitleCodes = /* GraphQL */ `query ListTagTitleCodes(
  $filter: ModelTagTitleCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagTitleCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_id
      title_code_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagTitleCodesQueryVariables,
  APITypes.ListTagTitleCodesQuery
>;
export const syncTagTitleCodes = /* GraphQL */ `query SyncTagTitleCodes(
  $filter: ModelTagTitleCodeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagTitleCodes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_id
      title_code_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagTitleCodesQueryVariables,
  APITypes.SyncTagTitleCodesQuery
>;
export const searchTagTitleCodes = /* GraphQL */ `query SearchTagTitleCodes(
  $filter: SearchableTagTitleCodeFilterInput
  $sort: [SearchableTagTitleCodeSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagTitleCodeAggregationInput]
) {
  searchTagTitleCodes(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_id
      title_code_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagTitleCodesQueryVariables,
  APITypes.SearchTagTitleCodesQuery
>;
export const getTagTitleHistory = /* GraphQL */ `query GetTagTitleHistory($id: ID!) {
  getTagTitleHistory(id: $id) {
    id
    tag_title_id
    tag_id
    tag_name
    tag_name_en
    title_id
    title_name
    updated_user_id
    updated_user_name
    operation_type
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagTitleHistoryQueryVariables,
  APITypes.GetTagTitleHistoryQuery
>;
export const listTagTitleHistories = /* GraphQL */ `query ListTagTitleHistories(
  $filter: ModelTagTitleHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagTitleHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag_title_id
      tag_id
      tag_name
      tag_name_en
      title_id
      title_name
      updated_user_id
      updated_user_name
      operation_type
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagTitleHistoriesQueryVariables,
  APITypes.ListTagTitleHistoriesQuery
>;
export const syncTagTitleHistories = /* GraphQL */ `query SyncTagTitleHistories(
  $filter: ModelTagTitleHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagTitleHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_title_id
      tag_id
      tag_name
      tag_name_en
      title_id
      title_name
      updated_user_id
      updated_user_name
      operation_type
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagTitleHistoriesQueryVariables,
  APITypes.SyncTagTitleHistoriesQuery
>;
export const searchTagTitleHistories = /* GraphQL */ `query SearchTagTitleHistories(
  $filter: SearchableTagTitleHistoryFilterInput
  $sort: [SearchableTagTitleHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagTitleHistoryAggregationInput]
) {
  searchTagTitleHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_title_id
      tag_id
      tag_name
      tag_name_en
      title_id
      title_name
      updated_user_id
      updated_user_name
      operation_type
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagTitleHistoriesQueryVariables,
  APITypes.SearchTagTitleHistoriesQuery
>;
export const getTagTitleCodeHistory = /* GraphQL */ `query GetTagTitleCodeHistory($id: ID!) {
  getTagTitleCodeHistory(id: $id) {
    id
    tag_title_code_id
    tag_id
    tag_name
    tag_name_en
    title_code_id
    title_code_name
    updated_user_id
    updated_user_name
    operation_type
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagTitleCodeHistoryQueryVariables,
  APITypes.GetTagTitleCodeHistoryQuery
>;
export const listTagTitleCodeHistories = /* GraphQL */ `query ListTagTitleCodeHistories(
  $filter: ModelTagTitleCodeHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagTitleCodeHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tag_title_code_id
      tag_id
      tag_name
      tag_name_en
      title_code_id
      title_code_name
      updated_user_id
      updated_user_name
      operation_type
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTagTitleCodeHistoriesQueryVariables,
  APITypes.ListTagTitleCodeHistoriesQuery
>;
export const syncTagTitleCodeHistories = /* GraphQL */ `query SyncTagTitleCodeHistories(
  $filter: ModelTagTitleCodeHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTagTitleCodeHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tag_title_code_id
      tag_id
      tag_name
      tag_name_en
      title_code_id
      title_code_name
      updated_user_id
      updated_user_name
      operation_type
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTagTitleCodeHistoriesQueryVariables,
  APITypes.SyncTagTitleCodeHistoriesQuery
>;
export const searchTagTitleCodeHistories = /* GraphQL */ `query SearchTagTitleCodeHistories(
  $filter: SearchableTagTitleCodeHistoryFilterInput
  $sort: [SearchableTagTitleCodeHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTagTitleCodeHistoryAggregationInput]
) {
  searchTagTitleCodeHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tag_title_code_id
      tag_id
      tag_name
      tag_name_en
      title_code_id
      title_code_name
      updated_user_id
      updated_user_name
      operation_type
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTagTitleCodeHistoriesQueryVariables,
  APITypes.SearchTagTitleCodeHistoriesQuery
>;
export const getBnf = /* GraphQL */ `query GetBnf($id: ID!) {
  getBnf(id: $id) {
    id
    seq_id
    tags {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBnfQueryVariables, APITypes.GetBnfQuery>;
export const listBnfs = /* GraphQL */ `query ListBnfs($filter: ModelBnfFilterInput, $limit: Int, $nextToken: String) {
  listBnfs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      tags {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListBnfsQueryVariables, APITypes.ListBnfsQuery>;
export const syncBnfs = /* GraphQL */ `query SyncBnfs(
  $filter: ModelBnfFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBnfs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      tags {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncBnfsQueryVariables, APITypes.SyncBnfsQuery>;
export const searchBnfs = /* GraphQL */ `query SearchBnfs(
  $filter: SearchableBnfFilterInput
  $sort: [SearchableBnfSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBnfAggregationInput]
) {
  searchBnfs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      tags {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBnfsQueryVariables,
  APITypes.SearchBnfsQuery
>;
export const getBnfHistory = /* GraphQL */ `query GetBnfHistory($id: ID!) {
  getBnfHistory(id: $id) {
    id
    bnf_id
    seq_id
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    deleted_at
    operation_type
    old_id
    old_bnf_id
    old_seq_id
    old_bnf_data_source
    old_obic7_commodity_cd
    old_obic7_commodity_name
    old_bvics_plan_cd_4char
    old_bvics_plan_cd_7char
    old_bvics_product_name
    old_admin_check_flg
    old_delete_flg
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    old_deleted_at
    old_operation_type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBnfHistoryQueryVariables,
  APITypes.GetBnfHistoryQuery
>;
export const listBnfHistories = /* GraphQL */ `query ListBnfHistories(
  $filter: ModelBnfHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBnfHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bnf_id
      seq_id
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      deleted_at
      operation_type
      old_id
      old_bnf_id
      old_seq_id
      old_bnf_data_source
      old_obic7_commodity_cd
      old_obic7_commodity_name
      old_bvics_plan_cd_4char
      old_bvics_plan_cd_7char
      old_bvics_product_name
      old_admin_check_flg
      old_delete_flg
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      old_deleted_at
      old_operation_type
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBnfHistoriesQueryVariables,
  APITypes.ListBnfHistoriesQuery
>;
export const syncBnfHistories = /* GraphQL */ `query SyncBnfHistories(
  $filter: ModelBnfHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBnfHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      bnf_id
      seq_id
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      deleted_at
      operation_type
      old_id
      old_bnf_id
      old_seq_id
      old_bnf_data_source
      old_obic7_commodity_cd
      old_obic7_commodity_name
      old_bvics_plan_cd_4char
      old_bvics_plan_cd_7char
      old_bvics_product_name
      old_admin_check_flg
      old_delete_flg
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      old_deleted_at
      old_operation_type
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBnfHistoriesQueryVariables,
  APITypes.SyncBnfHistoriesQuery
>;
export const searchBnfHistories = /* GraphQL */ `query SearchBnfHistories(
  $filter: SearchableBnfHistoryFilterInput
  $sort: [SearchableBnfHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBnfHistoryAggregationInput]
) {
  searchBnfHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      bnf_id
      seq_id
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_user_name
      update_reason
      createdAt
      updatedAt
      deleted_at
      operation_type
      old_id
      old_bnf_id
      old_seq_id
      old_bnf_data_source
      old_obic7_commodity_cd
      old_obic7_commodity_name
      old_bvics_plan_cd_4char
      old_bvics_plan_cd_7char
      old_bvics_product_name
      old_admin_check_flg
      old_delete_flg
      old_updated_user_id
      old_updated_user_name
      old_update_reason
      old_createdAt
      old_updatedAt
      old_deleted_at
      old_operation_type
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBnfHistoriesQueryVariables,
  APITypes.SearchBnfHistoriesQuery
>;
export const getCsvJob = /* GraphQL */ `query GetCsvJob($id: ID!) {
  getCsvJob(id: $id) {
    id
    type
    name
    filepath
    status
    message
    table_name
    search_condition
    error_log
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCsvJobQueryVariables, APITypes.GetCsvJobQuery>;
export const listCsvJobs = /* GraphQL */ `query ListCsvJobs(
  $filter: ModelCsvJobFilterInput
  $limit: Int
  $nextToken: String
) {
  listCsvJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      name
      filepath
      status
      message
      table_name
      search_condition
      error_log
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCsvJobsQueryVariables,
  APITypes.ListCsvJobsQuery
>;
export const syncCsvJobs = /* GraphQL */ `query SyncCsvJobs(
  $filter: ModelCsvJobFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCsvJobs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      type
      name
      filepath
      status
      message
      table_name
      search_condition
      error_log
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCsvJobsQueryVariables,
  APITypes.SyncCsvJobsQuery
>;
export const searchCsvJobs = /* GraphQL */ `query SearchCsvJobs(
  $filter: SearchableCsvJobFilterInput
  $sort: [SearchableCsvJobSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCsvJobAggregationInput]
) {
  searchCsvJobs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      type
      name
      filepath
      status
      message
      table_name
      search_condition
      error_log
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCsvJobsQueryVariables,
  APITypes.SearchCsvJobsQuery
>;
export const getDataLakeJob = /* GraphQL */ `query GetDataLakeJob($id: ID!) {
  getDataLakeJob(id: $id) {
    id
    operation_type
    status
    message
    error_log
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataLakeJobQueryVariables,
  APITypes.GetDataLakeJobQuery
>;
export const listDataLakeJobs = /* GraphQL */ `query ListDataLakeJobs(
  $filter: ModelDataLakeJobFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataLakeJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      operation_type
      status
      message
      error_log
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataLakeJobsQueryVariables,
  APITypes.ListDataLakeJobsQuery
>;
export const syncDataLakeJobs = /* GraphQL */ `query SyncDataLakeJobs(
  $filter: ModelDataLakeJobFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDataLakeJobs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      operation_type
      status
      message
      error_log
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDataLakeJobsQueryVariables,
  APITypes.SyncDataLakeJobsQuery
>;
export const searchDataLakeJobs = /* GraphQL */ `query SearchDataLakeJobs(
  $filter: SearchableDataLakeJobFilterInput
  $sort: [SearchableDataLakeJobSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDataLakeJobAggregationInput]
) {
  searchDataLakeJobs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      operation_type
      status
      message
      error_log
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDataLakeJobsQueryVariables,
  APITypes.SearchDataLakeJobsQuery
>;
export const getDataLakeJobStatus = /* GraphQL */ `query GetDataLakeJobStatus($id: ID!) {
  getDataLakeJobStatus(id: $id) {
    id
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataLakeJobStatusQueryVariables,
  APITypes.GetDataLakeJobStatusQuery
>;
export const listDataLakeJobStatuses = /* GraphQL */ `query ListDataLakeJobStatuses(
  $filter: ModelDataLakeJobStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataLakeJobStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataLakeJobStatusesQueryVariables,
  APITypes.ListDataLakeJobStatusesQuery
>;
export const syncDataLakeJobStatuses = /* GraphQL */ `query SyncDataLakeJobStatuses(
  $filter: ModelDataLakeJobStatusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDataLakeJobStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDataLakeJobStatusesQueryVariables,
  APITypes.SyncDataLakeJobStatusesQuery
>;
export const searchDataLakeJobStatuses = /* GraphQL */ `query SearchDataLakeJobStatuses(
  $filter: SearchableDataLakeJobStatusFilterInput
  $sort: [SearchableDataLakeJobStatusSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDataLakeJobStatusAggregationInput]
) {
  searchDataLakeJobStatuses(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDataLakeJobStatusesQueryVariables,
  APITypes.SearchDataLakeJobStatusesQuery
>;
export const getCountry = /* GraphQL */ `query GetCountry($id: ID!) {
  getCountry(id: $id) {
    id
    order_id
    name
    name_en
    country_code
    code_two_char
    code_three_char
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCountryQueryVariables,
  APITypes.GetCountryQuery
>;
export const listCountries = /* GraphQL */ `query ListCountries(
  $filter: ModelCountryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCountriesQueryVariables,
  APITypes.ListCountriesQuery
>;
export const syncCountries = /* GraphQL */ `query SyncCountries(
  $filter: ModelCountryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCountries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCountriesQueryVariables,
  APITypes.SyncCountriesQuery
>;
export const searchCountries = /* GraphQL */ `query SearchCountries(
  $filter: SearchableCountryFilterInput
  $sort: [SearchableCountrySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCountryAggregationInput]
) {
  searchCountries(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCountriesQueryVariables,
  APITypes.SearchCountriesQuery
>;
export const getRate = /* GraphQL */ `query GetRate($id: ID!) {
  getRate(id: $id) {
    id
    seq_id
    source_currency_id
    converted_currency_id
    from_date
    to_date
    exchange_rate
    company_name
    usage
    description
    exchange_rate_type
    delete_flg
    deleted_at
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRateQueryVariables, APITypes.GetRateQuery>;
export const listRates = /* GraphQL */ `query ListRates(
  $filter: ModelRateFilterInput
  $limit: Int
  $nextToken: String
) {
  listRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      source_currency_id
      converted_currency_id
      from_date
      to_date
      exchange_rate
      company_name
      usage
      description
      exchange_rate_type
      delete_flg
      deleted_at
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRatesQueryVariables, APITypes.ListRatesQuery>;
export const syncRates = /* GraphQL */ `query SyncRates(
  $filter: ModelRateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      source_currency_id
      converted_currency_id
      from_date
      to_date
      exchange_rate
      company_name
      usage
      description
      exchange_rate_type
      delete_flg
      deleted_at
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncRatesQueryVariables, APITypes.SyncRatesQuery>;
export const searchRates = /* GraphQL */ `query SearchRates(
  $filter: SearchableRateFilterInput
  $sort: [SearchableRateSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableRateAggregationInput]
) {
  searchRates(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      source_currency_id
      converted_currency_id
      from_date
      to_date
      exchange_rate
      company_name
      usage
      description
      exchange_rate_type
      delete_flg
      deleted_at
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchRatesQueryVariables,
  APITypes.SearchRatesQuery
>;
export const getCalendar = /* GraphQL */ `query GetCalendar($id: ID!) {
  getCalendar(id: $id) {
    id
    year
    month
    day
    day_of_week
    calendar_date
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    name
    holiday_flg
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCalendarQueryVariables,
  APITypes.GetCalendarQuery
>;
export const listCalendars = /* GraphQL */ `query ListCalendars(
  $filter: ModelCalendarFilterInput
  $limit: Int
  $nextToken: String
) {
  listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      year
      month
      day
      day_of_week
      calendar_date
      country_id
      country {
        id
        order_id
        name
        name_en
        country_code
        code_two_char
        code_three_char
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      holiday_flg
      delete_flg
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCalendarsQueryVariables,
  APITypes.ListCalendarsQuery
>;
export const syncCalendars = /* GraphQL */ `query SyncCalendars(
  $filter: ModelCalendarFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCalendars(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      year
      month
      day
      day_of_week
      calendar_date
      country_id
      country {
        id
        order_id
        name
        name_en
        country_code
        code_two_char
        code_three_char
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      holiday_flg
      delete_flg
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCalendarsQueryVariables,
  APITypes.SyncCalendarsQuery
>;
export const searchCalendars = /* GraphQL */ `query SearchCalendars(
  $filter: SearchableCalendarFilterInput
  $sort: [SearchableCalendarSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCalendarAggregationInput]
) {
  searchCalendars(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      year
      month
      day
      day_of_week
      calendar_date
      country_id
      country {
        id
        order_id
        name
        name_en
        country_code
        code_two_char
        code_three_char
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      holiday_flg
      delete_flg
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCalendarsQueryVariables,
  APITypes.SearchCalendarsQuery
>;
export const getBusinessEntity = /* GraphQL */ `query GetBusinessEntity($id: ID!) {
  getBusinessEntity(id: $id) {
    id
    seq_id
    name
    description
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessEntityQueryVariables,
  APITypes.GetBusinessEntityQuery
>;
export const listBusinessEntities = /* GraphQL */ `query ListBusinessEntities(
  $filter: ModelBusinessEntityFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessEntitiesQueryVariables,
  APITypes.ListBusinessEntitiesQuery
>;
export const syncBusinessEntities = /* GraphQL */ `query SyncBusinessEntities(
  $filter: ModelBusinessEntityFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBusinessEntities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBusinessEntitiesQueryVariables,
  APITypes.SyncBusinessEntitiesQuery
>;
export const searchBusinessEntities = /* GraphQL */ `query SearchBusinessEntities(
  $filter: SearchableBusinessEntityFilterInput
  $sort: [SearchableBusinessEntitySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBusinessEntityAggregationInput]
) {
  searchBusinessEntities(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBusinessEntitiesQueryVariables,
  APITypes.SearchBusinessEntitiesQuery
>;
export const getGroupCompany = /* GraphQL */ `query GetGroupCompany($id: ID!) {
  getGroupCompany(id: $id) {
    id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_2
    management_business_1
    management_business_2
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    deleted_at
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupCompanyQueryVariables,
  APITypes.GetGroupCompanyQuery
>;
export const listGroupCompanies = /* GraphQL */ `query ListGroupCompanies(
  $filter: ModelGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroupCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      seq_id
      group_company_code
      valid_start_date
      valid_end_date
      stravis_code
      company_name_ja
      company_name_en
      abbreviation
      company_attribute
      consolidation
      equity_method_application
      unit_class_1
      unit_class_2
      management_business_1
      management_business_2
      country_id
      country {
        id
        order_id
        name
        name_en
        country_code
        code_two_char
        code_three_char
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      accounting_period
      public_or_private
      group_agreement_flg
      management_contract_flg
      subcontracting_contract_flg
      brand_portfolio
      holding_company_1
      investment_ratio_1
      holding_company_2
      investment_ratio_2
      holding_company_3
      investment_ratio_3
      holding_company_4
      investment_ratio_4
      holding_company_5
      investment_ratio_5
      total_investment_ratio
      issued_shares_total
      treasury_stock
      nominee_shares
      capital
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      number_of_employees
      establishment_date
      representative_director_1_position
      representative_director_1_name
      representative_director_2_position
      representative_director_2_name
      representative_director_3_position
      representative_director_3_name
      business_description
      address_1
      address_2
      notes
      ip_address
      delete_flg
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      deleted_at
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupCompaniesQueryVariables,
  APITypes.ListGroupCompaniesQuery
>;
export const syncGroupCompanies = /* GraphQL */ `query SyncGroupCompanies(
  $filter: ModelGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      seq_id
      group_company_code
      valid_start_date
      valid_end_date
      stravis_code
      company_name_ja
      company_name_en
      abbreviation
      company_attribute
      consolidation
      equity_method_application
      unit_class_1
      unit_class_2
      management_business_1
      management_business_2
      country_id
      country {
        id
        order_id
        name
        name_en
        country_code
        code_two_char
        code_three_char
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      accounting_period
      public_or_private
      group_agreement_flg
      management_contract_flg
      subcontracting_contract_flg
      brand_portfolio
      holding_company_1
      investment_ratio_1
      holding_company_2
      investment_ratio_2
      holding_company_3
      investment_ratio_3
      holding_company_4
      investment_ratio_4
      holding_company_5
      investment_ratio_5
      total_investment_ratio
      issued_shares_total
      treasury_stock
      nominee_shares
      capital
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      number_of_employees
      establishment_date
      representative_director_1_position
      representative_director_1_name
      representative_director_2_position
      representative_director_2_name
      representative_director_3_position
      representative_director_3_name
      business_description
      address_1
      address_2
      notes
      ip_address
      delete_flg
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      deleted_at
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGroupCompaniesQueryVariables,
  APITypes.SyncGroupCompaniesQuery
>;
export const searchGroupCompanies = /* GraphQL */ `query SearchGroupCompanies(
  $filter: SearchableGroupCompanyFilterInput
  $sort: [SearchableGroupCompanySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableGroupCompanyAggregationInput]
) {
  searchGroupCompanies(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      seq_id
      group_company_code
      valid_start_date
      valid_end_date
      stravis_code
      company_name_ja
      company_name_en
      abbreviation
      company_attribute
      consolidation
      equity_method_application
      unit_class_1
      unit_class_2
      management_business_1
      management_business_2
      country_id
      country {
        id
        order_id
        name
        name_en
        country_code
        code_two_char
        code_three_char
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      accounting_period
      public_or_private
      group_agreement_flg
      management_contract_flg
      subcontracting_contract_flg
      brand_portfolio
      holding_company_1
      investment_ratio_1
      holding_company_2
      investment_ratio_2
      holding_company_3
      investment_ratio_3
      holding_company_4
      investment_ratio_4
      holding_company_5
      investment_ratio_5
      total_investment_ratio
      issued_shares_total
      treasury_stock
      nominee_shares
      capital
      currency_id
      currency {
        id
        order_id
        currency_code
        code_three_char
        name
        name_en
        digit_number
        currency_symbol
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      number_of_employees
      establishment_date
      representative_director_1_position
      representative_director_1_name
      representative_director_2_position
      representative_director_2_name
      representative_director_3_position
      representative_director_3_name
      business_description
      address_1
      address_2
      notes
      ip_address
      delete_flg
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      deleted_at
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchGroupCompaniesQueryVariables,
  APITypes.SearchGroupCompaniesQuery
>;
export const getGroupCompanyHistory = /* GraphQL */ `query GetGroupCompanyHistory($id: ID!) {
  getGroupCompanyHistory(id: $id) {
    id
    operation_type
    group_company_id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_1_name
    unit_class_2
    unit_class_2_name
    management_business_1
    management_business_2
    country_id
    country_name
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency_name
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_user_name
    updatedAt
    createdAt
    old_id
    old_operation_type
    old_group_company_id
    old_seq_id
    old_group_company_code
    old_valid_start_date
    old_valid_end_date
    old_stravis_code
    old_company_name_ja
    old_company_name_en
    old_abbreviation
    old_company_attribute
    old_consolidation
    old_equity_method_application
    old_unit_class_1
    old_unit_class_1_name
    old_unit_class_2
    old_unit_class_2_name
    old_management_business_1
    old_management_business_2
    old_country_id
    old_country_name
    old_accounting_period
    old_public_or_private
    old_group_agreement_flg
    old_management_contract_flg
    old_subcontracting_contract_flg
    old_brand_portfolio
    old_holding_company_1
    old_investment_ratio_1
    old_holding_company_2
    old_investment_ratio_2
    old_holding_company_3
    old_investment_ratio_3
    old_holding_company_4
    old_investment_ratio_4
    old_holding_company_5
    old_investment_ratio_5
    old_total_investment_ratio
    old_issued_shares_total
    old_treasury_stock
    old_nominee_shares
    old_capital
    old_currency_id
    old_currency_name
    old_number_of_employees
    old_establishment_date
    old_representative_director_1_position
    old_representative_director_1_name
    old_representative_director_2_position
    old_representative_director_2_name
    old_representative_director_3_position
    old_representative_director_3_name
    old_business_description
    old_address_1
    old_address_2
    old_notes
    old_ip_address
    old_delete_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_updatedAt
    old_createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupCompanyHistoryQueryVariables,
  APITypes.GetGroupCompanyHistoryQuery
>;
export const listGroupCompanyHistories = /* GraphQL */ `query ListGroupCompanyHistories(
  $filter: ModelGroupCompanyHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroupCompanyHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      operation_type
      group_company_id
      seq_id
      group_company_code
      valid_start_date
      valid_end_date
      stravis_code
      company_name_ja
      company_name_en
      abbreviation
      company_attribute
      consolidation
      equity_method_application
      unit_class_1
      unit_class_1_name
      unit_class_2
      unit_class_2_name
      management_business_1
      management_business_2
      country_id
      country_name
      accounting_period
      public_or_private
      group_agreement_flg
      management_contract_flg
      subcontracting_contract_flg
      brand_portfolio
      holding_company_1
      investment_ratio_1
      holding_company_2
      investment_ratio_2
      holding_company_3
      investment_ratio_3
      holding_company_4
      investment_ratio_4
      holding_company_5
      investment_ratio_5
      total_investment_ratio
      issued_shares_total
      treasury_stock
      nominee_shares
      capital
      currency_id
      currency_name
      number_of_employees
      establishment_date
      representative_director_1_position
      representative_director_1_name
      representative_director_2_position
      representative_director_2_name
      representative_director_3_position
      representative_director_3_name
      business_description
      address_1
      address_2
      notes
      ip_address
      delete_flg
      update_reason
      updated_user_id
      updated_user_name
      updatedAt
      createdAt
      old_id
      old_operation_type
      old_group_company_id
      old_seq_id
      old_group_company_code
      old_valid_start_date
      old_valid_end_date
      old_stravis_code
      old_company_name_ja
      old_company_name_en
      old_abbreviation
      old_company_attribute
      old_consolidation
      old_equity_method_application
      old_unit_class_1
      old_unit_class_1_name
      old_unit_class_2
      old_unit_class_2_name
      old_management_business_1
      old_management_business_2
      old_country_id
      old_country_name
      old_accounting_period
      old_public_or_private
      old_group_agreement_flg
      old_management_contract_flg
      old_subcontracting_contract_flg
      old_brand_portfolio
      old_holding_company_1
      old_investment_ratio_1
      old_holding_company_2
      old_investment_ratio_2
      old_holding_company_3
      old_investment_ratio_3
      old_holding_company_4
      old_investment_ratio_4
      old_holding_company_5
      old_investment_ratio_5
      old_total_investment_ratio
      old_issued_shares_total
      old_treasury_stock
      old_nominee_shares
      old_capital
      old_currency_id
      old_currency_name
      old_number_of_employees
      old_establishment_date
      old_representative_director_1_position
      old_representative_director_1_name
      old_representative_director_2_position
      old_representative_director_2_name
      old_representative_director_3_position
      old_representative_director_3_name
      old_business_description
      old_address_1
      old_address_2
      old_notes
      old_ip_address
      old_delete_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_updatedAt
      old_createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupCompanyHistoriesQueryVariables,
  APITypes.ListGroupCompanyHistoriesQuery
>;
export const syncGroupCompanyHistories = /* GraphQL */ `query SyncGroupCompanyHistories(
  $filter: ModelGroupCompanyHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGroupCompanyHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      operation_type
      group_company_id
      seq_id
      group_company_code
      valid_start_date
      valid_end_date
      stravis_code
      company_name_ja
      company_name_en
      abbreviation
      company_attribute
      consolidation
      equity_method_application
      unit_class_1
      unit_class_1_name
      unit_class_2
      unit_class_2_name
      management_business_1
      management_business_2
      country_id
      country_name
      accounting_period
      public_or_private
      group_agreement_flg
      management_contract_flg
      subcontracting_contract_flg
      brand_portfolio
      holding_company_1
      investment_ratio_1
      holding_company_2
      investment_ratio_2
      holding_company_3
      investment_ratio_3
      holding_company_4
      investment_ratio_4
      holding_company_5
      investment_ratio_5
      total_investment_ratio
      issued_shares_total
      treasury_stock
      nominee_shares
      capital
      currency_id
      currency_name
      number_of_employees
      establishment_date
      representative_director_1_position
      representative_director_1_name
      representative_director_2_position
      representative_director_2_name
      representative_director_3_position
      representative_director_3_name
      business_description
      address_1
      address_2
      notes
      ip_address
      delete_flg
      update_reason
      updated_user_id
      updated_user_name
      updatedAt
      createdAt
      old_id
      old_operation_type
      old_group_company_id
      old_seq_id
      old_group_company_code
      old_valid_start_date
      old_valid_end_date
      old_stravis_code
      old_company_name_ja
      old_company_name_en
      old_abbreviation
      old_company_attribute
      old_consolidation
      old_equity_method_application
      old_unit_class_1
      old_unit_class_1_name
      old_unit_class_2
      old_unit_class_2_name
      old_management_business_1
      old_management_business_2
      old_country_id
      old_country_name
      old_accounting_period
      old_public_or_private
      old_group_agreement_flg
      old_management_contract_flg
      old_subcontracting_contract_flg
      old_brand_portfolio
      old_holding_company_1
      old_investment_ratio_1
      old_holding_company_2
      old_investment_ratio_2
      old_holding_company_3
      old_investment_ratio_3
      old_holding_company_4
      old_investment_ratio_4
      old_holding_company_5
      old_investment_ratio_5
      old_total_investment_ratio
      old_issued_shares_total
      old_treasury_stock
      old_nominee_shares
      old_capital
      old_currency_id
      old_currency_name
      old_number_of_employees
      old_establishment_date
      old_representative_director_1_position
      old_representative_director_1_name
      old_representative_director_2_position
      old_representative_director_2_name
      old_representative_director_3_position
      old_representative_director_3_name
      old_business_description
      old_address_1
      old_address_2
      old_notes
      old_ip_address
      old_delete_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_updatedAt
      old_createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGroupCompanyHistoriesQueryVariables,
  APITypes.SyncGroupCompanyHistoriesQuery
>;
export const searchGroupCompanyHistories = /* GraphQL */ `query SearchGroupCompanyHistories(
  $filter: SearchableGroupCompanyHistoryFilterInput
  $sort: [SearchableGroupCompanyHistorySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableGroupCompanyHistoryAggregationInput]
) {
  searchGroupCompanyHistories(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      operation_type
      group_company_id
      seq_id
      group_company_code
      valid_start_date
      valid_end_date
      stravis_code
      company_name_ja
      company_name_en
      abbreviation
      company_attribute
      consolidation
      equity_method_application
      unit_class_1
      unit_class_1_name
      unit_class_2
      unit_class_2_name
      management_business_1
      management_business_2
      country_id
      country_name
      accounting_period
      public_or_private
      group_agreement_flg
      management_contract_flg
      subcontracting_contract_flg
      brand_portfolio
      holding_company_1
      investment_ratio_1
      holding_company_2
      investment_ratio_2
      holding_company_3
      investment_ratio_3
      holding_company_4
      investment_ratio_4
      holding_company_5
      investment_ratio_5
      total_investment_ratio
      issued_shares_total
      treasury_stock
      nominee_shares
      capital
      currency_id
      currency_name
      number_of_employees
      establishment_date
      representative_director_1_position
      representative_director_1_name
      representative_director_2_position
      representative_director_2_name
      representative_director_3_position
      representative_director_3_name
      business_description
      address_1
      address_2
      notes
      ip_address
      delete_flg
      update_reason
      updated_user_id
      updated_user_name
      updatedAt
      createdAt
      old_id
      old_operation_type
      old_group_company_id
      old_seq_id
      old_group_company_code
      old_valid_start_date
      old_valid_end_date
      old_stravis_code
      old_company_name_ja
      old_company_name_en
      old_abbreviation
      old_company_attribute
      old_consolidation
      old_equity_method_application
      old_unit_class_1
      old_unit_class_1_name
      old_unit_class_2
      old_unit_class_2_name
      old_management_business_1
      old_management_business_2
      old_country_id
      old_country_name
      old_accounting_period
      old_public_or_private
      old_group_agreement_flg
      old_management_contract_flg
      old_subcontracting_contract_flg
      old_brand_portfolio
      old_holding_company_1
      old_investment_ratio_1
      old_holding_company_2
      old_investment_ratio_2
      old_holding_company_3
      old_investment_ratio_3
      old_holding_company_4
      old_investment_ratio_4
      old_holding_company_5
      old_investment_ratio_5
      old_total_investment_ratio
      old_issued_shares_total
      old_treasury_stock
      old_nominee_shares
      old_capital
      old_currency_id
      old_currency_name
      old_number_of_employees
      old_establishment_date
      old_representative_director_1_position
      old_representative_director_1_name
      old_representative_director_2_position
      old_representative_director_2_name
      old_representative_director_3_position
      old_representative_director_3_name
      old_business_description
      old_address_1
      old_address_2
      old_notes
      old_ip_address
      old_delete_flg
      old_update_reason
      old_updated_user_id
      old_updated_user_name
      old_updatedAt
      old_createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchGroupCompanyHistoriesQueryVariables,
  APITypes.SearchGroupCompanyHistoriesQuery
>;
export const getBneCustomerGroupCompany = /* GraphQL */ `query GetBneCustomerGroupCompany($id: ID!) {
  getBneCustomerGroupCompany(id: $id) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBneCustomerGroupCompanyQueryVariables,
  APITypes.GetBneCustomerGroupCompanyQuery
>;
export const listBneCustomerGroupCompanies = /* GraphQL */ `query ListBneCustomerGroupCompanies(
  $filter: ModelBneCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listBneCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      customer_cd
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBneCustomerGroupCompaniesQueryVariables,
  APITypes.ListBneCustomerGroupCompaniesQuery
>;
export const syncBneCustomerGroupCompanies = /* GraphQL */ `query SyncBneCustomerGroupCompanies(
  $filter: ModelBneCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBneCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      customer_cd
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBneCustomerGroupCompaniesQueryVariables,
  APITypes.SyncBneCustomerGroupCompaniesQuery
>;
export const searchBneCustomerGroupCompanies = /* GraphQL */ `query SearchBneCustomerGroupCompanies(
  $filter: SearchableBneCustomerGroupCompanyFilterInput
  $sort: [SearchableBneCustomerGroupCompanySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBneCustomerGroupCompanyAggregationInput]
) {
  searchBneCustomerGroupCompanies(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      customer_cd
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBneCustomerGroupCompaniesQueryVariables,
  APITypes.SearchBneCustomerGroupCompaniesQuery
>;
export const getBcCustomerGroupCompany = /* GraphQL */ `query GetBcCustomerGroupCompany($id: ID!) {
  getBcCustomerGroupCompany(id: $id) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBcCustomerGroupCompanyQueryVariables,
  APITypes.GetBcCustomerGroupCompanyQuery
>;
export const listBcCustomerGroupCompanies = /* GraphQL */ `query ListBcCustomerGroupCompanies(
  $filter: ModelBcCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listBcCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      souten_cd
      souten_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBcCustomerGroupCompaniesQueryVariables,
  APITypes.ListBcCustomerGroupCompaniesQuery
>;
export const syncBcCustomerGroupCompanies = /* GraphQL */ `query SyncBcCustomerGroupCompanies(
  $filter: ModelBcCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBcCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      souten_cd
      souten_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBcCustomerGroupCompaniesQueryVariables,
  APITypes.SyncBcCustomerGroupCompaniesQuery
>;
export const searchBcCustomerGroupCompanies = /* GraphQL */ `query SearchBcCustomerGroupCompanies(
  $filter: SearchableBcCustomerGroupCompanyFilterInput
  $sort: [SearchableBcCustomerGroupCompanySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBcCustomerGroupCompanyAggregationInput]
) {
  searchBcCustomerGroupCompanies(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      souten_cd
      souten_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBcCustomerGroupCompaniesQueryVariables,
  APITypes.SearchBcCustomerGroupCompaniesQuery
>;
export const getBnfCustomerGroupCompany = /* GraphQL */ `query GetBnfCustomerGroupCompany($id: ID!) {
  getBnfCustomerGroupCompany(id: $id) {
    id
    customer_code
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBnfCustomerGroupCompanyQueryVariables,
  APITypes.GetBnfCustomerGroupCompanyQuery
>;
export const listBnfCustomerGroupCompanies = /* GraphQL */ `query ListBnfCustomerGroupCompanies(
  $filter: ModelBnfCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listBnfCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      customer_code
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBnfCustomerGroupCompaniesQueryVariables,
  APITypes.ListBnfCustomerGroupCompaniesQuery
>;
export const syncBnfCustomerGroupCompanies = /* GraphQL */ `query SyncBnfCustomerGroupCompanies(
  $filter: ModelBnfCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBnfCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      customer_code
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBnfCustomerGroupCompaniesQueryVariables,
  APITypes.SyncBnfCustomerGroupCompaniesQuery
>;
export const searchBnfCustomerGroupCompanies = /* GraphQL */ `query SearchBnfCustomerGroupCompanies(
  $filter: SearchableBnfCustomerGroupCompanyFilterInput
  $sort: [SearchableBnfCustomerGroupCompanySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBnfCustomerGroupCompanyAggregationInput]
) {
  searchBnfCustomerGroupCompanies(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      customer_code
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBnfCustomerGroupCompaniesQueryVariables,
  APITypes.SearchBnfCustomerGroupCompaniesQuery
>;
export const getBspCustomerGroupCompany = /* GraphQL */ `query GetBspCustomerGroupCompany($id: ID!) {
  getBspCustomerGroupCompany(id: $id) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBspCustomerGroupCompanyQueryVariables,
  APITypes.GetBspCustomerGroupCompanyQuery
>;
export const listBspCustomerGroupCompanies = /* GraphQL */ `query ListBspCustomerGroupCompanies(
  $filter: ModelBspCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listBspCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      souten_cd
      souten_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBspCustomerGroupCompaniesQueryVariables,
  APITypes.ListBspCustomerGroupCompaniesQuery
>;
export const syncBspCustomerGroupCompanies = /* GraphQL */ `query SyncBspCustomerGroupCompanies(
  $filter: ModelBspCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBspCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      souten_cd
      souten_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBspCustomerGroupCompaniesQueryVariables,
  APITypes.SyncBspCustomerGroupCompaniesQuery
>;
export const searchBspCustomerGroupCompanies = /* GraphQL */ `query SearchBspCustomerGroupCompanies(
  $filter: SearchableBspCustomerGroupCompanyFilterInput
  $sort: [SearchableBspCustomerGroupCompanySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBspCustomerGroupCompanyAggregationInput]
) {
  searchBspCustomerGroupCompanies(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      souten_cd
      souten_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBspCustomerGroupCompaniesQueryVariables,
  APITypes.SearchBspCustomerGroupCompaniesQuery
>;
export const getBnmlCustomerGroupCompany = /* GraphQL */ `query GetBnmlCustomerGroupCompany($id: ID!) {
  getBnmlCustomerGroupCompany(id: $id) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBnmlCustomerGroupCompanyQueryVariables,
  APITypes.GetBnmlCustomerGroupCompanyQuery
>;
export const listBnmlCustomerGroupCompanies = /* GraphQL */ `query ListBnmlCustomerGroupCompanies(
  $filter: ModelBnmlCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listBnmlCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      customer_cd
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBnmlCustomerGroupCompaniesQueryVariables,
  APITypes.ListBnmlCustomerGroupCompaniesQuery
>;
export const syncBnmlCustomerGroupCompanies = /* GraphQL */ `query SyncBnmlCustomerGroupCompanies(
  $filter: ModelBnmlCustomerGroupCompanyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBnmlCustomerGroupCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      customer_cd
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBnmlCustomerGroupCompaniesQueryVariables,
  APITypes.SyncBnmlCustomerGroupCompaniesQuery
>;
export const searchBnmlCustomerGroupCompanies = /* GraphQL */ `query SearchBnmlCustomerGroupCompanies(
  $filter: SearchableBnmlCustomerGroupCompanyFilterInput
  $sort: [SearchableBnmlCustomerGroupCompanySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBnmlCustomerGroupCompanyAggregationInput]
) {
  searchBnmlCustomerGroupCompanies(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      customer_cd
      customer_name
      group_company_code
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBnmlCustomerGroupCompaniesQueryVariables,
  APITypes.SearchBnmlCustomerGroupCompaniesQuery
>;
