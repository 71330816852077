import { ProductTcd } from "../models";
import { ProductTcdSearch } from "../types/form/productTcd";
import { MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID } from "./common";

type ProductTcdField = Array<{
  field: keyof ProductTcd | "title_code_name" | "data_source_name",
  label: string,
  type: "text" | "select" | "date" | "datetime" | "autoComplete" | "checkbox",
  placeholder?: string,
  optionsName?: string,
  requiredDataSources?: string[],
  disabledDataSources?: string[],
  column?: number,
  required?: boolean,
  disabled?: boolean
  inputs?: Array<{ field: "from" | "to", label: string }>
  isBlank?: boolean
}>

export const CREATE_PRODUCT_TCD_FIELDS: ProductTcdField = [
  {
    field: "title_code_name",
    label: "title-code_title-code-name",
    type: "text",
    disabled: true,
    column: 1
  },
  {
    field: "data_source_name",
    label: "product_data-source-name",
    type: "text",
    disabled: true,
    column: 1
  },
  {
    field: "product_key",
    label: "product_product-key",
    type: "text",
    placeholder: "プロダクトキーを入力",
    required: true,
    column: 1
  },
  {
    field: "name",
    label: "product_product-name",
    type: "text",
    placeholder: "プロダクト名を入力",
    required: true,
    column: 1
  },
  {
    field: "common_name",
    label: "product_product-common-name",
    type: "text",
    placeholder: "プロダクト共通名を入力",
    column: 1
  },
  {
    field: "product_type_id",
    label: "product_product-type-name",
    type: "select",
    placeholder: "プロダクト種別名を選択",
    optionsName: "productType",
    required: true,
    column: 1
  },
  {
    field: "product_class_id",
    label: "product_product-specific-name",
    type: "select",
    placeholder: "プロダクト区別名を選択",
    optionsName: "productClass",
    required: true,
    column: 1
  },
  {
    field: "platform_id",
    label: "product_platform-name",
    type: "select",
    placeholder: "プラットフォーム名を選択",
    optionsName: "platform",
    required: true,
    column: 1
  },
  {
    field: "market",
    label: "product_market",
    type: "text",
    placeholder: "マーケットを入力",
    column: 1
  },
  {
    field: "base_price",
    label: "product_base-price",
    type: "text",
    placeholder: "ベースプライスを入力",
    column: 2
  },
  {
    field: "currency_id",
    label: "product_currency",
    type: "select",
    placeholder: "通貨を選択",
    optionsName: "currency",
    column: 2
  },
  {
    field: "release_status",
    label: "product_delivery-status",
    type: "text",
    placeholder: "配信ステータスを入力",
    column: 2
  },
  {
    field: "info_release_date",
    label: "product_date-of-first-information-release",
    type: "date",
    placeholder: "情報初出日を入力",
    column: 2
  },
  {
    field: "preorder_start_date",
    label: "product_contract-start-date",
    type: "date",
    placeholder: "予約開始日を入力",
    column: 2
  },
  {
    field: "release_date",
    label: "product_release-date",
    type: "date",
    placeholder: "発売日を入力",
    column: 2
  },
  {
    field: "service_start_date",
    label: "product_service-start-date",
    type: "date",
    placeholder: "サービス開始日を入力",
    column: 2
  },
  {
    field: "service_end_date",
    label: "product_service-end-date",
    type: "date",
    placeholder: "サービス終了日を入力",
    column: 2
  },
  {
    field: "support_end_date",
    label: "product_support-end-date",
    type: "date",
    placeholder: "サポート終了日を入力",
    column: 2
  },
  {
    field: "update_reason",
    label: "product_reason-for-update",
    type: "text",
    placeholder: "更新理由を入力",
    column: 3
  },
  {
    field: "sku_number_apple",
    label: "sku_number_apple",
    type: "text",
    disabled: true,
    column: 3
  },
  {
    field: "app_id",
    label: "App_ID",
    type: "text",
    disabled: true,
    column: 3
  },
  {
    field: "package_name_google",
    label: "package_name_google",
    type: "text",
    disabled: true,
    column: 3
  },
  {
    field: "bank_app_id",
    label: "bank_app_id",
    type: "text",
    disabled: true,
    column: 3
  }
];

export const EDIT_PRODUCT_TCD_FIELDS: ProductTcdField = [
  {
    field: "title_code",
    label: "title-code_title-code-name",
    type: "autoComplete",
    placeholder: "タイトルコード名称を入力",
    requiredDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 1
  },
  {
    field: "seq_id",
    label: "product_product-id",
    type: "text",
    requiredDataSources: [],
    disabledDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID],
    column: 1
  },
  {
    field: "product_key",
    label: "product_product-key",
    type: "text",
    placeholder: "プロダクトキーを入力",
    requiredDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 1
  },
  {
    field: "name",
    label: "product_product-name",
    type: "text",
    placeholder: "プロダクト名を入力",
    requiredDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 1
  },
  {
    field: "common_name",
    label: "product_product-common-name",
    type: "text",
    placeholder: "プロダクト共通名を入力",
    requiredDataSources: [],
    disabledDataSources: [],
    column: 1
  },
  {
    field: "product_type_id",
    label: "product_product-type-name",
    type: "select",
    placeholder: "プロダクト種別名を選択",
    optionsName: "productType",
    requiredDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 1
  },
  {
    field: "product_class_id",
    label: "product_product-specific-name",
    type: "select",
    placeholder: "プロダクト区別名を選択",
    optionsName: "productClass",
    requiredDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 1
  },
  {
    field: "platform_id",
    label: "product_platform-name",
    type: "select",
    placeholder: "プラットフォーム名を選択",
    optionsName: "platform",
    requiredDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 1
  },
  {
    field: "market",
    label: "product_market",
    type: "text",
    placeholder: "マーケットを入力",
    requiredDataSources: [],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 1
  },
  {
    field: "data_source_id",
    label: "product_data-source-name",
    type: "select",
    placeholder: "データソース名を選択",
    optionsName: "dataSource",
    requiredDataSources: [],
    disabledDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID],
    column: 2
  },
  {
    field: "base_price",
    label: "product_base-price",
    type: "text",
    placeholder: "ベースプライスを入力",
    requiredDataSources: [],
    disabledDataSources: [],
    column: 2
  },
  {
    field: "currency_id",
    label: "product_currency",
    type: "select",
    placeholder: "通貨を選択",
    optionsName: "currency",
    requiredDataSources: [],
    disabledDataSources: [],
    column: 2
  },
  {
    field: "release_status",
    label: "product_delivery-status",
    type: "text",
    placeholder: "配信ステータスを入力",
    requiredDataSources: [],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 2
  },
  {
    field: "info_release_date",
    label: "product_date-of-first-information-release",
    type: "date",
    placeholder: "情報初出日を入力",
    requiredDataSources: [],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 2
  },
  {
    field: "preorder_start_date",
    label: "product_contract-start-date",
    type: "date",
    placeholder: "予約開始日を入力",
    requiredDataSources: [],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 2
  },
  {
    field: "release_date",
    label: "product_release-date",
    type: "date",
    placeholder: "発売日を入力",
    requiredDataSources: [],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 2
  },
  {
    field: "service_start_date",
    label: "product_service-start-date",
    type: "date",
    placeholder: "サービス開始日を入力",
    requiredDataSources: [],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 2
  },
  {
    field: "service_end_date",
    label: "product_service-end-date",
    type: "date",
    placeholder: "サービス終了日を入力",
    requiredDataSources: [],
    disabledDataSources: [BANASUKE_SEQ_ID],
    column: 2
  },
  {
    field: "support_end_date",
    label: "product_support-end-date",
    type: "date",
    placeholder: "サポート終了日を入力",
    requiredDataSources: [],
    disabledDataSources: [],
    column: 3
  },
  {
    field: "sku_number_apple",
    label: "sku_number_apple",
    type: "text",
    requiredDataSources: [],
    disabledDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3
  },
  {
    field: "app_id",
    label: "App_ID",
    type: "text",
    requiredDataSources: [],
    disabledDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3
  },
  {
    field: "package_name_google",
    label: "package_name_google",
    type: "text",
    requiredDataSources: [],
    disabledDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3
  },
  {
    field: "bank_app_id",
    label: "bank_app_id",
    type: "text",
    placeholder: "bank_app_idを入力",
    requiredDataSources: [],
    disabledDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    column: 3
  },
  {
    field: "updated_user_id",
    label: "product_final-updater",
    type: "text",
    requiredDataSources: [],
    disabledDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3
  },
  {
    field: "updatedAt",
    label: "product_final-update-date",
    type: "datetime",
    requiredDataSources: [],
    disabledDataSources: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3
  },
  {
    field: "update_reason",
    label: "product_reason-for-registration-renewal",
    type: "text",
    placeholder: "登録更新理由を入力",
    requiredDataSources: [],
    disabledDataSources: [],
    column: 3
  },
  {
    field: "admin_check_flg",
    label: "product_admin-checked-flag",
    type: "checkbox",
    requiredDataSources: [],
    disabledDataSources: [],
    column: 3
  },
];

export const SEARCH_PRODUCT_TCD_ADMIN_FIELDS: ProductTcdField = [
  {
    field: "seq_id",
    label: "product_product-id",
    type: "text",
    placeholder: "プロダクトIDを入力",
  },
  {
    field: "product_key",
    label: "product_product-key",
    type: "text",
    placeholder: "プロダクトキーを入力",
  },
  {
    field: "name",
    label: "product_product-name",
    type: "text",
    placeholder: "プロダクト名を入力",
  },
  {
    field: "common_name",
    label: "product_product-common-name",
    type: "text",
    placeholder: "プロダクト共通名を入力",
  },
  {
    field: "title_code_id",
    label: "title-code_title-code-id",
    type: "text",
    placeholder: "タイトルコードIDを入力",
  },
  {
    field: "title_code",
    label: "title-code_title-code-name",
    type: "text",
    placeholder: "タイトルコード名称を入力",
  },
  {
    field: "product_type_id",
    label: "product_product-type-name",
    type: "select",
    placeholder: "プロダクト種別名を選択",
    optionsName: "product_type",
  },
  {
    field: "product_class_id",
    label: "product_product-specific-name",
    type: "select",
    placeholder: "プロダクト区別名を選択",
    optionsName: "product_class",
  },
  {
    field: "market",
    label: "product_market",
    type: "text",
    placeholder: "マーケットを入力",
  },
  {
    field: "platform_id",
    label: "product_platform-name",
    type: "select",
    placeholder: "プラットフォーム名を選択",
    optionsName: "platform",
  },
  {
    field: "data_source_id",
    label: "product_data-source-name",
    type: "select",
    placeholder: "データソース名を選択",
    optionsName: "data_source",
  },
  {
    field: "base_price",
    label: "",
    type: "text",
    placeholder: "価格を入力",
    inputs: [
      { field: "from", label: "product_base-price" },
      { field: "to", label: "product_base-price" },
    ],
  },
  {
    field: "currency_id",
    label: "product_currency",
    type: "select",
    placeholder: "通貨を選択",
    optionsName: "currency",
  },
  {
    field: "release_status",
    label: "product_delivery-status",
    type: "text",
    placeholder: "配信ステータスを入力",
  },
  {
    field: "info_release_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product_date-of-first-information-release" },
      { field: "to", label: "product_date-of-first-information-release" },
    ],
  },
  {
    field: "preorder_start_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product_contract-start-date" },
      { field: "to", label: "product_contract-start-date" },
    ],
  },
  {
    field: "release_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product_release-date" },
      { field: "to", label: "product_release-date" },
    ],
  },
  {
    field: "service_start_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product_service-start-date" },
      { field: "to", label: "product_service-start-date" },
    ],
  },
  {
    field: "service_end_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product_service-end-date" },
      { field: "to", label: "product_service-end-date" },
    ],
  },
  {
    field: "support_end_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product_support-end-date" },
      { field: "to", label: "product_support-end-date" },
    ],
  },
  {
    field: "sku_number_apple",
    label: "sku_number_apple",
    type: "text",
    placeholder: "sku_number_appleを入力",
  },
  {
    field: "app_id",
    label: "App_ID",
    type: "text",
    placeholder: "App_IDを入力",
  },
  {
    field: "package_name_google",
    label: "package_name_google",
    type: "text",
    placeholder: "package_name_googleを入力",
  },
  {
    field: "bank_app_id",
    label: "bank_app_id",
    type: "text",
    placeholder: "bank_app_idを入力",
  },
  {
    field: "updated_user_id",
    label: "product_final-updater",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product_final-update-date" },
      { field: "to", label: "product_final-update-date" },
    ],
  },
  {
    field: "update_reason",
    label: "product_reason-for-update",
    type: "text",
    placeholder: "更新理由を入力",
  },
  {
    field: "system_update_flg",
    label: "product_automatic-update-flag",
    type: "select",
    optionsName: "system_update_flg",
    isBlank: false,
  },
  {
    field: "admin_check_flg",
    label: "product_admin-checked-flag",
    type: "select",
    optionsName: "admin_check_flg",
    isBlank: false,
  },
  {
    field: "delete_flg",
    label: "product_deleted-data",
    type: "select",
    optionsName: "delete_flg",
    isBlank: false,
  },
];

export const SEARCH_PRODUCT_TCD_FIELDS_DEFAULT_VALUES: ProductTcdSearch = {
  seq_id: "",
  product_key: "",
  name: "",
  common_name: "",
  title_code_id: "",
  title_code: "",
  product_type_id: "",
  product_class_id: "",
  market: "",
  platform_id: "",
  data_source_id: "",
  base_price_from: "",
  base_price_to: "",
  currency_id: "",
  release_status: "",
  info_release_date_from: null,
  info_release_date_to: null,
  preorder_start_date_from: null,
  preorder_start_date_to: null,
  release_date_from: null,
  release_date_to: null,
  service_start_date_from: null,
  service_start_date_to: null,
  service_end_date_from: null,
  service_end_date_to: null,
  support_end_date_from: null,
  support_end_date_to: null,
  sku_number_apple: "",
  app_id: "",
  package_name_google: "",
  bank_app_id: "",
  updated_user_id: "",
  updatedAt_from: null,
  updatedAt_to: null,
  update_reason: "",
  system_update_flg: "ALL",
  admin_check_flg: true,
  delete_flg: false,
};
