import { useState } from 'react';
import { searchCalendars } from '../graphql/queries';
import usePageSize from './usePageSize';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import { Calendar, SearchableCalendarSortInput } from '../API';
import { SEARCH_CALENDAR_FIELDS_DEFAULT_VALUES } from '../consts/calendar';
import { CalendarSearch } from '../types/form/calendar';
import { INITIAL_EXP_ONLY_DEL_QUERIES_STATE } from '../consts/export';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = { direction: 'desc', field: 'calendar_date' };

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

const dataToFilterCalendarObj: StringKeyObj = {
  calendar_date_from: { name: 'calendar_date', type: SEARCH_TYPE.GTE },
  calendar_date_to: { name: 'calendar_date', type: SEARCH_TYPE.LTE },
  year: { name: 'year', type: SEARCH_TYPE.EQ },
  month: { name: 'month', type: SEARCH_TYPE.EQ },
  day: { name: 'day', type: SEARCH_TYPE.EQ },
  day_of_week: { name: 'day_of_week', type: SEARCH_TYPE.WILDCARD },
  holiday_flg: { name: 'holiday_flg', type: SEARCH_TYPE.EQ },
  name: { name: 'name', type: SEARCH_TYPE.WILDCARD },
  country_id: { name: 'country_id', type: SEARCH_TYPE.WILDCARD },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

export const useSearchCalendars = () => {
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<CalendarSearch>(SEARCH_CALENDAR_FIELDS_DEFAULT_VALUES);
  const [expQueries, setExpQueries] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >(INITIAL_EXP_ONLY_DEL_QUERIES_STATE);

  const fetchCalendar = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: CalendarSearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const records = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          if (value === 'ALL' && key === 'delete_flg') return;
          if (value === 'ALL' && key === 'holiday_flg') return;
          return {
            name: dataToFilterCalendarObj[key].name,
            value: value,
            type: dataToFilterCalendarObj[key].type,
          };
        })
        .filter(Boolean);
      const filter = generateSearchFilter(records);
      console.log('🚀 ~ file: useSearchCalendars.ts:67 ~ useSearchCalendars ~ filter:', filter);
      console.log('🚀 ~ file: useSearchCalendars.ts:75 ~ useSearchCalendars ~ newSort ?? sort:', newSort ?? sort);
      const models: any = await API.graphql({
        query: searchCalendars,
        variables: {
          ...filter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });
      console.log('🚀 ~ file: useSearchCalendars.ts:75 ~ useSearchCalendars ~ models:', models);

      setTotal(models.data.searchCalendars.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchCalendars.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchCalendars.nextToken])));
      }
      newCondition && setCondition({ ...newCondition });
      // exportで使用するクエリをセット
      newCondition && setExpQueries(records);
      setCalendars([...models.data.searchCalendars.items]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchCalendar({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchCalendar({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchCalendar({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchCalendar({ nextToken });
  };

  return {
    fetchCalendar,
    loading,
    total,
    pageSize,
    onChangePageSize,
    calendars,
    setCalendars,
    onChangePage,
    page,
    onChangeSort,
    condition,
    setCondition,
    expQueries,
    setExpQueries,
  };
};
