import { generateSearchFilter, SEARCH_TYPE } from './search';
import { searchBnmls, searchGroupCompanies } from '../graphql/queries';
import {
  searchTitles,
  searchGenres,
  searchPlatforms,
  searchProductTypes,
  searchProductClasses,
  searchCurrencies,
  searchBusinessEntities,
  searchTitleCodes,
  searchBsps,
  searchBnams,
  searchBnfs,
} from '../graphql/queries';
import { searchTagsMinimumRelations } from '../graphql/custom-queries';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

export const duplicateDetection = async (
  /** 重複チェックをするモデル名 **/
  schemaName:
    | 'TAG'
    | 'TITLE'
    | 'TITLE_CODE'
    | 'GENRE'
    | 'PLATFORM'
    | 'PRODUCT_TYPE'
    | 'PRODUCT_CLASS'
    | 'CURRENCY'
    | 'BUSINESS_ENTITY'
    | 'BNML'
    | 'BSP'
    | 'BNAM'
    | 'BNF'
    | 'GROUP_COMPANY',
  /** 重複チェックをするフィールド名の配列 **/
  targetFields: string[],
  /** react-hook-fromのdata **/
  formData: any,
  /** react-hook-fromのclearErrors **/
  clearErrors: Function,
  /** react-hook-fromのsetError **/
  setError: Function,
  /** 編集の場合のみ、対象のID **/
  currentRowId?: string
): Promise<boolean[]> => {
  const isDuplication = async (key: string, inputValue: string) => {
    let result = false;
    let queries = [{ name: key, value: inputValue, type: SEARCH_TYPE.EQ }];
    if (currentRowId) {
      queries.push({ name: 'id', value: currentRowId, type: SEARCH_TYPE.NE });
    }
    const filter = generateSearchFilter(queries);

    try {
      switch (schemaName) {
        case 'TAG':
          const tags: any = await API.graphql({
            query: searchTagsMinimumRelations,
            variables: {
              ...filter,
            },
          });
          if (tags.data.searchTags.total) {
            result = true;
          }
          break;
        case 'TITLE':
          const titles: any = await API.graphql({
            query: searchTitles,
            variables: {
              ...filter,
            },
          });
          if (titles.data.searchTitles.total) {
            result = true;
          }
          break;
        case 'TITLE_CODE':
          const titleCodes: any = await API.graphql({
            query: searchTitleCodes,
            variables: {
              ...filter,
            },
          });
          if (titleCodes.data.searchTitleCodes.total) {
            result = true;
          }
          break;
        case 'GENRE':
          const genres: any = await API.graphql({
            query: searchGenres,
            variables: {
              ...filter,
            },
          });
          if (genres.data.searchGenres.total) {
            result = true;
          }
          break;
        case 'PLATFORM':
          const platforms: any = await API.graphql({
            query: searchPlatforms,
            variables: {
              ...filter,
            },
          });
          if (platforms.data.searchPlatforms.total) {
            result = true;
          }
          break;
        case 'PRODUCT_TYPE':
          const prdTypes: any = await API.graphql({
            query: searchProductTypes,
            variables: {
              ...filter,
            },
          });
          if (prdTypes.data.searchProductTypes.total) {
            result = true;
          }
          break;
        case 'PRODUCT_CLASS':
          const prdClasses: any = await API.graphql({
            query: searchProductClasses,
            variables: {
              ...filter,
            },
          });
          if (prdClasses.data.searchProductClasses.total) {
            result = true;
          }
          break;
        case 'CURRENCY':
          const currencies: any = await API.graphql({
            query: searchCurrencies,
            variables: {
              ...filter,
              limit: 500,
            },
          });
          if (currencies.data.searchCurrencies.total) {
            result = true;
          }
          break;
        case 'BNML':
          const bnmls: any = await API.graphql({
            query: searchBnmls,
            variables: {
              ...filter,
              limit: 500,
            },
          });
          if (bnmls.data.searchBnmls.total) {
            result = true;
          }
          break;
        case 'BUSINESS_ENTITY':
          const businessEntitie: any = await API.graphql({
            query: searchBusinessEntities,
            variables: {
              ...filter,
              limit: 500,
            },
          });
          if (businessEntitie.data.searchBusinessEntities.total) {
            result = true;
          }
          break;
        case 'BNAM':
          const bnams: any = await API.graphql({
            query: searchBnams,
            variables: {
              ...filter,
              limit: 500,
            },
          });
          if (bnams.data.searchBnams.total) {
            result = true;
          }
          break;
        case 'BNF':
          const bnfs: any = await API.graphql({
            query: searchBnfs,
            variables: {
              ...filter,
            },
          });
          if (bnfs.data.searchBnfs.total) {
            result = true;
          }
          break;
        case 'GROUP_COMPANY':
          const groupCompanyFilter = generateSearchFilter([
            { name: 'group_company_code', value: formData['group_company_code'], type: SEARCH_TYPE.EQ },
            { name: 'valid_start_date', value: formData['valid_start_date'], type: SEARCH_TYPE.LTE },
            { name: 'valid_start_date', value: formData['valid_start_date'], type: SEARCH_TYPE.GTE },
            { name: 'id', value: currentRowId, type: SEARCH_TYPE.NE },
          ]);
          const groupCompanies: any = await API.graphql({
            query: searchGroupCompanies,
            variables: {
              ...groupCompanyFilter,
            },
          });
          if (groupCompanies.data.searchGroupCompanies.total) {
            result = true;
          }
          break;
        default:
          break;
      }
    } catch (e) {
      console.error(e);
    }
    return result;
  };

  const setCustomError = (field: string, message: string) => {
    setError(field, { message: message });
  };

  const results: boolean[] = [];
  await Promise.all(
    targetFields.map(async (field) => {
      if (!formData[field]) return;
      const result = await isDuplication(field, formData[field]);
      results.push(result);
      result ? setCustomError(field, '重複登録はできません') : clearErrors(field);
    })
  );

  return results;
};
