import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { theme } from '../assets/theme/options';
import { DataGridPro, GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import { Genre, DataSource, TitleCodeHistory, BusinessEntity, Platform } from '../models';
import { searchGenres, listDataSources, searchBusinessEntities, searchPlatforms } from '../graphql/queries';
import { convertOperationType } from '../utils/converter';
import { AuthContext } from '../contexts/AuthContext';
import { useEditedColumnClass, useIsDeletedOrRestoreColumn } from '../hooks/useEditedColumnClass';
import { filterColumnsForGeneralRole } from '../utils/filterColumns';
import { useSearchTitleCodeHistories } from '../hooks/useSearchTitleCodeHistories';
import SearchTitleCodeHistoryDialog from '../components/titleCodeHistories/SearchTitleCodeHistoryDialog';
import DetaiTitleCodeHistoryDialog from '../components/titleCodeHistories/DetailTitleCodeHistoryDialog';
import { generateClient } from 'aws-amplify/api';
import { SearchableBusinessEntitySortInput, SearchableGenreSortInput, SearchablePlatformSortInput } from '../API';
const API = generateClient();

const tranceArrayText = (params: any) => {
  if (params.value?.length > 0) {
    let arrayText = '';
    params.value.split(',').map((text: string) => {
      arrayText = `${arrayText}"${text}", `;
    });
    arrayText = arrayText.slice(0, -2);
    return `[${arrayText}]`;
  } else {
    return null;
  }
};

function TitleCodeHistoryList() {
  const columns: GridColDef[] = [
    {
      field: 'operation_type',
      headerName: '操作種別',
      width: 210,
      valueFormatter: (params: any) => {
        return convertOperationType[params.value];
      }
    },
    { field: 'seq_id', headerName: 'タイトルコードID', width: 210 },
    {
      field: 'title_code',
      headerName: 'タイトルコード',
      width: 210,
      cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
    },
    {
      field: 'name_ja',
      headerName: 'タイトルコード名称(日本語)',
      width: 210,
      cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
    },
    {
      field: 'name_en',
      headerName: 'タイトルコード名称(英語)',
      width: 210,
      cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
    },
    {
      field: 'official_name_flg',
      headerName: 'タイトルコード名称 正式フラグ',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
      renderCell: ({ value }) => {
        return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
      }
    },
    {
      field: 'sap_title_code_name',
      headerName: 'SAPタイトルコード用名称',
      width: 210,
      cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
    },
    {
      field: 'search_name',
      headerName: '検索用タイトルコード名称',
      width: 210,
      cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
    },
    {
      field: 'project_cd',
      headerName: '関連プロジェクトコード',
      width: 210,
      cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
      valueFormatter: (params: any) => {
        return tranceArrayText(params);
      }
    },
    {
      field: 'wbs',
      headerName: '関連WBS',
      width: 210,
      cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
      valueFormatter: (params: any) => {
        return tranceArrayText(params);
      }
    },
    {
      field: 'bn_connect_title_ids',
      headerName: 'BN-ConnectタイトルID',
      width: 210,
      cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
      valueFormatter: (params: any) => {
        return tranceArrayText(params);
      }
    },
    {
      field: 'ce_link_key_code',
      headerName: 'CE-LINKキーコード',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
    },
    {
      field: 'ce_link_title_name_ja',
      headerName: 'CE-LINKキーコード名称(日本語)',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
    },
    {
      field: 'ce_link_title_name_en',
      headerName: 'CE-LINKキーコード名称(英語)',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
    },
    {
      field: 'genre_names',
      headerName: 'ジャンル',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
      valueFormatter: (params: any) => {
        return tranceArrayText(params);
      }
    },
    {
      field: 'banasuke_title_id',
      headerName: 'バナスケタイトルID',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
    },
    {
      field: 'banasuke_title_name_ja',
      headerName: 'バナスケタイトル名称（日本語）',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
    },
    {
      field: 'bundle_source_title_code_ids',
      headerName: 'バンドル元タイトルコードID',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
      valueFormatter: (params: any) => {
        return tranceArrayText(params);
      }
    },
    {
      field: 'porting_source_title_code_ids',
      headerName: '移植元タイトルコードID',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
      valueFormatter: (params: any) => {
        return tranceArrayText(params);
      }
    },
    {
      field: 'platform_names',
      headerName: 'PF（プラットフォーム）',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
      valueFormatter: (params: any) => {
        return tranceArrayText(params);
      }
    },
    {
      field: 'valid_flg',
      headerName: '有効フラグ',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
      renderCell: ({ value }) => {
        return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
      }
    },
    {
      field: 'business_entity_name',
      headerName: '事業体',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
    },
    {
      field: 'data_source_name',
      headerName: 'データソース名',
      width: 210
    },
    {
      field: 'system_update_flg',
      headerName: '自動更新フラグ',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
      renderCell: ({ value }) => {
        return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
      }
    },
    {
      field: 'admin_check_flg',
      headerName: '管理者チェック済フラグ',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
      renderCell: ({ value }) => {
        return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
      }
    },
    {
      field: 'delete_flg',
      headerName: '削除済データ',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useIsDeletedOrRestoreColumn(params),
      renderCell: ({ value }) => {
        return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
      }
    },
    {
      field: 'update_reason',
      headerName: '更新理由',
      width: 210,
      cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
    },
    {
      field: 'updated_user_name',
      headerName: '最終更新者',
      width: 210,
      valueGetter: (params) => {
        return params.row.updated_user_name;
      }
    },
    {
      field: 'updatedAt',
      headerName: '最終更新日時',
      width: 210,
      valueFormatter: (params: any) => {
        return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
      }
    }
  ];

  const [openSearch, setOpenSearch] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [row, setRow] = useState<TitleCodeHistory | null>(null);
  const [genres, setGenres] = useState<Genre[]>([]);
  const [businessEntity, setBusinessEntity] = useState<BusinessEntity[]>([]);
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const roleTypes = useContext(AuthContext).roles?.map((role) => role.role?.label);

  const {
    fetchTitleCodeHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    titleCodeHistories,
    setTitleCodeHistories,
    onChangePage,
    page,
    onChangeSort,
    condition
  } = useSearchTitleCodeHistories();

  useEffect(() => {
    fetchTitleCodeHistories({});
    fetchGenres();
    fetchDataSources();
    fetchPlatforms();
    fetchBusinessEntity();
    // eslint-disable-next-line
  }, []);

  // 一般ユーザの時は管理者チェックフラグのカラムを非表示にする
  const removeColumns = ['admin_check_flg'];
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns);

  const fetchGenres = async () => {
    try {
      const models: any = await API.graphql({
        query: searchGenres,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setGenres(models.data.searchGenres.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchBusinessEntity = useCallback(async () => {
    try {
      const models: any = await API.graphql({
        query: searchBusinessEntities,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setBusinessEntity(models.data.searchBusinessEntities.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchPlatforms = useCallback(async () => {
    try {
      const models: any = await API.graphql({
        query: searchPlatforms,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setPlatforms(models.data.searchPlatforms.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchDataSources = async () => {
    try {
      const models: any = await API.graphql({ query: listDataSources });
      // memo: searchクエリがないのでフロントでseq_idを利用してsort
      const sorted = (models.data as any).listDataSources.items.sort(
        (a: any, b: any) => a.seq_id.replace(/[^0-9^\.]/g, '') - b.seq_id.replace(/[^0-9^\.]/g, '')
      );
      setDataSources(sorted);
    } catch (e) {
      console.log(e);
    }
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const doOpenDetail = (params: any) => {
    setRow(params.row);
    setOpenDetail(true);
  };

  if (!roleTypes) return <></>;
  console.log({ titleCodeHistories });
  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={titleCodeHistories}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onRowClick={doOpenDetail}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            },
            '& .edited_cell': {
              background: theme.colors.editedCell
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openSearch && (
        <SearchTitleCodeHistoryDialog
          condition={condition}
          dataSources={dataSources}
          genres={genres}
          businessEntity={businessEntity}
          platforms={platforms}
          isAdmin={roleTypes.includes('ADMIN')}
          open={openSearch}
          setOpen={setOpenSearch}
          fetchTitleCodeHistories={fetchTitleCodeHistories}
        />
      )}
      {openDetail && row && (
        <DetaiTitleCodeHistoryDialog open={openDetail} row={row} setOpen={setOpenDetail} setRow={setRow} />
      )}
    </Box>
  );
}

export default TitleCodeHistoryList;
