import { useState } from 'react';
import { searchTagBnfHistories } from '../graphql/queries';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import usePageSize from './usePageSize';
import { TagBnfHistorySearch } from '../types/form/tagBnfHistory';
import { SEARCH_TAG_BNF_HISTORY_FIELDS_DEFAULT_VALUES } from '../consts/tagBnfHistory';
import { TagBnfHistory } from '../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = { direction: 'desc', field: 'updatedAt' };

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

const dataToFilterObj: { [key: string]: { name: string; type: string } } = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.EQ },
  bnf_seq_id: { name: 'bnf_seq_id', type: SEARCH_TYPE.WILDCARD },
  tag_name: { name: 'tag_name', type: SEARCH_TYPE.WILDCARD },
  tag_name_en: { name: 'tag_name_en', type: SEARCH_TYPE.WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  updated_user_name: { name: 'updated_user_name', type: SEARCH_TYPE.WILDCARD },
  bnf_data_source: { name: 'bnf_seq_id', type: SEARCH_TYPE.WILDCARD },
  obic7_commodity_cd: { name: 'bnf_seq_id', type: SEARCH_TYPE.WILDCARD },
  obic7_commodity_name: { name: 'bnf_seq_id', type: SEARCH_TYPE.WILDCARD },
  bvics_plan_cd_4char: { name: 'bnf_seq_id', type: SEARCH_TYPE.WILDCARD },
  bvics_plan_cd_7char: { name: 'bnf_seq_id', type: SEARCH_TYPE.WILDCARD },
  bvics_product_name: { name: 'bnf_seq_id', type: SEARCH_TYPE.WILDCARD },
};

export const useSearchTagBnfHistories = () => {
  const [tagBnfHistories, setTagBnfHistories] = useState<TagBnfHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<TagBnfHistorySearch>(SEARCH_TAG_BNF_HISTORY_FIELDS_DEFAULT_VALUES);

  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();

  const fetchTagBnfHistories = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: TagBnfHistorySearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const records = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          console.log(key);
          if (value === 'ALL' && key === 'delete_flg') return;
          return {
            name: dataToFilterObj[key].name,
            value: value,
            type: dataToFilterObj[key].type,
          };
        })
        .filter(Boolean);
      console.log(records);
      const filter = generateSearchFilter(records);
      const models: any = await API.graphql({
        query: searchTagBnfHistories,
        variables: {
          ...filter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });

      setTotal(models.data.searchTagBnfHistories.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchTagBnfHistories.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchTagBnfHistories.nextToken])));
      }

      setTagBnfHistories([...models.data.searchTagBnfHistories.items]);
      newCondition && setCondition({ ...newCondition });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchTagBnfHistories({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchTagBnfHistories({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchTagBnfHistories({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchTagBnfHistories({ nextToken });
  };

  return {
    fetchTagBnfHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    tagBnfHistories,
    setTagBnfHistories,
    onChangePage,
    condition,
    setCondition,
    page,
    onChangeSort,
  };
};
