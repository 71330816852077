import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { theme } from '../assets/theme/options';
import { DataGridPro, GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import SearchTitleHistoryDialog from '../components/titleHistories/SearchTitleHistoryDialog';
import DetaiTitleHistoryDialog from '../components/titleHistories/DetailTitleHistoryDialog';
import { TitleHistory, Genre, DataSource } from '../models';
import { searchGenres, listDataSources } from '../graphql/queries';
import { convertOperationType } from '../utils/converter';
import { AuthContext } from '../contexts/AuthContext';
import { useEditedColumnClass, useIsDeletedOrRestoreColumn } from '../hooks/useEditedColumnClass';
import { useSearchTitleHistories } from '../hooks/useSearchTitleHistories';
import { filterColumnsForGeneralRole } from '../utils/filterColumns';

import { generateClient } from 'aws-amplify/api';
import { SearchableGenreSortInput } from '../API';
const API = generateClient();

const columns: GridColDef[] = [
  {
    field: 'operation_type',
    headerName: '操作種別',
    width: 210,
    valueFormatter: (params: any) => {
      return convertOperationType[params.value];
    }
  },
  { field: 'seq_id', headerName: 'タイトルID', width: 210 },
  {
    field: 'name_ja',
    headerName: 'タイトル名（日本語）',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'name_en',
    headerName: 'タイトル名（英語）',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'search_name',
    headerName: '検索用タイトル名称',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'genre_name',
    headerName: 'ジャンル',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
    valueFormatter: (params: any) => {
      return tranceArrayText(params);
    }
  },
  {
    field: 'project_cd',
    headerName: 'プロジェクト定義',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
    valueFormatter: (params: any) => {
      return tranceArrayText(params);
    }
  },
  {
    field: 'sap_title_code',
    headerName: 'SAPタイトルコード',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
    valueFormatter: (params: any) => {
      return tranceArrayText(params);
    }
  },
  {
    field: 'bn_connect_title_id',
    headerName: 'BN-ConnectタイトルID',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
    valueFormatter: (params: any) => {
      return tranceArrayText(params);
    }
  },
  {
    field: 'banasuke_title_id',
    headerName: 'バナスケタイトルID',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
  },
  {
    field: 'ce_link_key_code',
    headerName: 'CE-LINKキーコード',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
  },
  {
    field: 'data_source_name',
    headerName: 'データソース名',
    width: 210
  },
  {
    field: 'system_update_flg',
    headerName: '自動更新フラグ',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
    renderCell: ({ value }) => {
      return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'admin_check_flg',
    headerName: '管理者チェック済フラグ',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params),
    renderCell: ({ value }) => {
      return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useIsDeletedOrRestoreColumn(params),
    renderCell: ({ value }) => {
      return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'update_reason',
    headerName: '更新理由',
    width: 210,
    cellClassName: (params: GridCellParams<string>) => useEditedColumnClass(params)
  },
  {
    field: 'updated_user_name',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => {
      return params.row.updated_user_name;
    }
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

const tranceArrayText = (params: any) => {
  if (params.value?.length > 0) {
    let arrayText = '';
    params.value.split(',').map((text: string) => {
      arrayText = `${arrayText}"${text}", `;
    });
    arrayText = arrayText.slice(0, -2);
    return `[${arrayText}]`;
  } else {
    return null;
  }
};

function TitleHistoryList() {
  const [openSearch, setOpenSearch] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [row, setRow] = useState<TitleHistory | null>(null);
  const [genres, setGenres] = useState<Genre[]>([]);
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const roleTypes = useContext(AuthContext).roles?.map((role) => role.role?.label);

  const {
    fetchTagTitleHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    tagTitleHistories,
    onChangePage,
    page,
    onChangeSort,
    condition
  } = useSearchTitleHistories();

  useEffect(() => {
    fetchTagTitleHistories({});
    fetchGenres();
    fetchDataSources();
    // eslint-disable-next-line
  }, []);

  // 一般ユーザの時は管理者チェックフラグのカラムを非表示にする
  const removeColumns = ['admin_check_flg'];
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns);

  const fetchGenres = async () => {
    try {
      const models: any = await API.graphql({
        query: searchGenres,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setGenres(models.data.searchGenres.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDataSources = async () => {
    try {
      const models: any = await API.graphql({ query: listDataSources });
      // memo: searchクエリがないのでフロントでseq_idを利用してsort
      const sorted = (models.data as any).listDataSources.items.sort(
        (a: any, b: any) => a.seq_id.replace(/[^0-9^\.]/g, '') - b.seq_id.replace(/[^0-9^\.]/g, '')
      );
      setDataSources(sorted);
    } catch (e) {
      console.log(e);
    }
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const doOpenDetail = (params: any) => {
    setRow(params.row);
    setOpenDetail(true);
  };

  if (!roleTypes) return <></>;
  console.log({ tagTitleHistories });
  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={tagTitleHistories}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onRowClick={doOpenDetail}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            },
            '& .edited_cell': {
              background: theme.colors.editedCell
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openSearch && (
        <SearchTitleHistoryDialog
          condition={condition}
          dataSources={dataSources}
          genres={genres}
          isAdmin={roleTypes.includes('ADMIN')}
          open={openSearch}
          setOpen={setOpenSearch}
          fetchTagTitleHistories={fetchTagTitleHistories}
        />
      )}
      {openDetail && row && (
        <DetaiTitleHistoryDialog open={openDetail} row={row} setOpen={setOpenDetail} setRow={setRow} />
      )}
    </Box>
  );
}

export default TitleHistoryList;
