import React, { memo } from 'react';
import { Button, ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
  height?: number | string
  width?: number | string
  disabled?: boolean
}

function PrimaryButton(props: Props) {
  const { children, onClick, height = 40, width = 93, variant = "contained" } = props

  return (
    <Button {...props} variant={variant} onClick={onClick} sx={{ height, width }} disabled={props.disabled ?? false}>
      {children}
    </Button>
  )
}

export default memo(PrimaryButton)