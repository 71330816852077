import { TitleCode } from '../models';
import { SearchConditionTitleCode } from '../types/form/titleCode';
import {
  MASTER_SYSTEM_SEQ_ID,
  CE_LINK_SEQ_ID,
  BANASUKE_SEQ_ID,
} from './common';

export const VALIDATE_DUPLICATE_TITLE_CODE_FIELDS = [
  'banasuke_title_id',
  'ce_link_key_code',
];

export const TITLE_CODE_RESET_VALUES: {
  field: keyof TitleCode;
  value: { defaultValue: string | boolean | { value: string }[] };
}[] = [
  { field: 'title_code', value: { defaultValue: '' } },
  { field: 'name_ja', value: { defaultValue: '' } },
  { field: 'name_en', value: { defaultValue: '' } },
  { field: 'official_name_flg', value: { defaultValue: false } },
  { field: 'sap_title_code_name', value: { defaultValue: '' } },
  { field: 'search_name', value: { defaultValue: '' } },
  { field: 'ce_link_key_code', value: { defaultValue: '' } },
  { field: 'ce_link_title_name_ja', value: { defaultValue: '' } },
  { field: 'ce_link_title_name_en', value: { defaultValue: '' } },
  { field: 'genre_ids', value: { defaultValue: [] } },
  { field: 'banasuke_title_id', value: { defaultValue: '' } },
  { field: 'banasuke_title_name_ja', value: { defaultValue: '' } },
  { field: 'business_entity_id', value: { defaultValue: '' } },
  { field: 'platform_ids', value: { defaultValue: [] } },
  { field: 'update_reason', value: { defaultValue: '' } },
];

type TitleCodeField = Array<{
  field: keyof TitleCode;
  label: string;
  type:
    | 'radio'
    | 'text'
    | 'checkbox'
    | 'arrayText'
    | 'chip'
    | 'select'
    | 'datetime';
  placeholder?: string;
  optionsName?: string;
  column?: number;
  required?: boolean;
  typeIds?: string[];
  isBlank?: boolean;
  inputs?: Array<{ field: 'from' | 'to'; label: 'title_final-update-date' }>;
}>;

export const CREATE_TITLE_CODE_FIELDS: TitleCodeField = [
  {
    field: 'data_source_id',
    label: 'title_data-source-id',
    type: 'radio',
    optionsName: 'dataSource',
    column: 1,
  },
  {
    field: 'title_code',
    label: 'title-code_title-code',
    type: 'text',
    placeholder: 'タイトルコードを入力',
    required: true,
    column: 1,
  },
  {
    field: 'name_ja',
    label: 'title-code_title-code-name-japanese',
    type: 'text',
    placeholder: 'タイトルコード名称(日本語)を入力',
    column: 1,
  },
  {
    field: 'name_en',
    label: 'title-code_title-code-name-english',
    type: 'text',
    placeholder: 'タイトルコード名称(英語)を入力',
    column: 1,
  },
  {
    field: 'official_name_flg',
    label: 'title-code_official-name-flg',
    required: true,
    type: 'checkbox',
    column: 1,
  },
  {
    field: 'sap_title_code_name',
    label: 'title-code_sap-title-code',
    type: 'text',
    placeholder: 'SAPタイトルコード用名称を入力',
    column: 1,
  },
  {
    field: 'search_name',
    label: 'title-code_search-name',
    type: 'text',
    placeholder: '検索用タイトルコード名称を入力',
    column: 1,
  },
  {
    field: 'project_cd',
    label: 'title-code_project-cd',
    type: 'arrayText',
    placeholder: 'コードを入力',
    column: 2,
  },
  {
    field: 'wbs',
    label: 'title-code_wbs',
    type: 'arrayText',
    placeholder: '関連WBSを入力',
    column: 2,
  },
  {
    field: 'bn_connect_title_ids',
    label: 'title-code_bn_connect-title-ids',
    type: 'arrayText',
    placeholder: 'BN-ConnectタイトルIDを入力',
    column: 2,
  },
  {
    field: 'ce_link_key_code',
    label: 'title-code_ce-link-key-code',
    type: 'text',
    placeholder: 'CE-LINKキーコードを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3,
  },
  {
    field: 'ce_link_title_name_ja',
    label: 'title-code_ce-link-title-name-ja',
    type: 'text',
    placeholder: 'CE-LINKキーコード(日本語)を入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3,
  },
  {
    field: 'ce_link_title_name_en',
    label: 'title-code_ce-link-title-name-en',
    type: 'text',
    placeholder: 'CE-LINKキーコード(英語)を入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3,
  },
  {
    field: 'genre_ids',
    label: 'title-code_genre-ids',
    type: 'chip',
    placeholder: 'ジャンルを入力',
    optionsName: 'genre',
    column: 3,
  },
  {
    field: 'banasuke_title_id',
    label: 'title-code_banasuke-title-id',
    type: 'text',
    placeholder: 'バナスケタイトルIDを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    column: 3,
  },
  {
    field: 'banasuke_title_name_ja',
    label: 'title-code_banasuke-title-name-ja',
    type: 'text',
    placeholder: 'バナスケタイトル名称(日本語)を入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    column: 3,
  },
  {
    field: 'bundle_source_title_code_ids',
    label: 'title-code_bundle-source-title-code-ids',
    type: 'arrayText',
    placeholder: 'バンドル元タイトルコードIDを入力',
    column: 4,
  },
  {
    field: 'porting_source_title_code_ids',
    label: 'title-code_porting-source-title-code-ids',
    type: 'arrayText',
    placeholder: '移植元タイトルコードIDを入力',
    column: 4,
  },
  {
    field: 'platform_ids',
    label: 'title-code_platform-ids',
    type: 'chip',
    placeholder: 'PF(プラットフォーム)を選択',
    optionsName: 'platform',
    column: 4,
  },
  {
    field: 'valid_flg',
    label: 'title-code_valid-flg',
    type: 'checkbox',
    column: 4,
  },
  {
    field: 'business_entity_id',
    label: 'title-code_business-entity',
    type: 'select',
    optionsName: 'businessEntity',
    placeholder: '事業体を選択',
    column: 4,
  },
  {
    field: 'update_reason',
    label: 'title-code_update-reason',
    type: 'text',
    placeholder: '登録更新理由を入力',
    column: 4,
  },
];

export const EDIT_TITLE_CODE_FIELDS: TitleCodeField = [
  {
    field: 'seq_id',
    label: 'title-code_title-code-id',
    type: 'text',
    placeholder: 'タイトルコードIDを入力',
    column: 1,
  },
  ...CREATE_TITLE_CODE_FIELDS.filter(
    (v) => v.field !== 'data_source_id' && v.field !== 'update_reason'
  ),
  {
    field: 'data_source_id',
    label: 'title_data-source-id',
    type: 'select',
    optionsName: 'dataSource',
    column: 4,
  },
  {
    field: 'updated_user_id',
    label: 'title_final-updater',
    type: 'text',
    placeholder: '',
    column: 4,
  },
  {
    field: 'updatedAt',
    label: 'title_final-update-date',
    type: 'datetime',
    column: 4,
  },
  {
    field: 'update_reason',
    label: 'title_reason-for-update',
    type: 'text',
    placeholder: '更新理由を入力',
    column: 4,
  },
  {
    field: 'admin_check_flg',
    label: 'title_admin-checked-flag',
    type: 'checkbox',
    column: 4,
  },
];

export const SEARCH_TITLE_CODE_FIELDS: TitleCodeField = [
  {
    field: 'seq_id',
    label: 'title-code_title-code-id',
    type: 'text',
    placeholder: 'タイトルコードIDを入力',
    column: 1,
  },
  {
    field: 'title_code',
    label: 'title-code_title-code',
    type: 'text',
    placeholder: 'タイトルコードを入力',
    required: true,
    column: 1,
  },
  {
    field: 'name_ja',
    label: 'title-code_title-code-name-japanese',
    type: 'text',
    placeholder: 'タイトルコード名称(日本語)を入力',
    column: 1,
  },
  {
    field: 'name_en',
    label: 'title-code_title-code-name-english',
    type: 'text',
    placeholder: 'タイトルコード名称(英語)を入力',
    column: 1,
  },
  {
    field: 'official_name_flg',
    label: 'title-code_official-name-flg',
    required: true,
    type: 'select',
    column: 1,
    optionsName: 'official_name_flg',
    isBlank: false,
  },
  {
    field: 'sap_title_code_name',
    label: 'title-code_sap-title-code',
    type: 'text',
    placeholder: 'SAPタイトルコード用名称を入力',
    column: 1,
  },
  {
    field: 'search_name',
    label: 'title-code_search-name',
    type: 'text',
    placeholder: '検索用タイトルコード名称を入力',
    column: 1,
  },
  {
    field: 'project_cd',
    label: 'title-code_project-cd',
    type: 'text',
    placeholder: 'コードを入力',
    column: 2,
  },
  {
    field: 'wbs',
    label: 'title-code_wbs',
    type: 'text',
    placeholder: '関連WBSを入力',
    column: 2,
  },
  {
    field: 'bn_connect_title_ids',
    label: 'title-code_bn_connect-title-ids',
    type: 'text',
    placeholder: 'BN-ConnectタイトルIDを入力',
    column: 2,
  },
  {
    field: 'ce_link_key_code',
    label: 'title-code_ce-link-key-code',
    type: 'text',
    placeholder: 'CE-LINKキーコードを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 2,
  },
  {
    field: 'ce_link_title_name_ja',
    label: 'title-code_ce-link-title-name-ja',
    type: 'text',
    placeholder: 'CE-LINKキーコード(日本語)を入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 2,
  },
  {
    field: 'ce_link_title_name_en',
    label: 'title-code_ce-link-title-name-en',
    type: 'text',
    placeholder: 'CE-LINKキーコード(英語)を入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 2,
  },
  {
    field: 'genre_ids',
    label: 'title-code_genre-ids',
    type: 'select',
    placeholder: 'ジャンルを選択',
    optionsName: 'genre',
    column: 2,
  },
  {
    field: 'banasuke_title_id',
    label: 'title-code_banasuke-title-id',
    type: 'text',
    placeholder: 'バナスケタイトルIDを入力',
    column: 3,
  },
  {
    field: 'banasuke_title_name_ja',
    label: 'title-code_banasuke-title-name-ja',
    type: 'text',
    placeholder: 'バナスケタイトル名称(日本語)を入力',
    column: 3,
  },
  {
    field: 'bundle_source_title_code_ids',
    label: 'title-code_bundle-source-title-code-ids',
    type: 'text',
    placeholder: 'バンドル元タイトルコードIDを入力',
    column: 3,
  },
  {
    field: 'porting_source_title_code_ids',
    label: 'title-code_porting-source-title-code-ids',
    type: 'text',
    placeholder: '移植元タイトルコードIDを入力',
    column: 3,
  },
  {
    field: 'platform_ids',
    label: 'title-code_platform-ids',
    type: 'select',
    placeholder: 'PF(プラットフォーム)を選択',
    optionsName: 'platform',
    column: 3,
  },
  {
    field: 'valid_flg',
    label: 'title-code_valid-flg',
    type: 'select',
    optionsName: 'valid_flg',
    isBlank: false,
    column: 3,
  },
  {
    field: 'business_entity_id',
    label: 'title-code_business-entity',
    type: 'select',
    optionsName: 'businessEntity',
    placeholder: '事業体を選択',
    column: 3,
  },
  {
    field: 'data_source_id',
    label: 'title_data-source-name',
    type: 'select',
    placeholder: 'データソース名を選択',
    optionsName: 'dataSource',
    column: 3,
  },
  {
    field: 'updated_user_id',
    label: 'title_final-updater',
    type: 'text',
    placeholder: '最終更新者を入力',
    column: 4,
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'title_final-update-date' },
      { field: 'to', label: 'title_final-update-date' },
    ],
    column: 4,
  },
  {
    field: 'update_reason',
    label: 'title_reason-for-update',
    type: 'text',
    placeholder: '更新理由を入力',
    column: 4,
  },
  {
    field: 'system_update_flg',
    label: 'title_automatic-update-flag',
    type: 'select',
    optionsName: 'system_update_flg',
    column: 4,
    isBlank: false,
  },
  {
    field: 'delete_flg',
    label: 'title_deleted-data',
    type: 'select',
    optionsName: 'delete_flg',
    column: 4,
    isBlank: false,
  },
  {
    field: 'admin_check_flg',
    label: 'title_admin-checked-flag',
    type: 'select',
    optionsName: 'admin_check_flg',
    column: 4,
    isBlank: false,
  },
];

export const SEARCH_TITLE_CODE_FIELDS_DEFAULT_VALUES: SearchConditionTitleCode =
  {
    seq_id: '',
    title_code: '',
    name_ja: '',
    name_en: '',
    official_name_flg: 'ALL',
    search_name: '',
    genre_ids: '',
    update_reason: '',
    project_cd: '',
    wbs: '',
    sap_title_code_name: '',
    data_source_id: '',
    bn_connect_title_ids: '',
    ce_link_title_name_ja: '',
    ce_link_title_name_en: '',
    banasuke_title_id: '',
    banasuke_title_name_ja: '',
    bundle_source_title_code_ids: '',
    porting_source_title_code_ids: '',
    business_entity_id: '',
    platform_ids: '',
    valid_flg: 'ALL',
    ce_link_key_code: '',
    updated_user_id: '',
    from: null,
    to: null,
    delete_flg: false,
    admin_check_flg: true,
    system_update_flg: 'ALL',
  };
