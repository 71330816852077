import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Alert } from '@mui/material';

import NavigationBox from '../components/NavigationBox';
import { TOP_PAGE_ROUTES } from '../consts/routes';
import { AuthContext } from '../contexts/AuthContext';

const StyledContainer = styled('div')(({ theme }) => ({
  background: theme.colors.defaultHeaderBg,
  position: 'relative',
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  background: theme.colors.infoBg,
  position: 'absolute',
  top: 65,
  width: 'calc(100% - 48px)',
  margin: '0 24px',
  '& .MuiAlert-icon': {
    padding: '17px 0',
  },
  '& .MuiAlert-message': {
    letterSpacing: '0.15px',
  },
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  gap: 48,
  left: 27,
  position: 'absolute',
  top: 153,
  paddingBottom: 30,
  '& > :not(style)': {
    height: 650,
    padding: '0 20px',
  },
}));

function HomePage() {
  const roleType = useContext(AuthContext).roles?.map(
    (role) => role.role?.label
  );

  if (!roleType) return <></>;
  return (
    <StyledContainer>
      <StyledAlert variant="filled" severity="info">
        <span>
          ここは管理ツールのページです。作業を行いたいページを選択してください。
        </span>
        <br />
        <span>なお、情報の取り扱いには注意してください。</span>
      </StyledAlert>
      <StyledBox>
        {TOP_PAGE_ROUTES.map(
          (route, idx) =>
            ((roleType.includes('ADMIN') && route.role !== 'COMPANY') ||
              roleType.includes(route.role)) && (
              <NavigationBox
                title={route.title}
                contents={route.contents}
                key={idx}
              />
            )
        )}
      </StyledBox>
    </StyledContainer>
  );
}

export default HomePage;
