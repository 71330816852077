import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import PrimaryButton from '../../components/PrimaryButton';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormAutocomplete from '../forms/FormAutocomplete';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import NewTagDialog from '../tags/NewTagDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { EDIT_TAG_BNML_FIELDS } from '../../consts/tagBnml';
import { tagBnmlFields } from '../../types';
import { classificationValidateTagBnml } from '../../hooks/classification';
import { generateSearchFilter, SEARCH_TYPE } from '../../utils/search';
import { fetchTagBnml, sleep } from '../../utils/fetchData';
import { useSearchTags } from '../../hooks/useSearchTags';
import { TagBnmlSearch } from '../../types/form/tagBnml';
import { searchTagsMinimumRelations } from '../../graphql/custom-queries';
import {
  updateTagBnmlWithoutOtherRelations,
  createTagBnmlWithoutOtherRelations,
  deleteTagBnmlWithoutOtherRelations,
} from '../../graphql/custom-mutations';

import type { User } from '../../models';
import type { Bnml, Tag, TagBnml } from '../../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  onClose: Function;
  addedTag: Tag | null;
  open: boolean;
  tags: Tag[];
  tagBnml: TagBnml;
  bnml: Bnml;
  user: User;
  setBnmls: Function;
  condition: TagBnmlSearch;
  setCondition: Function;
  setExpQueries: Function;
};

type Record = {
  bnml_seq_id?: string;
  mlics_plan_cd_4char?: number;
  mlics_plan_cd_7char?: number;
  mlics_commodity_name?: string;
  tag?: Tag;
  tag_id?: string;
  updatedAt?: string;
  update_reason?: string;
  updated_user_id?: string;
};

type StringKeyObj = {
  [key: string]: string | number | boolean | undefined | Tag;
};

function EditTagBnmlDialog(props: Props) {
  const { addedTag, onClose, open, tags, tagBnml, bnml, user, setBnmls, condition, setCondition, setExpQueries } =
    props;
  const [currentTags, setCurrentTags] = useState<Tag[]>(tags);
  const [confirm, setConfirm] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);

  const { fetchTags } = useSearchTags();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  useEffect(() => {
    addedTag && setValue('tag', addedTag);
  }, [addedTag, setValue]);

  const doClose = () => {
    onClose();
    reset();
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<any> = async (data) => {
    console.log({ data });

    // 確認画面で表示されるようにdataを変換して入れる
    data.tag_id = data.tag.id;
    data.tag_name_ja = data.tag.name_ja;
    data.tag_name_en = data.tag.name_en;
    setRecord(data);
    setEditConfirm(true);
  };

  // 確認編集画面の「登録」の処理
  const onSubmit = () => {
    setCanSubmit(true);
  };

  const onUpdate = async () => {
    if (tagBnml) {
      // memo: 既存データにtagTitleがあれば編集
      try {
        const formData = {
          id: tagBnml.id,
          tag_id: record.tag_id,
          _version: tagBnml._version,
          updated_user_id: user.id,
          update_reason: record.update_reason,
        };
        await API.graphql({ query: updateTagBnmlWithoutOtherRelations, variables: { input: formData } });
        onClose();
        doClose();
        addNotification({
          type: 'success',
          message: '紐付けの編集に成功しました',
        });
        await sleep(3000); //OpenSearchがアップデートされるまでスリープ
        await fetchTagBnml(setBnmls, condition, setCondition, setExpQueries);
      } catch (e) {
        addNotification({
          type: 'error',
          message: '紐付けの編集に失敗しました',
        });
        console.log(e);
      }
    } else {
      // memo: なければ新規作成
      try {
        const formData = {
          tag_id: record.tag_id,
          bnml_id: bnml.id,
          updated_user_id: user.id,
          update_reason: record.update_reason,
        };
        await API.graphql({ query: createTagBnmlWithoutOtherRelations, variables: { input: formData } });
        onClose();
        setEditConfirm(false);
        doClose();
        addNotification({
          type: 'success',
          message: '紐付けの編集に成功しました',
        });
        await sleep(3000); //OpenSearchがアップデートされるまでスリープ
        await fetchTagBnml(setBnmls, condition, setCondition, setExpQueries);
        reset();
      } catch (e) {
        addNotification({
          type: 'error',
          message: '紐付けの編集に失敗しました',
        });
        console.log(e);
      }
    }
  };

  const onDelete = async () => {
    try {
      const formData = { id: tagBnml.id, _version: tagBnml._version };
      await API.graphql({ query: deleteTagBnmlWithoutOtherRelations, variables: { input: formData } });
      onClose();
      setConfirm(false);
      doClose();
      addNotification({
        type: 'success',
        message: '紐付けの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTagBnml(setBnmls, condition, setCondition, setExpQueries);
    } catch (e) {
      addNotification({
        type: 'error',
        message: '紐付けの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const onTagSeatch = async () => {
    const value = (document.getElementById('tag') as HTMLInputElement).value;

    if (!value) return;

    const jaFilter = generateSearchFilter([{ name: 'name_ja', value, type: SEARCH_TYPE.WILDCARD }]);
    const enFilter = generateSearchFilter([{ name: 'name_en', value, type: SEARCH_TYPE.WILDCARD }]);
    try {
      const jaModels: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: { ...jaFilter, sort: { direction: 'desc', field: 'seq_id' }, limit: 500 },
      });
      const enModels: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: { ...enFilter, sort: { direction: 'desc', field: 'seq_id' }, limit: 500 },
      });
      setCurrentTags([...jaModels.data.searchTags.items, ...enModels.data.searchTags.items]);
    } catch (e) {
      console.log(e);
    }
  };

  const onConfirmCancel = () => {
    setConfirm(false);
  };

  const onDoConfirm = () => {
    setConfirm(true);
  };

  const onAddTagOpen = useCallback(() => {
    setOpenTag(true);
  }, []);

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'tag':
        return currentTags;
      default:
        return [];
    }
  };

  const field2value: StringKeyObj = {
    bnml_seq_id: bnml.seq_id,
    mlics_plan_cd_4char: bnml.mlics_plan_cd_4char,
    mlics_plan_cd_7char: bnml.mlics_plan_cd_7char,
    mlics_commodity_name: bnml.mlics_commodity_name,
    tag: tagBnml?.tag,
    updated_user_id: tagBnml?.updated_user_id,
    updatedAt: tagBnml?.updatedAt,
    update_reason: tagBnml?.update_reason,
  };

  const oldValue: StringKeyObj = {
    bnml_seq_id: bnml.seq_id,
    mlics_plan_cd_4char: bnml.mlics_plan_cd_4char,
    mlics_plan_cd_7char: bnml.mlics_plan_cd_7char,
    mlics_commodity_name: bnml.mlics_commodity_name,
    tag: tagBnml?.tag,
    updated_user_id: tagBnml?.updated_user_id,
    updatedAt: tagBnml?.updatedAt,
    update_reason: tagBnml?.update_reason,
  };

  if (!bnml) return <></>;

  return (
    <FormDialog
      open={open}
      onCancel={doClose}
      title="BNMLマスター共有タグ付け編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      onConfirm={tagBnml && onDoConfirm}
      height={510}
      width="md"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 350 },
        }}
        width={380}
      >
        {EDIT_TAG_BNML_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                disabled={row.disabled}
                errors={errors}
                validationRules={classificationValidateTagBnml(row.field as tagBnmlFields)}
                value={field2value[row.field]?.toString() || ''}
                required={row.required}
              />
            )}
            {row.type === 'datetime' && (
              <FormDateTimePicker
                label={row.label}
                defaultValue={field2value[row.field]?.toString()}
                type={row.type}
                disabled={row.disabled}
                field={row.field}
                control={control}
              />
            )}
            {row.type === 'autoComplete' && row.optionsName === 'name_ja' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& > div': { width: '70%' },
                  '& > button': { marginTop: '10px' },
                }}
              >
                <FormAutocomplete
                  id={row.field}
                  label={row.label}
                  placeholder={row.placeholder}
                  field={row.field}
                  control={control}
                  disabled={row.disabled}
                  errors={errors}
                  validationRules={classificationValidateTagBnml(row.field as tagBnmlFields)}
                  defaultValue={field2value[row.field]}
                  required={row.required}
                  options={options(row.field)}
                  freeSolo
                  includeSearchEnglish
                />
                <PrimaryButton onClick={onTagSeatch}>タグ検索</PrimaryButton>
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
      <Box sx={{ position: 'absolute', bottom: '10px', left: '25px' }} width={220}>
        <PrimaryButton width="auto" onClick={onAddTagOpen}>
          タグの新規作成
        </PrimaryButton>
      </Box>
      {confirm && (
        <ConfirmDialog
          message={`このBNMLマスター共有タグ付けを${'\n'}解除してよろしいでしょうか？`}
          open={confirm}
          onCancel={onConfirmCancel}
          onContinue={onDelete}
        />
      )}
      {openTag && user && (
        <NewTagDialog open={openTag} setOpen={setOpenTag} user={user} isTag={false} fetchTags={fetchTags} />
      )}
      {editConfirm && (
        <EditConfirmDialog
          fields={EDIT_TAG_BNML_FIELDS}
          onClose={onEditConfirmClose}
          onSubmit={onSubmit}
          open={editConfirm}
          record={record}
          schemaName="BNMLマスター共有タグ付け"
          beforeChangeValueData={oldValue}
        />
      )}
    </FormDialog>
  );
}

export default EditTagBnmlDialog;
