export const SEARCH_TITLE_HISTORY_PRODUCT_FIELDS = [
  {
    field: "operation_type",
    label: "title-history_operation-category",
    type: "select",
    placeholder: "操作種別を選択",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "title-history_title-id",
    type: "text",
    placeholder: "タイトルIDを入力",
  },
  {
    field: "name_ja",
    label: "title-history_title-name-japanese",
    type: "text",
    placeholder: "タイトル名(日本語)を入力",
  },
  {
    field: "name_en",
    label: "title-history_title-name-english",
    type: "text",
    placeholder: "タイトル名(英語)を入力",
  },
  {
    field: "search_name",
    label: "title-history_title-name-for-search",
    type: "text",
    placeholder: "検索用タイトル名称を入力",
  },
  {
    field: "genre_id",
    label: "title-history_genre",
    type: "select",
    placeholder: "ジャンルを選択",
    optionsName: "genre",
  },
  {
    field: "project_cd",
    label: "プロジェクト定義",
    type: "text",
    placeholder: "プロジェクト定義を入力",
  },
  {
    field: "sap_title_code",
    label: "title-history_sap-title-code",
    type: "text",
    placeholder: "SAPタイトルコードを入力",
  },
  {
    field: "data_source_id",
    label: "title-history_data-source-name",
    type: "select",
    placeholder: "データソース名を選択",
    optionsName: "dataSource",
  },
  {
    field: "bn_connect_title_id",
    label: "title-history_bn-connect-title-id",
    type: "text",
    placeholder: "BN-ConnectタイトルIDを入力",
  },
  {
    field: "banasuke_title_id",
    label: "title-history_banaske-title-id",
    type: "text",
    placeholder: "バナスケタイトルIDを入力",
  },
  {
    field: "ce_link_key_code",
    label: "title-history_ce-link-key-code",
    type: "text",
    placeholder: "CE-LINKキーコードを入力",
  },
  {
    field: "updated_user_id",
    label: "title-history_final-updater",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "title-history_final-update-date" },
      { field: "to", label: "title-history_final-update-date" },
    ],
  },
  {
    field: "update_reason",
    label: "title-history_reason-for-update",
    type: "textarea",
    placeholder: "更新理由を入力",
  },
  {
    field: "system_update_flg",
    label: "title-history_automatic-update-flag",
    type: "select",
    optionsName: "system_update_flg",
    isBlank: false,
  },
];

export const SEARCH_TITLE_HISTORY_ADMIN_FIELDS = [
  {
    field: "operation_type",
    label: "title-history_operation-category",
    type: "select",
    placeholder: "操作種別を選択",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "title-history_title-id",
    type: "text",
    placeholder: "タイトルIDを入力",
  },
  {
    field: "name_ja",
    label: "title-history_title-name-japanese",
    type: "text",
    placeholder: "タイトル名(日本語)を入力",
  },
  {
    field: "name_en",
    label: "title-history_title-name-english",
    type: "text",
    placeholder: "タイトル名(英語)を入力",
  },
  {
    field: "search_name",
    label: "title-history_title-name-for-search",
    type: "text",
    placeholder: "検索用タイトル名称を入力",
  },
  {
    field: "genre_id",
    label: "title-history_genre",
    type: "select",
    placeholder: "ジャンルを選択",
    optionsName: "genre",
  },
  {
    field: "project_cd",
    label: "プロジェクト定義",
    type: "text",
    placeholder: "プロジェクト定義を入力",
  },
  {
    field: "sap_title_code",
    label: "title-history_sap-title-code",
    type: "text",
    placeholder: "SAPタイトルコードを入力",
  },
  {
    field: "data_source_id",
    label: "title-history_data-source-name",
    type: "select",
    placeholder: "データソース名を選択",
    optionsName: "dataSource",
  },
  {
    field: "bn_connect_title_id",
    label: "title-history_bn-connect-title-id",
    type: "text",
    placeholder: "BN-ConnectタイトルIDを入力",
  },
  {
    field: "banasuke_title_id",
    label: "title-history_banaske-title-id",
    type: "text",
    placeholder: "バナスケタイトルIDを入力",
  },
  {
    field: "ce_link_key_code",
    label: "title-history_ce-link-key-code",
    type: "text",
    placeholder: "CE-LINKキーコードを入力",
  },
  {
    field: "updated_user_id",
    label: "title-history_final-updater",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "title-history_final-update-date" },
      { field: "to", label: "title-history_final-update-date" },
    ],
  },
  {
    field: "update_reason",
    label: "title-history_reason-for-update",
    type: "textarea",
    placeholder: "更新理由を入力",
  },
  {
    field: "system_update_flg",
    label: "title-history_automatic-update-flag",
    type: "select",
    optionsName: "system_update_flg",
    isBlank: false,
  },
  {
    field: "admin_check_flg",
    label: "title-history_admin-checked-flag",
    type: "select",
    optionsName: "admin_check_flg",
    defaultValue: true,
    isBlank: false,
  },
  {
    field: "delete_flg",
    label: "title_deleted-data",
    type: "select",
    optionsName: "delete_flg",
    isBlank: false,
  },
];

export const DETAIL_TITLE_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "title-history_operation-category",
    type: "select",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "title-history_title-id",
    type: "text",
  },
  {
    field: "name_ja",
    label: "title-history_title-name-japanese",
    type: "text",
  },
  {
    field: "name_en",
    label: "title-history_title-name-english",
    type: "text",
  },
  {
    field: "search_name",
    label: "title-history_title-name-for-search",
    type: "text",
  },
  {
    field: "genre_name",
    label: "title-history_genre",
    type: "text",
  },
  {
    field: "project_cd",
    label: "プロジェクト定義",
    type: "text",
  },
  {
    field: "sap_title_code",
    label: "title-history_sap-title-code",
    type: "text",
  },
  {
    field: "data_source_name",
    label: "title-history_data-source-name",
    type: "text",
  },
  {
    field: "bn_connect_title_id",
    label: "title-history_bn-connect-title-id",
    type: "text",
  },
  {
    field: "banasuke_title_id",
    label: "title-history_banaske-title-id",
    type: "text",
  },
  {
    field: "ce_link_key_code",
    label: "title-history_ce-link-key-code",
    type: "text",
  },
  {
    field: "updated_user_id",
    label: "title-history_final-updater",
    type: "text",
  },
  {
    field: "updatedAt",
    label: "title-history_final-update-date",
    type: "datetime",
  },
  {
    field: "deleted_at",
    label: "title-history_deleted-date",
    type: "datetime",
  },
  {
    field: "update_reason",
    label: "title-history_reason-for-update",
    type: "textarea",
  },
  {
    field: "system_update_flg",
    label: "title-history_automatic-update-flag",
    type: "checkbox",
  },
  {
    field: "admin_check_flg",
    label: "title-history_admin-checked-flag",
    type: "checkbox",
  },
  {
    field: "delete_flg",
    label: "title-history_deleted-data",
    type: "checkbox",
  },
];

export const SEARCH_TITLE_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  seq_id: "",
  name_ja: "",
  name_en: "",
  search_name: "",
  genre_id: "",
  sap_title_code: "",
  project_cd: "",
  update_reason: "",
  data_source_id: "",
  bn_connect_title_id: "",
  banasuke_title_id: "",
  ce_link_key_code: "",
  updated_user_id: "",
  from: null,
  to: null,
  admin_check_flg: true,
  delete_flg: false,
  system_update_flg: "ALL",
};
