import { GridCellParams } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

const arrangeFields = [
  'product_type_name',
  'product_class_name',
  'platform_name',
  'currency_name',
  'title_name',
  'genre_name',
  'tag_name',
  'updated_user_name',
  'title_code_name',
  'business_entity_name'
]

export const useEditedColumnClass = (params: GridCellParams): 'edited_cell' | '' => {
  const fieldName = arrangeFields.includes(params.field) ? params.field.replace('name', 'id') : params.field
  if (
    params.row.operation_type !== 'EDIT' ||
    (params.row[fieldName] === '<empty>' && !params.row[`old_${fieldName}`]) ||
    (!params.row[fieldName] && !params.row[`old_${fieldName}`]) ||
    (!params.row[fieldName] && params.row[`old_${fieldName}`] === '[]') ||
    (!params.row['old_id'])
  ) return ''
  return params.row[fieldName] !== params.row[`old_${fieldName}`] ? 'edited_cell' : ''
}

export const useEditedColumn = (row: any, field: string): boolean => {
  const fieldName = arrangeFields.includes(field) ? field.replace('name', 'id') : field
  if (
    row.operation_type !== 'EDIT' ||
    (row[fieldName] === '<empty>' && !row[`old_${fieldName}`]) ||
    (!row[fieldName] && !row[`old_${fieldName}`]) ||
    (!row['old_id'])
  ) return false
  return row[fieldName] !== row[`old_${fieldName}`] ? true : false
}

export const useIsDeletedOrRestoreColumn = (params: GridCellParams): 'edited_cell' | '' => (
  (params.row.operation_type === 'SOFTDELETE' || params.row.operation_type === 'RESTORESOFTDELETE') ? 'edited_cell' : ''
)
