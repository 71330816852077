import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import FormSelectBox from '../forms/FormSelectBox';
import SearchDialog from '../SearchDialog';
import {
  OPERATION_TYPE_OPTIONS,
  DELETE_FLG_OPTIONS,
  SYSTEM_UPDATE_OPTIONS,
  ADMIN_CHECK_FLG_OPTIONS
} from '../../consts/common';
import {
  SEARCH_TITLE_PRODUCT_FIELDS,
  SEARCH_TITLE_ADMIN_FIELDS,
  SEARCH_TITLE_FIELDS_DEFAULT_VALUES
} from '../../consts/title';
import { Genre, DataSource } from '../../models';
import { titleFields } from '../../types';
import { classificationValidateTitle } from '../../hooks/classification';
import { TitleSearch } from '../../types/form/title'
import { fetchTitles } from '../../utils/fetchData'

type Props = {
  condition: TitleSearch;
  dataSources: DataSource[];
  genres: Genre[];
  onChangeFilter: Function
  open: boolean;
  isAdmin: boolean;
  setCondition: Function;
  setExpQueries: Function
  setOpen: Function;
}

function SearchTitleDialog(props: Props) {
  const {
    condition,
    dataSources,
    genres,
    isAdmin,
    onChangeFilter,
    open,
    setOpen,
    setCondition,
    setExpQueries,
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_TITLE_FIELDS_DEFAULT_VALUES });
  const fields = isAdmin ? SEARCH_TITLE_ADMIN_FIELDS : SEARCH_TITLE_PRODUCT_FIELDS

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);


  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit: SubmitHandler<TitleSearch> = async (data) => {
    await fetchTitles(data, setCondition, onChangeFilter, setExpQueries)
    onClose()
  };

  const onReset = () => {
    reset();
  };

  const options = (name: string) => {
    switch (name) {
      case 'genre':
        return genres;
      case 'dataSource':
        return dataSources;
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      case 'system_update_flg':
        return SYSTEM_UPDATE_OPTIONS;
      case 'admin_check_flg':
        return ADMIN_CHECK_FLG_OPTIONS;
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <SearchDialog open={open} onCancel={onClose} onSubmit={handleSubmit(onSubmit)} onReset={onReset} height='600' width="xl">
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 360 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 580,
        }}
        width={1200}
      >
        {fields.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'select' && row.optionsName && (
              <FormSelectBox
                label={row.label}
                content={options(row.optionsName)}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
                isBlank={row.isBlank}
              />
            )}
            {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={row.inputs[0].field}
                  toLabel={row.inputs[0].label}
                  toField={row.inputs[1].field}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {(row.type === 'text' || row.type === 'textarea') && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                isTextArea={row.type === 'textarea'}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateTitle(row.field as titleFields, 'search')}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchTitleDialog;
