import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormSelectBox from '../forms/FormSelectBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import {
  OPERATION_TYPE_OPTIONS,
  SYSTEM_UPDATE_OPTIONS,
  ADMIN_CHECK_FLG_OPTIONS,
  DELETE_FLG_OPTIONS
} from '../../consts/common'
import {
  SEARCH_PRODUCT_HISTORY_PRODUCT_FIELDS,
  SEARCH_PRODUCT_HISTORY_ADMIN_FIELDS,
  SEARCH_PRODUCT_HISTORY_FIELDS_DEFAULT_VALUES
} from '../../consts/productHistory';
import { Currency, Platform, ProductClass, ProductType, DataSource } from '../../models';
import { ProductFields } from '../../types';
import { classificationValidatePrd } from '../../hooks/classification';
import { ProductHistorySearch } from '../../types/form/productHistory'

type Props = {
  condition: ProductHistorySearch
  currencies: Currency[];
  dataSources: DataSource[];
  isAdmin: boolean | undefined
  open: boolean;
  platforms: Platform[];
  productClasses: ProductClass[];
  productTypes: ProductType[];
  setOpen: Function;
  fetchProductHistories: Function;
}

type StringKeyObj = {
  [key: string]: { name: string; type: string };
}

function SearchProductHistoryDialog(props: Props) {
  const {
    condition,
    currencies,
    dataSources,
    isAdmin,
    open,
    platforms,
    productClasses,
    productTypes,
    setOpen,
    fetchProductHistories
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_PRODUCT_HISTORY_FIELDS_DEFAULT_VALUES });
  const fields = isAdmin ? SEARCH_PRODUCT_HISTORY_ADMIN_FIELDS : SEARCH_PRODUCT_HISTORY_PRODUCT_FIELDS

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<ProductHistorySearch> = async (newCondition) => {
    try {
      await fetchProductHistories({ newCondition })
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  }

  const onReset = () => {
    reset();
  };

  const options = (name: string) => {
    switch (name) {
      case 'platform':
        return platforms;
      case 'currency':
        return currencies;
      case 'product_class':
        return productClasses;
      case 'product_type':
        return productTypes;
      case 'data_source':
        return dataSources;
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      case 'system_update_flg':
        return SYSTEM_UPDATE_OPTIONS;
      case 'admin_check_flg':
        return ADMIN_CHECK_FLG_OPTIONS;
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <SearchDialog open={open} onCancel={onClose} onSubmit={handleSubmit(onSubmit)} onReset={onReset} height='inherit' isFullScreen>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 335 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 'inherit',
        }}
      >
        {fields.map((row, idx) => (
          <Box key={idx}>
            {row.type === 'select' && row.optionsName && (
              <FormSelectBox
                label={row.label}
                content={options(row.optionsName)}
                field={row.field}
                selectKey={row.field === 'currency_id' ? 'code_three_char' : 'name'}
                control={control}
                placeholder={row.placeholder}
                isBlank={row.isBlank}
              />
            )}
            {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={`${row.field}_${row.inputs[0].field}`}
                  toLabel={row.inputs[1].label}
                  toField={`${row.field}_${row.inputs[1].field}`}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {((row.type === 'text' || row.type === 'textarea') && !row.inputs) && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidatePrd(row.field as ProductFields, 'search')}
              />
            )}
            {(row.type === 'text' && row.inputs) && (
              <Box sx={{ display: 'flex' }}>
                <FormTextField
                  label={row.inputs[0].label}
                  placeholder={row.placeholder}
                  field={`${row.field}_${row.inputs[0].field}`}
                  control={control}
                  errors={errors}
                  validationRules={classificationValidatePrd(row.field as ProductFields, 'search')}
                />
                <Box pt={3} px={2}>〜</Box>
                <FormTextField
                  label={row.inputs[1].label}
                  placeholder={row.placeholder}
                  field={`${row.field}_${row.inputs[1].field}`}
                  control={control}
                  errors={errors}
                  validationRules={classificationValidatePrd(row.field as ProductFields, 'search')}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchProductHistoryDialog;
