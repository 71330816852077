export const SEARCH_TITLE_CODE_HISTORY_PRODUCT_FIELDS = [
  {
    field: "operation_type",
    label: "title-code-history_operation-type",
    type: "select",
    placeholder: "操作種別を選択",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "title-code-history_seq-id",
    type: "text",
    placeholder: "タイトルコードIDを入力",
  },
  {
    field: "title_code",
    label: "title-code-history_title-code",
    type: "text",
    placeholder: "タイトルコードを入力",
  },
  {
    field: "name_ja",
    label: "title-code-history_name-ja",
    type: "text",
    placeholder: "タイトルコード名称(日本語)を入力",
  },
  {
    field: "name_en",
    label: "title-code-history_name-en",
    type: "text",
    placeholder: "タイトルコード名称(英語)を入力",
  },
  {
    field: "official_name_flg",
    label: "title-code-history_official-name-flg",
    type: "select",
    optionsName: "official_name_flg",
    placeholder: "タイトルコード名称 正式フラグを入力",
    isBlank: false,
  },
  {
    field: "sap_title_code_name",
    label: "title-code-history_sap-title-code-name",
    type: "text",
    placeholder: "SAPタイトルコード用名称を入力",
  },
  {
    field: "search_name",
    label: "title-code-history_search-name",
    type: "text",
    placeholder: "検索用タイトルコード名称を入力",
  },
  {
    field: "project_cd",
    label: "title-code-history_project-cd",
    type: "text",
    placeholder: "関連プロジェクトコードを入力",
  },
  {
    field: "wbs",
    label: "title-code-history_wbs",
    type: "text",
    placeholder: "関連WBSを入力",
  },
  {
    field: "bn_connect_title_ids",
    label: "title-code-history_bn-connect-title-ids",
    type: "text",
    placeholder: "BN-ConnectタイトルIDを入力",
  },
  {
    field: "ce_link_key_code",
    label: "title-code-history_ce-link-key-code",
    type: "text",
    placeholder: "CE-LINKキーコードを入力",
  },
  {
    field: "ce_link_title_name_ja",
    label: "title-code-history_ce-link-title-name-ja",
    type: "text",
    placeholder: "CE-LINKキーコード名称(日本語)を入力",
  },
  {
    field: "ce_link_title_name_en",
    label: "title-code-history_ce-link-title-name-en",
    type: "text",
    placeholder: "CE-LINKキーコード名称(英語)を入力",
  },
  {
    field: "genre_ids",
    label: "title-code-history_genre-name",
    type: "select",
    placeholder: "ジャンルを選択",
    optionsName: "genre",
  },
  {
    field: "banasuke_title_id",
    label: "title-code-history_banasuke-title-id",
    type: "text",
    placeholder: "バナスケタイトルIDを入力",
  },
  {
    field: "banasuke_title_name_ja",
    label: "title-code-history_banasuke-title-name-ja",
    type: "text",
    placeholder: "バナスケタイトル名称（日本語）を入力",
  },
  {
    field: "bundle_source_title_code_ids",
    label: "title-code-history_bundle-source-title-code-ids",
    type: "text",
    placeholder: "バンドル元タイトルコードIDを入力",
  },
  {
    field: "porting_source_title_code_ids",
    label: "title-code-history_porting-source-title-code-ids",
    type: "text",
    placeholder: "移植元タイトルコードIDを入力",
  },
  {
    field: "platform_ids",
    label: "title-code-history_platform-ids",
    type: "select",
    placeholder: "PF（プラットフォーム）を選択",
    optionsName: "platform",
  },
  {
    field: "valid_flg",
    label: "title-code_valid-flg",
    type: "select",
    optionsName: "valid_flg",
    isBlank: false,
  },
  {
    field: "business_entity_id",
    label: "title-code-history_business-entity",
    type: "select",
    optionsName: 'businessEntity',
    placeholder: "事業体を選択",
  },
  {
    field: "data_source_id",
    label: "title-code-history_data-source-name",
    type: 'select',
    optionsName: 'dataSource',
    placeholder: "データソース名を選択",
  },
  {
    field: "updated_user_id",
    label: "title-code-history_updated-user-id",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "title-code-history_final-update-date" },
      { field: "to", label: "title-code-history_final-update-date" },
    ],
  },
  {
    field: "update_reason",
    label: "title-code-history_update-reason",
    type: "text",
    placeholder: "更新理由を入力",
  },
  {
    field: "system_update_flg",
    label: "title-code-history_automatic-update-flag",
    type: "select",
    optionsName: "system_update_flg",
    isBlank: false,
  },
];

export const SEARCH_TITLE_CODE_HISTORY_ADMIN_FIELDS = [
  ...SEARCH_TITLE_CODE_HISTORY_PRODUCT_FIELDS,
  {
    field: "admin_check_flg",
    label: "title-code-history_admin-checked-flag",
    type: "select",
    optionsName: "admin_check_flg",
    isBlank: false,
  },
  {
    field: "delete_flg",
    label: "title-code-history_deleted-data",
    type: "select",
    optionsName: "delete_flg",
    isBlank: false,
  },
];

export const DETAIL_TITLE_CODE_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "title-code-history_operation-type",
    type: "select",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "title-code-history_seq-id",
    type: "text",
  },
  {
    field: "title_code",
    label: "title-code-history_title-code",
    type: "text",
  },
  {
    field: "name_ja",
    label: "title-code-history_name-ja",
    type: "text",
  },
  {
    field: "name_en",
    label: "title-code-history_name-en",
    type: "text",
  },
  {
    field: "official_name_flg",
    label: "title-code-history_official-name-flg",
    type: "checkbox",
  },
  {
    field: "sap_title_code_name",
    label: "title-code-history_sap-title-code-name",
    type: "text",
  },
  {
    field: "search_name",
    label: "title-code-history_search-name",
    type: "text",
  },
  {
    field: "project_cd",
    label: "title-code-history_project-cd",
    type: "text",
  },
  {
    field: "wbs",
    label: "title-code-history_wbs",
    type: "text",
  },
  {
    field: "bn_connect_title_ids",
    label: "title-code-history_bn-connect-title-ids",
    type: "text",
  },
  {
    field: "ce_link_key_code",
    label: "title-code-history_ce-link-key-code",
    type: "text",
  },
  {
    field: "ce_link_title_name_ja",
    label: "title-code-history_ce-link-title-name-ja",
    type: "text",
  },
  {
    field: "ce_link_title_name_en",
    label: "title-code-history_ce-link-title-name-en",
    type: "text",
  },
  {
    field: "genre_names",
    label: "title-code-history_genre-name",
    type: "text",
  },
  {
    field: "banasuke_title_id",
    label: "title-code-history_banasuke-title-id",
    type: "text",
  },
  {
    field: "banasuke_title_name_ja",
    label: "title-code-history_banasuke-title-name-ja",
    type: "text",
  },
  {
    field: "bundle_source_title_code_ids",
    label: "title-code-history_bundle-source-title-code-ids",
    type: "text",
  },
  {
    field: "porting_source_title_code_ids",
    label: "title-code-history_porting-source-title-code-ids",
    type: "text",
  },
  {
    field: "platform_names",
    label: "title-code-history_platform-ids",
    type: "text",
  },
  {
    field: "valid_flg",
    label: "title-code_valid-flg",
    type: "checkbox",
  },
  {
    field: "business_entity_name",
    label: "title-code-history_business-entity",
    type: "text",
  },
  {
    field: "data_source_name",
    label: "title-code-history_data-source-name",
    type: "text",
  },
  {
    field: "updated_user_id",
    label: "title-code-history_updated-user-id",
    type: "text",
  },
  {
    field: "updatedAt",
    label: "title-code-history_final-update-date",
    type: "datetime",
  },
  {
    field: "update_reason",
    label: "title-code-history_update-reason",
    type: "text",
  },
  {
    field: "system_update_flg",
    label: "title-code-history_automatic-update-flag",
    type: "checkbox",
  },
  {
    field: "admin_check_flg",
    label: "title-code-history_admin-checked-flag",
    type: "checkbox",
  },
  {
    field: "delete_flg",
    label: "title-code-history_deleted-data",
    type: "checkbox",
  },
];

export const SEARCH_TITLE_CODE_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  seq_id: "",
  title_code: "",
  name_ja: "",
  name_en: "",
  official_name_flg: "ALL",
  sap_title_code_name: "",
  search_name: "",
  project_cd: "",
  wbs: "",
  bn_connect_title_ids: "",
  ce_link_key_code: "",
  ce_link_title_name_ja: "",
  ce_link_title_name_en: "",
  genre_ids: "",
  banasuke_title_id: "",
  banasuke_title_name_ja: "",
  bundle_source_title_code_ids: "",
  porting_source_title_code_ids: "",
  platform_ids: "",
  valid_flg: "ALL",
  business_entity_id: "",
  data_source_id: "",
  update_reason: "",
  updated_user_id: "",
  from: null,
  to: null,
  admin_check_flg: true,
  delete_flg: false,
  system_update_flg: "ALL",
};
