import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import FormTextField from '../forms/FormTextField';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import { UserRoles, Role } from '../../models';
import { EDIT_USER_FIELDS } from '../../consts/user';
import { getUserRoles } from '../../graphql/queries';
import { createUserRoles, updateUserRoles, deleteUser } from '../../graphql/mutations';
import ConfirmDialog from '../ConfirmDialog';
import { AuthContext } from '../../contexts/AuthContext';
import { sleep } from '../../utils/fetchData';
import type { User } from '../../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  currentUser: User;
  currentUserRoles?: UserRoles[];
  onClose: Function;
  open: boolean;
  roles: Role[];
  fetchUsers: Function;
};

type Record = {
  email?: string;
  userRoles_IP: boolean;
  userRoles_PRODUCT: boolean;
  userRoles_ADMIN: boolean;
  userRoles_COMPANY: boolean;
};

type StringKeyObj = {
  [key: string]: any;
};

function EditUserDialog(props: Props) {
  const [confirm, setConfirm] = useState(false);

  const { currentUser, currentUserRoles = [], onClose, open, roles, fetchUsers } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { user } = useContext(AuthContext);
  const { addNotification } = useContext(NotificationContext);
  const isLoginUser = user.id === currentUser.id;

  // MEMO: user作成時に全てのロールが登録されていないので、ない場合はuserRolesを新規作成する必要がある
  const existsRole = (roleLabel: string): { exists: boolean; existedUserRole: any } => {
    let existedUserRole = null;
    const exists = currentUserRoles.some((userRole: any) => {
      if (userRole.role.label === roleLabel) {
        existedUserRole = userRole;
        return true;
      }
    });
    return { exists, existedUserRole };
  };

  const onSubmit: SubmitHandler<any> = (data) => {
    console.log({ data });
    Promise.all(
      roles.map((role) => {
        const result = existsRole(role.label);
        result.exists ? onUpdate(result.existedUserRole, data) : onCreate(role);
      })
    )
      .then((res) => {
        onClose();
        reset();
      })
      .catch((e) => {
        console.log(e);
        alert('ユーザーロールの編集に失敗しました。詳しくは管理者にお問合せください。');
      });
  };

  const label2value = (label: string, record: Record): boolean => {
    if (label === 'IP') return !record.userRoles_IP;
    if (label === 'PRODUCT') return !record.userRoles_PRODUCT;
    if (label === 'ADMIN') return !record.userRoles_ADMIN;
    if (label === 'COMPANY') return !record.userRoles_COMPANY;
    return false;
  };

  const needUpdate = (userRoles: any, record: Record): boolean => {
    return userRoles.delete_flg !== label2value(userRoles.role.label, record);
  };

  const onUpdate = async (userRoles: UserRoles, record: Record) => {
    try {
      const model: any = await API.graphql({ query: getUserRoles, variables: { id: userRoles.id } });
      const __userRoles = model.data.getUserRoles;
      const result = needUpdate(__userRoles, record);
      if (!result) return;
      const formData = {
        id: __userRoles.id,
        delete_flg: label2value(__userRoles.role.label, record),
        _version: __userRoles._version,
      };
      await API.graphql({ query: updateUserRoles, variables: { input: formData } });
      addNotification({
        type: 'success',
        message: 'ユーザーロールの編集に成功しました',
      });
      fetchUsers({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'ユーザーロールの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onCreate = async (role: Role) => {
    try {
      const formData = {
        user_id: currentUser.id,
        role_id: role.id,
        delete_flg: false,
      };
      await API.graphql({ query: createUserRoles, variables: { input: formData } });
      addNotification({
        type: 'success',
        message: 'ユーザーロールの編集に成功しました',
      });
      fetchUsers({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'ユーザーロールの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const getRoleChecked = (role: string) => {
    return currentUserRoles.some((userRoles: any) => userRoles.role.label === role && !userRoles.delete_flg);
  };

  const field2value: StringKeyObj = {
    user_email: currentUser.email,
    userRoles_IP: getRoleChecked('IP'),
    userRoles_PRODUCT: getRoleChecked('PRODUCT'),
    userRoles_ADMIN: getRoleChecked('ADMIN'),
    userRoles_COMPANY: getRoleChecked('COMPANY'),
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const doContinue = async () => {
    if (isLoginUser) {
      // ログインしているユーザー自身の編集画面では削除ボタンを非表示にしているため、ここには到達しない想定
      addNotification({
        type: 'error',
        message: 'ログインしているユーザーを削除することは出来ません',
      });
      return;
    }

    try {
      const formData = { id: currentUser.id, _version: currentUser._version };
      await API.graphql({
        query: deleteUser,
        variables: {
          input: formData,
        },
      });
      setConfirm(false);
      onClose();

      addNotification({
        type: 'success',
        message: 'ユーザーの削除に成功しました',
      });

      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchUsers({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'ユーザーの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const onDoConfirm = () => {
    setConfirm(true);
  };

  console.log({ currentUser });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="ユーザーロールの編集"
      doText="変更"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onSubmit)}
      onConfirm={!isLoginUser && onDoConfirm}
      height={360}
      width="lg"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 320 },
        }}
        width={340}
      >
        {EDIT_USER_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                field={row.field}
                control={control}
                disabled={row.disabled}
                errors={errors}
                validationRules={[]}
                value={field2value[row.field]?.toString()}
              />
            )}
            {row.type === 'checkbox' && (
              <FormCheckBox
                field={row.field}
                label={row.label}
                control={control}
                defaultValue={field2value[row.field]}
                inputLabel={row.inputLabel}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <ConfirmDialog
        message={`このユーザーを削除しても${'\n'}よろしいでしょうか？`}
        open={confirm}
        onCancel={closeConfirm}
        onContinue={doContinue}
        type={'warning'}
      />
    </FormDialog>
  );
}

export default EditUserDialog;
