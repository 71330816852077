export const EDIT_TAG_TITLE_CODE_FIELDS = [
  {
    field: 'seq_id',
    label: 'title-code_title-code-id',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'name_ja',
    label: 'title-code_title-code-name',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'tag',
    label: 'tag-title_ip-tag-search',
    type: 'autoComplete',
    optionsName: 'name_ja',
    placeholder: 'タグ名を入力',
    required: true,
  },
  {
    field: 'tag',
    label: 'tag-title_ip-tag-en-search',
    type: 'autoComplete',
    optionsName: 'name_en',
    placeholder: '',
    required: true,
  },
  {
    field: 'updated_user_id',
    label: 'tag-title_tagged-last-updated-by',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'updatedAt',
    label: 'tag-title_tagged-last-modified',
    type: 'datetime',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'update_reason',
    label: '更新理由',
    type: 'text',
    placeholder: '更新理由を入力',
  },
];

export const SEARCH_TAG_TITLE_CODE_FIELDS = [
  {
    field: 'seq_id',
    label: 'タイトルコードID',
    type: 'text',
    placeholder: 'タイトルコードIDを入力',
  },
  {
    field: 'name_ja',
    label: 'タイトルコード名称',
    type: 'text',
    placeholder: 'タイトルコード名称を入力',
  },
  {
    field: 'tags_ja',
    label: 'tag-title_ip-tag',
    type: 'text',
    placeholder: 'IPタグ（日本語）を入力',
  },
  {
    field: 'tags_en',
    label: 'tag-title_ip-tag-en',
    type: 'text',
    placeholder: 'IPタグ（英語）を入力',
  },
  { field: 'is_unregistered', label: '未登録を検索', type: 'checkbox' },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'タイトルコード最終更新日時' },
      { field: 'to', label: 'タイトルコード最終更新日時' },
    ],
  },
  {
    field: 'update_reason',
    label: '更新理由',
    type: 'text',
    placeholder: '更新理由を入力',
  },
];

export const SEARCH_TAG_TITLE_CODE_FIELDS_DEFAULT_VALUES = {
  seq_id: '',
  name_ja: '',
  tags_ja: '',
  tags_en: '',
  updated_user_id: '',
  from: null,
  to: null,
  update_reason: '',
  is_unregistered: false,
};
