export const EDIT_TAG_BNF_FIELDS = [
  {
    field: "bnf_seq_id",
    label: "BNFマスターID",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "bnf_data_source",
    label: "BNFデータソース",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "obic7_commodity_cd",
    label: "OBIC7商品コード",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "obic7_commodity_name",
    label: "OBIC7商品名",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "bvics_plan_cd_4char",
    label: "BVICS企画コード(4桁)",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "bvics_plan_cd_7char",
    label: "BVICS企画コード(7桁)",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "bvics_product_name",
    label: "BVICS製品名",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "tag",
    label: "IPタグ（日本語）",
    type: "autoComplete",
    optionsName: "name_ja",
    placeholder: "IPタグ（日本語）を入力",
    required: true,
  },
  {
    field: "tag",
    label: "IPタグ（英語）",
    type: "autoComplete",
    optionsName: "name_en",
    placeholder: "IPタグ（英語）を入力",
    required: true,
  },
  {
    field: "updated_user_id",
    label: "タグ付け最終更新者",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "タグ付け最終更新日時",
    type: "datetime",
    placeholder: "",
    disabled: true,
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
];

export const SEARCH_TAG_BNF_FIELDS = [
  {
    field: "bnf_seq_id",
    label: "BNFマスターID",
    type: "text",
    placeholder: "BNFマスターIDを入力",
  },
  {
    field: "bnf_data_source",
    optionsName: "bnf_data_source",
    label: "BNFデータソース",
    type: "select",
    placeholder: "BNFデータソースを入力",
  },
  {
    field: "obic7_commodity_cd",
    label: "OBIC7商品コード",
    type: "text",
    placeholder: "OBIC7商品コードを入力",
  },
  {
    field: "obic7_commodity_name",
    label: "OBIC7商品名",
    type: "text",
    placeholder: "OBIC7商品名を入力",
  },
  {
    field: "bvics_plan_cd_4char",
    label: "BVICS企画コード(4桁)",
    type: "text",
    placeholder: "BVICS企画コード(4桁)を入力",
  },
  {
    field: "bvics_plan_cd_7char",
    label: "BVICS企画コード(7桁)",
    type: "text",
    placeholder: "BVICS企画コード(7桁)を入力",
  },
  {
    field: "bvics_product_name",
    label: "BVICS製品名",
    type: "text",
    placeholder: "BVICS製品名を入力",
  },
  {
    field: "tags_ja",
    label: "IPタグ（日本語）",
    type: "text",
    placeholder: "IPタグ（日本語）を入力",
  },
  {
    field: "tags_en",
    label: "IPタグ（英語）",
    type: "text",
    placeholder: "IPタグ（英語）を入力",
  },
  { field: "is_unregistered", label: "未登録を検索", type: "checkbox" },
  {
    field: "updatedAt",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "BNFマスター最終更新日" },
      { field: "to", label: "BNFマスター最終更新日" },
    ],
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
];

export const SEARCH_TAG_BNF_FIELDS_DEFAULT_VALUES = {
  bnf_seq_id: "",
  bnf_data_source: "",
  obic7_commodity_cd: "",
  obic7_commodity_name: "",
  bvics_plan_cd_4char: "",
  bvics_plan_cd_7char: "",
  bvics_product_name: "",
  tags_ja: "",
  tags_en: "",
  is_unregistered: false,
  from: null,
  to: null,
  update_reason: "",
};
