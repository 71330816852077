import React from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridCellParams } from '@mui/x-data-grid-pro';

import ExportButton from '../components/ExportButton';
import usePageSize from '../hooks/usePageSize';
import { PRODUCT_EXPORT_ROWS } from '../consts/export';
import { I18n } from 'aws-amplify/utils';

const columns = [
  {
    field: 'label',
    headerName: 'テーブル名',
    width: 300,
  },
  {
    field: 'button',
    headerName: '実行ボタン',
    width: 600,
    disableClickEventBubbling: true,
    renderCell: (params: GridCellParams) => <ExportButton tableName={params.row.name} type="ALL" />,
  },
];

function ProductCsvExportList() {
  const { pageSize, setPageSize } = usePageSize();

  const onChangePageSize = (num: number) => {
    setPageSize(num);
  };

  return (
    <Box sx={{ p: 1 }}>
      <div
        style={{
          height: 'calc(100vh - 170px)',
          width: '100%',
          marginTop: '75px',
        }}
      >
        <DataGridPro
          rows={PRODUCT_EXPORT_ROWS.map((row) => ({
            ...row,
            label: I18n.get(row.label),
          }))}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(num) => onChangePageSize(num)}
          rowsPerPageOptions={[50, 100, 150]}
          pagination
          getRowId={(row) => row.id}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
        />
      </div>
    </Box>
  );
}

export default ProductCsvExportList;
