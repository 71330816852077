import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Notification from '../components/Notification';
import { LanguageContext } from '../contexts/LanguageContext';

function DefaultPage() {
  const { language, changeLanguage } = useContext(LanguageContext);

  return (
    <div>
      <Header />
      <main key={language}>
        <Outlet />
      </main>
      <Footer />
      <Notification />
    </div>
  );
}

export default DefaultPage;
