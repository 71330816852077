import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { I18n } from 'aws-amplify/utils';

import LinkRouter from './LinkRouter';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Box,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid-pro';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export interface NavigationBoxProps {
  title: string;
  contents: { name: string; path?: string; children?: { name: string; path: string }[] }[];
}

const SytyledLabel = styled('div')(({ theme }) => ({
  background: theme.colors.defaultHeaderBg,
  height: 39,
  lineHeight: '39px',
  paddingLeft: 14,
  width: '100%',
}));

const TextSecondary = styled('p')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledUl = styled('ul')(() => ({
  paddingLeft: 5,
  minWidth: 300,
  height: '85%',
  overflow: 'auto',
}));

const StyledLi = styled('li')(() => ({
  listStyle: 'none',
  marginBottom: 20,
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    '&:before': {
      display: 'none',
    },
    marginBottom: 20,
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  height: 19,
  minHeight: 19,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  boxShadow: 'none',
  padding: 0,
  color: theme.palette.text.secondary,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: 0,
}));

function NavigationBox(props: NavigationBoxProps) {
  const { title, contents } = props;

  return (
    <Paper elevation={3}>
      <SytyledLabel>
        <TextSecondary>{I18n.get(title)}</TextSecondary>
      </SytyledLabel>
      <StyledUl>
        {contents.map((content, index) =>
          content.children ? (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>{I18n.get(content.name)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {content.children.map((c, idx) => (
                  <Box key={`${index}-${idx}`}>
                    <StyledLi key={c.path}>
                      <LinkRouter underline="hover" color="primary" to={c.path}>
                        {I18n.get(c.name)}
                      </LinkRouter>
                    </StyledLi>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ) : (
            <Box key={index}>
              <StyledLi key={content.path}>
                <LinkRouter underline="hover" color="primary" to={content.path}>
                  {I18n.get(content.name)}
                </LinkRouter>
              </StyledLi>
            </Box>
          )
        )}
      </StyledUl>
    </Paper>
  );
}

export default NavigationBox;
