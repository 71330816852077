import React, { useMemo } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumbs } from '@mui/material';

import LinkRouter from '../LinkRouter';
import {
  REGEXP_DEVELOP_HOST,
  REGEXP_FEATURE_HOST,
  REGEXP_MAIN_HOST,
  REGEXP_STG_HOST,
  REGEXP_SKRIT_HOST,
} from '../../consts/common';
import { BREADCRUMBS_EXCLUDE_PATHS, BREADCRUMBS_ROUTES } from '../../consts/routes';
import { I18n } from 'aws-amplify/utils';

function BreadCrumbs() {
  const breadcrumbs = useBreadcrumbs(
    BREADCRUMBS_ROUTES.map((crumb) => ({ ...crumb, breadcrumb: I18n.get(crumb.breadcrumb) })),
    { excludePaths: BREADCRUMBS_EXCLUDE_PATHS }
  );

  const locationName = useMemo(() => {
    switch (true) {
      case REGEXP_FEATURE_HOST.test(location.host):
        return '（開発ブランチ）';
      case REGEXP_DEVELOP_HOST.test(location.host):
        return '（開発環境）';
      case REGEXP_MAIN_HOST.test(location.host):
        return '（受入環境）';
      case REGEXP_STG_HOST.test(location.host):
        return '（検証環境）';
      case REGEXP_SKRIT_HOST.test(location.host):
        return '（本番環境）';
      default:
        return '';
    }
  }, []);

  return (
    <Breadcrumbs>
      {breadcrumbs.map(({ match, breadcrumb, location }, index) => {
        const paths = location.pathname.split('/').filter((path) => path && !BREADCRUMBS_EXCLUDE_PATHS.includes(path));
        const last = index === paths.length;
        const isRoot = index === 0;

        return last ? (
          <span key={match.pathname}>
            {breadcrumb}
            {isRoot && locationName}
          </span>
        ) : (
          <span key={match.pathname}>
            <LinkRouter color="primary" to={match.pathname}>
              {breadcrumb}
              {isRoot && locationName}
            </LinkRouter>
          </span>
        );
      })}
    </Breadcrumbs>
  );
}

export default BreadCrumbs;
