import { useEffect, useState } from "react"

const pageOptions = [50, 100, 150]
const defaultPageSise = 50

const usePageSize = () => {
  const localStoragePageSize = localStorage.getItem('pageSize')
  const [pageSizeState, setPageSizeState] = useState(Number(localStoragePageSize ?? defaultPageSise))

  useEffect(() => {
    // localStorageに値があればpageSizeの初期値に設定する
    if (!localStoragePageSize) return
    const pagesizeNumber = Number(localStoragePageSize)
    if (!pageOptions.includes(pagesizeNumber)) return
    setPageSizeState(pagesizeNumber)
  }, [localStoragePageSize])

  const setPageSize = (pageSize: number) => {
    localStorage.setItem('pageSize', String(pageSize))
    setPageSizeState(pageSize)
  }

  return { pageSize: pageSizeState, setPageSize }
}

export default usePageSize
