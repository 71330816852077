import React, { useCallback, useState, useEffect, useContext } from 'react';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format, parseISO } from 'date-fns';
import PrimaryButton from '../components/PrimaryButton';
import { AuthContext } from '../contexts/AuthContext';
import { filterColumnsForGeneralRole } from '../utils/filterColumns'
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { theme } from '../assets/theme/options';
import { useSearchBnmls } from '../hooks/useSearchBnmls';
import EditBnmlDialog from '../components/bnml/EditBnmlDialog';
import SearchBnmlDialog from '../components/bnml/SearchBnmlDialog';
import { Bnml } from '../models';
import NewBnmlDialog from '../components/bnml/NewBnmlDialog';



const columns: GridColDef[] = [
  { field: 'seq_id', headerName: 'BNMLマスターID', width: 150 },
  {
    field: 'mlics_plan_cd_4char',
    headerName: 'MLICS企画コード(4桁)',
    width: 260,
  },
  {
    field: 'mlics_plan_cd_7char',
    headerName: 'MLICS企画コード(7桁)',
    width: 260,
  },
  {
    field: 'mlics_commodity_name',
    headerName: 'MLICS商品名',
    width: 260,
  },
  {
    field: 'admin_check_flg',
    headerName: '管理者チェック済フラグ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
  {
    field: 'updated_user_id',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    },
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function BnmlList() {
  const [row, setRow] = useState<Bnml | null>(null)
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { user, roles } = useContext(AuthContext);
  const { fetchBnmls, loading, total, pageSize, onChangePageSize, bnmls, setBnmls, onChangePage, condition, page, onChangeSort, searchFilter } = useSearchBnmls()
  const [isUploaded, setIsUploaded] = useState(false)

  const roleTypes = roles?.map((role) => role.role?.label);

  useEffect(() => {
    fetchBnmls({})
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isUploaded) return
    fetchBnmls({})
    setIsUploaded(false)
    // eslint-disable-next-line
  }, [isUploaded])

  // 一般ユーザの時は削除済データのカラムを非表示にする
  const removeColumns = ["delete_flg"]
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns)

  const doOpenNew = (params: any) => {
    setOpenNew(true);
  };

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true)
  }, [])


  if (!roleTypes) return <></>;
  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1, width: 800 }}>
          <Box>
            <PrimaryButton onClick={doOpenNew}>新規作成</PrimaryButton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
            <ExportButton
              condition={searchFilter}
              tableName="BNML"
              type='SEARCH'
            />
            <ImportButton tableName="BNML" setIsUploaded={setIsUploaded} />
          </Box>
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>

      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={bnmls}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openNew && user && (
        <NewBnmlDialog
          open={openNew}
          setOpen={setOpenNew}
          user={user}
          fetchBnmls={fetchBnmls}
        />
      )}
      {row && user && (
        <EditBnmlDialog
          open={openEdit}
          row={row}
          setOpen={setOpenEdit}
          setRow={setRow}
          user={user}
          fetchBnmls={fetchBnmls}
        />
      )
      }
      <SearchBnmlDialog
        condition={condition}
        isAdmin={roleTypes.includes('ADMIN')}
        open={openSearch}
        setOpen={setOpenSearch}
        fetchBnmls={fetchBnmls}
      />
    </Box>
  );
}

export default BnmlList;
