import { generateSearchFilter, SEARCH_TYPE } from './search';
import {
  searchBsps,
  searchCurrencies,
  searchTitles,
  searchCountries,
  searchTitleCodes,
  searchBnams,
  searchBnmls,
  searchBnfs,
} from '../graphql/queries';
import { Currency, Country } from '../models';
import { sortByOrderIdAndCode } from './sortByOrderIdAndCode';
import type {
  TitleSearch,
  ProductSearch,
  TagBspSearch,
  TagBnmlSearch,
  TagBnfSearch,
  TagTitleSearch,
} from '../types/form';
import type {
  SearchTitlesQueryVariables,
  SearchTitlesQuery,
  SearchTagsQuery,
  SearchTagsQueryVariables,
  SearchTagTitlesQueryVariables,
  SearchTagTitlesQuery,
  SearchTitleCodesQueryVariables,
  SearchTitleCodesQuery,
  SearchTagTitleCodesQuery,
  SearchTagTitleCodesQueryVariables,
  SearchBnamsQuery,
  SearchBnamsQueryVariables,
  SearchTagBnamsQuery,
  SearchTagBnamsQueryVariables,
} from '../API';
import type { GraphQLResult } from '@aws-amplify/api';
import { SearchConditionTitleCode, SearchConditionConfigTitleCode } from '../types/form/titleCode';
import { TagTitleCodeSearch } from '../types/form/tagTitleCodeSearch';
import { ProductTcdSearch } from '../types/form/productTcd';
import { TagBnamSearch } from '../types/form/tagBnamSearch';
import {
  searchTagBnamsWithoutOhterRelations,
  searchTagBnfsWithoutOhterRelations,
  searchTagBnmlsWithoutOhterRelations,
  searchTagBspsWithoutOhterRelations,
  searchTagsMinimumRelations,
  searchTagTitleCodesWithoutOhterRelations,
  searchTagTitlesWithoutOhterRelations,
} from '../graphql/custom-queries';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

export const dataToFilterTitleObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  name_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  name_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  search_name: { name: 'search_name', type: SEARCH_TYPE.WILDCARD },
  genre_id: { name: 'genre_id', type: SEARCH_TYPE.WILDCARD },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  project_cd: { name: 'project_cd', type: SEARCH_TYPE.WILDCARD },
  sap_title_code: { name: 'sap_title_code', type: SEARCH_TYPE.WILDCARD },
  data_source_id: { name: 'data_source_id', type: SEARCH_TYPE.EQ },
  bn_connect_title_id: {
    name: 'bn_connect_title_id',
    type: SEARCH_TYPE.WILDCARD,
  },
  banasuke_title_id: { name: 'banasuke_title_id', type: SEARCH_TYPE.WILDCARD },
  ce_link_key_code: { name: 'ce_link_key_code', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
  system_update_flg: { name: 'system_update_flg', type: SEARCH_TYPE.EQ },
};

const dataToFilterProductObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  product_key: { name: 'product_key', type: SEARCH_TYPE.WILDCARD },
  name: { name: 'name', type: SEARCH_TYPE.WILDCARD },
  common_name: { name: 'common_name', type: SEARCH_TYPE.WILDCARD },
  title_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  title_name: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  product_type_id: { name: 'product_type_id', type: SEARCH_TYPE.EQ },
  product_class_id: { name: 'product_class_id', type: SEARCH_TYPE.EQ },
  market: { name: 'market', type: SEARCH_TYPE.WILDCARD },
  platform_id: { name: 'platform_id', type: SEARCH_TYPE.EQ },
  data_source_id: { name: 'data_source_id', type: SEARCH_TYPE.EQ },
  base_price_from: { name: 'base_price', type: SEARCH_TYPE.GTE },
  base_price_to: { name: 'base_price', type: SEARCH_TYPE.LTE },
  currency_id: { name: 'currency_id', type: SEARCH_TYPE.EQ },
  release_status: { name: 'release_status', type: SEARCH_TYPE.WILDCARD },
  info_release_date_from: { name: 'info_release_date', type: SEARCH_TYPE.GTE },
  info_release_date_to: { name: 'info_release_date', type: SEARCH_TYPE.LTE },
  preorder_start_date_from: {
    name: 'preorder_start_date',
    type: SEARCH_TYPE.GTE,
  },
  preorder_start_date_to: {
    name: 'preorder_start_date',
    type: SEARCH_TYPE.LTE,
  },
  release_date_from: { name: 'release_date', type: SEARCH_TYPE.GTE },
  release_date_to: { name: 'release_date', type: SEARCH_TYPE.LTE },
  service_start_date_from: {
    name: 'service_start_date',
    type: SEARCH_TYPE.GTE,
  },
  service_start_date_to: { name: 'service_start_date', type: SEARCH_TYPE.LTE },
  service_end_date_from: { name: 'service_end_date', type: SEARCH_TYPE.GTE },
  service_end_date_to: { name: 'service_end_date', type: SEARCH_TYPE.LTE },
  support_end_date_from: { name: 'support_end_date', type: SEARCH_TYPE.GTE },
  support_end_date_to: { name: 'support_end_date', type: SEARCH_TYPE.LTE },
  sku_number_apple: { name: 'sku_number_apple', type: SEARCH_TYPE.WILDCARD },
  app_id: { name: 'app_id', type: SEARCH_TYPE.WILDCARD },
  package_name_google: {
    name: 'package_name_google',
    type: SEARCH_TYPE.WILDCARD,
  },
  bank_app_id: { name: 'bank_app_id', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  updated_reason: { name: 'updated_reason', type: SEARCH_TYPE.WILDCARD },
  system_update_flg: { name: 'system_update_flg', type: SEARCH_TYPE.EQ },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

const dataToFilterProductTcdObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  product_key: { name: 'product_key', type: SEARCH_TYPE.WILDCARD },
  name: { name: 'name', type: SEARCH_TYPE.WILDCARD },
  common_name: { name: 'common_name', type: SEARCH_TYPE.WILDCARD },
  title_code_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  title_code: { name: 'title_code', type: SEARCH_TYPE.WILDCARD },
  product_type_id: { name: 'product_type_id', type: SEARCH_TYPE.EQ },
  product_class_id: { name: 'product_class_id', type: SEARCH_TYPE.EQ },
  market: { name: 'market', type: SEARCH_TYPE.WILDCARD },
  platform_id: { name: 'platform_id', type: SEARCH_TYPE.EQ },
  data_source_id: { name: 'data_source_id', type: SEARCH_TYPE.EQ },
  base_price_from: { name: 'base_price', type: SEARCH_TYPE.GTE },
  base_price_to: { name: 'base_price', type: SEARCH_TYPE.LTE },
  currency_id: { name: 'currency_id', type: SEARCH_TYPE.EQ },
  release_status: { name: 'release_status', type: SEARCH_TYPE.WILDCARD },
  info_release_date_from: { name: 'info_release_date', type: SEARCH_TYPE.GTE },
  info_release_date_to: { name: 'info_release_date', type: SEARCH_TYPE.LTE },
  preorder_start_date_from: {
    name: 'preorder_start_date',
    type: SEARCH_TYPE.GTE,
  },
  preorder_start_date_to: {
    name: 'preorder_start_date',
    type: SEARCH_TYPE.LTE,
  },
  release_date_from: { name: 'release_date', type: SEARCH_TYPE.GTE },
  release_date_to: { name: 'release_date', type: SEARCH_TYPE.LTE },
  service_start_date_from: {
    name: 'service_start_date',
    type: SEARCH_TYPE.GTE,
  },
  service_start_date_to: { name: 'service_start_date', type: SEARCH_TYPE.LTE },
  service_end_date_from: { name: 'service_end_date', type: SEARCH_TYPE.GTE },
  service_end_date_to: { name: 'service_end_date', type: SEARCH_TYPE.LTE },
  support_end_date_from: { name: 'support_end_date', type: SEARCH_TYPE.GTE },
  support_end_date_to: { name: 'support_end_date', type: SEARCH_TYPE.LTE },
  sku_number_apple: { name: 'sku_number_apple', type: SEARCH_TYPE.WILDCARD },
  app_id: { name: 'app_id', type: SEARCH_TYPE.WILDCARD },
  package_name_google: {
    name: 'package_name_google',
    type: SEARCH_TYPE.WILDCARD,
  },
  bank_app_id: { name: 'bank_app_id', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  system_update_flg: { name: 'system_update_flg', type: SEARCH_TYPE.EQ },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

export const dataToFilterTagObj: StringKeyObj = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.EQ },
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  name_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  name_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

export const dataToFilterTagTitleObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  title_name: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  tags_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  tags_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
};

export const dataToFilterTagTitleCodeObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  name_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  tags_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  tags_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
};

export const dataToFilterTagBnamObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  ip_name: { name: 'ip_name', type: SEARCH_TYPE.WILDCARD },
  tags_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  tags_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
};

export const dataToFilterBnfObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  bnf_data_source: { name: 'bnf_data_source', type: SEARCH_TYPE.EQ },
  obic7_commodity_cd: {
    name: 'obic7_commodity_cd',
    type: SEARCH_TYPE.WILDCARD,
  },
  obic7_commodity_name: {
    name: 'obic7_commodity_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  bvics_plan_cd_4char: {
    name: 'bvics_plan_cd_4char',
    type: SEARCH_TYPE.WILDCARD,
  },
  bvics_plan_cd_7char: {
    name: 'bvics_plan_cd_7char',
    type: SEARCH_TYPE.WILDCARD,
  },
  bvics_product_name: {
    name: 'bvics_product_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

export const dataToFilterBnfHistoriesObj: StringKeyObj = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.EQ },
  ...dataToFilterBnfObj,
};

export const dataToFilterBspObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  character_name: { name: 'character_name', type: SEARCH_TYPE.WILDCARD },
  character_code: { name: 'character_code', type: SEARCH_TYPE.WILDCARD },
  character_series_name: {
    name: 'character_series_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  character_series_code: {
    name: 'character_series_code',
    type: SEARCH_TYPE.WILDCARD,
  },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  system_update_flg: { name: 'system_update_flg', type: SEARCH_TYPE.EQ },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

const dataToFilterTagBnmlObj: StringKeyObj = {
  bnml_seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  mlics_plan_cd_4char: { name: 'mlics_plan_cd_4char', type: SEARCH_TYPE.EQ },
  mlics_plan_cd_7char: { name: 'mlics_plan_cd_7char', type: SEARCH_TYPE.EQ },
  mlics_commodity_name: {
    name: 'mlics_commodity_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  tags_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  tags_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
};

export const dataToFilterBnmlObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  mlics_plan_cd_4char: {
    name: 'mlics_plan_cd_4char',
    type: SEARCH_TYPE.WILDCARD,
  },
  mlics_plan_cd_7char: {
    name: 'mlics_plan_cd_7char',
    type: SEARCH_TYPE.WILDCARD,
  },
  mlics_commodity_name: {
    name: 'mlics_commodity_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
};

export const dataToFilterTagBnfObj: StringKeyObj = {
  bnf_seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  bnf_data_source: { name: 'bnf_data_source', type: SEARCH_TYPE.WILDCARD },
  obic7_commodity_cd: {
    name: 'obic7_commodity_cd',
    type: SEARCH_TYPE.WILDCARD,
  },
  obic7_commodity_name: {
    name: 'obic7_commodity_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  bvics_plan_cd_4char: {
    name: 'bvics_plan_cd_4char',
    type: SEARCH_TYPE.WILDCARD,
  },
  bvics_plan_cd_7char: {
    name: 'bvics_plan_cd_7char',
    type: SEARCH_TYPE.WILDCARD,
  },
  bvics_product_name: {
    name: 'bvics_product_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
  tags_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  tags_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
};

export const dataToFilterBnamObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  ip_name: { name: 'ip_name', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
};

const dataToFilterTagBspObj: StringKeyObj = {
  bsp_seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  bsp_character_name: { name: 'character_name', type: SEARCH_TYPE.WILDCARD },
  tags_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  tags_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
};

export const dataToFilterRateObj: StringKeyObj = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  source_currency_id: {
    name: 'source_currency_id',
    type: SEARCH_TYPE.WILDCARD,
  },
  converted_currency_id: {
    name: 'converted_currency_id',
    type: SEARCH_TYPE.WILDCARD,
  },
  from_date: { name: 'from_date', type: SEARCH_TYPE.GTE },
  from_date_from: { name: 'from_date', type: SEARCH_TYPE.GTE },
  from_date_to: { name: 'from_date', type: SEARCH_TYPE.LTE },
  to_date_from: { name: 'to_date', type: SEARCH_TYPE.GTE },
  to_date_to: { name: 'to_date', type: SEARCH_TYPE.LTE },
  to_date: { name: 'to_date', type: SEARCH_TYPE.LTE },
  exchange_rate: { name: 'exchange_rate', type: SEARCH_TYPE.EQ },
  company_name: { name: 'company_name', type: SEARCH_TYPE.WILDCARD },
  usage: { name: 'usage', type: SEARCH_TYPE.WILDCARD },
  description: { name: 'description', type: SEARCH_TYPE.WILDCARD },
  exchange_rate_type: {
    name: 'exchange_rate_type',
    type: SEARCH_TYPE.WILDCARD,
  },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

export const dataToFilterTitleCodeObj: SearchConditionConfigTitleCode = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  title_code: { name: 'title_code', type: SEARCH_TYPE.WILDCARD },
  name_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  name_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  official_name_flg: { name: 'official_name_flg', type: SEARCH_TYPE.EQ },
  search_name: { name: 'search_name', type: SEARCH_TYPE.WILDCARD },
  genre_ids: { name: 'genre_ids', type: SEARCH_TYPE.WILDCARD },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  project_cd: { name: 'project_cd', type: SEARCH_TYPE.WILDCARD },
  wbs: { name: 'wbs', type: SEARCH_TYPE.WILDCARD },
  sap_title_code_name: {
    name: 'sap_title_code_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  data_source_id: { name: 'data_source_id', type: SEARCH_TYPE.EQ },
  bn_connect_title_ids: {
    name: 'bn_connect_title_ids',
    type: SEARCH_TYPE.WILDCARD,
  },
  ce_link_title_name_ja: {
    name: 'ce_link_title_name_ja',
    type: SEARCH_TYPE.WILDCARD,
  },
  ce_link_title_name_en: {
    name: 'ce_link_title_name_en',
    type: SEARCH_TYPE.WILDCARD,
  },
  banasuke_title_id: { name: 'banasuke_title_id', type: SEARCH_TYPE.WILDCARD },
  banasuke_title_name_ja: {
    name: 'banasuke_title_name_ja',
    type: SEARCH_TYPE.WILDCARD,
  },
  bundle_source_title_code_ids: {
    name: 'bundle_source_title_code_ids',
    type: SEARCH_TYPE.WILDCARD,
  },
  porting_source_title_code_ids: {
    name: 'porting_source_title_code_ids',
    type: SEARCH_TYPE.WILDCARD,
  },
  business_entity_id: {
    name: 'business_entity_id',
    type: SEARCH_TYPE.WILDCARD,
  },
  valid_flg: { name: 'valid_flg', type: SEARCH_TYPE.EQ },
  platform_ids: { name: 'platform_ids', type: SEARCH_TYPE.WILDCARD },
  ce_link_key_code: { name: 'ce_link_key_code', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
  system_update_flg: { name: 'system_update_flg', type: SEARCH_TYPE.EQ },
};

export const dataToFilterDataLakeJobObj: StringKeyObj = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.WILDCARD },
  status: { name: 'status', type: SEARCH_TYPE.WILDCARD },
  message: { name: 'message', type: SEARCH_TYPE.WILDCARD },
  error_log: { name: 'error_log', type: SEARCH_TYPE.WILDCARD },
  createdAt: { name: 'createdAt', type: SEARCH_TYPE.GTE },
};

export const sleep = (waitTime: number) => new Promise((resolve) => setTimeout(resolve, waitTime));

const searchTitleGetIds = async (condition: ProductSearch) => {
  try {
    const titleQueries = Object.entries(condition)
      .map(([key, value]) => {
        if (['title_name', 'title_id'].includes(key)) {
          console.log(`dataToFilterProductObj[key].name => ${dataToFilterProductObj[key].name} \n value => ${value}`);
          return {
            name: dataToFilterProductObj[key].name,
            value: value,
            type: dataToFilterProductObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const filter = generateSearchFilter(titleQueries);
    const models: any = await API.graphql({ query: searchTitles, variables: filter });
    const titleIds = models.data.searchTitles.items.map((item: any) => item.id);
    if (titleIds.length == 0) {
      // タイトルIDが存在しなかった時に空配列を返却すると全データが表示されるので、空文字を入れた配列を返却する
      const noTitleIds = [''];
      return noTitleIds;
    } else {
      return titleIds;
    }
  } catch (e) {
    console.log(e);
  }
};

const searchTitleCodeGetIds = async (condition: ProductTcdSearch) => {
  try {
    const titleCodeQueries = Object.entries(condition)
      .map(([key, value]) => {
        if (['title_code_id', 'title_code'].includes(key)) {
          return {
            name: dataToFilterProductTcdObj[key].name,
            value: value,
            type: dataToFilterProductTcdObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const filter = generateSearchFilter(titleCodeQueries);
    const models: any = await API.graphql({ query: searchTitleCodes, variables: filter });
    const titleCodeIds = models.data.searchTitleCodes.items.map((item: any) => item.id);
    if (titleCodeIds.length == 0) {
      // タイトルIDが存在しなかった時に空配列を返却すると全データが表示されるので、空文字を入れた配列を返却する
      const noTitleIds = [''];
      return noTitleIds;
    } else {
      return titleCodeIds;
    }
  } catch (e) {
    console.log(e);
  }
};

const searchSetTtiles = async (condition: TitleSearch, onChangeFilter: Function, setExpQueries: Function) => {
  try {
    const searchQueries = Object.entries(condition)
      .map(([key, value]) => {
        if (value === 'ALL' && key === 'system_update_flg') return;
        if (value === 'ALL' && key === 'admin_check_flg') return;
        if (value === 'ALL' && key === 'delete_flg') return;
        return {
          name: dataToFilterTitleObj[key].name,
          value: value,
          type: dataToFilterTitleObj[key].type,
        };
      })
      .filter(Boolean);

    const { filter } = generateSearchFilter(searchQueries);
    onChangeFilter(filter);
    setExpQueries(searchQueries);
  } catch (e) {
    console.log(e);
  }
};

export const fetchTitles = async (
  condition: TitleSearch,
  setCondition: Function,
  onChangeFilter: Function,
  setExpQueries: Function
) => {
  console.log({ condition });
  try {
    await searchSetTtiles(condition, onChangeFilter, setExpQueries);
    setCondition(condition);
  } catch (e) {
    console.log(e);
  }
};

const searchSetProducts = async (
  condition: ProductSearch,
  titleIds: string[],
  onChangeFilter: Function,
  setExpQueries: Function
) => {
  try {
    const preQueries = Object.entries(condition)
      .map(([key, value]) => {
        if (value === 'ALL' && key === 'system_update_flg') return;
        if (value === 'ALL' && key === 'admin_check_flg') return;
        if (value === 'ALL' && key === 'delete_flg') return;
        if (key === 'title_name' || key === 'title_id') return;
        return {
          name: dataToFilterProductObj[key].name,
          value: value,
          type: dataToFilterProductObj[key].type,
        };
      })
      .filter(Boolean);
    const customQuery = {
      name: 'title_id',
      value: titleIds,
      type: SEARCH_TYPE.OR_EQ,
    };
    const searchQueries = !titleIds.length ? preQueries : [...preQueries, customQuery];
    const { filter } = generateSearchFilter(searchQueries);
    onChangeFilter(filter);
    // exportで使用するクエリをセット
    setExpQueries(searchQueries);
  } catch (e) {
    console.log(e);
  }
};

const searchSetProductTcds = async (
  condition: ProductTcdSearch,
  titleCodeIds: string[],
  onChangeFilter: Function,
  setExpQueries: Function
) => {
  try {
    const preQueries = Object.entries(condition)
      .map(([key, value]) => {
        if (value === 'ALL' && key === 'system_update_flg') return;
        if (value === 'ALL' && key === 'admin_check_flg') return;
        if (value === 'ALL' && key === 'delete_flg') return;
        if (key === 'title_code_id' || key === 'title_code') return;
        return {
          name: dataToFilterProductTcdObj[key].name,
          value: value,
          type: dataToFilterProductTcdObj[key].type,
        };
      })
      .filter(Boolean);
    const customQuery = {
      name: 'title_code_id',
      value: titleCodeIds,
      type: SEARCH_TYPE.OR_EQ,
    };
    const searchQueries = !titleCodeIds.length ? preQueries : [...preQueries, customQuery];
    const { filter } = generateSearchFilter(searchQueries);
    onChangeFilter(filter);
    // exportで使用するクエリをセット
    setExpQueries(searchQueries);
  } catch (e) {
    console.log(e);
  }
};

export const fetchProducts = async (
  condition: ProductSearch,
  setCondition: Function,
  onChangeFilter: Function,
  setExpQueries: Function
) => {
  console.log({ condition });
  try {
    // 1. タイトル名、タイトルID(titleのseq_id)があれば、タイトルテーブルから合致するタイトルID一覧を取得
    let titleIds = condition.title_name || condition.title_id ? await searchTitleGetIds(condition) : [];
    // 2. 上記のタイトルIDと他のフィールドの検索結果を結合してタイトルテーブルで検索
    await searchSetProducts(condition, titleIds, onChangeFilter, setExpQueries);
    setCondition(condition);
  } catch (e) {
    console.log(e);
  }
};

export const fetchProductTcd = async (
  condition: ProductTcdSearch,
  setCondition: Function,
  onChangeFilter: Function,
  setExpQueries: Function
) => {
  console.log({ condition });
  try {
    // 1. タイトル名、タイトルID(titleのseq_id)があれば、タイトルテーブルから合致するタイトルID一覧を取得
    const titleCodeIds = condition.title_code_id || condition.title_code ? await searchTitleCodeGetIds(condition) : [];
    // 2. 上記のタイトルIDと他のフィールドの検索結果を結合してタイトルテーブルで検索
    await searchSetProductTcds(condition, titleCodeIds, onChangeFilter, setExpQueries);
    setCondition(condition);
  } catch (e) {
    console.log(e);
  }
};

const searchTagTitleGetTitleIds = async (data: TagTitleSearch, tagIds?: (string | undefined)[]) => {
  try {
    let titleIds = [];
    // 170件以上だとなぜかOR_EQで検索ができなくなるので条件分岐
    if (tagIds?.length > 170) {
      let hasNextPage = true;
      let nextToken = null;
      let tagTitles = [];
      const tagTitleRecords = [
        {
          name: 'update_reason',
          value: data.update_reason,
          type: SEARCH_TYPE.WILDCARD,
        },
      ];
      const filter = generateSearchFilter(tagTitleRecords);
      while (hasNextPage) {
        const models = (await API.graphql({
          query: searchTagTitlesWithoutOhterRelations,
          variables: {
            ...filter,
            sort: { direction: 'desc', field: 'updatedAt' },
            limit: 1000,
            nextToken: nextToken,
          },
        })) as GraphQLResult<SearchTagTitlesQuery>;
        const tagTitleList = models?.data?.searchTagTitles?.items;
        tagTitleList && (tagTitles = [...tagTitles, ...tagTitleList]);
        nextToken = models?.data?.searchTagTitles?.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      }
      tagTitles = tagTitles.filter((item) => tagIds?.includes(item?.tag_id));
      titleIds = tagTitles.map((item: any) => item.title_id);
    } else {
      const tagTitleRecords = [
        {
          name: 'update_reason',
          value: data.update_reason,
          type: SEARCH_TYPE.WILDCARD,
        },
        { name: 'tag_id', value: tagIds, type: SEARCH_TYPE.OR_EQ },
      ];
      const filter = generateSearchFilter(tagTitleRecords);
      const models: any = await API.graphql({
        query: searchTagTitlesWithoutOhterRelations,
        variables: {
          ...filter,
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 1000,
        },
      });
      titleIds = models.data.searchTagTitles.items.map((item: any) => item.title_id);
    }
    return titleIds;
  } catch (e) {
    console.log(e);
  }
};

const searchSetTitles = async (
  data: TagTitleSearch,
  isUnregistered: boolean,
  setTitles: Function,
  titleIds?: (string | undefined)[]
) => {
  try {
    const titleRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'seq_id' || key === 'title_name' || key === 'from' || key === 'to') {
          return {
            name: dataToFilterTagTitleObj[key].name,
            value: value,
            type: dataToFilterTagTitleObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const titleRecordsAddDeleteFlag = [...titleRecords, { name: 'delete_flg', value: true, type: SEARCH_TYPE.NE }];
    const titleIdRecord = {
      name: 'id',
      value: titleIds,
      type: SEARCH_TYPE.OR_EQ,
    };
    const filter = generateSearchFilter(
      titleIds?.length > 170 ? titleRecordsAddDeleteFlag : [...titleRecordsAddDeleteFlag, titleIdRecord]
    );

    let hasNextPage = true;
    let nextToken = null;
    let titles = [];
    let filterModels = [];

    // 170件を超えるとOR_EQの検索が仕様上できなくなるので件数によって処理を分ける
    if (titleIds?.length > 170) {
      while (hasNextPage) {
        const models = (await API.graphql({
          query: searchTitles,
          variables: {
            ...filter,
            sort: { direction: 'desc', field: 'seq_id' },
            limit: 1000,
            nextToken: nextToken,
          } as SearchTitlesQueryVariables,
        })) as GraphQLResult<SearchTitlesQuery>;
        const titleList = models?.data?.searchTitles?.items;
        titleList && (titles = [...titles, ...titleList]);
        nextToken = models?.data?.searchTitles?.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      }
      filterModels = isUnregistered
        ? titles.filter((item) => !item?.tags?.items.length)
        : !titleIds || !titleIds.length
        ? titles.filter((item) => item?.tags?.items.length)
        : titles.filter((item) => titleIds.includes(item?.id) && item?.tags?.items.length);
    } else {
      const models = (await API.graphql({
        query: searchTitles,
        variables: {
          ...filter,
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 1000,
        } as SearchTitlesQueryVariables,
      })) as GraphQLResult<SearchTitlesQuery>;
      titles = models?.data?.searchTitles?.items;
      filterModels = isUnregistered ? titles.filter((item) => !item?.tags?.items.length) : titles;
    }

    if (!titles) setTitles([]);

    if (filterModels.length < 501) {
      setTitles(filterModels);
    } else {
      const copyFilterModels = [...filterModels];
      copyFilterModels.length = 500;
      setTitles(copyFilterModels);
    }
  } catch (e) {
    console.log(e);
  }
};

const searchTagGetIds = async (data: TagTitleSearch | TagTitleCodeSearch) => {
  try {
    const tagRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'tags_ja' || key === 'tags_en') {
          return {
            name: dataToFilterTagTitleObj[key].name,
            value: value,
            type: dataToFilterTagTitleObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const filter = generateSearchFilter(tagRecords);

    const tagIds = [];
    let hasNextPage = true;
    let nextToken = null;
    while (hasNextPage) {
      try {
        const models = (await API.graphql({
          query: searchTagsMinimumRelations,
          variables: {
            ...filter,
            limit: 1000,
            nextToken: nextToken,
          } as SearchTagsQueryVariables,
        })) as GraphQLResult<SearchTagsQuery>;
        const ids = models?.data?.searchTags?.items.map((item) => item?.id);
        ids && tagIds.push(...ids);
        nextToken = models?.data?.searchTags?.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      } catch (e) {
        console.error(e);
      }
    }
    return tagIds;
  } catch (e) {
    console.log(e);
  }
};

export const fetchTagTitles = async (
  condition: TagTitleSearch,
  setTitles: Function,
  setCondition: Function,
  onClose: Function
) => {
  console.log({ condition });
  try {
    if (condition.is_unregistered) {
      await searchSetTitles(condition, true, setTitles);
    } else if (condition.tags_ja || condition.tags_en) {
      // 1. タグ名で合致するタグのID一覧を取得
      const tagIds = await searchTagGetIds(condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (tagIds?.length === 0) {
        setTitles([]);
        setCondition(condition);
        onClose();
        return;
      }
      // 3. 上記のタグIDがあれば、タグタイトルテーブルから合致するタグタイトルのタイトルID一覧を取得
      const titleIds = await searchTagTitleGetTitleIds(condition, tagIds);
      // 4. 上記のタイトルIDがなければ、検索結果0で処理抜ける
      if (titleIds?.length === 0) {
        setTitles([]);
        setCondition(condition);
        onClose();
        return;
      }
      // 5. 上記のタイトルIDと他のフィールドの検索結果を合わせてタイトルテーブルで検索
      await searchSetTitles(condition, false, setTitles, titleIds);
    } else if (condition.update_reason) {
      // 1. 更新理由からタグTITLEのTITLE_IDを取得
      const titleIds = await searchTagTitleGetTitleIds(condition);
      // 2. 上記のタイトルIDがなければ、検索結果0で処理抜ける
      if (titleIds?.length === 0) {
        setTitles([]);
        setCondition(condition);
        onClose();
        return;
      }
      // 3. 上記のTITLE_IDと他のフィールドの検索結果を合わせてTITLEテーブルで検索
      await searchSetTitles(condition, false, setTitles, titleIds);
    } else {
      // 1. TITLEテーブルで検索
      await searchSetTitles(condition, false, setTitles);
    }
    setCondition(condition);
    onClose();
  } catch (e) {
    console.log(e);
  }
};

const searchTagTitleCodeGetTagIds = async (data: TagTitleCodeSearch, tagIds?: (string | undefined)[]) => {
  try {
    let titleCodeIds = [];

    const tagTitleCodeRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'update_reason') {
          return {
            name: dataToFilterTagTitleCodeObj[key].name,
            value: value,
            type: dataToFilterTagTitleCodeObj[key].type,
          };
        }
      })
      .filter(Boolean);

    const tagIdRecord = {
      name: 'tag_id',
      value: tagIds,
      type: SEARCH_TYPE.OR_EQ,
    };
    const filter = generateSearchFilter(
      tagIds?.length > 170 ? tagTitleCodeRecords : [...tagTitleCodeRecords, tagIdRecord]
    );

    // 170件以上だとなぜかOR_EQで検索ができなくなるので条件分岐
    if (tagIds?.length > 170) {
      let hasNextPage = true;
      let nextToken = null;
      let tagTitleCodes = [];
      while (hasNextPage) {
        const models = (await API.graphql({
          query: searchTagTitleCodesWithoutOhterRelations,
          variables: {
            ...filter,
            sort: { direction: 'desc', field: 'updatedAt' },
            limit: 600, // 600以上を指定すると検索実行時にエラーとなるため600を指定
            nextToken: nextToken,
          } as SearchTagTitleCodesQueryVariables,
        })) as GraphQLResult<SearchTagTitleCodesQuery>;
        const tagTitleCodeList = models?.data?.searchTagTitleCodes?.items;
        tagTitleCodeList && (tagTitleCodes = [...tagTitleCodes, ...tagTitleCodeList]);
        nextToken = models?.data?.searchTagTitleCodes?.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      }

      tagTitleCodes = tagTitleCodes.filter((item) => tagIds?.includes(item?.tag_id));
      titleCodeIds = tagTitleCodes.map((item: any) => item.title_code_id);
    } else {
      const models: any = await API.graphql({
        query: searchTagTitleCodesWithoutOhterRelations,
        variables: {
          ...filter,
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 1000,
        },
      });
      titleCodeIds = models.data.searchTagTitleCodes.items.map((item: any) => item.title_code_id);
    }
    return titleCodeIds;
  } catch (e) {
    console.log(e);
  }
};

const searchSetTagTitleCodes = async (
  data: TagTitleCodeSearch,
  isUnregistered: boolean,
  setTitleCodes: Function,
  setTitleCodeIds: Function,
  setExpQueries: Function,
  titleCodeIds?: (string | undefined)[]
) => {
  try {
    const titleCodeRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'seq_id' || key === 'name_ja' || key === 'from' || key === 'to') {
          return {
            name: dataToFilterTagTitleCodeObj[key].name,
            value: value,
            type: dataToFilterTagTitleCodeObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const titleCodeRecordsAddDeleteFlag = [
      ...titleCodeRecords,
      { name: 'delete_flg', value: true, type: SEARCH_TYPE.NE },
    ];
    const titleCodeIdRecord = {
      name: 'id',
      value: titleCodeIds,
      type: SEARCH_TYPE.OR_EQ,
    };
    const searchQueries =
      titleCodeIds == null || titleCodeIds.length < 1
        ? [...titleCodeRecordsAddDeleteFlag]
        : [...titleCodeRecordsAddDeleteFlag, titleCodeIdRecord];
    setExpQueries(searchQueries);
    const filter = generateSearchFilter(searchQueries);

    let hasNextPage = true;
    let nextToken = null;
    let titleCodes = [];
    let filterModels = [];

    // 170件を超えるとOR_EQの検索が仕様上できなくなるので件数によって処理を分ける
    if (titleCodeIds?.length > 170) {
      while (hasNextPage) {
        const models = (await API.graphql({
          query: searchTitleCodes,
          variables: {
            ...filter,
            sort: { direction: 'desc', field: 'seq_id' },
            limit: 1000,
            nextToken: nextToken,
          } as SearchTitleCodesQueryVariables,
        })) as GraphQLResult<SearchTitleCodesQuery>;
        const titleCodeList = models?.data?.searchTitleCodes.items;
        titleCodeList && (titleCodes = [...titleCodes, ...titleCodeList]);
        nextToken = models?.data?.searchTitleCodes?.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      }
      filterModels = isUnregistered
        ? titleCodes.filter((item) => !item?.tags?.items.length)
        : !titleCodeIds || !titleCodeIds.length
        ? titleCodes.filter((item) => item?.tags?.items.length)
        : titleCodes.filter((item) => titleCodeIds.includes(item?.id) && item?.tags?.items.length);
    } else {
      const models = (await API.graphql({
        query: searchTitleCodes,
        variables: {
          ...filter,
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 950,
        } as SearchTitleCodesQueryVariables,
      })) as GraphQLResult<SearchTitleCodesQuery>;
      titleCodes = models?.data?.searchTitleCodes?.items;
      console.log('titleCodes', titleCodes);
      filterModels = isUnregistered ? titleCodes.filter((item) => !item?.tags?.items.length) : titleCodes;
    }

    if (filterModels.length < 501) {
      setTitleCodes(filterModels);
    } else {
      const copyFilterModels = [...filterModels];
      copyFilterModels.length = 500;
      setTitleCodes(copyFilterModels);
    }

    // exportで使用するクエリをセット
    setTitleCodeIds(titleCodes.map((titleCode) => titleCode.id));
  } catch (e) {
    console.log(e);
  }
};

export const fetchTagTitleCodes = async (
  condition: TagTitleCodeSearch,
  setTitleCodes: Function,
  setCondition: Function,
  onClose: Function,
  setTitleCodeIds: Function,
  setExpQueries: Function
) => {
  console.log('condition', condition);
  try {
    if (condition.is_unregistered) {
      await searchSetTagTitleCodes(condition, true, setTitleCodes, setTitleCodeIds, setExpQueries);
    } else if (condition.tags_ja || condition.tags_en) {
      // 1. タグ名で合致するタグのID一覧を取得
      // FIXME: ここで取得したtagIdが検索して取得したいはずのタイトルコードに紐づくtagIdに合致しないため結果が0になる。
      const tagIds = await searchTagGetIds(condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (tagIds?.length === 0) {
        setTitleCodes([]);
        setCondition(condition);
        onClose();
        return;
      }
      // 3. 上記のタグIDがあれば、タグタイトルテーブルから合致するタグタイトルコードを取得
      const titleCodeIds = await searchTagTitleCodeGetTagIds(condition, tagIds);
      // 4. 上記のタイトルIDがなければ、検索結果0で処理抜ける
      if (titleCodeIds?.length === 0) {
        setTitleCodes([]);
        setCondition(condition);
        onClose();
        return;
      }
      // 5. 上記のタイトルコードIDと他のフィールドの検索結果を合わせてタイトルコードテーブルで検索
      await searchSetTagTitleCodes(condition, false, setTitleCodes, setTitleCodeIds, setExpQueries, titleCodeIds);
    } else if (condition.update_reason) {
      const titleCodeIds = await searchTagTitleCodeGetTagIds(condition);
      if (titleCodeIds?.length === 0) {
        setTitleCodes([]);
        setCondition(condition);
        onClose();
        return;
      }

      await searchSetTagTitleCodes(condition, false, setTitleCodes, setTitleCodeIds, setExpQueries, titleCodeIds);
    } else {
      // 1. TITLE_CODEテーブルで検索
      await searchSetTagTitleCodes(condition, false, setTitleCodes, setTitleCodeIds, setExpQueries);
    }
    setCondition(condition);
    onClose();
  } catch (e) {
    console.log(e);
  }
};

const searchSetBsps = async (
  setBsps: Function,
  data: TagBspSearch,
  isUnregistered: boolean,
  setExpQueries: Function,
  bspIds?: string[]
) => {
  try {
    const bspRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'bsp_seq_id' || key === 'bsp_character_name' || key === 'from' || key === 'to') {
          return {
            name: dataToFilterTagBspObj[key].name,
            value: value,
            type: dataToFilterTagBspObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const bspRecordsAddDeleteFlag = [...bspRecords, { name: 'delete_flg', value: true, type: SEARCH_TYPE.NE }];
    let customQuery = isUnregistered
      ? { name: 'id', value: bspIds, type: SEARCH_TYPE.OR_NE }
      : { name: 'id', value: bspIds, type: SEARCH_TYPE.OR_EQ };
    const searchQueries =
      !bspIds || !bspIds.length ? bspRecordsAddDeleteFlag : [...bspRecordsAddDeleteFlag, customQuery];
    const filter = generateSearchFilter(searchQueries);
    const models: any = await API.graphql({
      query: searchBsps,
      variables: {
        ...filter,
        sort: { direction: 'desc', field: 'seq_id' },
        limit: 500,
      },
    });
    setBsps(models.data.searchBsps.items);
    // exportで使用するクエリをセット
    setExpQueries(searchQueries);
  } catch (e) {
    console.log(e);
  }
};

const searchTagBspGetBspIds = async (isUnregistered: boolean, data: TagBspSearch, tagIds?: string[]) => {
  try {
    const tagBspRecords = [
      { name: 'tag_id', value: tagIds, type: SEARCH_TYPE.OR_EQ },
      {
        name: 'update_reason',
        value: data.update_reason,
        type: SEARCH_TYPE.WILDCARD,
      },
    ];
    const filter = isUnregistered ? { filter: { _deleted: { ne: true } } } : generateSearchFilter(tagBspRecords);
    const models: any = await API.graphql({
      query: searchTagBspsWithoutOhterRelations,
      variables: {
        ...filter,
        limit: 1000,
      },
    });
    const bspIds = models.data.searchTagBsps.items.map((item: any) => item.bsp_id);
    return bspIds;
  } catch (e) {
    console.log(e);
  }
};

const searchTagBspGetIds = async (data: TagBspSearch) => {
  try {
    const tagRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'tags_ja' || key === 'tags_en') {
          return {
            name: dataToFilterTagBspObj[key].name,
            value: value,
            type: dataToFilterTagBspObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const filter = generateSearchFilter(tagRecords);
    const models: any = await API.graphql({ query: searchTagsMinimumRelations, variables: { ...filter, limit: 1000 } });
    const tagIds = models.data.searchTags.items.map((item: any) => item.id);
    return tagIds;
  } catch (e) {
    console.log(e);
  }
};

export const fetchTagBsp = async (
  setBsps: Function,
  condition: TagBspSearch,
  setCondition: Function,
  setExpQueries: Function
) => {
  console.log({ condition });
  try {
    if (condition.is_unregistered) {
      // MEMO: タグ未登録の検索処理
      // 1. 削除されていないタグBSPのBSP_IDを取得
      const bspIds = await searchTagBspGetBspIds(true, condition);
      // 2. 上記のBSP_IDと他のフィールドの検索結果を合わせてBSPテーブルで検索
      await searchSetBsps(setBsps, condition, true, setExpQueries, bspIds);
    } else if (condition.tags_ja || condition.tags_en) {
      // MEMO: タグの検索処理あり&タグタイトルの更新理由ありorなし
      // 1. タグ名で合致するタグのID一覧を取得
      const tagIds = await searchTagBspGetIds(condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (tagIds.length === 0) {
        setBsps([]);
        setCondition(condition);
        return;
      }
      // 3. 上記のタグIDがあれば、タグBSPテーブルから合致するタグBSPのBSP_ID一覧を取得
      const bspIds = await searchTagBspGetBspIds(false, condition, tagIds);
      // 4. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (bspIds.length === 0) {
        setBsps([]);
        setCondition(condition);
        return;
      }
      // 5. 上記のBSP_IDと他のフィールドの検索結果を合わせてタイトルテーブルで検索
      await searchSetBsps(setBsps, condition, false, setExpQueries, bspIds);
    } else if (!condition.tags_ja && !condition.tags_en && condition.update_reason) {
      // MEMO: タグの検索処理なし&タグタイトルの更新理由あり
      // 1. 更新理由からタグBSPのBSP_IDを取得
      const bspIds = await searchTagBspGetBspIds(false, condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (bspIds.length === 0) {
        setBsps([]);
        setCondition(condition);
        return;
      }
      // 3. 上記のBSP_IDと他のフィールドの検索結果を合わせてBSPテーブルで検索
      await searchSetBsps(setBsps, condition, false, setExpQueries, bspIds);
    } else {
      // MEMO: タグの検索処理なし
      // 1. 上記のBSP_IDと他のフィールドの検索結果を合わせてBSPテーブルで検索
      await searchSetBsps(setBsps, condition, false, setExpQueries);
    }
    setCondition(condition);
  } catch (e) {
    console.log(e);
  }
};

const searchSetBnmls = async (
  setBnmls: Function,
  data: TagBnmlSearch,
  isUnregistered: boolean,
  setExpQueries: Function,
  bnmlIds?: string[]
) => {
  try {
    const bnmlRecords = Object.entries(data)
      .map(([key, value]) => {
        if (
          key === 'bnml_seq_id' ||
          key === 'mlics_plan_cd_4char' ||
          key === 'mlics_plan_cd_7char' ||
          key === 'mlics_commodity_name' ||
          key === 'from' ||
          key === 'to'
        ) {
          return {
            name: dataToFilterTagBnmlObj[key].name,
            value: value,
            type: dataToFilterTagBnmlObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const bnmlRecordsAddDeleteFlag = [...bnmlRecords, { name: 'delete_flg', value: true, type: SEARCH_TYPE.NE }];
    let customQuery = isUnregistered
      ? { name: 'id', value: bnmlIds, type: SEARCH_TYPE.OR_NE }
      : { name: 'id', value: bnmlIds, type: SEARCH_TYPE.OR_EQ };
    const searchQueries =
      !bnmlIds || !bnmlIds.length ? bnmlRecordsAddDeleteFlag : [...bnmlRecordsAddDeleteFlag, customQuery];
    const filter = generateSearchFilter(searchQueries);
    const models: any = await API.graphql({
      query: searchBnmls,
      variables: {
        ...filter,
        sort: { direction: 'desc', field: 'seq_id' },
        limit: 500,
      },
    });
    setBnmls(models.data.searchBnmls.items);
    // exportで使用するクエリをセット
    setExpQueries(searchQueries);
  } catch (e) {
    console.log(e);
  }
};

const searchTagBnmlGetBnmlIds = async (isUnregistered: boolean, data: TagBnmlSearch, tagIds?: string[]) => {
  try {
    const tagBnmlRecords = [
      { name: 'tag_id', value: tagIds, type: SEARCH_TYPE.OR_EQ },
      {
        name: 'update_reason',
        value: data.update_reason,
        type: SEARCH_TYPE.WILDCARD,
      },
    ];
    const filter = isUnregistered ? { filter: { _deleted: { ne: true } } } : generateSearchFilter(tagBnmlRecords);
    const models: any = await API.graphql({
      query: searchTagBnmlsWithoutOhterRelations,
      variables: {
        ...filter,
        limit: 1000,
      },
    });
    const bnmlIds = models.data.searchTagBnmls.items.map((item: any) => item.bnml_id);
    return bnmlIds;
  } catch (e) {
    console.log(e);
  }
};

const searchTagBnmlGetIds = async (data: TagBnmlSearch) => {
  try {
    const tagRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'tags_ja' || key === 'tags_en') {
          return {
            name: dataToFilterTagBnmlObj[key].name,
            value: value,
            type: dataToFilterTagBnmlObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const filter = generateSearchFilter(tagRecords);
    const models: any = await API.graphql({ query: searchTagsMinimumRelations, variables: { ...filter, limit: 1000 } });
    const tagIds = models.data.searchTags.items.map((item: any) => item.id);
    return tagIds;
  } catch (e) {
    console.log(e);
  }
};

export const fetchTagBnml = async (
  setBnmls: Function,
  condition: TagBnmlSearch,
  setCondition: Function,
  setExpQueries: Function
) => {
  console.log({ condition });
  try {
    if (condition.is_unregistered) {
      // MEMO: タグ未登録の検索処理
      // 1. 削除されていないタグBNMLのBNML_IDを取得
      const bnmlIds = await searchTagBnmlGetBnmlIds(true, condition);
      // 2. 上記のBNML_IDと他のフィールドの検索結果を合わせてBNMLテーブルで検索
      await searchSetBnmls(setBnmls, condition, true, setExpQueries, bnmlIds);
    } else if (condition.tags_ja || condition.tags_en) {
      // MEMO: タグの検索処理あり&タグタイトルの更新理由ありorなし
      // 1. タグ名で合致するタグのID一覧を取得
      const tagIds = await searchTagBnmlGetIds(condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (tagIds.length === 0) {
        setBnmls([]);
        setCondition(condition);
        return;
      }
      // 3. 上記のタグIDがあれば、タグBNMLテーブルから合致するタグBNMLのBNML_ID一覧を取得
      const bnmlIds = await searchTagBnmlGetBnmlIds(false, condition, tagIds);
      // 4. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (bnmlIds.length === 0) {
        setBnmls([]);
        setCondition(condition);
        return;
      }
      // 5. 上記のBNML_IDと他のフィールドの検索結果を合わせてタイトルテーブルで検索
      await searchSetBnmls(setBnmls, condition, false, setExpQueries, bnmlIds);
    } else if (!condition.tags_ja && !condition.tags_en && condition.update_reason) {
      // MEMO: タグの検索処理なし&タグタイトルの更新理由あり
      // 1. 更新理由からタグBNMLのBNML_IDを取得
      const bnmlIds = await searchTagBnmlGetBnmlIds(false, condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (bnmlIds.length === 0) {
        setBnmls([]);
        setCondition(condition);
        return;
      }
      // 3. 上記のBNML_IDと他のフィールドの検索結果を合わせてBNMLテーブルで検索
      await searchSetBnmls(setBnmls, condition, false, setExpQueries, bnmlIds);
    } else {
      // MEMO: タグの検索処理なし
      // 1. 上記のBNML_IDと他のフィールドの検索結果を合わせてBNMLテーブルで検索
      await searchSetBnmls(setBnmls, condition, false, setExpQueries);
    }
    setCondition(condition);
  } catch (e) {
    console.log(e);
  }
};

const searchSetBnfs = async (
  setBnfs: Function,
  data: TagBnfSearch,
  isUnregistered: boolean,
  setExpQueries: Function,
  bnfIds?: string[]
) => {
  try {
    const bnfRecords = Object.entries(data)
      .map(([key, value]) => {
        if (
          key === 'bnf_seq_id' ||
          key === 'bnf_data_source' ||
          key === 'obic7_commodity_cd' ||
          key === 'obic7_commodity_name' ||
          key === 'bvics_plan_cd_4char' ||
          key === 'bvics_plan_cd_7char' ||
          key === 'bvics_product_name' ||
          key === 'from' ||
          key === 'to'
        ) {
          return {
            name: dataToFilterTagBnfObj[key].name,
            value: value,
            type: dataToFilterTagBnfObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const bnfRecordsAddDeleteFlag = [...bnfRecords, { name: 'delete_flg', value: true, type: SEARCH_TYPE.NE }];
    let customQuery = isUnregistered
      ? { name: 'id', value: bnfIds, type: SEARCH_TYPE.OR_NE }
      : { name: 'id', value: bnfIds, type: SEARCH_TYPE.OR_EQ };
    const searchQueries =
      !bnfIds || !bnfIds.length ? bnfRecordsAddDeleteFlag : [...bnfRecordsAddDeleteFlag, customQuery];
    const filter = generateSearchFilter(searchQueries);
    const models: any = await API.graphql({
      query: searchBnfs,
      variables: {
        ...filter,
        sort: { direction: 'desc', field: 'seq_id' },
        limit: 500,
      },
    });
    setBnfs(models.data.searchBnfs.items);
    // exportで使用するクエリをセット
    setExpQueries(searchQueries);
  } catch (e) {
    console.log(e);
  }
};

const searchTagBnfGetBnfIds = async (isUnregistered: boolean, data: TagBnfSearch, tagIds?: string[]) => {
  try {
    const tagBnfRecords = [
      { name: 'tag_id', value: tagIds, type: SEARCH_TYPE.OR_EQ },
      {
        name: 'update_reason',
        value: data.update_reason,
        type: SEARCH_TYPE.WILDCARD,
      },
    ];
    const filter = isUnregistered ? { filter: { _deleted: { ne: true } } } : generateSearchFilter(tagBnfRecords);
    const models: any = await API.graphql({
      query: searchTagBnfsWithoutOhterRelations,
      variables: {
        ...filter,
        limit: 1000,
      },
    });
    const bnfIds = models.data.searchTagBnfs.items.map((item: any) => item.bnf_id);
    return bnfIds;
  } catch (e) {
    console.log(e);
  }
};

const searchTagBnfGetIds = async (data: TagBnfSearch) => {
  try {
    const tagRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'tags_ja' || key === 'tags_en') {
          return {
            name: dataToFilterTagBnfObj[key].name,
            value: value,
            type: dataToFilterTagBnfObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const filter = generateSearchFilter(tagRecords);
    const models: any = await API.graphql({ query: searchTagsMinimumRelations, variables: { ...filter, limit: 1000 } });
    const tagIds = models.data.searchTags.items.map((item: any) => item.id);
    return tagIds;
  } catch (e) {
    console.log(e);
  }
};

export const fetchTagBnf = async (
  setBnfs: Function,
  condition: TagBnfSearch,
  setCondition: Function,
  setExpQueries: Function
) => {
  console.log({ condition });
  try {
    if (condition.is_unregistered) {
      // MEMO: タグ未登録の検索処理
      // 1. 削除されていないタグBNMLのBNML_IDを取得
      const bnfIds = await searchTagBnfGetBnfIds(true, condition);
      // 2. 上記のBNML_IDと他のフィールドの検索結果を合わせてBNMLテーブルで検索
      await searchSetBnfs(setBnfs, condition, true, setExpQueries, bnfIds);
    } else if (condition.tags_ja || condition.tags_en) {
      // MEMO: タグの検索処理あり&タグタイトルの更新理由ありorなし
      // 1. タグ名で合致するタグのID一覧を取得
      const tagIds = await searchTagBnfGetIds(condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (tagIds.length === 0) {
        setBnfs([]);
        setCondition(condition);
        return;
      }
      // 3. 上記のタグIDがあれば、タグBNMLテーブルから合致するタグBNMLのBNML_ID一覧を取得
      const bnfIds = await searchTagBnfGetBnfIds(false, condition, tagIds);
      // 4. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (bnfIds.length === 0) {
        setBnfs([]);
        setCondition(condition);
        return;
      }
      // 5. 上記のBNML_IDと他のフィールドの検索結果を合わせてタイトルテーブルで検索
      await searchSetBnfs(setBnfs, condition, false, setExpQueries, bnfIds);
    } else if (!condition.tags_ja && !condition.tags_en && condition.update_reason) {
      // MEMO: タグの検索処理なし&タグタイトルの更新理由あり
      // 1. 更新理由からタグBNMLのBNML_IDを取得
      const bnfIds = await searchTagBnfGetBnfIds(false, condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (bnfIds.length === 0) {
        setBnfs([]);
        setCondition(condition);
        return;
      }
      // 3. 上記のBNML_IDと他のフィールドの検索結果を合わせてBNMLテーブルで検索
      await searchSetBnfs(setBnfs, condition, false, setExpQueries, bnfIds);
    } else {
      // MEMO: タグの検索処理なし
      // 1. 上記のBNML_IDと他のフィールドの検索結果を合わせてBNMLテーブルで検索
      await searchSetBnfs(setBnfs, condition, false, setExpQueries);
    }
    setCondition(condition);
  } catch (e) {
    console.log(e);
  }
};

export const fetchCurrencies = async (setCurrencies: Function) => {
  try {
    const models: any = await API.graphql({ query: searchCurrencies, variables: { limit: 500 } });
    const items = models?.data?.searchCurrencies?.items as Currency[];
    const result = sortByOrderIdAndCode<Currency>(items, 'code_three_char');
    setCurrencies(result);
  } catch (e) {
    console.log(e);
  }
};

export const fetchCountries = async (setCountries: Function) => {
  try {
    const models: any = await API.graphql({ query: searchCountries, variables: { limit: 500 } });
    const items = models?.data?.searchCountries?.items as Country[];
    const result = sortByOrderIdAndCode<Country>(items, 'code_three_char');
    setCountries(result);
  } catch (e) {
    console.log(e);
  }
};

// export const searchSetCalendars = async (setRows: Function, condition: CalendarSearch, setCondition: Function, setOpen?: Function, setExpQueries?: Function) => {
//   try {
//     const searchQueries = Object.entries(condition).map(([key, value]) => {
//       if (value === 'ALL' && key === 'delete_flg') return
//       return {
//         name: dataToFilterCalendarObj[key].name,
//         value: value,
//         type: dataToFilterCalendarObj[key].type,
//       }
//     }).filter(Boolean);
//     const filter = generateSearchFilter(searchQueries);
//     const models: any = await API.graphql(
//       graphqlOperation(searchCalendars, { ...filter, limit: 500 })
//     )

//     const items = models?.data?.searchCalendars?.items as Calendar[]
//     const result = sortByOrderIdAndCode<Calendar>(items, 'code_two_char')
//     setRows(result);

//     console.log({ models });

//     setCondition(condition);
//     // exportで使用するクエリをセット
//     setExpQueries && setExpQueries(searchQueries)
//     setOpen(false);
//   } catch (e) {
//     console.log(e);
//   }
// }

// export const fetchCalendars = async (setCalendars: Function, condition: CalendarSearch, setCondition: Function) => {
//   try {
//     const searchQueries = Object.entries(condition).map(([key, value]) => {
//       if (value === 'ALL' && key === 'delete_flg') return
//       return {
//         name: dataToFilterCalendarObj[key].name,
//         value: value,
//         type: dataToFilterCalendarObj[key].type,
//       }
//     }).filter(Boolean);
//     const filter = generateSearchFilter(searchQueries);
//     const models: any = await API.graphql(
//       graphqlOperation(searchCalendars, { ...filter, limit: 500 })
//     );
//     const items = models?.data?.searchCalendars?.items as Calendar[]
//     const result = sortByOrderIdAndCode<Calendar>(items, 'code_two_char')
//     setCalendars(result);
//     setCondition(condition);
//   } catch (e) {
//     console.error(e);
//   }
// };

export const fetchTitleCodes = async (
  condition: SearchConditionTitleCode,
  setCondition: Function,
  onChangeFilter: Function,
  setExpQueries: Function
) => {
  try {
    await searchSetTitleCodes(condition, onChangeFilter, setExpQueries);
    setCondition(condition);
  } catch (e) {
    console.log(e);
  }
};

const searchSetTitleCodes = async (
  condition: SearchConditionTitleCode,
  onChangeFilter: Function,
  setExpQueries: Function
) => {
  try {
    const searchQueries = Object.entries(condition)
      .map(([key, value]) => {
        if (value === 'ALL' && key === 'official_name_flg') return;
        if (value === 'ALL' && key === 'system_update_flg') return;
        if (value === 'ALL' && key === 'admin_check_flg') return;
        if (value === 'ALL' && key === 'valid_flg') return;
        if (value === 'ALL' && key === 'delete_flg') return;
        return {
          name: dataToFilterTitleCodeObj[key].name,
          value: value,
          type: dataToFilterTitleCodeObj[key].type,
        };
      })
      .filter(Boolean);

    const { filter } = generateSearchFilter(searchQueries);
    onChangeFilter(filter);
    setExpQueries(searchQueries);
  } catch (e) {
    console.log(e);
  }
};

const searchTagBnamGetIds = async (data: TagBnamSearch) => {
  try {
    const tagRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'tags_ja' || key === 'tags_en') {
          return {
            name: dataToFilterTagBnamObj[key].name,
            value: value,
            type: dataToFilterTagBnamObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const filter = generateSearchFilter(tagRecords);

    const tagIds = [];
    let hasNextPage = true;
    let nextToken = null;
    while (hasNextPage) {
      try {
        const models = (await API.graphql({
          query: searchTagsMinimumRelations,
          variables: {
            ...filter,
            limit: 1000,
            nextToken: nextToken,
          } as SearchTagsQueryVariables,
        })) as GraphQLResult<SearchTagsQuery>;
        const ids = models?.data?.searchTags?.items.map((item) => item?.id);
        ids && tagIds.push(...ids);
        nextToken = models?.data?.searchTags?.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      } catch (e) {
        console.error(e);
      }
    }
    return tagIds;
  } catch (e) {
    console.log(e);
  }
};

export const fetchTagBnams = async (
  condition: TagBnamSearch,
  setBnams: Function,
  setCondition: Function,
  onClose: Function,
  setBnamIds: Function,
  setExpQueries: Function
) => {
  console.log('condition', condition);
  try {
    if (condition.is_unregistered) {
      await searchSetTagBnams(condition, true, setBnams, setBnamIds, setExpQueries);
    } else if (condition.tags_ja || condition.tags_en) {
      // 1. タグ名で合致するタグのID一覧を取得
      const tagIds = await searchTagBnamGetIds(condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (tagIds?.length === 0) {
        setBnams([]);
        setCondition(condition);
        onClose();
        return;
      }
      // 3. 上記のタグIDがあれば、タグBNAMテーブルから合致するタグBNAMのBNAM_ID一覧を取得
      const bnamIds = await searchTagBnamGetTagIds(condition, tagIds);
      // 4. 上記のBNAM_IDがなければ、検索結果0で処理抜ける
      if (bnamIds?.length === 0) {
        setBnams([]);
        setCondition(condition);
        onClose();
        return;
      }
      // 5. 上記のBNAM_IDと他のフィールドの検索結果を合わせてBNAMテーブルで検索
      await searchSetTagBnams(condition, false, setBnams, setBnamIds, setExpQueries, bnamIds);
    } else if (!condition.tags_ja && !condition.tags_en && condition.update_reason) {
      // MEMO: タグの検索処理なし&タグタイトルの更新理由あり
      // 1. 更新理由からタグBNAMのBNAM_IDを取得
      const bnamIds = await searchTagBnamGetBnamIds(condition);
      // 2. 上記のタグIDがなければ、検索結果0で処理抜ける
      if (bnamIds.length === 0) {
        setBnams([]);
        setCondition(condition);
        return;
      }
      // 3. 上記のBNAM_IDと他のフィールドの検索結果を合わせてBNMLテーブルで検索)
      await searchSetTagBnams(condition, false, setBnams, setBnamIds, setExpQueries, bnamIds);
    } else {
      // 1. BNAMテーブルで検索
      await searchSetTagBnams(condition, false, setBnams, setBnamIds, setExpQueries);
    }
    setCondition(condition);
    onClose();
  } catch (e) {
    console.log(e);
  }
};

const searchTagBnamGetTagIds = async (data: TagBnamSearch, tagIds?: (string | undefined)[]) => {
  try {
    let bnamIds = [];
    // 170件以上だとなぜかOR_EQで検索ができなくなるので条件分岐
    if (tagIds?.length > 170) {
      let hasNextPage = true;
      let nextToken = null;
      let tagBnams = [];
      while (hasNextPage) {
        const models = (await API.graphql({
          query: searchTagBnamsWithoutOhterRelations,
          variables: {
            filter: { filter: { _deleted: { ne: true } } },
            limit: 1000,
            nextToken: nextToken,
          } as SearchTagBnamsQueryVariables,
        })) as GraphQLResult<SearchTagBnamsQuery>;
        const tagBnamList = models?.data?.searchTagBnams?.items;
        tagBnamList && (tagBnams = [...tagBnams, ...tagBnamList]);
        nextToken = models?.data?.searchTagBnams?.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      }
      tagBnams = tagBnams.filter((item) => tagIds?.includes(item?.tag_id));
      bnamIds = tagBnams.map((item: any) => item.bnam_id);
    } else {
      const tagBnamRecords = [{ name: 'tag_id', value: tagIds, type: SEARCH_TYPE.OR_EQ }];
      const filter = generateSearchFilter(tagBnamRecords);
      const models: any = await API.graphql({
        query: searchTagBnamsWithoutOhterRelations,
        variables: {
          ...filter,
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 1000,
        },
      });
      bnamIds = models.data.searchTagBnams.items.map((item: any) => item.bnam_id);
    }
    return bnamIds;
  } catch (e) {
    console.log(e);
  }
};

const searchSetTagBnams = async (
  data: TagBnamSearch,
  isUnregistered: boolean,
  setbnams: Function,
  setbnamIds: Function,
  setExpQueries: Function,
  bnamIds?: (string | undefined)[]
) => {
  try {
    const bnamRecords = Object.entries(data)
      .map(([key, value]) => {
        if (key === 'seq_id' || key === 'ip_name' || key === 'from' || key === 'to') {
          return {
            name: dataToFilterTagBnamObj[key].name,
            value: value,
            type: dataToFilterTagBnamObj[key].type,
          };
        }
      })
      .filter(Boolean);
    const bnamRecordsAddDeleteFlag = [...bnamRecords, { name: 'delete_flg', value: true, type: SEARCH_TYPE.NE }];
    const bnamIdRecord = {
      name: 'id',
      value: bnamIds,
      type: SEARCH_TYPE.OR_EQ,
    };
    const searchQueries =
      bnamIds == null || bnamIds.length < 1
        ? [...bnamRecordsAddDeleteFlag]
        : [...bnamRecordsAddDeleteFlag, bnamIdRecord];
    setExpQueries(searchQueries);
    const filter = generateSearchFilter(searchQueries);

    let hasNextPage = true;
    let nextToken = null;
    let bnams = [];
    let filterModels = [];

    // 170件を超えるとOR_EQの検索が仕様上できなくなるので件数によって処理を分ける
    if (bnamIds?.length > 170) {
      while (hasNextPage) {
        const models = (await API.graphql({
          query: searchBnams,
          variables: {
            ...filter,
            sort: { direction: 'desc', field: 'seq_id' },
            limit: 1000,
            nextToken: nextToken,
          } as SearchBnamsQueryVariables,
        })) as GraphQLResult<SearchBnamsQuery>;
        const bnamList = models?.data?.searchBnams.items;
        bnamList && (bnams = [...bnams, ...bnamList]);
        nextToken = models?.data?.searchBnams?.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      }
      filterModels = isUnregistered
        ? bnams.filter((item) => !item?.tags?.items.length)
        : !bnamIds || !bnamIds.length
        ? bnams.filter((item) => item?.tags?.items.length)
        : bnams.filter((item) => bnamIds.includes(item?.id) && item?.tags?.items.length);
    } else {
      const models = (await API.graphql({
        query: searchBnams,
        variables: {
          ...filter,
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 950,
        } as SearchBnamsQueryVariables,
      })) as GraphQLResult<SearchBnamsQuery>;
      bnams = models?.data?.searchBnams?.items;
      console.log('bnams', bnams);
      filterModels = isUnregistered ? bnams.filter((item) => !item?.tags?.items.length) : bnams;
    }

    if (filterModels.length < 501) {
      setbnams(filterModels);
    } else {
      const copyFilterModels = [...filterModels];
      copyFilterModels.length = 500;
      setbnams(copyFilterModels);
    }

    // exportで使用するクエリをセット
    setbnamIds(bnams.map((bnam) => bnam.id));
  } catch (e) {
    console.log(e);
  }
};

const searchTagBnamGetBnamIds = async (data: TagBnamSearch) => {
  try {
    const tagBnamRecords = [
      {
        name: 'update_reason',
        value: data.update_reason,
        type: SEARCH_TYPE.WILDCARD,
      },
    ];
    const filter = generateSearchFilter(tagBnamRecords);
    const models: any = await API.graphql({
      query: searchTagBnamsWithoutOhterRelations,
      variables: {
        ...filter,
        limit: 1000,
      },
    });

    console.log({ models });
    const bnamIds = models.data.searchTagBnams.items.map((item: any) => item.bnam_id);
    return bnamIds;
  } catch (e) {
    console.log(e);
  }
};
