export const ADMIN_EXPORT_ROWS = [
  { id: 0, label: 'export-genre-master', name: 'GENRE' },
  { id: 1, label: 'export_platform-master', name: 'PLATFORM' },
  { id: 2, label: 'export_product-type', name: 'PRODUCT_TYPE' },
  { id: 3, label: 'export_product-category', name: 'PRODUCT_CLASS' },
  { id: 4, label: 'export_currency-master', name: 'CURRENCY' },
  { id: 5, label: 'export_country-master', name: 'COUNTRY' },
  { id: 6, label: 'export_calendar-master', name: 'CALENDAR' },
  { id: 7, label: 'export_rate-master', name: 'RATE' },
  { id: 8, label: 'export_business-entity-master', name: 'BUSINESS_ENTITY' },
];

export const IP_EXPORT_ROWS = [
  { id: 0, label: 'export_shared-tags', name: 'TAG' },
  { id: 1, label: 'export_shared-tag-relation', name: 'TAG_TITLE' },
  { id: 2, label: 'export_title-code-tag', name: 'TAG_TITLE_CODE' },
  { id: 3, label: 'export_bsp', name: 'BSP' },
  { id: 4, label: 'export_bsp-tag', name: 'TAG_BSP' },
  { id: 5, label: 'export_bnf', name: 'BNF' },
  { id: 6, label: 'export_bnf-tag', name: 'TAG_BNF' },
  { id: 7, label: 'export_bnml', name: 'BNML' },
  { id: 8, label: 'export_bnml-tag', name: 'TAG_BNML' },
  { id: 9, label: 'export_bnam', name: 'BNAM' },
  { id: 10, label: 'export_bnam-tag', name: 'TAG_BNAM' },
];

export const PRODUCT_EXPORT_ROWS = [
  { id: 0, label: 'export_title-code', name: 'TITLE_CODE' },
  { id: 1, label: 'export_product-tcd', name: 'PRODUCT_TCD' },
];

export const COMPANY_EXPORT_ROWS = [
  { id: 0, label: 'export_group-company', name: 'GROUP_COMPANY' },
];

export const INITIAL_EXP_QUERIES_STATE = [
  { name: 'delete_flg', type: 'NE', value: true },
  { name: 'admin_check_flg', type: 'EQ', value: true },
];

export const INITIAL_EXP_ONLY_DEL_QUERIES_STATE = [
  { name: 'delete_flg', type: 'NE', value: true },
];
