export const TOP_PAGE_ROUTES = [
  {
    title: 'route_ip-master-manager',
    contents: [
      { name: 'route_shared-tags-list', path: '/ip/tag' },
      { name: 'route_shared-tag-history', path: '/ip/history/tag' },
      {
        name: 'route-parent_bne',
        children: [
          {
            name: 'route_title-code-shared-tagging-title-list',
            path: '/ip/relation/titleCode',
          },
          {
            name: 'route_shared-tagging-titlecode-history',
            path: '/ip/history/relation/tagTitleCode',
          },
          {
            name: 'route_title-code-shared-tagging-csv-import-status',
            path: '/ip/import/tagTitleCode',
          },
        ],
      },
      {
        name: 'route-parent_bsp',
        children: [
          { name: 'route_bsp-list', path: '/ip/bsp' },
          { name: 'route_shared-tagging-bsp-list', path: '/ip/relation/bsp' },
          { name: 'route_bsp-history', path: '/ip/history/bsp' },
          {
            name: 'route_shared-bsp-history',
            path: '/ip/history/relation/bsp',
          },
          { name: 'route_bsp-csv-import-status', path: '/ip/import/bsps' },
          {
            name: 'route_tag-bsp-csv-import-status',
            path: '/ip/import/tagBsps',
          },
        ],
      },
      {
        name: 'route-parent_bnf',
        children: [
          { name: 'route_bnf-list', path: '/ip/bnf' },
          { name: 'route_shared-tagging-bnf-list', path: '/ip/relation/bnf' },
          { name: 'route_bnf-history', path: '/ip/history/bnf' },
          {
            name: 'route_shared-bnf-history',
            path: '/ip/history/relation/bnf',
          },
          { name: 'route_bnf-csv-import-status', path: '/ip/import/bnfs' },
          {
            name: 'route_tag-bnf-csv-import-status',
            path: '/ip/import/tagBnfs',
          },
        ],
      },
      {
        name: 'route-parent_bnml',
        children: [
          { name: 'route_bnml-list', path: '/ip/bnml' },
          { name: 'route_shared-tagging-bnml-list', path: '/ip/relation/bnml' },
          { name: 'route_bnml-history', path: '/ip/history/bnml' },
          {
            name: 'route_shared-bnml-history',
            path: '/ip/history/relation/bnml',
          },
          { name: 'route_bnml-csv-import-status', path: '/ip/import/bnmls' },
          {
            name: 'route_tag-bnml-csv-import-status',
            path: '/ip/import/tagBnmls',
          },
        ],
      },
      {
        name: 'route-parent_bnam',
        children: [
          { name: 'route_bnam-master', path: '/ip/bnam' },
          {
            name: 'route_shared-tagging-bnam-master',
            path: '/ip/relation/bnam',
          },
          { name: 'route_bnam-master-history', path: '/ip/history/bnam' },
          {
            name: 'route_shared-bnam-history',
            path: '/ip/history/relation/bnam',
          },
          { name: 'route_bnam-csv-import-status', path: '/ip/import/bnams' },
          {
            name: 'route_tag-bnam-csv-import-status',
            path: '/ip/import/tagBnams',
          },
        ],
      },
      { name: 'route_ip-export-all', path: '/ip/csv/exports' },
    ],
    role: 'IP',
  },
  {
    title: 'route_product-master-manager',
    contents: [
      { name: 'route_title-code-list', path: '/product/titleCodes' },
      { name: 'route_product-tcd-list', path: '/product/tcds' },
      { name: 'route_title-code-history', path: '/product/history/titleCodes' },
      {
        name: 'route_product-tcd-history',
        path: '/product/history/productTcds',
      },
      {
        name: 'route_title-code-csv-import-status',
        path: '/product/import/titleCodes',
      },
      {
        name: 'route_product-tcd-csv-import-status',
        path: '/product/import/productTcds',
      },
      { name: 'route_product-export-all', path: '/product/csv/exports' },
    ],
    role: 'PRODUCT',
  },
  {
    title: 'route_company-master-manager',
    contents: [
      { 
        name: 'route_company-master-manager-list',
        path: '/company/companies'
      },
      {
        name: 'route_company-master-manager-history',
        path: '/company/history/companies',
      },
      {
        name: 'route_company-master-manager-csv-import',
        path: '/company/import/companies',
      },
      { 
        name: 'route_company-export-all',
        path: '/company/csv/exports'
      },
    ],
    role: 'COMPANY',
  },
  {
    title: 'route_administrator-configuration',
    contents: [
      { name: 'route-genre-master', path: '/admin/genres' },
      { name: 'route_platform-master', path: '/admin/platforms' },
      { name: 'route_product-type-master', path: '/admin/productTypes' },
      { name: 'route_product-category-master', path: '/admin/productClasses' },
      { name: 'route_currency-master', path: '/admin/currencies' },
      { name: 'route_user-master', path: '/admin/users' },
      { name: 'route_country-master', path: '/admin/countries' },
      { name: 'route_calendar-master', path: '/admin/calendars' },
      { name: 'route_rate-master', path: '/admin/rates' },
      { name: 'route_business-entity-master', path: '/admin/businessEntities' },
      { name: 'route_admin-export-all', path: '/admin/csv/exports' },
      { name: 'route_admin-csv-import-status', path: '/admin/csv/imports' },
      { name: 'route_admin-datalake-status', path: '/admin/datalake' },
    ],
    role: 'ADMIN',
  },
];

export const BREADCRUMBS_ROUTES = [
  { path: '/', breadcrumb: 'route_top' },
  { path: '/ip/tag', breadcrumb: 'route_shared-tags-list' },
  { path: '/ip/relation/title', breadcrumb: 'route_shared-tagging-title-list' },
  {
    path: '/ip/relation/titleCode',
    breadcrumb: 'route_title-code-shared-tagging-title-list',
  },
  { path: '/ip/history/tag', breadcrumb: 'route_shared-tag-history' },
  {
    path: '/ip/history/relation/tag',
    breadcrumb: 'route_shared-tagging-history',
  },
  {
    path: '/ip/history/relation/tagTitleCode',
    breadcrumb: 'route_shared-tagging-titlecode-history',
  },
  { path: '/ip/bsp', breadcrumb: 'route_bsp-list' },
  { path: '/ip/bnml', breadcrumb: 'route_bnml-list' },
  { path: '/ip/history/bnml', breadcrumb: 'route_bnml-history' },
  { path: '/ip/relation/bnml', breadcrumb: 'route_shared-tagging-bnml-list' },
  {
    path: '/ip/history/relation/bnml',
    breadcrumb: 'route_shared-bnml-history',
  },
  { path: '/ip/history/relation/bnf', breadcrumb: 'route_shared-bnf-history' },
  { path: '/ip/import/tagBnfs', breadcrumb: 'route_tag-bnf-csv-import-status' },
  { path: '/ip/relation/bnf', breadcrumb: 'route_shared-tagging-bnf-list' },
  { path: '/ip/import/bnfs', breadcrumb: 'route_bnf-csv-import-status' },
  { path: '/ip/import/bnmls', breadcrumb: 'route_bnml-csv-import-status' },
  { path: '/ip/relation/bsp', breadcrumb: 'route_shared-tagging-bsp-list' },
  { path: '/ip/history/bsp', breadcrumb: 'route_bsp-history' },
  { path: '/ip/history/relation/bsp', breadcrumb: 'route_shared-bsp-history' },
  { path: '/ip/import/tagBsps', breadcrumb: 'route_tag-bsp-csv-import-status' },
  {
    path: '/ip/import/tagBnmls',
    breadcrumb: 'route_tag-bnml-csv-import-status',
  },
  { path: '/ip/import/bsps', breadcrumb: 'route_bsp-csv-import-status' },
  {
    path: '/ip/import/tagTitleCode',
    breadcrumb: 'route_title-code-shared-tagging-csv-import-status',
  },
  { path: '/ip/bnam', breadcrumb: 'route_bnam-master' },
  { path: '/ip/relation/bnam', breadcrumb: 'route_shared-tagging-bnam-master' },
  { path: '/ip/import/bnams', breadcrumb: 'route_bnam-csv-import-status' },
  { path: '/ip/history/bnam', breadcrumb: 'route_bnam-master-history' },
  {
    path: '/ip/import/tagBnams',
    breadcrumb: 'route_tag-bnam-csv-import-status',
  },
  {
    path: '/ip/history/relation/bnam',
    breadcrumb: 'route_shared-bnam-history',
  },
  { path: '/ip/bnf', breadcrumb: 'route_bnf-list' },
  { path: '/ip/history/bnf', breadcrumb: 'route_bnf-history' },
  { path: '/ip/csv/exports', breadcrumb: 'route_ip-export-all' },
  { path: '/product/titles', breadcrumb: 'route_title-list' },
  { path: '/product/titleCodes', breadcrumb: 'route_title-code-list' },
  { path: '/product/products', breadcrumb: 'product_product-list' },
  { path: '/product/tcds', breadcrumb: 'route_product-tcd-list' },
  { path: '/product/history/titles', breadcrumb: 'route_title-history' },
  {
    path: '/product/history/titleCodes',
    breadcrumb: 'route_title-code-history',
  },
  { path: '/product/history/products', breadcrumb: 'route_product-history' },
  {
    path: '/product/history/productTcds',
    breadcrumb: 'route_product-tcd-history',
  },
  {
    path: '/product/import/titles',
    breadcrumb: 'route_title-csv-import-status',
  },
  {
    path: '/product/import/products',
    breadcrumb: 'route_product-csv-import-status',
  },
  {
    path: '/product/import/titleCodes',
    breadcrumb: 'route_title-code-csv-import-status',
  },
  {
    path: '/product/import/productTcds',
    breadcrumb: 'route_product-tcd-csv-import-status',
  },
  { path: '/product/csv/exports', breadcrumb: 'route_product-export-all' },
  { path: '/company/csv/exports', breadcrumb: 'route_company-export-all' },
  { path: '/admin/genres', breadcrumb: 'route-genre-master' },
  { path: '/admin/platforms', breadcrumb: 'route_platform-master' },
  { path: '/admin/productTypes', breadcrumb: 'route_product-type-master' },
  {
    path: '/admin/productClasses',
    breadcrumb: 'route_product-category-master',
  },
  { path: '/admin/currencies', breadcrumb: 'route_currency-master' },
  { path: '/admin/users', breadcrumb: 'route_user-master' },
  { path: '/admin/countries', breadcrumb: 'route_country-master' },
  { path: '/admin/rates', breadcrumb: 'route_rate-master' },
  { path: '/admin/calendars', breadcrumb: 'route_calendar-master' },
  {
    path: '/admin/businessEntities',
    breadcrumb: 'route_business-entity-master',
  },
  { path: '/admin/csv/exports', breadcrumb: 'route_admin-export-all' },
  { path: '/admin/csv/imports', breadcrumb: 'route_admin-csv-import-status' },
  { path: '/admin/datalake', breadcrumb: 'route_admin-datalake-status' },
  { path: '/admin/companies', breadcrumb: 'route_administrator-company-list' },
  {
    path: '/admin/history/companies',
    breadcrumb: 'route_administrator-company-history',
  },
  {
    path: '/admin/import/companies',
    breadcrumb: 'route_administrator-company-csv-import',
  },
  {
    path: '/admin/export/companies',
    breadcrumb: 'route_administrator-company-all-export',
  },
  {
    path: '/company/companies',
    breadcrumb: 'route_company-master-manager-list',
  },
  {
    path: '/company/history/companies',
    breadcrumb: 'route_company-master-manager-history',
  },
  {
    path: '/company/import/companies',
    breadcrumb: 'route_company-master-manager-csv-import',
  },
  { 
    path: '/company/csv/exports',
    breadcrumb: 'route_company-export-all',
  },
];

export const BREADCRUMBS_EXCLUDE_PATHS = [
  'ip',
  'product',
  'company',
  'admin',
  'history',
  'relation',
  'exports',
  'import',
  'imports',
  'ip/history',
  'ip/relation',
  'ip/history/relation',
  'ip/import',
  'ip/csv',
  'product/history',
  'product/import',
  'product/csv',
  'company/history',
  'company/csv',
  'company/import',
  'admin/csv',
];
