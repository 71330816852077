import React, { useCallback, useContext, useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import EditTagTitleDialog from '../components/tagTitles/EditTagTitleDialog';
import SearchTagTitleDialog from '../components/tagTitles/SearchTagTitleDialog';
import { searchTitles } from '../graphql/queries';
import { onCreateTitle, onUpdateTitle } from '../graphql/subscriptions';
import { AuthContext } from '../contexts/AuthContext';
import { SEARCH_TAG_TITLE_FIELDS_DEFAULT_VALUES } from '../consts/tagTitle';
import { TagTitleSearch } from '../types/form/tagTitle';
import usePageSize from '../hooks/usePageSize';
import { onCreateTagWithoutRelations } from '../graphql/custom-subscriptions';
import { searchTagsMinimumRelations } from '../graphql/custom-queries';

import type { Title } from '../models';
import type { TagTitle, Tag, SearchableTitleSortInput } from '../API';

import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const columns: GridColDef[] = [
  {
    field: 'seq_id',
    headerName: 'タイトルID',
    width: 250
  },
  {
    field: 'name_ja',
    headerName: 'タイトル名',
    width: 250
  },
  {
    field: 'tags_ja',
    headerName: 'IPタグ（日本語）',
    width: 250,
    valueGetter: ({ row }) => {
      const tagTitle = row.tags.items.find((tagTitle: TagTitle) => !tagTitle._deleted);
      return tagTitle?.tag?.name_ja;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <Box sx={{ bgcolor: grey[800], height: 30, lineHeight: '30px', px: 1, color: 'white' }}>未登録</Box>;
      }
      return value;
    }
  },
  {
    field: 'tags_en',
    headerName: 'IPタグ（英語）',
    width: 250,
    valueGetter: ({ row }) => {
      const tagTitle = row.tags.items.find((tagTitle: TagTitle) => !tagTitle._deleted);
      return tagTitle?.tag?.name_en;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <Box sx={{ bgcolor: grey[800], height: 30, lineHeight: '30px', px: 1, color: 'white' }}>未登録</Box>;
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (v1 === undefined) return 1;
      if (v2 === undefined) return -1;
      if (v1 === '') return 1;
      if (v2 === '') return -1;
      if (v1 === null) return 1;
      if (v2 === null) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'updatedAt',
    headerName: 'タイトル最終更新日時',
    width: 250,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

function TagTitleList() {
  const [titles, setTitles] = useState<Title[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [title, setTitle] = useState<Title | null>(null);
  const [tagTitle, setTagTitle] = useState<TagTitle | null>(null);
  const [addedTag, setAddedTag] = useState<Tag | null>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<TagTitleSearch>(SEARCH_TAG_TITLE_FIELDS_DEFAULT_VALUES);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchTitles();
    fetchTags();
    subscribeOnCreateTitle();
    subscribeOnUpdateTitle();
    subscribeOnCreateTag();
  }, []);

  const fetchTitles = async () => {
    try {
      const models: any = await API.graphql({
        query: searchTitles,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 500
        }
      });
      setTitles(models.data.searchTitles.items);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTags = async () => {
    try {
      const models: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 500
        }
      });
      setTags(models.data.searchTags.items);
    } catch (e) {
      console.log(e);
    }
  };

  const subscribeOnCreateTitle = () => {
    const client = API.graphql({ query: onCreateTitle });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const title = data.onCreateTitle;
          setTitles((prev) => [title, ...prev]);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnUpdateTitle = () => {
    const client = API.graphql({ query: onUpdateTitle });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const title = data.onUpdateTitle;
          if (!title.delete_flg) {
            // 更新
            setTitles((titles) => titles.map((__title) => (__title.id === title.id ? title : __title)));
          } else {
            // 削除
            setTitles((titles) => titles.filter((__title) => __title.id !== title.id));
          }
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnCreateTag = () => {
    const client = API.graphql({ query: onCreateTagWithoutRelations });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const tag = data.onCreateTag;
          setTags((tags) => [tag, ...tags]);
          setAddedTag(tag);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const doOpenEdit = (params: any) => {
    setTitle(params.row);
    const __tagTitle = params.row.tags.items.find((tagTitle: any) => !tagTitle._deleted);
    __tagTitle && setTagTitle(__tagTitle);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const onChangePageSize = (num: number) => {
    setPageSize(num);
  };

  const onCloseEdit = () => {
    setTagTitle(null);
    setTitle(null);
    setAddedTag(null);
    setOpenEdit(false);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={titles}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onPageSizeChange={(num) => onChangePageSize(num)}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            }
          }}
        />
      </div>
      {title && openEdit && user && (
        <EditTagTitleDialog
          addedTag={addedTag}
          onClose={onCloseEdit}
          open={openEdit}
          tags={tags}
          tagTitle={tagTitle}
          title={title}
          user={user}
          setTitles={setTitles}
          condition={condition}
          setCondition={setCondition}
        />
      )}
      {openSearch && (
        <SearchTagTitleDialog
          condition={condition}
          open={openSearch}
          setCondition={setCondition}
          setOpen={setOpenSearch}
          setTitles={setTitles}
        />
      )}
    </Box>
  );
}

export default TagTitleList;
