import React, { useRef, useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import { Bnam, User } from '../../models';
import { EDIT_BNAM_FIELDS, VALIDATE_DUPLICATE_BNAM_FIELDS } from '../../consts/bnam';
import { BnamFields } from '../../types';
import { classificationValidateBnam } from '../../hooks/classification';
import { getBnam } from '../../graphql/queries';
import { updateBnam } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { sleep } from '../../utils/fetchData';
import FormCheckBox from '../forms/FormCheckBox';
import EditConfirmDialog from '../EditConfirmDialog';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  row: Bnam;
  setOpen: Function;
  fetchBnams: Function;
  setRow: Function;
  user: User;
};

type Record = {
  ip_name?: string;
  update_reason?: string;
  admin_check_flg?: boolean;
};

function EditBnamDialog(props: Props) {
  const { open, row, setOpen, setRow, user, fetchBnams } = props;
  const [confirm, setConfirm] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const { checkAndRestore } = useRestore();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const onConfirmCancel = () => {
    setConfirm(false);
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<Record> = async (data) => {
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('BNAM', VALIDATE_DUPLICATE_BNAM_FIELDS, data, clearErrors, setError, row.id);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
  };

  // 確認編集画面の「登録」の処理
  const onSubmit = () => {
    setCanSubmit(true);
  };

  const onUpdate = async () => {
    try {
      const model: any = await API.graphql({ query: getBnam, variables: { id: row.id } });
      const bnam = (model.data as any).getBnam;
      const formData = {
        id: row.id,
        ip_name: record.ip_name,
        update_reason: record.update_reason,
        admin_check_flg: record.admin_check_flg,
        _version: bnam._version,
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateBnam, variables: { input: formData } });
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'BNAMマスターの編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchBnams({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BNAMマスターの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete = async () => {
    try {
      const model: any = await API.graphql({ query: getBnam, variables: { id: row.id } });
      const bnam = (model.data as any).getBnam;
      const formData = {
        id: row.id,
        _version: bnam._version,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateBnam, variables: { input: formData } });
      closeConfirm();
      onClose();
      addNotification({
        type: 'success',
        message: 'BNAMマスターの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchBnams({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BNAMマスターの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const restoreConfirm = () => {
    setAlertMsg(`このBNAMマスターを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('bnam', row);
      onClose();
      addNotification({
        type: 'success',
        message: 'BNAMマスターの復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchBnams({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BNAMマスターの復元に失敗しました',
      });
      console.log(e);
    }
  };

  const onDoConfirm = () => {
    setAlertMsg(`このBNAMマスターを削除しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BNAMマスターの編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!row.delete_flg && handleSubmit(onPreSubmit)}
      onConfirm={row.delete_flg ? restoreConfirm : onDoConfirm}
      height={480}
      width="xs"
      isRestore={row.delete_flg}
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 300 },
        }}
        width={340}
      >
        {EDIT_BNAM_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {__row.type === 'text' && (
              <FormTextField
                label={__row.label}
                placeholder={__row.placeholder}
                field={__row.field}
                control={control}
                disabled={row.delete_flg || __row.disabled}
                errors={errors}
                validationRules={classificationValidateBnam(__row.field as BnamFields)}
                value={row[__row.field as keyof Pick<Bnam, 'ip_name'>]}
                required={row.delete_flg ? false : __row.required}
              />
            )}
            {__row.type === 'checkbox' && (
              <FormCheckBox
                field={__row.field}
                label={__row.label}
                control={control}
                defaultValue={row[__row.field as keyof Pick<Bnam, 'admin_check_flg'>] || false}
                disabled={row.delete_flg}
              />
            )}
            {__row.type === 'datetime' && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<Bnam, 'updatedAt'>]}
                type={__row.type}
                disabled={row.delete_flg || __row.disabled}
                field={__row.field}
                control={control}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <ConfirmDialog
        message={alertMsg}
        open={confirm}
        onCancel={onConfirmCancel}
        onContinue={row.delete_flg ? onRestore : handleSubmit(onDelete)}
      />
      <EditConfirmDialog
        fields={EDIT_BNAM_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="BNAMマスター"
        beforeChangeValueData={row}
      />
    </FormDialog>
  );
}

export default EditBnamDialog;
