import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import { Bnam } from '../models';
import NewBnamDialog from '../components/bnam/NewBnamDialog';
import PrimaryButton from '../components/PrimaryButton';
import { AuthContext } from '../contexts/AuthContext';
import { useSearchBnams } from '../hooks/useSearchBnams';
import EditBnamDialog from '../components/bnam/EditBnamDialog';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton'
import { filterColumnsForGeneralRole } from '../utils/filterColumns'
import SearchBnamDialog from '../components/bnam/SearchBnamDialog';
import { INITIAL_EXP_QUERIES_STATE } from '../consts/export';

const columns: GridColDef[] = [
  {
    field: 'seq_id',
    headerName: 'BNAMマスターID',
    width: 223,
  },
  {
    field: 'ip_name',
    headerName: 'IP名称',
    width: 223,
  },
  {
    field: 'admin_check_flg',
    headerName: '管理者チェックフラグ',
    width: 223,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 223,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
  {
    field: 'updated_user_id',
    headerName: '最終更新者',
    width: 223,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    },
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 223,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function BnamManagement() {
  const [row, setRow] = useState<Bnam | null>(null);
  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openSearch, setOpenSearch] = useState(false);
  const { user, roles } = useContext(AuthContext);
  const roleTypes = roles?.map((role) => role.role?.label);
  const [isUploaded, setIsUploaded] = useState(false)
  const [expQueries, setExpQueries] = useState<{
    name: string;
    value: string | number | boolean;
    type: string;
  }[]>(INITIAL_EXP_QUERIES_STATE)

  const { fetchBnams, loading, total, pageSize, onChangePageSize, bnams, onChangePage, condition, page, onChangeSort } = useSearchBnams()

  useEffect(() => {
    fetchBnams({})
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isUploaded) return
    fetchBnams({})
    setIsUploaded(false)
    // eslint-disable-next-line
  }, [isUploaded])

  // 一般ユーザの時は削除済データのカラムを非表示にする
  const removeColumns = ["delete_flg"]
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns)

  const doOpenNew = useCallback(() => {
    setOpenNew(true);
  }, []);

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true)
  }, [])

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, width: 900 }}>
          <PrimaryButton onClick={doOpenNew}>新規作成</PrimaryButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
            <ExportButton
              condition={expQueries}
              tableName="BNAM"
              type='SEARCH'
            />
            <ImportButton tableName="BNAM" setIsUploaded={setIsUploaded} />
          </Box>
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 180px)', width: '100%' }}>
        <DataGridPro
          rows={bnams}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {user && openNew && <NewBnamDialog open={openNew} setOpen={setOpenNew} user={user} fetchBnams={fetchBnams} />}
      {row && user && openEdit && <EditBnamDialog open={openEdit} row={row} setOpen={setOpenEdit} setRow={setRow} user={user} fetchBnams={fetchBnams} />}
      <SearchBnamDialog
        condition={condition}
        isAdmin={roleTypes.includes('ADMIN')}
        open={openSearch}
        setOpen={setOpenSearch}
        fetchBnams={fetchBnams}
        setExpQueries={setExpQueries}
      />
    </Box>
  );
}

export default BnamManagement;
