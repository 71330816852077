import React, { useContext, useEffect, memo, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';

type Props = {
  allowedRoles: string[] // ADMIN or PRODUCT or IP
  children: ReactNode
}

function AuthGuard(props: Props) {
  const { allowedRoles, children } = props
  const roleTypes = useContext(AuthContext).roles?.map((role) => role.role?.label);
  const navigate = useNavigate();

  useEffect(() => {
    if (!roleTypes || roleTypes.length === 0) return
    const result = allowedRoles.every((role) => !roleTypes.includes(role))
    result && navigate('/')
    // eslint-disable-next-line
  }, [allowedRoles, roleTypes]);

  if (!roleTypes || roleTypes.length === 0) return <></>;
  return <>{children}</>

}

export default memo(AuthGuard)