import { CognitoIdentityCredentials, config as awsConfig } from 'aws-sdk';
import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import awsExports from '../aws-exports';
import awsOptions from '../aws-options';

export function useCredentials() {
  (async () => {
    const idp = 'cognito-idp.ap-northeast-1.amazonaws.com/' + awsExports.aws_user_pools_id;
    awsConfig.region = awsExports.aws_project_region;
    awsConfig.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: awsOptions.aws_cognito_identity_pool_id || '',
      Logins: {
        [idp]: (await fetchAuthSession()).tokens?.idToken?.toString(),
      },
    });
  })();
}
