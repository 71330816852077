import { useState } from 'react';
import { searchPlatforms } from '../graphql/queries';
import usePageSize from './usePageSize';
import { Platform } from '../models';
import { generateClient } from 'aws-amplify/api';
import { SearchablePlatformSortInput } from '../API';
const API = generateClient();

const defaultSort = { direction: 'asc', field: 'seq_id' };

export const useSearchPlatforms = () => {
  const [platforms, setPlatforms] = useState<Platform[]>([]);

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();
  const [showDeleted, setShowDeleted] = useState(false);

  const fetchPlatforms = async ({
    nextToken,
    newLimit,
    newSort,
    isShowDeleteFlg
  }: {
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
    isShowDeleteFlg?: boolean;
  }) => {
    try {
      setLoading(true);
      const filter = { delete_flg: { ne: true } };
      const models: any = await API.graphql({
        query: searchPlatforms,
        variables: {
          filter: isShowDeleteFlg ? null : filter,
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });

      setTotal(models.data.searchPlatforms.total);
      if (newLimit || newSort) {
        setNextTokens([models.data.searchPlatforms.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchPlatforms.nextToken])));
      }

      setPlatforms([...models.data.searchPlatforms.items]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchPlatforms({ newLimit, isShowDeleteFlg: showDeleted });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchPlatforms({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchPlatforms({ newSort, isShowDeleteFlg: showDeleted });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchPlatforms({ nextToken, isShowDeleteFlg: showDeleted });
  };

  const onChangeShowDeleted = () => {
    setPage(0);
    setShowDeleted((prev) => {
      fetchPlatforms({ isShowDeleteFlg: !prev });
      return !prev;
    });
  };

  return {
    fetchPlatforms,
    loading,
    total,
    pageSize,
    onChangePageSize,
    platforms,
    setPlatforms,
    onChangePage,
    page,
    onChangeSort,
    onChangeShowDeleted,
    showDeleted
  };
};
