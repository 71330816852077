export const CREATE_BUSINESS_ENTITY_FIELDS = [
  {
    field: "name",
    label: "事業体名",
    type: "text",
    placeholder: "事業体名を入力",
    required: true,
  },
  {
    field: "description",
    label: "概要",
    type: "text",
    placeholder: "概要を入力",
    required: false,
  },
];

export const EDIT_BUSINESS_ENTITY_FIELDS = [
  {
    field: "seq_id",
    label: "ID",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "name",
    label: "事業体名",
    type: "text",
    placeholder: "事業体名を入力",
    required: true,
  },
  {
    field: "description",
    label: "概要",
    type: "text",
    placeholder: "概要を入力",
    required: false,
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "最終更新日時",
    type: "datetime",
    placeholder: "",
    disabled: true,
  },
];

export const VALIDATE_DUPLICATE_BUSINESS_ENTITY_FIELDS = ["name"];
