import React, { useState } from 'react'
import type { PropsWithChildren } from 'react'
import { NotificationContext } from '../contexts/NotificationContext'
import type { Notification } from '../contexts/NotificationContext'

const initialNotification = {
  isShow: false,
  type: 'error',
  message: ''
} as const

export default function NotificationProvider(props: PropsWithChildren<{}>) {
  const { children } = props
  const [notification, setNotification] = useState<Notification>(initialNotification)

  const addNotification = (notification: Omit<Notification, 'isShow'>) => {
    setNotification({
      isShow: true,
      type: notification.type,
      message: notification.message
    })
  }

  const removeNotification = (event: React.SyntheticEvent<any> | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setNotification({
      ...notification,
      isShow: false,
    })
  }

  return (
    <NotificationContext.Provider value={{ notification, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  )
}
