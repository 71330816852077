import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import { User, GroupCompanyHistory, GroupCompany } from '../../models';
import { DETAIL_GROUP_COMPANY_HISTORY_FIELDS } from '../../consts/groupCompanyHistory';
import { OPERATION_TYPE_OPTIONS } from '../../consts/common';
import { useEditedColumn } from '../../hooks/useEditedColumnClass';

type Props = {
  open: boolean;
  row: GroupCompanyHistory;
  setOpen: Function;
  setRow: Function;
  user: User;
  groupCompanies: GroupCompany[];
};

type Record = {
  operation_type?: '';
  seq_id?: '';
  mlics_plan_cd_4char?: '';
  mlics_plan_cd_7char?: '';
  mlics_commodity_name?: '';
  updated_user_id?: '';
  updatedAt_from?: null;
  updatedAt_to?: null;
  update_reason?: '';
  system_update_flg?: false;
};

function DetailGroupCompanyHistoryDialog(props: Props) {
  const { open, row, setOpen, setRow, groupCompanies } = props;
  const {
    control,
    formState: { errors },
  } = useForm();

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      default:
        return [];
    }
  };

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="グループ会社マスタ履歴の詳細"
      doText=""
      cancelText="キャンセル"
      height={600}
      width="xl"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 300 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 530,
        }}
        width={1200}
      >
        {DETAIL_GROUP_COMPANY_HISTORY_FIELDS.map((__row, idx) => {
          return (
            <React.Fragment key={idx}>
              {(__row.type === 'text' || __row.type === 'textarea') && (
                <FormTextField
                  label={__row.label}
                  field={__row.field}
                  control={control}
                  disabled={__row.disabled}
                  errors={errors}
                  validationRules={[]}
                  value={
                    row[
                      __row.field as keyof Pick<
                        GroupCompanyHistory,
                        'update_reason' | 'updated_user_id'
                      >
                    ]
                  }
                  isTextArea={__row.type === 'textarea'}
                  isEdited={useEditedColumn(row, __row.field)}
                />
              )}
              {__row.type === 'checkbox' && (
                <FormCheckBox
                  field={__row.field}
                  label={__row.label}
                  control={control}
                  defaultValue={
                    row[
                      __row.field as keyof Pick<
                        GroupCompanyHistory,
                        'delete_flg'
                      >
                    ]
                  }
                  disabled={__row.disabled}
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </FormDialog>
  );
}

export default DetailGroupCompanyHistoryDialog;
