export const getTagWithoutRelations = /* GraphQL */ `
  query GetTagWithoutRelations($id: ID!) {
    getTag(id: $id) {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const searchTagsMinimumRelations = /* GraphQL */ `
  query SearchTagsMinimumRelations(
    $filter: SearchableTagFilterInput
    $sort: [SearchableTagSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagAggregationInput]
  ) {
    searchTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        titles {
          items {
            id
            _deleted
          }
        }
        title_codes {
          items {
            id
            _deleted
          }
        }
        bsps {
          items {
            id
            _deleted
          }
        }
        bnams {
          items {
            id
            _deleted
          }
        }
        bnmls {
          items {
            id
            _deleted
          }
        }
        bnfs {
          items {
            id
            _deleted
          }
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const searchTagTitlesWithoutOhterRelations = /* GraphQL */ `
  query SearchTagTitlesWithoutOhterRelations(
    $filter: SearchableTagTitleFilterInput
    $sort: [SearchableTagTitleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagTitleAggregationInput]
  ) {
    searchTagTitles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          titles {
            items {
              id
              tag_id
              title_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          tags {
            items {
              id
              tag_id
              title_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const searchTagTitleCodesWithoutOhterRelations = /* GraphQL */ `
  query SearchTagTitleCodesWithoutOhterRelations(
    $filter: SearchableTagTitleCodeFilterInput
    $sort: [SearchableTagTitleCodeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagTitleCodeAggregationInput]
  ) {
    searchTagTitleCodes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          title_codes {
            items {
              id
              tag_id
              title_code_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          tags {
            items {
              id
              tag_id
              title_code_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const searchTagBspsWithoutOhterRelations = /* GraphQL */ `
  query SearchTagBspsWithoutOhterRelations(
    $filter: SearchableTagBspFilterInput
    $sort: [SearchableTagBspSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagBspAggregationInput]
  ) {
    searchTagBsps(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          bsps {
            items {
              id
              tag_id
              bsp_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            items {
              id
              tag_id
              bsp_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const searchTagBnamsWithoutOhterRelations = /* GraphQL */ `
  query SearchTagBnamsWithoutOhterRelations(
    $filter: SearchableTagBnamFilterInput
    $sort: [SearchableTagBnamSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagBnamAggregationInput]
  ) {
    searchTagBnams(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          bnams {
            items {
              id
              tag_id
              bnam_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            items {
              id
              tag_id
              bnam_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const searchTagBnmlsWithoutOhterRelations = /* GraphQL */ `
  query SearchTagBnmlsWithoutOhterRelations(
    $filter: SearchableTagBnmlFilterInput
    $sort: [SearchableTagBnmlSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagBnmlAggregationInput]
  ) {
    searchTagBnmls(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          bnmls {
            items {
              id
              tag_id
              bnml_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            items {
              id
              tag_id
              bnml_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const searchTagBnfsWithoutOhterRelations = /* GraphQL */ `
  query SearchTagBnfsWithoutOhterRelations(
    $filter: SearchableTagBnfFilterInput
    $sort: [SearchableTagBnfSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagBnfAggregationInput]
  ) {
    searchTagBnfs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          bnfs {
            items {
              id
              tag_id
              bnf_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnf {
          id
          seq_id
          tags {
            items {
              id
              tag_id
              bnf_id
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
