import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import NewTitleDialog from '../components/titles/NewTitleDialog';
import EditTitleDialog from '../components/titles/EditTitleDialog';
import SearchTitleDialog from '../components/titles/SearchTitleDialog';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { Title, DataSource, Currency, Platform, ProductType, ProductClass } from '../models';
import {
  searchGenres,
  searchCurrencies,
  listDataSources,
  searchProductClasses,
  searchProductTypes,
  searchPlatforms
} from '../graphql/queries';
import { onUpdateGenre } from '../graphql/subscriptions';
import { AuthContext } from '../contexts/AuthContext';
import { sortByOrderIdAndCode } from '../utils/sortByOrderIdAndCode';
import { useSearchTitles } from '../hooks/useSearchTitles';
import { INITIAL_EXP_QUERIES_STATE } from '../consts/export';
import { SEARCH_TITLE_FIELDS_DEFAULT_VALUES } from '../consts/title';
import { TitleSearch } from '../types/form/title';
import { filterColumnsForGeneralRole } from '../utils/filterColumns';

import { generateClient } from 'aws-amplify/api';
import {
  SearchableGenreSortInput,
  SearchablePlatformSortInput,
  SearchableProductClassSortInput,
  SearchableProductTypeSortInput
} from '../API';
const API = generateClient();

const columns: GridColDef[] = [
  {
    field: 'seq_id',
    headerName: 'タイトルID',
    width: 190
  },
  {
    field: 'name_ja',
    headerName: 'タイトル名（日本語）',
    width: 210
  },
  {
    field: 'tags',
    headerName: 'IPタグ',
    width: 210,
    valueGetter: (params) => {
      const latestTagParam = params.value.items.length
        ? params.value.items.sort((x, y) => new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime())[
            params.value.items.length - 1
          ]
        : undefined;
      return latestTagParam && !latestTagParam._deleted ? latestTagParam.tag.name_ja : '';
    }
  },
  {
    field: 'name_en',
    headerName: 'タイトル名（英語）',
    width: 210
  },
  {
    field: 'search_name',
    headerName: '検索用タイトル名称',
    width: 210
  },
  {
    field: 'genre_name',
    headerName: 'ジャンル',
    width: 210,
    valueFormatter: (params: any) => {
      return tranceArrayText(params);
    }
  },
  {
    field: 'project_cd',
    headerName: 'プロジェクト定義',
    width: 210,
    valueFormatter: (params: any) => {
      return tranceArrayText(params);
    }
  },
  {
    field: 'sap_title_code',
    headerName: 'SAPタイトルコード',
    width: 210,
    valueFormatter: (params: any) => {
      return tranceArrayText(params);
    }
  },
  {
    field: 'bn_connect_title_id',
    headerName: 'BN-ConnectタイトルID',
    width: 210,
    valueFormatter: (params: any) => {
      return tranceArrayText(params);
    }
  },
  {
    field: 'banasuke_title_id',
    headerName: 'バナスケタイトルID',
    width: 210
  },
  {
    field: 'ce_link_key_code',
    headerName: 'CE-LINKキーコード',
    width: 210
  },
  {
    field: 'data_source_name',
    headerName: 'データソース名',
    width: 210,
    valueGetter: (params) => {
      return params.row.data_source?.name;
    }
  },
  {
    field: 'system_update_flg',
    headerName: '自動更新フラグ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'admin_check_flg',
    headerName: '管理者チェック済フラグ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'updated_by',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    }
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

const tranceArrayText = (params: any) => {
  if (params.value?.length > 0 && params.value[0]) {
    let arrayText = '';
    params.value.map((text: string) => {
      if (text) {
        arrayText = `${arrayText}"${text}", `;
      }
    });
    arrayText = arrayText.slice(0, -2);
    return `[${arrayText}]`;
  } else {
    return null;
  }
};

function TitleList() {
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [row, setRow] = useState<Title | null>(null);
  const [condition, setCondition] = useState<TitleSearch>(SEARCH_TITLE_FIELDS_DEFAULT_VALUES);
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [productClasses, setProductClasses] = useState<ProductClass[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [expQueries, setExpQueries] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >(INITIAL_EXP_QUERIES_STATE);
  const { user, roles } = useContext(AuthContext);
  const roleTypes = roles?.map((role) => role.role?.label);

  const {
    searchTitls,
    onChangePageSize,
    onChangePage,
    onChangeSort,
    onChangeFilter,
    setRows,
    setGenres,
    loading,
    total,
    pageSize,
    rows,
    page,
    genres
  } = useSearchTitles();

  useEffect(() => {
    fetchGenres();
    fetchDataSources();
    fetchCurrencies();
    fetchPlatforms();
    fetchProductClasses();
    fetchProductTypes();
    subscribeOnUpdateGenre();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!genres.length) return;
  }, [genres]);

  useEffect(() => {
    if (!genres.length && !isUploaded) return;
    searchTitls({});
    setIsUploaded(false);
    // eslint-disable-next-line
  }, [genres, isUploaded]);

  // 一般ユーザの時は削除済データのカラムを非表示にする
  const removeColumns = ['delete_flg'];
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns);

  const fetchGenres = async () => {
    try {
      const models: any = await API.graphql({
        query: searchGenres,
        variables: {
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setGenres(models.data.searchGenres.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDataSources = async () => {
    try {
      const models: any = await API.graphql({ query: listDataSources });
      // memo: searchクエリがないのでフロントでseq_idを利用してsort
      const sorted = models.data.listDataSources.items.sort(
        (a: any, b: any) => a.seq_id.replace(/[^0-9^\.]/g, '') - b.seq_id.replace(/[^0-9^\.]/g, '')
      );
      setDataSources(models.data.listDataSources.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const models: any = await API.graphql({ query: searchCurrencies, variables: { limit: 500 } });
      const items = models?.data?.searchCurrencies?.items as Currency[];
      const result = sortByOrderIdAndCode<Currency>(items, 'code_three_char');
      setCurrencies(result);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const models: any = await API.graphql({
        query: searchPlatforms,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setPlatforms(models.data.searchPlatforms.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductClasses = async () => {
    try {
      const models: any = await API.graphql({
        query: searchProductClasses,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setProductClasses(models.data.searchProductClasses.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductTypes = async () => {
    try {
      const models: any = await API.graphql({
        query: searchProductTypes,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setProductTypes(models.data.searchProductTypes.items);
    } catch (e) {
      console.log(e);
    }
  };

  const subscribeOnUpdateGenre = () => {
    const client = API.graphql({ query: onUpdateGenre });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const genre = data.onUpdateGenre;
          if (!genre.delete_flg) {
            genres.includes(genre.id) // 更新
              ? setGenres((genres) => genres.map((__genre) => (__genre.id === genre.id ? genre : __genre)))
              : setGenres((prev) => {
                  // 復元
                  const sorted = [...prev, genre].sort(
                    (a: any, b: any) => a.seq_id.replace(/[^0-9^\.]/g, '') - b.seq_id.replace(/[^0-9^\.]/g, '')
                  );
                  return sorted;
                });
          } else {
            // 削除
            setGenres((genres) => genres.filter((__genre) => __genre.id !== genre.id));
          }
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const doopenNew = useCallback(() => {
    setOpenNew(true);
  }, []);

  const doopenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doopenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  if (!roleTypes) return <></>;
  console.log({ rows });
  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, width: 900 }}>
          <PrimaryButton onClick={doopenNew}>新規作成</PrimaryButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
            <ExportButton condition={expQueries} tableName="TITLE" type="SEARCH" />
            <ImportButton tableName="TITLE" setIsUploaded={setIsUploaded} />
          </Box>
        </Box>
        <PrimaryButton onClick={doopenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          rows={rows}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doopenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openNew && user && (
        <NewTitleDialog
          dataSources={dataSources}
          genres={genres}
          open={openNew}
          setOpen={setOpenNew}
          condition={condition}
          setCondition={setCondition}
          onChangeFilter={onChangeFilter}
          setExpQueries={setExpQueries}
        />
      )}
      {openEdit && row && user && (
        <EditTitleDialog
          currencies={currencies}
          dataSources={dataSources}
          genres={genres}
          isAdmin={roleTypes.includes('ADMIN')}
          open={openEdit}
          platforms={platforms}
          productClasses={productClasses}
          productTypes={productTypes}
          row={row}
          setOpen={setOpenEdit}
          setRow={setRow}
          user={user}
          condition={condition}
          setCondition={setCondition}
          onChangeFilter={onChangeFilter}
          setExpQueries={setExpQueries}
        />
      )}
      {openSearch && (
        <SearchTitleDialog
          condition={condition}
          dataSources={dataSources}
          genres={genres}
          isAdmin={roleTypes.includes('ADMIN')}
          open={openSearch}
          setOpen={setOpenSearch}
          setCondition={setCondition}
          onChangeFilter={onChangeFilter}
          setExpQueries={setExpQueries}
        />
      )}
    </Box>
  );
}

export default TitleList;
