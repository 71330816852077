import React from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import { DETAIL_IMPORT_FIELDS } from '../../consts/import';
import { convertCsvJobStatus, convertCsvTableName, type TableName } from '../../utils/converter';
import type { CsvJob } from '../../API';

type Props = {
  open: boolean;
  row: CsvJob;
  setOpen: Function;
  setRow: Function;
  dialogTitle: string
}

function DetailImportDialog(props: Props) {
  const { open, row, setOpen, setRow, dialogTitle } = props;
  const { control, formState: { errors } } = useForm();

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const formatValue = (field: keyof CsvJob, value: any) => {
    switch (field) {
      case 'status':
        return convertCsvJobStatus[value];
      case 'table_name':
        return convertCsvTableName[value as TableName];
      default:
        return value;
    }
  }

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title={dialogTitle}
      cancelText="キャンセル"
      height={480}
      width="md"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 320 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 400,
        }}
        width={680}
      >
        {DETAIL_IMPORT_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {(__row.type === 'text' || __row.type === 'textarea') && (
              <FormTextField
                label={__row.label}
                field={__row.field}
                control={control}
                disabled={__row.disabled}
                errors={errors}
                validationRules={[]}
                value={formatValue(__row.field as keyof CsvJob, row[__row.field as keyof CsvJob])}
                isTextArea={__row.type === 'textarea'}
              />
            )}
            {(__row.type === 'datetime' || __row.type === 'date') && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<CsvJob, 'createdAt'>] as string}
                type={__row.type}
                disabled={__row.disabled}
                field={__row.field}
                control={control}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormDialog>
  );
}

export default DetailImportDialog;
