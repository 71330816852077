export const CREATE_PLATFORM_FIELDS = [
  {
    field: 'name',
    label: 'プラットフォーム名称',
    type: 'text',
    placeholder: 'プラットフォーム名称を入力',
    required: true,
  },
  {
    field: 'banasuke_name',
    label: 'バナスケプラットフォーム名',
    type: 'text',
    placeholder: 'バナスケプラットフォーム名を入力',
    required: false,
  },
];

export const EDIT_PLATFORM_FIELDS = [
  {
    field: 'seq_id',
    label: 'ID',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'name',
    label: 'プラットフォーム名称',
    type: 'text',
    placeholder: 'プラットフォーム名称を入力',
    required: true,
  },
  {
    field: 'banasuke_name',
    label: 'バナスケプラットフォーム名',
    type: 'text',
    placeholder: 'バナスケプラットフォーム名を入力',
  },
  {
    field: 'updated_user_id',
    label: '最終更新者',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'updatedAt',
    label: '最終更新日時',
    type: 'datetime',
    placeholder: '',
    disabled: true,
  },
];

export const VALIDATE_DUPLICATE_PLATFORM_FIELDS = ['name', 'banasuke_name'];
