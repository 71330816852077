import React, { useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import FormSelectBox from '../forms/FormSelectBox';
import SearchDialog from '../SearchDialog';
import {
  DELETE_FLG_OPTIONS,
  SYSTEM_UPDATE_OPTIONS,
  ADMIN_CHECK_FLG_OPTIONS,
  OFFICIAL_OPTIONS,
  VALIDITY_OPTIONS,
} from '../../consts/common';
import { Genre, DataSource, BusinessEntity, Platform } from '../../models';
import { titleFields } from '../../types';
import { classificationValidateTitle } from '../../hooks/classification';
import { fetchTitleCodes } from '../../utils/fetchData';
import { SearchConditionTitleCode } from '../../types/form/titleCode';
import { SEARCH_TITLE_CODE_FIELDS, SEARCH_TITLE_CODE_FIELDS_DEFAULT_VALUES } from '../../consts/titleCode';

type Props = {
  condition: SearchConditionTitleCode;
  dataSources: DataSource[];
  genres: Genre[];
  businessEntity: BusinessEntity[];
  platforms: Platform[];
  onChangeFilter: Function;
  isAdmin: boolean;
  setCondition: Function;
  setExpQueries: Function;
  isDialogOpen: boolean;
  closeFunc: Function;
};

type valueOf<T> = T[keyof T];

function SearchTitleCodeDialog(props: Props) {
  const {
    condition,
    dataSources,
    genres,
    businessEntity,
    platforms,
    isAdmin,
    onChangeFilter,
    setCondition,
    isDialogOpen,
    setExpQueries,
    closeFunc,
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues: SEARCH_TITLE_CODE_FIELDS_DEFAULT_VALUES });

  useEffect(() => {
    Object.entries(condition).forEach((cond: [keyof SearchConditionTitleCode, valueOf<typeof condition>]) =>
      setValue(cond[0], cond[1])
    );
  }, [condition, setValue]);

  const onClose = () => {
    reset();
    closeFunc();
  };

  const onSubmit: SubmitHandler<SearchConditionTitleCode> = async (data) => {
    console.log({ data });
    await fetchTitleCodes(data, setCondition, onChangeFilter, setExpQueries);
    onClose();
  };

  const options = useCallback(
    (name: string) => {
      switch (name) {
        case 'genre':
          return genres;
        case 'dataSource':
          return dataSources;
        case 'businessEntity':
          return businessEntity;
        case 'platform':
          return platforms;
        case 'system_update_flg':
          return SYSTEM_UPDATE_OPTIONS;
        case 'admin_check_flg':
          return ADMIN_CHECK_FLG_OPTIONS;
        case 'official_name_flg':
          return OFFICIAL_OPTIONS;
        case 'valid_flg':
          return VALIDITY_OPTIONS;
        case 'delete_flg':
          return DELETE_FLG_OPTIONS;
        default:
          return [];
      }
    },
    [dataSources, genres, businessEntity, platforms]
  );

  // 管理者のみadmin_check_flgを表示
  const defaultFields = isAdmin ? SEARCH_TITLE_CODE_FIELDS : SEARCH_TITLE_CODE_FIELDS.slice(0, -1);
  /** 最大カラム数 */
  const maxColumnOrders = Math.max(...defaultFields.map((field) => field.column));

  return (
    <SearchDialog
      open={isDialogOpen}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => reset()}
      height="600"
      width="xl"
    >
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => (
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: 300 },
              display: 'flex',
              flexFlow: 'column wrap',
              height: 'inherit',
            }}
            width={330}
            key={i}
          >
            {defaultFields.map(
              (row) =>
                row.column === i + 1 && (
                  <React.Fragment key={row.field}>
                    {row.type === 'select' && row.optionsName && (
                      <FormSelectBox
                        label={row.label}
                        content={options(row.optionsName)}
                        field={row.field}
                        control={control}
                        placeholder={row.placeholder}
                        isBlank={row.isBlank}
                      />
                    )}
                    {row.type === 'datetime' && row.inputs && (
                      <Box sx={{ display: 'flex' }}>
                        <FormDateTimeRangePicker
                          fromLabel={row.inputs[0].label}
                          fromField={row.inputs[0].field}
                          toLabel={row.inputs[0].label}
                          toField={row.inputs[1].field}
                          type={row.type}
                          placeholder={row.placeholder}
                          control={control}
                          watch={watch}
                          errors={errors}
                        />
                      </Box>
                    )}
                    {row.type === 'text' && (
                      <FormTextField
                        label={row.label}
                        placeholder={row.placeholder}
                        field={row.field}
                        control={control}
                        errors={errors}
                        validationRules={classificationValidateTitle(row.field as titleFields, 'search')}
                      />
                    )}
                  </React.Fragment>
                )
            )}
          </Box>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchTitleCodeDialog;
