export const SEARCH_COUNTRY_FIELDS_DEFAULT_VALUES = {
  order_id: "",
  country_code: "",
  code_two_char: "",
  code_three_char: "",
  name: "",
  name_en: "",
  update_reason: "",
  from: null,
  to: null
};

export const SEARCH_COUNTRY_FIELDS = [
  {
    field: 'order_id',
    label: 'country_order-id',
    type: 'text',
    placeholder: '表示順を入力',
  },
  {
    field: 'country_code',
    label: 'country_code-country',
    type: 'text',
    placeholder: '国コードを入力',
  },
  {
    field: 'code_two_char',
    label: 'country_code-second',
    type: 'text',
    placeholder: 'コード（2文字）を入力',
  },
  {
    field: 'code_three_char',
    label: 'country_code-third',
    type: 'text',
    placeholder: 'コード（3文字）を入力',
  },
  {
    field: 'name',
    label: 'country_name_japanese',
    type: 'text',
    placeholder: '国名（日本語）を入力',
  },
  {
    field: 'name_en',
    label: 'country_name_english',
    type: 'text',
    placeholder: '国名（英語）を入力',
  },
  {
    field: 'update_reason',
    label: 'country_update-reason',
    type: 'text',
    placeholder: '更新理由を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'country_final-update-date' },
      { field: 'to', label: 'country_final-update-date' },
    ],
  }
];
