import { Country, Currency } from "../models"

// 基本はorder_idで昇順ソート、order_idが「-」の場合はcodeで昇順ソート
export const sortByOrderIdAndCode = <T extends Country | Currency>(items: T[], sortKey: string) => {
  let codeIdList: T[] = []
  let orderIdList: T[] = []
  if (items.length > 0) {
    items.map((item: T) => {
      if (item.order_id == 9999) return codeIdList = [...codeIdList, item]
      orderIdList = [...orderIdList, item]
    })
    orderIdList = orderIdList.slice().sort((a: any, b: any) => {
      return a.order_id - b.order_id
    })
    codeIdList = codeIdList.slice().sort((a: any, b: any) => {
      if (a[sortKey] > b[sortKey]) {
        return 1;
      } else {
        return -1;
      }
    })
  }
  return [...orderIdList, ...codeIdList] as T[]
}