import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextField from '../forms/FormTextField';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import { calendarFields } from '../../types';
import { classificationValidateCalendar } from '../../hooks/classification';
import { CalendarSearch } from '../../types/form/calendar'
import { SEARCH_CALENDAR_FIELDS_DEFAULT_VALUES, SEARCH_CALENDAR_FIELDS, DAY_OF_WEEK_OPTIONS, HOLIDAY_FLG_OPTIONS } from '../../consts/calendar';
import FormSelectBox from '../forms/FormSelectBox';
import { DELETE_FLG_OPTIONS } from '../../consts/common';
import { Country } from '../../models';

type Props = {
  countries: Country[];
  condition: CalendarSearch;
  open: boolean;
  setOpen: Function;
  fetchCalendar: Function
  setRows: Function;
  setExpQueries?: Function
}

function SearchCalendarDialog(props: Props) {
  const { condition, open, setOpen, setRows, countries, setExpQueries, fetchCalendar } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_CALENDAR_FIELDS_DEFAULT_VALUES });

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<CalendarSearch> = async (newCondition) => {
    try {
      await fetchCalendar({ newCondition })
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onReset = () => {
    reset();
  };

  const options = (name: string) => {
    switch (name) {
      case 'dayOfWeek':
        return DAY_OF_WEEK_OPTIONS;
      case 'holidayFlag':
        return HOLIDAY_FLG_OPTIONS
      case 'country':
        return countries
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <SearchDialog
      open={open}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
      width="lg"
      title='祝日カレンダーマスタの検索'
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 360 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: '500px',
        }}
        width='750px'
      >
        {
          SEARCH_CALENDAR_FIELDS.map((row, idx) => {
            return (
              <Box key={idx} mb={1}>
                {row.type === 'select' && row.optionsName && (
                  <FormSelectBox
                    label={row.label}
                    content={options(row.optionsName)}
                    field={row.field}
                    control={control}
                    selectKey='name'
                    placeholder={row.placeholder}
                    isBlank={row.isBlank}
                  />
                )}
                {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
                  <Box sx={{ display: 'flex' }}>
                    <FormDateTimeRangePicker
                      fromLabel={row.inputs[0].label}
                      fromField={row.inputs[0].field}
                      toLabel={row.inputs[0].label}
                      toField={row.inputs[1].field}
                      type={row.type}
                      placeholder={row.placeholder}
                      control={control}
                      watch={watch}
                      errors={errors}
                    />
                  </Box>
                )}
                {row.type === 'text' && (
                  <FormTextField
                    label={row.label}
                    placeholder={row.placeholder}
                    field={row.field}
                    control={control}
                    errors={errors}
                    validationRules={classificationValidateCalendar(row.field as calendarFields)}
                  />
                )}
              </Box>
            )
          })
        }
      </Box>
    </SearchDialog >
  );
}

export default SearchCalendarDialog;
