import { useState } from 'react';
import { searchBnamHistories } from '../graphql/queries';
import { BnamHistorySearch } from '../types/form';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import usePageSize from './usePageSize';
import { BnamHistory, SearchableBnamHistorySortInput } from '../API';
import { SEARCH_BNAM_HISTORY_FIELDS_DEFAULT_VALUES } from '../consts/bnamHistory';
import { dataToFilterBnamObj } from '../utils/fetchData';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = { direction: 'desc', field: 'updatedAt' };

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

const dataToFilterObj: StringKeyObj = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.EQ },
  ...dataToFilterBnamObj
};

export const useSearchBnamHistories = () => {
  const [bnamHistories, setBnamHistories] = useState<BnamHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<BnamHistorySearch>(SEARCH_BNAM_HISTORY_FIELDS_DEFAULT_VALUES);
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();

  const fetchBnamHistories = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort
  }: {
    newCondition?: BnamHistorySearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const queries = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          if (value === 'ALL' && key === 'delete_flg') return;
          if (value === 'ALL' && key === 'admin_check_flg') return;
          if (value === 'ALL' && key === 'system_update_flg') return;
          return {
            name: dataToFilterObj[key].name,
            value: value,
            type: dataToFilterObj[key].type
          };
        })
        .filter(Boolean);

      const filter = generateSearchFilter(queries);
      const models: any = await API.graphql({
        query: searchBnamHistories,
        variables: {
          ...filter,
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });

      setTotal(models.data.searchBnamHistories.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchBnamHistories.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchBnamHistories.nextToken])));
      }

      setBnamHistories([...models.data.searchBnamHistories.items]);
      newCondition && setCondition({ ...newCondition });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchBnamHistories({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchBnamHistories({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchBnamHistories({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchBnamHistories({ nextToken });
  };

  return {
    fetchBnamHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    bnamHistories,
    setBnamHistories,
    onChangePage,
    condition,
    setCondition,
    page,
    onChangeSort
  };
};
