/**
 *
 *
 * TODO: Titleがなくなる際に既存のFormSelectChipをこちらに変更（命名も2を取る）
 *
 *
 */

import React from 'react';
import { FormControl, InputLabel, MenuItem, Box, Typography, Chip } from '@mui/material';
import Select from '@mui/material/Select';
import { Controller, Control, ErrorOption } from 'react-hook-form';

import { theme } from '../../assets/theme/options';
import { validationRules } from '../../types';
import createValidationRules from '../../hooks/createValidationRules';
import RequiredLabel from './RequiredLabel';
import { I18n } from 'aws-amplify/utils';
import { Genre, Platform } from '../../models';

interface FormSelectChipProps {
  /** ラベル */
  label: string;
  /** 内容 */
  models: Genre[] | Platform[];
  /** インプット内容 */
  defaultValue?: string[];
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** placeholder */
  placeholder?: string;
  /** ReactFookForm */
  control: Control;
  errors?: ErrorOption;
  /** バリデーションの内容 */
  validationRules?: validationRules[];
  /** 必須ラベルの有無 */
  required?: boolean;
}

function FormSelectChip(props: FormSelectChipProps) {
  const defaultContents = props.defaultValue || [];
  const contentsName = (id: string): string => {
    const needValues = props.models.map((model) => ({ id: model.id, name: model.name }));
    return needValues.find((model) => model.id === id).name;
  };

  return (
    <Box position={'relative'}>
      {props.required && <RequiredLabel />}
      <InputLabel sx={{ fontSize: '12px', marginBottom: '2px' }}>{I18n.get(props.label)}</InputLabel>
      <FormControl fullWidth size="small">
        <Controller
          name={props.field}
          control={props.control}
          defaultValue={defaultContents}
          rules={props.validationRules ? createValidationRules(props.validationRules) : undefined}
          render={({ field }) => (
            <Select
              {...field}
              error={props.errors && !!props.errors[props.field]}
              defaultValue={defaultContents}
              disabled={props.disabled}
              displayEmpty
              multiple
              sx={{ background: props.disabled ? theme.colors.disabled : '' }}
              renderValue={(selected) =>
                selected.length ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {selected.map((v) => (
                      <Chip key={v} label={contentsName(v)} size="small" />
                    ))}
                  </Box>
                ) : (
                  <Typography color={theme.colors.placeholder}>{props.placeholder}</Typography>
                )
              }
            >
              {props.models.map(
                (item) =>
                  item.delete_flg !== true && (
                    <MenuItem key={item.name} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )
              )}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  );
}

export default FormSelectChip;
