import { useState } from 'react';
import { searchUsers } from '../graphql/queries';
import usePageSize from './usePageSize';
import { User } from '../models';
import { CurrencySearch } from '../types/form';
import { generateClient } from 'aws-amplify/api';
import { SearchableUserSortInput } from '../API';
const API = generateClient();

interface StringKeyObj {
  [key: string]: string;
}

const field2searchField: StringKeyObj = {
  id: 'id',
  email: 'email',
  hasUserRolesIP: 'roles',
  hasUserRolesPRODUCT: 'roles',
  hasUserRolesADMIN: 'roles',
  updated_user_id: 'updated_user_id',
  updatedAt: 'updatedAt'
};

export const useSearchUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>({});
  const { pageSize, setPageSize } = usePageSize();

  const fetchUsers = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort
  }: {
    newCondition?: CurrencySearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const models: any = await API.graphql({
        query: searchUsers,
        variables: {
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });
      setTotal(models.data.searchUsers.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchUsers.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchUsers.nextToken])));
      }
      setUsers([...models.data.searchUsers.items]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchUsers({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchUsers({ newSort: {} });
    } else {
      const newSort = { direction: sortModel[0].sort, field: field2searchField[sortModel[0].field] };
      setSort({ ...newSort });
      fetchUsers({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchUsers({ nextToken });
  };

  return { fetchUsers, loading, total, pageSize, onChangePageSize, users, setUsers, onChangePage, page, onChangeSort };
};
