interface OperationType {
  [key: string]: '新規登録' | '編集' | '論理削除' | '論理復元' | '物理削除';
}

interface CsvJobStatus {
  [key: string]: '待機中' | '処理中' | '完了' | 'エラー';
}

export const convertOperationType: OperationType = {
  CREATE: '新規登録',
  EDIT: '編集',
  SOFTDELETE: '論理削除',
  RESTORESOFTDELETE: '論理復元',
  DELETE: '物理削除',
};

export const convertCsvJobStatus: CsvJobStatus = {
  STANDBY: '待機中',
  DOING: '処理中',
  DONE: '完了',
  ERROR: 'エラー',
};

export type TableName =
  | 'TAG'
  | 'TITLE'
  | 'PRODUCT'
  | 'PRODUCT_TCD'
  | 'TAGTITLE'
  | 'CURRENCY'
  | 'GENRE'
  | 'PLATFORM'
  | 'PRODUCT_CLASS'
  | 'PRODUCT_TYPE'
  | 'BSP'
  | 'TAGBSP'
  | 'RATE'
  | 'TITLE_CODE'
  | 'TAG_TITLE_CODE'
  | 'BNML'
  | 'TAGBNML'
  | 'BNF'
  | 'TAGBNF'
  | 'BNAM'
  | 'TAGBNAM'
  | 'CALENDAR'
  | 'GROUP_COMPANY';

type ConvertedTableName =
  | '共有タグ'
  | 'タイトル'
  | 'プロダクト'
  | 'TCDプロダクト'
  | '共有タグ付け'
  | '通貨'
  | 'ジャンル'
  | 'プラットフォーム'
  | 'プリダクト区分'
  | 'プロダクト種別'
  | 'BSP'
  | 'BSPタグ付け'
  | 'レート'
  | 'タイトルコード'
  | 'タイトルコード共有タグ付け'
  | 'BNML'
  | 'BNMLタグ付け'
  | 'BNAM'
  | 'BNF'
  | 'BNFタグ付け'
  | 'BNAMタグ付け'
  | 'グループ会社'
  | '祝日カレンダー';

export const convertCsvTableName: { [key in TableName]: ConvertedTableName } = {
  TAG: '共有タグ',
  TITLE: 'タイトル',
  PRODUCT: 'プロダクト',
  PRODUCT_TCD: 'TCDプロダクト',
  TAGTITLE: '共有タグ付け',
  CURRENCY: '通貨',
  GENRE: 'ジャンル',
  PLATFORM: 'プロダクト',
  PRODUCT_CLASS: 'プリダクト区分',
  PRODUCT_TYPE: 'プロダクト種別',
  BSP: 'BSP',
  BNF: 'BNF',
  BNAM: 'BNAM',
  BNML: 'BNML',
  TAGBSP: 'BSPタグ付け',
  TAGBNAM: 'BNAMタグ付け',
  TAGBNML: 'BNMLタグ付け',
  TAGBNF: 'BNFタグ付け',
  RATE: 'レート',
  TITLE_CODE: 'タイトルコード',
  TAG_TITLE_CODE: 'タイトルコード共有タグ付け',
  CALENDAR: '祝日カレンダー',
  GROUP_COMPANY: 'グループ会社',
};
