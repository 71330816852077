export const DETAIL_PRODUCT_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "product-history_operation-category",
    type: "select",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "product-history_product-id",
    type: "text",
  },
  {
    field: "product_key",
    label: "product-history_product-key",
    type: "text",
  },
  {
    field: "name",
    label: "product-history_product-name",
    type: "text",
  },
  {
    field: "common_name",
    label: "product-history_product-common-name",
    type: "text",
  },
  {
    field: "title_name",
    label: "product-history_title-name",
    type: "text",
  },
  {
    field: "product_type_name",
    label: "product-history_product-type-name",
    type: "text",
  },
  {
    field: "product_class_name",
    label: "product-history_product-specific-name",
    type: "text",
  },
  {
    field: "market",
    label: "product-history_market",
    type: "text",
  },
  {
    field: "platform_name",
    label: "product-history_platform-name",
    type: "text",
  },
  {
    field: "data_source_name",
    label: "product-history_data-source-name",
    type: "text",
  },
  {
    field: "base_price",
    label: "product-history_base-price",
    type: "text",
  },
  {
    field: "currency_name",
    label: "product-history_currency",
    type: "text",
  },
  {
    field: "release_status",
    label: "product-history_delivery-status",
    type: "text",
  },
  {
    field: "info_release_date",
    label: "product-history_date-of-first-information-release",
    type: "date",
  },
  {
    field: "preorder_start_date",
    label: "product-history_contract-start-date",
    type: "date",
  },
  {
    field: "release_date",
    label: "product-history_release-date",
    type: "date",
  },
  {
    field: "service_start_date",
    label: "product-history_service-start-date",
    type: "date",
  },
  {
    field: "service_end_date",
    label: "product-history_service-end-date",
    type: "date",
  },
  {
    field: "support_end_date",
    label: "product-history_support-end-date",
    type: "date",
  },
  {
    field: "sku_number_apple",
    label: "sku_number_apple",
    type: "text",
  },
  {
    field: "app_id",
    label: "App_ID",
    type: "text",
  },
  {
    field: "package_name_google",
    label: "package_name_google",
    type: "text",
  },
  {
    field: "bank_app_id",
    label: "bank_app_id",
    type: "text",
  },
  {
    field: "updated_user_id",
    label: "product-history_final-updater",
    type: "text",
  },
  {
    field: "updatedAt",
    label: "product-history_final-update-date",
    type: "datetime",
  },
  {
    field: "updated_reason",
    label: "product-history_reason-for-update",
    type: "textarea",
  },
  {
    field: "deleted_at",
    label: "product-history_deleted-date",
    type: "datetime",
  },
  {
    field: "system_update_flg",
    label: "product-history_automatic-update-flag",
    type: "checkbox",
  },
  {
    field: "admin_check_flg",
    label: "product-history_admin-checked-flag",
    type: "checkbox",
  },
  {
    field: "delete_flg",
    label: "product-history_deleted-data",
    type: "checkbox",
  },
];

export const SEARCH_PRODUCT_HISTORY_PRODUCT_FIELDS = [
  {
    field: "operation_type",
    label: "product-history_operation-category",
    type: "select",
    placeholder: "操作種別を選択",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "product-history_product-id",
    type: "text",
    placeholder: "プロダクトIDを入力",
  },
  {
    field: "product_key",
    label: "product-history_product-key",
    type: "text",
    placeholder: "プロダクトキーを入力",
  },
  {
    field: "name",
    label: "product-history_product-name",
    type: "text",
    placeholder: "プロダクト名を入力",
  },
  {
    field: "common_name",
    label: "product-history_product-common-name",
    type: "text",
    placeholder: "プロダクト共通名を入力",
  },
  {
    field: "title_name",
    label: "product-history_title-name",
    type: "text",
    placeholder: "タイトル名を入力",
  },
  {
    field: "product_type_id",
    label: "product-history_product-type-name",
    type: "select",
    placeholder: "プロダクト種別名を選択",
    optionsName: "product_type",
  },
  {
    field: "product_class_id",
    label: "product-history_product-specific-name",
    type: "select",
    placeholder: "プロダクト区別名を選択",
    optionsName: "product_class",
  },
  {
    field: "market",
    label: "product-history_market",
    type: "text",
    placeholder: "マーケットを入力",
  },
  {
    field: "platform_id",
    label: "product-history_platform-name",
    type: "select",
    placeholder: "プラットフォーム名を選択",
    optionsName: "platform",
  },
  {
    field: "data_source_id",
    label: "product-history_data-source-name",
    type: "select",
    placeholder: "データソース名を選択",
    optionsName: "data_source",
  },
  {
    field: "base_price",
    label: "",
    type: "text",
    placeholder: "価格を入力",
    inputs: [
      { field: "from", label: "product-history_base-price" },
      { field: "to", label: "product-history_base-price" },
    ],
  },
  {
    field: "currency_id",
    label: "product-history_currency",
    type: "select",
    placeholder: "通貨を選択",
    optionsName: "currency",
  },
  {
    field: "release_status",
    label: "product-history_delivery-status",
    type: "text",
    placeholder: "配信ステータスを入力",
  },
  {
    field: "info_release_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_date-of-first-information-release" },
      { field: "to", label: "product-history_date-of-first-information-release" },
    ],
  },
  {
    field: "preorder_start_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_contract-start-date" },
      { field: "to", label: "product-history_contract-start-date" },
    ],
  },
  {
    field: "release_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_release-date" },
      { field: "to", label: "product-history_release-date" },
    ],
  },
  {
    field: "service_start_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_service-start-date" },
      { field: "to", label: "product-history_service-start-date" },
    ],
  },
  {
    field: "service_end_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_service-end-date" },
      { field: "to", label: "product-history_service-end-date" },
    ],
  },
  {
    field: "support_end_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_support-end-date" },
      { field: "to", label: "product-history_support-end-date" },
    ],
  },
  {
    field: "sku_number_apple",
    label: "sku_number_apple",
    type: "text",
    placeholder: "sku_number_appleを入力",
  },
  {
    field: "app_id",
    label: "App_ID",
    type: "text",
    placeholder: "App_IDを入力",
  },
  {
    field: "package_name_google",
    label: "package_name_google",
    type: "text",
    placeholder: "package_name_googleを入力",
  },
  {
    field: "bank_app_id",
    label: "bank_app_id",
    type: "text",
    placeholder: "bank_app_idを入力",
  },
  {
    field: "updated_user_id",
    label: "product-history_final-updater",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_final-update-date" },
      { field: "to", label: "product-history_final-update-date" },
    ],
  },
  {
    field: "updated_reason",
    label: "product-history_reason-for-update",
    type: "textarea",
    placeholder: "更新理由を入力",
  },
  {
    field: "system_update_flg",
    label: "product-history_automatic-update-flag",
    type: "select",
    optionsName: "system_update_flg",
    isBlank: false,
  },
];

export const SEARCH_PRODUCT_HISTORY_ADMIN_FIELDS = [
  {
    field: "operation_type",
    label: "product-history_operation-category",
    type: "select",
    placeholder: "操作種別を選択",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "product-history_product-id",
    type: "text",
    placeholder: "プロダクトIDを入力",
  },
  {
    field: "product_key",
    label: "product-history_product-key",
    type: "text",
    placeholder: "プロダクトキーを入力",
  },
  {
    field: "name",
    label: "product-history_product-name",
    type: "text",
    placeholder: "プロダクト名を入力",
  },
  {
    field: "common_name",
    label: "product-history_product-common-name",
    type: "text",
    placeholder: "プロダクト共通名を入力",
  },
  {
    field: "title_name",
    label: "product-history_title-name",
    type: "text",
    placeholder: "タイトル名を入力",
  },
  {
    field: "product_type_id",
    label: "product-history_product-type-name",
    type: "select",
    placeholder: "プロダクト種別名を選択",
    optionsName: "product_type",
  },
  {
    field: "product_class_id",
    label: "product_product-specific-name",
    type: "select",
    placeholder: "プロダクト区別名を選択",
    optionsName: "product_class",
  },
  {
    field: "market",
    label: "product-history_market",
    type: "text",
    placeholder: "マーケットを入力",
  },
  {
    field: "platform_id",
    label: "product-history_platform-name",
    type: "select",
    placeholder: "プラットフォーム名を選択",
    optionsName: "platform",
  },
  {
    field: "data_source_id",
    label: "product-history_data-source-name",
    type: "select",
    placeholder: "データソース名を選択",
    optionsName: "data_source",
  },
  {
    field: "base_price",
    label: "",
    type: "text",
    placeholder: "価格を入力",
    inputs: [
      { field: "from", label: "product-history_base-price" },
      { field: "to", label: "product-history_base-price" },
    ],
  },
  {
    field: "currency_id",
    label: "product-history_currency",
    type: "select",
    placeholder: "通貨を選択",
    optionsName: "currency",
  },
  {
    field: "release_status",
    label: "product-history_delivery-status",
    type: "text",
    placeholder: "配信ステータスを入力",
  },
  {
    field: "info_release_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_date-of-first-information-release" },
      { field: "to", label: "product-history_date-of-first-information-release" },
    ],
  },
  {
    field: "preorder_start_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_contract-start-date" },
      { field: "to", label: "product-history_contract-start-date" },
    ],
  },
  {
    field: "release_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_release-date" },
      { field: "to", label: "product-history_release-date" },
    ],
  },
  {
    field: "service_start_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_service-start-date" },
      { field: "to", label: "product-history_service-start-date" },
    ],
  },
  {
    field: "service_end_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_service-end-date" },
      { field: "to", label: "product-history_service-end-date" },
    ],
  },
  {
    field: "support_end_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_support-end-date" },
      { field: "to", label: "product-history_support-end-date" },
    ],
  },
  {
    field: "sku_number_apple",
    label: "sku_number_apple",
    type: "text",
    placeholder: "sku_number_appleを入力",
  },
  {
    field: "app_id",
    label: "App_ID",
    type: "text",
    placeholder: "App_IDを入力",
  },
  {
    field: "package_name_google",
    label: "package_name_google",
    type: "text",
    placeholder: "package_name_googleを入力",
  },
  {
    field: "bank_app_id",
    label: "bank_app_id",
    type: "text",
    placeholder: "bank_app_idを入力",
  },
  {
    field: "updated_user_id",
    label: "product-history_final-updater",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "product-history_final-update-date" },
      { field: "to", label: "product-history_final-update-date" },
    ],
  },
  {
    field: "updated_reason",
    label: "product-history_reason-for-update",
    type: "textarea",
    placeholder: "更新理由を入力",
  },
  {
    field: "system_update_flg",
    label: "product-history_automatic-update-flag",
    type: "select",
    optionsName: "system_update_flg",
    isBlank: false,
  },
  {
    field: "admin_check_flg",
    label: "product-history_admin-checked-flag",
    type: "select",
    optionsName: "admin_check_flg",
    isBlank: false,
  },
  {
    field: "delete_flg",
    label: "product_deleted-data",
    type: "select",
    optionsName: "delete_flg",
    isBlank: false,
  },
];

export const SEARCH_PRODUCT_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  seq_id: "",
  product_key: "",
  name: "",
  common_name: "",
  title_name: "",
  product_type_id: "",
  product_class_id: "",
  market: "",
  platform_id: "",
  data_source_id: "",
  base_price_from: "",
  base_price_to: "",
  currency_id: "",
  release_status: "",
  info_release_date_from: null,
  info_release_date_to: null,
  preorder_start_date_from: null,
  preorder_start_date_to: null,
  release_date_from: null,
  release_date_to: null,
  service_start_date_from: null,
  service_start_date_to: null,
  service_end_date_from: null,
  service_end_date_to: null,
  support_end_date_from: null,
  support_end_date_to: null,
  sku_number_apple: "",
  app_id: "",
  package_name_google: "",
  bank_app_id: "",
  updated_user_id: "",
  updatedAt_from: null,
  updatedAt_to: null,
  updated_reason: "",
  admin_check_flg: true,
  system_update_flg: "ALL",
  delete_flg: false,
};
