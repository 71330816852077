import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import SearchTagTitleHistoryDialog from '../components/tagTitleHistories/SearchTagTitleHistoryDialog';
import { convertOperationType } from '../utils/converter';
import { useSearchTagTitleHistories } from '../hooks/useSearchTagTitleHistories';

const columns: GridColDef[] = [
  {
    field: 'operation_type',
    headerName: '操作種別',
    width: 210,
    valueFormatter: (params: any) => {
      return convertOperationType[params.value];
    },
  },
  { field: 'title_name', headerName: 'タイトル名', width: 210 },
  { field: 'tag_name', headerName: 'IPタグ（日本語）', width: 210 },
  { field: 'tag_name_en', headerName: 'IPタグ（英語）', width: 210 },
  {
    field: 'updated_user_name',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => {
      return params.row.updated_user_name;
    },
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function TagTitleHistoryList() {
  const [openSearch, setOpenSearch] = useState(false);

  const { fetchTagTitleHistories, loading, total, pageSize, onChangePageSize, tagTitleHistories, setTagTitleHistories, onChangePage, condition, setCondition, page, onChangeSort } = useSearchTagTitleHistories()

  useEffect(() => {
    fetchTagTitleHistories({})
    // eslint-disable-next-line
  }, []);

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={tagTitleHistories}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openSearch && (
        <SearchTagTitleHistoryDialog
          condition={condition}
          open={openSearch}
          setOpen={setOpenSearch}
          fetchTagTitleHistories={fetchTagTitleHistories}
        />
      )}
    </Box>
  );
}

export default TagTitleHistoryList;
