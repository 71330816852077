import { useState } from 'react';
import { searchProductTcds } from '../graphql/queries';
import usePageSize from './usePageSize';
import { generateClient } from 'aws-amplify/api';
import { SearchableProductTcdSortInput } from '../API';
const API = generateClient();

interface StringKeyObj {
  [key: string]: string;
}

export function useSearchProductTcds() {
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<{}>({ delete_flg: { ne: true }, admin_check_flg: { eq: true } });
  const [sort, setSort] = useState<{}>({ direction: 'desc', field: 'updatedAt' });
  const { pageSize, setPageSize } = usePageSize();

  const searchPrdTcds = async ({
    nextToken,
    newLimit,
    newFilter,
    newSort
  }: {
    nextToken?: string | null;
    newLimit?: number;
    newFilter?: {};
    newSort?: {};
  }) => {
    setLoading(true);

    try {
      const models: any = await API.graphql({
        query: searchProductTcds,
        variables: {
          filter: newFilter ?? filter,
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });

      setTotal(models.data.searchProductTcds.total);
      if (newLimit || newFilter || newSort) {
        setNextTokens([models.data.searchProductTcds.nextToken]);
        setPage(0);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchProductTcds.nextToken])));
      }
      setRows(models.data.searchProductTcds.items);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    searchPrdTcds({ newLimit });
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return tokens;
        });
      }
      return newPage;
    });
    searchPrdTcds({ nextToken });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      searchPrdTcds({ newSort: { direction: 'desc', field: 'updatedAt' } });
    } else {
      const newSort = { direction: sortModel[0].sort, field: field2searchField[sortModel[0].field] };
      setSort(newSort);
      searchPrdTcds({ newSort });
    }
  };

  const onChangeFilter = (newFilter: {}) => {
    setFilter(newFilter);
    searchPrdTcds({ newFilter });
  };

  const field2searchField: StringKeyObj = {
    seq_id: 'seq_id',
    name: 'name',
    title_code: 'title_code_id',
    common_name: 'common_name',
    product_type: 'product_type_id',
    product_class: 'product_class_id',
    platform: 'platform_id',
    base_price: 'base_price',
    info_release_date: 'info_release_date',
    preorder_start_date: 'preorder_start_date',
    release_date: 'release_date',
    service_start_date: 'service_start_date',
    product_key: 'product_key',
    sku_number_apple: 'sku_number_apple',
    package_name_google: 'package_name_google',
    bank_app_id: 'bank_app_id',
    updatedAt: 'updatedAt',
    updated_by: 'updated_user_id'
  };

  return {
    searchPrdTcds,
    onChangePageSize,
    onChangePage,
    onChangeSort,
    onChangeFilter,
    setRows,
    total,
    page,
    loading,
    rows
  };
}
