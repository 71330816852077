export const EDIT_TAG_BSP_FIELDS = [
  {
    field: "bsp_seq_id",
    label: "BSPマスターID",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "bsp_character_name",
    label: "キャラクター名",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "tag",
    label: "IPタグ検索（日本語）",
    type: "autoComplete",
    optionsName: "name_ja",
    placeholder: "IPタグ（日本語）を入力",
    required: true,
  },
  {
    field: "tag",
    label: "IPタグ（英語）",
    type: "autoComplete",
    optionsName: "name_en",
    placeholder: "IPタグ（英語）を入力",
    required: true,
  },
  {
    field: "updated_user_id",
    label: "タグ付け最終更新者",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "タグ付け最終更新日時",
    type: "datetime",
    placeholder: "",
    disabled: true,
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
];

export const SEARCH_TAG_BSP_FIELDS = [
  {
    field: "bsp_seq_id",
    label: "BSPマスターID",
    type: "text",
    placeholder: "BSPマスターIDを入力",
  },
  {
    field: "bsp_character_name",
    label: "キャラクター名",
    type: "text",
    placeholder: "キャラクター名を入力",
  },
  {
    field: "tags_ja",
    label: "IPタグ（日本語）",
    type: "text",
    placeholder: "IPタグ（日本語）を入力",
  },
  {
    field: "tags_en",
    label: "IPタグ（英語）",
    type: "text",
    placeholder: "IPタグ（英語）を入力",
  },
  { field: "is_unregistered", label: "未登録を検索", type: "checkbox" },
  {
    field: "updatedAt",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "BSPマスター最終更新日" },
      { field: "to", label: "BSPマスター最終更新日" },
    ],
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
];

export const SEARCH_TAG_BSP_FIELDS_DEFAULT_VALUES = {
  bsp_seq_id: "",
  bsp_character_name: "",
  tags_ja: "",
  tags_en: "",
  is_unregistered: false,
  from: null,
  to: null,
  update_reason: "",
};
