import React, { useContext } from 'react';
import { NotificationContext } from '../contexts/NotificationContext'
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

function Notification() {
  const { notification, removeNotification } = useContext(NotificationContext);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Snackbar open={notification.isShow} autoHideDuration={10000} onClose={removeNotification}>
      <Alert onClose={removeNotification} severity={notification.type} sx={{ width: '100%' }}>
        {notification.message}
      </Alert>
    </Snackbar>
  )
}

export default Notification
