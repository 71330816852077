import { MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID, BANASUKE_SEQ_ID } from './common';

export const CREATE_TITLE_FIELDS = [
  {
    field: 'data_source_id',
    label: 'title_data-source-id',
    type: 'radio',
    optionsName: 'dataSource',
    column: 1
  },
  {
    field: 'name_ja',
    label: 'title_title-name-japanese',
    type: 'text',
    placeholder: 'タイトル名(日本語)を入力',
    required: true,
    column: 1
  },
  {
    field: 'name_en',
    label: 'title_title-name-english',
    type: 'text',
    placeholder: 'タイトル名(英語)を入力',
    column: 1
  },
  {
    field: 'search_name',
    label: 'title_title-name-for-search',
    type: 'text',
    placeholder: '検索用タイトル名称を入力',
    column: 1
  },
  {
    field: 'genre_id',
    label: 'title_genre',
    type: 'chip',
    placeholder: 'ジャンルを選択',
    optionsName: 'genre',
    column: 1
  },
  {
    field: 'project_cd',
    label: 'プロジェクト定義',
    type: 'arrayText',
    placeholder: 'プロジェクト定義を入力',
    column: 2
  },
  {
    field: 'sap_title_code',
    label: 'title_sap-title-code',
    type: 'arrayText',
    placeholder: 'SAPタイトルコードを入力',
    column: 2
  },
  {
    field: 'bn_connect_title_id',
    label: 'title_bn-connect-title-id',
    type: 'arrayText',
    placeholder: 'BN-ConnectタイトルIDを入力',
    column: 2
  },
  {
    field: 'banasuke_title_id',
    label: 'title_banaske-title-id',
    type: 'text',
    placeholder: 'バナスケタイトルIDを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    column: 3
  },
  {
    field: 'ce_link_key_code',
    label: 'title_ce-link-key-code',
    type: 'text',
    placeholder: 'CE-LINKキーコードを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3
  },
  {
    field: 'update_reason',
    label: 'title_reason-for-registration-renewal',
    type: 'textarea',
    placeholder: '登録更新理由を入力',
    column: 3
  },
];

export const EDIT_TITLE_PRODUCT_FIELDS = [
  {
    field: 'seq_id',
    label: 'title_title-id',
    type: 'text',
    disabled: true,
    column: 1
  },
  {
    field: 'name_ja',
    label: 'title_title-name-japanese',
    type: 'text',
    placeholder: 'タイトル名(日本語)を入力',
    required: true,
    column: 1
  },
  {
    field: 'name_en',
    label: 'title_title-name-english',
    type: 'text',
    placeholder: 'タイトル名(英語)を入力',
    column: 1
  },
  {
    field: 'search_name',
    label: 'title_title-name-for-search',
    type: 'text',
    placeholder: '検索用タイトル名称を入力',
    column: 1
  },
  {
    field: 'genre_id',
    label: 'title_genre',
    type: 'chip',
    placeholder: 'ジャンルを選択',
    optionsName: 'genre',
    column: 1
  },
  {
    field: 'project_cd',
    label: 'プロジェクト定義',
    type: 'arrayText',
    placeholder: 'プロジェクト定義を入力',
    column: 2
  },
  {
    field: 'sap_title_code',
    label: 'title_sap-title-code',
    type: 'arrayText',
    placeholder: 'SAPタイトルコードを入力',
    column: 2
  },
  {
    field: 'bn_connect_title_id',
    label: 'title_bn-connect-title-id',
    type: 'arrayText',
    placeholder: 'BN-ConnectタイトルIDを入力',
    column: 2
  },
  {
    field: 'banasuke_title_id',
    label: 'title_banaske-title-id',
    type: 'text',
    placeholder: 'バナスケタイトルIDを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    column: 3
  },
  {
    field: 'ce_link_key_code',
    label: 'title_ce-link-key-code',
    type: 'text',
    placeholder: 'CE-LINKキーコードを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3
  },
  {
    field: 'data_source_id',
    label: 'title_data-source-name',
    type: 'select',
    optionsName: 'dataSource',
    disabled: true,
    column: 3
  },
  {
    field: 'updated_user_id',
    label: 'title_final-updater',
    type: 'text',
    placeholder: '',
    disabled: true,
    column: 3
  },
  {
    field: 'updatedAt',
    label: 'title_final-update-date',
    type: 'datetime',
    disabled: true,
    column: 3
  },
  {
    field: 'update_reason',
    label: 'title_reason-for-update',
    type: 'textarea',
    placeholder: '更新理由を入力',
    column: 3
  },
];

export const EDIT_TITLE_ADMIN_FIELDS = [
  {
    field: 'seq_id',
    label: 'title_title-id',
    type: 'text',
    disabled: true,
    column: 1
  },
  {
    field: 'name_ja',
    label: 'title_title-name-japanese',
    type: 'text',
    placeholder: 'タイトル名(日本語)を入力',
    required: true,
    column: 1
  },
  {
    field: 'name_en',
    label: 'title_title-name-english',
    type: 'text',
    placeholder: 'タイトル名(英語)を入力',
    column: 1
  },
  {
    field: 'search_name',
    label: 'title_title-name-for-search',
    type: 'text',
    placeholder: '検索用タイトル名称を入力',
    column: 1
  },
  {
    field: 'genre_id',
    label: 'title_genre',
    type: 'chip',
    placeholder: 'ジャンルを選択',
    optionsName: 'genre',
    column: 1
  },
  {
    field: 'project_cd',
    label: 'プロジェクト定義',
    type: 'arrayText',
    placeholder: 'プロジェクト定義を入力',
    column: 2
  },
  {
    field: 'sap_title_code',
    label: 'title_sap-title-code',
    type: 'arrayText',
    placeholder: 'SAPタイトルコードを入力',
    column: 2
  },
  {
    field: 'bn_connect_title_id',
    label: 'title_bn-connect-title-id',
    type: 'arrayText',
    placeholder: 'BN-ConnectタイトルIDを入力',
    column: 2
  },
  {
    field: 'banasuke_title_id',
    label: 'title_banaske-title-id',
    type: 'text',
    placeholder: 'バナスケタイトルIDを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, CE_LINK_SEQ_ID],
    column: 3
  },
  {
    field: 'ce_link_key_code',
    label: 'title_ce-link-key-code',
    type: 'text',
    placeholder: 'CE-LINKキーコードを入力',
    typeIds: [MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID],
    column: 3
  },
  {
    field: 'data_source_id',
    label: 'title_data-source-name',
    type: 'select',
    optionsName: 'dataSource',
    disabled: true,
    column: 3
  },
  {
    field: 'updated_user_id',
    label: 'title_final-updater',
    type: 'text',
    placeholder: '',
    disabled: true,
    column: 3
  },
  {
    field: 'updatedAt',
    label: 'title_final-update-date',
    type: 'datetime',
    disabled: true,
    column: 3
  },
  {
    field: 'update_reason',
    label: 'title_reason-for-update',
    type: 'textarea',
    placeholder: '更新理由を入力',
    column: 3
  },
  {
    field: 'admin_check_flg',
    label: 'title_admin-checked-flag',
    type: 'checkbox',
    column: 3
  },
];

export const SEARCH_TITLE_PRODUCT_FIELDS = [
  {
    field: 'seq_id',
    label: 'title_title-id',
    type: 'text',
    placeholder: 'タイトルIDを入力',
  },
  {
    field: 'name_ja',
    label: 'title_title-name-japanese',
    type: 'text',
    placeholder: 'タイトル名(日本語)を入力',
  },
  {
    field: 'name_en',
    label: 'title_title-name-english',
    type: 'text',
    placeholder: 'タイトル名(英語)を入力',
  },
  {
    field: 'search_name',
    label: 'title_title-name-for-search',
    type: 'text',
    placeholder: '検索用タイトル名称を入力',
  },
  {
    field: 'genre_id',
    label: 'title_genre',
    type: 'select',
    placeholder: 'ジャンルを選択',
    optionsName: 'genre',
  },
  {
    field: 'project_cd',
    label: 'プロジェクト定義',
    type: 'text',
    placeholder: 'プロジェクト定義を入力',
  },
  {
    field: 'sap_title_code',
    label: 'title_sap-title-code',
    type: 'text',
    placeholder: 'SAPタイトルコードを入力',
  },
  {
    field: 'data_source_id',
    label: 'title_data-source-name',
    type: 'select',
    placeholder: 'データソース名を選択',
    optionsName: 'dataSource',
  },
  {
    field: 'bn_connect_title_id',
    label: 'BN-ConnectタイトルID',
    type: 'text',
    placeholder: 'BN-ConnectタイトルIDを入力',
  },
  {
    field: 'banasuke_title_id',
    label: 'title_bn-connect-title-id',
    type: 'text',
    placeholder: 'バナスケタイトルIDを入力',
  },
  {
    field: 'ce_link_key_code',
    label: 'title_ce-link-key-code',
    type: 'text',
    placeholder: 'CE-LINKキーコードを入力',
  },
  {
    field: 'updated_user_id',
    label: 'title_final-updater',
    type: 'text',
    placeholder: '最終更新者を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'title_final-update-date' },
      { field: 'to', label: 'title_final-update-date' },
    ],
  },
  {
    field: 'update_reason',
    label: 'title_reason-for-update',
    type: 'textarea',
    placeholder: '更新理由を入力',
  },
  {
    field: 'system_update_flg',
    label: 'title_automatic-update-flag',
    type: 'select',
    optionsName: 'system_update_flg',
    isBlank: false,
  },
];

export const SEARCH_TITLE_ADMIN_FIELDS = [
  {
    field: 'seq_id',
    label: 'title_title-id',
    type: 'text',
    placeholder: 'タイトルIDを入力',
  },
  {
    field: 'name_ja',
    label: 'title_title-name-japanese',
    type: 'text',
    placeholder: 'タイトル名(日本語)を入力',
  },
  {
    field: 'name_en',
    label: 'title_title-name-english',
    type: 'text',
    placeholder: 'タイトル名(英語)を入力',
  },
  {
    field: 'search_name',
    label: 'title_title-name-for-search',
    type: 'text',
    placeholder: '検索用タイトル名称を入力',
  },
  {
    field: 'genre_id',
    label: 'title_genre',
    type: 'select',
    placeholder: 'ジャンルを選択',
    optionsName: 'genre',
  },
  {
    field: 'project_cd',
    label: 'プロジェクト定義',
    type: 'text',
    placeholder: 'プロジェクト定義を入力',
  },
  {
    field: 'sap_title_code',
    label: 'title_sap-title-code',
    type: 'text',
    placeholder: 'SAPタイトルコードを入力',
  },
  {
    field: 'data_source_id',
    label: 'title_data-source-name',
    type: 'select',
    placeholder: 'データソース名を選択',
    optionsName: 'dataSource',
  },
  {
    field: 'bn_connect_title_id',
    label: 'title_bn-connect-title-id',
    type: 'text',
    placeholder: 'BN-ConnectタイトルIDを入力',
  },
  {
    field: 'banasuke_title_id',
    label: 'title_banaske-title-id',
    type: 'text',
    placeholder: 'バナスケタイトルIDを入力',
  },
  {
    field: 'ce_link_key_code',
    label: 'title_ce-link-key-code',
    type: 'text',
    placeholder: 'CE-LINKキーコードを入力',
  },
  {
    field: 'updated_user_id',
    label: 'title_final-updater',
    type: 'text',
    placeholder: '最終更新者を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'title_final-update-date' },
      { field: 'to', label: 'title_final-update-date' },
    ],
  },
  {
    field: 'update_reason',
    label: 'title_reason-for-update',
    type: 'textarea',
    placeholder: '更新理由を入力',
  },
  {
    field: 'system_update_flg',
    label: 'title_automatic-update-flag',
    type: 'select',
    optionsName: 'system_update_flg',
    isBlank: false,
  },
  {
    field: 'admin_check_flg',
    label: 'title_admin-checked-flag',
    type: 'select',
    optionsName: 'admin_check_flg',
    isBlank: false,
  },
  {
    field: 'delete_flg',
    label: 'title_deleted-data',
    type: 'select',
    optionsName: 'delete_flg',
    isBlank: false,
  },
];

export const RESET_TITLE_FIELDS = [
  { field: 'name_ja', value: { defaultValue: '' } },
  { field: 'name_en', value: { defaultValue: '' } },
  { field: 'search_name', value: { defaultValue: '' } },
  { field: 'genre_id', value: { defaultValue: '' } },
  { field: 'update_reason', value: { defaultValue: '' } },
  { field: 'banasuke_title_id', value: { defaultValue: '' } },
  { field: 'ce_link_key_code', value: { defaultValue: '' } },
];

export const VALIDATE_DUPLICATE_TITLE_FIELDS = ['banasuke_title_id', 'ce_link_key_code'];

export const SEARCH_TITLE_FIELDS_DEFAULT_VALUES = {
  seq_id: '',
  name_ja: '',
  name_en: '',
  search_name: '',
  genre_id: '',
  sap_title_code: '',
  project_cd: '',
  update_reason: '',
  data_source_id: '',
  bn_connect_title_id: '',
  banasuke_title_id: '',
  ce_link_key_code: '',
  updated_user_id: '',
  from: null,
  to: null,
  delete_flg: false,
  admin_check_flg: true,
  system_update_flg: 'ALL',
};
