import React, { useEffect, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import FormSelectBox from '../forms/FormSelectBox';
import SearchDialog from '../SearchDialog';
import { GroupCompany, Country, Currency } from '../../models';
import { titleFields } from '../../types';
import { classificationValidateTitle } from '../../hooks/classification';
import { SearchConditionGroupCompany } from '../../types/form/GroupCompany';
import {
  BRAND_PORTFOLIO_LIST,
  COMPANY_ATTRIBUTE_LIST,
  CONSOLIDATION_LIST,
  EQUITY_METHOD_APPLICATION_LIST,
  MANAGEMENT_BUSINESS_LIST,
  PUBLIC_OR_PRIVATE_LIST,
  UNIT_CLASS_LIST,
  GROUP_AGREEMENT_FLG_LIST,
  MANAGEMENT_CONTACT_FLG_LIST,
  SUBCONTRACTING_CONTACT_FLG_LIST,
  SEARCH_GROUP_COMPANY_FIELDS,
  SEARCH_GROUP_COMPANY_FIELDS_DEFAULT_VALUES,
} from '../../consts/groupCompany';
import { generateSearchFilter, SEARCH_TYPE } from '../../utils/search';
import { searchGroupCompanies } from '../../graphql/queries';
import FormAutocomplete from '../forms/FormAutocomplete';
import PrimaryButton from '../PrimaryButton';
import { DELETE_FLG_OPTIONS } from '../../consts/common';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  setOpen: Function;
  onChangeFilter: Function;
  fetchGroupCompanies: Function;
  condition: SearchConditionGroupCompany;
  groupCompanies: GroupCompany[];
  countries: Country[];
  currencies: Currency[];
  setFilter: React.Dispatch<React.SetStateAction<SearchConditionGroupCompany>>;
};

type valueOf<T> = T[keyof T];

function SearchGroupCompanyDialog(props: Props) {
  const {
    condition,
    open,
    setOpen,
    onChangeFilter,
    fetchGroupCompanies,
    groupCompanies,
    countries,
    currencies,
    setFilter,
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues: SEARCH_GROUP_COMPANY_FIELDS_DEFAULT_VALUES });

  const [currentGroupCompanies, setCurrentGGroupCompanies] = useState<GroupCompany[]>(groupCompanies);

  const updateFormValueForCondition = useCallback(() => {
    Object.entries(condition).forEach((cond: [keyof SearchConditionGroupCompany, valueOf<typeof condition>]) =>
      setValue(cond[0], cond[1])
    );
    setValue(
      'holding_company_1',
      // @ts-expect-error
      groupCompanies?.find((groupCompany) => condition?.['holding_company_1'] === groupCompany.group_company_code)
    );
    setValue(
      'holding_company_2',
      // @ts-expect-error
      groupCompanies?.find((groupCompany) => condition?.['holding_company_2'] === groupCompany.group_company_code)
    );
    setValue(
      'holding_company_3',
      // @ts-expect-error
      groupCompanies?.find((groupCompany) => condition?.['holding_company_3'] === groupCompany.group_company_code)
    );
    setValue(
      'holding_company_4',
      // @ts-expect-error
      groupCompanies?.find((groupCompany) => condition?.['holding_company_4'] === groupCompany.group_company_code)
    );
    setValue(
      'holding_company_5',
      // @ts-expect-error
      groupCompanies?.find((groupCompany) => condition?.['holding_company_5'] === groupCompany.group_company_code)
    );
  }, [condition, groupCompanies, setValue]);

  useEffect(() => {
    updateFormValueForCondition();
  }, [updateFormValueForCondition]);

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onReset = () => {
    reset();
    setFilter(SEARCH_GROUP_COMPANY_FIELDS_DEFAULT_VALUES);
    updateFormValueForCondition();
  };

  const stringToNumber = (text: string | null | number | undefined) => {
    if (typeof text !== 'number' && !text) return null;
    return Number(text);
  };

  const onSubmit: SubmitHandler<SearchConditionGroupCompany> = async (newFilter) => {
    await fetchGroupCompanies({
      newFilter: {
        ...newFilter,
        holding_company_1:
          newFilter.holding_company_1 !== '' ? newFilter.holding_company_1?.group_company_code ?? null : null,
        holding_company_2:
          newFilter.holding_company_2 !== '' ? newFilter.holding_company_2?.group_company_code ?? null : null,
        holding_company_3:
          newFilter.holding_company_3 !== '' ? newFilter.holding_company_3?.group_company_code ?? null : null,
        holding_company_4:
          newFilter.holding_company_4 !== '' ? newFilter.holding_company_4?.group_company_code ?? null : null,
        holding_company_5:
          newFilter.holding_company_5 !== '' ? newFilter.holding_company_5?.group_company_code ?? null : null,
        ip_address: newFilter?.['ip_address'],
        accounting_period: stringToNumber(newFilter.accounting_period ?? null),
        investment_ratio_1: stringToNumber(newFilter.investment_ratio_1 ?? null),
        investment_ratio_2: stringToNumber(newFilter.investment_ratio_2 ?? null),
        investment_ratio_3: stringToNumber(newFilter.investment_ratio_3 ?? null),
        investment_ratio_4: stringToNumber(newFilter.investment_ratio_4 ?? null),
        investment_ratio_5: stringToNumber(newFilter.investment_ratio_5 ?? null),
        capital: newFilter.capital,
        number_of_employees: stringToNumber(newFilter.number_of_employees ?? null),
        total_investment_ratio: stringToNumber(newFilter.total_investment_ratio ?? null),
        issued_shares_total: stringToNumber(newFilter.issued_shares_total ?? null),
        treasury_stock: stringToNumber(newFilter.treasury_stock ?? null),
      },
    });
    onClose();
  };

  const options = (name: string) => {
    switch (name) {
      case 'company_attribute':
        return COMPANY_ATTRIBUTE_LIST;
      case 'consolidation':
        return CONSOLIDATION_LIST;
      case 'equity_method_application':
        return EQUITY_METHOD_APPLICATION_LIST;
      case 'unit_class_1':
      case 'unit_class_2':
        return UNIT_CLASS_LIST;
      case 'management_business_1':
      case 'management_business_2':
        return MANAGEMENT_BUSINESS_LIST;
      case 'country':
        return countries;
      case 'public_or_private':
        return PUBLIC_OR_PRIVATE_LIST;
      case 'brand_portfolio':
        return BRAND_PORTFOLIO_LIST;
      case 'holding_company_1':
      case 'holding_company_2':
      case 'holding_company_3':
      case 'holding_company_4':
      case 'holding_company_5':
        return currentGroupCompanies;
      case 'currency':
        return currencies;
      case 'group_agreement_flg':
        return GROUP_AGREEMENT_FLG_LIST;
      case 'management_contract_flg':
        return MANAGEMENT_CONTACT_FLG_LIST;
      case 'subcontracting_contract_flg':
        return SUBCONTRACTING_CONTACT_FLG_LIST;
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      default:
        return [];
    }
  };

  const onGroupCompaniesSearch = async (field: string) => {
    const value = (document.getElementById(field) as HTMLInputElement).value;

    if (!value) return;

    const filter = generateSearchFilter([{ name: 'group_company_code', value, type: SEARCH_TYPE.WILDCARD }]);
    try {
      const models: any = await API.graphql({
        query: searchGroupCompanies,
        variables: { ...filter, sort: { direction: 'desc', field: 'seq_id' }, limit: 500 },
      });
      const groupCompanies = [...models.data.searchGroupCompanies.items] as GroupCompany[];
      let uniqueGroupCompanyCode = [];
      const uniqueGroupCompanies = groupCompanies.reduce((current, groupCompany) => {
        if (!uniqueGroupCompanyCode.includes(groupCompany.group_company_code)) {
          uniqueGroupCompanyCode.push(groupCompany.group_company_code);
          return [...current, groupCompany];
        }
        return current;
      }, []);
      setCurrentGGroupCompanies([...uniqueGroupCompanies]);
    } catch (e) {
      console.log(e);
    }
  };

  /** 最大カラム数 */
  const maxColumnOrders = Math.max(...SEARCH_GROUP_COMPANY_FIELDS.map((field) => field.column));

  return (
    <SearchDialog
      open={open}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
      height="600"
      width="xl"
    >
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => (
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: 300 },
              display: 'flex',
              flexFlow: 'column wrap',
              height: 'inherit',
            }}
            width={330}
            key={i}
          >
            {SEARCH_GROUP_COMPANY_FIELDS.map(
              (row) =>
                row.column === i + 1 && (
                  <React.Fragment key={row.field}>
                    {row.type === 'select' && row.optionsName && (
                      <FormSelectBox
                        label={row.label}
                        content={options(row.optionsName)}
                        field={row.field}
                        control={control}
                        placeholder={row.placeholder}
                        isBlank={row.isBlank}
                      />
                    )}
                    {(row.type === 'datetime' || row.type === 'date') && row.inputs && (
                      <Box sx={{ display: 'flex' }}>
                        <FormDateTimeRangePicker
                          fromLabel={row.inputs[0].label}
                          fromField={row.inputs[0].field}
                          toLabel={row.inputs[0].label}
                          toField={row.inputs[1].field}
                          type={row.type}
                          placeholder={row.placeholder}
                          control={control}
                          watch={watch}
                          errors={errors}
                        />
                      </Box>
                    )}
                    {row.type === 'text' && (
                      <FormTextField
                        label={row.label}
                        placeholder={row.placeholder}
                        field={row.field}
                        control={control}
                        errors={errors}
                        validationRules={[]}
                      />
                    )}
                    {row.type === 'autoComplete' && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          '& > div': { width: '70%' },
                          '& > button': { marginTop: '10px' },
                        }}
                      >
                        <FormAutocomplete
                          id={row.field}
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          errors={errors}
                          validationRules={[]}
                          required={row.required}
                          options={options(row.field)}
                          freeSolo
                          includeSearchEnglish
                          isGroupCompany
                        />
                        <PrimaryButton onClick={() => onGroupCompaniesSearch(row.field)}>検索</PrimaryButton>
                      </Box>
                    )}
                  </React.Fragment>
                )
            )}
          </Box>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchGroupCompanyDialog;
