import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormSelectBox from '../forms/FormSelectBox';
import FormCheckBox from '../forms/FormCheckBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import {
  SEARCH_TAG_TITLE_HISTORIES_FIELDS,
  SEARCH_TAG_TITLE_HISTORY_FIELDS_DEFAULT_VALUES
} from '../../consts/tagTitleHistory';
import { OPERATION_TYPE_OPTIONS, } from '../../consts/common';
import { tagTitleHistoryFields } from '../../types';
import { classificationValidateTagTitleHistory } from '../../hooks/classification';
import { RelationHistorySearch } from '../../types/form/relationHistory'

type Props = {
  condition: RelationHistorySearch;
  open: boolean;
  setOpen: Function;
  fetchTagTitleHistories: Function;
}

function SearchTagTitleHistoryDialog(props: Props) {
  const { condition, open, setOpen, fetchTagTitleHistories } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_TAG_TITLE_HISTORY_FIELDS_DEFAULT_VALUES });

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<RelationHistorySearch> = async (newCondition) => {
    try {
      await fetchTagTitleHistories({ newCondition })
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onReset = () => {
    reset();
  };

  const options = (name: string) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <SearchDialog open={open} onCancel={onClose} onReset={onReset} onSubmit={handleSubmit(onSubmit)} height={510} width="xl">
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 400 },
        }}
        width={440}
      >
        {SEARCH_TAG_TITLE_HISTORIES_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'select' && (
              <FormSelectBox
                label={row.label}
                content={options(row.field)}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
              />
            )}
            {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={row.inputs[0].field}
                  toLabel={row.inputs[0].label}
                  toField={row.inputs[1].field}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {(row.type === 'text' || row.type === 'textarea') && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                isTextArea={row.type === 'textarea'}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateTagTitleHistory(row.field as tagTitleHistoryFields)}
              />
            )}
            {row.type === 'checkbox' && <FormCheckBox field={row.field} label={row.label} control={control} />}
          </React.Fragment>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchTagTitleHistoryDialog;
