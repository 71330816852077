import {
  ProductFields,
  tagFields,
  titleFields,
  tagTitleHistoryFields,
  tagTitleCodeHistoryFields,
  tagTitleFields,
  tagBspFields,
  tagBnmlFields,
  genreFields,
  platformFields,
  productTypeFields,
  productClassFields,
  currencyFields,
  BspFields,
  BspHistoryFields,
  countryFields,
  rateFields,
  calendarFields,
  businessEntityFields,
  BnamFields,
  BnmlFields,
  BnmlHistoryFields,
  BnamHistoryFields,
  tagBnamFields,
  tagBnfFields,
  GroupCompanyHistoryFields,
} from '../types';
import { validationRules } from '../types/index';
import { TagBspHistorySearch } from '../types/form/tagBspHistory';
import { TitleCode, ProductTcd, Bnf, BnfHistory, GroupCompany } from '../models';
import { TagBnmlHistorySearch } from '../types/form/tagBnmlHistory';
import { TagBnamHistorySearch } from '../types/form/tagBnamHistory';
import { TagBnfHistorySearch } from '../types/form/tagBnfHistory';

export const classificationValidatePrd = (field: ProductFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'base_price':
        return ['number', 'minnum', 'maxnum'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'base_price':
        return ['number', 'minnum', 'maxnum'];
      case 'bank_app_id':
        return ['maxlen16', 'alphanumericAndSymbol'];
      case 'name':
        return ['maxlen256', 'required', 'invalidTabAndNewLine'];
      case 'product_key':
        return ['maxlen256', 'required'];
      case 'common_name':
        return ['maxlen256', 'invalidTabAndNewLine'];
      case 'updated_reason':
        return ['maxlen1024'];
      case 'product_type_id':
      case 'product_class_id':
      case 'platform_id':
        return ['required'];
      case 'title':
      case 'title_code':
        return ['required'];
      case 'market':
        return ['maxlen256', 'invalidTabAndNewLine'];
      case 'release_status':
        return ['maxlen256', 'invalidTabAndNewLine'];
      default:
        return [];
    }
  }
};

export const classificationValidateProductTcd = (field: keyof ProductTcd, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'base_price':
        return ['number', 'minnum', 'maxnum'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'base_price':
        return ['number', 'minnum', 'maxnum'];
      case 'bank_app_id':
        return ['maxlen16', 'alphanumericAndSymbol'];
      case 'name':
        return ['maxlen256', 'required', 'invalidTabAndNewLine'];
      case 'product_key':
        return ['maxlen256', 'required', 'alphanumericAndSymbolAndSpaceAndFullWidthSpace'];
      case 'common_name':
        return ['maxlen256', 'invalidTabAndNewLine'];
      case 'update_reason':
        return ['maxlen1024', 'invalidTabAndNewLine'];
      case 'product_type_id':
      case 'product_class_id':
      case 'platform_id':
        return ['required'];
      case 'title_code':
        return ['required'];
      case 'market':
        return ['maxlen256', 'invalidTabAndNewLine'];
      case 'release_status':
        return ['maxlen256', 'invalidTabAndNewLine'];
      default:
        return [];
    }
  }
};

export const classificationValidateTag = (field: tagFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'name_ja':
        return ['maxlen128'];
      case 'name_en':
        return ['maxlen128'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'name_ja':
        return ['required', 'maxlen128', 'invalidTabAndNewLine'];
      case 'name_en':
        return ['maxlen128', 'invalidTabAndNewLine'];
      default:
        return [];
    }
  }
};

export const classificationValidateTitle = (field: titleFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'name_ja':
        return ['maxlen128'];
      case 'name_en':
        return ['maxlen128'];
      case 'search_name':
        return ['maxlen1024'];
      case 'genre_id':
        return [];
      case 'project_cd':
        return ['maxlen256', 'alphanumericAndSymbol'];
      case 'sap_title_code':
        return ['maxlen256', 'alphanumericAndSymbol'];
      case 'update_reason':
        return ['maxlen1024'];
      case 'data_source_id':
        return [];
      case 'bn_connect_title_id':
        return ['maxlen256', 'alphanumericAndSymbol'];
      case 'banasuke_title_id':
        return ['maxlen8', 'alphanumeric'];
      case 'ce_link_key_code':
        return ['maxlen32', 'alphanumeric'];
      case 'admin_check_flg':
        return [];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'name_ja':
        return ['required', 'maxlen128', 'invalidTabAndNewLine'];
      case 'name_en':
        return ['maxlen128', 'invalidTabAndNewLine'];
      case 'search_name':
        return ['maxlen1024', 'invalidTabAndNewLine'];
      case 'genre_id':
        return [];
      case 'project_cd':
        return ['maxlen256', 'alphanumericAndSymbol'];
      case 'sap_title_code':
        return ['maxlen256', 'alphanumericAndSymbol'];
      case 'update_reason':
        return ['maxlen1024'];
      case 'data_source_id':
        return [];
      case 'bn_connect_title_id':
        return ['maxlen256', 'alphanumericAndSymbol'];
      case 'banasuke_title_id':
        return ['maxlen8', 'alphanumeric'];
      case 'ce_link_key_code':
        return ['maxlen32', 'alphanumeric'];
      case 'admin_check_flg':
        return [];
      default:
        return [];
    }
  }
};

export const classificationValidateTagTitleHistory = (field: tagTitleHistoryFields): validationRules[] => {
  switch (field) {
    case 'title_name':
      return ['maxlen128'];
    case 'tag_name':
      return ['maxlen128'];
    default:
      return [];
  }
};

export const classificationValidateTagTitleCodeHistory = (field: tagTitleCodeHistoryFields): validationRules[] => {
  switch (field) {
    case 'title_code_name':
      return ['maxlen128'];
    case 'tag_name':
      return ['maxlen128'];
    default:
      return [];
  }
};

export const classificationValidateTagTitle = (field: tagTitleFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'title_name':
        return ['maxlen128'];
      case 'tag_name':
        return ['maxlen128'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'tag':
        return ['tagRequired'];
      default:
        return [];
    }
  }
};

export const classificationValidateTagBsp = (field: tagBspFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'bsp_character_name':
        return ['maxlen128'];
      case 'tag_name':
        return ['maxlen128'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'tag':
        return ['required'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  }
};

export const classificationValidateTagBnml = (field: tagBnmlFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'mlics_plan_cd_4char':
        return ['maxlen4', 'integer'];
      case 'mlics_plan_cd_7char':
        return ['maxlen7', 'integer'];
      case 'mlics_commodity_name':
        return ['maxlen256'];
      case 'tag_name':
        return ['maxlen128'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'tag':
        return ['required'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  }
};

export const classificationValidateTagBnmlHitory = (field: keyof TagBnmlHistorySearch): validationRules[] => {
  switch (field) {
    case 'update_reason':
      return ['maxlen1024'];
    default:
      return [];
  }
};

export const classificationValidateTagBnfHistory = (field: keyof TagBnfHistorySearch): validationRules[] => {
  switch (field) {
    case 'update_reason':
      return ['maxlen1024'];
    default:
      return [];
  }
};

export const classificationValidateGenre = (field: genreFields): validationRules[] => {
  switch (field) {
    case 'name':
      return ['required', 'invalidTabAndNewLine'];
    case 'name_ja':
      return ['maxlen256', 'invalidTabAndNewLine'];
    case 'ce_link_id':
      return ['invalidTabAndNewLine'];
    default:
      return [];
  }
};

export const classificationValidatePlatform = (field: platformFields): validationRules[] => {
  switch (field) {
    case 'name':
      return ['required', 'invalidTabAndNewLine'];
    case 'banasuke_name':
      return ['invalidTabAndNewLine'];
    default:
      return [];
  }
};

export const classificationValidateProductType = (field: productTypeFields): validationRules[] => {
  switch (field) {
    case 'name':
      return ['required', 'invalidTabAndNewLine'];
    case 'name_ja':
      return ['maxlen256', 'invalidTabAndNewLine'];
    default:
      return [];
  }
};

export const classificationValidateProductClass = (field: productClassFields): validationRules[] => {
  switch (field) {
    case 'name':
      return ['required', 'invalidTabAndNewLine'];
    case 'name_ja':
      return ['maxlen256', 'invalidTabAndNewLine'];
    default:
      return [];
  }
};

export const classificationValidateCurrency = (field: currencyFields): validationRules[] => {
  switch (field) {
    case 'code_three_char':
      return ['maxlen3'];
    case 'update_reason':
      return ['maxlen256'];
    case 'name':
      return ['maxlen256'];
    case 'name_en':
      return ['maxlen256'];
    default:
      return [];
  }
};

export const classificationValidateBsp = (field: BspFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'character_name':
        return ['maxlen256'];
      case 'character_code':
        return ['maxlen32', 'alphanumeric'];
      case 'character_series_name':
        return ['maxlen256'];
      case 'character_series_code':
        return ['maxlen32', 'alphanumeric'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'character_name':
        return ['maxlen256'];
      case 'character_code':
        return ['required', 'maxlen32', 'alphanumeric'];
      case 'character_series_name':
        return ['maxlen256'];
      case 'character_series_code':
        return ['required', 'maxlen32', 'alphanumeric'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  }
};

export const classificationValidateBnml = (field: BnmlFields): validationRules[] => {
  switch (field) {
    case 'mlics_plan_cd_4char':
      return ['maxlen4', 'integer'];
    case 'mlics_plan_cd_7char':
      return ['maxlen7', 'integer'];
    case 'mlics_commodity_name':
      return ['maxlen256', 'required'];
    case 'update_reason':
      return ['maxlen1024'];
    default:
      return [];
  }
};

export const classificationSearchValidateBnml = (field: BnmlFields): validationRules[] => {
  switch (field) {
    case 'mlics_plan_cd_4char':
      return ['maxlen4', 'integer'];
    case 'mlics_plan_cd_7char':
      return ['maxlen7', 'integer'];
    case 'mlics_commodity_name':
      return ['maxlen256'];
    case 'update_reason':
      return ['maxlen1024'];
    default:
      return [];
  }
};

export type BnfFields =
  | 'seq_id'
  | 'bnf_data_source'
  | 'obic7_commodity_cd'
  | 'obic7_commodity_name'
  | 'bvics_plan_cd_4char'
  | 'bvics_plan_cd_7char'
  | 'bvics_product_name'
  | 'updated_user_id'
  | 'updatedAt_from'
  | 'updatedAt_to'
  | 'update_reason'
  | 'system_update_flg'
  | 'delete_flg';

export const classificationValidateBnf = (
  field: keyof Bnf | keyof BnfHistory | 'operation_type',
  isSearch?: 'search'
): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'bnf_data_source':
        return ['required'];
      case 'obic7_commodity_cd':
        return ['maxlen6'];
      case 'obic7_commodity_name':
        return ['maxlen256'];
      case 'bvics_plan_cd_4char':
        return ['maxlen4'];
      case 'bvics_plan_cd_7char':
        return ['maxlen7'];
      case 'bvics_product_name':
        return ['maxlen256'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  }
};

export const classificationValidateTagBnf = (field: tagBnfFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'bnf_data_source':
        return ['maxlen256'];
      case 'obic7_commodity_cd':
        return ['maxlen256'];
      case 'obic7_commodity_name':
        return ['maxlen256'];
      case 'bvics_plan_cd_4char':
        return ['maxlen256'];
      case 'bvics_plan_cd_7char':
        return ['maxlen256'];
      case 'bvics_product_name':
        return ['maxlen256'];
      case 'tag_name':
        return ['maxlen128'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'tag':
        return ['required'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  }
};

export const classificationValidateBspHistory = (field: BspHistoryFields): validationRules[] => {
  switch (field) {
    case 'update_reason':
      return ['maxlen256'];
    default:
      return [];
  }
};

export const classificationValidateBnmlHistory = (field: BnmlHistoryFields): validationRules[] => {
  switch (field) {
    case 'update_reason':
      return ['maxlen256'];
    default:
      return [];
  }
};

export const classificationValidateTagBspHistory = (field: keyof TagBspHistorySearch): validationRules[] => {
  switch (field) {
    case 'update_reason':
      return ['maxlen1024'];
    default:
      return [];
  }
};

export const classificationValidateCountry = (field: countryFields): validationRules[] => {
  switch (field) {
    case 'code_two_char':
      return ['maxlen2', 'alphanumericAndSymbol'];
    case 'code_three_char':
      return ['maxlen3', 'alphanumericAndSymbol'];
    case 'update_reason':
      return ['maxlen256'];
    case 'name':
      return ['maxlen128'];
    case 'name_en':
      return ['maxlen128', 'alphanumericAndSymbolAndSpaceAndFullWidthSpace'];
    default:
      return [];
  }
};

export const classificationValidateRate = (field: rateFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'exchange_rate':
        return ['number'];
      case 'company_name':
        return ['maxlen256'];
      case 'exchange_rate_type':
        return ['maxlen32'];
      case 'usage':
        return ['maxlen1024'];
      case 'description':
        return ['maxlen1024'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'source_currency_id':
        return ['required'];
      case 'converted_currency_id':
        return ['required'];
      case 'from_date':
        return ['required'];
      case 'to_date':
        return ['required'];
      case 'exchange_rate':
        return ['required', 'number'];
      case 'company_name':
        return ['required', 'maxlen256'];
      case 'exchange_rate_type':
        return ['required', 'maxlen32'];
      case 'usage':
        return ['maxlen1024'];
      case 'description':
        return ['maxlen1024'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  }
};

export const classificationValidateCalendar = (field: calendarFields): validationRules[] => {
  switch (field) {
    case 'year':
      return ['maxlen4', 'integer'];
    case 'month':
      return ['maxlen2', 'integer', 'monthMaxnum'];
    case 'day':
      return ['maxlen2', 'integer', 'dateMaxnum'];
    case 'update_reason':
      return ['maxlen256'];
    default:
      return [];
  }
};

export const classificationValidateTitleCode = (field: keyof TitleCode): validationRules[] => {
  switch (field) {
    case 'title_code':
      return ['maxlen256', 'alphanumericAndSymbol', 'required', 'invalidTabAndNewLine'];
    case 'name_ja':
    case 'name_en':
    case 'sap_title_code_name':
    case 'ce_link_title_name_ja':
    case 'ce_link_title_name_en':
    case 'banasuke_title_name_ja':
      return ['maxlen256', 'invalidTabAndNewLine'];
    case 'search_name':
    case 'update_reason':
      return ['maxlen1024', 'invalidTabAndNewLine'];
    case 'project_cd':
    case 'wbs':
    case 'bn_connect_title_ids':
    case 'bundle_source_title_code_ids':
    case 'porting_source_title_code_ids':
      return ['maxlen32', 'alphanumericAndSymbol'];
    case 'ce_link_key_code':
      return ['maxlen32', 'alphanumeric', 'invalidTabAndNewLine'];
    case 'banasuke_title_id':
      return ['maxlen8', 'alphanumeric', 'invalidTabAndNewLine'];
    default:
      return [];
  }
};

export const classificationValidateGroupCompany = (field: keyof GroupCompany): validationRules[] => {
  switch (field) {
    case 'group_company_code':
      return ['invalidTabAndNewLine', 'required'];
    case 'valid_start_date':
      return ['alphanumeric', 'invalidTabAndNewLine', 'required'];
    case 'stravis_code':
      return ['maxlen20', 'alphanumericAndSymbol', 'invalidTabAndNewLine'];
    case 'company_name_ja':
      return ['maxlen256', 'invalidTabAndNewLine'];
    case 'company_name_en':
    case 'abbreviation':
      return ['maxlen256', 'invalidTabAndNewLine'];
    case 'nominee_shares':
      return ['maxlen256'];
    case 'accounting_period':
      return ['maxlen10', 'invalidTabAndNewLine'];
    case 'total_investment_ratio':
    case 'investment_ratio_1':
    case 'investment_ratio_2':
    case 'investment_ratio_3':
    case 'investment_ratio_4':
    case 'investment_ratio_5':
      return ['maxlen10', 'integer'];
    case 'capital':
      return ['maxlen20', 'integer'];
    case 'number_of_employees':
    case 'treasury_stock':
    case 'issued_shares_total':
      return ['maxlen10', 'integer'];
    case 'representative_director_1_position':
    case 'representative_director_1_name':
    case 'representative_director_2_position':
    case 'representative_director_2_name':
    case 'representative_director_3_position':
    case 'representative_director_3_name':
      return ['maxlen256', 'invalidTabAndNewLine'];
    case 'notes':
    case 'address_1':
    case 'address_2':
    case 'business_description':
    case 'update_reason':
      return ['maxlen1024'];
    case 'ip_address':
      return ['alphanumeric', 'maxlen256', 'invalidTabAndNewLine'];
    default:
      return [];
  }
};

export const classificationValidateBusinessEntity = (field: businessEntityFields): validationRules[] => {
  switch (field) {
    case 'name':
      return ['maxlen256', 'required', 'invalidTabAndNewLine'];
    case 'description':
      return ['maxlen1024'];
    default:
      return [];
  }
};

export const classificationValidateBnam = (field: BnamFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'ip_name':
        return ['maxlen256'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'ip_name':
        return ['maxlen256', 'required', 'invalidTabAndNewLine'];
      case 'update_reason':
        return ['maxlen1024'];
      default:
        return [];
    }
  }
};
export const classificationValidateBnamHistory = (field: BnamHistoryFields): validationRules[] => {
  switch (field) {
    case 'ip_name':
      return ['maxlen256'];
    case 'update_reason':
      return ['maxlen1024'];
    default:
      return [];
  }
};

export const classificationValidateTagBnam = (field: tagBnamFields, isSearch?: 'search'): validationRules[] => {
  if (isSearch) {
    switch (field) {
      case 'ip_name':
        return ['maxlen256'];
      case 'tag_name':
        return ['maxlen128'];
      default:
        return [];
    }
  } else {
    switch (field) {
      case 'tag':
        return ['required'];
      default:
        return [];
    }
  }
};

export const classificationValidateTagBnamHitory = (field: keyof TagBnamHistorySearch): validationRules[] => {
  switch (field) {
    case 'update_reason':
      return ['maxlen1024'];
    default:
      return [];
  }
};

export const classificationValidateGroupCompanyHistory = (field: GroupCompanyHistoryFields): validationRules[] => {
  switch (field) {
    default:
      return [];
  }
};
