export const DETAIL_IMPORT_FIELDS = [
  {
    field: "table_name",
    label: "マスタ名",
    type: "text",
    required: false,
    disabled: true,
  },
  {
    field: "name",
    label: "ファイル名",
    type: "text",
    required: false,
    disabled: true,
  },
  {
    field: "status",
    label: "ステータス",
    type: "text",
    required: false,
    disabled: true,
  },
  {
    field: "updated_user_id",
    label: "ユーザー",
    type: "text",
    required: false,
    disabled: true,
  },
  {
    field: "createdAt",
    label: "インポート日時",
    type: "datetime",
    required: false,
    disabled: true,
  },
  {
    field: "message",
    label: "メッセージ",
    type: "textarea",
    required: false,
    disabled: true,
  },
  {
    field: "error_log",
    label: "エラーログ",
    type: "textarea",
    required: false,
    disabled: true,
  },
];
