/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateGenre = /* GraphQL */ `subscription OnCreateGenre($filter: ModelSubscriptionGenreFilterInput) {
  onCreateGenre(filter: $filter) {
    id
    ce_link_id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGenreSubscriptionVariables,
  APITypes.OnCreateGenreSubscription
>;
export const onUpdateGenre = /* GraphQL */ `subscription OnUpdateGenre($filter: ModelSubscriptionGenreFilterInput) {
  onUpdateGenre(filter: $filter) {
    id
    ce_link_id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGenreSubscriptionVariables,
  APITypes.OnUpdateGenreSubscription
>;
export const onDeleteGenre = /* GraphQL */ `subscription OnDeleteGenre($filter: ModelSubscriptionGenreFilterInput) {
  onDeleteGenre(filter: $filter) {
    id
    ce_link_id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGenreSubscriptionVariables,
  APITypes.OnDeleteGenreSubscription
>;
export const onCreateTag = /* GraphQL */ `subscription OnCreateTag($filter: ModelSubscriptionTagFilterInput) {
  onCreateTag(filter: $filter) {
    id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    titles {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    title_codes {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bsps {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnams {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnmls {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnfs {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTagSubscriptionVariables,
  APITypes.OnCreateTagSubscription
>;
export const onUpdateTag = /* GraphQL */ `subscription OnUpdateTag($filter: ModelSubscriptionTagFilterInput) {
  onUpdateTag(filter: $filter) {
    id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    titles {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    title_codes {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bsps {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnams {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnmls {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnfs {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTagSubscriptionVariables,
  APITypes.OnUpdateTagSubscription
>;
export const onDeleteTag = /* GraphQL */ `subscription OnDeleteTag($filter: ModelSubscriptionTagFilterInput) {
  onDeleteTag(filter: $filter) {
    id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    titles {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    title_codes {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bsps {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnams {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnmls {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnfs {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTagSubscriptionVariables,
  APITypes.OnDeleteTagSubscription
>;
export const onCreateTitle = /* GraphQL */ `subscription OnCreateTitle($filter: ModelSubscriptionTitleFilterInput) {
  onCreateTitle(filter: $filter) {
    id
    seq_id
    name_ja
    name_en
    search_name
    sap_title_code
    bn_connect_title_id
    project_cd
    banasuke_title_id
    ce_link_key_code
    update_reason
    genre_id
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTitleSubscriptionVariables,
  APITypes.OnCreateTitleSubscription
>;
export const onUpdateTitle = /* GraphQL */ `subscription OnUpdateTitle($filter: ModelSubscriptionTitleFilterInput) {
  onUpdateTitle(filter: $filter) {
    id
    seq_id
    name_ja
    name_en
    search_name
    sap_title_code
    bn_connect_title_id
    project_cd
    banasuke_title_id
    ce_link_key_code
    update_reason
    genre_id
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTitleSubscriptionVariables,
  APITypes.OnUpdateTitleSubscription
>;
export const onDeleteTitle = /* GraphQL */ `subscription OnDeleteTitle($filter: ModelSubscriptionTitleFilterInput) {
  onDeleteTitle(filter: $filter) {
    id
    seq_id
    name_ja
    name_en
    search_name
    sap_title_code
    bn_connect_title_id
    project_cd
    banasuke_title_id
    ce_link_key_code
    update_reason
    genre_id
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTitleSubscriptionVariables,
  APITypes.OnDeleteTitleSubscription
>;
export const onCreateTitleCode = /* GraphQL */ `subscription OnCreateTitleCode($filter: ModelSubscriptionTitleCodeFilterInput) {
  onCreateTitleCode(filter: $filter) {
    id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    business_entity_id
    business_entity {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_ids
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    valid_flg
    update_reason
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTitleCodeSubscriptionVariables,
  APITypes.OnCreateTitleCodeSubscription
>;
export const onUpdateTitleCode = /* GraphQL */ `subscription OnUpdateTitleCode($filter: ModelSubscriptionTitleCodeFilterInput) {
  onUpdateTitleCode(filter: $filter) {
    id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    business_entity_id
    business_entity {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_ids
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    valid_flg
    update_reason
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTitleCodeSubscriptionVariables,
  APITypes.OnUpdateTitleCodeSubscription
>;
export const onDeleteTitleCode = /* GraphQL */ `subscription OnDeleteTitleCode($filter: ModelSubscriptionTitleCodeFilterInput) {
  onDeleteTitleCode(filter: $filter) {
    id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    business_entity_id
    business_entity {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_ids
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    valid_flg
    update_reason
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTitleCodeSubscriptionVariables,
  APITypes.OnDeleteTitleCodeSubscription
>;
export const onCreateProduct = /* GraphQL */ `subscription OnCreateProduct($filter: ModelSubscriptionProductFilterInput) {
  onCreateProduct(filter: $filter) {
    id
    seq_id
    name
    common_name
    title_id
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updated_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductSubscriptionVariables,
  APITypes.OnCreateProductSubscription
>;
export const onUpdateProduct = /* GraphQL */ `subscription OnUpdateProduct($filter: ModelSubscriptionProductFilterInput) {
  onUpdateProduct(filter: $filter) {
    id
    seq_id
    name
    common_name
    title_id
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updated_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductSubscriptionVariables,
  APITypes.OnUpdateProductSubscription
>;
export const onDeleteProduct = /* GraphQL */ `subscription OnDeleteProduct($filter: ModelSubscriptionProductFilterInput) {
  onDeleteProduct(filter: $filter) {
    id
    seq_id
    name
    common_name
    title_id
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updated_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductSubscriptionVariables,
  APITypes.OnDeleteProductSubscription
>;
export const onCreateProductTcd = /* GraphQL */ `subscription OnCreateProductTcd(
  $filter: ModelSubscriptionProductTcdFilterInput
) {
  onCreateProductTcd(filter: $filter) {
    id
    seq_id
    name
    common_name
    title_code_id
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    system_update_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductTcdSubscriptionVariables,
  APITypes.OnCreateProductTcdSubscription
>;
export const onUpdateProductTcd = /* GraphQL */ `subscription OnUpdateProductTcd(
  $filter: ModelSubscriptionProductTcdFilterInput
) {
  onUpdateProductTcd(filter: $filter) {
    id
    seq_id
    name
    common_name
    title_code_id
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    system_update_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductTcdSubscriptionVariables,
  APITypes.OnUpdateProductTcdSubscription
>;
export const onDeleteProductTcd = /* GraphQL */ `subscription OnDeleteProductTcd(
  $filter: ModelSubscriptionProductTcdFilterInput
) {
  onDeleteProductTcd(filter: $filter) {
    id
    seq_id
    name
    common_name
    title_code_id
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    system_update_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductTcdSubscriptionVariables,
  APITypes.OnDeleteProductTcdSubscription
>;
export const onCreateBnml = /* GraphQL */ `subscription OnCreateBnml($filter: ModelSubscriptionBnmlFilterInput) {
  onCreateBnml(filter: $filter) {
    id
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    tags {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBnmlSubscriptionVariables,
  APITypes.OnCreateBnmlSubscription
>;
export const onUpdateBnml = /* GraphQL */ `subscription OnUpdateBnml($filter: ModelSubscriptionBnmlFilterInput) {
  onUpdateBnml(filter: $filter) {
    id
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    tags {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBnmlSubscriptionVariables,
  APITypes.OnUpdateBnmlSubscription
>;
export const onDeleteBnml = /* GraphQL */ `subscription OnDeleteBnml($filter: ModelSubscriptionBnmlFilterInput) {
  onDeleteBnml(filter: $filter) {
    id
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    tags {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBnmlSubscriptionVariables,
  APITypes.OnDeleteBnmlSubscription
>;
export const onCreateBsp = /* GraphQL */ `subscription OnCreateBsp($filter: ModelSubscriptionBspFilterInput) {
  onCreateBsp(filter: $filter) {
    id
    seq_id
    character_name
    character_code
    character_series_name
    character_series_code
    tags {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBspSubscriptionVariables,
  APITypes.OnCreateBspSubscription
>;
export const onUpdateBsp = /* GraphQL */ `subscription OnUpdateBsp($filter: ModelSubscriptionBspFilterInput) {
  onUpdateBsp(filter: $filter) {
    id
    seq_id
    character_name
    character_code
    character_series_name
    character_series_code
    tags {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBspSubscriptionVariables,
  APITypes.OnUpdateBspSubscription
>;
export const onDeleteBsp = /* GraphQL */ `subscription OnDeleteBsp($filter: ModelSubscriptionBspFilterInput) {
  onDeleteBsp(filter: $filter) {
    id
    seq_id
    character_name
    character_code
    character_series_name
    character_series_code
    tags {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBspSubscriptionVariables,
  APITypes.OnDeleteBspSubscription
>;
export const onCreateBnam = /* GraphQL */ `subscription OnCreateBnam($filter: ModelSubscriptionBnamFilterInput) {
  onCreateBnam(filter: $filter) {
    id
    seq_id
    ip_name
    tags {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBnamSubscriptionVariables,
  APITypes.OnCreateBnamSubscription
>;
export const onUpdateBnam = /* GraphQL */ `subscription OnUpdateBnam($filter: ModelSubscriptionBnamFilterInput) {
  onUpdateBnam(filter: $filter) {
    id
    seq_id
    ip_name
    tags {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBnamSubscriptionVariables,
  APITypes.OnUpdateBnamSubscription
>;
export const onDeleteBnam = /* GraphQL */ `subscription OnDeleteBnam($filter: ModelSubscriptionBnamFilterInput) {
  onDeleteBnam(filter: $filter) {
    id
    seq_id
    ip_name
    tags {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBnamSubscriptionVariables,
  APITypes.OnDeleteBnamSubscription
>;
export const onCreateBnf = /* GraphQL */ `subscription OnCreateBnf($filter: ModelSubscriptionBnfFilterInput) {
  onCreateBnf(filter: $filter) {
    id
    seq_id
    tags {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBnfSubscriptionVariables,
  APITypes.OnCreateBnfSubscription
>;
export const onUpdateBnf = /* GraphQL */ `subscription OnUpdateBnf($filter: ModelSubscriptionBnfFilterInput) {
  onUpdateBnf(filter: $filter) {
    id
    seq_id
    tags {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBnfSubscriptionVariables,
  APITypes.OnUpdateBnfSubscription
>;
export const onDeleteBnf = /* GraphQL */ `subscription OnDeleteBnf($filter: ModelSubscriptionBnfFilterInput) {
  onDeleteBnf(filter: $filter) {
    id
    seq_id
    tags {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBnfSubscriptionVariables,
  APITypes.OnDeleteBnfSubscription
>;
export const onCreateCsvJob = /* GraphQL */ `subscription OnCreateCsvJob($filter: ModelSubscriptionCsvJobFilterInput) {
  onCreateCsvJob(filter: $filter) {
    id
    type
    name
    filepath
    status
    message
    table_name
    search_condition
    error_log
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCsvJobSubscriptionVariables,
  APITypes.OnCreateCsvJobSubscription
>;
export const onUpdateCsvJob = /* GraphQL */ `subscription OnUpdateCsvJob($filter: ModelSubscriptionCsvJobFilterInput) {
  onUpdateCsvJob(filter: $filter) {
    id
    type
    name
    filepath
    status
    message
    table_name
    search_condition
    error_log
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCsvJobSubscriptionVariables,
  APITypes.OnUpdateCsvJobSubscription
>;
export const onDeleteCsvJob = /* GraphQL */ `subscription OnDeleteCsvJob($filter: ModelSubscriptionCsvJobFilterInput) {
  onDeleteCsvJob(filter: $filter) {
    id
    type
    name
    filepath
    status
    message
    table_name
    search_condition
    error_log
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCsvJobSubscriptionVariables,
  APITypes.OnDeleteCsvJobSubscription
>;
export const onCreateDataLakeJob = /* GraphQL */ `subscription OnCreateDataLakeJob(
  $filter: ModelSubscriptionDataLakeJobFilterInput
) {
  onCreateDataLakeJob(filter: $filter) {
    id
    operation_type
    status
    message
    error_log
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDataLakeJobSubscriptionVariables,
  APITypes.OnCreateDataLakeJobSubscription
>;
export const onUpdateDataLakeJob = /* GraphQL */ `subscription OnUpdateDataLakeJob(
  $filter: ModelSubscriptionDataLakeJobFilterInput
) {
  onUpdateDataLakeJob(filter: $filter) {
    id
    operation_type
    status
    message
    error_log
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDataLakeJobSubscriptionVariables,
  APITypes.OnUpdateDataLakeJobSubscription
>;
export const onDeleteDataLakeJob = /* GraphQL */ `subscription OnDeleteDataLakeJob(
  $filter: ModelSubscriptionDataLakeJobFilterInput
) {
  onDeleteDataLakeJob(filter: $filter) {
    id
    operation_type
    status
    message
    error_log
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDataLakeJobSubscriptionVariables,
  APITypes.OnDeleteDataLakeJobSubscription
>;
export const onCreateDataLakeJobStatus = /* GraphQL */ `subscription OnCreateDataLakeJobStatus(
  $filter: ModelSubscriptionDataLakeJobStatusFilterInput
) {
  onCreateDataLakeJobStatus(filter: $filter) {
    id
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDataLakeJobStatusSubscriptionVariables,
  APITypes.OnCreateDataLakeJobStatusSubscription
>;
export const onUpdateDataLakeJobStatus = /* GraphQL */ `subscription OnUpdateDataLakeJobStatus(
  $filter: ModelSubscriptionDataLakeJobStatusFilterInput
) {
  onUpdateDataLakeJobStatus(filter: $filter) {
    id
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDataLakeJobStatusSubscriptionVariables,
  APITypes.OnUpdateDataLakeJobStatusSubscription
>;
export const onDeleteDataLakeJobStatus = /* GraphQL */ `subscription OnDeleteDataLakeJobStatus(
  $filter: ModelSubscriptionDataLakeJobStatusFilterInput
) {
  onDeleteDataLakeJobStatus(filter: $filter) {
    id
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDataLakeJobStatusSubscriptionVariables,
  APITypes.OnDeleteDataLakeJobStatusSubscription
>;
export const onCreateGroupCompany = /* GraphQL */ `subscription OnCreateGroupCompany(
  $filter: ModelSubscriptionGroupCompanyFilterInput
) {
  onCreateGroupCompany(filter: $filter) {
    id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_2
    management_business_1
    management_business_2
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    deleted_at
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGroupCompanySubscriptionVariables,
  APITypes.OnCreateGroupCompanySubscription
>;
export const onUpdateGroupCompany = /* GraphQL */ `subscription OnUpdateGroupCompany(
  $filter: ModelSubscriptionGroupCompanyFilterInput
) {
  onUpdateGroupCompany(filter: $filter) {
    id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_2
    management_business_1
    management_business_2
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    deleted_at
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGroupCompanySubscriptionVariables,
  APITypes.OnUpdateGroupCompanySubscription
>;
export const onDeleteGroupCompany = /* GraphQL */ `subscription OnDeleteGroupCompany(
  $filter: ModelSubscriptionGroupCompanyFilterInput
) {
  onDeleteGroupCompany(filter: $filter) {
    id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_2
    management_business_1
    management_business_2
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    deleted_at
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGroupCompanySubscriptionVariables,
  APITypes.OnDeleteGroupCompanySubscription
>;
export const onCreateBneCustomerGroupCompany = /* GraphQL */ `subscription OnCreateBneCustomerGroupCompany(
  $filter: ModelSubscriptionBneCustomerGroupCompanyFilterInput
) {
  onCreateBneCustomerGroupCompany(filter: $filter) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBneCustomerGroupCompanySubscriptionVariables,
  APITypes.OnCreateBneCustomerGroupCompanySubscription
>;
export const onUpdateBneCustomerGroupCompany = /* GraphQL */ `subscription OnUpdateBneCustomerGroupCompany(
  $filter: ModelSubscriptionBneCustomerGroupCompanyFilterInput
) {
  onUpdateBneCustomerGroupCompany(filter: $filter) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBneCustomerGroupCompanySubscriptionVariables,
  APITypes.OnUpdateBneCustomerGroupCompanySubscription
>;
export const onDeleteBneCustomerGroupCompany = /* GraphQL */ `subscription OnDeleteBneCustomerGroupCompany(
  $filter: ModelSubscriptionBneCustomerGroupCompanyFilterInput
) {
  onDeleteBneCustomerGroupCompany(filter: $filter) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBneCustomerGroupCompanySubscriptionVariables,
  APITypes.OnDeleteBneCustomerGroupCompanySubscription
>;
export const onCreateBcCustomerGroupCompany = /* GraphQL */ `subscription OnCreateBcCustomerGroupCompany(
  $filter: ModelSubscriptionBcCustomerGroupCompanyFilterInput
) {
  onCreateBcCustomerGroupCompany(filter: $filter) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBcCustomerGroupCompanySubscriptionVariables,
  APITypes.OnCreateBcCustomerGroupCompanySubscription
>;
export const onUpdateBcCustomerGroupCompany = /* GraphQL */ `subscription OnUpdateBcCustomerGroupCompany(
  $filter: ModelSubscriptionBcCustomerGroupCompanyFilterInput
) {
  onUpdateBcCustomerGroupCompany(filter: $filter) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBcCustomerGroupCompanySubscriptionVariables,
  APITypes.OnUpdateBcCustomerGroupCompanySubscription
>;
export const onDeleteBcCustomerGroupCompany = /* GraphQL */ `subscription OnDeleteBcCustomerGroupCompany(
  $filter: ModelSubscriptionBcCustomerGroupCompanyFilterInput
) {
  onDeleteBcCustomerGroupCompany(filter: $filter) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBcCustomerGroupCompanySubscriptionVariables,
  APITypes.OnDeleteBcCustomerGroupCompanySubscription
>;
export const onCreateBnfCustomerGroupCompany = /* GraphQL */ `subscription OnCreateBnfCustomerGroupCompany(
  $filter: ModelSubscriptionBnfCustomerGroupCompanyFilterInput
) {
  onCreateBnfCustomerGroupCompany(filter: $filter) {
    id
    customer_code
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBnfCustomerGroupCompanySubscriptionVariables,
  APITypes.OnCreateBnfCustomerGroupCompanySubscription
>;
export const onUpdateBnfCustomerGroupCompany = /* GraphQL */ `subscription OnUpdateBnfCustomerGroupCompany(
  $filter: ModelSubscriptionBnfCustomerGroupCompanyFilterInput
) {
  onUpdateBnfCustomerGroupCompany(filter: $filter) {
    id
    customer_code
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBnfCustomerGroupCompanySubscriptionVariables,
  APITypes.OnUpdateBnfCustomerGroupCompanySubscription
>;
export const onDeleteBnfCustomerGroupCompany = /* GraphQL */ `subscription OnDeleteBnfCustomerGroupCompany(
  $filter: ModelSubscriptionBnfCustomerGroupCompanyFilterInput
) {
  onDeleteBnfCustomerGroupCompany(filter: $filter) {
    id
    customer_code
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBnfCustomerGroupCompanySubscriptionVariables,
  APITypes.OnDeleteBnfCustomerGroupCompanySubscription
>;
export const onCreateBspCustomerGroupCompany = /* GraphQL */ `subscription OnCreateBspCustomerGroupCompany(
  $filter: ModelSubscriptionBspCustomerGroupCompanyFilterInput
) {
  onCreateBspCustomerGroupCompany(filter: $filter) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBspCustomerGroupCompanySubscriptionVariables,
  APITypes.OnCreateBspCustomerGroupCompanySubscription
>;
export const onUpdateBspCustomerGroupCompany = /* GraphQL */ `subscription OnUpdateBspCustomerGroupCompany(
  $filter: ModelSubscriptionBspCustomerGroupCompanyFilterInput
) {
  onUpdateBspCustomerGroupCompany(filter: $filter) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBspCustomerGroupCompanySubscriptionVariables,
  APITypes.OnUpdateBspCustomerGroupCompanySubscription
>;
export const onDeleteBspCustomerGroupCompany = /* GraphQL */ `subscription OnDeleteBspCustomerGroupCompany(
  $filter: ModelSubscriptionBspCustomerGroupCompanyFilterInput
) {
  onDeleteBspCustomerGroupCompany(filter: $filter) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBspCustomerGroupCompanySubscriptionVariables,
  APITypes.OnDeleteBspCustomerGroupCompanySubscription
>;
export const onCreateBnmlCustomerGroupCompany = /* GraphQL */ `subscription OnCreateBnmlCustomerGroupCompany(
  $filter: ModelSubscriptionBnmlCustomerGroupCompanyFilterInput
) {
  onCreateBnmlCustomerGroupCompany(filter: $filter) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBnmlCustomerGroupCompanySubscriptionVariables,
  APITypes.OnCreateBnmlCustomerGroupCompanySubscription
>;
export const onUpdateBnmlCustomerGroupCompany = /* GraphQL */ `subscription OnUpdateBnmlCustomerGroupCompany(
  $filter: ModelSubscriptionBnmlCustomerGroupCompanyFilterInput
) {
  onUpdateBnmlCustomerGroupCompany(filter: $filter) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBnmlCustomerGroupCompanySubscriptionVariables,
  APITypes.OnUpdateBnmlCustomerGroupCompanySubscription
>;
export const onDeleteBnmlCustomerGroupCompany = /* GraphQL */ `subscription OnDeleteBnmlCustomerGroupCompany(
  $filter: ModelSubscriptionBnmlCustomerGroupCompanyFilterInput
) {
  onDeleteBnmlCustomerGroupCompany(filter: $filter) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBnmlCustomerGroupCompanySubscriptionVariables,
  APITypes.OnDeleteBnmlCustomerGroupCompanySubscription
>;
