import { useState } from 'react';
import { generateSearchFilter } from '../utils/search';
import usePageSize from './usePageSize';
import { dataToFilterDataLakeJobObj } from '../utils/fetchData';
import { SEARCH_DATALAKEJOB_FIELDS_DEFAULT_VALUES } from '../consts/dataLakeJob';
import { DataLakeJobSearch } from '../types/form/dataLakeJob';
import { DataLakeJob } from '../models';
import { searchDataLakeJobs } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = { direction: 'desc', field: 'updatedAt' };

export const useSearchDataLakeJobs = () => {
  const [datalakeJobs, setDatalakeJobs] = useState<DataLakeJob[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<DataLakeJobSearch>(SEARCH_DATALAKEJOB_FIELDS_DEFAULT_VALUES);
  const [searchFilter, setSearchFilter] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >();

  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();

  const fetchDatalakeJobs = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: DataLakeJob;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const records = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          console.log([key, value], [key, value], [key, value]);
          if (value === 'ALL' && key === 'delete_flg') return;
          if (value === 'ALL' && key === 'admin_check_flg') return;
          if (value === 'ALL' && key === 'system_update_flg') return;
          return {
            name: dataToFilterDataLakeJobObj[key].name,
            value: value,
            type: dataToFilterDataLakeJobObj[key].type,
          };
        })
        .filter(Boolean);
      setSearchFilter(records);
      const filter = generateSearchFilter(records);
      const models: any = await API.graphql({
        query: searchDataLakeJobs,
        variables: {
          ...filter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });
      console.log(models);

      setTotal(models.data.searchDataLakeJobs.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchDataLakeJobs.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchDataLakeJobs.nextToken])));
      }

      setDatalakeJobs([...models.data.searchDataLakeJobs.items]);
      newCondition && setCondition({ ...newCondition });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchDatalakeJobs({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchDatalakeJobs({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchDatalakeJobs({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchDatalakeJobs({ nextToken });
  };

  return {
    fetchDatalakeJobs,
    loading,
    total,
    pageSize,
    onChangePageSize,
    datalakeJobs,
    setDatalakeJobs,
    onChangePage,
    condition,
    page,
    onChangeSort,
    searchFilter,
  };
};
