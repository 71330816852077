import React from 'react'
import { Step, StepLabel, Stepper } from "@mui/material";

interface StepBarProps {
  steps: string[];
  activeStep: number
  completed: boolean
  error: boolean
}

function StepBar(props: StepBarProps) {
  const { steps, activeStep, completed, error } = props

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, idx) => (
        <Step key={label} completed={completed}>
          <StepLabel error={error && activeStep === idx}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default StepBar