export function is_localhost() {
  const hostname = document.location.hostname;
  return ['localhost', '127.0.0.1'].includes(hostname) || hostname.includes('.app.github.dev');
}
export function is_maintenance_available() {
  const hostname = document.location.hostname;
  return (
    !['localhost', '127.0.0.1'].includes(document.location.hostname) &&
    !hostname.includes('dkdgl50yxcuj2.amplifyapp.com')
  );
}
