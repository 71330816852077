import React, { useCallback, ReactNode, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import PrimaryButton from '../components/PrimaryButton';

const StyledTitle = styled('div')(({ theme }) => ({
  background: theme.colors.defaultHeaderBg,
  height: 44,
  lineHeight: 2.7,
  padding: '0 20px',
}));

type Props = {
  open: boolean;
  title: string;
  doText?: string;
  cancelText: string;
  onCancel: Function;
  onSubmit?: Function | false;
  onConfirm?: Function;
  children: ReactNode;
  height: number | string;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isRestore?: boolean;
  isFullScreen?: boolean;
  disabledSubmit?: boolean;
};

function FormDialog(props: Props) {
  const {
    open,
    title,
    doText,
    cancelText,
    onSubmit,
    onCancel,
    onConfirm,
    children,
    height,
    width,
    isRestore = false,
    isFullScreen = false,
  } = props;

  const onUpdating = useRef(false); // 連打防止用フラグ

  const doSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (onSubmit) {
      // 連打回避
      if (onUpdating.current) return;
      onUpdating.current = true;
      onSubmit();
      if (onUpdating.current) {
        setTimeout(() => {
          onUpdating.current = false;
        }, 2000);
      }
    }
  };

  const doCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const doConfirm = useCallback(() => {
    onConfirm && onConfirm();
  }, [onConfirm]);

  return (
    <Dialog open={open} maxWidth={width} fullScreen={isFullScreen}>
      <StyledTitle>
        {title}
        {onConfirm && (
          <IconButton
            onClick={doConfirm}
            sx={{
              position: 'absolute',
              right: 52,
              top: 3,
            }}
          >
            {isRestore ? <RestoreFromTrashIcon /> : <DeleteIcon />}
          </IconButton>
        )}
        <IconButton
          onClick={doCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 3,
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledTitle>
      <DialogContent
        sx={{
          height: height,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        <PrimaryButton variant="text" onClick={doCancel}>
          {cancelText}
        </PrimaryButton>
        {onSubmit && (
          <PrimaryButton variant="text" onClick={(e) => doSubmit(e)} disabled={props.disabledSubmit ?? false}>
            {doText}
          </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default FormDialog;
