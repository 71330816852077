import React from 'react';
import { TextField, Box, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

import { theme } from '../../assets/theme/options';
import { validationRules } from '../../types';
import createValidationRules from '../../hooks/createValidationRules';
import RequiredLabel from './RequiredLabel';
import { I18n } from 'aws-amplify/utils';
interface FormTextFieldProps {
  /** ラベル */
  label: string;
  /** placeholder */
  placeholder?: string;
  /** インプット内容 */
  value?: string | number;
  /** テキストエリアかどうか */
  isTextArea?: boolean;
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** ReactFookForm */
  control: any;
  errors: any;
  /** バリデーションの内容 */
  validationRules: validationRules[] | string[];
  /** 必須ラベルの有無 */
  required?: boolean;
  handleChange?: Function;
  /** 編集済み色 */
  isEdited?: boolean;
}

function FormTextField(props: FormTextFieldProps) {
  return (
    <Box position={'relative'}>
      {props.required && <RequiredLabel />}
      <InputLabel sx={{ fontSize: '12px', marginBottom: '2px' }}>{I18n.get(props.label)}</InputLabel>
      <Controller
        name={props.field}
        control={props.control}
        defaultValue={props.value || ''}
        rules={createValidationRules(props.validationRules)}
        render={({ field }) => (
          <TextField
            {...field}
            placeholder={props.placeholder}
            error={!!props.errors[props.field]}
            fullWidth
            size="small"
            multiline
            disabled={props.disabled}
            sx={{
              '& .MuiOutlinedInput-root.Mui-disabled': {
                background: props.disabled && props.isEdited ? theme.colors.editedCell : theme.colors.disabled,
              },
            }}
            minRows={props.isTextArea ? 3 : undefined}
            helperText={props.errors[props.field]?.message || null}
            onChange={(e) => {
              field.onChange(e);
              props.handleChange && props.handleChange(e);
            }}
          />
        )}
      />
    </Box>
  );
}

export default FormTextField;
