import React, { useCallback, useState, useEffect, useContext } from 'react';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { theme } from '../assets/theme/options';
import { format, parseISO } from 'date-fns';
import DetailBspHistoryDialog from '../components/bspHistory/DetailBspHistoryDialog';
import SearchBspHistoryDialog from '../components/bspHistory/SearchBspHistoryDialog';
import PrimaryButton from '../components/PrimaryButton';
import { BspHistory } from '../models';
import { convertOperationType } from '../utils/converter';
import { AuthContext } from '../contexts/AuthContext';
import { useEditedColumnClass } from '../hooks/useEditedColumnClass';
import { useSearchBspHistories } from '../hooks/useSearchBspHistories';

const columns: GridColDef[] = [
  {
    field: 'operation_type', headerName: '操作種別',
    width: 210,
    valueFormatter: (params: any) => {
      return convertOperationType[params.value];
    },
  },
  {
    field: 'seq_id', headerName: 'BSPマスターID',
    width: 210
  },
  {
    field: 'character_name',
    headerName: 'キャラクター名',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
  },
  {
    field: 'character_code', headerName: 'キャラクターコード',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
  },
  {
    field: 'character_series_name', headerName: 'キャラクターシリーズ名称',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
  },
  {
    field: 'character_series_code',
    headerName: 'キャラクターシリーズコード',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
  },
  {
    field: 'system_update_flg',
    headerName: '自動更新フラグ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function BspHistoryList() {
  const [row, setRow] = useState<BspHistory | null>(null);
  const [openDetail, setOpenDetail] = useState(false)
  const [openSearch, setOpenSearch] = useState(false);
  const { user, roles } = useContext(AuthContext);
  const roleTypes = roles?.map((role) => role.role?.label);

  const { fetchBspHistories, loading, total, pageSize, onChangePageSize, bspHistories, setBspHistories, onChangePage, condition, setCondition, page, onChangeSort } = useSearchBspHistories()

  useEffect(() => {
    fetchBspHistories({})
    // eslint-disable-next-line
  }, []);


  const doOpenSearch = useCallback(() => {
    setOpenSearch(true)
  }, [])

  const doOpenDetail = (params: any) => {
    setRow(params.row);
    setOpenDetail(true);
  };


  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} textAlign={'right'}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={bspHistories}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onRowClick={doOpenDetail}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .edited_cell': {
              background: theme.colors.editedCell
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openSearch && (
        <SearchBspHistoryDialog
          condition={condition}
          isAdmin={roleTypes?.includes('ADMIN')}
          open={openSearch}
          setOpen={setOpenSearch}
          fetchBspHistories={fetchBspHistories}
        />
      )}
      {openDetail && row && user && (
        <DetailBspHistoryDialog
          open={openDetail}
          row={row}
          setOpen={setOpenDetail}
          setRow={setRow}
          user={user}
        />
      )}
    </Box>
  );
}

export default BspHistoryList;
