import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Backdrop, Button } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, createSearchParams } from 'react-router-dom';
import { NotificationContext } from '../../contexts/NotificationContext';

import PrimaryButton from '../../components/PrimaryButton';
import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormArrayTextField from '../forms/FormArrayTextField';
import NewProductDialog from '../products/NewProductDialog';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { BANASUKE_SEQ_ID } from '../../consts/common';
import {
  EDIT_TITLE_PRODUCT_FIELDS,
  EDIT_TITLE_ADMIN_FIELDS,
  VALIDATE_DUPLICATE_TITLE_FIELDS,
} from '../../consts/title';
import { titleFields } from '../../types';
import { classificationValidateTitle } from '../../hooks/classification';
import { getTitle, searchProducts, getProduct } from '../../graphql/queries';
import { updateTitle, updateProduct, deleteTagTitle } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { duplicateDetection } from '../../utils/duplicateDetection';
import FormSelectChip from '../forms/FormSelectChip';
import { fetchTitles, sleep } from '../../utils/fetchData';
import { TitleSearch } from '../../types/form/title';

import type {
  Title,
  DataSource,
  Product,
  User,
  Platform,
  ProductClass,
  ProductType,
  Currency,
  Genre,
} from '../../models';
import type { TagTitle } from '../../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  currencies: Currency[];
  dataSources: DataSource[];
  genres: Genre[];
  isAdmin: boolean;
  open: boolean;
  platforms: Platform[];
  productClasses: ProductClass[];
  productTypes: ProductType[];
  row: Title;
  setOpen: Function;
  setRow: Function;
  user: User;
  condition: TitleSearch;
  setCondition: Function;
  onChangeFilter: Function;
  setExpQueries: Function;
};

type Record = {
  seq_id?: string;
  name_ja?: string;
  name_en?: string;
  search_name?: string;
  data_sorce_id?: string;
  project_cd?: [];
  genre_id?: [];
  sap_title_code?: [];
  bn_connect_title_id?: [];
  banasuke_title_id?: string;
  ce_link_key_code?: string;
  update_reason?: string;
  admin_check_flg?: boolean;
  updatedAt?: string;
};

type SelectGenre = {
  id: string;
  name: string;
};

function EditTitleDialog(props: Props) {
  const {
    currencies,
    dataSources,
    genres,
    isAdmin,
    open,
    platforms,
    productClasses,
    productTypes,
    row,
    setOpen,
    setRow,
    user,
    condition,
    setCondition,
    onChangeFilter,
    setExpQueries,
  } = props;
  const [confirm, setConfirm] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const [tagTitle, setTagTitle] = useState<TagTitle | null>(null);
  const [openPrd, setOpenPrd] = useState(false);
  const [selectGenres, setSelectGenres] = useState<SelectGenre[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  const fields = isAdmin ? EDIT_TITLE_ADMIN_FIELDS : EDIT_TITLE_PRODUCT_FIELDS;
  const { loading, checkAndRestore } = useRestore();

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  useEffect(() => {
    if (!open) return;
    if (row.project_cd && row.project_cd?.length > 0) {
      const formated = row.project_cd.map((val: string | null) => {
        return { value: val };
      });
      setValue('project_cd', formated);
    } else {
      setValue('project_cd', [{ value: '' }]);
    }
    if (row.sap_title_code && row.sap_title_code?.length > 0) {
      const formated = row.sap_title_code.map((val: string | null) => {
        return { value: val };
      });
      setValue('sap_title_code', formated);
    } else {
      setValue('sap_title_code', [{ value: '' }]);
    }
    if (row.bn_connect_title_id && row.bn_connect_title_id?.length > 0) {
      const formated = row.bn_connect_title_id.map((val: string | null) => {
        return { value: val };
      });
      setValue('bn_connect_title_id', formated);
    } else {
      setValue('bn_connect_title_id', [{ value: '' }]);
    }
  }, [open, row, setValue]);

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<any> = async (data) => {
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('TITLE', VALIDATE_DUPLICATE_TITLE_FIELDS, data, clearErrors, setError, row.id);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
  };

  // 確認編集画面の「登録」の処理
  const onSubmit = () => {
    setCanSubmit(true);
  };

  const onUpdate = async () => {
    try {
      const model: any = await API.graphql({ query: getTitle, variables: { id: row.id } });
      const title = model.data.getTitle;
      const filteredRecord = removeUnUseRecord(record);
      const formData = {
        ...filteredRecord,
        id: row.id,
        name_ja: record.name_ja,
        name_en: record.name_en,
        search_name: record.search_name,
        genre_id: record.genre_id && record.genre_id?.length > 0 ? record.genre_id.map((value: any) => value.id) : [],
        project_cd:
          record.project_cd && record.project_cd?.length > 0
            ? record.project_cd.map((value: any) => value.value)
            : null,
        sap_title_code:
          record.sap_title_code && record.sap_title_code?.length > 0
            ? record.sap_title_code.map((value: any) => value.value)
            : null,
        bn_connect_title_id:
          record.bn_connect_title_id && record.bn_connect_title_id.length > 0
            ? record.bn_connect_title_id.map((value: any) => value.value)
            : null,
        update_reason: record.update_reason,
        banasuke_title_id: record.banasuke_title_id,
        ce_link_key_code: record.ce_link_key_code,
        admin_check_flg: record.admin_check_flg,
        _version: title._version,
        updated_user_id: user.id,
        system_update_flg: false,
      };
      await API.graphql({ query: updateTitle, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'タイトルの編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTitles(condition, setCondition, onChangeFilter, setExpQueries);
      reset();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'タイトルの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const removeUnUseRecord = (record: Record) => {
    const { seq_id, updatedAt, ...res } = record;
    return res;
  };

  console.log({ tagTitle });

  const onDelete: SubmitHandler<Record> = async (data) => {
    if (products.length > 0) {
      await Promise.all(products.map((product) => deleteProduct(product)));
    }
    if (tagTitle) await __deleteTagTitle(tagTitle);
    await deleteTitle(data);
    setConfirm(false);
    onClose();
  };

  // 関連するプロダクトを削除
  const deleteProduct = async (product: Product) => {
    try {
      const model: any = await API.graphql({ query: getProduct, variables: { id: product.id } });
      const __product = model.data.getProduct;
      const formData = {
        id: __product.id,
        _version: __product._version,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateProduct, variables: { input: formData } });
    } catch (e) {
      console.log(e);
    }
  };

  // 関連するタグタイトルを削除
  const __deleteTagTitle = async (tagTitle: TagTitle) => {
    try {
      const formData = { id: tagTitle.id, _version: tagTitle._version };
      await API.graphql({ query: deleteTagTitle, variables: { input: formData } });
    } catch (e) {
      console.log(e);
    }
  };

  // 対象のタイトルを削除
  const deleteTitle = async (data: Record) => {
    try {
      const model: any = await API.graphql({ query: getTitle, variables: { id: row.id } });
      const title = model.data.getTitle;
      const formData = {
        id: row.id,
        update_reason: data.update_reason,
        _version: title._version,
        delete_flg: true,
        system_update_flg: false,
        deleted_at: new Date().toISOString(),
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateTitle, variables: { input: formData } });
      onClose();
      addNotification({
        type: 'success',
        message: 'タイトルの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTitles(condition, setCondition, onChangeFilter, setExpQueries);
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'タイトルの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const checkRelation = async () => {
    // プロダクトとの紐付け確認
    const models: any = await API.graphql({
      query: searchProducts,
      variables: {
        filter: { title_id: { eq: row.id }, _deleted: { ne: true } },
      },
    });
    setProducts(models.data.searchProducts.items);
    const isPrdRel = models.data.searchProducts.items.length > 0;

    // タグタイトルとのリレーション確認
    const isTagRel = (row.tags as any).items.some((item: any) => {
      if (!item._deleted) setTagTitle(item);
      return !item._deleted;
    });

    classifyMsg(isPrdRel, isTagRel);
    setConfirm(true);
  };

  const classifyMsg = (isPrdRel: boolean, isTagRel: boolean) => {
    if (!isPrdRel && !isTagRel) {
      setAlertMsg(`このタイトルを削除しても${'\n'}よろしいでしょうか？`);
    }
    if (isPrdRel && !isTagRel) {
      setAlertMsg(`このタイトルは紐づいている${'\n'}プロダクトがありますが${'\n'}削除してもよろしいでしょうか？`);
    }
    if (!isPrdRel && isTagRel) {
      setAlertMsg(`このタイトルはリレーションで${'\n'}タグ付けされてますが${'\n'}削除してもよろしいでしょうか？`);
    }
    if (isPrdRel && isTagRel) {
      setAlertMsg(
        `このタイトルは紐づいているプロダクトがあり${'\n'}リレーションでタグ付けされてますが${'\n'}削除してもよろしいでしょうか？`
      );
    }
  };

  const onConfirmCancel = () => {
    setConfirm(false);
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const onPrdOpen = useCallback(() => {
    setOpenPrd(true);
  }, []);

  const restoreConfirm = () => {
    setAlertMsg(`このタイトルを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('title', row);
      onClose();
      addNotification({
        type: 'success',
        message: 'タイトルの復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTitles(condition, setCondition, onChangeFilter, setExpQueries);
      onClose();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'タイトルの復元に失敗しました',
      });
      console.log(e);
    }
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'genre':
        return genres;
      case 'dataSource':
        return dataSources;
      default:
        return [];
    }
  };

  const productPageQueryParams: string = createSearchParams({
    titleId: row.id,
  }).toString();

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="タイトルの編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!row.delete_flg && handleSubmit(onPreSubmit)}
      onConfirm={row.delete_flg ? restoreConfirm : checkRelation}
      height={600}
      width="lg"
      isRestore={row.delete_flg}
    >
      <Box display={'flex'}>
        {[1, 2, 3].map((num) => {
          return (
            <Box
              sx={{
                '& > :not(style)': { my: 1, width: 335 },
                display: 'flex',
                flexFlow: 'column wrap',
                height: 'inherit',
              }}
              width={380}
              key={num}
            >
              {fields.map((__row, idx) => {
                return (
                  __row.column == num && (
                    <React.Fragment key={idx}>
                      {(__row.type === 'text' || __row.type === 'textarea') && (
                        <FormTextField
                          label={__row.label}
                          placeholder={__row.placeholder}
                          field={__row.field}
                          control={control}
                          disabled={
                            row.delete_flg || __row.typeIds?.includes(row.data_source?.seq_id || '') || __row.disabled
                          }
                          errors={errors}
                          validationRules={classificationValidateTitle(__row.field as titleFields)}
                          value={
                            row[
                              __row.field as keyof Pick<
                                Title,
                                | 'seq_id'
                                | 'name_ja'
                                | 'name_en'
                                | 'search_name'
                                | 'banasuke_title_id'
                                | 'ce_link_key_code'
                                | 'updated_user_id'
                                | 'update_reason'
                              >
                            ]
                          }
                          required={row.delete_flg ? false : __row.required}
                          isTextArea={__row.type === 'textarea'}
                        />
                      )}
                      {__row.type === 'datetime' && (
                        <FormDateTimePicker
                          label={__row.label}
                          defaultValue={row[__row.field as keyof Pick<Title, 'updatedAt'>]}
                          type={__row.type}
                          disabled={row.delete_flg || __row.disabled}
                          field={__row.field}
                          control={control}
                        />
                      )}
                      {__row.type === 'select' && __row.optionsName && (
                        <FormSelectBox
                          label={__row.label}
                          content={options(__row.optionsName)}
                          disabled={row.delete_flg || __row.disabled}
                          defaultValue={row[__row.field as keyof Pick<Title, 'data_source_id'>]}
                          field={__row.field}
                          control={control}
                          placeholder={__row.placeholder}
                        />
                      )}
                      {__row.type === 'checkbox' && (
                        <FormCheckBox
                          field={__row.field}
                          label={__row.label}
                          control={control}
                          defaultValue={row[__row.field as keyof Pick<Title, 'admin_check_flg'>] || false}
                          disabled={row.delete_flg}
                        />
                      )}
                      {__row.type === 'arrayText' && (
                        <FormArrayTextField
                          label={__row.label}
                          placeholder={__row.placeholder}
                          field={__row.field}
                          control={control}
                          disabled={
                            row.delete_flg || __row.typeIds?.includes(row.data_source?.seq_id || '') || __row.disabled
                          }
                          errors={errors}
                          validationRules={classificationValidateTitle(__row.field as titleFields)}
                          required={row.delete_flg ? false : __row.required}
                          notShowButton={row.delete_flg}
                        />
                      )}
                      {__row.type === 'chip' && (
                        <FormSelectChip
                          label={__row.label}
                          content={options(__row.optionsName)}
                          field={__row.field}
                          control={control}
                          disabled={
                            row.delete_flg || __row.typeIds?.includes(row.data_source?.seq_id || '') || __row.disabled
                          }
                          placeholder={__row.placeholder}
                          setValue={setValue}
                          defaultValue={row.genre_id}
                          genres={selectGenres}
                          setGenres={setSelectGenres}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{ position: 'absolute', bottom: '10px', left: '25px', display: 'flex', justifyContent: 'space-around' }}
        width={row.data_source?.seq_id === BANASUKE_SEQ_ID ? 220 : 440}
      >
        {!row.delete_flg && (
          // 右クリック時に別タブ表示の選択肢を表示させるためにLinkを使用。
          // material-uiのButtonを使用しつつreact-router-domのstateを指定するためにcomponentにLinkを指定。
          <Button
            variant="contained"
            component={Link}
            to={`/product/products?${productPageQueryParams}`}
            state={{ titleId: row.id, titleName: row.name_ja }}
          >
            プロダクト一覧を表示
          </Button>
        )}
        {!row.delete_flg && row.data_source?.seq_id !== BANASUKE_SEQ_ID && (
          <PrimaryButton width="auto" onClick={onPrdOpen}>
            プロダクトの新規作成
          </PrimaryButton>
        )}
      </Box>
      {confirm && (
        <ConfirmDialog
          message={alertMsg}
          open={confirm}
          onCancel={onConfirmCancel}
          onContinue={row.delete_flg ? onRestore : handleSubmit(onDelete)}
          updateReason={
            row.delete_flg ? (
              <></>
            ) : (
              <Box px={3} mb={1}>
                <FormTextField
                  label="更新理由"
                  placeholder="更新理由を入力"
                  value={row.update_reason?.toString() || ''}
                  field="update_reason"
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateTitle('update_reason')}
                />
              </Box>
            )
          }
        />
      )}
      {editConfirm && (
        <EditConfirmDialog
          fields={fields}
          onClose={onEditConfirmClose}
          onSubmit={onSubmit}
          open={editConfirm}
          record={record}
          schemaName="タイトル"
          genres={genres}
          dataSources={dataSources}
          beforeChangeValueData={row}
        />
      )}
      {row && openPrd && user && (
        <NewProductDialog
          currencies={currencies}
          platforms={platforms}
          productClasses={productClasses}
          productTypes={productTypes}
          datasources={dataSources}
          open={openPrd}
          setOpen={setOpenPrd}
          title={row}
          user={user}
        />
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormDialog>
  );
}

export default EditTitleDialog;
