export const EDIT_TAG_BNML_FIELDS = [
  {
    field: "bnml_seq_id",
    label: "BNMLマスターID",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "mlics_plan_cd_4char",
    label: "MLICS企画コード(4桁)",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "mlics_plan_cd_7char",
    label: "MLICS企画コード(7桁)",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "mlics_commodity_name",
    label: "MLICS商品名",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "tag",
    label: "IPタグ（日本語）",
    type: "autoComplete",
    optionsName: "name_ja",
    placeholder: "IPタグ（日本語）を入力",
    required: true,
  },
  {
    field: "tag",
    label: "IPタグ（英語）",
    type: "autoComplete",
    optionsName: "name_en",
    placeholder: "IPタグ（英語）を入力",
    required: true,
  },
  {
    field: "updated_user_id",
    label: "タグ付け最終更新者",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "タグ付け最終更新日時",
    type: "datetime",
    placeholder: "",
    disabled: true,
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
];

export const SEARCH_TAG_BNML_FIELDS = [
  {
    field: "bnml_seq_id",
    label: "BNMLマスターID",
    type: "text",
    placeholder: "BNMLマスターIDを入力",
  },
  {
    field: "mlics_plan_cd_4char",
    label: "MLICS企画コード(4桁)",
    type: "text",
    placeholder: "MLICS企画コード(4桁)を入力",
  },
  {
    field: "mlics_plan_cd_7char",
    label: "MLICS企画コード(7桁)",
    type: "text",
    placeholder: "MLICS企画コード(7桁)を入力",
  },
  {
    field: "mlics_commodity_name",
    label: "MLICS商品名",
    type: "text",
    placeholder: "MLICS商品名を入力",
  },
  {
    field: "tags_ja",
    label: "IPタグ（日本語）",
    type: "text",
    placeholder: "IPタグ（日本語）を入力",
  },
  {
    field: "tags_en",
    label: "IPタグ（英語）",
    type: "text",
    placeholder: "IPタグ（英語）を入力",
  },
  { field: "is_unregistered", label: "未登録を検索", type: "checkbox" },
  {
    field: "updatedAt",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "BNMLマスター最終更新日" },
      { field: "to", label: "BNMLマスター最終更新日" },
    ],
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
];

export const SEARCH_TAG_BNML_FIELDS_DEFAULT_VALUES = {
  bnml_seq_id: "",
  mlics_plan_cd_4char: null,
  mlics_plan_cd_7char: null,
  mlics_commodity_name: "",
  tags_ja: "",
  tags_en: "",
  is_unregistered: false,
  from: null,
  to: null,
  update_reason: "",
};
