import { useState } from 'react';

import usePageSize from './usePageSize';

import { TitleSearch } from '../types/form';
import { SEARCH_TITLE_FIELDS_DEFAULT_VALUES } from '../consts/title';
import { searchTitles } from '../graphql/queries';

import useModifyTitles from './useModifyTitles';
import { Genre, Title } from '../models';
import { generateClient } from 'aws-amplify/api';
import { SearchableTitleSortInput } from '../API';
const API = generateClient();

interface StringKeyObj {
  [key: string]: string;
}

export const useSearchTitles = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<{}>({ delete_flg: { ne: true }, admin_check_flg: { eq: true } });
  const [sort, setSort] = useState<{}>({ direction: 'desc', field: 'updatedAt' });
  const { pageSize, setPageSize } = usePageSize();
  const [genres, setGenres] = useState<Genre[]>([]);

  const searchTitls = async ({
    nextToken,
    newLimit,
    newFilter,
    newSort
  }: {
    nextToken?: string | null;
    newLimit?: number;
    newFilter?: {};
    newSort?: {};
  }) => {
    setLoading(true);
    try {
      const models: any = await API.graphql({
        query: searchTitles,
        variables: {
          filter: newFilter ?? filter,
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });

      setTotal(models.data.searchTitles.total);
      if (newLimit || newFilter || newSort) {
        setNextTokens([models.data.searchTitles.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchTitles.nextToken])));
      }
      const modifiedModelItems = useModifyTitles(models.data.searchTitles.items, genres);
      setRows(modifiedModelItems);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    searchTitls({ newLimit });
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    searchTitls({ nextToken });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      searchTitls({ newSort: { direction: 'desc', field: 'updatedAt' } });
    } else {
      if (sortModel[0].field === 'tags') {
        return;
      }
      const newSort = { direction: sortModel[0].sort, field: field2searchField[sortModel[0].field] };
      setSort({ ...newSort });
      searchTitls({ newSort });
    }
  };

  const onChangeFilter = (newFilter: {}) => {
    setFilter(newFilter);
    searchTitls({ newFilter });
  };

  const field2searchField: StringKeyObj = {
    seq_id: 'seq_id',
    name_ja: 'name_ja',
    tags: 'tag_id',
    name_en: 'name_en',
    search_name: 'search_name',
    genre_name: 'genre_id',
    project_cd: 'project_cd',
    sap_title_code: 'sap_title_code',
    update_reason: 'update_reason',
    data_source_name: 'data_source_id',
    bn_connect_title_id: 'bn_connect_title_id',
    banasuke_title_id: 'banasuke_title_id',
    ce_link_key_code: 'ce_link_key_code',
    updated_by: 'updated_user_id',
    updatedAt: 'updatedAt'
  };

  return {
    searchTitls,
    onChangePageSize,
    onChangePage,
    onChangeSort,
    onChangeFilter,
    setRows,
    setGenres,
    loading,
    total,
    pageSize,
    rows,
    page,
    genres
  };
};
