/** *************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 ************************************************************************* */

/* eslint-disable */
import React, { useState, useEffect, MouseEvent, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { I18n } from 'aws-amplify/utils';
import { signOut } from 'aws-amplify/auth';

import { LanguageContext } from '../../contexts/LanguageContext';
import BreadCrumbs from './BreadCrumbs';
import { AuthContext } from '../../contexts/AuthContext';
import {
  REGEXP_DEVELOP_HOST,
  REGEXP_FEATURE_HOST,
  REGEXP_MAIN_HOST,
  REGEXP_STG_HOST,
  REGEXP_SKRIT_HOST,
} from '../../consts/common';

export default function Header() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [username, setUsername] = useState('');
  const { user } = useContext(AuthContext);
  const { language, changeLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (!user) return;
    setUsername(user.name);
  }, [user]);

  const handleMenu = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await signOut();
    location.replace('/');
  };

  const handleLanguage = () => {};

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          background: (theme) => theme.colors.defaultHeaderBg,
          ...(REGEXP_DEVELOP_HOST.test(location.host) && {
            background: (theme) => theme.colors.devHeaderBg,
          }),
          ...(REGEXP_MAIN_HOST.test(location.host) && {
            background: (theme) => theme.colors.mainHeaderBg,
          }),
          ...(REGEXP_STG_HOST.test(location.host) && {
            background: (theme) => theme.colors.stgHeaderBg,
          }),
          ...(REGEXP_SKRIT_HOST.test(location.host) && {
            background: (theme) => theme.colors.skritHeaderBg,
          }),
          ...(REGEXP_FEATURE_HOST.test(location.host) && {
            background: (theme) => theme.colors.featureHeaderBg,
          }),
        }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, color: (theme) => theme.palette.text.secondary }}>
            <BreadCrumbs />
          </Typography>
          <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>{username}</Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* [FIXME] 詳細が分からないので仮置 */}
              <MenuItem onClick={handleLogout}>{I18n.get('Sign Out')}</MenuItem>
            </Menu>
            {
              <>
                {/* <Button
                  variant="text"
                  onClick={() => {
                    changeLanguage('ja');
                  }}
                >
                  ja
                </Button>
                /
                <Button
                  variant="text"
                  onClick={() => {
                    changeLanguage('en');
                  }}
                >
                  en
                </Button> */}
              </>
            }
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
