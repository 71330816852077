import { Bnf } from '../models';
import { BnfSearch } from '../types/form/bnf';

type BnfField = Array<{
  field: keyof Bnf;
  label: string;
  type:
    | 'text'
    | 'select'
    | 'date'
    | 'datetime'
    | 'autoComplete'
    | 'checkbox'
    | 'textarea';
  placeholder?: string;
  optionsName?: string;
  requiredDataSources?: string[];
  disabledDataSources?: string[];
  required?: boolean;
  disabled?: boolean;
  inputs?: Array<{ field: 'from' | 'to'; label: string }>;
  isBlank?: boolean;
}>;

export const CREATE_BNF_FIELDS: BnfField = [
  {
    field: 'bnf_data_source',
    optionsName: 'bnf_data_source',
    label: 'BNFデータソース',
    type: 'select',
    placeholder: 'BNFデータソースを入力',
    required: false,
    isBlank: false,
  },
  {
    field: 'obic7_commodity_cd',
    label: 'OBIC7商品コード',
    type: 'text',
    placeholder: 'OBIC7商品コードを選択',
    required: false,
  },
  {
    field: 'obic7_commodity_name',
    label: 'OBIC7商品名',
    type: 'text',
    placeholder: 'OBIC7商品名を入力',
    required: false,
  },
  {
    field: 'bvics_plan_cd_4char',
    label: 'BVICS企画コード(4桁)',
    type: 'text',
    placeholder: 'BVICS企画コード(4桁)を入力',
    required: false,
  },
  {
    field: 'bvics_plan_cd_7char',
    label: 'BVICS企画コード(7桁)',
    type: 'text',
    placeholder: 'BVICS企画コード(7桁)を入力',
    required: false,
  },
  {
    field: 'bvics_product_name',
    label: 'BVICS商品名',
    type: 'text',
    placeholder: 'BVICS商品名を入力',
    required: false,
  },
  {
    field: 'update_reason',
    label: '更新理由',
    type: 'text',
    placeholder: '更新理由を入力',
    required: false,
  },
];

export const EDIT_BNF_FIELDS: BnfField = [
  {
    field: 'seq_id',
    label: 'BNFマスターID',
    type: 'text',
    placeholder: 'BNFマスターIDを入力',
    required: false,
    disabled: true,
  },
  {
    field: 'bnf_data_source',
    optionsName: 'bnf_data_source',
    label: 'BNFデータソース',
    type: 'select',
    placeholder: 'BNFデータソースを入力',
    required: false,
    isBlank: false,
  },
  {
    field: 'obic7_commodity_cd',
    label: 'OBIC7商品コード',
    type: 'text',
    placeholder: 'OBIC7商品コードを入力',
    required: false,
  },
  {
    field: 'obic7_commodity_name',
    label: 'OBIC7商品名',
    type: 'text',
    placeholder: 'OBIC7商品名を入力',
    required: false,
  },
  {
    field: 'bvics_plan_cd_4char',
    label: 'BVICS企画コード(4桁)',
    type: 'text',
    placeholder: 'BVICS企画コード(4桁)を入力',
    required: false,
  },
  {
    field: 'bvics_plan_cd_7char',
    label: 'BVICS企画コード(7桁)',
    type: 'text',
    placeholder: 'BVICS企画コード(7桁)を入力',
    required: false,
  },
  {
    field: 'bvics_product_name',
    label: 'BVICS商品名',
    type: 'text',
    placeholder: 'BVICS商品名を入力',
    required: false,
  },
  {
    field: 'updated_user_id',
    label: '最終更新者',
    type: 'text',
    placeholder: '最終更新者を入力',
    required: false,
    disabled: true,
  },
  {
    field: 'updatedAt',
    label: '最終更新日時',
    type: 'datetime',
    required: false,
    disabled: true,
  },
  {
    field: 'update_reason',
    label: '更新理由',
    type: 'text',
    placeholder: '更新理由を入力',
    required: false,
  },
  {
    field: 'admin_check_flg',
    label: '管理者チェック済みフラグ',
    type: 'checkbox',
    requiredDataSources: [],
    disabledDataSources: [],
    required: false,
  },
];

export const SEARCH_BNF_FIELDS: BnfField = [
  {
    field: 'seq_id',
    label: 'BSPマスターID',
    type: 'text',
    placeholder: 'BSPマスターIDを入力',
  },
  {
    field: 'bnf_data_source',
    label: 'BNFデータソース',
    type: 'select',
    optionsName: 'bnf_data_source',
    placeholder: 'BNFデータソースを入力',
    isBlank: false,
  },
  {
    field: 'obic7_commodity_cd',
    label: 'OBIC7商品コード',
    type: 'text',
    placeholder: 'OBIC7商品コードを入力',
  },
  {
    field: 'obic7_commodity_name',
    label: 'OBIC7商品名',
    type: 'text',
    placeholder: 'OBIC7商品名を入力',
  },
  {
    field: 'bvics_plan_cd_4char',
    label: 'BVICS企画コード(4桁)',
    type: 'text',
    placeholder: 'BVICS企画コード(4桁)を入力',
  },
  {
    field: 'bvics_plan_cd_7char',
    label: 'BVICS企画コード(7桁)',
    type: 'text',
    placeholder: 'BVICS企画コード(7桁)を入力',
  },
  {
    field: 'bvics_plan_cd_7char',
    label: 'BVICS商品名',
    type: 'text',
    placeholder: 'BVICS商品名を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: '最終更新日時' },
      { field: 'to', label: '最終更新日時' },
    ],
  },
  {
    field: 'update_reason',
    label: '更新理由',
    type: 'text',
    placeholder: '更新理由を入力',
    required: false,
    disabled: false,
  },
  {
    field: 'admin_check_flg',
    label: '管理者チェックフラグ',
    type: 'select',
    optionsName: 'admin_check_flg',
    isBlank: false,
  },
];

export const SEARCH_BNF_ADMIN_FIELDS: BnfField = [
  {
    field: 'seq_id',
    label: 'BNFマスターID',
    type: 'text',
    placeholder: 'BNFマスターIDを入力',
  },
  {
    field: 'bnf_data_source',
    label: 'BNFデータソース',
    type: 'select',
    optionsName: 'bnf_data_source',
    placeholder: 'BNFデータソースを入力',
    isBlank: false,
  },
  {
    field: 'obic7_commodity_cd',
    label: 'OBIC7商品コード',
    type: 'text',
    placeholder: 'OBIC7商品コードを入力',
  },
  {
    field: 'obic7_commodity_name',
    label: 'OBIC7商品名',
    type: 'text',
    placeholder: 'OBIC7商品名を入力',
  },
  {
    field: 'bvics_plan_cd_4char',
    label: 'BVICS企画コード(4桁)',
    type: 'text',
    placeholder: 'BVICS企画コード(4桁)を入力',
  },
  {
    field: 'bvics_plan_cd_7char',
    label: 'BVICS企画コード(7桁)',
    type: 'text',
    placeholder: 'BVICS企画コード(7桁)を入力',
  },
  {
    field: 'bvics_product_name',
    label: 'BVICS商品名',
    type: 'text',
    placeholder: 'BVICS商品名を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: '最終更新日時' },
      { field: 'to', label: '最終更新日時' },
    ],
  },
  {
    field: 'update_reason',
    label: '更新理由',
    type: 'text',
    placeholder: '更新理由を入力',
    required: false,
    disabled: false,
  },
  {
    field: 'delete_flg',
    label: '削除済データ',
    type: 'select',
    optionsName: 'delete_flg',
    isBlank: false,
  },
  {
    field: 'admin_check_flg',
    label: '管理者チェックフラグ',
    type: 'select',
    optionsName: 'admin_check_flg',
    isBlank: false,
  },
];

export const SEARCH_BNF_FIELDS_DEFAULT_VALUES: BnfSearch = {
  seq_id: '',
  bnf_data_source: '',
  obic7_commodity_cd: '',
  obic7_commodity_name: '',
  bvics_plan_cd_4char: '',
  bvics_plan_cd_7char: '',
  bvics_product_name: '',
  updatedAt_from: null,
  updatedAt_to: null,
  update_reason: '',
  admin_check_flg: true,
  delete_flg: false,
};

export const VALIDATE_DUPLICATE_BNF_FIELDS: string[] = [
  'obic7_commodity_cd',
  'bvics_plan_cd_7char',
];
