import { useState } from 'react';
import { searchGroupCompanyHistories } from '../graphql/queries';
import { GroupCompanyHistorySearch } from '../types/form';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import usePageSize from './usePageSize';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

import { SEARCH_GROUP_COMPANY_HISTORY_FIELDS_DEFAULT_VALUES } from '../consts/groupCompanyHistory';
import { GroupCompanyHistory } from '../models';

const defaultSort = { direction: 'desc', field: 'updatedAt' };

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

export const dataToFilterObj: {
  [key in keyof GroupCompanyHistorySearch]: {
    name: keyof GroupCompanyHistory;
    type: string;
  };
} = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.EQ },
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  group_company_code: {
    name: 'group_company_code',
    type: SEARCH_TYPE.WILDCARD,
  },
  valid_start_date_from: { name: 'valid_start_date', type: SEARCH_TYPE.GTE },
  valid_start_date_to: { name: 'valid_start_date', type: SEARCH_TYPE.LTE },
  valid_end_date_from: { name: 'valid_end_date', type: SEARCH_TYPE.GTE },
  valid_end_date_to: { name: 'valid_end_date', type: SEARCH_TYPE.LTE },
  stravis_code: { name: 'stravis_code', type: SEARCH_TYPE.WILDCARD },
  company_name_ja: { name: 'company_name_ja', type: SEARCH_TYPE.WILDCARD },
  company_name_en: { name: 'company_name_en', type: SEARCH_TYPE.WILDCARD },
  abbreviation: { name: 'abbreviation', type: SEARCH_TYPE.WILDCARD },
  company_attribute: { name: 'company_attribute', type: SEARCH_TYPE.WILDCARD },
  consolidation: { name: 'consolidation', type: SEARCH_TYPE.WILDCARD },
  equity_method_application: {
    name: 'equity_method_application',
    type: SEARCH_TYPE.WILDCARD,
  },
  unit_class_1: { name: 'unit_class_1', type: SEARCH_TYPE.WILDCARD },
  unit_class_2: { name: 'unit_class_2', type: SEARCH_TYPE.WILDCARD },
  management_business_1: {
    name: 'management_business_1',
    type: SEARCH_TYPE.WILDCARD,
  },
  management_business_2: {
    name: 'management_business_2',
    type: SEARCH_TYPE.WILDCARD,
  },
  country_id: { name: 'country_id', type: SEARCH_TYPE.EQ },
  accounting_period: { name: 'accounting_period', type: SEARCH_TYPE.EQ },
  public_or_private: { name: 'public_or_private', type: SEARCH_TYPE.EQ },
  group_agreement_flg: { name: 'group_agreement_flg', type: SEARCH_TYPE.EQ },
  management_contract_flg: {
    name: 'management_contract_flg',
    type: SEARCH_TYPE.EQ,
  },
  subcontracting_contract_flg: {
    name: 'subcontracting_contract_flg',
    type: SEARCH_TYPE.EQ,
  },
  brand_portfolio: { name: 'brand_portfolio', type: SEARCH_TYPE.WILDCARD },
  holding_company_1: {
    name: 'holding_company_1',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_1: { name: 'investment_ratio_1', type: SEARCH_TYPE.EQ },
  holding_company_2: {
    name: 'holding_company_2',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_2: { name: 'investment_ratio_2', type: SEARCH_TYPE.EQ },
  holding_company_3: {
    name: 'holding_company_3',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_3: { name: 'investment_ratio_3', type: SEARCH_TYPE.EQ },
  holding_company_4: {
    name: 'holding_company_4',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_4: { name: 'investment_ratio_4', type: SEARCH_TYPE.EQ },
  holding_company_5: {
    name: 'holding_company_5',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_5: { name: 'investment_ratio_5', type: SEARCH_TYPE.EQ },
  total_investment_ratio: {
    name: 'total_investment_ratio',
    type: SEARCH_TYPE.EQ,
  },
  issued_shares_total: { name: 'issued_shares_total', type: SEARCH_TYPE.EQ },
  treasury_stock: { name: 'treasury_stock', type: SEARCH_TYPE.EQ },
  nominee_shares: { name: 'nominee_shares', type: SEARCH_TYPE.EQ },
  capital: { name: 'capital', type: SEARCH_TYPE.EQ },
  currency_id: { name: 'currency_id', type: SEARCH_TYPE.EQ },
  number_of_employees: { name: 'number_of_employees', type: SEARCH_TYPE.EQ },
  establishment_date_from: {
    name: 'establishment_date',
    type: SEARCH_TYPE.GTE,
  },
  establishment_date_to: { name: 'establishment_date', type: SEARCH_TYPE.LTE },
  representative_director_1_position: {
    name: 'representative_director_1_position',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_1_name: {
    name: 'representative_director_1_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_2_position: {
    name: 'representative_director_2_position',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_2_name: {
    name: 'representative_director_2_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_3_position: {
    name: 'representative_director_3_position',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_3_name: {
    name: 'representative_director_3_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  business_description: {
    name: 'business_description',
    type: SEARCH_TYPE.WILDCARD,
  },
  address_1: { name: 'address_1', type: SEARCH_TYPE.WILDCARD },
  address_2: { name: 'address_2', type: SEARCH_TYPE.WILDCARD },
  notes: { name: 'notes', type: SEARCH_TYPE.WILDCARD },
  ip_address: { name: 'ip_address', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

export const useSearchGroupCompanyHistories = () => {
  const [groupCompanyHistories, setGroupCompanyHistories] = useState<GroupCompanyHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<GroupCompanyHistorySearch>(
    SEARCH_GROUP_COMPANY_HISTORY_FIELDS_DEFAULT_VALUES
  );
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();

  const fetchGroupCompanyHistories = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: GroupCompanyHistorySearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const queries = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          if (value === 'ALL' && key === 'delete_flg') return;
          if (value === 'ALL' && key === 'admin_check_flg') return;
          if (value === 'ALL' && key === 'system_update_flg') return;
          return {
            name: dataToFilterObj[key].name,
            value: value,
            type: dataToFilterObj[key].type,
          };
        })
        .filter(Boolean);

      const filter = generateSearchFilter(queries);

      const models: any = await API.graphql({
        query: searchGroupCompanyHistories,
        variables: {
          ...filter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });

      setTotal(models.data.searchGroupCompanyHistories.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchGroupCompanyHistories.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchGroupCompanyHistories.nextToken])));
      }

      setGroupCompanyHistories([...models.data.searchGroupCompanyHistories.items]);
      newCondition && setCondition({ ...newCondition });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchGroupCompanyHistories({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchGroupCompanyHistories({ newSort: defaultSort });
    } else {
      const newSort = {
        direction: sortModel[0].sort,
        field: sortModel[0].field,
      };
      setSort({ ...newSort });
      fetchGroupCompanyHistories({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchGroupCompanyHistories({ nextToken });
  };

  return {
    fetchGroupCompanyHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    groupCompanyHistories,
    setGroupCompanyHistories,
    onChangePage,
    condition,
    setCondition,
    page,
    onChangeSort,
  };
};
