import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextField from '../forms/FormTextField';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import { SEARCH_CURRENCY_FIELDS, SEARCH_CURRENCY_FIELDS_DEFAULT_VALUES } from '../../consts/currency';
import { classificationValidateCurrency } from '../../hooks/classification';
import { currencyFields } from '../../types';
import { CurrencySearch } from '../../types/form/currency';

type Props = {
  condition: CurrencySearch;
  open: boolean;
  setOpen: Function;
  fetchCurrencies: Function;
}

function SearchCurrencyDialog(props: Props) {
  const { condition, open, setOpen, fetchCurrencies } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_CURRENCY_FIELDS_DEFAULT_VALUES });

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: Array<any>) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<CurrencySearch> = async (newCondition) => {
    try {
      await fetchCurrencies({ newCondition })
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onReset = () => {
    reset();
  };

  return (
    <SearchDialog
      open={open}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
      width="lg"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 360 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: '380px',
        }}
        width='750px'
      >
        {SEARCH_CURRENCY_FIELDS.map((row, idx) => (
          <Box key={idx} mb={1}>
            {(row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={row.inputs[0].field}
                  toLabel={row.inputs[0].label}
                  toField={row.inputs[1].field}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateCurrency(row.field as currencyFields)}
              />
            )}
          </Box>
        ))}
      </Box>
    </SearchDialog >
  );
}

export default SearchCurrencyDialog;