import React, { useCallback, useContext, useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/material';
import { theme } from '../assets/theme/options';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import EditTagBnfDialog from '../components/tagBnf/EditTagBnfDialog';
import SearchTagBnfDialog from '../components/tagBnf/SearchTagBnfDialog';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { searchBnfs } from '../graphql/queries';
import { onCreateBnf, onUpdateBnf } from '../graphql/subscriptions';
import { AuthContext } from '../contexts/AuthContext';
import { SEARCH_TAG_BNF_FIELDS_DEFAULT_VALUES } from '../consts/tagBnf';
import { INITIAL_EXP_ONLY_DEL_QUERIES_STATE } from '../consts/export';
import { TagBnfSearch } from '../types/form/tagBnf';
import usePageSize from '../hooks/usePageSize';
import { searchTagsMinimumRelations } from '../graphql/custom-queries';
import { onCreateTagWithoutRelations } from '../graphql/custom-subscriptions';

import type { Bnf } from '../models';
import type { SearchableBnfSortInput, Tag, TagBnf } from '../API';

import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const columns: GridColDef[] = [
  { field: 'seq_id', headerName: 'BNFマスターID', width: 250 },
  {
    field: 'bnf_data_source',
    headerName: 'BNFデータソース',
    width: 260
  },
  {
    field: 'obic7_commodity_cd',
    headerName: 'OBIC7商品コード',
    width: 260
  },
  {
    field: 'obic7_commodity_name',
    headerName: 'OBIC7商品名',
    width: 260
  },
  {
    field: 'bvics_plan_cd_4char',
    headerName: 'BVICS企画コード(4桁)',
    width: 260
  },
  {
    field: 'bvics_plan_cd_7char',
    headerName: 'BVICS企画コード(7桁)',
    width: 260
  },
  {
    field: 'bvics_product_name',
    headerName: 'BVICS製品名',
    width: 260
  },
  {
    field: 'tags_ja',
    headerName: 'IPタグ（日本語）',
    width: 250,
    valueGetter: ({ row }) => {
      const tagBnf = row.tags.items.find((tagBnf: TagBnf) => !tagBnf._deleted);
      return tagBnf?.tag?.name_ja;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <Box sx={{ bgcolor: grey[800], height: 30, lineHeight: '30px', px: 1, color: 'white' }}>未登録</Box>;
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (!v1) return 1;
      if (!v2) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'tags_en',
    headerName: 'IPタグ（英語）',
    width: 250,
    valueGetter: ({ row }) => {
      const tagBnf = row.tags.items.find((tagBnf: TagBnf) => !tagBnf._deleted);
      return tagBnf?.tag?.name_en;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <Box sx={{ bgcolor: grey[800], height: 30, lineHeight: '30px', px: 1, color: 'white' }}>未登録</Box>;
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (!v1) return 1;
      if (!v2) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'updatedAt',
    headerName: 'BNFマスター最終更新日時',
    width: 250,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

function TagBnfList() {
  const [bnfs, setBnfs] = useState<Bnf[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [bnf, setBnf] = useState<Bnf | null>(null);
  const [tagBnf, setTagBnf] = useState<TagBnf | null>(null);
  const [addedTag, setAddedTag] = useState<Tag | null>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<TagBnfSearch>(SEARCH_TAG_BNF_FIELDS_DEFAULT_VALUES);
  const [expQueries, setExpQueries] = useState(INITIAL_EXP_ONLY_DEL_QUERIES_STATE);
  const [isUploaded, setIsUploaded] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchBnf();
    fetchTags();
    subscribeOnCreateBnf();
    subscribeOnUpdateBnf();
    subscribeOnCreateTag();
  }, []);

  useEffect(() => {
    if (!isUploaded) return;
    fetchBnf();
    setIsUploaded(false);
  }, [isUploaded]);

  const fetchBnf = async () => {
    try {
      const models: any = await API.graphql({
        query: searchBnfs,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 500
        }
      });
      setBnfs(models.data.searchBnfs.items);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTags = async () => {
    try {
      const models: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 500
        }
      });
      setTags(models.data.searchTags.items);
    } catch (e) {
      console.log(e);
    }
  };

  const subscribeOnCreateBnf = () => {
    const client = API.graphql({ query: onCreateBnf });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const bnf = data.onCreateBnf;
          setBnfs((prev) => [bnf, ...prev]);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnUpdateBnf = () => {
    const client = API.graphql({ query: onUpdateBnf });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const bnf = data.onUpdateBnf;
          if (!bnf.delete_flg) {
            // 更新
            setBnfs((bnfs) => bnfs.map((__bnf) => (__bnf.id === bnf.id ? bnf : __bnf)));
          } else {
            // 削除
            setBnfs((bnfs) => bnfs.filter((__bnf) => __bnf.id !== bnf.id));
          }
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnCreateTag = () => {
    const client = API.graphql({ query: onCreateTagWithoutRelations });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const tag = data.onCreateTag;
          setTags((tags) => [tag, ...tags]);
          setAddedTag(tag);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const doOpenEdit = (params: any) => {
    setBnf(params.row);
    const __tagBnf = params.row.tags.items.find((tagBnf: any) => !tagBnf._deleted);
    __tagBnf && setTagBnf(__tagBnf);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const onChangePageSize = (num: number) => {
    setPageSize(num);
  };

  const onCloseEdit = () => {
    setTagBnf(null);
    setBnf(null);
    setAddedTag(null);
    setOpenEdit(false);
  };

  console.log({ bnfs });
  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
          <ExportButton condition={expQueries} tableName="TAG_BNF" type="SEARCH" />
          <ImportButton tableName="TAGBNF" setIsUploaded={setIsUploaded} />
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          rows={bnfs}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onPageSizeChange={(num) => onChangePageSize(num)}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            }
          }}
        />
      </div>
      {bnf && openEdit && user && (
        <EditTagBnfDialog
          addedTag={addedTag}
          onClose={onCloseEdit}
          open={openEdit}
          tags={tags}
          tagBnf={tagBnf}
          bnf={bnf}
          user={user}
          setBnfs={setBnfs}
          condition={condition}
          setCondition={setCondition}
          setExpQueries={setExpQueries}
        />
      )}
      {openSearch && (
        <SearchTagBnfDialog
          condition={condition}
          open={openSearch}
          setCondition={setCondition}
          setExpQueries={setExpQueries}
          setOpen={setOpenSearch}
          setBnfs={setBnfs}
        />
      )}
    </Box>
  );
}

export default TagBnfList;
