import React, { useCallback, useContext, useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/material';
import { theme } from '../assets/theme/options';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import EditTagBspDialog from '../components/tagBsp/EditTagBspDialog';
import SearchTagBspDialog from '../components/tagBsp/SearchTagBspDialog';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { searchBsps } from '../graphql/queries';
import { onCreateBsp, onUpdateBsp } from '../graphql/subscriptions';
import { AuthContext } from '../contexts/AuthContext';
import { SEARCH_TAG_BSP_FIELDS_DEFAULT_VALUES } from '../consts/tagBsp';
import { INITIAL_EXP_ONLY_DEL_QUERIES_STATE } from '../consts/export';
import { TagBspSearch } from '../types/form/tagBsp';
import usePageSize from '../hooks/usePageSize';
import { onCreateTagWithoutRelations } from '../graphql/custom-subscriptions';
import { searchTagsMinimumRelations } from '../graphql/custom-queries';

import type { Bsp } from '../models';
import type { TagBsp, Tag, SearchableBspSortInput } from '../API';

import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const columns: GridColDef[] = [
  { field: 'seq_id', headerName: 'BSPマスターID', width: 250 },
  { field: 'character_name', headerName: 'キャラクター名', width: 250 },
  {
    field: 'tags_ja',
    headerName: 'IPタグ（日本語）',
    width: 250,
    valueGetter: ({ row }) => {
      const tagBsp = row.tags.items.find((tagBsp: TagBsp) => !tagBsp._deleted);
      return tagBsp?.tag?.name_ja;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <Box sx={{ bgcolor: grey[800], height: 30, lineHeight: '30px', px: 1, color: 'white' }}>未登録</Box>;
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (!v1) return 1;
      if (!v2) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'tags_en',
    headerName: 'IPタグ（英語）',
    width: 250,
    valueGetter: ({ row }) => {
      const tagBsp = row.tags.items.find((tagBsp: TagBsp) => !tagBsp._deleted);
      return tagBsp?.tag?.name_en;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <Box sx={{ bgcolor: grey[800], height: 30, lineHeight: '30px', px: 1, color: 'white' }}>未登録</Box>;
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (!v1) return 1;
      if (!v2) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'updatedAt',
    headerName: 'BSPマスター最終更新日時',
    width: 250,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

function TagBspList() {
  const [bsps, setBsps] = useState<Bsp[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [bsp, setBsp] = useState<Bsp | null>(null);
  const [tagBsp, setTagBsp] = useState<TagBsp | null>(null);
  const [addedTag, setAddedTag] = useState<Tag | null>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<TagBspSearch>(SEARCH_TAG_BSP_FIELDS_DEFAULT_VALUES);
  const [expQueries, setExpQueries] = useState(INITIAL_EXP_ONLY_DEL_QUERIES_STATE);
  const [isUploaded, setIsUploaded] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchBsps();
    fetchTags();
    subscribeOnCreateBsp();
    subscribeOnUpdateBsp();
    subscribeOnCreateTag();
  }, []);

  useEffect(() => {
    if (!isUploaded) return;
    fetchBsps();
    setIsUploaded(false);
  }, [isUploaded]);

  const fetchBsps = async () => {
    try {
      const models: any = await API.graphql({
        query: searchBsps,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 500
        }
      });
      setBsps(models.data.searchBsps.items);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTags = async () => {
    try {
      const models: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 500
        }
      });
      setTags(models.data.searchTags.items);
    } catch (e) {
      console.log(e);
    }
  };

  const subscribeOnCreateBsp = () => {
    const client = API.graphql({ query: onCreateBsp });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const bsp = data.onCreateBsp;
          setBsps((prev) => [bsp, ...prev]);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnUpdateBsp = () => {
    const client = API.graphql({ query: onUpdateBsp });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const bsp = data.onUpdateBsp;
          if (!bsp.delete_flg) {
            // 更新
            setBsps((bsps) => bsps.map((__bsp) => (__bsp.id === bsp.id ? bsp : __bsp)));
          } else {
            // 削除
            setBsps((bsps) => bsps.filter((__bsp) => __bsp.id !== bsp.id));
          }
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnCreateTag = () => {
    const client = API.graphql({ query: onCreateTagWithoutRelations });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const tag = data.onCreateTag;
          setTags((tags) => [tag, ...tags]);
          setAddedTag(tag);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const doOpenEdit = (params: any) => {
    setBsp(params.row);
    const __tagBsp = params.row.tags.items.find((tagBsp: any) => !tagBsp._deleted);
    __tagBsp && setTagBsp(__tagBsp);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const onChangePageSize = (num: number) => {
    setPageSize(num);
  };

  const onCloseEdit = () => {
    setTagBsp(null);
    setBsp(null);
    setAddedTag(null);
    setOpenEdit(false);
  };

  console.log({ bsps });
  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
          <ExportButton condition={expQueries} tableName="TAG_BSP" type="SEARCH" />
          <ImportButton tableName="TAGBSP" setIsUploaded={setIsUploaded} />
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          rows={bsps}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onPageSizeChange={(num) => onChangePageSize(num)}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            }
          }}
        />
      </div>
      {bsp && openEdit && user && (
        <EditTagBspDialog
          addedTag={addedTag}
          onClose={onCloseEdit}
          open={openEdit}
          tags={tags}
          tagBsp={tagBsp}
          bsp={bsp}
          user={user}
          setBsps={setBsps}
          condition={condition}
          setCondition={setCondition}
          setExpQueries={setExpQueries}
        />
      )}
      {openSearch && (
        <SearchTagBspDialog
          condition={condition}
          open={openSearch}
          setCondition={setCondition}
          setExpQueries={setExpQueries}
          setOpen={setOpenSearch}
          setBsps={setBsps}
        />
      )}
    </Box>
  );
}

export default TagBspList;
