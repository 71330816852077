import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { searchBnams } from '../graphql/queries';

import PrimaryButton from '../components/PrimaryButton';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { AuthContext } from '../contexts/AuthContext';
import { grey } from '@mui/material/colors';
import { onCreateBnam, onUpdateBnam } from '../graphql/subscriptions';
import usePageSize from '../hooks/usePageSize';
import { INITIAL_EXP_ONLY_DEL_QUERIES_STATE } from '../consts/export';
import EditTagBnamDialog from '../components/tagBnams/EditTagBnamDialog';
import SearchTagBnamDialog from '../components/tagBnams/SearchTagBnamDialog';
import { TagBnamSearch } from '../types/form/tagBnamSearch';
import { SEARCH_TAG_BNAM_FIELDS_DEFAULT_VALUES } from '../consts/tagBnam';
import { onCreateTagWithoutRelations } from '../graphql/custom-subscriptions';
import { searchTagsMinimumRelations } from '../graphql/custom-queries';

import type { Tag, TagBnam, Bnam, SearchableBnamSortInput, SearchableTagSortInput } from '../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const columns: GridColDef[] = [
  {
    field: 'seq_id',
    headerName: 'BNAMマスターID',
    width: 190
  },
  {
    field: 'ip_name',
    headerName: 'IP名称',
    width: 210
  },
  {
    field: 'tags_ja',
    headerName: 'IPタグ（日本語）',
    width: 210,
    valueGetter: ({ row }) => {
      const tagBnam = row.tags.items.find((tagBnam: TagBnam) => !tagBnam._deleted);
      return tagBnam?.tag?.name_ja;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return (
          <Box
            sx={{
              bgcolor: grey[800],
              height: 30,
              lineHeight: '30px',
              px: 1,
              color: 'white'
            }}
          >
            未登録
          </Box>
        );
      }
      return value;
    }
  },
  {
    field: 'tag_en',
    headerName: 'IPタグ（英語）',
    width: 210,
    valueGetter: ({ row }) => {
      const tagBnam = row.tags.items.find((tagBnam: TagBnam) => !tagBnam._deleted);
      return tagBnam?.tag?.name_en;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return (
          <Box
            sx={{
              bgcolor: grey[800],
              height: 30,
              lineHeight: '30px',
              px: 1,
              color: 'white'
            }}
          >
            未登録
          </Box>
        );
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (v1 === undefined) return 1;
      if (v2 === undefined) return -1;
      if (v1 === '') return 1;
      if (v2 === '') return -1;
      if (v1 === null) return 1;
      if (v2 === null) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'updatedAt',
    headerName: 'BNAMマスター最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

function TagBnamList() {
  const [Bnams, setBnams] = useState<Bnam[]>([]);
  const [bnam, setBnam] = useState<Bnam | null>(null);
  const [total, setTotal] = useState(0);
  const { pageSize, setPageSize } = usePageSize();
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<TagBnamSearch>(SEARCH_TAG_BNAM_FIELDS_DEFAULT_VALUES);
  const [loading, setLoading] = useState<boolean>(false);

  // 検索関連
  const [openSearch, setOpenSearch] = useState(false);

  // 編集関連
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useContext(AuthContext);
  const [tagBnam, setTagBnam] = useState<TagBnam | null>(null);
  const [tags, setTags] = useState<Tag[]>([]);
  const [addedTag, setAddedTag] = useState<Tag | null>(null);

  // インポート/エクスポートボタン関連
  const [bnamIds, setBnamIds] = useState<string[] | undefined>(undefined);
  const [isUploaded, setIsUploaded] = useState(false);
  const [expQueries, setExpQueries] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >(INITIAL_EXP_ONLY_DEL_QUERIES_STATE);

  const fetchBnams = useCallback(
    async ({ nextToken, newLimit }: { nextToken?: string | null; newLimit?: number }) => {
      try {
        setLoading(true);
        const models: any = await API.graphql({
          query: searchBnams,
          variables: {
            filter: { delete_flg: { ne: true } },
            //@ts-ignore
            sort: { direction: 'desc', field: 'seq_id' },
            limit: pageSize,
            nextToken: nextToken ?? null
          }
        });
        setTotal(models.data.searchBnams.total);
        if (newLimit) {
          setNextTokens([models.data.searchBnams.nextToken]);
        } else {
          setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchBnams.nextToken])));
        }
        setBnams(models?.data?.searchBnams.items);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    },
    [pageSize]
  );

  const fetchTags = async () => {
    try {
      const models: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 500
        }
      });
      setTags(models.data.searchTags.items);
    } catch (e) {
      console.log(e);
    }
  };

  const subscribeOnCreateBnam = () => {
    const client = API.graphql({ query: onCreateBnam });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const bnam = data.onCreateBnam;
          setBnams((prev) => [bnam, ...prev]);
        }
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnUpdateBnam = () => {
    const client = API.graphql({ query: onUpdateBnam });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const bnam = data.onUpdateBnam;
          console.log('bnam', bnam);
          if (!bnam.delete_flg) {
            // 更新
            setBnams((Bnams) => Bnams.map((__bnam) => (__bnam.id === bnam.id ? bnam : __bnam)));
          } else {
            // 削除
            setBnams((Bnams) => Bnams.filter((__bnam) => __bnam.id !== bnam.id));
          }
        }
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnCreateTag = () => {
    const client = API.graphql({ query: onCreateTagWithoutRelations });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const tag = data.onCreateTag;
          setTags((tags) => [tag, ...tags]);
          setAddedTag(tag);
        }
      });
      return () => subscription.unsubscribe();
    }
  };

  const doOpenEdit = (params: any) => {
    setBnam(params.row);
    const __tagBnam = params.row.tags.items.find((tagBnam: any) => !tagBnam._deleted);
    __tagBnam && setTagBnam(__tagBnam);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const onChangePageSize = (num: number) => {
    setPageSize(num);
    setPage(0);
    fetchBnams({ newLimit: num });
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchBnams({ nextToken });
  };

  const onCloseEdit = () => {
    setTagBnam(null);
    setBnam(null);
    setAddedTag(null);
    setOpenEdit(false);
  };

  useEffect(() => {
    fetchBnams({});
    fetchTags();
    subscribeOnCreateBnam();
    subscribeOnUpdateBnam();
    subscribeOnCreateTag();
  }, [fetchBnams]);

  useEffect(() => {
    if (!isUploaded) return;
    fetchBnams({});
    setIsUploaded(false);
  }, [fetchBnams, isUploaded]);

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
          <ExportButton condition={expQueries} tableName="TAG_BNAM" type="SEARCH" />
          <ImportButton tableName="TAGBNAM" setIsUploaded={setIsUploaded} />
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          rows={Bnams}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            }
          }}
          loading={loading}
          paginationMode="server"
        />
      </div>

      {openEdit && user && (
        <EditTagBnamDialog
          addedTag={addedTag}
          onClose={onCloseEdit}
          open={openEdit}
          tags={tags}
          tagBnam={tagBnam}
          bnam={bnam}
          user={user}
          setBnams={setBnams}
          condition={condition}
          setCondition={setCondition}
          setBnamIds={setBnamIds}
          setExpQueries={setExpQueries}
        />
      )}
      {openSearch && (
        <SearchTagBnamDialog
          condition={condition}
          open={openSearch}
          setCondition={setCondition}
          setOpen={setOpenSearch}
          setBnams={setBnams}
          setBnamIds={setBnamIds}
          setExpQueries={setExpQueries}
        />
      )}
    </Box>
  );
}

export default TagBnamList;
