//import AWS from 'aws-sdk';
import { Hub } from 'aws-amplify/utils';
import { getCurrentUser, signInWithRedirect, fetchUserAttributes } from 'aws-amplify/auth';
import React, { useState, useEffect, ReactNode, createContext } from 'react';
import { Grid } from '@mui/material';
import awsExports from '../aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { ThemeProvider as AmplifyProvider } from '@aws-amplify/ui-react';
import { is_localhost } from '../utils/app';
import { getUser, listUsers } from '../graphql/queries';
import { User, UserRoles } from '../models';
import PrimaryButton from '../components/PrimaryButton';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

//Amplify.configure(awsExports);

const localhost: boolean = is_localhost();

interface ContextInterface {
  user: User | null;
  roles: UserRoles[] | null;
}
interface Props {
  children: ReactNode;
}

const defaultState = {
  user: null,
  roles: null,
};

const AuthContext = React.createContext<ContextInterface>(defaultState);

function AuthProvider(props: Props) {
  const [user, setUser] = useState<User | null>(null);
  const [roles, setRoles] = useState<UserRoles[] | null>(null);
  //const [getIdpCredentials, setGetIdpCredentials] = useState<Function | null>(null);

  const fetchUser = async () => {
    try {
      const userData = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      // デバッグ用
      // Auth.currentSession().then((data) => {
      //   console.log(`token: ${data.getIdToken().getJwtToken()}`);
      // });

      if (userData) {
        const rslt: any = await API.graphql({
          query: listUsers,
          variables: { filter: { email: { eq: attributes.email } } },
        });

        const model = (rslt.data as any).listUsers.items[0];
        if (!model) {
          console.log('could not specify user');
          return {};
        }
        const user = model;
        if (user) {
          return user;
        }
      }

      return {};
    } catch (e) {
      return console.log('Not signed in');
    }
  };

  const filterRoles = (roles: UserRoles[]) => roles.filter((role: UserRoles) => role.delete_flg !== true);

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event as string) {
        case 'signIn':
        case 'cognitoHostedUI':
          fetchUser().then((userData) => {
            setUser(userData);
            setRoles(filterRoles(userData.roles?.items));
            //setGetIdpCredentials(getIdpCredentialsFunction());
          });
          break;
        case 'signOut':
          setUser(null);
          setRoles(null);
          //setGetIdpCredentials(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure');
          break;
      }
    });
    if (!user) {
      fetchUser().then((userData) => {
        setUser(userData);
        setRoles(filterRoles(userData.roles?.items));
        //setGetIdpCredentials(getIdpCredentialsFunction());
      });
    }
  });
  return (
    <AuthContext.Provider value={{ user, roles }}>
      {localhost && <AmplifyProvider>{props.children}</AmplifyProvider>}
      {!localhost && user && props.children}
      {!localhost && !user && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <PrimaryButton variant="contained" onClick={() => signInWithRedirect()}>
              Sign In
            </PrimaryButton>
          </Grid>
        </Grid>
      )}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
