import React, { useCallback, useState, useEffect, useContext } from 'react';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format, parseISO } from 'date-fns';
import { AuthContext } from '../contexts/AuthContext';
import { Bnf } from '../API';
import { useSearchBnfs } from '../hooks/useSearchBnfs';
import { filterColumnsForGeneralRole } from '../utils/filterColumns';
import { Box } from '@mui/material';
import PrimaryButton from '../components/PrimaryButton';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { theme } from '../assets/theme/options';
import EditBnfDialog from '../components/bnf/EditBnfDialog';
import SearchBnfDialog from '../components/bnf/SearchBnfDialog';
import NewBnfDialog from '../components/bnf/NewBnfDialog';

const columns: GridColDef[] = [
  { field: 'seq_id', headerName: 'BNFマスターID', width: 150 },
  { field: 'bnf_data_source', headerName: 'BNFデータソース', width: 150 },
  {
    field: 'obic7_commodity_cd',
    headerName: 'OBIC7商品コード',
    width: 260,
  },
  { field: 'obic7_commodity_name', headerName: 'OBIC7商品名', width: 150 },
  {
    field: 'bvics_plan_cd_4char',
    headerName: 'BVICS企画コード(4桁)',
    width: 260,
  },
  {
    field: 'bvics_plan_cd_7char',
    headerName: 'BVICS企画コード(7桁)',
    width: 210,
  },
  {
    field: 'bvics_product_name',
    headerName: 'BVICS商品名',
    width: 210,
  },
  {
    field: 'admin_check_flg',
    headerName: '管理者チェックフラグ',
    width: 150,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    },
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    },
  },
  {
    field: 'updated_user_id',
    headerName: '最終更新者',
    width: 210,
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function BnfList() {
  const [openNew, setOpenNew] = useState(false);
  const [row, setRow] = useState<Bnf | null>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { user, roles } = useContext(AuthContext);
  const {
    fetchBnfs,
    loading,
    total,
    pageSize,
    onChangePageSize,
    bnfs,
    setBnfs,
    onChangePage,
    condition,
    page,
    onChangeSort,
    searchFilter,
  } = useSearchBnfs();
  const [isUploaded, setIsUploaded] = useState(false);

  useEffect(() => {
    fetchBnfs({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isUploaded) return;
    fetchBnfs({});
    setIsUploaded(false);
    // eslint-disable-next-line
  }, [isUploaded]);

  const roleTypes = roles?.map((role) => role.role?.label);

  // 一般ユーザの時は削除済データのカラムを非表示にする
  const removeColumns = ['delete_flg'];
  const filterdColumns = filterColumnsForGeneralRole(
    roleTypes,
    columns,
    removeColumns
  );

  const doOpenNew = useCallback(() => {
    setOpenNew(true);
  }, []);

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  if (!roleTypes) return <></>;

  return (
    <Box sx={{ p: 1 }}>
      <Box
        my={theme.spacing(2)}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, width: 900 }}>
          <PrimaryButton onClick={doOpenNew}>新規作成</PrimaryButton>
          {/* FIXME: exportとimportは別で修正する */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: 800,
            }}
          >
            <ExportButton
              condition={searchFilter}
              tableName="BNF"
              type="SEARCH"
            />
            <ImportButton tableName="BNF" setIsUploaded={setIsUploaded} />
          </Box>
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>

      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={bnfs}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openNew && user && (
        <NewBnfDialog
          open={openNew}
          setOpen={setOpenNew}
          user={user}
          fetchBnfs={fetchBnfs}
        />
      )}
      {row && user && (
        <EditBnfDialog
          open={openEdit}
          row={row}
          setOpen={setOpenEdit}
          setRow={setRow}
          user={user}
          fetchBnfs={fetchBnfs}
        />
      )}
      <SearchBnfDialog
        condition={condition}
        isAdmin={roleTypes.includes('ADMIN')}
        open={openSearch}
        setOpen={setOpenSearch}
        fetchBnfs={fetchBnfs}
      />
    </Box>
  );
}

export default BnfList;
