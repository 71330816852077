import { createContext } from 'react'
import type { AlertColor } from '@mui/material';

export interface Notification {
  isShow: boolean
  type: AlertColor
  message: string
}

export interface NotificationContext {
  notification: Notification
  addNotification: (notification: Omit<Notification, 'isShow'>) => void
  removeNotification: (event: React.SyntheticEvent<any> | Event, reason?: string) => void
}

export const NotificationContext = createContext<NotificationContext>({} as never)
