export const LOCAL_STORAGE_LANGUAGE = "BNEMASTER_LANGUAGE";

export const REGEXP_FEATURE_HOST = /feature-/;
export const REGEXP_DEVELOP_HOST = /develop/;
export const REGEXP_MAIN_HOST = /main/;
export const REGEXP_STG_HOST = /^stg.bandainamco-master-mgmt/;
export const REGEXP_SKRIT_HOST = /^bandainamco-master-mgmt/;

export const DATA_SOURCES_SEQ_CODE = "DTS";
export const MASTER_SYSTEM_SEQ_ID = "DTS0000000001";
export const BANASUKE_SEQ_ID = "DTS0000000002";
export const CE_LINK_SEQ_ID = "DTS0000000003";
export const MULCHI_SEQ_ID = "DTS0000000004";

export const OPERATION_TYPE_OPTIONS = [
  { id: "CREATE", name: "common_create" },
  { id: "EDIT", name: "common_edit" },
  { id: "SOFTDELETE", name: "common_logical-deletion" },
  { id: "RESTORESOFTDELETE", name: "common_logical-restore" },
  { id: "DELETE", name: "common_physical-deletion" },
];

export const SYSTEM_UPDATE_OPTIONS = [
  { id: "ALL", name: "common_all-data" },
  { id: true, name: "common_automatic-updates" },
  { id: false, name: "common_manual-updates" },
];

export const OFFICIAL_OPTIONS = [
  { id: "ALL", name: "common_all-data" },
  { id: true, name: "common_official" },
  { id: false, name: "common_unofficial" },
];

export const VALIDITY_OPTIONS = [
  { id: "ALL", name: "common_all-data" },
  { id: true, name: "common_validity" },
  { id: false, name: "common_invalid" },
];

export const DELETE_FLG_OPTIONS = [
  { id: false, name: "common_non-deleted" },
  { id: true, name: "common-deleted" },
  { id: "ALL", name: "common_all-data" },
];

export const ADMIN_CHECK_FLG_OPTIONS = [
  { id: true, name: "common_checked" },
  { id: false, name: "common_non-checked" },
  { id: "ALL", name: "common_all-data" },
];

export const OPERATION_TYPE_FILED = {
  field: "operation_type",
  label: "common_operation-category",
  type: "select",
  placeholder: "common_select-operation-type",
  optionsName: "operation_type",
};

export const OFFICIAL_NAME_OPTIONS = [
  { id: "ALL", name: "common_all-data" },
  { id: true, name: "common_formal" },
  { id: false, name: "common_informal" },
];

export const EDIT_CONFIRM_HEADER_COLS = ["common_item-name", "common_value"];

export const BNF_DATA_SOURCES = [
  { id: "OBIC7", name: "OBIC7" },
  { id: "BVICS", name: "BVICS" },
];
