/**
 * 注意：現状vscodeで編集/保存すると一部のキーの引用符が外れるのでVSCode以外のエディタで編集してください
 * 追記：prettierの対象から除外したので保存OK
 */

/**
 *
 * awsのログイン画面で使用するものは固定値?
 * 'Back to Sign In': 'サインイン画面に戻る'以下の項目
 *
 */

export const I18N_VOCABULARIES = {
  ja: {
    'common_all-data': '全データ',
    'common_automatic-updates': '自動更新',
    'common_checked': 'チェック済み',
    'common_create': '新規登録',
    'common_edit': '編集',
    'common_item-name': '項目名',
    'common_logical-deletion': '論理削除',
    'common_logical-restore': '論理復元',
    'common_manual-updates': '手動更新',
    'common_non-checked': '未チェック',
    'common_non-deleted': '未削除',
    'common_operation-category': '操作種別',
    'common_physical-deletion': '物理削除',
    'common_select-operation-type': '操作種別を選択',
    'common_value': '値',
    'common_official': '正式',
    'common_unofficial': '非正式',
    'common_validity': '有効',
    'common_invalid': '無効',
    'common-deleted': '削除済み',
    'common_formal': '正式',
    'common_informal': '非正式',
    'edit_user-email': 'メールアドレス',
    'export_currency-master': '通貨マスタ',
    'export_platform-master': 'プラットフォームマスタ',
    'export_product-category': 'プロダクト区分',
    'export_product-type': 'プロダクト種別',
    'export_product': 'プロダクト',
    'export_product-tcd': 'TCDプロダクト',
    'export_shared-tag-relation': '共有タグリレーション',
    'export_shared-tags': '共有タグ',
    'export_title': 'タイトル',
    'export-genre-master': 'ジャンルマスタ',
    'export_group-company': 'グループ会社マスタ',
    'product_admin-checked-flag': '管理者チェック済フラグ',
    'product_automatic-update-flag': '自動更新フラグ',
    'product_back-to-sign-in': 'サインイン画面に戻る',
    'product_base-price': 'ベースプライス',
    'product_contract-start-date': '予約開始日',
    'product_contract-start-date': '予約開始日',
    'product_currency': '通貨',
    'product_data-source-name': 'データソース名',
    'product_date-of-first-information-release': '情報初出日',
    'product_deleted-data': '削除済データ',
    'product_delivery-status': '配信ステータス',
    'product_final-update-date': '最終更新日時',
    'product_final-updater': '最終更新者',
    'product_market': 'マーケット',
    'product_platform-name': 'プラットフォーム名',
    'product_product-category': 'プロダクト区分',
    'product_product-common-name': 'プロダクト共通名',
    'product_product-distinguished-name': 'プロダクト区分名',
    'product_product-id': 'プロダクトID',
    'product_product-key': 'プロダクトキー',
    'product_product-list': 'プロダクト一覧',
    'product_product-name': 'プロダクト名',
    'product_product-specific-name': 'プロダクト区別名',
    'product_product-type-name': 'プロダクト種別名',
    'product_reason-for-registration-renewal': '登録更新理由',
    'product_reason-for-update': '更新理由',
    'product_release-date': '発売日',
    'product_service-end-date': 'サービス終了日',
    'product_service-start-date': 'サービス開始日',
    'product_support-end-date': 'サポート終了日',
    'product_title-id': 'タイトルID',
    'product_title-name': 'タイトル名',
    'product-history_admin-checked-flag': '管理者チェック済フラグ',
    'product-history_automatic-update-flag': '自動更新フラグ',
    'product-history_base-price': 'ベースプライス',
    'product-history_contract-start-date': '予約開始日',
    'product-history_currency': '通貨',
    'product-history_data-source-name': 'データソース名',
    'product-history_date-of-first-information-release': '情報初出日',
    'product-history_deleted-data': '削除済データ',
    'product-history_deleted-date': '削除日時',
    'product-history_delivery-status': '配信ステータス',
    'product-history_final-update-date': '最終更新日時',
    'product-history_final-updater': '最終更新者',
    'product-history_market': 'マーケット',
    'product-history_operation-category': '操作種別',
    'product-history_platform-name': 'プラットフォーム名',
    'product-history_product-common-name': 'プロダクト共通名',
    'product-history_product-id': 'プロダクトID',
    'product-history_product-key': 'プロダクトキー',
    'product-history_product-name': 'プロダクト名',
    'product-history_product-specific-name': 'プロダクト区別名',
    'product-history_product-type-name': 'プロダクト種別名',
    'product-history_reason-for-update': '更新理由',
    'product-history_release-date': '発売日',
    'product-history_service-end-date': 'サービス終了日',
    'product-history_service-start-date': 'サービス開始日',
    'product-history_support-end-date': 'サポート終了日',
    'product-history_title-name': 'タイトル名',
    'product-tcd-history_admin-checked-flag': '管理者チェック済フラグ',
    'product-tcd-history_automatic-update-flag': '自動更新フラグ',
    'product-tcd-history_base-price': 'ベースプライス',
    'product-tcd-history_contract-start-date': '予約開始日',
    'product-tcd-history_currency': '通貨',
    'product-tcd-history_data-source-name': 'データソース名',
    'product-tcd-history_date-of-first-information-release': '情報初出日',
    'product-tcd-history_deleted-data': '削除済データ',
    'product-tcd-history_deleted-date': '削除日時',
    'product-tcd-history_delivery-status': '配信ステータス',
    'product-tcd-history_final-update-date': '最終更新日時',
    'product-tcd-history_final-updater': '最終更新者',
    'product-tcd-history_market': 'マーケット',
    'product-tcd-history_operation-category': '操作種別',
    'product-tcd-history_platform-name': 'プラットフォーム名',
    'product-tcd-history_product-common-name': 'プロダクト共通名',
    'product-tcd-history_product-id': 'プロダクトID',
    'product-tcd-history_product-key': 'プロダクトキー',
    'product-tcd-history_product-name': 'プロダクト名',
    'product-tcd-history_product-specific-name': 'プロダクト区別名',
    'product-tcd-history_product-type-name': 'プロダクト種別名',
    'product-tcd-history_reason-for-update': '更新理由',
    'product-tcd-history_release-date': '発売日',
    'product-tcd-history_service-end-date': 'サービス終了日',
    'product-tcd-history_service-start-date': 'サービス開始日',
    'product-tcd-history_support-end-date': 'サポート終了日',
    'product-tcd-history_title-name': 'タイトルコード名称',
    'route_administrator-configuration': '管理者設定',
    'route_admin-csv-import-status': '管理者CSVインポート状況',
    'route_admin-datalake-status': 'データレイク手動実行',
    'route_currency-master': '通貨マスタ',
    'route_admin-export-all': '全件エクスポート',
    'route_ip-export-all': '全件エクスポート',
    'route_product-export-all': '全件エクスポート',
    'route_company-export-all': '全件エクスポート',
    'route_ip-master-manager': 'IPマスター管理',
    'route_platform-master': 'プラットフォームマスタ',
    'route_product-category-master': 'プロダクト区分マスタ',
    'route_product-csv-import-status': 'プロダクトCSVインポート状況',
    'route_product-tcd-csv-import-status': 'TCDプロダクトCSVインポート状況',
    'route_product-history': 'プロダクト履歴',
    'route_product-tcd-history': 'TCDプロダクト履歴',
    'route_product-list': 'プロダクト一覧',
    'route_product-tcd-list': 'TCDプロダクト一覧',
    'route_product-master-manager': '商品マスター管理',
    'route_product-type-master': 'プロダクト種別マスタ',
    'route_product-type': 'プロダクト種別',
    'route_product': 'プロダクト',
    'route_shared-tag-history': '共有タグ履歴',
    'route_shared-tagging-history': '共有タグ付け履歴',
    'route_shared-tagging-title-list': '共有タグ付け一覧',
    'route_shared-tags-list': '共有タグ一覧',
    'route_shared-tags': '共有タグ',
    'route_title-csv-import-status': 'タイトルCSVインポート状況',
    'route_title-history': 'タイトル履歴',
    'route_title-code-history': 'タイトルコード履歴',
    'route_title-code-csv-import-status': 'タイトルコードCSVインポート状況',
    'route_title-list': 'タイトル一覧',
    'route_title-code-list': 'タイトルコード一覧',
    'route_shared-tagging-titlecode-history': 'タイトルコード共有タグ付け履歴',
    'route_title-code-shared-tagging-title-list': 'タイトルコード共有タグ付け一覧',
    'route_title-code-shared-tagging-csv-import-status': 'TCDタグ付けCSVインポート状況',
    'route_top': 'トップページ',
    'route_user-master': 'ユーザーマスタ',
    'route_country-master': '国マスタ',
    'route_rate-master': 'レートマスタ',
    'route_calendar-master': '祝日カレンダーマスタ',
    'route_business-entity-master': '事業体マスタ',
    'route-genre-master': 'ジャンルマスタ',
    'route_bnam-master': 'BNAMマスター一覧',
    'route_shared-tagging-bnam-master': 'BNAMマスター共有タグ付け一覧',
    'route_shared-bnam-history': 'BNAMマスター共有タグ付け履歴一覧',
    'route_bnam-master-history': 'BNAMマスター履歴一覧',
    'route-parent_bne': 'BNE',
    'route-parent_bnf': 'BNF',
    'route-parent_bsp': 'BSP',
    'route-parent_bnml': 'BNML',
    'route-parent_bnam': 'BNAM',
    'route_bnf-list': 'BNFマスター一覧',
    'route_shared-tagging-bnf-list': 'BNFマスター共有タグ付け一覧',
    'route_tag-bnf-csv-import-status': 'BNF共有タグ付けCSVインポート状況',
    'route_bnf-csv-import-status': 'BNFマスターCSVインポート状況',
    'route_company-master-manager': 'グループ会社マスタ',
    'tag_deleted-data': '削除済データ',
    'tag_final-update-date': '最終更新日時',
    'tag_final-updater': '最終更新者',
    'tag_ip-tag-english': 'IPタグ(英語)',
    'tag_ip-tag-japanese': 'IPタグ(日本語)',
    'tag_tag-id': 'タグID',
    'tag-history_final-update-date': '最終更新日時',
    'tag-history_final-updater': '最終更新者',
    'tag-history_ip-tag-english': 'IPタグ(英語)',
    'tag-history_ip-tag-japanese': 'IPタグ(日本語)',
    'tag-history_operation-category': '操作種別',
    'tag-history_tag-id': 'タグID',
    'tag-title_ip-tag-search': 'IPタグ(日本語)',
    'tag-title_ip-tag': 'IPタグ(日本語)',
    'tag-title_ip-tag-en-search': 'IPタグ(英語)',
    'tag-title_ip-tag-en': 'IPタグ(英語)',
    'tag-title_tagged-last-modified': 'タグ付け最終更新日時',
    'tag-title_tagged-last-updated-by': 'タグ付け最終更新者',
    'tag-title_title-last-updated': 'タイトル最終更新日時',
    'tag-title_title-name': 'タイトル名',
    'tag-title-history_final-update-date': '最終更新日時',
    'tag-title-code-history_final-update-date': '最終更新日時',
    'tag-title-history_final-updater': '最終更新者',
    'tag-title-code-history_final-updater': '最終更新者',
    'tag-title-history_operation-category': '操作種別',
    'tag-title-history_title-name': 'タイトル名',
    'tag-title-code-history_title-name': 'タイトルコード名',
    'title_admin-checked-flag': '管理者チェック済フラグ',
    'title_automatic-update-flag': '自動更新フラグ',
    'title_banaske-title-id': 'バナスケタイトルID',
    'title_bn-connect-title-id': 'BN-ConnectタイトルID',
    'title_ce-link-key-code': 'CE-LINKキーコード',
    'title_data-source-id': 'データソース名',
    'title_data-source-name': 'データソース名',
    'title_deleted-data': '削除済データ',
    'title_final-update-date': '最終更新日時',
    'title_final-updater': '最終更新者',
    'title_genre': 'ジャンル',
    'title_reason-for-registration-renewal': '登録更新理由',
    'title_reason-for-update': '更新理由',
    'title_sap-title-code': 'SAPタイトルコード',
    'title_title-id': 'タイトルID',
    'title_title-name-english': 'タイトル名(英語)',
    'title_title-name-for-search': '検索用タイトル名称',
    'title_title-name-japanese': 'タイトル名(日本語)',
    'title-history_admin-checked-flag': '管理者チェック済フラグ',
    'title-history_automatic-update-flag': '自動更新フラグ',
    'title-history_banaske-title-id': 'バナスケタイトルID',
    'title-history_bn-connect-title-id': 'BN-ConnectタイトルID',
    'title-history_ce-link-key-code': 'CE-LINKキーコード',
    'title-history_data-source-name': 'データソース名',
    'title-history_deleted-data': '削除済データ',
    'title-history_deleted-date': '削除日時',
    'title-history_final-update-date': '最終更新日時',
    'title-history_final-updater': '最終更新者',
    'title-history_genre': 'ジャンル',
    'title-history_operation-category': '操作種別',
    'title-history_reason-for-update': '更新理由',
    'title-history_sap-title-code': 'SAPタイトルコード',
    'title-history_title-id': 'タイトルID',
    'title-history_title-name-english': 'タイトル名(英語)',
    'title-history_title-name-for-search': '検索用タイトル名称',
    'title-history_title-name-japanese': 'タイトル名(日本語)',
    'title-code_title-code-id': 'タイトルコードID',
    'title-code_title-code': 'タイトルコード',
    'title-code_title-code-name': 'タイトルコード名称',
    'title-code_title-code-name-japanese': 'タイトルコード名称(日本語)',
    'title-code_title-code-name-english': 'タイトルコード名称(英語)',
    'title-code_official-name-flg': 'タイトルコード名称 正式フラグ',
    'title-code_sap-title-code': 'SAPタイトルコード用名称',
    'title-code_search-name': '検索用タイトルコード名称',
    'title-code_project-cd': '関連プロジェクトコード',
    'title-code_wbs': '関連WBS',
    'title-code_bn_connect-title-ids': 'BN-ConnectタイトルID',
    'title-code_ce-link-key-code': 'CE-LINKキーコード',
    'title-code_ce-link-title-name-ja': 'CE-LINKキーコード名称(日本語)',
    'title-code_ce-link-title-name-en': 'CE-LINKキーコード名称(英語)',
    'title-code_genre-ids': 'ジャンル',
    'title-code_banasuke-title-id': 'バナスケタイトルID',
    'title-code_banasuke-title-name-ja': 'バナスケタイトル名称(日本語)',
    'title-code_bundle-source-title-code-ids': 'バンドル元タイトルコードID',
    'title-code_porting-source-title-code-ids': '移植元タイトルコードID',
    'title-code_platform-ids': 'PF(プラットフォーム)',
    'title-code_valid-flg': '有効フラグ',
    'title-code_business-entity': '事業体',
    'title-code_update-reason': '登録更新理由',
    'title-code-history_operation-type': '操作種別',
    'title-code-history_seq-id': 'タイトルコードID',
    'title-code-history_title-code': 'タイトルコード',
    'title-code-history_name-ja': 'タイトルコード名称(日本語)',
    'title-code-history_name-en': 'タイトルコード名称(英語)',
    'title-code-history_sap-title-code-name': 'SAPタイトルコード用名称',
    'title-code-history_search-name': '検索用タイトルコード名称',
    'title-code-history_project-cd': '関連プロジェクトコード',
    'title-code-history_wbs': '関連WBS',
    'title-code-history_official-name-flg': 'タイトルコード名称 正式フラグ',
    'title-code-history_bn-connect-title-ids': 'BN-ConnectタイトルID',
    'title-code-history_ce-link-key-code': 'CE-LINKキーコード',
    'title-code-history_ce-link-title-name-ja': 'CE-LINKキーコード名称(日本語)',
    'title-code-history_ce-link-title-name-en': 'CE-LINKキーコード名称(英語)',
    'title-code-history_genre-ids': 'ジャンル',
    'title-code-history_genre-name': 'ジャンル',
    'title-code-history_data-source-name': 'データソース名',
    'title-code-history_banasuke-title-id': 'バナスケタイトルID',
    'title-code-history_banasuke-title-name-ja': 'バナスケタイトル名称（日本語）',
    'title-code-history_bundle-source-title-code-ids': 'バンドル元タイトルコードID',
    'title-code-history_porting-source-title-code-ids': '移植元タイトルコードID',
    'title-code-history_platform-ids': 'PF（プラットフォーム）',
    'title-code-history_business-entity': '事業体',
    'title-code-history_update-reason': '更新理由',
    'title-code-history_updated-user-id': '最終更新者',
    'title-code-history_final-update-date': '最終更新日時',
    'title-code-history_deleted-date': '削除日時',
    'title-code-history_automatic-update-flag': '自動更新フラグ',
    'title-code-history_admin-checked-flag': '管理者チェック済フラグ',
    'title-code-history_deleted-data': '削除済データ',
    'title-code_update-reason': '登録更新理由',
    'Back to Sign In': 'サインイン画面に戻る',
    'Confirm': '確認',
    'Confirm Sign Up': 'サインアップの確認',
    'Confirmation Code': '確認コード',
    'Create Account': '新規登録',
    'Create a new account': 'アカウントの新規登録',
    'Create account': '新規登録',
    'Email': 'メールアドレス',
    'Enter your code': '確認コードを入力してください',
    'Enter your password': 'パスワードを入力してください',
    'Enter your username': 'ユーザー名を入力してください',
    'Forgot your password? ': 'パスワードをお忘れの方 ',
    'Have an account? ': 'アカウント登録済みの方 ',
    'Hello': 'こんにちは ',
    'Incorrect username or password': 'ユーザー名またはパスワードが異なります',
    'Lost your code? ': 'コードを紛失した方 ',
    'No account? ': 'アカウント未登録の方 ',
    'Password': 'パスワード',
    'Phone Number': '電話番号',
    'Resend Code': '確認コードの再送',
    'Reset password': 'パスワードのリセット',
    'Reset your password': 'パスワードのリセット',
    'Send Code': 'コードの送信',
    'Sign In': 'サインイン',
    'Sign Out': 'サインアウト',
    'Sign in': 'サインイン',
    'Sign in to your account': 'サインイン',
    'User does not exist': 'ユーザーが存在しません',
    'Username': 'ユーザー名',
    'Username cannot be empty': 'ユーザー名は必須入力です',
    'Username/client id combination not found.': 'ユーザー名が見つかりません',
    'IP Master Manager': 'IPマスター管理',
    'Product Master Manager': '商品マスター管理',
    'Administrator Configuration': '管理者設定',
    'route_bsp-list': 'BSPマスター一覧',
    'route_bnml-list': 'BNMLマスター一覧',
    'route_bnml-history': 'BNMLマスター履歴一覧',
    'route_shared-tagging-bnml-list': 'BNMLマスター共有タグ付け一覧',
    'route_shared-bnml-history': 'BNMLマスター共有タグ付け履歴',
    'route_bnf-history': 'BNFマスター履歴一覧',
    'route_shared-bnf-history': 'BNFマスター共有タグ付け履歴',
    'route_bnml-csv-import-status': 'BNMLマスターCSVインポート状況',
    'route_bnam-csv-import-status': 'BNAMマスターCSVインポート状況',
    'route_shared-tagging-bsp-list': 'BSPマスター共有タグ付け一覧',
    'route_shared-bsp-history': 'BSPマスター共有タグ付け履歴一覧',
    'export_bsp': 'BSPマスター',
    'export_bsp-tag': 'BSPマスター共有タグ付け',
    'export_bnf': 'BNFマスター',
    'export_bnf-tag': 'BNFマスター共有タグ付け',
    'export_bnml': 'BNMLマスター',
    'export_bnml-tag': 'BNMLマスター共有タグ付け',
    'export_bnam': 'BNAMマスター',
    'export_bnam-tag': 'BNAMマスター共有タグ付け',
    'export_bnf': 'BNFマスター',
    'export_bnf-tag': 'BNFマスター共有タグ付け',
    'export_country-master': '国マスタ',
    'export_calendar-master': '祝日カレンダーマスタ',
    'export_group-company': 'グループ会社マスタ',
    'route_tag-bsp-csv-import-status': 'BSPタグ付けCSVインポート状況',
    'route_tag-bnml-csv-import-status': 'BNMLタグ付けCSVインポート状況',
    'route_tag-bnf-csv-import-status': 'BNFタグ付けCSVインポート状況',
    'route_tag-bnam-csv-import-status': 'BNAMタグ付けCSVインポート状況',
    'route_bsp-csv-import-status': 'BSPマスターCSVインポート状況',
    'route_bsp-history': 'BSPマスター履歴一覧',
    'route_shared-bsp-history': 'BSPマスター共有タグ付け履歴',
    'country_order-id': '表示順',
    'country_code-country': '国コード',
    'country_code-second': 'コード（2文字）',
    'country_code-third': 'コード（3文字）',
    'country_name_japanese': '国名（日本語）',
    'country_name_english': '国名（英語）',
    'country_update-reason': '更新理由',
    'country_final-update-date': '最終更新日時',
    'currency_order_id': '表示順',
    'currency_currency-code': '通貨コード',
    'currency_code-three-char': 'コード（3文字）',
    'currency_name-japanese': '通貨名（日本語）',
    'currency_name-english': '通貨名（英語）',
    'currency_digit-number': '有効桁数',
    'currency_currency-symbol': '通貨記号',
    'currency_update-reason': '更新理由',
    'currency_final-update-date': '最終更新日時',
    'rate_source-currency-id': '換算前通貨',
    'rate_converted-currency-id': '換算後通貨',
    'rate_from-date': '有効開始日',
    'rate_to-date': '有効終了日',
    'rate_exchange-rate': '換算レート',
    'rate_company-name': '会社名',
    'rate_usage': '用途',
    'rate_description': '説明',
    'rate_exchange-rate-type': '換算レート type',
    'rate_update-reason': '更新理由',
    'rate_seq-id': 'レートコード',
    'rate_updated-user-id': '最終更新者',
    'rate_updated-at': '最終更新日時',
    'rate_deleted-data': '削除済データ',
    'rate_update-reason': '更新理由',
    'export_rate-master': 'レートマスタ',
    'export_calendar-master': '祝日カレンダーマスタ',
    'export_title-code': 'タイトルコード',
    'export_title-code-tag': 'タイトルコード共有タグ付け',
    'export_business-entity-master': '事業体マスタ',
    'calendar_calendar-date': '日付',
    'calendar_year': '年',
    'calendar_month': '月',
    'calendar_day': '日',
    'calendar_day-of-week': '曜日',
    'calendar_holiday-flg': '祝日フラグ',
    'calendar_name': '日付名称',
    'calendar_country-id': '国名',
    'calendar_final-updater': '最終更新者',
    'calendar_updated-at': '最終更新日時',
    'calendar_update-reason': '更新理由',
    'calendar_deleted-data': '削除済データ',
    'route_bnf-list': 'BNFマスター一覧',
    'route_company-master-manager-list': 'グループ会社マスタ一覧',
    'route_company-master-manager-list': 'グループ会社マスタ一覧',
    'route_company-master-manager-history': 'グループ会社マスタ履歴',
    'route_company-master-manager-csv-import': 'グループ会社マスタCSVインポート状況',
    'group-company-seq_id': 'グループ会社マスタID ',
    'group-company-group_company_code': 'グループ会社コード',
    'group-company-valid_start_date': '有効開始日',
    'group-company-valid_end_date': '有効終了日',
    'group-company-stravis_code': 'Stravisコード',
    'group-company-company_name_ja': '会社名（日本語）',
    'group-company-company_name_en': '会社名（英語）',
    'group-company-abbreviation': '略称',
    'group-company-company_attribute': '会社属性',
    'group-company-consolidation': '連結・非連結',
    'group-company-unit_class_1': 'ユニット区分1',
    'group-company-unit_class_2': 'ユニット区分2',
    'group-company-management_business_1': '管理事業1',
    'group-company-management_business_2': '管理事業2',
    'group-company-country_id': '国/地域',
    'group-company-accounting_period': '決算期',
    'group-company-public_or_private': '公開/非公開',
    'group-company-group_agreement_flg': 'グループ規約適用',
    'group-company-management_contract_flg': '経営管理契約',
    'group-company-subcontracting_contract_flg': '業務委託契約',
    'group-company-brand_portfolio': 'ブランドポートフォリオ',
    'group-company-holding_company_1': '保有会社1',
    'group-company-investment_ratio_1': '出資比率1',
    'group-company-holding_company_2': '保有会社2',
    'group-company-investment_ratio_2': '出資比率2',
    'group-company-holding_company_3': '保有会社3',
    'group-company-investment_ratio_3': '出資比率3',
    'group-company-holding_company_4': '保有会社4',
    'group-company-investment_ratio_4': '出資比率4',
    'group-company-holding_company_5': '保有会社5',
    'group-company-investment_ratio_5': '出資比率5',
    'group-company-total_investment_ratio': '合計出資比率',
    'group-company-issued_shares_total': '発行済株式総数',
    'group-company-treasury_stock': '自己株',
    'group-company-nominee_shares': '名義貸株式',
    'group-company-capital': '資本金',
    'group-company-currency_id': '現地通貨',
    'group-company-number_of_employees': '従業員数',
    'group-company-establishment_date': '設立日',
    'group-company-representative_director_1_position': '代表取締役1役職名',
    'group-company-representative_director_1_name': '代表取締役1氏名',
    'group-company-representative_director_2_position': '代表取締役2役職名',
    'group-company-representative_director_2_name': '代表取締役2氏名',
    'group-company-representative_director_3_position': '代表取締役3役職名',
    'group-company-representative_director_3_name': '代表取締役3氏名',
    'group-company-business_description': '業務内容',
    'group-company-address_1': '住所1',
    'group-company-address_2': '住所2',
    'group-company-notes': '備考',
    'group-company-ip_address': 'IPアドレス',
    'group-company-equity_method_application': '持分法適用・非適用',
    'group-company-updated_reason': '更新理由',
    'group-company-false-group_agreement_flg': 'グループ規約適用なし',
    'group-company-true-group_agreement_flg': 'グループ規約適用あり',
    'group-company-all-group_agreement_flg': '全データ',
    'group-company-false-management_contract_flg': '経営管理規約なし',
    'group-company-true-management_contract_flg': '経営管理規約あり',
    'group-company-all-management_contract_flg': '全データ',
    'group-company-false-subcontracting_contract_flg': '業務委託契約なし',
    'group-company-true-subcontracting_contract_flg': '業務委託契約あり',
    'group-company-all-subcontracting_contract_flg': '全データ',
    'group-company-delete_flg': '削除済データ',
    'group-company_final-updater': '最終更新者',
  },
  en: {
    'common_all-data': '全データ',
    'common_automatic-updates': '自動更新',
    'common_checked': 'チェック済み',
    'common_create': '新規登録',
    'common_edit': '編集',
    'common_item-name': '項目名',
    'common_logical-deletion': '論理削除',
    'common_logical-restore': '論理復元',
    'common_manual-updates': '手動更新',
    'common_non-checked': '未チェック',
    'common_non-deleted': '未削除',
    'common_operation-category': '操作種別',
    'common_physical-deletion': '物理削除',
    'common_select-operation-type': '操作種別を選択',
    'common_value': '値',
    'common-deleted': '削除済み',
    'common_formal': '正式',
    'common_informal': '非正式',
    'edit_user-email': 'メールアドレス',
    'export_currency-master': '通貨マスタ',
    'export_platform-master': 'プラットフォームマスタ',
    'export_product-category': 'プロダクト区分',
    'export_product-type': 'プロダクト種別',
    'export_product': 'プロダクト',
    'export_product-tcd': 'TCDプロダクト',
    'export_shared-tag-relation': '共有タグリレーション',
    'export_shared-tags': '共有タグ',
    'export_title': 'タイトル',
    'export-genre-master': 'ジャンルマスタ',
    'product_admin-checked-flag': '管理者チェック済フラグ',
    'product_automatic-update-flag': '自動更新フラグ',
    'product_back-to-sign-in': 'サインイン画面に戻る',
    'product_base-price': 'ベースプライス',
    'product_contract-start-date': '予約開始日',
    'product_contract-start-date': '予約開始日',
    'product_currency': '通貨',
    'product_data-source-name': 'データソース名',
    'product_date-of-first-information-release': '情報初出日',
    'product_deleted-data': '削除済データ',
    'product_delivery-status': '配信ステータス',
    'product_final-update-date': '最終更新日時',
    'product_final-updater': '最終更新者',
    'product_market': 'マーケット',
    'product_platform-name': 'プラットフォーム名',
    'product_product-category': 'プロダクト区分',
    'product_product-common-name': 'プロダクト共通名',
    'product_product-distinguished-name': 'プロダクト区分名',
    'product_product-id': 'プロダクトID',
    'product_product-key': 'プロダクトキー',
    'product_product-list': 'プロダクト一覧',
    'product_product-name': 'プロダクト名',
    'product_product-specific-name': 'プロダクト区別名',
    'product_product-type-name': 'プロダクト種別名',
    'product_reason-for-registration-renewal': '登録更新理由',
    'product_reason-for-update': '更新理由',
    'product_release-date': '発売日',
    'product_service-end-date': 'サービス終了日',
    'product_service-start-date': 'サービス開始日',
    'product_support-end-date': 'サポート終了日',
    'product_title-id': 'タイトルID',
    'product_title-name': 'タイトル名',
    'product-history_admin-checked-flag': '管理者チェック済フラグ',
    'product-history_automatic-update-flag': '自動更新フラグ',
    'product-history_base-price': 'ベースプライス',
    'product-history_contract-start-date': '予約開始日',
    'product-history_currency': '通貨',
    'product-history_data-source-name': 'データソース名',
    'product-history_date-of-first-information-release': '情報初出日',
    'product-history_deleted-data': '削除済データ',
    'product-history_deleted-date': '削除日時',
    'product-history_delivery-status': '配信ステータス',
    'product-history_final-update-date': '最終更新日時',
    'product-history_final-updater': '最終更新者',
    'product-history_market': 'マーケット',
    'product-history_operation-category': '操作種別',
    'product-history_platform-name': 'プラットフォーム名',
    'product-history_product-common-name': 'プロダクト共通名',
    'product-history_product-id': 'プロダクトID',
    'product-history_product-key': 'プロダクトキー',
    'product-history_product-name': 'プロダクト名',
    'product-history_product-specific-name': 'プロダクト区別名',
    'product-history_product-type-name': 'プロダクト種別名',
    'product-history_reason-for-update': '更新理由',
    'product-history_release-date': '発売日',
    'product-history_service-end-date': 'サービス終了日',
    'product-history_service-start-date': 'サービス開始日',
    'product-history_support-end-date': 'サポート終了日',
    'product-history_title-name': 'タイトル名',
    'product-tcd-history_admin-checked-flag': '管理者チェック済フラグ',
    'product-tcd-history_automatic-update-flag': '自動更新フラグ',
    'product-tcd-history_base-price': 'ベースプライス',
    'product-tcd-history_contract-start-date': '予約開始日',
    'product-tcd-history_currency': '通貨',
    'product-tcd-history_data-source-name': 'データソース名',
    'product-tcd-history_date-of-first-information-release': '情報初出日',
    'product-tcd-history_deleted-data': '削除済データ',
    'product-tcd-history_deleted-date': '削除日時',
    'product-tcd-history_delivery-status': '配信ステータス',
    'product-tcd-history_final-update-date': '最終更新日時',
    'product-tcd-history_final-updater': '最終更新者',
    'product-tcd-history_market': 'マーケット',
    'product-tcd-history_operation-category': '操作種別',
    'product-tcd-history_platform-name': 'プラットフォーム名',
    'product-tcd-history_product-common-name': 'プロダクト共通名',
    'product-tcd-history_product-id': 'プロダクトID',
    'product-tcd-history_product-key': 'プロダクトキー',
    'product-tcd-history_product-name': 'プロダクト名',
    'product-tcd-history_product-specific-name': 'プロダクト区別名',
    'product-tcd-history_product-type-name': 'プロダクト種別名',
    'product-tcd-history_reason-for-update': '更新理由',
    'product-tcd-history_release-date': '発売日',
    'product-tcd-history_service-end-date': 'サービス終了日',
    'product-tcd-history_service-start-date': 'サービス開始日',
    'product-tcd-history_support-end-date': 'サポート終了日',
    'product-tcd-history_title-name': 'タイトルコード名称',
    'route_administrator-configuration': '管理者設定',
    'route_admin-csv-import-status': '管理者CSVインポート状況',
    'route_admin-datalake-status': 'データレイク手動実行',
    'route_currency-master': '通貨マスタ',
    'route_admin-export-all': '全件エクスポート',
    'route_ip-export-all': '全件エクスポート',
    'route_product-export-all': '全件エクスポート',
    'route_company-export-all': '全件エクスポート',
    'route_ip-master-manager': 'IPマスター管理',
    'route_platform-master': 'プラットフォームマスタ',
    'route_product-category-master': 'プロダクト区分マスタ',
    'route_product-csv-import-status': 'プロダクトCSVインポート状況',
    'route_product-tcd-csv-import-status': 'TCDプロダクトCSVインポート状況',
    'route_product-history': 'プロダクト履歴',
    'route_product-tcd-history': 'TCDプロダクト履歴',
    'route_product-list': 'プロダクト一覧',
    'route_product-tcd-list': 'TCDプロダクト一覧',
    'route_product-master-manager': '商品マスター管理',
    'route_product-type-master': 'プロダクト種別マスタ',
    'route_product-type': 'プロダクト種別',
    'route_product': 'プロダクト',
    'route_shared-tag-history': '共有タグ履歴',
    'route_shared-tagging-history': '共有タグ付け履歴',
    'route_shared-tagging-title-list': '共有タグ付け一覧',
    'route_shared-tagging-titlecode-history': 'タイトルコード共有タグ付け履歴',
    'route_shared-tags-list': '共有タグ一覧',
    'route_shared-tags': '共有タグ',
    'route_title-csv-import-status': 'タイトルCSVインポート状況',
    'route_title-history': 'タイトル履歴',
    'route_title-code-history': 'タイトルコード履歴',
    'route_title-code-csv-import-status': 'タイトルコードCSVインポート状況',
    'route_title-list': 'タイトル一覧',
    'route_title-code-list': 'タイトルコード一覧',
    'route_title-code-shared-tagging-title-list': 'タイトルコード共有タグ付け一覧',
    'route_title-code-shared-tagging-csv-import-status': 'TCDタグ付けCSVインポート状況',
    'route_top': 'トップページ',
    'route_user-master': 'ユーザーマスタ',
    'route_country-master': '国マスタ',
    'route_rate-master': 'レートマスタ',
    'route_calendar-master': '祝日カレンダーマスタ',
    'route_business-entity-master': '事業体マスタ',
    'route-genre-master': 'ジャンルマスタ',
    'route_bnam-master': 'BNAMマスター一覧',
    'route_shared-tagging-bnam-master': 'BNAMマスター共有タグ付け一覧',
    'route_shared-bnam-history': 'BNAMマスター共有タグ付け履歴一覧',
    'route_bnam-master-history': 'BNAMマスター履歴一覧',
    'route-parent_bne': 'BNE',
    'route-parent_bnf': 'BNF',
    'route-parent_bsp': 'BSP',
    'route-parent_bnml': 'BNML',
    'route-parent_bnam': 'BNAM',
    'route_bnf-list': 'BNFマスター一覧',
    'route_shared-bnf-history': 'BNFマスター共有タグ付け履歴',
    'route_shared-tagging-bnf-list': 'BNFマスター共有タグ付け一覧',
    'route_tag-bnf-csv-import-status': 'BNF共有タグ付けCSVインポート状況',
    'route_bnf-csv-import-status': 'BNFマスターCSVインポート状況',
    'route_company-master-manager': 'グループ会社マスタ',
    'tag_deleted-data': '削除済データ',
    'tag_final-update-date': '最終更新日時',
    'tag_final-updater': '最終更新者',
    'tag_ip-tag-english': 'IPタグ(英語)',
    'tag_ip-tag-japanese': 'IPタグ(日本語)',
    'tag_tag-id': 'タグID',
    'tag-history_final-update-date': '最終更新日時',
    'tag-history_final-updater': '最終更新者',
    'tag-history_ip-tag-english': 'IPタグ(英語)',
    'tag-history_ip-tag-japanese': 'IPタグ(日本語)',
    'tag-history_operation-category': '操作種別',
    'tag-history_tag-id': 'タグID',
    'tag-title_ip-tag-search': 'IPタグ(日本語)',
    'tag-title_ip-tag': 'IPタグ(日本語)',
    'tag-title_ip-tag-en-search': 'IPタグ(英語)',
    'tag-title_ip-tag-en': 'IPタグ(英語)',
    'tag-title_tagged-last-modified': 'タグ付け最終更新日時',
    'tag-title_tagged-last-updated-by': 'タグ付け最終更新者',
    'tag-title_title-last-updated': 'タイトル最終更新日時',
    'tag-title_title-name': 'タイトル名',
    'tag-title-history_final-update-date': '最終更新日時',
    'tag-title-history_final-updater': '最終更新者',
    'tag-title-history_operation-category': '操作種別',
    'tag-title-history_title-name': 'タイトル名',
    'title_admin-checked-flag': '管理者チェック済フラグ',
    'title_admin-checked-flag': '管理者チェック済フラグ',
    'title_automatic-update-flag': '自動更新フラグ',
    'title_banaske-title-id': 'バナスケタイトルID',
    'title_bn-connect-title-id': 'BN-ConnectタイトルID',
    'title_ce-link-key-code': 'CE-LINKキーコード',
    'title_data-source-id': 'データソース名',
    'title_data-source-name': 'データソース名',
    'title_deleted-data': '削除済データ',
    'title_final-update-date': '最終更新日時',
    'title_final-updater': '最終更新者',
    'title_genre': 'ジャンル',
    'title_reason-for-registration-renewal': '登録更新理由',
    'title_reason-for-update': '更新理由',
    'title_sap-title-code': 'SAPタイトルコード',
    'title_title-id': 'タイトルID',
    'title_title-name-english': 'タイトル名(英語)',
    'title_title-name-for-search': '検索用タイトル名称',
    'title_title-name-japanese': 'タイトル名(日本語)',
    'title-history_admin-checked-flag': '管理者チェック済フラグ',
    'title-history_automatic-update-flag': '自動更新フラグ',
    'title-history_banaske-title-id': 'バナスケタイトルID',
    'title-history_bn-connect-title-id': 'BN-ConnectタイトルID',
    'title-history_ce-link-key-code': 'CE-LINKキーコード',
    'title-history_data-source-name': 'データソース名',
    'title-history_final-update-date': '最終更新日時',
    'title-history_final-updater': '最終更新者',
    'title-history_genre': 'ジャンル',
    'title-history_operation-category': '操作種別',
    'title-history_reason-for-update': '更新理由',
    'title-history_sap-title-code': 'SAPタイトルコード',
    'title-history_title-id': 'タイトルID',
    'title-history_title-name-english': 'タイトル名(英語)',
    'title-history_title-name-for-search': '検索用タイトル名称',
    'title-history_title-name-japanese': 'タイトル名(日本語)',
    'title-code_title-code-id': 'タイトルコードID',
    'title-code_title-code': 'タイトルコード',
    'title-code_title-code-name': 'タイトルコード名称',
    'title-code_title-code-name-japanese': 'タイトルコード名称(日本語)',
    'title-code_title-code-name-english': 'タイトルコード名称(英語)',
    'title-code_official-name-flg': 'タイトルコード名称 正式フラグ',
    'title-code_sap-title-code': 'SAPタイトルコード用名称',
    'title-code_search-name': '検索用タイトルコード名称',
    'title-code_project-cd': '関連プロジェクトコード',
    'title-code_wbs': '関連WBS',
    'title-code_bn_connect-title-ids': 'BN-ConnectタイトルID',
    'title-code_ce-link-key-code': 'CE-LINKキーコード',
    'title-code_ce-link-title-name-ja': 'CE-LINKキーコード名称(日本語)',
    'title-code_ce-link-title-name-en': 'CE-LINKキーコード名称(英語)',
    'title-code_genre-ids': 'ジャンル',
    'title-code_banasuke-title-id': 'バナスケタイトルID',
    'title-code_banasuke-title-name-ja': 'バナスケタイトル名称(日本語)',
    'title-code_bundle-source-title-code-ids': 'バンドル元タイトルコードID',
    'title-code_porting-source-title-code-ids': '移植元タイトルコードID',
    'title-code_platform-ids': 'PF(プラットフォーム)',
    'title-code_business-entity': '事業体',
    'title-code_update-reason': '登録更新理由',
    'title-code-history_operation-type': '操作種別',
    'title-code-history_seq-id': 'タイトルコードID',
    'title-code-history_title-code': 'タイトルコード',
    'title-code-history_name-ja': 'タイトルコード名称(日本語)',
    'title-code-history_name-en': 'タイトルコード名称(英語)',
    'title-code-history_sap-title-code-name': 'SAPタイトルコード用名称',
    'title-code-history_search-name': '検索用タイトルコード名称',
    'title-code-history_project-cd': '関連プロジェクトコード',
    'title-code-history_wbs': '関連WBS',
    'title-code-history_official-name-flg': 'タイトルコード名称 正式フラグ',
    'title-code-history_bn-connect-title-ids': 'BN-ConnectタイトルID',
    'title-code-history_ce-link-key-code': 'CE-LINKキーコード',
    'title-code-history_ce-link-title-name-ja': 'CE-LINKキーコード名称(日本語)',
    'title-code-history_ce-link-title-name-en': 'CE-LINKキーコード名称(英語)',
    'title-code-history_genre-ids': 'ジャンル',
    'title-code-history_genre-name': 'ジャンル',
    'title-code-history_data-source-name': 'データソース名',
    'title-code-history_banasuke-title-id': 'バナスケタイトルID',
    'title-code-history_banasuke-title-name-ja': 'バナスケタイトル名称（日本語）',
    'title-code-history_bundle-source-title-code-ids': 'バンドル元タイトルコードID',
    'title-code-history_porting-source-title-code-ids': '移植元タイトルコードID',
    'title-code-history_platform-ids': 'PF（プラットフォーム）',
    'title-code-history_business-entity': '事業体',
    'title-code-history_update-reason': '更新理由',
    'title-code-history_updated-user-id': '最終更新者',
    'title-code-history_final-update-date': '最終更新日時',
    'title-code-history_deleted-date': '削除日時',
    'title-code-history_automatic-update-flag': '自動更新フラグ',
    'title-code-history_admin-checked-flag': '管理者チェック済フラグ',
    'title-code-history_deleted-data': '削除済データ',
    'route_bsp-list': 'BSPマスター一覧',
    'route_shared-tagging-bsp-list': 'BSPマスター共有タグ付け一覧',
    'route_bnml-list': 'BNMLマスター一覧',
    'route_bnml-history': 'BNMLマスター履歴一覧',
    'route_shared-tagging-bnml-list': 'BNMLマスター共有タグ付け一覧',
    'route_shared-bnml-history': 'BNMLマスター共有タグ付け履歴',
    'route_shared-bnf-history': 'BNFマスター共有タグ付け履歴',
    'route_bnf-history': 'BNFマスター履歴一覧',
    'route_bnml-csv-import-status': 'BNMLマスターCSVインポート状況',
    'route_bnf-csv-import-status': 'BNFマスターCSVインポート状況',
    'route_bnam-csv-import-status': 'BNAMマスターCSVインポート状況',
    'route_shared-bsp-history': 'BSPマスター共有タグ付け履歴一覧',
    'route_bsp-history': 'BSPマスター履歴一覧',
    'route_shared-bsp-history': 'BSPマスター共有タグ付け履歴',
    'route_bsp-tagging-history': "BSP共有タグ付け履歴",
    'export_bsp': 'BSPマスター',
    'export_bsp-tag': 'BSPマスター共有タグ付け',
    'export_bnf': 'BNFマスター',
    'export_bnf-tag': 'BNFマスター共有タグ付け',
    'export_bnml': 'BNMLマスター',
    'export_bnml-tag': 'BNMLマスター共有タグ付け',
    'export_bnam': 'BNAMマスター',
    'export_bnam-tag': 'BNAMマスター共有タグ付け',
    'export_bnf': 'BNFマスター',
    'export_bnf-tag': 'BNFマスター共有タグ付け',
    'export_country-master': '国マスタ',
    'export_calendar-master': '祝日カレンダーマスタ',
    'route_tag-bsp-csv-import-status': 'BSPタグ付けCSVインポート状況',
    'route_tag-bnml-csv-import-status': 'BNMLタグ付けCSVインポート状況',
    'route_tag-bnam-csv-import-status': 'BNAMタグ付けCSVインポート状況',
    'route_bsp-csv-import-status': 'BSPマスターCSVインポート状況',
    'country_order-id': '表示順',
    'country_code-country': '国コード',
    'country_code-second': 'コード（2文字）',
    'country_code-third': 'コード（3文字）',
    'country_name_japanese': '国名（日本語）',
    'country_name_english': '国名（英語）',
    'country_update-reason': '更新理由',
    'country_final-update-date': '最終更新日時',
    'currency_order_id': '表示順',
    'currency_currency-code': '通貨コード',
    'currency_code-three-char': 'コード（3文字）',
    'currency_name-japanese': '通貨名（日本語）',
    'currency_name-english': '通貨名（英語）',
    'currency_digit-number': '有効桁数',
    'currency_currency-symbol': '通貨記号',
    'currency_update-reason': '更新理由',
    'currency_final-update-date': '最終更新日時',
    'rate_source-currency-id': '換算前通貨',
    'rate_converted-currency-id': '換算後通貨',
    'rate_from-date': '有効開始日',
    'rate_to-date': '有効終了日',
    'rate_exchange-rate': '換算レート',
    'rate_company-name': '会社名',
    'rate_usage': '用途',
    'rate_description': '説明',
    'rate_exchange-rate-type': '換算レート type',
    'rate_update-reason': '更新理由',
    'rate_seq-id': 'レートコード',
    'rate_updated-user-id': '最終更新者',
    'rate_updated-at': '最終更新日時',
    'rate_deleted-data': '削除済データ',
    'rate_update-reason': '更新理由',
    'export_rate-master': 'レートマスタ',
    'export_calendar-master': '祝日カレンダーマスタ',
    'export_title-code': 'タイトルコード',
    'export_title-code-tag': 'タイトルコード共有タグ付け',
    'export_business-entity-master': '事業体マスタ',
    'calendar_calendar-date': '日付',
    'calendar_year': '年',
    'calendar_month': '月',
    'calendar_day': '日',
    'calendar_day-of-week': '曜日',
    'calendar_holiday-flg': '祝日フラグ',
    'calendar_name': '日付名称',
    'calendar_country-id': '国名',
    'calendar_final-updater': '最終更新者',
    'calendar_updated-at': '最終更新日時',
    'calendar_update-reason': '更新理由',
    'calendar_deleted-data': '削除済データ',
    'route_bnf-list': 'BNFマスター一覧',
    'route_administrator-company': 'グループ会社マスタ一覧',
    'route_administrator-company': 'グループ会社マスタ',
    'route_administrator-company-list': 'グループ会社マスタ一覧',
    'route_administrator-company-history': 'グループ会社マスタ履歴',
    'route_administrator-company-csv-import': 'グループ会社マスタCSVインポート状況',
    'route_administrator-company-all-export': '全件エクスポート',
    'group-company-seq_id': 'グループ会社マスタID',
    'group-company-group_company_id': 'グループ会社コード',
    'group-company-valid_start_date': '有効開始日',
    'group-company-valid_end_date': '有効終了日',
    'group-company-stravis_code': 'Stravisコード',
    'group-company-company_name_ja': '会社名（日本語）',
    'group-company-company_name_en': '会社名（英語）',
    'group-company-abbreviation': '略称',
    'group-company-company_attribute': '会社属性',
    'group-company-consolidation': '連結・非連結',
    'group-company-unit_class_1': 'ユニット区分1',
    'group-company-unit_class_2': 'ユニット区分2',
    'group-company-management_business_1': '管理事業1',
    'group-company-management_business_2': '管理事業2',
    'group-company-country_id': '国/地域',
    'group-company-accounting_period': '決算期',
    'group-company-public_or_private': '公開/非公開',
    'group-company-group_agreement_flg': 'グループ規約適用',
    'group-company-management_contract_flg': '経営管理契約',
    'group-company-subcontracting_contract_flg': '業務委託契約',
    'group-company-brand_portfolio': 'ブランドポートフォリオ',
    'group-company-holding_company_1': '保有会社1',
    'group-company-investment_ratio_1': '出資比率1',
    'group-company-holding_company_2': '保有会社2',
    'group-company-investment_ratio_2': '出資比率2',
    'group-company-holding_company_3': '保有会社3',
    'group-company-investment_ratio_3': '出資比率3',
    'group-company-holding_company_4': '保有会社4',
    'group-company-investment_ratio_4': '出資比率4',
    'group-company-holding_company_5': '保有会社5',
    'group-company-investment_ratio_5': '出資比率5',
    'group-company-total_investment_ratio': '合計出資比率',
    'group-company-issued_shares_total': '発行済株式総数',
    'group-company-treasury_stock': '自己株',
    'group-company-nominee_shares': '名義貸株式',
    'group-company-capital': '資本金',
    'group-company-currency_id': '現地通貨',
    'group-company-number_of_employees': '従業員数',
    'group-company-establishment_date': '設立日',
    'group-company-representative_director_1_position': '代表取締役1役職名',
    'group-company-representative_director_1_name': '代表取締役1氏名',
    'group-company-representative_director_2_position': '代表取締役2役職名',
    'group-company-representative_director_2_name': '代表取締役2氏名',
    'group-company-representative_director_3_position': '代表取締役3役職名',
    'group-company-representative_director_3_name': '代表取締役3氏名',
    'group-company-business_description': '業務内容',
    'group-company-address_1': '住所1',
    'group-company-address_2': '住所2',
    'group-company-notes': '備考',
    'group-company-ip_address': 'IPアドレス',
    'group-company-delete_flg': '削除フラグ',
    'group-company-equity_method_application': '持分法適用・非適用',
    'group-company-updated_reason': '更新理由',
    'group-company-false-group_agreement_flg': 'グループ規約適用なし',
    'group-company-true-group_agreement_flg': 'グループ規約適用あり',
    'group-company-all-group_agreement_flg': '全データ',
    'group-company-false-management_contract_flg': '経営管理規約なし',
    'group-company-true-management_contract_flg': '経営管理規約あり',
    'group-company-all-management_contract_flg': '全データ',
    'group-company-false-subcontracting_contract_flg': '業務委託契約なし',
    'group-company-true-subcontracting_contract_flg': '業務委託契約あり',
    'group-company-all-subcontracting_contract_flg': '全データ',
    'group-company-delete_flg': '削除済データ',
    'group-company_final-updater': '最終更新者',
  },
};
