export const createTagWithoutRelations = /* GraphQL */ `
  mutation CreateTagWithoutRelations(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTagWithoutRelations = /* GraphQL */ `
  mutation UpdateTagWithoutRelations(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createTagTitleWithoutOhterRelations = /* GraphQL */ `
  mutation CreateTagTitleWithoutOhterRelations(
    $input: CreateTagTitleInput!
    $condition: ModelTagTitleConditionInput
  ) {
    createTagTitle(input: $input, condition: $condition) {
      id
      tag_id
      title_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title {
              id
              seq_id
              name_ja
              name_en
              search_name
              sap_title_code
              bn_connect_title_id
              project_cd
              banasuke_title_id
              ce_link_key_code
              update_reason
              genre_id
              data_source_id
              admin_check_flg
              updated_user_id
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title {
              id
              seq_id
              name_ja
              name_en
              search_name
              sap_title_code
              bn_connect_title_id
              project_cd
              banasuke_title_id
              ce_link_key_code
              update_reason
              genre_id
              data_source_id
              admin_check_flg
              updated_user_id
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTagTitleWithoutOhterRelations = /* GraphQL */ `
  mutation UpdateTagTitleWithoutOhterRelations(
    $input: UpdateTagTitleInput!
    $condition: ModelTagTitleConditionInput
  ) {
    updateTagTitle(input: $input, condition: $condition) {
      id
      tag_id
      title_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title {
              id
              seq_id
              name_ja
              name_en
              search_name
              sap_title_code
              bn_connect_title_id
              project_cd
              banasuke_title_id
              ce_link_key_code
              update_reason
              genre_id
              data_source_id
              admin_check_flg
              updated_user_id
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title {
              id
              seq_id
              name_ja
              name_en
              search_name
              sap_title_code
              bn_connect_title_id
              project_cd
              banasuke_title_id
              ce_link_key_code
              update_reason
              genre_id
              data_source_id
              admin_check_flg
              updated_user_id
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteTagTitleWithoutOhterRelations = /* GraphQL */ `
  mutation DeleteTagTitleWithoutOhterRelations(
    $input: DeleteTagTitleInput!
    $condition: ModelTagTitleConditionInput
  ) {
    deleteTagTitle(input: $input, condition: $condition) {
      id
      tag_id
      title_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        titles {
          items {
            id
            tag_id
            title_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title {
              id
              seq_id
              name_ja
              name_en
              search_name
              sap_title_code
              bn_connect_title_id
              project_cd
              banasuke_title_id
              ce_link_key_code
              update_reason
              genre_id
              data_source_id
              admin_check_flg
              updated_user_id
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      title {
        id
        seq_id
        name_ja
        name_en
        search_name
        sap_title_code
        bn_connect_title_id
        project_cd
        banasuke_title_id
        ce_link_key_code
        update_reason
        genre_id
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tags {
          items {
            id
            tag_id
            title_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title {
              id
              seq_id
              name_ja
              name_en
              search_name
              sap_title_code
              bn_connect_title_id
              project_cd
              banasuke_title_id
              ce_link_key_code
              update_reason
              genre_id
              data_source_id
              admin_check_flg
              updated_user_id
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        admin_check_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createTagTitleCodeWithoutOtherRelations = /* GraphQL */ `
  mutation CreateTagTitleCodeWithoutOtherRelations(
    $input: CreateTagTitleCodeInput!
    $condition: ModelTagTitleCodeConditionInput
  ) {
    createTagTitleCode(input: $input, condition: $condition) {
      id
      tag_id
      title_code_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title_code {
              id
              seq_id
              title_code
              name_ja
              name_en
              official_name_flg
              sap_title_code_name
              search_name
              project_cd
              wbs
              bn_connect_title_ids
              ce_link_key_code
              ce_link_title_name_ja
              ce_link_title_name_en
              genre_ids
              banasuke_title_id
              banasuke_title_name_ja
              bundle_source_title_code_ids
              porting_source_title_code_ids
              business_entity_id
              platform_ids
              data_source_id
              valid_flg
              update_reason
              system_update_flg
              admin_check_flg
              delete_flg
              deleted_at
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title_code {
              id
              seq_id
              title_code
              name_ja
              name_en
              official_name_flg
              sap_title_code_name
              search_name
              project_cd
              wbs
              bn_connect_title_ids
              ce_link_key_code
              ce_link_title_name_ja
              ce_link_title_name_en
              genre_ids
              banasuke_title_id
              banasuke_title_name_ja
              bundle_source_title_code_ids
              porting_source_title_code_ids
              business_entity_id
              platform_ids
              data_source_id
              valid_flg
              update_reason
              system_update_flg
              admin_check_flg
              delete_flg
              deleted_at
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTagTitleCodeWithoutOtherRelations = /* GraphQL */ `
  mutation UpdateTagTitleCodeWithoutOtherRelations(
    $input: UpdateTagTitleCodeInput!
    $condition: ModelTagTitleCodeConditionInput
  ) {
    updateTagTitleCode(input: $input, condition: $condition) {
      id
      tag_id
      title_code_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title_code {
              id
              seq_id
              title_code
              name_ja
              name_en
              official_name_flg
              sap_title_code_name
              search_name
              project_cd
              wbs
              bn_connect_title_ids
              ce_link_key_code
              ce_link_title_name_ja
              ce_link_title_name_en
              genre_ids
              banasuke_title_id
              banasuke_title_name_ja
              bundle_source_title_code_ids
              porting_source_title_code_ids
              business_entity_id
              platform_ids
              data_source_id
              valid_flg
              update_reason
              system_update_flg
              admin_check_flg
              delete_flg
              deleted_at
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title_code {
              id
              seq_id
              title_code
              name_ja
              name_en
              official_name_flg
              sap_title_code_name
              search_name
              project_cd
              wbs
              bn_connect_title_ids
              ce_link_key_code
              ce_link_title_name_ja
              ce_link_title_name_en
              genre_ids
              banasuke_title_id
              banasuke_title_name_ja
              bundle_source_title_code_ids
              porting_source_title_code_ids
              business_entity_id
              platform_ids
              data_source_id
              valid_flg
              update_reason
              system_update_flg
              admin_check_flg
              delete_flg
              deleted_at
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteTagTitleCodeWithoutOtherRelations = /* GraphQL */ `
  mutation DeleteTagTitleCodeWithoutOtherRelations(
    $input: DeleteTagTitleCodeInput!
    $condition: ModelTagTitleCodeConditionInput
  ) {
    deleteTagTitleCode(input: $input, condition: $condition) {
      id
      tag_id
      title_code_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        title_codes {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title_code {
              id
              seq_id
              title_code
              name_ja
              name_en
              official_name_flg
              sap_title_code_name
              search_name
              project_cd
              wbs
              bn_connect_title_ids
              ce_link_key_code
              ce_link_title_name_ja
              ce_link_title_name_en
              genre_ids
              banasuke_title_id
              banasuke_title_name_ja
              bundle_source_title_code_ids
              porting_source_title_code_ids
              business_entity_id
              platform_ids
              data_source_id
              valid_flg
              update_reason
              system_update_flg
              admin_check_flg
              delete_flg
              deleted_at
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      title_code {
        id
        seq_id
        title_code
        name_ja
        name_en
        official_name_flg
        sap_title_code_name
        search_name
        project_cd
        wbs
        bn_connect_title_ids
        ce_link_key_code
        ce_link_title_name_ja
        ce_link_title_name_en
        genre_ids
        banasuke_title_id
        banasuke_title_name_ja
        bundle_source_title_code_ids
        porting_source_title_code_ids
        business_entity_id
        business_entity {
          id
          seq_id
          name
          description
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        platform_ids
        data_source_id
        data_source {
          id
          seq_id
          name
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tags {
          items {
            id
            tag_id
            title_code_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            title_code {
              id
              seq_id
              title_code
              name_ja
              name_en
              official_name_flg
              sap_title_code_name
              search_name
              project_cd
              wbs
              bn_connect_title_ids
              ce_link_key_code
              ce_link_title_name_ja
              ce_link_title_name_en
              genre_ids
              banasuke_title_id
              banasuke_title_name_ja
              bundle_source_title_code_ids
              porting_source_title_code_ids
              business_entity_id
              platform_ids
              data_source_id
              valid_flg
              update_reason
              system_update_flg
              admin_check_flg
              delete_flg
              deleted_at
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        valid_flg
        update_reason
        system_update_flg
        admin_check_flg
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createTagBspWithoutOtherRelations = /* GraphQL */ `
  mutation CreateTagBspWithoutOtherRelations(
    $input: CreateTagBspInput!
    $condition: ModelTagBspConditionInput
  ) {
    createTagBsp(input: $input, condition: $condition) {
      id
      tag_id
      bsp_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bsp {
              id
              seq_id
              character_name
              character_code
              character_series_name
              character_series_code
              updated_user_id
              update_reason
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bsp {
        id
        seq_id
        character_name
        character_code
        character_series_name
        character_series_code
        tags {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bsp {
              id
              seq_id
              character_name
              character_code
              character_series_name
              character_series_code
              updated_user_id
              update_reason
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTagBspWithoutOtherRelations = /* GraphQL */ `
  mutation UpdateTagBspWithoutOtherRelations(
    $input: UpdateTagBspInput!
    $condition: ModelTagBspConditionInput
  ) {
    updateTagBsp(input: $input, condition: $condition) {
      id
      tag_id
      bsp_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bsp {
              id
              seq_id
              character_name
              character_code
              character_series_name
              character_series_code
              updated_user_id
              update_reason
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bsp {
        id
        seq_id
        character_name
        character_code
        character_series_name
        character_series_code
        tags {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bsp {
              id
              seq_id
              character_name
              character_code
              character_series_name
              character_series_code
              updated_user_id
              update_reason
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteTagBspWithoutOtherRelations = /* GraphQL */ `
  mutation DeleteTagBspWithoutOtherRelations(
    $input: DeleteTagBspInput!
    $condition: ModelTagBspConditionInput
  ) {
    deleteTagBsp(input: $input, condition: $condition) {
      id
      tag_id
      bsp_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bsps {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bsp {
              id
              seq_id
              character_name
              character_code
              character_series_name
              character_series_code
              updated_user_id
              update_reason
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bsp {
        id
        seq_id
        character_name
        character_code
        character_series_name
        character_series_code
        tags {
          items {
            id
            tag_id
            bsp_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bsp {
              id
              seq_id
              character_name
              character_code
              character_series_name
              character_series_code
              updated_user_id
              update_reason
              delete_flg
              system_update_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        delete_flg
        system_update_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createTagBnmlWithoutOtherRelations = /* GraphQL */ `
  mutation CreateTagBnmlWithoutOtherRelations(
    $input: CreateTagBnmlInput!
    $condition: ModelTagBnmlConditionInput
  ) {
    createTagBnml(input: $input, condition: $condition) {
      id
      tag_id
      bnml_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnml {
              id
              seq_id
              mlics_plan_cd_4char
              mlics_plan_cd_7char
              mlics_commodity_name
              update_reason
              updated_user_id
              delete_flg
              admin_check_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnml {
        id
        seq_id
        mlics_plan_cd_4char
        mlics_plan_cd_7char
        mlics_commodity_name
        tags {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnml {
              id
              seq_id
              mlics_plan_cd_4char
              mlics_plan_cd_7char
              mlics_commodity_name
              update_reason
              updated_user_id
              delete_flg
              admin_check_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        update_reason
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        delete_flg
        admin_check_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTagBnmlWithoutOtherRelations = /* GraphQL */ `
  mutation UpdateTagBnmlWithoutOtherRelations(
    $input: UpdateTagBnmlInput!
    $condition: ModelTagBnmlConditionInput
  ) {
    updateTagBnml(input: $input, condition: $condition) {
      id
      tag_id
      bnml_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnml {
              id
              seq_id
              mlics_plan_cd_4char
              mlics_plan_cd_7char
              mlics_commodity_name
              update_reason
              updated_user_id
              delete_flg
              admin_check_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnml {
        id
        seq_id
        mlics_plan_cd_4char
        mlics_plan_cd_7char
        mlics_commodity_name
        tags {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnml {
              id
              seq_id
              mlics_plan_cd_4char
              mlics_plan_cd_7char
              mlics_commodity_name
              update_reason
              updated_user_id
              delete_flg
              admin_check_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        update_reason
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        delete_flg
        admin_check_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteTagBnmlWithoutOtherRelations = /* GraphQL */ `
  mutation DeleteTagBnmlWithoutOtherRelations(
    $input: DeleteTagBnmlInput!
    $condition: ModelTagBnmlConditionInput
  ) {
    deleteTagBnml(input: $input, condition: $condition) {
      id
      tag_id
      bnml_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnmls {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnml {
              id
              seq_id
              mlics_plan_cd_4char
              mlics_plan_cd_7char
              mlics_commodity_name
              update_reason
              updated_user_id
              delete_flg
              admin_check_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnml {
        id
        seq_id
        mlics_plan_cd_4char
        mlics_plan_cd_7char
        mlics_commodity_name
        tags {
          items {
            id
            tag_id
            bnml_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnml {
              id
              seq_id
              mlics_plan_cd_4char
              mlics_plan_cd_7char
              mlics_commodity_name
              update_reason
              updated_user_id
              delete_flg
              admin_check_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        update_reason
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        delete_flg
        admin_check_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createTagBnamWithoutOtherRelations = /* GraphQL */ `
  mutation CreateTagBnamWithoutOtherRelations(
    $input: CreateTagBnamInput!
    $condition: ModelTagBnamConditionInput
  ) {
    createTagBnam(input: $input, condition: $condition) {
      id
      tag_id
      bnam_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnam {
              id
              seq_id
              ip_name
              updated_user_id
              update_reason
              delete_flg
              deleted_at
              admin_check_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnam {
        id
        seq_id
        ip_name
        tags {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnam {
              id
              seq_id
              ip_name
              updated_user_id
              update_reason
              delete_flg
              deleted_at
              admin_check_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        delete_flg
        deleted_at
        admin_check_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTagBnamWithoutOtherRelations = /* GraphQL */ `
  mutation UpdateTagBnamWithoutOtherRelations(
    $input: UpdateTagBnamInput!
    $condition: ModelTagBnamConditionInput
  ) {
    updateTagBnam(input: $input, condition: $condition) {
      id
      tag_id
      bnam_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnam {
              id
              seq_id
              ip_name
              updated_user_id
              update_reason
              delete_flg
              deleted_at
              admin_check_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnam {
        id
        seq_id
        ip_name
        tags {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnam {
              id
              seq_id
              ip_name
              updated_user_id
              update_reason
              delete_flg
              deleted_at
              admin_check_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        delete_flg
        deleted_at
        admin_check_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteTagBnamWithoutOtherRelations = /* GraphQL */ `
  mutation DeleteTagBnamWithoutOtherRelations(
    $input: DeleteTagBnamInput!
    $condition: ModelTagBnamConditionInput
  ) {
    deleteTagBnam(input: $input, condition: $condition) {
      id
      tag_id
      bnam_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnams {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnam {
              id
              seq_id
              ip_name
              updated_user_id
              update_reason
              delete_flg
              deleted_at
              admin_check_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnam {
        id
        seq_id
        ip_name
        tags {
          items {
            id
            tag_id
            bnam_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnam {
              id
              seq_id
              ip_name
              updated_user_id
              update_reason
              delete_flg
              deleted_at
              admin_check_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        delete_flg
        deleted_at
        admin_check_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createTagBnfWithoutOtherRelations = /* GraphQL */ `
  mutation CreateTagBnfWithoutOtherRelations(
    $input: CreateTagBnfInput!
    $condition: ModelTagBnfConditionInput
  ) {
    createTagBnf(input: $input, condition: $condition) {
      id
      tag_id
      bnf_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnf {
              id
              seq_id
              bnf_data_source
              obic7_commodity_cd
              obic7_commodity_name
              bvics_plan_cd_4char
              bvics_plan_cd_7char
              bvics_product_name
              admin_check_flg
              delete_flg
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnf {
        id
        seq_id
        tags {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnf {
              id
              seq_id
              bnf_data_source
              obic7_commodity_cd
              obic7_commodity_name
              bvics_plan_cd_4char
              bvics_plan_cd_7char
              bvics_product_name
              admin_check_flg
              delete_flg
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        bnf_data_source
        obic7_commodity_cd
        obic7_commodity_name
        bvics_plan_cd_4char
        bvics_plan_cd_7char
        bvics_product_name
        admin_check_flg
        delete_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTagBnfWithoutOtherRelations = /* GraphQL */ `
  mutation UpdateTagBnfWithoutOtherRelations(
    $input: UpdateTagBnfInput!
    $condition: ModelTagBnfConditionInput
  ) {
    updateTagBnf(input: $input, condition: $condition) {
      id
      tag_id
      bnf_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnf {
              id
              seq_id
              bnf_data_source
              obic7_commodity_cd
              obic7_commodity_name
              bvics_plan_cd_4char
              bvics_plan_cd_7char
              bvics_product_name
              admin_check_flg
              delete_flg
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnf {
        id
        seq_id
        tags {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnf {
              id
              seq_id
              bnf_data_source
              obic7_commodity_cd
              obic7_commodity_name
              bvics_plan_cd_4char
              bvics_plan_cd_7char
              bvics_product_name
              admin_check_flg
              delete_flg
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        bnf_data_source
        obic7_commodity_cd
        obic7_commodity_name
        bvics_plan_cd_4char
        bvics_plan_cd_7char
        bvics_product_name
        admin_check_flg
        delete_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteTagBnfWithoutOtherRelations = /* GraphQL */ `
  mutation DeleteTagBnfWithoutOtherRelations(
    $input: DeleteTagBnfInput!
    $condition: ModelTagBnfConditionInput
  ) {
    deleteTagBnf(input: $input, condition: $condition) {
      id
      tag_id
      bnf_id
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        seq_id
        name_ja
        name_en
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bnfs {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnf {
              id
              seq_id
              bnf_data_source
              obic7_commodity_cd
              obic7_commodity_name
              bvics_plan_cd_4char
              bvics_plan_cd_7char
              bvics_product_name
              admin_check_flg
              delete_flg
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bnf {
        id
        seq_id
        tags {
          items {
            id
            tag_id
            bnf_id
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            tag {
              id
              seq_id
              name_ja
              name_en
              updated_user_id
              delete_flg
              deleted_at
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            bnf {
              id
              seq_id
              bnf_data_source
              obic7_commodity_cd
              obic7_commodity_name
              bvics_plan_cd_4char
              bvics_plan_cd_7char
              bvics_product_name
              admin_check_flg
              delete_flg
              updated_user_id
              update_reason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        bnf_data_source
        obic7_commodity_cd
        obic7_commodity_name
        bvics_plan_cd_4char
        bvics_plan_cd_7char
        bvics_product_name
        admin_check_flg
        delete_flg
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
