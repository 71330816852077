export const SEARCH_TAG_HISTORIES_FIELDS = [
  {
    field: "operation_type",
    label: "tag-history_operation-category",
    type: "select",
    placeholder: "操作種別を選択",
  },
  {
    field: "seq_id",
    label: "tag-history_tag-id",
    type: "text",
    placeholder: "タグIDを入力",
  },
  {
    field: "name_ja",
    label: "tag-history_ip-tag-japanese",
    type: "text",
    placeholder: "IPタグ(日本語)を入力",
  },
  {
    field: "name_en",
    label: "tag-history_ip-tag-english",
    type: "text",
    placeholder: "IPタグ(英語)を入力",
  },
  {
    field: "updated_user_id",
    label: "tag-history_final-updater",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "tag-history_final-update-date" },
      { field: "to", label: "tag-history_final-update-date" },
    ],
  },
];

export const SEARCH_TAG_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  seq_id: "",
  name_ja: "",
  name_en: "",
  updated_user_id: "",
  from: null,
  to: null,
};
