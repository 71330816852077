import React from 'react';
import { FormControl, InputLabel, MenuItem, Box, Typography, FormHelperText, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import { I18n } from 'aws-amplify/utils';

import { Genre, Currency, DataSource, Platform, ProductClass, ProductType, Country, GroupCompany } from '../../models';
import { theme } from '../../assets/theme/options';
import { validationRules } from '../../types';
import createValidationRules from '../../hooks/createValidationRules';
import RequiredLabel from './RequiredLabel';
interface model {
  id: string | boolean;
  name: string;
}

interface FormSelectBoxProps {
  /** ラベル */
  label: string;
  /** 内容 */
  content:
    | model[]
    | Genre[]
    | DataSource[]
    | Platform[]
    | Currency[]
    | ProductClass[]
    | ProductType[]
    | Country[]
    | GroupCompany[];
  /** インプット内容 */
  defaultValue?: string;
  /** セレクトボックスに表示する内容 */
  selectKey?: 'code_three_char' | 'name';
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** placeholder */
  placeholder?: string;
  /** ReactFookForm */
  control: any;
  errors?: any;
  /** バリデーションの内容 */
  validationRules?: validationRules[] | string[];
  /** 必須ラベルの有無 */
  required?: boolean;
  /** 空白オプションの有無 */
  isBlank?: boolean;
}

function FormSelectBox(props: FormSelectBoxProps) {
  const { isBlank = true } = props;

  return (
    <Box position={'relative'}>
      {props.required && <RequiredLabel />}
      <InputLabel sx={{ fontSize: '12px', marginBottom: '2px' }}>{I18n.get(props.label)}</InputLabel>
      <FormControl fullWidth size="small">
        <Controller
          name={props.field}
          control={props.control}
          defaultValue={props.defaultValue || ''}
          rules={props.validationRules ? createValidationRules(props.validationRules) : undefined}
          render={({ field }) => (
            <React.Fragment>
              <Select
                {...field}
                error={props.errors && !!props.errors[props.field]}
                disabled={props.disabled}
                displayEmpty
                renderValue={
                  field.value || typeof field.value === 'boolean'
                    ? undefined
                    : () => <Typography color={theme.colors.placeholder}>{props.placeholder}</Typography>
                }
                sx={{ background: props.disabled ? theme.colors.disabled : '' }}
              >
                {isBlank && <MenuItem value="">　</MenuItem>}
                {props.content.map((option: any, idx) => (
                  <MenuItem key={idx} value={option.id as any}>
                    {I18n.get(option[props.selectKey || 'name'])}
                  </MenuItem>
                ))}
              </Select>
              {props.errors?.[props.field]?.message && (
                <FormHelperText error>{props.errors[props.field].message}</FormHelperText>
              )}
            </React.Fragment>
          )}
        />
      </FormControl>
    </Box>
  );
}

export default FormSelectBox;
