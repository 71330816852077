import React, { useContext, useEffect, useState, memo } from 'react';
import { SQS } from 'aws-sdk';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import PrimaryButton from '../components/PrimaryButton';

import { onCreateDataLakeJob, onUpdateDataLakeJob } from '../graphql/subscriptions';
import { searchDataLakeJobs } from '../graphql/queries';
import { createDataLakeJob } from '../graphql/mutations';
import { AuthContext } from '../contexts/AuthContext';
import { DataLakeJob } from '../models';
import { generateSearchFilter } from '../utils/search';
import { useCredentials } from '../hooks/useCredentials';
import awsOptions from '../aws-options';
import awsExports from '../aws-exports';
import { Box, Stack } from '@mui/material';
import StepBar from './StepBar';
import { useDataLakeJob } from '../hooks/useDataLakeJob';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ConfirmDialog from './ConfirmDialog';
import { SearchableDataLakeJobSortInput } from '../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type JobStatus = 'DOING' | 'DONE' | 'ERROR';

const steps = [
  '待機中', // STANDBY
  '処理中', // DOING
  '完了' // DONE
];

function ExecuteDatalakeButton() {
  // const [job, setJob] = useState<DataLakeJob | null>({
  //   id: "1",
  //   operation_type: "manual",
  //   status: "DONE",
  //   message: "eee",
  //   error_log: "string",
  //   createdAt: "string",
  //   updatedAt: "string",
  // });

  const [job, setJob] = useState<DataLakeJob | null>(null);
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false); // 確認ダイアログの表示/非表示

  useEffect(() => {
    useCredentials();
    subscribeOnCreateJob();
    subscribeOnUpdateJob();
    firstSetJob();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    job && setStep((prev) => changeStep(job.status as JobStatus, prev));
    job?.status === 'ERROR' && alertErrorAndResetJob(job?.message ?? '');
  }, [job]);

  const changeStep = (status: JobStatus, currentStep: number): number => {
    switch (status) {
      case 'DOING':
        return 1;
      case 'DONE':
        return 2;
      case 'ERROR':
        return currentStep;
      default:
        const _: never = status;
    }
  };

  const firstSetJob = async () => {
    const lastDoignJob = await fetchLastDoingJob();
    console.log(lastDoignJob);
    setJob(lastDoignJob);
  };

  const fetchLastDoingJob = async () => {
    try {
      const models: any = await API.graphql({
        query: searchDataLakeJobs,
        variables: {
          filter: { status: { match: 'DOING' } },
          //@ts-ignore
          sort: { field: 'createdAt', direction: 'desc' },
          limit: 1,
          nextToken: null
        }
      });
      console.log(models);
      if (models.data.searchDataLakeJobs.items.length == 0) {
        return null;
      } else {
        return models.data.searchDataLakeJobs.items[0];
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const subscribeOnCreateJob = () => {
    const client = API.graphql({ query: onCreateDataLakeJob });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const created: DataLakeJob = data.onCreateDataLakeJob;
          console.log(created);
          console.log(job);
          setJob(created);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnUpdateJob = () => {
    const client = API.graphql({ query: onUpdateDataLakeJob });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const updated: DataLakeJob = data.onUpdateDataLakeJob;
          console.log(updated);
          console.log(job);
          // setJob((prev) => prev?.id === updated.id ? updated : prev)
          setJob(updated);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const alertErrorAndResetJob = (message: string) => {
    alert(`エラー:\n${message ? message : '不明なエラーが発生しました'}`);
    setJob(null);
  };

  const handleClickDatalake = async () => {
    // 1. 確認モーダルを閉じる
    handleClose();
    // 2. DOINGのカラムをチェック
    const lastDoingJob = await fetchLastDoingJob();
    if (lastDoingJob != null) {
      alert('現在、他のユーザーがデータレイクを実行中です。そちらが完了してから再度実行してください。');
      setJob(lastDoingJob);
      return;
    }
    // 3. jobの作成
    const newJob = await newDataLakeJob();
    setJob(newJob);
    console.log(newJob);
    console.log(job);
    // 4. キューの送信
    newJob && sendMessage(newJob.id);
  };

  const newDataLakeJob = async () => {
    try {
      const record = {
        operation_type: 'manual',
        status: 'DOING'
      };
      const model: any = await API.graphql({ query: createDataLakeJob, variables: { input: record } });
      const newJob = (model.data as any).createDataLakeJob;
      return newJob;
    } catch (e) {
      console.log(e);
    }
  };

  const sendMessage = async (id: string) => {
    try {
      const sqs = new SQS();
      const { QueueUrl } = await sqs
        .getQueueUrl({
          QueueName: 'datalake-' + awsOptions.amplify_backend_name
        })
        .promise();
      if (!QueueUrl) {
        alertErrorAndResetJob('リクエスト送信準備エラー\n担当者にお問い合わせください');
        return;
      }
      const message = {
        id: id,
        operation_type: 'manual'
      };
      const MessageBody = JSON.stringify({ message });
      const result = await sqs.sendMessage({ MessageBody, QueueUrl }).promise();
      console.log({ result });
    } catch (e) {
      console.error(e);
      alertErrorAndResetJob('リクエスト送信エラー\n担当者にお問い合わせください');
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <PrimaryButton onClick={handleOpen} disabled={job?.status == 'DOING'}>
          実行
        </PrimaryButton>
        <Box sx={{ width: 360, height: 40, p: 1 }}>
          <StepBar steps={steps} activeStep={step} completed={job?.status === 'DONE'} error={job?.status === 'ERROR'} />
        </Box>
      </Stack>
      <ConfirmDialog
        message={'データレイクの手動連携をします。よろしいですか？'}
        open={open}
        onCancel={handleClose}
        onContinue={handleClickDatalake}
      />
    </>
  );
}

export default memo(ExecuteDatalakeButton);
