import { useState } from 'react';
import usePageSize from './usePageSize';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import { ProductTcdHistory } from '../API';
import { SEARCH_PRODUCT_TCD_HISTORY_FIELDS_DEFAULT_VALUES } from '../consts/productTcdHistory';
import { searchProductTcdHistories } from '../graphql/queries';
import { ProductTcdHistorySearch } from '../types/form/productTcdHistory';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = [{ direction: 'desc', field: 'updatedAt' }];

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

const dataToFilterObj: StringKeyObj = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.EQ },
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  product_key: { name: 'product_key', type: SEARCH_TYPE.WILDCARD },
  name: { name: 'name', type: SEARCH_TYPE.WILDCARD },
  common_name: { name: 'common_name', type: SEARCH_TYPE.WILDCARD },
  title_code_name: { name: 'title_code_name', type: SEARCH_TYPE.WILDCARD },
  product_type_id: { name: 'product_type_id', type: SEARCH_TYPE.EQ },
  product_class_id: { name: 'product_class_id', type: SEARCH_TYPE.EQ },
  market: { name: 'market', type: SEARCH_TYPE.WILDCARD },
  platform_id: { name: 'platform_id', type: SEARCH_TYPE.EQ },
  data_source_id: { name: 'data_source_id', type: SEARCH_TYPE.EQ },
  base_price_from: { name: 'base_price', type: SEARCH_TYPE.GTE },
  base_price_to: { name: 'base_price', type: SEARCH_TYPE.LTE },
  currency_id: { name: 'currency_id', type: SEARCH_TYPE.EQ },
  release_status: { name: 'release_status', type: SEARCH_TYPE.WILDCARD },
  info_release_date_from: { name: 'info_release_date', type: SEARCH_TYPE.GTE },
  info_release_date_to: { name: 'info_release_date', type: SEARCH_TYPE.LTE },
  preorder_start_date_from: { name: 'preorder_start_date', type: SEARCH_TYPE.GTE },
  preorder_start_date_to: { name: 'preorder_start_date', type: SEARCH_TYPE.LTE },
  release_date_from: { name: 'release_date', type: SEARCH_TYPE.GTE },
  release_date_to: { name: 'release_date', type: SEARCH_TYPE.LTE },
  service_start_date_from: { name: 'service_start_date', type: SEARCH_TYPE.GTE },
  service_start_date_to: { name: 'service_start_date', type: SEARCH_TYPE.LTE },
  service_end_date_from: { name: 'service_end_date', type: SEARCH_TYPE.GTE },
  service_end_date_to: { name: 'service_end_date', type: SEARCH_TYPE.LTE },
  support_end_date_from: { name: 'support_end_date', type: SEARCH_TYPE.GTE },
  support_end_date_to: { name: 'support_end_date', type: SEARCH_TYPE.LTE },
  sku_number_apple: { name: 'sku_number_apple', type: SEARCH_TYPE.WILDCARD },
  app_id: { name: 'app_id', type: SEARCH_TYPE.WILDCARD },
  package_name_google: { name: 'package_name_google', type: SEARCH_TYPE.WILDCARD },
  bank_app_id: { name: 'bank_app_id', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
  system_update_flg: { name: 'system_update_flg', type: SEARCH_TYPE.EQ },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

export const useSearchProductTcdHistories = () => {
  const [productTcdHistories, setProductTcdHistories] = useState<ProductTcdHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>(defaultSort);
  const [firstFetch, setFirstFetch] = useState(true);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<ProductTcdHistorySearch>(SEARCH_PRODUCT_TCD_HISTORY_FIELDS_DEFAULT_VALUES);

  const fetchProductTcdHistories = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: ProductTcdHistorySearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const queries = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          if (value === 'ALL' && key === 'admin_check_flg') return;
          if (value === 'ALL' && key === 'system_update_flg') return;
          if (value === 'ALL' && key === 'delete_flg') return;
          return {
            name: dataToFilterObj[key].name,
            value: value,
            type: dataToFilterObj[key].type,
          };
        })
        .filter(Boolean);

      const filter = generateSearchFilter(queries);

      const defaultFilter = { admin_check_flg: { eq: true } };

      const searchCondition = firstFetch
        ? {
            filter: defaultFilter,
            sort: newSort ?? sort,
            limit: newLimit ?? pageSize,
            nextToken: nextToken ?? null,
          }
        : {
            ...filter,
            sort: newSort ?? sort,
            limit: newLimit ?? pageSize,
            nextToken: nextToken ?? null,
          };

      const models: any = await API.graphql({ query: searchProductTcdHistories, variables: searchCondition });

      setTotal(models.data.searchProductTcdHistories.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchProductTcdHistories.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchProductTcdHistories.nextToken])));
      }
      newCondition && setCondition({ ...newCondition });
      setFirstFetch(false);
      setProductTcdHistories([...models.data.searchProductTcdHistories.items]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchProductTcdHistories({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchProductTcdHistories({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchProductTcdHistories({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchProductTcdHistories({ nextToken });
  };

  return {
    fetchProductTcdHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    productTcdHistories,
    setProductTcdHistories,
    onChangePage,
    page,
    onChangeSort,
    condition,
  };
};
