import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormSelectBox from '../forms/FormSelectBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import { OPERATION_TYPE_OPTIONS } from '../../consts/common'
import { classificationValidateTagBnfHistory } from '../../hooks/classification';
import { TagBnfHistorySearch } from '../../types/form/tagBnfHistory';
import { SEARCH_TAG_BNF_HISTORY_FIELDS, SEARCH_TAG_BNF_HISTORY_FIELDS_DEFAULT_VALUES } from '../../consts/tagBnfHistory';


type Props = {
  condition: TagBnfHistorySearch;
  isAdmin: boolean | undefined
  open: boolean;
  setOpen: Function;
  fetchTagBnfHistories: Function;
}

function SearchTagBnfHistoryDialog(props: Props) {
  const {
    condition,
    isAdmin,
    open,
    setOpen,
    fetchTagBnfHistories
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_TAG_BNF_HISTORY_FIELDS_DEFAULT_VALUES });

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<TagBnfHistorySearch> = async (newCondition) => {
    try {
      await fetchTagBnfHistories({ newCondition })
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <SearchDialog open={open} onCancel={onClose} onSubmit={handleSubmit(onSubmit)}
      height={600}
      width="md"
      onReset={reset}>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 360 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 600,
        }}
        width={750}
      >
        {SEARCH_TAG_BNF_HISTORY_FIELDS.map((row, idx) => (
          <Box key={idx}>
            {row.type === 'select' && row.optionsName && (
              <FormSelectBox
                label={row.label}
                content={OPERATION_TYPE_OPTIONS}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
              />
            )}
            {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={`${row.field}_${row.inputs[0].field}`}
                  toLabel={row.inputs[1].label}
                  toField={`${row.field}_${row.inputs[1].field}`}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {((row.type === 'text' || row.type === 'textarea') && !row.inputs) && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateTagBnfHistory(row.field as keyof TagBnfHistorySearch)}
              />
            )}
            {(row.type === 'text' && row.inputs) && (
              <Box sx={{ display: 'flex' }}>
                <FormTextField
                  label={row.inputs[0].label}
                  placeholder={row.placeholder}
                  field={`${row.field}_${row.inputs[0].field}`}
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateTagBnfHistory(row.field as keyof TagBnfHistorySearch)}
                />
                <Box pt={3} px={2}>〜</Box>
                <FormTextField
                  label={row.inputs[1].label}
                  placeholder={row.placeholder}
                  field={`${row.field}_${row.inputs[1].field}`}
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateTagBnfHistory(row.field as keyof TagBnfHistorySearch)}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchTagBnfHistoryDialog;