import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormSelectBox from '../forms/FormSelectBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import {
  OPERATION_TYPE_OPTIONS,
  SYSTEM_UPDATE_OPTIONS,
  DELETE_FLG_OPTIONS
} from '../../consts/common'
import { BspFields } from '../../types';
import { classificationValidateBsp } from '../../hooks/classification';
import { BspSearch } from '../../types/form/bsp';
import { SEARCH_BSP_FIELDS, SEARCH_BSP_ADMIN_FIELDS, SEARCH_BSP_FIELDS_DEFAULT_VALUES } from '../../consts/bsp';

type Props = {
  condition: BspSearch;
  isAdmin: boolean;
  open: boolean;
  setOpen: Function;
  fetchBsps: Function;
}

function SearchProductDialog(props: Props) {
  const {
    condition,
    isAdmin,
    open,
    setOpen,
    fetchBsps
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_BSP_FIELDS_DEFAULT_VALUES });
  const fields = isAdmin ? SEARCH_BSP_ADMIN_FIELDS : SEARCH_BSP_FIELDS;

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit: SubmitHandler<BspSearch> = async (newCondition) => {
    await fetchBsps({ newCondition })
    setOpen(false)
  };

  const options = (name: string) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      case 'system_update_flg':
        return SYSTEM_UPDATE_OPTIONS;
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <SearchDialog
      open={open}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      height={600}
      width="lg"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 300 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 530,
        }}
        width={980}
      >
        {fields.map((row, idx) => (
          <Box key={idx}>
            {row.type === 'select' && row.optionsName && (
              <FormSelectBox
                label={row.label}
                content={options(row.optionsName)}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
                isBlank={row.isBlank}
              />
            )}
            {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={`${row.field}_${row.inputs[0].field}`}
                  toLabel={row.inputs[1].label}
                  toField={`${row.field}_${row.inputs[1].field}`}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {((row.type === 'text' || row.type === 'textarea') && !row.inputs) && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateBsp(row.field as BspFields, 'search')}
              />
            )}
            {(row.type === 'text' && row.inputs) && (
              <Box sx={{ display: 'flex' }}>
                <FormTextField
                  label={row.inputs[0].label}
                  placeholder={row.placeholder}
                  field={`${row.field}_${row.inputs[0].field}`}
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateBsp(row.field as BspFields, 'search')}
                />
                <Box pt={3} px={2}>〜</Box>
                <FormTextField
                  label={row.inputs[1].label}
                  placeholder={row.placeholder}
                  field={`${row.field}_${row.inputs[1].field}`}
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateBsp(row.field as BspFields, 'search')}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchProductDialog;
