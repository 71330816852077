import React from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import { User, BnfHistory } from '../../models';
import { BNF_DATA_SOURCES, OPERATION_TYPE_OPTIONS } from '../../consts/common'
import { useEditedColumn } from '../../hooks/useEditedColumnClass';
import { DETAIL_BNF_HISTORY_FIELDS } from '../../consts/bnfHistory';

type Props = {
  open: boolean;
  row: BnfHistory;
  setRow: Function;
  user: User;
}

function DetaiBnfHistoryDialog(props: Props) {
  const {
    open,
    row,
    setRow,
  } = props;
  const {
    control,
    formState: { errors },
  } = useForm();

  const onClose = () => setRow(null);

  const options = (name: string | undefined) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      case "bnf_data_source":
        return BNF_DATA_SOURCES
      default:
        return [];
    }
  }

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BNFマスター履歴の詳細"
      doText=""
      cancelText="キャンセル"
      height={600}
      width="md"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 300 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 530,
        }}
        width={700}
      >
        {DETAIL_BNF_HISTORY_FIELDS.map((__row, idx) => {
          return (
            <React.Fragment key={idx}>
              {(__row.type === 'text' || __row.type === 'textarea') && (
                <FormTextField
                  label={__row.label}
                  field={__row.field}
                  control={control}
                  disabled={__row.disabled}
                  errors={errors}
                  validationRules={[]}
                  value={row[__row.field]}
                  isTextArea={__row.type === 'textarea'}
                  isEdited={useEditedColumn(row, __row.field)}
                />
              )}
              {(__row.type === 'datetime' || __row.type === 'date') && (
                <FormDateTimePicker
                  label={__row.label}
                  defaultValue={row[__row.field]}
                  type={__row.type}
                  disabled={__row.disabled}
                  field={__row.field}
                  control={control}
                  isEdited={useEditedColumn(row, __row.field)}
                />
              )}
              {__row.type === 'select' && __row.optionsName && (
                <FormSelectBox
                  label={__row.label}
                  content={options(__row.optionsName)}
                  defaultValue={row[__row.field]}
                  field={__row.field}
                  control={control}
                  disabled={__row.disabled}
                />
              )}
              {__row.type === 'checkbox' && (
                <FormCheckBox
                  field={__row.field}
                  label={__row.label}
                  control={control}
                  defaultValue={row[__row.field]}
                  disabled={__row.disabled}
                />
              )}
            </React.Fragment>
          )
        })}
      </Box>
    </FormDialog>
  );
}

export default DetaiBnfHistoryDialog;
