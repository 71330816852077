import React from 'react';
import { TextField, Box, InputLabel } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';

import { theme } from '../../assets/theme/options';
import { validationRules } from '../../types';
import createValidationRules from '../../hooks/createValidationRules';
import RequiredLabel from './RequiredLabel';
import PrimaryButton from '../../components/PrimaryButton';
import { I18n } from 'aws-amplify/utils';
interface FormArrayTextFieldProps {
  /** ラベル */
  label: string;
  /** placeholder */
  placeholder?: string;
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** ReactFookForm */
  control: any;
  errors: any;
  /** バリデーションの内容 */
  validationRules: validationRules[] | string[];
  /** 必須ラベルの有無 */
  required?: boolean;
  /** PrimaryButtonを表示させない */
  notShowButton?: boolean;
}

function FormArrayTextField(props: FormArrayTextFieldProps) {
  const {
    label,
    placeholder,
    disabled,
    field: fieldName,
    control,
    errors,
    validationRules,
    required,
    notShowButton,
  } = props;
  const { fields, append, remove } = useFieldArray({ control, name: fieldName });

  return (
    <Box position={'relative'}>
      {required && <RequiredLabel />}
      <InputLabel sx={{ fontSize: '12px' }}>{I18n.get(label)}</InputLabel>
      <Box sx={{ maxHeight: 168, overflow: 'auto' }}>
        {fields?.map((__field, idx) => (
          <Box sx={{ '& > :not(style)': { mb: 1 }, display: 'flex' }} key={__field.id}>
            <Controller
              name={`${fieldName}[${idx}].value`}
              control={control}
              rules={createValidationRules(validationRules)}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus={fields?.length > 1}
                  placeholder={placeholder}
                  error={!!errors[fieldName]?.[idx]}
                  fullWidth
                  size="small"
                  disabled={disabled}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-disabled': { background: theme.colors.disabled },
                    paddingTop: '1px',
                  }}
                  helperText={errors[fieldName]?.[idx]?.value?.message || null}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            {!notShowButton && (
              <>
                {fields.length > 1 && (
                  <PrimaryButton variant="outlined" height={40} onClick={() => remove(idx)} disabled={disabled}>
                    −
                  </PrimaryButton>
                )}
                <PrimaryButton variant="outlined" height={40} onClick={() => append({ value: '' })} disabled={disabled}>
                  ＋
                </PrimaryButton>
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default FormArrayTextField;
