import { Title, Genre } from '../models';

const useModifyTitles = (titles: Title[], genres: Genre[]) => {
  return titles.map((title) => {
    const names = title.genre_id?.map((id) => {
      const genre = genres.find((genre) => (id === genre.id))
      return genre?.name
    })
    return { ...title, genre_name: names ?? [] }
  })
}

export default useModifyTitles