import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Backdrop } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import FormCheckBox from '../forms/FormCheckBox';
import ConfirmDialog from '../ConfirmDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { User } from '../../models';
import { classificationValidateBnf } from '../../hooks/classification';
import { getBnf } from '../../graphql/queries';
import { updateBnf } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { sleep } from '../../utils/fetchData';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { Bnf } from '../../API';
import { EDIT_BNF_FIELDS, VALIDATE_DUPLICATE_BNF_FIELDS } from '../../consts/bnf';
import FormSelectBox from '../forms/FormSelectBox';
import { BNF_DATA_SOURCES } from '../../consts/common';

import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  row: any;
  setOpen: Function;
  setRow: Function;
  fetchBnfs: Function;
  user: User;
};

type Record = {
  bnf_master_id?: string;
  bnf_data_source?: string;
  obic7_commodity_cd?: string;
  obic7_commodity_name?: string;
  bvics_plan_cd_4char?: string;
  bvics_plan_cd_7char?: string;
  bvics_product_name?: string;
  updated_user_id?: string;
  updatedAt?: string;
  update_reason?: string;
  admin_check_flg?: boolean;
};

function EditBnfDialog(props: Props) {
  const { open, row, setOpen, setRow, user, fetchBnfs } = props;
  const [confirm, setConfirm] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isRel, setIsRel] = useState(false);
  const { loading, checkAndRestore } = useRestore();
  const [editBnfFields, setEditBnfFields] = useState(EDIT_BNF_FIELDS);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    watch,
    register,
  } = useForm();
  const selectedBnfDataSource: string | undefined = watch('bnf_data_source');

  const { addNotification } = useContext(NotificationContext);

  // FIXME: NewBnfDialogと処理を共通化する
  useEffect(() => {
    // ダイアログ初回表示時
    if (selectedBnfDataSource == null) {
      if (row.bnf_data_source === 'OBIC7') {
        const newFields = editBnfFields.map((f) => {
          if (f.field === 'obic7_commodity_name') {
            return { ...f, required: true };
          }
          if (f.field === 'bvics_product_name') {
            return { ...f, required: false };
          }
          return f;
        });
        setEditBnfFields(newFields);
      } else if (row.bnf_data_source === 'BVICS') {
        const newFields = editBnfFields.map((f) => {
          if (f.field === 'bvics_product_name') {
            return { ...f, required: true };
          }
          if (f.field === 'obic7_commodity_name') {
            return { ...f, required: false };
          }
          return f;
        });
        setEditBnfFields(newFields);
      }
    }

    // セレクトボックスでBnfDataSourceを変更した際
    if (selectedBnfDataSource === 'OBIC7') {
      register('obic7_commodity_name', { required: '未入力です' });
      register('bvics_product_name', { required: false });

      const newFields = editBnfFields.map((f) => {
        if (f.field === 'obic7_commodity_name') {
          return { ...f, required: true };
        }
        if (f.field === 'bvics_product_name') {
          return { ...f, required: false };
        }
        return f;
      });
      setEditBnfFields(newFields);
    } else if (selectedBnfDataSource === 'BVICS') {
      register('bvics_product_name', { required: '未入力です' });
      register('obic7_commodity_name', { required: false });

      const newFields = editBnfFields.map((f) => {
        if (f.field === 'bvics_product_name') {
          return { ...f, required: true };
        }
        if (f.field === 'obic7_commodity_name') {
          return { ...f, required: false };
        }
        return f;
      });
      setEditBnfFields(newFields);
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [selectedBnfDataSource, row]);

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const onConfirmCancel = () => {
    setConfirm(false);
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<Record> = async (data) => {
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('BNF', VALIDATE_DUPLICATE_BNF_FIELDS, data, clearErrors, setError, row.id);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
  };

  // 確認編集画面の「登録」の処理
  const onSubmit = () => {
    setCanSubmit(true);
  };

  const onUpdate = async () => {
    try {
      const model: any = await API.graphql({ query: getBnf, variables: { id: row.id } });
      const bnf = (model.data as any).getBnf;
      const formData = {
        id: row.id,
        bnf_data_source: record.bnf_data_source,
        obic7_commodity_cd: record.obic7_commodity_cd,
        obic7_commodity_name: record.obic7_commodity_name,
        bvics_plan_cd_4char: record.bvics_plan_cd_4char,
        bvics_plan_cd_7char: record.bvics_plan_cd_7char,
        bvics_product_name: record.bvics_product_name,
        _version: bnf._version,
        updated_user_id: user.id,
        update_reason: record.update_reason,
        admin_check_flg: record.admin_check_flg,
      };
      await API.graphql({ query: updateBnf, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'BNFマスターの編集に成功しました',
      });
      await sleep(3000);
      await fetchBnfs({});
      reset();
    } catch (e) {
      addNotification({
        type: 'success',
        message: 'BNFマスターの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete: SubmitHandler<Record> = async (data) => {
    await deleteBnf(data);
    setConfirm(false);
    onClose();
    await sleep(3000);
    await fetchBnfs({});
  };

  const deleteBnf = async (data: Record) => {
    try {
      const model: any = await API.graphql({ query: getBnf, variables: { id: row.id } });
      const bnf = (model.data as any).getBnf;
      const formData = {
        id: row.id,
        _version: bnf._version,
        updated_user_id: user.id,
        update_reason: data.update_reason,
        delete_flg: true,
      };
      await API.graphql({ query: updateBnf, variables: { input: formData } });
      reset();
      addNotification({
        type: 'success',
        message: 'BNFマスターの削除に成功しました',
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BNFマスターの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const checkRelation = () => {
    setAlertMsg(`このBNFマスターを削除しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const restoreConfirm = () => {
    setAlertMsg(`このBNFマスターを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('bnf', row);
      onClose();
      addNotification({
        type: 'success',
        message: 'BNFマスターの復元に成功しました',
      });
      await sleep(3000);
      await fetchBnfs({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BNFマスターの復元に失敗しました',
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const getOptions = (optionName: string) => {
    switch (optionName) {
      default:
      case 'bnf_data_source':
        return BNF_DATA_SOURCES;
    }
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BNFマスターの編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!row.delete_flg && handleSubmit(onPreSubmit)}
      onConfirm={row.delete_flg ? restoreConfirm : checkRelation}
      isRestore={row.delete_flg}
      height={600}
      width="md"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 300 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 530,
        }}
        width={980}
      >
        {editBnfFields.map((__row, idx) => (
          <React.Fragment key={idx}>
            {(__row.type === 'text' || __row.type === 'textarea') && (
              <FormTextField
                label={__row.label}
                placeholder={__row.placeholder}
                field={__row.field}
                control={control}
                disabled={row.delete_flg || __row.disabled}
                errors={errors}
                validationRules={classificationValidateBnf(__row.field)}
                value={row[__row.field as keyof Pick<Bnf, 'updated_user_id'>]}
                required={row.delete_flg ? false : __row.required}
                isTextArea={__row.type === 'textarea'}
              />
            )}
            {__row.type === 'datetime' && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<Bnf, 'updatedAt'>]}
                type={__row.type}
                disabled={row.delete_flg || __row.disabled}
                field={__row.field}
                control={control}
              />
            )}
            {__row.type === 'checkbox' && (
              <FormCheckBox
                field={__row.field}
                label={__row.label}
                control={control}
                defaultValue={row[__row.field as keyof Pick<Bnf, 'admin_check_flg'>] || false}
                disabled={row.delete_flg || __row.disabled}
              />
            )}
            {__row.type === 'select' && __row.optionsName && (
              <FormSelectBox
                label={__row.label}
                content={getOptions(__row.optionsName)}
                field={__row.field}
                control={control}
                placeholder={__row.placeholder}
                defaultValue={row[__row.field as keyof Pick<Bnf, 'bnf_data_source'>] || false}
                validationRules={classificationValidateBnf(row.field)}
                isBlank={__row.isBlank}
                disabled={row.delete_flg || __row.disabled}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <ConfirmDialog
        message={alertMsg}
        open={confirm}
        onCancel={onConfirmCancel}
        onContinue={row.delete_flg ? onRestore : handleSubmit(onDelete)}
        type={isRel ? 'error' : 'warning'}
        updateReason={
          row.delete_flg ? (
            <></>
          ) : (
            <Box px={3} mb={1}>
              <FormTextField
                label="更新理由"
                placeholder="更新理由を入力"
                value={row.update_reason?.toString() || ''}
                field="update_reason"
                control={control}
                errors={errors}
                validationRules={classificationValidateBnf('update_reason')}
              />
            </Box>
          )
        }
      />
      <EditConfirmDialog
        fields={editBnfFields}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="BNFマスター"
        beforeChangeValueData={row}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormDialog>
  );
}

export default EditBnfDialog;
