import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { User, Currency, Platform, ProductClass, ProductType, Title, EagerDataSource } from '../../models';
import { CREATE_PRODUCT_FIELDS } from '../../consts/product';
import { ProductFields } from '../../types';
import { classificationValidatePrd } from '../../hooks/classification';
import { createProduct } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { CE_LINK_SEQ_ID, MULCHI_SEQ_ID } from '../../consts/common';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  currencies: Currency[];
  open: boolean;
  platforms: Platform[];
  productClasses: ProductClass[];
  productTypes: ProductType[];
  datasources?: EagerDataSource[];
  setOpen: Function;
  title: Title;
  user: User;
};

type Record = {
  title_id?: string;
  data_source_id?: string;
  product_key?: string;
  name?: string;
  common_name?: string;
  product_type_id?: string;
  product_class_id?: string;
  platform_id?: string;
  market?: string;
  base_price?: string;
  currency_id?: string;
  release_status?: string;
  info_release_date?: string;
  preorder_start_date?: string;
  release_date?: string;
  service_start_date?: string;
  service_end_date?: string;
  sku_number_apple?: string;
  app_id?: string;
  package_name_google?: string;
  bank_app_id?: string;
  updated_reason?: string;
};

function NewProductDialog(props: Props) {
  const { currencies, open, platforms, productClasses, productTypes, datasources, setOpen, title, user } = props;
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const getNextId = useAutoIncrement('PRD');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  const celinkDatasource = datasources?.find((datasource) => datasource.seq_id === CE_LINK_SEQ_ID);
  const mulchiDatasource = datasources?.find((datasource) => datasource.seq_id === MULCHI_SEQ_ID);

  useEffect(() => {
    if (!open) return;
    // MEMO: ブラウザ上でそれぞれの名称を表示する必要がある為、nameをセットする(登録処理時に再度IDに戻す処理をする)
    setValue('title_id', title.name_ja);
    setValue(
      'data_source_id',
      title.data_source?.id === mulchiDatasource?.id ? celinkDatasource?.name : title.data_source?.name
    );
  }, [open, title, setValue, mulchiDatasource, celinkDatasource]);

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  const onClose = () => {
    reset();
    setOpen(false);
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<any> = async (data) => {
    console.log({ data });

    setRecord(data);
    setEditConfirm(true);
  };

  const onUpdate = async () => {
    try {
      const seq_id = await getNextId();
      const formData = {
        ...record,
        seq_id,
        title_id: title.id,
        data_source_id: title.data_source?.id === mulchiDatasource?.id ? celinkDatasource?.id : title.data_source?.id,
        base_price: parseFloat(record.base_price || ''),
        delete_flg: false,
        admin_check_flg: false,
        updated_user_id: user.id,
        system_update_flg: false,
      };
      await API.graphql({ query: createProduct, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'プロダクトの新規作成に成功しました',
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'プロダクトの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  // 確認編集画面の「登録」の処理
  const onSubmit = () => {
    setCanSubmit(true);
  };

  const selectOptions = (name: string | undefined) => {
    switch (name) {
      case 'productType':
        return productTypes;
      case 'productClass':
        return productClasses;
      case 'platform':
        return platforms;
      case 'currency':
        return currencies;
      default:
        return [];
    }
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="プロダクトの新規作成"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      height="inherit"
      isFullScreen
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 400 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 'inherit',
        }}
      >
        {CREATE_PRODUCT_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                disabled={row.disabled}
                errors={errors}
                validationRules={classificationValidatePrd(row.field as ProductFields)}
                required={row.required}
              />
            )}
            {(row.type === 'datetime' || row.type === 'date') && (
              <FormDateTimePicker
                label={row.label}
                type={row.type}
                disabled={row.disabled}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
                errors={errors}
                errorMessage={'日付形式が無効です'}
              />
            )}
            {row.type === 'select' && (
              <FormSelectBox
                label={row.label}
                content={selectOptions(row.optionsName)}
                disabled={row.disabled}
                field={row.field}
                selectKey={row.field === 'currency_id' ? 'code_three_char' : 'name'}
                control={control}
                placeholder={row.placeholder}
                required={row.required}
                errors={errors}
                validationRules={classificationValidatePrd(row.field as ProductFields)}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <EditConfirmDialog
        currencies={currencies}
        fields={CREATE_PRODUCT_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        platforms={platforms}
        productClasses={productClasses}
        productTypes={productTypes}
        record={record}
        schemaName="プロダクト"
      />
    </FormDialog>
  );
}

export default NewProductDialog;
