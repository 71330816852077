import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format, parseISO } from 'date-fns';
import { theme } from '../assets/theme/options';
import FormDialog from './FormDialog';
import {
  Genre,
  DataSource,
  Tag,
  Title,
  Product,
  Currency,
  Platform,
  ProductClass,
  ProductType,
  BusinessEntity,
  Country,
  GroupCompany,
} from '../models';
import { BNF_DATA_SOURCES, EDIT_CONFIRM_HEADER_COLS, OPERATION_TYPE_OPTIONS } from '../consts/common';
import { I18n } from 'aws-amplify/utils';
import {
  COMPANY_ATTRIBUTE_LIST,
  BRAND_PORTFOLIO_LIST,
  CONSOLIDATION_LIST,
  CREATE_GROUP_COMPANY_FIELDS,
  EQUITY_METHOD_APPLICATION_LIST,
  MANAGEMENT_BUSINESS_LIST,
  PUBLIC_OR_PRIVATE_LIST,
  UNIT_CLASS_LIST,
  VALIDATE_DUPLICATE_GROUP_COMPANY_FIELDS,
} from '../consts/groupCompany';

type Props = {
  fields: any[];
  onClose: Function;
  onSubmit: Function;
  open: boolean;
  record: { [key: string]: any };
  schemaName: string;
  genres?: Genre[];
  dataSources?: DataSource[];
  currencies?: Currency[];
  countries?: Country[];
  platforms?: Platform[];
  productClasses?: ProductClass[];
  productTypes?: ProductType[];
  businessEntity?: BusinessEntity[];
  groupCompanies?: GroupCompany[];
  beforeChangeValueData?: Tag | Title | Product | any;
  disabledSubmit?: boolean;
};

function EditConfirmDialog(props: Props) {
  const {
    fields,
    onClose,
    onSubmit,
    open,
    record,
    schemaName,
    genres,
    dataSources,
    currencies,
    countries,
    productClasses,
    productTypes,
    platforms,
    businessEntity,
    beforeChangeValueData,
    groupCompanies,
  } = props;

  const translate = (type: string | undefined, field: any, optionsName?: string) => {
    if (!record || !record[field]) return;
    if (field == 'currency_id' || optionsName === 'currency') {
      const currency = id2Data(record[field], optionsName);
      if (schemaName === 'グループ会社マスタ') {
        return currency.name;
      }
      return currency.code_three_char;
    }

    switch (type) {
      case 'text':
        return record[field];
      case 'textarea':
        return record[field];
      case 'autoComplete':
        if (optionsName) {
          if (field == 'title') {
            return record[field].name_ja;
          } else if (field.includes('holding_company_')) {
            return record[field];
          } else {
            return record[field][optionsName];
          }
        } else {
          return record[field];
        }
      case 'select':
        // タイトル編集時にこの分岐を通るときにはrecordにtitleは含まれないので存在チェックをする。
        if (field === 'data_source_id' && record['title'] != null) {
          // プロダクト編集時にタイトルを変更すると紐づくdata_sourceも変わるケースがある。
          // recordのdata_source_idはタイトルに紐づくidになっていないため、タイトルに紐づくdata_source_idで変換する。
          return id2Data(record['title'].data_source_id, optionsName).name;
        } else {
          return id2Data(record[field], optionsName).name;
        }
      case 'radio':
        return id2Data(record[field], optionsName).name;
      case 'date':
        return format(parseISO(record[field]), 'yyyy/MM/dd');
      case 'datetime':
        return format(parseISO(record[field]), 'yyyy/MM/dd HH:mm');
      case 'checkbox':
        return record[field];
      case 'arrayText':
        if (record[field] && record[field].length > 0) {
          const filteredBlank = record[field].filter((col: any) => col.value).map((col: any) => col.value);
          let arrayText: string | null = '';
          if (filteredBlank.length > 0) {
            filteredBlank.map((text: string) => {
              arrayText = arrayText + `"${text}", `;
            });
            arrayText = `[${arrayText.slice(0, -2)}]`;
          } else {
            arrayText = null;
          }
          return arrayText;
        } else {
          return null;
        }
      case 'chip':
        if (record[field] && record[field].length > 0) {
          const chips = record[field]?.length > 0 ? record[field].map((value: any) => value.name) : null;
          let arrayText: string | null = '';
          if (chips?.length > 0) {
            chips?.map((text: string) => {
              arrayText = arrayText + `"${text}", `;
            });
            arrayText = `[${arrayText.slice(0, -2)}]`;
          } else {
            arrayText = null;
          }
          return arrayText;
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  const id2Data = (id: string, schemaName: string | undefined): any => {
    switch (schemaName) {
      case 'genre':
        return genres?.find((genre) => genre.id === id);
      case 'dataSource':
        return dataSources?.find((dataSource) => dataSource.id === id);
      case 'productClass':
        return productClasses?.find((productClass) => productClass.id === id);
      case 'productType':
        return productTypes?.find((productType) => productType.id === id);
      case 'platform':
        return platforms?.find((platform) => platform.id === id);
      case 'currency':
        return currencies?.find((currency) => currency.id === id);
      case 'country':
        return countries?.find((country) => country.id === id);
      case 'businessEntity':
        return businessEntity?.find((v) => v.id === id);
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS.find((OPERATION_TYPE_OPTION) => OPERATION_TYPE_OPTION.id === id);
      case 'bnf_data_source':
        return BNF_DATA_SOURCES.find((bnfDataSource) => bnfDataSource.id === id);
      case 'company_attribute':
        return COMPANY_ATTRIBUTE_LIST.find((list) => list.id === id);
      case 'consolidation':
        return CONSOLIDATION_LIST.find((list) => list.id === id);
      case 'equity_method_application':
        return EQUITY_METHOD_APPLICATION_LIST.find((list) => list.id === id);
      case 'unit_class_1':
      case 'unit_class_2':
        return UNIT_CLASS_LIST.find((list) => list.id === id);
      case 'management_business_1':
      case 'management_business_2':
        return MANAGEMENT_BUSINESS_LIST.find((list) => list.id === id);
      case 'public_or_private':
        return PUBLIC_OR_PRIVATE_LIST.find((list) => list.id === id);
      case 'brand_portfolio':
        return BRAND_PORTFOLIO_LIST.find((list) => list.id === id);
      // case 'holding_company_1':
      // case 'holding_company_2':
      // case 'holding_company_3':
      // case 'holding_company_4':
      // case 'holding_company_5':
      //     return groupCompanies?.find((list) => list.id === id)?.group_company_code;
      default:
        return null;
    }
  };

  const getColumnToUpdate = () => {
    if (!beforeChangeValueData) return [];
    return Object.entries(record)
      .map(([newKey]) => {
        const oldValue = beforeChangeValueData[newKey];
        const newValue = record[newKey as any];
        //  カラムが後から追加された時などは、既存データにnullが発生してしまうので、データ入力がない状態と同等とみなす(AWS上のemptyは、JS上でnullと返される)
        if ((!oldValue && !newValue) || oldValue === newValue) return;
        if (newKey === 'sap_title_code' || newKey === 'bn_connect_title_id') {
          const result = oldValue?.toString() === record[newKey as any].map((value: any) => value.value).toString();
          return result ? false : newKey;
        }
        if (newKey === 'genre_id') {
          const result = oldValue?.toString() == record[newKey as any].map((value: any) => value.id).toString();
          return result ? false : newKey;
        }
        if (newKey === 'genre_ids' || newKey === 'platform_ids') {
          const result = oldValue.map((v) => v.id).toString() === record[newKey].map((v) => v.id).toString();
          return result ? false : newKey;
        }
        if (
          newKey === 'wbs' ||
          newKey === 'bn_connect_title_ids' ||
          newKey === 'bundle_source_title_code_ids' ||
          newKey === 'porting_source_title_code_ids' ||
          newKey === 'project_cd'
        ) {
          const oldValues = oldValue
            .map((v) => v.value)
            .sort()
            .filter(Boolean);
          const newValues = record[newKey]
            .map((v) => v.value)
            .sort()
            .filter(Boolean);
          return oldValues.toString() === newValues.toString() ? false : newKey;
        }
        if (newKey === 'ip_address') {
          const oldValues =
            oldValue
              ?.map((v) => v.value ?? v)
              .sort()
              .filter(Boolean) ?? [];
          const newValues = record[newKey]
            .map((v) => v.value ?? v)
            .sort()
            .filter(Boolean);
          return oldValues.toString() === newValues.toString() ? false : newKey;
        }
        if (newKey === 'tag') {
          return newValue['name_ja'] === beforeChangeValueData[newKey]?.name_ja ? false : newKey;
        }
        if (newKey === 'title') {
          if (newValue['id'] !== oldValue['id']) {
            // タイトルが変更 且つ 紐づくデータソースが変更された場合にデータソースもハイライト対象とする。
            if (
              beforeChangeValueData.title &&
              record.title &&
              beforeChangeValueData.title.data_source_id !== record.title.data_source_id
            ) {
              return [newKey, 'data_source_id'];
            } else {
              return newKey;
            }
          } else {
            return false;
          }
        }
        if (newKey === 'admin_check_flg' || newKey === 'mlics_plan_cd_4char' || newKey === 'mlics_plan_cd_7char') {
          return newValue === oldValue ? false : newKey;
        }
        return newKey;
      })
      .flat()
      .filter(Boolean);
  };

  if (!record || !open) return <></>;
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title={`編集した${schemaName}の内容を確認`}
      doText={
        schemaName === 'BSPマスター共有タグ付け' ||
        schemaName === 'BNMLマスター共有タグ付け' ||
        schemaName === 'BNAMマスター共有タグ付け' ||
        schemaName === 'BNFマスター共有タグ付け'
          ? 'タグ付け'
          : '登録'
      }
      cancelText="戻る"
      onSubmit={onSubmit}
      height={950}
      width="md"
      disabledSubmit={props.disabledSubmit}
    >
      <TableContainer sx={{ minWidth: 800, maxWidth: 800 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {EDIT_CONFIRM_HEADER_COLS.map((col, idx) => (
                <TableCell key={idx} style={{ minWidth: 300 }}>
                  {I18n.get(col)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field: any, idx) => (
              <TableRow key={idx}>
                <TableCell>{I18n.get(field.label)}</TableCell>
                {getColumnToUpdate().includes(field.field) ? (
                  <TableCell style={{ backgroundColor: theme.colors.editedCell }}>
                    {field.type === 'checkbox' ? (
                      translate(field.type, field.field, field.optionsName) ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )
                    ) : (
                      translate(field.type, field.field, field.optionsName)
                    )}
                  </TableCell>
                ) : (
                  <TableCell>
                    {field.type === 'checkbox' ? (
                      translate(field.type, field.field, field.optionsName) ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )
                    ) : (
                      translate(field.type, field.field, field.optionsName)
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormDialog>
  );
}

export default EditConfirmDialog;
