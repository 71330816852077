import React, { useContext, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import FormTextField from '../forms/FormTextField';
import FormDialog from '../FormDialog';
import { createBnam } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { CREATE_BNAM_FIELDS, VALIDATE_DUPLICATE_BNAM_FIELDS } from '../../consts/bnam';
import { BnamFields } from '../../types';
import { classificationValidateBnam } from '../../hooks/classification';
import { User } from '../../models';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { sleep } from '../../utils/fetchData';
import EditConfirmDialog from '../EditConfirmDialog';
import { generateClient } from 'aws-amplify/api';
import { CreateBnamInput } from '../../API';
const API = generateClient();

type Props = {
  open: boolean;
  setOpen: Function;
  fetchBnams: Function;
  user: User;
};

type Record = {
  ip_name?: string;
  update_reason?: string;
};

function NewBnamDialog(props: Props) {
  const { open, setOpen, user, fetchBnams } = props;
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const onUpdating = useRef(false); // 連打防止用フラグ
  const getNextId = useAutoIncrement('BNA');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onPreSubmit: SubmitHandler<any> = async (data) => {
    // 連打回避
    if (onUpdating.current) return;
    onUpdating.current = true;
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('BNAM', VALIDATE_DUPLICATE_BNAM_FIELDS, data, clearErrors, setError);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    } // 連打回避解除
    if (onUpdating.current) {
      setTimeout(() => {
        onUpdating.current = false;
      }, 2000);
    }
  };

  const onCreate = async () => {
    try {
      const seq_id = await getNextId();
      const formData = {
        ...record,
        seq_id,
        admin_check_flg: false,
        delete_flg: false,
        updated_user_id: user.id,
      } as CreateBnamInput;
      await API.graphql({ query: createBnam, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'BNAMマスターの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchBnams({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BNAMマスターの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const onSubmit = () => {
    onCreate();
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BNAMマスターの新規登録"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      height={350}
      width="xs"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 280 },
        }}
        width={340}
      >
        {CREATE_BNAM_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateBnam(row.field as BnamFields)}
                required={row.required}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <EditConfirmDialog
        fields={CREATE_BNAM_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="BNAMマスター"
      />
    </FormDialog>
  );
}

export default NewBnamDialog;
