import { GroupCompany } from '../models';
import { SearchConditionGroupCompany } from '../types/form/GroupCompany';

type GroupCompanyField = Array<{
  field: keyof GroupCompany;
  label: string;
  type: 'radio' | 'text' | 'checkbox' | 'arrayText' | 'chip' | 'select' | 'autoComplete' | 'date' | 'datetime';
  placeholder?: string;
  optionsName?: string;
  column?: number;
  required?: boolean;
  typeIds?: string[];
  isBlank?: boolean;
  inputs?: Array<{ field: string; label: string }>;
}>;

export const VALIDATE_DUPLICATE_GROUP_COMPANY_FIELDS = ['group_company_code', 'valid_start_date'];

export const COMPANY_ATTRIBUTE_LIST = [
  { id: '親会社', name: '親会社' },
  { id: '子会社', name: '子会社' },
  { id: '関連会社', name: '関連会社' },
];

export const CONSOLIDATION_LIST = [
  { id: '連結', name: '連結' },
  { id: '非連結', name: '非連結' },
];

export const EQUITY_METHOD_APPLICATION_LIST = [
  { id: '持分法適用', name: '持分法適用' },
  { id: '持分法非適用', name: '持分法非適用' },
];

export const UNIT_CLASS_LIST = [
  { id: 'HD', name: 'HD' },
  { id: 'ENT', name: 'ENT' },
  { id: 'AM', name: 'AM' },
  { id: 'IPP', name: 'IPP' },
  { id: '関連事業', name: '関連事業' },
];

export const MANAGEMENT_BUSINESS_LIST = [
  { id: 'DIG', name: 'DIG' },
  { id: 'TH', name: 'TH' },
  { id: 'AM', name: 'AM' },
  { id: 'IPP', name: 'IPP' },
];

export const PUBLIC_OR_PRIVATE_LIST = [
  { id: '公開', name: '公開' },
  { id: '非公開', name: '非公開' },
];

export const BRAND_PORTFOLIO_LIST = [
  { id: 'Category1', name: 'Category1' },
  { id: 'Category2', name: 'Category2' },
];

export const GROUP_AGREEMENT_FLG_LIST = [
  { id: true, name: 'group-company-true-group_agreement_flg' },
  { id: false, name: 'group-company-false-group_agreement_flg' },
];

export const MANAGEMENT_CONTACT_FLG_LIST = [
  { id: true, name: 'group-company-true-management_contract_flg' },
  { id: false, name: 'group-company-false-management_contract_flg' },
];

export const SUBCONTRACTING_CONTACT_FLG_LIST = [
  { id: true, name: 'group-company-true-subcontracting_contract_flg' },
  { id: false, name: 'group-company-false-subcontracting_contract_flg' },
];

export const CREATE_GROUP_COMPANY_FIELDS: GroupCompanyField = [
  {
    field: 'group_company_code',
    label: 'group-company-group_company_code',
    type: 'text',
    placeholder: 'グループ会社コードを入力',
    required: true,
    column: 1,
  },
  {
    field: 'valid_start_date',
    label: 'group-company-valid_start_date',
    type: 'date',
    placeholder: '有効開始日を入力',
    required: true,
    column: 1,
    inputs: [
      { field: 'valid_start_date_from', label: 'group-company-valid_start_date' },
      { field: 'valid_start_date_to', label: 'group-company-valid_start_date' },
    ],
  },
  {
    field: 'valid_end_date',
    label: 'group-company-valid_end_date',
    type: 'date',
    placeholder: '有効終了日を入力',
    column: 1,
    inputs: [
      { field: 'valid_end_date_from', label: 'group-company-valid_end_date' },
      { field: 'valid_end_date_to', label: 'group-company-valid_end_date' },
    ],
  },
  {
    field: 'stravis_code',
    label: 'group-company-stravis_code',
    type: 'text',
    placeholder: 'Stravisコードを入力',
    column: 1,
  },
  {
    field: 'company_name_ja',
    label: 'group-company-company_name_ja',
    type: 'text',
    placeholder: '会社名（日本語）を入力',
    column: 1,
  },
  {
    field: 'company_name_en',
    label: 'group-company-company_name_en',
    type: 'text',
    placeholder: '会社名（英語）を入力',
    column: 1,
  },
  {
    field: 'abbreviation',
    label: 'group-company-abbreviation',
    type: 'text',
    placeholder: '略称を入力',
    column: 1,
  },
  {
    field: 'company_attribute',
    optionsName: 'company_attribute',
    label: 'group-company-company_attribute',
    type: 'select',
    placeholder: '会社属性を入力',
    column: 1,
  },
  {
    field: 'consolidation',
    optionsName: 'consolidation',
    label: 'group-company-consolidation',
    type: 'select',
    placeholder: '連結・非連結を入力',
    column: 1,
  },
  {
    field: 'equity_method_application',
    optionsName: 'equity_method_application',
    label: 'group-company-equity_method_application',
    type: 'select',
    placeholder: '持分法適用・非適用を入力',
    column: 1,
  },
  {
    field: 'unit_class_1',
    optionsName: 'unit_class_1',
    label: 'group-company-unit_class_1',
    type: 'select',
    placeholder: 'ユニット区分1を入力',
    column: 2,
  },
  {
    field: 'unit_class_2',
    optionsName: 'unit_class_2',
    label: 'group-company-unit_class_2',
    type: 'select',
    placeholder: 'ユニット区分2を入力',
    column: 2,
  },
  {
    field: 'management_business_1',
    optionsName: 'management_business_1',
    label: 'group-company-management_business_1',
    type: 'select',
    placeholder: '管理事業1を入力',
    column: 2,
  },
  {
    field: 'management_business_2',
    optionsName: 'management_business_2',
    label: 'group-company-management_business_2',
    type: 'select',
    placeholder: '管理事業2を入力',
    column: 2,
  },
  {
    field: 'country_id',
    optionsName: 'country',
    label: 'group-company-country_id',
    type: 'select',
    placeholder: '国/地域を入力',
    column: 2,
  },
  {
    field: 'accounting_period',
    label: 'group-company-accounting_period',
    type: 'text',
    placeholder: '決算期を入力',
    column: 2,
  },
  {
    field: 'public_or_private',
    optionsName: 'public_or_private',
    label: 'group-company-public_or_private',
    type: 'select',
    placeholder: '公開/非公開を入力',
    column: 2,
  },
  {
    field: 'group_agreement_flg',
    label: 'group-company-group_agreement_flg',
    type: 'checkbox',
    placeholder: 'グループ規約適用を入力',
    column: 2,
  },
  {
    field: 'management_contract_flg',
    label: 'group-company-management_contract_flg',
    type: 'checkbox',
    placeholder: '経営管理契約を入力',
    column: 2,
  },
  {
    field: 'subcontracting_contract_flg',
    label: 'group-company-subcontracting_contract_flg',
    type: 'checkbox',
    placeholder: '業務委託契約を入力',
    column: 2,
  },
  {
    field: 'brand_portfolio',
    optionsName: 'brand_portfolio',
    label: 'group-company-brand_portfolio',
    type: 'select',
    placeholder: 'ブランドポートフォリオを入力',
    column: 3,
  },
  {
    field: 'holding_company_1',
    optionsName: 'holding_company_1',
    label: 'group-company-holding_company_1',
    type: 'autoComplete',
    placeholder: '保有会社1を入力',
    column: 3,
  },
  {
    field: 'investment_ratio_1',
    label: 'group-company-investment_ratio_1',
    type: 'text',
    placeholder: '出資比率1を入力',
    column: 3,
  },
  {
    field: 'holding_company_2',
    optionsName: 'holding_company_2',
    label: 'group-company-holding_company_2',
    type: 'autoComplete',
    placeholder: '保有会社2を入力',
    column: 3,
  },
  {
    field: 'investment_ratio_2',
    label: 'group-company-investment_ratio_2',
    type: 'text',
    placeholder: '出資比率2を入力',
    column: 3,
  },
  {
    field: 'holding_company_3',
    optionsName: 'holding_company_3',
    label: 'group-company-holding_company_3',
    type: 'autoComplete',
    placeholder: '保有会社3を入力',
    column: 3,
  },
  {
    field: 'investment_ratio_3',
    label: 'group-company-investment_ratio_3',
    type: 'text',
    placeholder: '出資比率3を入力',
    column: 3,
  },
  {
    field: 'holding_company_4',
    optionsName: 'holding_company_4',
    label: 'group-company-holding_company_4',
    type: 'autoComplete',
    placeholder: '保有会社4を入力',
    column: 3,
  },
  {
    field: 'investment_ratio_4',
    label: 'group-company-investment_ratio_4',
    type: 'text',
    placeholder: '出資比率4を入力',
    column: 3,
  },
  {
    field: 'holding_company_5',
    optionsName: 'holding_company_5',
    label: 'group-company-holding_company_5',
    type: 'autoComplete',
    placeholder: '保有会社5を入力',
    column: 3,
  },
  {
    field: 'investment_ratio_5',
    label: 'group-company-investment_ratio_5',
    type: 'text',
    placeholder: '出資比率5を入力',
    column: 4,
  },
  {
    field: 'total_investment_ratio',
    label: 'group-company-total_investment_ratio',
    type: 'text',
    placeholder: '合計出資比率を入力',
    column: 4,
  },
  {
    field: 'issued_shares_total',
    label: 'group-company-issued_shares_total',
    type: 'text',
    placeholder: '発行済株式総数を入力',
    column: 4,
  },
  {
    field: 'treasury_stock',
    label: 'group-company-treasury_stock',
    type: 'text',
    placeholder: '自己株を入力',
    column: 4,
  },
  {
    field: 'nominee_shares',
    label: 'group-company-nominee_shares',
    type: 'text',
    placeholder: '名義貸株式を入力',
    column: 4,
  },
  {
    field: 'capital',
    label: 'group-company-capital',
    type: 'text',
    placeholder: '資本金を入力',
    column: 4,
  },
  {
    field: 'currency_id',
    optionsName: 'currency',
    label: 'group-company-currency_id',
    type: 'select',
    placeholder: '現地通貨を入力',
    column: 4,
  },
  {
    field: 'number_of_employees',
    label: 'group-company-number_of_employees',
    type: 'text',
    placeholder: '従業員数を入力',
    column: 4,
  },
  {
    field: 'establishment_date',
    label: 'group-company-establishment_date',
    type: 'date',
    placeholder: '設立日を入力',
    column: 4,
    inputs: [
      { field: 'establishment_date_from', label: 'group-company-establishment_date' },
      { field: 'establishment_date_to', label: 'group-company-establishment_date' },
    ],
  },
  {
    field: 'representative_director_1_position',
    label: 'group-company-representative_director_1_position',
    type: 'text',
    placeholder: '代表取締役1役職名を入力',
    column: 4,
  },
  {
    field: 'representative_director_1_name',
    label: 'group-company-representative_director_1_name',
    type: 'text',
    placeholder: '代表取締役1氏名を入力',
    column: 5,
  },
  {
    field: 'representative_director_2_position',
    label: 'group-company-representative_director_2_position',
    type: 'text',
    placeholder: '代表取締役2役職名を入力',
    column: 5,
  },
  {
    field: 'representative_director_2_name',
    label: 'group-company-representative_director_2_name',
    type: 'text',
    placeholder: '代表取締役2氏名を入力',
    column: 5,
  },
  {
    field: 'representative_director_3_position',
    label: 'group-company-representative_director_3_position',
    type: 'text',
    placeholder: '代表取締役3役職名を入力',
    column: 5,
  },
  {
    field: 'representative_director_3_name',
    label: 'group-company-representative_director_3_name',
    type: 'text',
    placeholder: '代表取締役3氏名を入力',
    column: 5,
  },
  {
    field: 'business_description',
    label: 'group-company-business_description',
    type: 'text',
    placeholder: '業務内容を入力',
    column: 5,
  },
  {
    field: 'address_1',
    label: 'group-company-address_1',
    type: 'text',
    placeholder: '住所1を入力',
    column: 5,
  },
  {
    field: 'address_2',
    label: 'group-company-address_2',
    type: 'text',
    placeholder: '住所2を入力',
    column: 5,
  },
  {
    field: 'notes',
    label: 'group-company-notes',
    type: 'text',
    placeholder: '備考を入力',
    column: 5,
  },
  {
    field: 'ip_address',
    label: 'group-company-ip_address',
    type: 'arrayText',
    placeholder: 'IPアドレスを入力',
    column: 5,
  },
  {
    field: 'update_reason',
    label: 'group-company-updated_reason',
    type: 'text',
    placeholder: '更新理由を入力',
    column: 5,
  },
];

export const UPDATE_GROUP_COMPANY_FIELDS: GroupCompanyField = [
  {
    field: 'seq_id',
    label: 'group-company-seq_id',
    type: 'text',
    placeholder: 'グループ会社IDを入力',
    column: 1,
  },
  ...CREATE_GROUP_COMPANY_FIELDS,
];

export const SEARCH_GROUP_COMPANY_FIELDS: GroupCompanyField = [
  {
    field: 'seq_id',
    label: 'group-company-seq_id',
    type: 'text',
    placeholder: 'グループ会社IDを入力',
    column: 1,
  },
  ...CREATE_GROUP_COMPANY_FIELDS.filter((field) => {
    return (
      field.field !== 'group_agreement_flg' &&
      field.field !== 'management_contract_flg' &&
      field.field !== 'subcontracting_contract_flg' &&
      field.field !== 'investment_ratio_5' &&
      field.field !== 'representative_director_1_name' &&
      field.field !== 'representative_director_1_position' &&
      field.field !== 'representative_director_2_name' &&
      field.field !== 'representative_director_2_position' &&
      field.field !== 'representative_director_3_name' &&
      field.field !== 'representative_director_3_position' &&
      field.field !== 'ip_address' &&
      field.field !== 'delete_flg' &&
      field.field !== 'updated_user_id'
    );
  }),
  {
    field: 'group_agreement_flg',
    optionsName: 'group_agreement_flg',
    label: 'group-company-group_agreement_flg',
    type: 'select',
    placeholder: 'グループ規約適用を選択',
    column: 2,
  },
  {
    field: 'management_contract_flg',
    optionsName: 'management_contract_flg',
    label: 'group-company-management_contract_flg',
    type: 'select',
    placeholder: '経営管理契約を選択',
    column: 2,
  },
  {
    field: 'subcontracting_contract_flg',
    optionsName: 'subcontracting_contract_flg',
    label: 'group-company-subcontracting_contract_flg',
    type: 'select',
    placeholder: '業務委託契約を選択',
    column: 2,
  },
  {
    field: 'investment_ratio_5',
    label: 'group-company-investment_ratio_5',
    type: 'text',
    placeholder: '出資比率5を入力',
    column: 3,
  },
  {
    field: 'representative_director_1_position',
    label: 'group-company-representative_director_1_position',
    type: 'text',
    placeholder: '代表取締役1役職名を入力',
    column: 4,
  },
  {
    field: 'representative_director_1_name',
    label: 'group-company-representative_director_1_name',
    type: 'text',
    placeholder: '代表取締役1氏名を入力',
    column: 4,
  },
  {
    field: 'representative_director_2_position',
    label: 'group-company-representative_director_2_position',
    type: 'text',
    placeholder: '代表取締役2役職名を入力',
    column: 4,
  },
  {
    field: 'representative_director_2_name',
    label: 'group-company-representative_director_2_name',
    type: 'text',
    placeholder: '代表取締役2氏名を入力',
    column: 4,
  },
  {
    field: 'representative_director_3_position',
    label: 'group-company-representative_director_3_position',
    type: 'text',
    placeholder: '代表取締役3役職名を入力',
    column: 4,
  },
  {
    field: 'representative_director_3_name',
    label: 'group-company-representative_director_3_name',
    type: 'text',
    placeholder: '代表取締役3氏名を入力',
    column: 4,
  },
  {
    field: 'ip_address',
    label: 'group-company-ip_address',
    type: 'text',
    placeholder: 'IPアドレスを入力',
    column: 5,
  },
  {
    field: 'delete_flg',
    optionsName: 'delete_flg',
    label: 'group-company-delete_flg',
    type: 'select',
    placeholder: '削除済データを選択',
    column: 5,
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'updatedAt_from', label: '最終更新日時' },
      { field: 'updatedAt_to', label: '最終更新日時' },
    ],
    column: 5,
  },
  {
    field: 'updated_user_id',
    label: 'group-company_final-updater',
    type: 'text',
    placeholder: '最終更新者を入力',
    column: 5,
  },
];

export const SEARCH_GROUP_COMPANY_FIELDS_DEFAULT_VALUES: SearchConditionGroupCompany = {
  seq_id: '',
  group_company_code: '',
  valid_start_date_from: undefined,
  valid_start_date_to: undefined,
  valid_end_date_from: undefined,
  valid_end_date_to: undefined,
  stravis_code: '',
  company_name_ja: '',
  company_name_en: '',
  abbreviation: '',
  company_attribute: '',
  consolidation: '',
  equity_method_application: '',
  unit_class_1: '',
  unit_class_2: '',
  management_business_1: '',
  management_business_2: '',
  country_id: '',
  accounting_period: undefined,
  public_or_private: '',
  group_agreement_flg: '',
  management_contract_flg: '',
  subcontracting_contract_flg: '',
  brand_portfolio: '',
  holding_company_1: '',
  investment_ratio_1: undefined,
  holding_company_2: '',
  investment_ratio_2: undefined,
  holding_company_3: '',
  investment_ratio_3: undefined,
  holding_company_4: '',
  investment_ratio_4: undefined,
  holding_company_5: '',
  investment_ratio_5: undefined,
  total_investment_ratio: undefined,
  issued_shares_total: undefined,
  treasury_stock: undefined,
  nominee_shares: '',
  capital: undefined,
  currency_id: '',
  number_of_employees: undefined,
  establishment_date_from: undefined,
  establishment_date_to: undefined,
  representative_director_1_position: '',
  representative_director_1_name: '',
  representative_director_2_position: '',
  representative_director_2_name: '',
  representative_director_3_position: '',
  representative_director_3_name: '',
  business_description: '',
  address_1: '',
  address_2: '',
  notes: '',
  ip_address: undefined,
  delete_flg: false,
  update_reason: '',
  updated_user_id: '',
  updatedAt_from: undefined,
  updatedAt_to: undefined,
};
