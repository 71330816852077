export const SEARCH_TAG_TITLE_CODE_HISTORIES_FIELDS = [
  {
    field: "operation_type",
    label: "tag-title-history_operation-category",
    type: "select",
    placeholder: "操作種別を選択",
  },
  {
    field: "title_code_name",
    label: "title-code_title-code-name",
    type: "text",
    placeholder: "タイトルコード名称を入力",
  },
  {
    field: "tag_name",
    label: "IPタグ（日本語）",
    type: "text",
    placeholder: "IPタグ（日本語）を入力",
  },
  {
    field: "tag_name_en",
    label: "IPタグ（英語）",
    type: "text",
    placeholder: "IPタグ（英語）を入力",
  },
  {
    field: "updated_user_id",
    label: "tag-title-code-history_final-updater",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "tag-title-code-history_final-update-date" },
      { field: "to", label: "tag-title-code-history_final-update-date" },
    ],
  },
];

export const SEARCH_TAG_TITLE_CODE_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  title_code_name: "",
  tag_name: "",
  tag_name_en: "",
  updated_user_id: "",
  from: null,
  to: null,
};
