export const SEARCH_TYPE = {
  WILDCARD: 'WILDCARD',
  UUID_WILDCARD: 'UUID_WILDCARD',
  EQ: 'EQ',
  NE: 'NE',
  GTE: 'GTE',
  LTE: 'LTE',
  EXISTS: 'EXISTS',
  OR_EQ: 'OR_EQ',
  OR_NE: 'OR_NE',
};

export function generateSearchFilter(fields: any[]) {
  const escape_json = (w: string) => {
    w = w.replaceAll('\\', '').replaceAll('\r', '').replaceAll('\n', '').replaceAll('*', '').replaceAll('"', '');
    return w;
  };
  // MEMO: DynamoDBの仕様上、物理削除データが一時的に"_deleted: True"の状態で残る為検索からデフォルトで弾く
  const defalutCondition = '{ "_deleted": {"ne": true } }';
  const and_conditions = fields
    .map((field) => {
      if (typeof field.value !== 'boolean' && !field.value) return; // boolean型ではなく、valueがnull, '', undefinedの場合は取り除く

      if (field.type == SEARCH_TYPE.WILDCARD) {
        // @ts-ignore  //@TODO
        const words = field.value.split(/[\u{20}\u{3000}]/u);
        const or_conditions = words.map((word: string) => {
          return (
            '{ "or": [ { "' +
            field.name +
            '": { "wildcard": "*' +
            escape_json(word) +
            '*" } }, { "' +
            field.name +
            '": { "matchPhrase": "' +
            escape_json(word) +
            '" } }, { "' +
            field.name +
            '": { "matchPhrasePrefix": "' +
            escape_json(word) +
            '" } } ] }'
          );
        });
        return '{ "and": [' + or_conditions.join(',') + '] }';
      }
      // SEARCH_TYPE.WILDCARDの検索では、文字列の先頭が数字で次に「-」が続くと上手く検索が出来ないので以下を追加
      if (field.type == SEARCH_TYPE.UUID_WILDCARD) {
        // @ts-ignore  //@TODO
        const words = field.value.split(/[\u{20}\u{3000}]/u);
        const or_conditions = words.map((word: string) => {
          return (
            '{ "or": [ { "' +
            field.name +
            '": { "wildcard": "*' +
            escape_json(word) +
            '*" } }, { "' +
            field.name +
            '": { "match": "' +
            escape_json(word) +
            '" } }, { "' +
            field.name +
            '": { "matchPhrase": "' +
            escape_json(word) +
            '" } }, { "' +
            field.name +
            '": { "matchPhrasePrefix": "' +
            escape_json(word) +
            '" } } ] }'
          );
        });
        return '{ "and": [' + or_conditions.join(',') + '] }';
      }
      if (field.type == SEARCH_TYPE.EQ) {
        if (typeof field.value === 'boolean') {
          // valueがboolean
          return `{ \"${field.name}\": {"eq": ${field.value} } }`;
        } else {
          // valueがstring
          return '{ "' + field.name + '": {"eq":"' + field.value + '"} }';
        }
      }
      if (field.type == SEARCH_TYPE.NE) {
        if (typeof field.value === 'boolean') {
          // valueがboolean
          return `{ \"${field.name}\": {"ne": ${field.value} } }`;
        } else {
          // valueがstring
          return '{ "' + field.name + '": {"ne":"' + field.value + '"} }';
        }
      }
      if (field.type == SEARCH_TYPE.GTE) {
        return '{ "' + field.name + '": {"gte":"' + field.value + '"} }';
      }
      if (field.type == SEARCH_TYPE.LTE) {
        return '{ "' + field.name + '": {"lte":"' + field.value + '"} }';
      }
      if (field.type == SEARCH_TYPE.EXISTS) {
        return `{ \"${field.name}\": {"exists": ${field.value} } }`;
      }
      // 同じフィールドで複数「or」「eq」で検索する
      // field.value は配列
      if (field.type == SEARCH_TYPE.OR_EQ) {
        const or_conditions = field.value.map((v: string) => {
          return '{ "' + field.name + '": {"eq":"' + v + '"} }';
        });
        return '{ "or": [' + or_conditions.join(',') + ']}';
      }
      // 同じフィールドで複数「or」「ne」で検索する
      // field.value は配列
      if (field.type == SEARCH_TYPE.OR_NE) {
        const or_conditions = field.value.map((v: string) => {
          return '{ "or": [ { "' + field.name + '": {"ne":"' + v + '"} } ] }';
        });
        return or_conditions;
      }
    })
    .filter(Boolean)
    .concat(defalutCondition);
  console.log({ and_conditions });
  const json = '{"filter": { "and": [' + and_conditions.join(',') + ']}}';
  return JSON.parse(json);
}
