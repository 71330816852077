import React, { useState, useEffect, useContext } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { AuthContext } from '../contexts/AuthContext';
import { Bnf } from '../API';
import { Box } from '@mui/material';
import PrimaryButton from '../components/PrimaryButton';
import { theme } from '../assets/theme/options';
import { useSearchBnfHistories } from '../hooks/useSearchBnfHistories';
import { BNF_HISTORY_COLMNS } from '../consts/bnfHistory';
import SearchBnfHistoryDialog from '../components/bnfHistory/SearchBnfHistoryDialog';
import DetaiBnfHistoryDialog from '../components/bnfHistory/DetailBbfHistoryDialog';

function BnfHistoryList() {
  const [selectedRow, setSelectedRow] = useState<Bnf | null>(null)
  const [openSearch, setOpenSearch] = useState(false);
  const { user, roles } = useContext(AuthContext);
  const { fetchBnfHistories, loading, total, pageSize, onChangePageSize, bnfHistories, onChangePage, condition, page, onChangeSort } = useSearchBnfHistories()
  const roleTypes = roles?.map((role) => role.role?.label);

  useEffect(() => {
    fetchBnfHistories({})
    // eslint-disable-next-line
  }, []);

  const doOpenSearch = () => setOpenSearch(true)
  const doOpenDetail = (params) => setSelectedRow(params.row)

  if (!roleTypes) return <></>;

  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} textAlign={"right"}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>

      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={bnfHistories}
          columns={BNF_HISTORY_COLMNS}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenDetail}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
            '& .edited_cell': {
              background: theme.colors.editedCell
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      <SearchBnfHistoryDialog
        condition={condition}
        isAdmin={roleTypes.includes('ADMIN')}
        open={openSearch}
        setOpen={setOpenSearch}
        fetchBnfs={fetchBnfHistories}
      />
      {selectedRow && <DetaiBnfHistoryDialog
        open={!!selectedRow}
        row={selectedRow}
        setRow={setSelectedRow}
        user={user}
      />}
    </Box>
  );

}

export default BnfHistoryList;