import { useState } from 'react';
import { SEARCH_GROUP_COMPANY_FIELDS_DEFAULT_VALUES } from '../consts/groupCompany';
import { searchGroupCompanies } from '../graphql/queries';
import { GroupCompany } from '../models';
import { SearchConditionGroupCompany } from '../types/form/GroupCompany';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import usePageSize from './usePageSize';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

export const dataToFilterBnfObj: {
  [key in keyof SearchConditionGroupCompany]: {
    name: keyof GroupCompany;
    type: string;
  };
} = {
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  group_company_code: {
    name: 'group_company_code',
    type: SEARCH_TYPE.WILDCARD,
  },
  valid_start_date_from: { name: 'valid_start_date', type: SEARCH_TYPE.GTE },
  valid_start_date_to: { name: 'valid_start_date', type: SEARCH_TYPE.LTE },
  valid_end_date_from: { name: 'valid_end_date', type: SEARCH_TYPE.GTE },
  valid_end_date_to: { name: 'valid_end_date', type: SEARCH_TYPE.LTE },
  stravis_code: { name: 'stravis_code', type: SEARCH_TYPE.WILDCARD },
  company_name_ja: { name: 'company_name_ja', type: SEARCH_TYPE.WILDCARD },
  company_name_en: { name: 'company_name_en', type: SEARCH_TYPE.WILDCARD },
  abbreviation: { name: 'abbreviation', type: SEARCH_TYPE.WILDCARD },
  company_attribute: { name: 'company_attribute', type: SEARCH_TYPE.WILDCARD },
  consolidation: { name: 'consolidation', type: SEARCH_TYPE.WILDCARD },
  equity_method_application: {
    name: 'equity_method_application',
    type: SEARCH_TYPE.WILDCARD,
  },
  unit_class_1: { name: 'unit_class_1', type: SEARCH_TYPE.WILDCARD },
  unit_class_2: { name: 'unit_class_2', type: SEARCH_TYPE.WILDCARD },
  management_business_1: {
    name: 'management_business_1',
    type: SEARCH_TYPE.WILDCARD,
  },
  management_business_2: {
    name: 'management_business_2',
    type: SEARCH_TYPE.WILDCARD,
  },
  country_id: { name: 'country_id', type: SEARCH_TYPE.EQ },
  accounting_period: { name: 'accounting_period', type: SEARCH_TYPE.EQ },
  public_or_private: { name: 'public_or_private', type: SEARCH_TYPE.EQ },
  group_agreement_flg: { name: 'group_agreement_flg', type: SEARCH_TYPE.EQ },
  management_contract_flg: {
    name: 'management_contract_flg',
    type: SEARCH_TYPE.EQ,
  },
  subcontracting_contract_flg: {
    name: 'subcontracting_contract_flg',
    type: SEARCH_TYPE.EQ,
  },
  brand_portfolio: { name: 'brand_portfolio', type: SEARCH_TYPE.WILDCARD },
  holding_company_1: {
    name: 'holding_company_1',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_1: { name: 'investment_ratio_1', type: SEARCH_TYPE.EQ },
  holding_company_2: {
    name: 'holding_company_2',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_2: { name: 'investment_ratio_2', type: SEARCH_TYPE.EQ },
  holding_company_3: {
    name: 'holding_company_3',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_3: { name: 'investment_ratio_3', type: SEARCH_TYPE.EQ },
  holding_company_4: {
    name: 'holding_company_4',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_4: { name: 'investment_ratio_4', type: SEARCH_TYPE.EQ },
  holding_company_5: {
    name: 'holding_company_5',
    type: SEARCH_TYPE.WILDCARD,
  },
  investment_ratio_5: { name: 'investment_ratio_5', type: SEARCH_TYPE.EQ },
  total_investment_ratio: {
    name: 'total_investment_ratio',
    type: SEARCH_TYPE.EQ,
  },
  issued_shares_total: { name: 'issued_shares_total', type: SEARCH_TYPE.EQ },
  treasury_stock: { name: 'treasury_stock', type: SEARCH_TYPE.EQ },
  nominee_shares: { name: 'nominee_shares', type: SEARCH_TYPE.EQ },
  capital: { name: 'capital', type: SEARCH_TYPE.EQ },
  currency_id: { name: 'currency_id', type: SEARCH_TYPE.EQ },
  number_of_employees: { name: 'number_of_employees', type: SEARCH_TYPE.EQ },
  establishment_date_from: {
    name: 'establishment_date',
    type: SEARCH_TYPE.GTE,
  },
  establishment_date_to: { name: 'establishment_date', type: SEARCH_TYPE.LTE },
  representative_director_1_position: {
    name: 'representative_director_1_position',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_1_name: {
    name: 'representative_director_1_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_2_position: {
    name: 'representative_director_2_position',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_2_name: {
    name: 'representative_director_2_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_3_position: {
    name: 'representative_director_3_position',
    type: SEARCH_TYPE.WILDCARD,
  },
  representative_director_3_name: {
    name: 'representative_director_3_name',
    type: SEARCH_TYPE.WILDCARD,
  },
  business_description: {
    name: 'business_description',
    type: SEARCH_TYPE.WILDCARD,
  },
  address_1: { name: 'address_1', type: SEARCH_TYPE.WILDCARD },
  address_2: { name: 'address_2', type: SEARCH_TYPE.WILDCARD },
  notes: { name: 'notes', type: SEARCH_TYPE.WILDCARD },
  ip_address: { name: 'ip_address', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  updatedAt_from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  updatedAt_to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
};

export const useSearchGroupCompanyList = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<SearchConditionGroupCompany>(SEARCH_GROUP_COMPANY_FIELDS_DEFAULT_VALUES);
  const [sort, setSort] = useState<{}>({
    direction: 'desc',
    field: 'updatedAt',
  });
  const { pageSize, setPageSize } = usePageSize();
  const [expQueries, setExpQueries] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >();

  const searchGroupCompanyList = async ({
    nextToken,
    newLimit,
    newFilter,
    newSort,
  }: {
    nextToken?: string | null;
    newLimit?: number;
    newFilter?: SearchConditionGroupCompany;
    newSort?: {};
  }) => {
    setLoading(true);
    setFilter(newFilter ? { ...newFilter } : { ...filter });
    const records = Object.entries(newFilter ?? filter)
      .map(([key, value]) => {
        if (value === 'ALL' && key === 'delete_flg') return;
        if (value === '') return;
        return {
          name: dataToFilterBnfObj[key].name,
          value: value,
          type: dataToFilterBnfObj[key].type,
        };
      })
      .filter(Boolean);
    setExpQueries(records);
    const serarchFilter = generateSearchFilter(records);
    console.log({ records, serarchFilter });
    try {
      const models: any = await API.graphql({
        query: searchGroupCompanies,
        variables: {
          ...serarchFilter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });
      setTotal(models.data.searchGroupCompanies.total);
      if (newLimit || newFilter || newSort) {
        setNextTokens([models.data.searchGroupCompanies.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchGroupCompanies.nextToken])));
      }
      setRows([...models.data.searchGroupCompanies.items]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    searchGroupCompanyList({ newLimit });
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    searchGroupCompanyList({ nextToken });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      searchGroupCompanyList({
        newSort: { direction: 'desc', field: 'updatedAt' },
      });
    } else {
      let field: string;
      switch (sortModel[0].field) {
        case 'country':
          field = 'country_id';
          break;
        case 'currency':
          field = 'currency_id';
          break;
        default:
          field = sortModel[0].field;
          break;
      }
      const newSort = { direction: sortModel[0].sort, field };
      setSort({ ...newSort });
      searchGroupCompanyList({ newSort });
    }
  };

  const onChangeFilter = (newFilter: SearchConditionGroupCompany) => {
    setFilter(newFilter);
    searchGroupCompanyList({ newFilter });
  };

  return {
    searchGroupCompanyList,
    onChangePageSize,
    onChangePage,
    onChangeSort,
    onChangeFilter,
    setRows,
    loading,
    total,
    pageSize,
    rows,
    page,
    filter,
    expQueries,
    setExpQueries,
    setFilter,
  };
};
