import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import PrimaryButton from '../components/PrimaryButton';
import { AuthContext } from '../contexts/AuthContext';
import SearchCalendarDialog from '../components/calendar/SearchCalendarDialog';
import { Country } from '../models';
import { Calendar } from '../API';
import { fetchCountries } from '../utils/fetchData';
import EditCalendarDialog from '../components/calendar/EditCalendarDialog';
import { useSearchCalendars } from '../hooks/useSearchCalendars';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';

function CalendarManagement() {
  const columns: GridColDef[] = [
    {
      field: 'calendar_date',
      headerName: '日付',
      width: 223,
      valueFormatter: (params: any) => {
        return format(parseISO(params.value), 'yyyy/MM/dd');
      }
    },
    {
      field: 'year',
      headerName: '年',
      width: 223,
    },
    {
      field: 'month',
      headerName: '月',
      width: 223,
    },
    {
      field: 'day',
      headerName: '日',
      width: 223,
    },
    {
      field: 'day_of_week',
      headerName: '曜日',
      width: 223,
    },
    {
      field: 'holiday_flg',
      headerName: '祝日フラグ',
      width: 223,
      renderCell: (params: GridCellParams) => {
        return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
      },
    },
    {
      field: 'name',
      headerName: '日付名称',
      width: 223,
    },
    {
      field: 'country_id',
      headerName: '国名',
      width: 223,
      renderCell: ({ row }) => {
        return countries.find((country) => country.id == row?.['country_id'])?.name || '';
      },
    },
    {
      field: 'update_reason',
      headerName: '更新理由',
      width: 223,
    },
    {
      field: 'updated_user_id',
      headerName: '最終更新者',
      width: 223,
      valueGetter: (params) => {
        return params.row.updated_user_id;
      },
    },
    {
      field: 'updatedAt',
      headerName: '最終更新日時',
      width: 223,
      valueFormatter: (params: any) => {
        return params.value ? format(parseISO(params.value), 'yyyy/MM/dd HH:mm') : '';
      },
    },
    {
      field: 'delete_flg',
      headerName: '削除済データ',
      width: 190,
      renderCell: (params: GridCellParams) => {
        return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
      },
    },
  ];

  const [countries, setCountries] = useState<Country[]>([]);
  const [openSearch, setOpenSearch] = useState(false)
  const [openEdit, setOpenEdit] = useState(false);
  const [row, setRow] = useState<Calendar | null>(null);
  const { user } = useContext(AuthContext);
  const [isUploaded, setIsUploaded] = useState(false)
  const { fetchCalendar, loading, total, pageSize, onChangePageSize, calendars, setCalendars, onChangePage, page, onChangeSort, condition, expQueries } = useSearchCalendars()

  useEffect(() => {
    fetchCalendar({})
    fetchCountries(setCountries)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isUploaded) return
    fetchCalendar({})
    setIsUploaded(false)
    // eslint-disable-next-line
  }, [isUploaded])

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
          <ExportButton
            condition={expQueries}
            tableName="CALENDAR"
            type='SEARCH'
          />
          <ImportButton tableName="CALENDAR" setIsUploaded={setIsUploaded} />
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 180px)', width: '100%' }}>
        <DataGridPro
          rows={calendars}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openEdit && user && (
        <EditCalendarDialog
          countries={countries}
          onClose={onCloseEdit}
          open={openEdit}
          row={row}
          setRow={setRow}
        />
      )}
      {user && openSearch && <SearchCalendarDialog
        countries={countries}
        condition={condition}
        open={openSearch}
        setOpen={setOpenSearch}
        fetchCalendar={fetchCalendar}
        setRows={setCalendars}
      />}
    </Box >
  );
}

export default CalendarManagement;
