import React, { useContext, useEffect, useState, memo } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { SQS, S3 } from 'aws-sdk';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { onUpdateCsvJob } from '../graphql/subscriptions';
import { createCsvJob } from '../graphql/mutations';
import { AuthContext } from '../contexts/AuthContext';
import { CsvJob } from '../models';
import { useCredentials } from '../hooks/useCredentials';
import awsOptions from '../aws-options';
import awsExports from '../aws-exports';
import { Box, Stack } from '@mui/material';
import StepBar from './StepBar';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

interface ImportButtonProps {
  tableName:
    | 'TITLE'
    | 'PRODUCT'
    | 'TAGBSP'
    | 'RATE'
    | 'CALENDAR'
    | 'PRODUCT_TCD'
    | 'TITLE_CODE'
    | 'TAG_TITLE_CODE'
    | 'BSP'
    | 'BNAM'
    | 'BNML'
    | 'TAGBNML'
    | 'TAGBNAM'
    | 'TAGBNF'
    | 'BNF'
    | 'GROUP_COMPANY';
  setIsUploaded: Dispatch<SetStateAction<boolean>>;
}

type JobStatus = 'STANDBY' | 'READY' | 'VALIDATE' | 'UPDATE' | 'DONE' | 'ERROR';

const Input = styled('input')({
  display: 'none'
});

const steps = [
  '待機中', // STANDBY
  '準備中', // READY
  '検証中', // VALIDATE
  '更新中', // UPDATE
  '完了' // DONE
];

function ImportButton(props: ImportButtonProps) {
  const { tableName, setIsUploaded } = props;
  const [job, setJob] = useState<CsvJob | null>(null);
  const [step, setStep] = useState(0);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    useCredentials();
    subscribeOnUpdateJob();
  }, []);

  useEffect(() => {
    job && setStep((prev) => changeStep(job.status as JobStatus, prev));
    job?.status === 'ERROR' && alertError(job?.message ?? '');
    job?.status === 'DONE' && alertDoneAndResetJob();
    // eslint-disable-next-line
  }, [job?.status]);

  const changeStep = (status: JobStatus, currentStep: number): number => {
    switch (status) {
      case 'STANDBY':
        return 0;
      case 'READY':
        return 1;
      case 'VALIDATE':
        return 2;
      case 'UPDATE':
        return 3;
      case 'DONE':
        return 4;
      case 'ERROR':
        return currentStep;
      default:
        const _: never = status;
    }
  };

  const subscribeOnUpdateJob = () => {
    const client = API.graphql({ query: onUpdateCsvJob });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const updated: CsvJob = data.onUpdateCsvJob;
          setJob((prev) => (prev?.id === updated.id ? updated : prev));
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const alertError = (message: string) => {
    alert(`エラー:\n${message ? message : '不明なエラーが発生しました'}`);
  };

  const alertDoneAndResetJob = () => {
    alert('アップロード完了しました');
    setJob(null);
    setIsUploaded(true);
  };

  const handleClickImport = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // 0. ファイル名の抽出
    const file = (e.target.files as FileList)[0];
    // 1. jobの作成
    const newJob = await newCsvJob(file.name);
    setJob(newJob);
    // 2. fileをS3へアップロード
    const filePath = `csv/import/${tableName.toLowerCase()}/${newJob.id}.csv`;
    try {
      const s3 = new S3();
      await s3
        .putObject({
          Bucket: awsExports.aws_user_files_s3_bucket,
          Key: filePath,
          Body: file
        })
        .promise();
    } catch (err) {
      console.log(err);
      alertError('アップロードエラー\n担当者にお問い合わせください');
      setJob(null);
      return;
    } finally {
      e.target.value = '';
    }
    // 3. キューの送信
    newJob && sendMessage({ id: newJob.id, filePath });
  };

  const newCsvJob = async (fileName: string) => {
    try {
      const record = {
        type: 'IMPORT',
        table_name: tableName,
        updated_user_id: user?.id,
        status: 'STANDBY',
        name: fileName // uploadされたファイル名
      };
      const model: any = await API.graphql({ query: createCsvJob, variables: { input: record } });
      const newJob = (model.data as any).createCsvJob;
      return newJob;
    } catch (e) {
      console.log(e);
    }
  };

  const sendMessage = async (message: { id: string; filePath: string }) => {
    try {
      const sqs = new SQS();
      const { QueueUrl } = await sqs
        .getQueueUrl({
          QueueName: 'importCsv-' + awsOptions.amplify_backend_name
        })
        .promise();
      if (!QueueUrl) {
        alertError('リクエスト送信準備エラー\n担当者にお問い合わせください');
        setJob(null);
        return;
      }
      const MessageBody = JSON.stringify({ message });
      const result = await sqs.sendMessage({ MessageBody, QueueUrl }).promise();
      console.log({ result });
    } catch (e) {
      console.error(e);
      alertError('リクエスト送信準備エラー\n担当者にお問い合わせください');
      setJob(null);
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <label htmlFor="upload-csv">
        <Input accept=".csv" id="upload-csv" type="file" onChange={handleClickImport} />
        <LoadingButton
          loading={['STANDBY', 'READY', 'VALIDATE', 'UPDATE'].includes(job?.status)}
          variant="contained"
          component="span"
          sx={{ height: 40, width: 120 }}
        >
          インポート
        </LoadingButton>
      </label>
      {job && (
        <Box sx={{ width: 640, height: 40, p: 1 }}>
          <StepBar steps={steps} activeStep={step} completed={job.status === 'DONE'} error={job.status === 'ERROR'} />
        </Box>
      )}
    </Stack>
  );
}

export default memo(ImportButton);
