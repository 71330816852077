import { withAuthenticator, ThemeProvider as AmplifyProvider } from '@aws-amplify/ui-react';

import awsExports from './aws-exports';
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { MaintenanceProvider } from './contexts/MaintenanceContext';
import AuthGuard from './libs/auth/AuthGuard';
import AdminCsvExportList from './pages/AdminCsvExportList';
import BnamHistoryList from './pages/BnamHistoryList';
import BnamManagement from './pages/BnamManagement';
import BnfHistoryList from './pages/BnfHistoryList';
import BnfList from './pages/BnfList';
import BnmlHistoryList from './pages/BnmlHistoryList';
import BnmlList from './pages/BnmlList';
import BspHistoryList from './pages/BspHistoryList';
import BspList from './pages/BspList';
import CurrencyManagement from './pages/CurrencyManagement';
import GenreManagement from './pages/GenreManagement';
import HomePage from './pages/HomePage';
import ImportAdminList from './pages/ImportAdminList';
import ImportProductList from './pages/ImportProductList';
import ImportTagBspList from './pages/ImportTagBspList';
import ImportTitleList from './pages/ImportTitleList';
import PlatformManagement from './pages/PlatformManagement';
import ProductClassManagement from './pages/ProductClassManagement';
import ProductHistoryList from './pages/ProductHistoryList';
import ProductList from './pages/ProductList';
import ProductTcdList from './pages/ProductTcdList';
import ProductTypeManagement from './pages/ProductTypeManagement';
import TagBspHistoryList from './pages/TagBspHistoryList';
import TagBspList from './pages/TagBspList';
import TagHistoryList from './pages/TagHistoryList';
import TagList from './pages/TagList';
import TagTitleCodeHistoryList from './pages/TagTitleCodeHistoryList';
import TagTitleHistoryList from './pages/TagTitleHistoryList';
import TagTitleList from './pages/TagTitleList';
import TitleHistoryList from './pages/TitleHistoryList';
import TitleList from './pages/TitleList';
import UserManagement from './pages/UserManagement';
import './App.css';
import '@aws-amplify/ui-react/styles.css';

import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import CountryManagement from './pages/CountryManagement';
import CalendarManagement from './pages/CalendarManagement';
import BusinessEntityManagement from './pages/BusinessEntityManagement';
import NotificationProvider from './providers/NotificationProvider';
import RateManagement from './pages/RateManagement';
import TitleCodeList from './pages/TitleCodeList';
import TitleCodeHistoryList from './pages/TitleCodeHistoryList';
import ProductTcdHistoryList from './pages/ProductTcdHistoryList';
import TagTitleCodeList from './pages/TagTitleCodeList';
import ImportTitleCodeList from './pages/ImportTitleCodeList';
import ImportProductTcdList from './pages/ImportProductTcdList';
import ImportTagTitleCodeList from './pages/ImportTagTitleCodeList';
import ImportBspList from './pages/ImportBspList';
import TagBnmlList from './pages/TagBnmlList';
import ImportBnmlList from './pages/ImportBnmlList';
import ImportBnamList from './pages/ImportBnamList';
import ImportTagBnmlList from './pages/ImportTagBnmlList';
import TagBnmlHistoryList from './pages/TagBnmlHistoryList';
import TagBnamList from './pages/TagBnamList';
import ImportTagBnamList from './pages/ImportTagBnamList';
import TagBnamHistoryList from './pages/TagBnamHistoryList';
import TagBnfHistoryList from './pages/TagBnfHistoryList';
import ImportTagBnfList from './pages/ImportTagBnfList';
import TagBnfList from './pages/TagBnfList';
import ImportBnfList from './pages/ImportBnfList';
import DatalakeManagement from './pages/DatalakeManagement';
import GroupCompanyList from './pages/GroupCompanyList';
import GroupCompanyHistoryList from './pages/GroupCompanyHistoryList';
import IpCsvExportList from './pages/IpCsvExportList';
import ProductCsvExportList from './pages/ProductCsvExportList';
import CompanyCsvExportList from './pages/CompanyCsvExportList';
import ImportGroupCompanyList from './pages/ImportGroupCompanyList';
import DefaultPage from './pages/DefaultPage';

import { Amplify } from 'aws-amplify';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { theme } from './assets/theme/options';
import { is_localhost } from './utils/app';
import config from './amplifyconfiguration.json';

Amplify.configure(awsExports);

const localhost: boolean = is_localhost();

const App = () => {
  LicenseInfo.setLicenseKey(
    //process.env.REACT_APP_DATA_GRID_PRO_LICENSE_KEY || ''
    import.meta.env.VITE_REACT_APP_DATA_GRID_PRO_LICENSE_KEY || '',
  );

  return (
    <MaintenanceProvider>
      <LanguageProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <NotificationProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<DefaultPage />}>
                    <Route index element={<HomePage />} />
                    <Route path="product">
                      <Route
                        path="products"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                            <ProductList />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="tcds"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                            <ProductTcdList />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="titles"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                            <TitleList />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="titleCodes"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                            <TitleCodeList />
                          </AuthGuard>
                        }
                      />
                      <Route path="history">
                        <Route
                          path="titles"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                              <TitleHistoryList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="products"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                              <ProductHistoryList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="titleCodes"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                              <TitleCodeHistoryList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="productTcds"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                              <ProductTcdHistoryList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route path="import">
                        <Route
                          path="titles"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                              <ImportTitleList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="products"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                              <ImportProductList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="titleCodes"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                              <ImportTitleCodeList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="productTcds"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'PRODUCT']}>
                              <ImportProductTcdList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route path="csv">
                        <Route
                          path="exports"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ProductCsvExportList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                    </Route>
                    <Route path="ip">
                      <Route
                        path="tag"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                            <TagList />
                          </AuthGuard>
                        }
                      />
                      <Route path="relation">
                        <Route
                          path="title"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <TagTitleList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bsp"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <TagBspList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="titleCode"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <TagTitleCodeList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bnam"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <TagBnamList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bnml"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <TagBnmlList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bnf"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <TagBnfList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route path="history">
                        <Route
                          path="tag"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <TagHistoryList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bsp"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <BspHistoryList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bnml"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <BnmlHistoryList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bnf"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <BnfHistoryList />
                            </AuthGuard>
                          }
                        />
                        <Route path="relation">
                          <Route
                            path="tag"
                            element={
                              <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                                <TagTitleHistoryList />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="tagTitleCode"
                            element={
                              <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                                <TagTitleCodeHistoryList />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="bsp"
                            element={
                              <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                                <TagBspHistoryList />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="bnml"
                            element={
                              <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                                <TagBnmlHistoryList />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="bnam"
                            element={
                              <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                                <TagBnamHistoryList />
                              </AuthGuard>
                            }
                          />
                        </Route>
                        <Route
                          path="bnam"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <BnamHistoryList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route path="history">
                        <Route path="relation">
                          <Route
                            path="bsp"
                            element={
                              <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                                <TagBspHistoryList />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="bnml"
                            element={
                              <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                                <TagBspHistoryList />
                              </AuthGuard>
                            }
                          />
                          <Route
                            path="bnf"
                            element={
                              <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                                <TagBnfHistoryList />
                              </AuthGuard>
                            }
                          />
                        </Route>
                      </Route>
                      <Route
                        path="bsp"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                            <BspList />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="bnml"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                            <BnmlList />
                          </AuthGuard>
                        }
                      />
                      <Route path="import">
                        <Route
                          path="bsps"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportBspList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bnams"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportBnamList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bnfs"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportBnfList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="bnmls"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportBnmlList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="tagBsps"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportTagBspList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="tagBnmls"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportTagBnmlList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="tagBnams"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportTagBnamList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="tagBnfs"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportTagBnfList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="tagTitleCode"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <ImportTagTitleCodeList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route
                        path="bnam"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                            <BnamManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="bnf"
                        element={
                          <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                            <BnfList />
                          </AuthGuard>
                        }
                      />
                      <Route path="csv">
                        <Route
                          path="exports"
                          element={
                            <AuthGuard allowedRoles={['ADMIN', 'IP']}>
                              <IpCsvExportList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                    </Route>
                    <Route path="company">
                      <Route
                        path="companies"
                        element={
                          <AuthGuard allowedRoles={['COMPANY']}>
                            <GroupCompanyList />
                          </AuthGuard>
                        }
                      />
                      <Route path="history">
                        <Route
                          path="companies"
                          element={
                            <AuthGuard allowedRoles={['COMPANY']}>
                              <GroupCompanyHistoryList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route path="import">
                        <Route
                          path="companies"
                          element={
                            <AuthGuard allowedRoles={['COMPANY']}>
                              <ImportGroupCompanyList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route path="csv">
                        <Route
                          path="exports"
                          element={
                            <AuthGuard allowedRoles={['COMPANY']}>
                              <CompanyCsvExportList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                    </Route>
                    <Route path="admin">
                      <Route
                        path="genres"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <GenreManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="platforms"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <PlatformManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="productTypes"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <ProductTypeManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="productClasses"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <ProductClassManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="currencies"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <CurrencyManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="users"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <UserManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="countries"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <CountryManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="rates"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <RateManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="calendars"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <CalendarManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="businessEntities"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <BusinessEntityManagement />
                          </AuthGuard>
                        }
                      />
                      <Route
                        path="datalake"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <DatalakeManagement />
                          </AuthGuard>
                        }
                      />
                      <Route path="csv">
                        <Route
                          path="exports"
                          element={
                            <AuthGuard allowedRoles={['ADMIN']}>
                              <AdminCsvExportList />
                            </AuthGuard>
                          }
                        />
                        <Route
                          path="imports"
                          element={
                            <AuthGuard allowedRoles={['ADMIN']}>
                              <ImportAdminList />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route
                        path="companies"
                        element={
                          <AuthGuard allowedRoles={['ADMIN']}>
                            <DatalakeManagement />
                          </AuthGuard>
                        }
                      />
                      <Route path="import">
                        <Route
                          path="companies"
                          element={
                            <AuthGuard allowedRoles={['ADMIN']}>
                              <DatalakeManagement />
                            </AuthGuard>
                          }
                        />
                      </Route>
                      <Route path="export">
                        <Route
                          path="companies"
                          element={
                            <AuthGuard allowedRoles={['ADMIN']}>
                              <DatalakeManagement />
                            </AuthGuard>
                          }
                        />
                      </Route>
                    </Route>
                    <Route path="*" element={<p>404!</p>} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </NotificationProvider>
          </AuthProvider>
        </ThemeProvider>
      </LanguageProvider>
    </MaintenanceProvider>
  );
};

export default localhost ? withAuthenticator(App) : App;
