import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      defaultHeaderBg: string;
      devHeaderBg: string;
      mainHeaderBg: string;
      stgHeaderBg: string;
      skritHeaderBg: string;
      featureHeaderBg: string;
      disabled: string;
      attentionBg: string;
      attentionText: string;
      infoBg: string;
      requiredBg: string;
      greenBg: string;
      placeholder: string;
      editedCell: string;
      selectChip?: string;
    };
  }
  interface ThemeOptions {
    colors?: {
      defaultHeaderBg?: string;
      devHeaderBg?: string;
      mainHeaderBg?: string;
      stgHeaderBg?: string;
      skritHeaderBg?: string;
      featureHeaderBg?: string;
      disabled?: string;
      attentionBg?: string;
      attentionText?: string;
      infoBg?: string;
      requiredBg?: string;
      greenBg?: string;
      placeholder?: string;
      editedCell?: string;
      selectChip?: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
  colors: {
    defaultHeaderBg: '#E0E0E0',
    devHeaderBg: '#B3B3B3',
    mainHeaderBg: '#A0C238',
    stgHeaderBg: '#f7d254',
    skritHeaderBg: '#FF9933',
    featureHeaderBg: '#DB84FA',
    disabled: '#E0E0E0',
    attentionBg: '#ED6C02',
    attentionText: '#FFF',
    infoBg: '#2196F3',
    requiredBg: '#FF0000',
    greenBg: '#128807',
    placeholder: '#9e9e9e',
    editedCell: '#fce2c7',
    selectChip: '#E0E0E0',
  },
});
