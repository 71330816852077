import { useState } from 'react';
import { searchCountries } from '../graphql/queries';
import usePageSize from './usePageSize';
import { Country } from '../models';
import { CoutrySearch } from '../types/form';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import { SEARCH_COUNTRY_FIELDS_DEFAULT_VALUES } from '../consts/country';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = [
  { direction: 'asc', field: 'order_id' },
  { direction: 'asc', field: 'code_two_char' },
];

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

const dataToFilterObj: StringKeyObj = {
  order_id: { name: 'order_id', type: SEARCH_TYPE.EQ },
  name: { name: 'name', type: SEARCH_TYPE.WILDCARD },
  name_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  country_code: { name: 'country_code', type: SEARCH_TYPE.EQ },
  code_two_char: { name: 'code_two_char', type: SEARCH_TYPE.WILDCARD },
  code_three_char: { name: 'code_three_char', type: SEARCH_TYPE.WILDCARD },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
};

export const useSearchCountries = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<CoutrySearch>(SEARCH_COUNTRY_FIELDS_DEFAULT_VALUES);

  const fetchCountries = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: CoutrySearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const records = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          return {
            name: dataToFilterObj[key].name,
            value: value,
            type: dataToFilterObj[key].type,
          };
        })
        .filter(Boolean);
      const filter = generateSearchFilter(records);
      const models: any = await API.graphql({
        query: searchCountries,
        variables: {
          ...filter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });

      setTotal(models.data.searchCountries.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchCountries.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchCountries.nextToken])));
      }
      newCondition && setCondition({ ...newCondition });
      setCountries([...models.data.searchCountries.items]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchCountries({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchCountries({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchCountries({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchCountries({ nextToken });
  };

  return {
    fetchCountries,
    loading,
    total,
    pageSize,
    onChangePageSize,
    countries,
    setCountries,
    onChangePage,
    page,
    onChangeSort,
    condition,
  };
};
