/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const incrementSequence = /* GraphQL */ `mutation IncrementSequence($input: IncrementSequenceInput!) {
  incrementSequence(input: $input) {
    id
    table_label
    current_number
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.IncrementSequenceMutationVariables,
  APITypes.IncrementSequenceMutation
>;
export const createSequence = /* GraphQL */ `mutation CreateSequence(
  $input: CreateSequenceInput!
  $condition: ModelSequenceConditionInput
) {
  createSequence(input: $input, condition: $condition) {
    id
    table_label
    current_number
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSequenceMutationVariables,
  APITypes.CreateSequenceMutation
>;
export const updateSequence = /* GraphQL */ `mutation UpdateSequence(
  $input: UpdateSequenceInput!
  $condition: ModelSequenceConditionInput
) {
  updateSequence(input: $input, condition: $condition) {
    id
    table_label
    current_number
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSequenceMutationVariables,
  APITypes.UpdateSequenceMutation
>;
export const deleteSequence = /* GraphQL */ `mutation DeleteSequence(
  $input: DeleteSequenceInput!
  $condition: ModelSequenceConditionInput
) {
  deleteSequence(input: $input, condition: $condition) {
    id
    table_label
    current_number
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSequenceMutationVariables,
  APITypes.DeleteSequenceMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    cognito_user_id
    email
    name
    roles {
      items {
        id
        user_id
        role_id
        user {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role {
          id
          seq_id
          label
          name
          default
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    cognito_user_id
    email
    name
    roles {
      items {
        id
        user_id
        role_id
        user {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role {
          id
          seq_id
          label
          name
          default
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    cognito_user_id
    email
    name
    roles {
      items {
        id
        user_id
        role_id
        user {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role {
          id
          seq_id
          label
          name
          default
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createRole = /* GraphQL */ `mutation CreateRole(
  $input: CreateRoleInput!
  $condition: ModelRoleConditionInput
) {
  createRole(input: $input, condition: $condition) {
    id
    seq_id
    label
    name
    default
    users {
      items {
        id
        user_id
        role_id
        user {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role {
          id
          seq_id
          label
          name
          default
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleMutationVariables,
  APITypes.CreateRoleMutation
>;
export const updateRole = /* GraphQL */ `mutation UpdateRole(
  $input: UpdateRoleInput!
  $condition: ModelRoleConditionInput
) {
  updateRole(input: $input, condition: $condition) {
    id
    seq_id
    label
    name
    default
    users {
      items {
        id
        user_id
        role_id
        user {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role {
          id
          seq_id
          label
          name
          default
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoleMutationVariables,
  APITypes.UpdateRoleMutation
>;
export const deleteRole = /* GraphQL */ `mutation DeleteRole(
  $input: DeleteRoleInput!
  $condition: ModelRoleConditionInput
) {
  deleteRole(input: $input, condition: $condition) {
    id
    seq_id
    label
    name
    default
    users {
      items {
        id
        user_id
        role_id
        user {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role {
          id
          seq_id
          label
          name
          default
          users {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        delete_flg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoleMutationVariables,
  APITypes.DeleteRoleMutation
>;
export const createUserRoles = /* GraphQL */ `mutation CreateUserRoles(
  $input: CreateUserRolesInput!
  $condition: ModelUserRolesConditionInput
) {
  createUserRoles(input: $input, condition: $condition) {
    id
    user_id
    role_id
    user {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    role {
      id
      seq_id
      label
      name
      default
      users {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserRolesMutationVariables,
  APITypes.CreateUserRolesMutation
>;
export const updateUserRoles = /* GraphQL */ `mutation UpdateUserRoles(
  $input: UpdateUserRolesInput!
  $condition: ModelUserRolesConditionInput
) {
  updateUserRoles(input: $input, condition: $condition) {
    id
    user_id
    role_id
    user {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    role {
      id
      seq_id
      label
      name
      default
      users {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserRolesMutationVariables,
  APITypes.UpdateUserRolesMutation
>;
export const deleteUserRoles = /* GraphQL */ `mutation DeleteUserRoles(
  $input: DeleteUserRolesInput!
  $condition: ModelUserRolesConditionInput
) {
  deleteUserRoles(input: $input, condition: $condition) {
    id
    user_id
    role_id
    user {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    role {
      id
      seq_id
      label
      name
      default
      users {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserRolesMutationVariables,
  APITypes.DeleteUserRolesMutation
>;
export const createGenre = /* GraphQL */ `mutation CreateGenre(
  $input: CreateGenreInput!
  $condition: ModelGenreConditionInput
) {
  createGenre(input: $input, condition: $condition) {
    id
    ce_link_id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGenreMutationVariables,
  APITypes.CreateGenreMutation
>;
export const updateGenre = /* GraphQL */ `mutation UpdateGenre(
  $input: UpdateGenreInput!
  $condition: ModelGenreConditionInput
) {
  updateGenre(input: $input, condition: $condition) {
    id
    ce_link_id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGenreMutationVariables,
  APITypes.UpdateGenreMutation
>;
export const deleteGenre = /* GraphQL */ `mutation DeleteGenre(
  $input: DeleteGenreInput!
  $condition: ModelGenreConditionInput
) {
  deleteGenre(input: $input, condition: $condition) {
    id
    ce_link_id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGenreMutationVariables,
  APITypes.DeleteGenreMutation
>;
export const createPlatform = /* GraphQL */ `mutation CreatePlatform(
  $input: CreatePlatformInput!
  $condition: ModelPlatformConditionInput
) {
  createPlatform(input: $input, condition: $condition) {
    id
    seq_id
    name
    banasuke_name
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlatformMutationVariables,
  APITypes.CreatePlatformMutation
>;
export const updatePlatform = /* GraphQL */ `mutation UpdatePlatform(
  $input: UpdatePlatformInput!
  $condition: ModelPlatformConditionInput
) {
  updatePlatform(input: $input, condition: $condition) {
    id
    seq_id
    name
    banasuke_name
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlatformMutationVariables,
  APITypes.UpdatePlatformMutation
>;
export const deletePlatform = /* GraphQL */ `mutation DeletePlatform(
  $input: DeletePlatformInput!
  $condition: ModelPlatformConditionInput
) {
  deletePlatform(input: $input, condition: $condition) {
    id
    seq_id
    name
    banasuke_name
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlatformMutationVariables,
  APITypes.DeletePlatformMutation
>;
export const createProductClass = /* GraphQL */ `mutation CreateProductClass(
  $input: CreateProductClassInput!
  $condition: ModelProductClassConditionInput
) {
  createProductClass(input: $input, condition: $condition) {
    id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductClassMutationVariables,
  APITypes.CreateProductClassMutation
>;
export const updateProductClass = /* GraphQL */ `mutation UpdateProductClass(
  $input: UpdateProductClassInput!
  $condition: ModelProductClassConditionInput
) {
  updateProductClass(input: $input, condition: $condition) {
    id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductClassMutationVariables,
  APITypes.UpdateProductClassMutation
>;
export const deleteProductClass = /* GraphQL */ `mutation DeleteProductClass(
  $input: DeleteProductClassInput!
  $condition: ModelProductClassConditionInput
) {
  deleteProductClass(input: $input, condition: $condition) {
    id
    seq_id
    name
    name_ja
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductClassMutationVariables,
  APITypes.DeleteProductClassMutation
>;
export const createProductType = /* GraphQL */ `mutation CreateProductType(
  $input: CreateProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  createProductType(input: $input, condition: $condition) {
    id
    seq_id
    name
    name_ja
    banasuke_name
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductTypeMutationVariables,
  APITypes.CreateProductTypeMutation
>;
export const updateProductType = /* GraphQL */ `mutation UpdateProductType(
  $input: UpdateProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  updateProductType(input: $input, condition: $condition) {
    id
    seq_id
    name
    name_ja
    banasuke_name
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductTypeMutationVariables,
  APITypes.UpdateProductTypeMutation
>;
export const deleteProductType = /* GraphQL */ `mutation DeleteProductType(
  $input: DeleteProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  deleteProductType(input: $input, condition: $condition) {
    id
    seq_id
    name
    name_ja
    banasuke_name
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductTypeMutationVariables,
  APITypes.DeleteProductTypeMutation
>;
export const createCurrency = /* GraphQL */ `mutation CreateCurrency(
  $input: CreateCurrencyInput!
  $condition: ModelCurrencyConditionInput
) {
  createCurrency(input: $input, condition: $condition) {
    id
    order_id
    currency_code
    code_three_char
    name
    name_en
    digit_number
    currency_symbol
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCurrencyMutationVariables,
  APITypes.CreateCurrencyMutation
>;
export const updateCurrency = /* GraphQL */ `mutation UpdateCurrency(
  $input: UpdateCurrencyInput!
  $condition: ModelCurrencyConditionInput
) {
  updateCurrency(input: $input, condition: $condition) {
    id
    order_id
    currency_code
    code_three_char
    name
    name_en
    digit_number
    currency_symbol
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCurrencyMutationVariables,
  APITypes.UpdateCurrencyMutation
>;
export const deleteCurrency = /* GraphQL */ `mutation DeleteCurrency(
  $input: DeleteCurrencyInput!
  $condition: ModelCurrencyConditionInput
) {
  deleteCurrency(input: $input, condition: $condition) {
    id
    order_id
    currency_code
    code_three_char
    name
    name_en
    digit_number
    currency_symbol
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCurrencyMutationVariables,
  APITypes.DeleteCurrencyMutation
>;
export const createDataSource = /* GraphQL */ `mutation CreateDataSource(
  $input: CreateDataSourceInput!
  $condition: ModelDataSourceConditionInput
) {
  createDataSource(input: $input, condition: $condition) {
    id
    seq_id
    name
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDataSourceMutationVariables,
  APITypes.CreateDataSourceMutation
>;
export const updateDataSource = /* GraphQL */ `mutation UpdateDataSource(
  $input: UpdateDataSourceInput!
  $condition: ModelDataSourceConditionInput
) {
  updateDataSource(input: $input, condition: $condition) {
    id
    seq_id
    name
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataSourceMutationVariables,
  APITypes.UpdateDataSourceMutation
>;
export const deleteDataSource = /* GraphQL */ `mutation DeleteDataSource(
  $input: DeleteDataSourceInput!
  $condition: ModelDataSourceConditionInput
) {
  deleteDataSource(input: $input, condition: $condition) {
    id
    seq_id
    name
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDataSourceMutationVariables,
  APITypes.DeleteDataSourceMutation
>;
export const createTag = /* GraphQL */ `mutation CreateTag(
  $input: CreateTagInput!
  $condition: ModelTagConditionInput
) {
  createTag(input: $input, condition: $condition) {
    id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    titles {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    title_codes {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bsps {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnams {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnmls {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnfs {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagMutationVariables,
  APITypes.CreateTagMutation
>;
export const updateTag = /* GraphQL */ `mutation UpdateTag(
  $input: UpdateTagInput!
  $condition: ModelTagConditionInput
) {
  updateTag(input: $input, condition: $condition) {
    id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    titles {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    title_codes {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bsps {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnams {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnmls {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnfs {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagMutationVariables,
  APITypes.UpdateTagMutation
>;
export const deleteTag = /* GraphQL */ `mutation DeleteTag(
  $input: DeleteTagInput!
  $condition: ModelTagConditionInput
) {
  deleteTag(input: $input, condition: $condition) {
    id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    titles {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    title_codes {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bsps {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnams {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnmls {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnfs {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    delete_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagMutationVariables,
  APITypes.DeleteTagMutation
>;
export const createTagHistory = /* GraphQL */ `mutation CreateTagHistory(
  $input: CreateTagHistoryInput!
  $condition: ModelTagHistoryConditionInput
) {
  createTagHistory(input: $input, condition: $condition) {
    id
    tag_id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagHistoryMutationVariables,
  APITypes.CreateTagHistoryMutation
>;
export const updateTagHistory = /* GraphQL */ `mutation UpdateTagHistory(
  $input: UpdateTagHistoryInput!
  $condition: ModelTagHistoryConditionInput
) {
  updateTagHistory(input: $input, condition: $condition) {
    id
    tag_id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagHistoryMutationVariables,
  APITypes.UpdateTagHistoryMutation
>;
export const deleteTagHistory = /* GraphQL */ `mutation DeleteTagHistory(
  $input: DeleteTagHistoryInput!
  $condition: ModelTagHistoryConditionInput
) {
  deleteTagHistory(input: $input, condition: $condition) {
    id
    tag_id
    seq_id
    name_ja
    name_en
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagHistoryMutationVariables,
  APITypes.DeleteTagHistoryMutation
>;
export const createTitle = /* GraphQL */ `mutation CreateTitle(
  $input: CreateTitleInput!
  $condition: ModelTitleConditionInput
) {
  createTitle(input: $input, condition: $condition) {
    id
    seq_id
    name_ja
    name_en
    search_name
    sap_title_code
    bn_connect_title_id
    project_cd
    banasuke_title_id
    ce_link_key_code
    update_reason
    genre_id
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTitleMutationVariables,
  APITypes.CreateTitleMutation
>;
export const updateTitle = /* GraphQL */ `mutation UpdateTitle(
  $input: UpdateTitleInput!
  $condition: ModelTitleConditionInput
) {
  updateTitle(input: $input, condition: $condition) {
    id
    seq_id
    name_ja
    name_en
    search_name
    sap_title_code
    bn_connect_title_id
    project_cd
    banasuke_title_id
    ce_link_key_code
    update_reason
    genre_id
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTitleMutationVariables,
  APITypes.UpdateTitleMutation
>;
export const deleteTitle = /* GraphQL */ `mutation DeleteTitle(
  $input: DeleteTitleInput!
  $condition: ModelTitleConditionInput
) {
  deleteTitle(input: $input, condition: $condition) {
    id
    seq_id
    name_ja
    name_en
    search_name
    sap_title_code
    bn_connect_title_id
    project_cd
    banasuke_title_id
    ce_link_key_code
    update_reason
    genre_id
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title {
          id
          seq_id
          name_ja
          name_en
          search_name
          sap_title_code
          bn_connect_title_id
          project_cd
          banasuke_title_id
          ce_link_key_code
          update_reason
          genre_id
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          admin_check_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTitleMutationVariables,
  APITypes.DeleteTitleMutation
>;
export const createTitleCode = /* GraphQL */ `mutation CreateTitleCode(
  $input: CreateTitleCodeInput!
  $condition: ModelTitleCodeConditionInput
) {
  createTitleCode(input: $input, condition: $condition) {
    id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    business_entity_id
    business_entity {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_ids
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    valid_flg
    update_reason
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTitleCodeMutationVariables,
  APITypes.CreateTitleCodeMutation
>;
export const updateTitleCode = /* GraphQL */ `mutation UpdateTitleCode(
  $input: UpdateTitleCodeInput!
  $condition: ModelTitleCodeConditionInput
) {
  updateTitleCode(input: $input, condition: $condition) {
    id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    business_entity_id
    business_entity {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_ids
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    valid_flg
    update_reason
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTitleCodeMutationVariables,
  APITypes.UpdateTitleCodeMutation
>;
export const deleteTitleCode = /* GraphQL */ `mutation DeleteTitleCode(
  $input: DeleteTitleCodeInput!
  $condition: ModelTitleCodeConditionInput
) {
  deleteTitleCode(input: $input, condition: $condition) {
    id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    business_entity_id
    business_entity {
      id
      seq_id
      name
      description
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_ids
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tags {
      items {
        id
        tag_id
        title_code_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        title_code {
          id
          seq_id
          title_code
          name_ja
          name_en
          official_name_flg
          sap_title_code_name
          search_name
          project_cd
          wbs
          bn_connect_title_ids
          ce_link_key_code
          ce_link_title_name_ja
          ce_link_title_name_en
          genre_ids
          banasuke_title_id
          banasuke_title_name_ja
          bundle_source_title_code_ids
          porting_source_title_code_ids
          business_entity_id
          business_entity {
            id
            seq_id
            name
            description
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          platform_ids
          data_source_id
          data_source {
            id
            seq_id
            name
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tags {
            nextToken
            startedAt
            __typename
          }
          valid_flg
          update_reason
          system_update_flg
          admin_check_flg
          delete_flg
          deleted_at
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    valid_flg
    update_reason
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTitleCodeMutationVariables,
  APITypes.DeleteTitleCodeMutation
>;
export const createTitleHistory = /* GraphQL */ `mutation CreateTitleHistory(
  $input: CreateTitleHistoryInput!
  $condition: ModelTitleHistoryConditionInput
) {
  createTitleHistory(input: $input, condition: $condition) {
    id
    title_id
    seq_id
    name_ja
    name_en
    search_name
    genre_id
    genre_name
    sap_title_code
    project_cd
    bn_connect_title_id
    banasuke_title_id
    ce_link_key_code
    data_source_id
    data_source_name
    update_reason
    admin_check_flg
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    system_update_flg
    old_id
    old_title_id
    old_seq_id
    old_name_ja
    old_name_en
    old_search_name
    old_genre_id
    old_genre_name
    old_sap_title_code
    old_project_cd
    old_bn_connect_title_id
    old_banasuke_title_id
    old_ce_link_key_code
    old_data_source_id
    old_data_source_name
    old_update_reason
    old_admin_check_flg
    old_system_update_flg
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTitleHistoryMutationVariables,
  APITypes.CreateTitleHistoryMutation
>;
export const updateTitleHistory = /* GraphQL */ `mutation UpdateTitleHistory(
  $input: UpdateTitleHistoryInput!
  $condition: ModelTitleHistoryConditionInput
) {
  updateTitleHistory(input: $input, condition: $condition) {
    id
    title_id
    seq_id
    name_ja
    name_en
    search_name
    genre_id
    genre_name
    sap_title_code
    project_cd
    bn_connect_title_id
    banasuke_title_id
    ce_link_key_code
    data_source_id
    data_source_name
    update_reason
    admin_check_flg
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    system_update_flg
    old_id
    old_title_id
    old_seq_id
    old_name_ja
    old_name_en
    old_search_name
    old_genre_id
    old_genre_name
    old_sap_title_code
    old_project_cd
    old_bn_connect_title_id
    old_banasuke_title_id
    old_ce_link_key_code
    old_data_source_id
    old_data_source_name
    old_update_reason
    old_admin_check_flg
    old_system_update_flg
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTitleHistoryMutationVariables,
  APITypes.UpdateTitleHistoryMutation
>;
export const deleteTitleHistory = /* GraphQL */ `mutation DeleteTitleHistory(
  $input: DeleteTitleHistoryInput!
  $condition: ModelTitleHistoryConditionInput
) {
  deleteTitleHistory(input: $input, condition: $condition) {
    id
    title_id
    seq_id
    name_ja
    name_en
    search_name
    genre_id
    genre_name
    sap_title_code
    project_cd
    bn_connect_title_id
    banasuke_title_id
    ce_link_key_code
    data_source_id
    data_source_name
    update_reason
    admin_check_flg
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    system_update_flg
    old_id
    old_title_id
    old_seq_id
    old_name_ja
    old_name_en
    old_search_name
    old_genre_id
    old_genre_name
    old_sap_title_code
    old_project_cd
    old_bn_connect_title_id
    old_banasuke_title_id
    old_ce_link_key_code
    old_data_source_id
    old_data_source_name
    old_update_reason
    old_admin_check_flg
    old_system_update_flg
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTitleHistoryMutationVariables,
  APITypes.DeleteTitleHistoryMutation
>;
export const createTitleCodeHistory = /* GraphQL */ `mutation CreateTitleCodeHistory(
  $input: CreateTitleCodeHistoryInput!
  $condition: ModelTitleCodeHistoryConditionInput
) {
  createTitleCodeHistory(input: $input, condition: $condition) {
    id
    title_code_id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    genre_names
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    platform_ids
    platform_names
    business_entity_id
    business_entity_name
    data_source_id
    data_source_name
    valid_flg
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    update_reason
    updated_user_id
    updated_user_name
    createdAt
    updatedAt
    operation_type
    old_id
    old_title_code_id
    old_seq_id
    old_title_code
    old_name_ja
    old_name_en
    old_official_name_flg
    old_sap_title_code_name
    old_search_name
    old_project_cd
    old_wbs
    old_bn_connect_title_ids
    old_ce_link_key_code
    old_ce_link_title_name_ja
    old_ce_link_title_name_en
    old_genre_ids
    old_genre_names
    old_banasuke_title_id
    old_banasuke_title_name_ja
    old_bundle_source_title_code_ids
    old_porting_source_title_code_ids
    old_platform_ids
    old_platform_names
    old_business_entity_id
    old_business_entity_name
    old_data_source_id
    old_data_source_name
    old_valid_flg
    old_system_update_flg
    old_admin_check_flg
    old_delete_flg
    old_deleted_at
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_createdAt
    old_updatedAt
    old_operation_type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTitleCodeHistoryMutationVariables,
  APITypes.CreateTitleCodeHistoryMutation
>;
export const updateTitleCodeHistory = /* GraphQL */ `mutation UpdateTitleCodeHistory(
  $input: UpdateTitleCodeHistoryInput!
  $condition: ModelTitleCodeHistoryConditionInput
) {
  updateTitleCodeHistory(input: $input, condition: $condition) {
    id
    title_code_id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    genre_names
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    platform_ids
    platform_names
    business_entity_id
    business_entity_name
    data_source_id
    data_source_name
    valid_flg
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    update_reason
    updated_user_id
    updated_user_name
    createdAt
    updatedAt
    operation_type
    old_id
    old_title_code_id
    old_seq_id
    old_title_code
    old_name_ja
    old_name_en
    old_official_name_flg
    old_sap_title_code_name
    old_search_name
    old_project_cd
    old_wbs
    old_bn_connect_title_ids
    old_ce_link_key_code
    old_ce_link_title_name_ja
    old_ce_link_title_name_en
    old_genre_ids
    old_genre_names
    old_banasuke_title_id
    old_banasuke_title_name_ja
    old_bundle_source_title_code_ids
    old_porting_source_title_code_ids
    old_platform_ids
    old_platform_names
    old_business_entity_id
    old_business_entity_name
    old_data_source_id
    old_data_source_name
    old_valid_flg
    old_system_update_flg
    old_admin_check_flg
    old_delete_flg
    old_deleted_at
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_createdAt
    old_updatedAt
    old_operation_type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTitleCodeHistoryMutationVariables,
  APITypes.UpdateTitleCodeHistoryMutation
>;
export const deleteTitleCodeHistory = /* GraphQL */ `mutation DeleteTitleCodeHistory(
  $input: DeleteTitleCodeHistoryInput!
  $condition: ModelTitleCodeHistoryConditionInput
) {
  deleteTitleCodeHistory(input: $input, condition: $condition) {
    id
    title_code_id
    seq_id
    title_code
    name_ja
    name_en
    official_name_flg
    sap_title_code_name
    search_name
    project_cd
    wbs
    bn_connect_title_ids
    ce_link_key_code
    ce_link_title_name_ja
    ce_link_title_name_en
    genre_ids
    genre_names
    banasuke_title_id
    banasuke_title_name_ja
    bundle_source_title_code_ids
    porting_source_title_code_ids
    platform_ids
    platform_names
    business_entity_id
    business_entity_name
    data_source_id
    data_source_name
    valid_flg
    system_update_flg
    admin_check_flg
    delete_flg
    deleted_at
    update_reason
    updated_user_id
    updated_user_name
    createdAt
    updatedAt
    operation_type
    old_id
    old_title_code_id
    old_seq_id
    old_title_code
    old_name_ja
    old_name_en
    old_official_name_flg
    old_sap_title_code_name
    old_search_name
    old_project_cd
    old_wbs
    old_bn_connect_title_ids
    old_ce_link_key_code
    old_ce_link_title_name_ja
    old_ce_link_title_name_en
    old_genre_ids
    old_genre_names
    old_banasuke_title_id
    old_banasuke_title_name_ja
    old_bundle_source_title_code_ids
    old_porting_source_title_code_ids
    old_platform_ids
    old_platform_names
    old_business_entity_id
    old_business_entity_name
    old_data_source_id
    old_data_source_name
    old_valid_flg
    old_system_update_flg
    old_admin_check_flg
    old_delete_flg
    old_deleted_at
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_createdAt
    old_updatedAt
    old_operation_type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTitleCodeHistoryMutationVariables,
  APITypes.DeleteTitleCodeHistoryMutation
>;
export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    seq_id
    name
    common_name
    title_id
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updated_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    seq_id
    name
    common_name
    title_id
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updated_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    seq_id
    name
    common_name
    title_id
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    updated_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createProductTcd = /* GraphQL */ `mutation CreateProductTcd(
  $input: CreateProductTcdInput!
  $condition: ModelProductTcdConditionInput
) {
  createProductTcd(input: $input, condition: $condition) {
    id
    seq_id
    name
    common_name
    title_code_id
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    system_update_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductTcdMutationVariables,
  APITypes.CreateProductTcdMutation
>;
export const updateProductTcd = /* GraphQL */ `mutation UpdateProductTcd(
  $input: UpdateProductTcdInput!
  $condition: ModelProductTcdConditionInput
) {
  updateProductTcd(input: $input, condition: $condition) {
    id
    seq_id
    name
    common_name
    title_code_id
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    system_update_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductTcdMutationVariables,
  APITypes.UpdateProductTcdMutation
>;
export const deleteProductTcd = /* GraphQL */ `mutation DeleteProductTcd(
  $input: DeleteProductTcdInput!
  $condition: ModelProductTcdConditionInput
) {
  deleteProductTcd(input: $input, condition: $condition) {
    id
    seq_id
    name
    common_name
    title_code_id
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_type_id
    product_type {
      id
      seq_id
      name
      name_ja
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    product_class_id
    product_class {
      id
      seq_id
      name
      name_ja
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    platform_id
    platform {
      id
      seq_id
      name
      banasuke_name
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    base_price
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    data_source_id
    data_source {
      id
      seq_id
      name
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    app_id
    market
    admin_check_flg
    system_update_flg
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductTcdMutationVariables,
  APITypes.DeleteProductTcdMutation
>;
export const createProductHistory = /* GraphQL */ `mutation CreateProductHistory(
  $input: CreateProductHistoryInput!
  $condition: ModelProductHistoryConditionInput
) {
  createProductHistory(input: $input, condition: $condition) {
    id
    product_id
    seq_id
    name
    common_name
    title_id
    title_name
    product_type_id
    product_type_name
    product_class_id
    product_class_name
    platform_id
    platform_name
    base_price
    currency_id
    currency_name
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    data_source_id
    data_source_name
    app_id
    market
    admin_check_flg
    system_update_flg
    updated_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    old_id
    old_product_id
    old_seq_id
    old_name
    old_common_name
    old_title_id
    old_title_name
    old_product_type_id
    old_product_type_name
    old_product_class_id
    old_product_class_name
    old_platform_id
    old_platform_name
    old_base_price
    old_currency_id
    old_currency_name
    old_info_release_date
    old_preorder_start_date
    old_release_date
    old_release_status
    old_service_start_date
    old_service_end_date
    old_support_end_date
    old_product_key
    old_sku_number_apple
    old_package_name_google
    old_bank_app_id
    old_data_source_id
    old_data_source_name
    old_app_id
    old_market
    old_admin_check_flg
    old_system_update_flg
    old_updated_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductHistoryMutationVariables,
  APITypes.CreateProductHistoryMutation
>;
export const updateProductHistory = /* GraphQL */ `mutation UpdateProductHistory(
  $input: UpdateProductHistoryInput!
  $condition: ModelProductHistoryConditionInput
) {
  updateProductHistory(input: $input, condition: $condition) {
    id
    product_id
    seq_id
    name
    common_name
    title_id
    title_name
    product_type_id
    product_type_name
    product_class_id
    product_class_name
    platform_id
    platform_name
    base_price
    currency_id
    currency_name
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    data_source_id
    data_source_name
    app_id
    market
    admin_check_flg
    system_update_flg
    updated_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    old_id
    old_product_id
    old_seq_id
    old_name
    old_common_name
    old_title_id
    old_title_name
    old_product_type_id
    old_product_type_name
    old_product_class_id
    old_product_class_name
    old_platform_id
    old_platform_name
    old_base_price
    old_currency_id
    old_currency_name
    old_info_release_date
    old_preorder_start_date
    old_release_date
    old_release_status
    old_service_start_date
    old_service_end_date
    old_support_end_date
    old_product_key
    old_sku_number_apple
    old_package_name_google
    old_bank_app_id
    old_data_source_id
    old_data_source_name
    old_app_id
    old_market
    old_admin_check_flg
    old_system_update_flg
    old_updated_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductHistoryMutationVariables,
  APITypes.UpdateProductHistoryMutation
>;
export const deleteProductHistory = /* GraphQL */ `mutation DeleteProductHistory(
  $input: DeleteProductHistoryInput!
  $condition: ModelProductHistoryConditionInput
) {
  deleteProductHistory(input: $input, condition: $condition) {
    id
    product_id
    seq_id
    name
    common_name
    title_id
    title_name
    product_type_id
    product_type_name
    product_class_id
    product_class_name
    platform_id
    platform_name
    base_price
    currency_id
    currency_name
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    data_source_id
    data_source_name
    app_id
    market
    admin_check_flg
    system_update_flg
    updated_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    operation_type
    createdAt
    updatedAt
    old_id
    old_product_id
    old_seq_id
    old_name
    old_common_name
    old_title_id
    old_title_name
    old_product_type_id
    old_product_type_name
    old_product_class_id
    old_product_class_name
    old_platform_id
    old_platform_name
    old_base_price
    old_currency_id
    old_currency_name
    old_info_release_date
    old_preorder_start_date
    old_release_date
    old_release_status
    old_service_start_date
    old_service_end_date
    old_support_end_date
    old_product_key
    old_sku_number_apple
    old_package_name_google
    old_bank_app_id
    old_data_source_id
    old_data_source_name
    old_app_id
    old_market
    old_admin_check_flg
    old_system_update_flg
    old_updated_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductHistoryMutationVariables,
  APITypes.DeleteProductHistoryMutation
>;
export const createProductTcdHistory = /* GraphQL */ `mutation CreateProductTcdHistory(
  $input: CreateProductTcdHistoryInput!
  $condition: ModelProductTcdHistoryConditionInput
) {
  createProductTcdHistory(input: $input, condition: $condition) {
    id
    product_tcd_id
    seq_id
    name
    common_name
    title_code_id
    title_code_name
    product_type_id
    product_type_name
    product_class_id
    product_class_name
    platform_id
    platform_name
    base_price
    currency_id
    currency_name
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    data_source_id
    data_source_name
    app_id
    market
    admin_check_flg
    system_update_flg
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    operation_type
    createdAt
    updatedAt
    deleted_at
    old_id
    old_product_tcd_id
    old_seq_id
    old_name
    old_common_name
    old_title_code_id
    old_title_code_name
    old_product_type_id
    old_product_type_name
    old_product_class_id
    old_product_class_name
    old_platform_id
    old_platform_name
    old_base_price
    old_currency_id
    old_currency_name
    old_info_release_date
    old_preorder_start_date
    old_release_date
    old_release_status
    old_service_start_date
    old_service_end_date
    old_support_end_date
    old_product_key
    old_sku_number_apple
    old_package_name_google
    old_bank_app_id
    old_data_source_id
    old_data_source_name
    old_app_id
    old_market
    old_admin_check_flg
    old_system_update_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductTcdHistoryMutationVariables,
  APITypes.CreateProductTcdHistoryMutation
>;
export const updateProductTcdHistory = /* GraphQL */ `mutation UpdateProductTcdHistory(
  $input: UpdateProductTcdHistoryInput!
  $condition: ModelProductTcdHistoryConditionInput
) {
  updateProductTcdHistory(input: $input, condition: $condition) {
    id
    product_tcd_id
    seq_id
    name
    common_name
    title_code_id
    title_code_name
    product_type_id
    product_type_name
    product_class_id
    product_class_name
    platform_id
    platform_name
    base_price
    currency_id
    currency_name
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    data_source_id
    data_source_name
    app_id
    market
    admin_check_flg
    system_update_flg
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    operation_type
    createdAt
    updatedAt
    deleted_at
    old_id
    old_product_tcd_id
    old_seq_id
    old_name
    old_common_name
    old_title_code_id
    old_title_code_name
    old_product_type_id
    old_product_type_name
    old_product_class_id
    old_product_class_name
    old_platform_id
    old_platform_name
    old_base_price
    old_currency_id
    old_currency_name
    old_info_release_date
    old_preorder_start_date
    old_release_date
    old_release_status
    old_service_start_date
    old_service_end_date
    old_support_end_date
    old_product_key
    old_sku_number_apple
    old_package_name_google
    old_bank_app_id
    old_data_source_id
    old_data_source_name
    old_app_id
    old_market
    old_admin_check_flg
    old_system_update_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductTcdHistoryMutationVariables,
  APITypes.UpdateProductTcdHistoryMutation
>;
export const deleteProductTcdHistory = /* GraphQL */ `mutation DeleteProductTcdHistory(
  $input: DeleteProductTcdHistoryInput!
  $condition: ModelProductTcdHistoryConditionInput
) {
  deleteProductTcdHistory(input: $input, condition: $condition) {
    id
    product_tcd_id
    seq_id
    name
    common_name
    title_code_id
    title_code_name
    product_type_id
    product_type_name
    product_class_id
    product_class_name
    platform_id
    platform_name
    base_price
    currency_id
    currency_name
    info_release_date
    preorder_start_date
    release_date
    release_status
    service_start_date
    service_end_date
    support_end_date
    product_key
    sku_number_apple
    package_name_google
    bank_app_id
    data_source_id
    data_source_name
    app_id
    market
    admin_check_flg
    system_update_flg
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    operation_type
    createdAt
    updatedAt
    deleted_at
    old_id
    old_product_tcd_id
    old_seq_id
    old_name
    old_common_name
    old_title_code_id
    old_title_code_name
    old_product_type_id
    old_product_type_name
    old_product_class_id
    old_product_class_name
    old_platform_id
    old_platform_name
    old_base_price
    old_currency_id
    old_currency_name
    old_info_release_date
    old_preorder_start_date
    old_release_date
    old_release_status
    old_service_start_date
    old_service_end_date
    old_support_end_date
    old_product_key
    old_sku_number_apple
    old_package_name_google
    old_bank_app_id
    old_data_source_id
    old_data_source_name
    old_app_id
    old_market
    old_admin_check_flg
    old_system_update_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_operation_type
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductTcdHistoryMutationVariables,
  APITypes.DeleteProductTcdHistoryMutation
>;
export const createBnml = /* GraphQL */ `mutation CreateBnml(
  $input: CreateBnmlInput!
  $condition: ModelBnmlConditionInput
) {
  createBnml(input: $input, condition: $condition) {
    id
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    tags {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBnmlMutationVariables,
  APITypes.CreateBnmlMutation
>;
export const updateBnml = /* GraphQL */ `mutation UpdateBnml(
  $input: UpdateBnmlInput!
  $condition: ModelBnmlConditionInput
) {
  updateBnml(input: $input, condition: $condition) {
    id
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    tags {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBnmlMutationVariables,
  APITypes.UpdateBnmlMutation
>;
export const deleteBnml = /* GraphQL */ `mutation DeleteBnml(
  $input: DeleteBnmlInput!
  $condition: ModelBnmlConditionInput
) {
  deleteBnml(input: $input, condition: $condition) {
    id
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    tags {
      items {
        id
        tag_id
        bnml_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnml {
          id
          seq_id
          mlics_plan_cd_4char
          mlics_plan_cd_7char
          mlics_commodity_name
          tags {
            nextToken
            startedAt
            __typename
          }
          update_reason
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          admin_check_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    delete_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBnmlMutationVariables,
  APITypes.DeleteBnmlMutation
>;
export const createBnmlHistory = /* GraphQL */ `mutation CreateBnmlHistory(
  $input: CreateBnmlHistoryInput!
  $condition: ModelBnmlHistoryConditionInput
) {
  createBnmlHistory(input: $input, condition: $condition) {
    id
    bnml_id
    operation_type
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    system_update_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    old_id
    old_bnml_id
    old_operation_type
    old_seq_id
    old_mlics_plan_cd_4char
    old_mlics_plan_cd_7char
    old_mlics_commodity_name
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_system_update_flg
    old_admin_check_flg
    old_deleted_at
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBnmlHistoryMutationVariables,
  APITypes.CreateBnmlHistoryMutation
>;
export const updateBnmlHistory = /* GraphQL */ `mutation UpdateBnmlHistory(
  $input: UpdateBnmlHistoryInput!
  $condition: ModelBnmlHistoryConditionInput
) {
  updateBnmlHistory(input: $input, condition: $condition) {
    id
    bnml_id
    operation_type
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    system_update_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    old_id
    old_bnml_id
    old_operation_type
    old_seq_id
    old_mlics_plan_cd_4char
    old_mlics_plan_cd_7char
    old_mlics_commodity_name
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_system_update_flg
    old_admin_check_flg
    old_deleted_at
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBnmlHistoryMutationVariables,
  APITypes.UpdateBnmlHistoryMutation
>;
export const deleteBnmlHistory = /* GraphQL */ `mutation DeleteBnmlHistory(
  $input: DeleteBnmlHistoryInput!
  $condition: ModelBnmlHistoryConditionInput
) {
  deleteBnmlHistory(input: $input, condition: $condition) {
    id
    bnml_id
    operation_type
    seq_id
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    system_update_flg
    admin_check_flg
    deleted_at
    createdAt
    updatedAt
    old_id
    old_bnml_id
    old_operation_type
    old_seq_id
    old_mlics_plan_cd_4char
    old_mlics_plan_cd_7char
    old_mlics_commodity_name
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_system_update_flg
    old_admin_check_flg
    old_deleted_at
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBnmlHistoryMutationVariables,
  APITypes.DeleteBnmlHistoryMutation
>;
export const createTagBnml = /* GraphQL */ `mutation CreateTagBnml(
  $input: CreateTagBnmlInput!
  $condition: ModelTagBnmlConditionInput
) {
  createTagBnml(input: $input, condition: $condition) {
    id
    tag_id
    bnml_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnml {
      id
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      tags {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagBnmlMutationVariables,
  APITypes.CreateTagBnmlMutation
>;
export const updateTagBnml = /* GraphQL */ `mutation UpdateTagBnml(
  $input: UpdateTagBnmlInput!
  $condition: ModelTagBnmlConditionInput
) {
  updateTagBnml(input: $input, condition: $condition) {
    id
    tag_id
    bnml_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnml {
      id
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      tags {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagBnmlMutationVariables,
  APITypes.UpdateTagBnmlMutation
>;
export const deleteTagBnml = /* GraphQL */ `mutation DeleteTagBnml(
  $input: DeleteTagBnmlInput!
  $condition: ModelTagBnmlConditionInput
) {
  deleteTagBnml(input: $input, condition: $condition) {
    id
    tag_id
    bnml_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnml {
      id
      seq_id
      mlics_plan_cd_4char
      mlics_plan_cd_7char
      mlics_commodity_name
      tags {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      update_reason
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      admin_check_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagBnmlMutationVariables,
  APITypes.DeleteTagBnmlMutation
>;
export const createTagBnmlHistory = /* GraphQL */ `mutation CreateTagBnmlHistory(
  $input: CreateTagBnmlHistoryInput!
  $condition: ModelTagBnmlHistoryConditionInput
) {
  createTagBnmlHistory(input: $input, condition: $condition) {
    id
    operation_type
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    bnml_id
    bnml_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_mlics_plan_cd_4char
    old_mlics_plan_cd_7char
    old_mlics_commodity_name
    old_bnml_id
    old_bnml_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagBnmlHistoryMutationVariables,
  APITypes.CreateTagBnmlHistoryMutation
>;
export const updateTagBnmlHistory = /* GraphQL */ `mutation UpdateTagBnmlHistory(
  $input: UpdateTagBnmlHistoryInput!
  $condition: ModelTagBnmlHistoryConditionInput
) {
  updateTagBnmlHistory(input: $input, condition: $condition) {
    id
    operation_type
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    bnml_id
    bnml_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_mlics_plan_cd_4char
    old_mlics_plan_cd_7char
    old_mlics_commodity_name
    old_bnml_id
    old_bnml_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagBnmlHistoryMutationVariables,
  APITypes.UpdateTagBnmlHistoryMutation
>;
export const deleteTagBnmlHistory = /* GraphQL */ `mutation DeleteTagBnmlHistory(
  $input: DeleteTagBnmlHistoryInput!
  $condition: ModelTagBnmlHistoryConditionInput
) {
  deleteTagBnmlHistory(input: $input, condition: $condition) {
    id
    operation_type
    mlics_plan_cd_4char
    mlics_plan_cd_7char
    mlics_commodity_name
    bnml_id
    bnml_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_mlics_plan_cd_4char
    old_mlics_plan_cd_7char
    old_mlics_commodity_name
    old_bnml_id
    old_bnml_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagBnmlHistoryMutationVariables,
  APITypes.DeleteTagBnmlHistoryMutation
>;
export const createTagBnf = /* GraphQL */ `mutation CreateTagBnf(
  $input: CreateTagBnfInput!
  $condition: ModelTagBnfConditionInput
) {
  createTagBnf(input: $input, condition: $condition) {
    id
    tag_id
    bnf_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnf {
      id
      seq_id
      tags {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagBnfMutationVariables,
  APITypes.CreateTagBnfMutation
>;
export const updateTagBnf = /* GraphQL */ `mutation UpdateTagBnf(
  $input: UpdateTagBnfInput!
  $condition: ModelTagBnfConditionInput
) {
  updateTagBnf(input: $input, condition: $condition) {
    id
    tag_id
    bnf_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnf {
      id
      seq_id
      tags {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagBnfMutationVariables,
  APITypes.UpdateTagBnfMutation
>;
export const deleteTagBnf = /* GraphQL */ `mutation DeleteTagBnf(
  $input: DeleteTagBnfInput!
  $condition: ModelTagBnfConditionInput
) {
  deleteTagBnf(input: $input, condition: $condition) {
    id
    tag_id
    bnf_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnf {
      id
      seq_id
      tags {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnf_data_source
      obic7_commodity_cd
      obic7_commodity_name
      bvics_plan_cd_4char
      bvics_plan_cd_7char
      bvics_product_name
      admin_check_flg
      delete_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagBnfMutationVariables,
  APITypes.DeleteTagBnfMutation
>;
export const createTagBnfHistory = /* GraphQL */ `mutation CreateTagBnfHistory(
  $input: CreateTagBnfHistoryInput!
  $condition: ModelTagBnfHistoryConditionInput
) {
  createTagBnfHistory(input: $input, condition: $condition) {
    id
    operation_type
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    bnf_id
    bnf_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_bnf_data_source
    old_obic7_commodity_cd
    old_obic7_commodity_name
    old_bvics_plan_cd_4char
    old_bvics_plan_cd_7char
    old_bvics_product_name
    old_bnf_id
    old_bnf_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagBnfHistoryMutationVariables,
  APITypes.CreateTagBnfHistoryMutation
>;
export const updateTagBnfHistory = /* GraphQL */ `mutation UpdateTagBnfHistory(
  $input: UpdateTagBnfHistoryInput!
  $condition: ModelTagBnfHistoryConditionInput
) {
  updateTagBnfHistory(input: $input, condition: $condition) {
    id
    operation_type
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    bnf_id
    bnf_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_bnf_data_source
    old_obic7_commodity_cd
    old_obic7_commodity_name
    old_bvics_plan_cd_4char
    old_bvics_plan_cd_7char
    old_bvics_product_name
    old_bnf_id
    old_bnf_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagBnfHistoryMutationVariables,
  APITypes.UpdateTagBnfHistoryMutation
>;
export const deleteTagBnfHistory = /* GraphQL */ `mutation DeleteTagBnfHistory(
  $input: DeleteTagBnfHistoryInput!
  $condition: ModelTagBnfHistoryConditionInput
) {
  deleteTagBnfHistory(input: $input, condition: $condition) {
    id
    operation_type
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    bnf_id
    bnf_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_bnf_data_source
    old_obic7_commodity_cd
    old_obic7_commodity_name
    old_bvics_plan_cd_4char
    old_bvics_plan_cd_7char
    old_bvics_product_name
    old_bnf_id
    old_bnf_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagBnfHistoryMutationVariables,
  APITypes.DeleteTagBnfHistoryMutation
>;
export const createBsp = /* GraphQL */ `mutation CreateBsp(
  $input: CreateBspInput!
  $condition: ModelBspConditionInput
) {
  createBsp(input: $input, condition: $condition) {
    id
    seq_id
    character_name
    character_code
    character_series_name
    character_series_code
    tags {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBspMutationVariables,
  APITypes.CreateBspMutation
>;
export const updateBsp = /* GraphQL */ `mutation UpdateBsp(
  $input: UpdateBspInput!
  $condition: ModelBspConditionInput
) {
  updateBsp(input: $input, condition: $condition) {
    id
    seq_id
    character_name
    character_code
    character_series_name
    character_series_code
    tags {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBspMutationVariables,
  APITypes.UpdateBspMutation
>;
export const deleteBsp = /* GraphQL */ `mutation DeleteBsp(
  $input: DeleteBspInput!
  $condition: ModelBspConditionInput
) {
  deleteBsp(input: $input, condition: $condition) {
    id
    seq_id
    character_name
    character_code
    character_series_name
    character_series_code
    tags {
      items {
        id
        tag_id
        bsp_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bsp {
          id
          seq_id
          character_name
          character_code
          character_series_name
          character_series_code
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          system_update_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    system_update_flg
    deleted_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBspMutationVariables,
  APITypes.DeleteBspMutation
>;
export const createBspHistory = /* GraphQL */ `mutation CreateBspHistory(
  $input: CreateBspHistoryInput!
  $condition: ModelBspHistoryConditionInput
) {
  createBspHistory(input: $input, condition: $condition) {
    id
    bsp_id
    seq_id
    operation_type
    character_name
    character_code
    character_series_name
    character_series_code
    system_update_flg
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    createdAt
    updatedAt
    old_id
    old_bsp_id
    old_seq_id
    old_operation_type
    old_character_name
    old_character_code
    old_character_series_name
    old_character_series_code
    old_system_update_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBspHistoryMutationVariables,
  APITypes.CreateBspHistoryMutation
>;
export const updateBspHistory = /* GraphQL */ `mutation UpdateBspHistory(
  $input: UpdateBspHistoryInput!
  $condition: ModelBspHistoryConditionInput
) {
  updateBspHistory(input: $input, condition: $condition) {
    id
    bsp_id
    seq_id
    operation_type
    character_name
    character_code
    character_series_name
    character_series_code
    system_update_flg
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    createdAt
    updatedAt
    old_id
    old_bsp_id
    old_seq_id
    old_operation_type
    old_character_name
    old_character_code
    old_character_series_name
    old_character_series_code
    old_system_update_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBspHistoryMutationVariables,
  APITypes.UpdateBspHistoryMutation
>;
export const deleteBspHistory = /* GraphQL */ `mutation DeleteBspHistory(
  $input: DeleteBspHistoryInput!
  $condition: ModelBspHistoryConditionInput
) {
  deleteBspHistory(input: $input, condition: $condition) {
    id
    bsp_id
    seq_id
    operation_type
    character_name
    character_code
    character_series_name
    character_series_code
    system_update_flg
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    createdAt
    updatedAt
    old_id
    old_bsp_id
    old_seq_id
    old_operation_type
    old_character_name
    old_character_code
    old_character_series_name
    old_character_series_code
    old_system_update_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBspHistoryMutationVariables,
  APITypes.DeleteBspHistoryMutation
>;
export const createTagBsp = /* GraphQL */ `mutation CreateTagBsp(
  $input: CreateTagBspInput!
  $condition: ModelTagBspConditionInput
) {
  createTagBsp(input: $input, condition: $condition) {
    id
    tag_id
    bsp_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bsp {
      id
      seq_id
      character_name
      character_code
      character_series_name
      character_series_code
      tags {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagBspMutationVariables,
  APITypes.CreateTagBspMutation
>;
export const updateTagBsp = /* GraphQL */ `mutation UpdateTagBsp(
  $input: UpdateTagBspInput!
  $condition: ModelTagBspConditionInput
) {
  updateTagBsp(input: $input, condition: $condition) {
    id
    tag_id
    bsp_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bsp {
      id
      seq_id
      character_name
      character_code
      character_series_name
      character_series_code
      tags {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagBspMutationVariables,
  APITypes.UpdateTagBspMutation
>;
export const deleteTagBsp = /* GraphQL */ `mutation DeleteTagBsp(
  $input: DeleteTagBspInput!
  $condition: ModelTagBspConditionInput
) {
  deleteTagBsp(input: $input, condition: $condition) {
    id
    tag_id
    bsp_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bsp {
      id
      seq_id
      character_name
      character_code
      character_series_name
      character_series_code
      tags {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagBspMutationVariables,
  APITypes.DeleteTagBspMutation
>;
export const createTagBspHistory = /* GraphQL */ `mutation CreateTagBspHistory(
  $input: CreateTagBspHistoryInput!
  $condition: ModelTagBspHistoryConditionInput
) {
  createTagBspHistory(input: $input, condition: $condition) {
    id
    operation_type
    character_name
    bsp_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_character_name
    old_bsp_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagBspHistoryMutationVariables,
  APITypes.CreateTagBspHistoryMutation
>;
export const updateTagBspHistory = /* GraphQL */ `mutation UpdateTagBspHistory(
  $input: UpdateTagBspHistoryInput!
  $condition: ModelTagBspHistoryConditionInput
) {
  updateTagBspHistory(input: $input, condition: $condition) {
    id
    operation_type
    character_name
    bsp_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_character_name
    old_bsp_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagBspHistoryMutationVariables,
  APITypes.UpdateTagBspHistoryMutation
>;
export const deleteTagBspHistory = /* GraphQL */ `mutation DeleteTagBspHistory(
  $input: DeleteTagBspHistoryInput!
  $condition: ModelTagBspHistoryConditionInput
) {
  deleteTagBspHistory(input: $input, condition: $condition) {
    id
    operation_type
    character_name
    bsp_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_character_name
    old_bsp_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagBspHistoryMutationVariables,
  APITypes.DeleteTagBspHistoryMutation
>;
export const createBnam = /* GraphQL */ `mutation CreateBnam(
  $input: CreateBnamInput!
  $condition: ModelBnamConditionInput
) {
  createBnam(input: $input, condition: $condition) {
    id
    seq_id
    ip_name
    tags {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBnamMutationVariables,
  APITypes.CreateBnamMutation
>;
export const updateBnam = /* GraphQL */ `mutation UpdateBnam(
  $input: UpdateBnamInput!
  $condition: ModelBnamConditionInput
) {
  updateBnam(input: $input, condition: $condition) {
    id
    seq_id
    ip_name
    tags {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBnamMutationVariables,
  APITypes.UpdateBnamMutation
>;
export const deleteBnam = /* GraphQL */ `mutation DeleteBnam(
  $input: DeleteBnamInput!
  $condition: ModelBnamConditionInput
) {
  deleteBnam(input: $input, condition: $condition) {
    id
    seq_id
    ip_name
    tags {
      items {
        id
        tag_id
        bnam_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnam {
          id
          seq_id
          ip_name
          tags {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          delete_flg
          deleted_at
          admin_check_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBnamMutationVariables,
  APITypes.DeleteBnamMutation
>;
export const createBnamHistory = /* GraphQL */ `mutation CreateBnamHistory(
  $input: CreateBnamHistoryInput!
  $condition: ModelBnamHistoryConditionInput
) {
  createBnamHistory(input: $input, condition: $condition) {
    id
    bnam_id
    seq_id
    operation_type
    ip_name
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    old_id
    old_bnam_id
    old_seq_id
    old_operation_type
    old_ip_name
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_admin_check_flg
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBnamHistoryMutationVariables,
  APITypes.CreateBnamHistoryMutation
>;
export const updateBnamHistory = /* GraphQL */ `mutation UpdateBnamHistory(
  $input: UpdateBnamHistoryInput!
  $condition: ModelBnamHistoryConditionInput
) {
  updateBnamHistory(input: $input, condition: $condition) {
    id
    bnam_id
    seq_id
    operation_type
    ip_name
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    old_id
    old_bnam_id
    old_seq_id
    old_operation_type
    old_ip_name
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_admin_check_flg
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBnamHistoryMutationVariables,
  APITypes.UpdateBnamHistoryMutation
>;
export const deleteBnamHistory = /* GraphQL */ `mutation DeleteBnamHistory(
  $input: DeleteBnamHistoryInput!
  $condition: ModelBnamHistoryConditionInput
) {
  deleteBnamHistory(input: $input, condition: $condition) {
    id
    bnam_id
    seq_id
    operation_type
    ip_name
    update_reason
    updated_user_id
    updated_user_name
    delete_flg
    deleted_at
    admin_check_flg
    createdAt
    updatedAt
    old_id
    old_bnam_id
    old_seq_id
    old_operation_type
    old_ip_name
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_delete_flg
    old_deleted_at
    old_admin_check_flg
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBnamHistoryMutationVariables,
  APITypes.DeleteBnamHistoryMutation
>;
export const createTagBnam = /* GraphQL */ `mutation CreateTagBnam(
  $input: CreateTagBnamInput!
  $condition: ModelTagBnamConditionInput
) {
  createTagBnam(input: $input, condition: $condition) {
    id
    tag_id
    bnam_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnam {
      id
      seq_id
      ip_name
      tags {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagBnamMutationVariables,
  APITypes.CreateTagBnamMutation
>;
export const updateTagBnam = /* GraphQL */ `mutation UpdateTagBnam(
  $input: UpdateTagBnamInput!
  $condition: ModelTagBnamConditionInput
) {
  updateTagBnam(input: $input, condition: $condition) {
    id
    tag_id
    bnam_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnam {
      id
      seq_id
      ip_name
      tags {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagBnamMutationVariables,
  APITypes.UpdateTagBnamMutation
>;
export const deleteTagBnam = /* GraphQL */ `mutation DeleteTagBnam(
  $input: DeleteTagBnamInput!
  $condition: ModelTagBnamConditionInput
) {
  deleteTagBnam(input: $input, condition: $condition) {
    id
    tag_id
    bnam_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bnam {
      id
      seq_id
      ip_name
      tags {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      update_reason
      delete_flg
      deleted_at
      admin_check_flg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagBnamMutationVariables,
  APITypes.DeleteTagBnamMutation
>;
export const createTagBnamHistory = /* GraphQL */ `mutation CreateTagBnamHistory(
  $input: CreateTagBnamHistoryInput!
  $condition: ModelTagBnamHistoryConditionInput
) {
  createTagBnamHistory(input: $input, condition: $condition) {
    id
    operation_type
    ip_name
    tag_bnam_id
    bnam_id
    bnam_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_ip_name
    old_tag_bnam_id
    old_bnam_id
    old_bnam_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagBnamHistoryMutationVariables,
  APITypes.CreateTagBnamHistoryMutation
>;
export const updateTagBnamHistory = /* GraphQL */ `mutation UpdateTagBnamHistory(
  $input: UpdateTagBnamHistoryInput!
  $condition: ModelTagBnamHistoryConditionInput
) {
  updateTagBnamHistory(input: $input, condition: $condition) {
    id
    operation_type
    ip_name
    tag_bnam_id
    bnam_id
    bnam_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_ip_name
    old_tag_bnam_id
    old_bnam_id
    old_bnam_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagBnamHistoryMutationVariables,
  APITypes.UpdateTagBnamHistoryMutation
>;
export const deleteTagBnamHistory = /* GraphQL */ `mutation DeleteTagBnamHistory(
  $input: DeleteTagBnamHistoryInput!
  $condition: ModelTagBnamHistoryConditionInput
) {
  deleteTagBnamHistory(input: $input, condition: $condition) {
    id
    operation_type
    ip_name
    tag_bnam_id
    bnam_id
    bnam_seq_id
    tag_id
    tag_name
    tag_name_en
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    old_id
    old_operation_type
    old_ip_name
    old_tag_bnam_id
    old_bnam_id
    old_bnam_seq_id
    old_tag_id
    old_tag_name
    old_tag_name_en
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagBnamHistoryMutationVariables,
  APITypes.DeleteTagBnamHistoryMutation
>;
export const createTagTitle = /* GraphQL */ `mutation CreateTagTitle(
  $input: CreateTagTitleInput!
  $condition: ModelTagTitleConditionInput
) {
  createTagTitle(input: $input, condition: $condition) {
    id
    tag_id
    title_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagTitleMutationVariables,
  APITypes.CreateTagTitleMutation
>;
export const updateTagTitle = /* GraphQL */ `mutation UpdateTagTitle(
  $input: UpdateTagTitleInput!
  $condition: ModelTagTitleConditionInput
) {
  updateTagTitle(input: $input, condition: $condition) {
    id
    tag_id
    title_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagTitleMutationVariables,
  APITypes.UpdateTagTitleMutation
>;
export const deleteTagTitle = /* GraphQL */ `mutation DeleteTagTitle(
  $input: DeleteTagTitleInput!
  $condition: ModelTagTitleConditionInput
) {
  deleteTagTitle(input: $input, condition: $condition) {
    id
    tag_id
    title_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    title {
      id
      seq_id
      name_ja
      name_en
      search_name
      sap_title_code
      bn_connect_title_id
      project_cd
      banasuke_title_id
      ce_link_key_code
      update_reason
      genre_id
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admin_check_flg
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      delete_flg
      system_update_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagTitleMutationVariables,
  APITypes.DeleteTagTitleMutation
>;
export const createTagTitleCode = /* GraphQL */ `mutation CreateTagTitleCode(
  $input: CreateTagTitleCodeInput!
  $condition: ModelTagTitleCodeConditionInput
) {
  createTagTitleCode(input: $input, condition: $condition) {
    id
    tag_id
    title_code_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagTitleCodeMutationVariables,
  APITypes.CreateTagTitleCodeMutation
>;
export const updateTagTitleCode = /* GraphQL */ `mutation UpdateTagTitleCode(
  $input: UpdateTagTitleCodeInput!
  $condition: ModelTagTitleCodeConditionInput
) {
  updateTagTitleCode(input: $input, condition: $condition) {
    id
    tag_id
    title_code_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagTitleCodeMutationVariables,
  APITypes.UpdateTagTitleCodeMutation
>;
export const deleteTagTitleCode = /* GraphQL */ `mutation DeleteTagTitleCode(
  $input: DeleteTagTitleCodeInput!
  $condition: ModelTagTitleCodeConditionInput
) {
  deleteTagTitleCode(input: $input, condition: $condition) {
    id
    tag_id
    title_code_id
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    tag {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      titles {
        items {
          id
          tag_id
          title_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title {
            id
            seq_id
            name_ja
            name_en
            search_name
            sap_title_code
            bn_connect_title_id
            project_cd
            banasuke_title_id
            ce_link_key_code
            update_reason
            genre_id
            data_source_id
            admin_check_flg
            updated_user_id
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      title_codes {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bsps {
        items {
          id
          tag_id
          bsp_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bsp {
            id
            seq_id
            character_name
            character_code
            character_series_name
            character_series_code
            updated_user_id
            update_reason
            delete_flg
            system_update_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnams {
        items {
          id
          tag_id
          bnam_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnam {
            id
            seq_id
            ip_name
            updated_user_id
            update_reason
            delete_flg
            deleted_at
            admin_check_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnmls {
        items {
          id
          tag_id
          bnml_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnml {
            id
            seq_id
            mlics_plan_cd_4char
            mlics_plan_cd_7char
            mlics_commodity_name
            update_reason
            updated_user_id
            delete_flg
            admin_check_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      bnfs {
        items {
          id
          tag_id
          bnf_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          bnf {
            id
            seq_id
            bnf_data_source
            obic7_commodity_cd
            obic7_commodity_name
            bvics_plan_cd_4char
            bvics_plan_cd_7char
            bvics_product_name
            admin_check_flg
            delete_flg
            updated_user_id
            update_reason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    title_code {
      id
      seq_id
      title_code
      name_ja
      name_en
      official_name_flg
      sap_title_code_name
      search_name
      project_cd
      wbs
      bn_connect_title_ids
      ce_link_key_code
      ce_link_title_name_ja
      ce_link_title_name_en
      genre_ids
      banasuke_title_id
      banasuke_title_name_ja
      bundle_source_title_code_ids
      porting_source_title_code_ids
      business_entity_id
      business_entity {
        id
        seq_id
        name
        description
        delete_flg
        deleted_at
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      platform_ids
      data_source_id
      data_source {
        id
        seq_id
        name
        delete_flg
        deleted_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      tags {
        items {
          id
          tag_id
          title_code_id
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          tag {
            id
            seq_id
            name_ja
            name_en
            updated_user_id
            delete_flg
            deleted_at
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          title_code {
            id
            seq_id
            title_code
            name_ja
            name_en
            official_name_flg
            sap_title_code_name
            search_name
            project_cd
            wbs
            bn_connect_title_ids
            ce_link_key_code
            ce_link_title_name_ja
            ce_link_title_name_en
            genre_ids
            banasuke_title_id
            banasuke_title_name_ja
            bundle_source_title_code_ids
            porting_source_title_code_ids
            business_entity_id
            platform_ids
            data_source_id
            valid_flg
            update_reason
            system_update_flg
            admin_check_flg
            delete_flg
            deleted_at
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      valid_flg
      update_reason
      system_update_flg
      admin_check_flg
      delete_flg
      deleted_at
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagTitleCodeMutationVariables,
  APITypes.DeleteTagTitleCodeMutation
>;
export const createTagTitleHistory = /* GraphQL */ `mutation CreateTagTitleHistory(
  $input: CreateTagTitleHistoryInput!
  $condition: ModelTagTitleHistoryConditionInput
) {
  createTagTitleHistory(input: $input, condition: $condition) {
    id
    tag_title_id
    tag_id
    tag_name
    tag_name_en
    title_id
    title_name
    updated_user_id
    updated_user_name
    operation_type
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagTitleHistoryMutationVariables,
  APITypes.CreateTagTitleHistoryMutation
>;
export const updateTagTitleHistory = /* GraphQL */ `mutation UpdateTagTitleHistory(
  $input: UpdateTagTitleHistoryInput!
  $condition: ModelTagTitleHistoryConditionInput
) {
  updateTagTitleHistory(input: $input, condition: $condition) {
    id
    tag_title_id
    tag_id
    tag_name
    tag_name_en
    title_id
    title_name
    updated_user_id
    updated_user_name
    operation_type
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagTitleHistoryMutationVariables,
  APITypes.UpdateTagTitleHistoryMutation
>;
export const deleteTagTitleHistory = /* GraphQL */ `mutation DeleteTagTitleHistory(
  $input: DeleteTagTitleHistoryInput!
  $condition: ModelTagTitleHistoryConditionInput
) {
  deleteTagTitleHistory(input: $input, condition: $condition) {
    id
    tag_title_id
    tag_id
    tag_name
    tag_name_en
    title_id
    title_name
    updated_user_id
    updated_user_name
    operation_type
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagTitleHistoryMutationVariables,
  APITypes.DeleteTagTitleHistoryMutation
>;
export const createTagTitleCodeHistory = /* GraphQL */ `mutation CreateTagTitleCodeHistory(
  $input: CreateTagTitleCodeHistoryInput!
  $condition: ModelTagTitleCodeHistoryConditionInput
) {
  createTagTitleCodeHistory(input: $input, condition: $condition) {
    id
    tag_title_code_id
    tag_id
    tag_name
    tag_name_en
    title_code_id
    title_code_name
    updated_user_id
    updated_user_name
    operation_type
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagTitleCodeHistoryMutationVariables,
  APITypes.CreateTagTitleCodeHistoryMutation
>;
export const updateTagTitleCodeHistory = /* GraphQL */ `mutation UpdateTagTitleCodeHistory(
  $input: UpdateTagTitleCodeHistoryInput!
  $condition: ModelTagTitleCodeHistoryConditionInput
) {
  updateTagTitleCodeHistory(input: $input, condition: $condition) {
    id
    tag_title_code_id
    tag_id
    tag_name
    tag_name_en
    title_code_id
    title_code_name
    updated_user_id
    updated_user_name
    operation_type
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagTitleCodeHistoryMutationVariables,
  APITypes.UpdateTagTitleCodeHistoryMutation
>;
export const deleteTagTitleCodeHistory = /* GraphQL */ `mutation DeleteTagTitleCodeHistory(
  $input: DeleteTagTitleCodeHistoryInput!
  $condition: ModelTagTitleCodeHistoryConditionInput
) {
  deleteTagTitleCodeHistory(input: $input, condition: $condition) {
    id
    tag_title_code_id
    tag_id
    tag_name
    tag_name_en
    title_code_id
    title_code_name
    updated_user_id
    updated_user_name
    operation_type
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagTitleCodeHistoryMutationVariables,
  APITypes.DeleteTagTitleCodeHistoryMutation
>;
export const createBnf = /* GraphQL */ `mutation CreateBnf(
  $input: CreateBnfInput!
  $condition: ModelBnfConditionInput
) {
  createBnf(input: $input, condition: $condition) {
    id
    seq_id
    tags {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBnfMutationVariables,
  APITypes.CreateBnfMutation
>;
export const updateBnf = /* GraphQL */ `mutation UpdateBnf(
  $input: UpdateBnfInput!
  $condition: ModelBnfConditionInput
) {
  updateBnf(input: $input, condition: $condition) {
    id
    seq_id
    tags {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBnfMutationVariables,
  APITypes.UpdateBnfMutation
>;
export const deleteBnf = /* GraphQL */ `mutation DeleteBnf(
  $input: DeleteBnfInput!
  $condition: ModelBnfConditionInput
) {
  deleteBnf(input: $input, condition: $condition) {
    id
    seq_id
    tags {
      items {
        id
        tag_id
        bnf_id
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        tag {
          id
          seq_id
          name_ja
          name_en
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          titles {
            nextToken
            startedAt
            __typename
          }
          title_codes {
            nextToken
            startedAt
            __typename
          }
          bsps {
            nextToken
            startedAt
            __typename
          }
          bnams {
            nextToken
            startedAt
            __typename
          }
          bnmls {
            nextToken
            startedAt
            __typename
          }
          bnfs {
            nextToken
            startedAt
            __typename
          }
          delete_flg
          deleted_at
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bnf {
          id
          seq_id
          tags {
            nextToken
            startedAt
            __typename
          }
          bnf_data_source
          obic7_commodity_cd
          obic7_commodity_name
          bvics_plan_cd_4char
          bvics_plan_cd_7char
          bvics_product_name
          admin_check_flg
          delete_flg
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          update_reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        update_reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBnfMutationVariables,
  APITypes.DeleteBnfMutation
>;
export const createBnfHistory = /* GraphQL */ `mutation CreateBnfHistory(
  $input: CreateBnfHistoryInput!
  $condition: ModelBnfHistoryConditionInput
) {
  createBnfHistory(input: $input, condition: $condition) {
    id
    bnf_id
    seq_id
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    deleted_at
    operation_type
    old_id
    old_bnf_id
    old_seq_id
    old_bnf_data_source
    old_obic7_commodity_cd
    old_obic7_commodity_name
    old_bvics_plan_cd_4char
    old_bvics_plan_cd_7char
    old_bvics_product_name
    old_admin_check_flg
    old_delete_flg
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    old_deleted_at
    old_operation_type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBnfHistoryMutationVariables,
  APITypes.CreateBnfHistoryMutation
>;
export const updateBnfHistory = /* GraphQL */ `mutation UpdateBnfHistory(
  $input: UpdateBnfHistoryInput!
  $condition: ModelBnfHistoryConditionInput
) {
  updateBnfHistory(input: $input, condition: $condition) {
    id
    bnf_id
    seq_id
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    deleted_at
    operation_type
    old_id
    old_bnf_id
    old_seq_id
    old_bnf_data_source
    old_obic7_commodity_cd
    old_obic7_commodity_name
    old_bvics_plan_cd_4char
    old_bvics_plan_cd_7char
    old_bvics_product_name
    old_admin_check_flg
    old_delete_flg
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    old_deleted_at
    old_operation_type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBnfHistoryMutationVariables,
  APITypes.UpdateBnfHistoryMutation
>;
export const deleteBnfHistory = /* GraphQL */ `mutation DeleteBnfHistory(
  $input: DeleteBnfHistoryInput!
  $condition: ModelBnfHistoryConditionInput
) {
  deleteBnfHistory(input: $input, condition: $condition) {
    id
    bnf_id
    seq_id
    bnf_data_source
    obic7_commodity_cd
    obic7_commodity_name
    bvics_plan_cd_4char
    bvics_plan_cd_7char
    bvics_product_name
    admin_check_flg
    delete_flg
    updated_user_id
    updated_user_name
    update_reason
    createdAt
    updatedAt
    deleted_at
    operation_type
    old_id
    old_bnf_id
    old_seq_id
    old_bnf_data_source
    old_obic7_commodity_cd
    old_obic7_commodity_name
    old_bvics_plan_cd_4char
    old_bvics_plan_cd_7char
    old_bvics_product_name
    old_admin_check_flg
    old_delete_flg
    old_updated_user_id
    old_updated_user_name
    old_update_reason
    old_createdAt
    old_updatedAt
    old_deleted_at
    old_operation_type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBnfHistoryMutationVariables,
  APITypes.DeleteBnfHistoryMutation
>;
export const createCsvJob = /* GraphQL */ `mutation CreateCsvJob(
  $input: CreateCsvJobInput!
  $condition: ModelCsvJobConditionInput
) {
  createCsvJob(input: $input, condition: $condition) {
    id
    type
    name
    filepath
    status
    message
    table_name
    search_condition
    error_log
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCsvJobMutationVariables,
  APITypes.CreateCsvJobMutation
>;
export const updateCsvJob = /* GraphQL */ `mutation UpdateCsvJob(
  $input: UpdateCsvJobInput!
  $condition: ModelCsvJobConditionInput
) {
  updateCsvJob(input: $input, condition: $condition) {
    id
    type
    name
    filepath
    status
    message
    table_name
    search_condition
    error_log
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCsvJobMutationVariables,
  APITypes.UpdateCsvJobMutation
>;
export const deleteCsvJob = /* GraphQL */ `mutation DeleteCsvJob(
  $input: DeleteCsvJobInput!
  $condition: ModelCsvJobConditionInput
) {
  deleteCsvJob(input: $input, condition: $condition) {
    id
    type
    name
    filepath
    status
    message
    table_name
    search_condition
    error_log
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCsvJobMutationVariables,
  APITypes.DeleteCsvJobMutation
>;
export const createDataLakeJob = /* GraphQL */ `mutation CreateDataLakeJob(
  $input: CreateDataLakeJobInput!
  $condition: ModelDataLakeJobConditionInput
) {
  createDataLakeJob(input: $input, condition: $condition) {
    id
    operation_type
    status
    message
    error_log
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDataLakeJobMutationVariables,
  APITypes.CreateDataLakeJobMutation
>;
export const updateDataLakeJob = /* GraphQL */ `mutation UpdateDataLakeJob(
  $input: UpdateDataLakeJobInput!
  $condition: ModelDataLakeJobConditionInput
) {
  updateDataLakeJob(input: $input, condition: $condition) {
    id
    operation_type
    status
    message
    error_log
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataLakeJobMutationVariables,
  APITypes.UpdateDataLakeJobMutation
>;
export const deleteDataLakeJob = /* GraphQL */ `mutation DeleteDataLakeJob(
  $input: DeleteDataLakeJobInput!
  $condition: ModelDataLakeJobConditionInput
) {
  deleteDataLakeJob(input: $input, condition: $condition) {
    id
    operation_type
    status
    message
    error_log
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDataLakeJobMutationVariables,
  APITypes.DeleteDataLakeJobMutation
>;
export const createDataLakeJobStatus = /* GraphQL */ `mutation CreateDataLakeJobStatus(
  $input: CreateDataLakeJobStatusInput!
  $condition: ModelDataLakeJobStatusConditionInput
) {
  createDataLakeJobStatus(input: $input, condition: $condition) {
    id
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDataLakeJobStatusMutationVariables,
  APITypes.CreateDataLakeJobStatusMutation
>;
export const updateDataLakeJobStatus = /* GraphQL */ `mutation UpdateDataLakeJobStatus(
  $input: UpdateDataLakeJobStatusInput!
  $condition: ModelDataLakeJobStatusConditionInput
) {
  updateDataLakeJobStatus(input: $input, condition: $condition) {
    id
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataLakeJobStatusMutationVariables,
  APITypes.UpdateDataLakeJobStatusMutation
>;
export const deleteDataLakeJobStatus = /* GraphQL */ `mutation DeleteDataLakeJobStatus(
  $input: DeleteDataLakeJobStatusInput!
  $condition: ModelDataLakeJobStatusConditionInput
) {
  deleteDataLakeJobStatus(input: $input, condition: $condition) {
    id
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDataLakeJobStatusMutationVariables,
  APITypes.DeleteDataLakeJobStatusMutation
>;
export const createCountry = /* GraphQL */ `mutation CreateCountry(
  $input: CreateCountryInput!
  $condition: ModelCountryConditionInput
) {
  createCountry(input: $input, condition: $condition) {
    id
    order_id
    name
    name_en
    country_code
    code_two_char
    code_three_char
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCountryMutationVariables,
  APITypes.CreateCountryMutation
>;
export const updateCountry = /* GraphQL */ `mutation UpdateCountry(
  $input: UpdateCountryInput!
  $condition: ModelCountryConditionInput
) {
  updateCountry(input: $input, condition: $condition) {
    id
    order_id
    name
    name_en
    country_code
    code_two_char
    code_three_char
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCountryMutationVariables,
  APITypes.UpdateCountryMutation
>;
export const deleteCountry = /* GraphQL */ `mutation DeleteCountry(
  $input: DeleteCountryInput!
  $condition: ModelCountryConditionInput
) {
  deleteCountry(input: $input, condition: $condition) {
    id
    order_id
    name
    name_en
    country_code
    code_two_char
    code_three_char
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCountryMutationVariables,
  APITypes.DeleteCountryMutation
>;
export const createRate = /* GraphQL */ `mutation CreateRate(
  $input: CreateRateInput!
  $condition: ModelRateConditionInput
) {
  createRate(input: $input, condition: $condition) {
    id
    seq_id
    source_currency_id
    converted_currency_id
    from_date
    to_date
    exchange_rate
    company_name
    usage
    description
    exchange_rate_type
    delete_flg
    deleted_at
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRateMutationVariables,
  APITypes.CreateRateMutation
>;
export const updateRate = /* GraphQL */ `mutation UpdateRate(
  $input: UpdateRateInput!
  $condition: ModelRateConditionInput
) {
  updateRate(input: $input, condition: $condition) {
    id
    seq_id
    source_currency_id
    converted_currency_id
    from_date
    to_date
    exchange_rate
    company_name
    usage
    description
    exchange_rate_type
    delete_flg
    deleted_at
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRateMutationVariables,
  APITypes.UpdateRateMutation
>;
export const deleteRate = /* GraphQL */ `mutation DeleteRate(
  $input: DeleteRateInput!
  $condition: ModelRateConditionInput
) {
  deleteRate(input: $input, condition: $condition) {
    id
    seq_id
    source_currency_id
    converted_currency_id
    from_date
    to_date
    exchange_rate
    company_name
    usage
    description
    exchange_rate_type
    delete_flg
    deleted_at
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRateMutationVariables,
  APITypes.DeleteRateMutation
>;
export const createCalendar = /* GraphQL */ `mutation CreateCalendar(
  $input: CreateCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  createCalendar(input: $input, condition: $condition) {
    id
    year
    month
    day
    day_of_week
    calendar_date
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    name
    holiday_flg
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCalendarMutationVariables,
  APITypes.CreateCalendarMutation
>;
export const updateCalendar = /* GraphQL */ `mutation UpdateCalendar(
  $input: UpdateCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  updateCalendar(input: $input, condition: $condition) {
    id
    year
    month
    day
    day_of_week
    calendar_date
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    name
    holiday_flg
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCalendarMutationVariables,
  APITypes.UpdateCalendarMutation
>;
export const deleteCalendar = /* GraphQL */ `mutation DeleteCalendar(
  $input: DeleteCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  deleteCalendar(input: $input, condition: $condition) {
    id
    year
    month
    day
    day_of_week
    calendar_date
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    name
    holiday_flg
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCalendarMutationVariables,
  APITypes.DeleteCalendarMutation
>;
export const createBusinessEntity = /* GraphQL */ `mutation CreateBusinessEntity(
  $input: CreateBusinessEntityInput!
  $condition: ModelBusinessEntityConditionInput
) {
  createBusinessEntity(input: $input, condition: $condition) {
    id
    seq_id
    name
    description
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessEntityMutationVariables,
  APITypes.CreateBusinessEntityMutation
>;
export const updateBusinessEntity = /* GraphQL */ `mutation UpdateBusinessEntity(
  $input: UpdateBusinessEntityInput!
  $condition: ModelBusinessEntityConditionInput
) {
  updateBusinessEntity(input: $input, condition: $condition) {
    id
    seq_id
    name
    description
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessEntityMutationVariables,
  APITypes.UpdateBusinessEntityMutation
>;
export const deleteBusinessEntity = /* GraphQL */ `mutation DeleteBusinessEntity(
  $input: DeleteBusinessEntityInput!
  $condition: ModelBusinessEntityConditionInput
) {
  deleteBusinessEntity(input: $input, condition: $condition) {
    id
    seq_id
    name
    description
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessEntityMutationVariables,
  APITypes.DeleteBusinessEntityMutation
>;
export const createGroupCompany = /* GraphQL */ `mutation CreateGroupCompany(
  $input: CreateGroupCompanyInput!
  $condition: ModelGroupCompanyConditionInput
) {
  createGroupCompany(input: $input, condition: $condition) {
    id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_2
    management_business_1
    management_business_2
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    deleted_at
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupCompanyMutationVariables,
  APITypes.CreateGroupCompanyMutation
>;
export const updateGroupCompany = /* GraphQL */ `mutation UpdateGroupCompany(
  $input: UpdateGroupCompanyInput!
  $condition: ModelGroupCompanyConditionInput
) {
  updateGroupCompany(input: $input, condition: $condition) {
    id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_2
    management_business_1
    management_business_2
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    deleted_at
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupCompanyMutationVariables,
  APITypes.UpdateGroupCompanyMutation
>;
export const deleteGroupCompany = /* GraphQL */ `mutation DeleteGroupCompany(
  $input: DeleteGroupCompanyInput!
  $condition: ModelGroupCompanyConditionInput
) {
  deleteGroupCompany(input: $input, condition: $condition) {
    id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_2
    management_business_1
    management_business_2
    country_id
    country {
      id
      order_id
      name
      name_en
      country_code
      code_two_char
      code_three_char
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency {
      id
      order_id
      currency_code
      code_three_char
      name
      name_en
      digit_number
      currency_symbol
      update_reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    deleted_at
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupCompanyMutationVariables,
  APITypes.DeleteGroupCompanyMutation
>;
export const createGroupCompanyHistory = /* GraphQL */ `mutation CreateGroupCompanyHistory(
  $input: CreateGroupCompanyHistoryInput!
  $condition: ModelGroupCompanyHistoryConditionInput
) {
  createGroupCompanyHistory(input: $input, condition: $condition) {
    id
    operation_type
    group_company_id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_1_name
    unit_class_2
    unit_class_2_name
    management_business_1
    management_business_2
    country_id
    country_name
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency_name
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_user_name
    updatedAt
    createdAt
    old_id
    old_operation_type
    old_group_company_id
    old_seq_id
    old_group_company_code
    old_valid_start_date
    old_valid_end_date
    old_stravis_code
    old_company_name_ja
    old_company_name_en
    old_abbreviation
    old_company_attribute
    old_consolidation
    old_equity_method_application
    old_unit_class_1
    old_unit_class_1_name
    old_unit_class_2
    old_unit_class_2_name
    old_management_business_1
    old_management_business_2
    old_country_id
    old_country_name
    old_accounting_period
    old_public_or_private
    old_group_agreement_flg
    old_management_contract_flg
    old_subcontracting_contract_flg
    old_brand_portfolio
    old_holding_company_1
    old_investment_ratio_1
    old_holding_company_2
    old_investment_ratio_2
    old_holding_company_3
    old_investment_ratio_3
    old_holding_company_4
    old_investment_ratio_4
    old_holding_company_5
    old_investment_ratio_5
    old_total_investment_ratio
    old_issued_shares_total
    old_treasury_stock
    old_nominee_shares
    old_capital
    old_currency_id
    old_currency_name
    old_number_of_employees
    old_establishment_date
    old_representative_director_1_position
    old_representative_director_1_name
    old_representative_director_2_position
    old_representative_director_2_name
    old_representative_director_3_position
    old_representative_director_3_name
    old_business_description
    old_address_1
    old_address_2
    old_notes
    old_ip_address
    old_delete_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_updatedAt
    old_createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupCompanyHistoryMutationVariables,
  APITypes.CreateGroupCompanyHistoryMutation
>;
export const updateGroupCompanyHistory = /* GraphQL */ `mutation UpdateGroupCompanyHistory(
  $input: UpdateGroupCompanyHistoryInput!
  $condition: ModelGroupCompanyHistoryConditionInput
) {
  updateGroupCompanyHistory(input: $input, condition: $condition) {
    id
    operation_type
    group_company_id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_1_name
    unit_class_2
    unit_class_2_name
    management_business_1
    management_business_2
    country_id
    country_name
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency_name
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_user_name
    updatedAt
    createdAt
    old_id
    old_operation_type
    old_group_company_id
    old_seq_id
    old_group_company_code
    old_valid_start_date
    old_valid_end_date
    old_stravis_code
    old_company_name_ja
    old_company_name_en
    old_abbreviation
    old_company_attribute
    old_consolidation
    old_equity_method_application
    old_unit_class_1
    old_unit_class_1_name
    old_unit_class_2
    old_unit_class_2_name
    old_management_business_1
    old_management_business_2
    old_country_id
    old_country_name
    old_accounting_period
    old_public_or_private
    old_group_agreement_flg
    old_management_contract_flg
    old_subcontracting_contract_flg
    old_brand_portfolio
    old_holding_company_1
    old_investment_ratio_1
    old_holding_company_2
    old_investment_ratio_2
    old_holding_company_3
    old_investment_ratio_3
    old_holding_company_4
    old_investment_ratio_4
    old_holding_company_5
    old_investment_ratio_5
    old_total_investment_ratio
    old_issued_shares_total
    old_treasury_stock
    old_nominee_shares
    old_capital
    old_currency_id
    old_currency_name
    old_number_of_employees
    old_establishment_date
    old_representative_director_1_position
    old_representative_director_1_name
    old_representative_director_2_position
    old_representative_director_2_name
    old_representative_director_3_position
    old_representative_director_3_name
    old_business_description
    old_address_1
    old_address_2
    old_notes
    old_ip_address
    old_delete_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_updatedAt
    old_createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupCompanyHistoryMutationVariables,
  APITypes.UpdateGroupCompanyHistoryMutation
>;
export const deleteGroupCompanyHistory = /* GraphQL */ `mutation DeleteGroupCompanyHistory(
  $input: DeleteGroupCompanyHistoryInput!
  $condition: ModelGroupCompanyHistoryConditionInput
) {
  deleteGroupCompanyHistory(input: $input, condition: $condition) {
    id
    operation_type
    group_company_id
    seq_id
    group_company_code
    valid_start_date
    valid_end_date
    stravis_code
    company_name_ja
    company_name_en
    abbreviation
    company_attribute
    consolidation
    equity_method_application
    unit_class_1
    unit_class_1_name
    unit_class_2
    unit_class_2_name
    management_business_1
    management_business_2
    country_id
    country_name
    accounting_period
    public_or_private
    group_agreement_flg
    management_contract_flg
    subcontracting_contract_flg
    brand_portfolio
    holding_company_1
    investment_ratio_1
    holding_company_2
    investment_ratio_2
    holding_company_3
    investment_ratio_3
    holding_company_4
    investment_ratio_4
    holding_company_5
    investment_ratio_5
    total_investment_ratio
    issued_shares_total
    treasury_stock
    nominee_shares
    capital
    currency_id
    currency_name
    number_of_employees
    establishment_date
    representative_director_1_position
    representative_director_1_name
    representative_director_2_position
    representative_director_2_name
    representative_director_3_position
    representative_director_3_name
    business_description
    address_1
    address_2
    notes
    ip_address
    delete_flg
    update_reason
    updated_user_id
    updated_user_name
    updatedAt
    createdAt
    old_id
    old_operation_type
    old_group_company_id
    old_seq_id
    old_group_company_code
    old_valid_start_date
    old_valid_end_date
    old_stravis_code
    old_company_name_ja
    old_company_name_en
    old_abbreviation
    old_company_attribute
    old_consolidation
    old_equity_method_application
    old_unit_class_1
    old_unit_class_1_name
    old_unit_class_2
    old_unit_class_2_name
    old_management_business_1
    old_management_business_2
    old_country_id
    old_country_name
    old_accounting_period
    old_public_or_private
    old_group_agreement_flg
    old_management_contract_flg
    old_subcontracting_contract_flg
    old_brand_portfolio
    old_holding_company_1
    old_investment_ratio_1
    old_holding_company_2
    old_investment_ratio_2
    old_holding_company_3
    old_investment_ratio_3
    old_holding_company_4
    old_investment_ratio_4
    old_holding_company_5
    old_investment_ratio_5
    old_total_investment_ratio
    old_issued_shares_total
    old_treasury_stock
    old_nominee_shares
    old_capital
    old_currency_id
    old_currency_name
    old_number_of_employees
    old_establishment_date
    old_representative_director_1_position
    old_representative_director_1_name
    old_representative_director_2_position
    old_representative_director_2_name
    old_representative_director_3_position
    old_representative_director_3_name
    old_business_description
    old_address_1
    old_address_2
    old_notes
    old_ip_address
    old_delete_flg
    old_update_reason
    old_updated_user_id
    old_updated_user_name
    old_updatedAt
    old_createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupCompanyHistoryMutationVariables,
  APITypes.DeleteGroupCompanyHistoryMutation
>;
export const createBneCustomerGroupCompany = /* GraphQL */ `mutation CreateBneCustomerGroupCompany(
  $input: CreateBneCustomerGroupCompanyInput!
  $condition: ModelBneCustomerGroupCompanyConditionInput
) {
  createBneCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBneCustomerGroupCompanyMutationVariables,
  APITypes.CreateBneCustomerGroupCompanyMutation
>;
export const updateBneCustomerGroupCompany = /* GraphQL */ `mutation UpdateBneCustomerGroupCompany(
  $input: UpdateBneCustomerGroupCompanyInput!
  $condition: ModelBneCustomerGroupCompanyConditionInput
) {
  updateBneCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBneCustomerGroupCompanyMutationVariables,
  APITypes.UpdateBneCustomerGroupCompanyMutation
>;
export const deleteBneCustomerGroupCompany = /* GraphQL */ `mutation DeleteBneCustomerGroupCompany(
  $input: DeleteBneCustomerGroupCompanyInput!
  $condition: ModelBneCustomerGroupCompanyConditionInput
) {
  deleteBneCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBneCustomerGroupCompanyMutationVariables,
  APITypes.DeleteBneCustomerGroupCompanyMutation
>;
export const createBcCustomerGroupCompany = /* GraphQL */ `mutation CreateBcCustomerGroupCompany(
  $input: CreateBcCustomerGroupCompanyInput!
  $condition: ModelBcCustomerGroupCompanyConditionInput
) {
  createBcCustomerGroupCompany(input: $input, condition: $condition) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBcCustomerGroupCompanyMutationVariables,
  APITypes.CreateBcCustomerGroupCompanyMutation
>;
export const updateBcCustomerGroupCompany = /* GraphQL */ `mutation UpdateBcCustomerGroupCompany(
  $input: UpdateBcCustomerGroupCompanyInput!
  $condition: ModelBcCustomerGroupCompanyConditionInput
) {
  updateBcCustomerGroupCompany(input: $input, condition: $condition) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBcCustomerGroupCompanyMutationVariables,
  APITypes.UpdateBcCustomerGroupCompanyMutation
>;
export const deleteBcCustomerGroupCompany = /* GraphQL */ `mutation DeleteBcCustomerGroupCompany(
  $input: DeleteBcCustomerGroupCompanyInput!
  $condition: ModelBcCustomerGroupCompanyConditionInput
) {
  deleteBcCustomerGroupCompany(input: $input, condition: $condition) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBcCustomerGroupCompanyMutationVariables,
  APITypes.DeleteBcCustomerGroupCompanyMutation
>;
export const createBnfCustomerGroupCompany = /* GraphQL */ `mutation CreateBnfCustomerGroupCompany(
  $input: CreateBnfCustomerGroupCompanyInput!
  $condition: ModelBnfCustomerGroupCompanyConditionInput
) {
  createBnfCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_code
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBnfCustomerGroupCompanyMutationVariables,
  APITypes.CreateBnfCustomerGroupCompanyMutation
>;
export const updateBnfCustomerGroupCompany = /* GraphQL */ `mutation UpdateBnfCustomerGroupCompany(
  $input: UpdateBnfCustomerGroupCompanyInput!
  $condition: ModelBnfCustomerGroupCompanyConditionInput
) {
  updateBnfCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_code
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBnfCustomerGroupCompanyMutationVariables,
  APITypes.UpdateBnfCustomerGroupCompanyMutation
>;
export const deleteBnfCustomerGroupCompany = /* GraphQL */ `mutation DeleteBnfCustomerGroupCompany(
  $input: DeleteBnfCustomerGroupCompanyInput!
  $condition: ModelBnfCustomerGroupCompanyConditionInput
) {
  deleteBnfCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_code
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBnfCustomerGroupCompanyMutationVariables,
  APITypes.DeleteBnfCustomerGroupCompanyMutation
>;
export const createBspCustomerGroupCompany = /* GraphQL */ `mutation CreateBspCustomerGroupCompany(
  $input: CreateBspCustomerGroupCompanyInput!
  $condition: ModelBspCustomerGroupCompanyConditionInput
) {
  createBspCustomerGroupCompany(input: $input, condition: $condition) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBspCustomerGroupCompanyMutationVariables,
  APITypes.CreateBspCustomerGroupCompanyMutation
>;
export const updateBspCustomerGroupCompany = /* GraphQL */ `mutation UpdateBspCustomerGroupCompany(
  $input: UpdateBspCustomerGroupCompanyInput!
  $condition: ModelBspCustomerGroupCompanyConditionInput
) {
  updateBspCustomerGroupCompany(input: $input, condition: $condition) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBspCustomerGroupCompanyMutationVariables,
  APITypes.UpdateBspCustomerGroupCompanyMutation
>;
export const deleteBspCustomerGroupCompany = /* GraphQL */ `mutation DeleteBspCustomerGroupCompany(
  $input: DeleteBspCustomerGroupCompanyInput!
  $condition: ModelBspCustomerGroupCompanyConditionInput
) {
  deleteBspCustomerGroupCompany(input: $input, condition: $condition) {
    id
    souten_cd
    souten_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBspCustomerGroupCompanyMutationVariables,
  APITypes.DeleteBspCustomerGroupCompanyMutation
>;
export const createBnmlCustomerGroupCompany = /* GraphQL */ `mutation CreateBnmlCustomerGroupCompany(
  $input: CreateBnmlCustomerGroupCompanyInput!
  $condition: ModelBnmlCustomerGroupCompanyConditionInput
) {
  createBnmlCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBnmlCustomerGroupCompanyMutationVariables,
  APITypes.CreateBnmlCustomerGroupCompanyMutation
>;
export const updateBnmlCustomerGroupCompany = /* GraphQL */ `mutation UpdateBnmlCustomerGroupCompany(
  $input: UpdateBnmlCustomerGroupCompanyInput!
  $condition: ModelBnmlCustomerGroupCompanyConditionInput
) {
  updateBnmlCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBnmlCustomerGroupCompanyMutationVariables,
  APITypes.UpdateBnmlCustomerGroupCompanyMutation
>;
export const deleteBnmlCustomerGroupCompany = /* GraphQL */ `mutation DeleteBnmlCustomerGroupCompany(
  $input: DeleteBnmlCustomerGroupCompanyInput!
  $condition: ModelBnmlCustomerGroupCompanyConditionInput
) {
  deleteBnmlCustomerGroupCompany(input: $input, condition: $condition) {
    id
    customer_cd
    customer_name
    group_company_code
    delete_flg
    deleted_at
    updated_user_id
    updated_by {
      id
      cognito_user_id
      email
      name
      roles {
        items {
          id
          user_id
          role_id
          user {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role {
            id
            seq_id
            label
            name
            default
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          delete_flg
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            nextToken
            startedAt
            __typename
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            updated_user_id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    update_reason
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBnmlCustomerGroupCompanyMutationVariables,
  APITypes.DeleteBnmlCustomerGroupCompanyMutation
>;
