import React from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

import PrimaryButton from '../components/PrimaryButton';

export interface ConfirmDialogProps {
  message: string;
  open: boolean;
  onCancel?: Function;
  onContinue?: Function;
  type?: 'error' | 'info' | 'success' | 'warning';
  updateReason?: React.ReactElement;
  isEnsureMessageHeight?: boolean;
}

function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    message,
    open,
    onCancel,
    onContinue,
    type = 'warning',
    updateReason,
    isEnsureMessageHeight,
  } = props;

  const doContinue = () => {
    onContinue && onContinue();
  };

  const doCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Dialog open={open} onClose={onCancel ? doCancel : doContinue}>
      <DialogContent sx={isEnsureMessageHeight ? { minHeight: '108px' } : {}}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert
            variant="filled"
            severity={type}
            sx={{ whiteSpace: 'pre-line' }}
          >
            {message}
          </Alert>
        </Stack>
      </DialogContent>
      {updateReason}
      <DialogActions>
        <Stack direction="row" justifyContent="center" spacing={2} width="100%">
          {onCancel && (
            <PrimaryButton variant="text" onClick={doCancel}>
              いいえ
            </PrimaryButton>
          )}
          {onContinue && (
            <PrimaryButton variant="text" onClick={doContinue}>
              はい
            </PrimaryButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmDialog;
