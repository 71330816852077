export const EDIT_USER_FIELDS = [
  {
    field: 'user_email',
    label: 'メールアドレス',
    type: 'text',
    disabled: true,
  },
  {
    field: 'userRoles_IP',
    label: '一般ユーザー（IPマスタ）',
    type: 'checkbox',
    inputLabel: 'ロール設定',
  },
  {
    field: 'userRoles_PRODUCT',
    label: '一般ユーザー（商品マスタ）',
    type: 'checkbox',
  },
  {
    field: 'userRoles_COMPANY',
    label: '一般ユーザー（グループ会社マスタ）',
    type: 'checkbox',
  },
  {
    field: 'userRoles_ADMIN',
    label: '管理ユーザー',
    type: 'checkbox',
  },
];
