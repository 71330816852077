import React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { I18n } from 'aws-amplify/utils';
interface model {
  id: string;
  name: string;
}

interface FormRadioGroupProps {
  /** ラベル */
  label?: string;
  /** ヘルパーテキスト */
  // helperText?: strng;
  /** 内容 */
  content: model[];
  /** インプット内容 */
  value?: string;
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** ReactFookForm */
  control: any;
}

export default function RadioButtonsGroup(props: FormRadioGroupProps) {
  const { label, content, value, disabled = false, field, control } = props;

  return (
    <FormControl component="fieldset" fullWidth disabled={disabled}>
      {label && <FormLabel component="legend">{I18n.get(label)}</FormLabel>}
      <Controller
        name={field}
        control={control}
        defaultValue={value ? value : content[0].id}
        render={({ field }) => (
          <RadioGroup {...field} row>
            {content.map((option) => (
              <FormControlLabel value={option.id} control={<Radio />} label={option.name} key={option.id} />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
