import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormSelectBox from '../forms/FormSelectBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import { OPERATION_TYPE_OPTIONS, DELETE_FLG_OPTIONS } from '../../consts/common';
import {
  SEARCH_TAG_FIELDS,
  SEARCH_TAG_ADMIN_FIELDS,
  SEARCH_TAG_FIELDS_DEFAULT_VALUES,
} from '../../consts/tag';
import { tagFields } from '../../types';
import { classificationValidateTag } from '../../hooks/classification';
import { TagSearch } from '../../types/form/tag'

type Props = {
  isAdmin?: boolean;
  open: boolean;
  setOpen: Function;
  fetchTags: Function
  condition: TagSearch
}

function SearchTagDialog(props: Props) {
  const { isAdmin, open, setOpen, fetchTags, condition } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_TAG_FIELDS_DEFAULT_VALUES });
  const fields = isAdmin ? SEARCH_TAG_ADMIN_FIELDS : SEARCH_TAG_FIELDS;

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<TagSearch> = async (newCondition) => {
    await fetchTags({ newCondition })
    setOpen(false)
  };

  const onReset = () => {
    reset();
  };

  const options = (name: string) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <SearchDialog
      open={open}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
      height="470"
      width="sm"
    >
      <Box
        sx={{ '& > :not(style)': { m: 2, width: 400 } }}
        width={440}
      >
        {fields.map((row, idx) => (
          <React.Fragment key={idx}>
            {(row.type === 'select') && (
              <FormSelectBox
                label={row.label}
                content={options(row.field)}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
              />
            )}
            {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={row.inputs[0].field}
                  toLabel={row.inputs[0].label}
                  toField={row.inputs[1].field}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateTag(row.field as tagFields, 'search')}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </SearchDialog >
  );
}

export default SearchTagDialog;
