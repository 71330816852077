import React, { memo } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import { theme } from '../../assets/theme/options';

interface RequiredLabelProps {
  green?: boolean;
}

function RequiredLabel(props: RequiredLabelProps) {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <Avatar
        sx={{
          bgcolor: props.green ? theme.colors.greenBg : theme.colors.requiredBg,
          height: 18,
          width: 32,
          fontSize: '0.8rem',
          fontWeight: 'bold',
          position: 'absolute',
          right: '4px',
        }}
        variant="rounded"
      >
        {props.green ? '連携' : '必須'}
      </Avatar>
    </Stack>
  );
}

export default memo(RequiredLabel);
