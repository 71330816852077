import React, { useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import NewTagDialog from '../components/tags/NewTagDialog';
import EditTagDialog from '../components/tags/EditTagDialog';
import SearchTagDialog from '../components/tags/SearchTagDialog';
import { AuthContext } from '../contexts/AuthContext';
import { useSearchTags } from '../hooks/useSearchTags';
import { filterColumnsForGeneralRole } from '../utils/filterColumns'

import type { Tag } from '../API';

const columns: GridColDef[] = [
  { field: 'seq_id', headerName: 'タグID', width: 190 },
  {
    field: 'name_ja',
    headerName: 'IPタグ(日本語)',
    width: 270,
  },
  {
    field: 'name_en',
    headerName: 'IPタグ(英語)',
    width: 270,
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
  {
    field: 'updated_user_id',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    },
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function TagList() {
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [row, setRow] = useState<Tag | null>(null);
  const { user, roles } = useContext(AuthContext);
  const roleTypes = roles?.map((role) => role.role?.label);

  const { fetchTags, loading, total, pageSize, onChangePageSize, page, tags, onChangePage, condition, onChangeSort } = useSearchTags()

  useEffect(() => {
    fetchTags({})
    // eslint-disable-next-line
  }, []);

  // 一般ユーザの時は削除済データのカラムを非表示にする
  const removeColumns = ["delete_flg"]
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns)

  const doOpenNew = useCallback(() => {
    setOpenNew(true);
  }, []);

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);


  if (!roleTypes) return <></>;
  console.log({ tags });
  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
        <PrimaryButton onClick={doOpenNew}>新規作成</PrimaryButton>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={tags}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}

          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {user && (
        <NewTagDialog
          open={openNew}
          setOpen={setOpenNew}
          user={user}
          isTag={true}
          fetchTags={fetchTags}
        />)}
      {row && user && (
        <EditTagDialog
          open={openEdit}
          row={row}
          setOpen={setOpenEdit}
          setRow={setRow}
          user={user}
          fetchTags={fetchTags}
        />)}
      <SearchTagDialog
        isAdmin={roleTypes.includes('ADMIN')}
        open={openSearch}
        setOpen={setOpenSearch}
        fetchTags={fetchTags}
        condition={condition}
      />
    </Box>
  );
}

export default TagList;
