export const DETAIL_BNAM_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "select",
    optionsName: "operation_type",
    placeholder: "操作種別を選択",
    required: false,
    disabled: true,
  },
  {
    field: "seq_id",
    label: "BNAMマスターID",
    type: "text",
    placeholder: "BNAMマスターIDを入力",
    required: false,
    disabled: true,
  },
  {
    field: "ip_name",
    label: "BNAM IP名称",
    type: "text",
    placeholder: "BNAM IP名称を入力",
    required: false,
    disabled: true,
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "最終更新者を入力",
    required: false,
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "最終更新日時",
    type: "datetime",
    required: false,
    disabled: true,
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "textarea",
    placeholder: "更新理由を入力",
    required: false,
    disabled: true,
  },
  {
    field: "delete_flg",
    label: "削除済データ",
    type: "checkbox",
    required: false,
    disabled: true,
  },
  {
    field: "admin_check_flg",
    label: "管理者チェックフラグ",
    type: "checkbox",
    required: false,
    disabled: true,
  },
];

export const SEARCH_BNAM_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "select",
    placeholder: "操作種別を選択",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "BNAMマスターID",
    type: "text",
    placeholder: "BNAMマスターIDを入力",
  },
  {
    field: "ip_name",
    label: "BNAM IP名称",
    type: "text",
    placeholder: "BNAM IP名称を入力",
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'title_final-update-date' },
      { field: 'to', label: 'title_final-update-date' },
    ],
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "text",
    placeholder: "更新理由を入力",
  },
  {
    field: "admin_check_flg",
    label: "管理者チェックフラグ",
    type: "select",
    optionsName: "admin_check_flg",
    isBlank: false,
  },
  {
    field: "delete_flg",
    label: "削除済データ",
    type: "select",
    optionsName: "delete_flg",
    isBlank: false,
  },
];

export const SEARCH_BNAM_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  seq_id: "",
  ip_name: "",
  updated_user_id: "",
  updatedAt_from: null,
  updatedAt_to: null,
  update_reason: "",
  admin_check_flg: "ALL",
  delete_flg: "ALL",
};

