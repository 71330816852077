export const onCreateTagWithoutRelations = /* GraphQL */ `
  subscription OnCreateTagWithoutRelations($filter: ModelSubscriptionTagFilterInput) {
    onCreateTag(filter: $filter) {
      id
      seq_id
      name_ja
      name_en
      updated_user_id
      updated_by {
        id
        cognito_user_id
        email
        name
        roles {
          items {
            id
            user_id
            role_id
            user {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            role {
              id
              seq_id
              label
              name
              default
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            delete_flg
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        updated_user_id
        updated_by {
          id
          cognito_user_id
          email
          name
          roles {
            items {
              id
              user_id
              role_id
              delete_flg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          updated_user_id
          updated_by {
            id
            cognito_user_id
            email
            name
            roles {
              nextToken
              startedAt
            }
            updated_user_id
            updated_by {
              id
              cognito_user_id
              email
              name
              updated_user_id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      delete_flg
      deleted_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
