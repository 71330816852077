import { useState } from 'react';
import { searchBnfs } from '../graphql/queries';
import { generateSearchFilter } from '../utils/search';
import usePageSize from './usePageSize';
import { dataToFilterBnfObj } from '../utils/fetchData';
import { Bnf, SearchableBnfSortInput } from '../API';
import { BnfSearch } from '../types/form/bnf';
import { SEARCH_BNF_FIELDS_DEFAULT_VALUES } from '../consts/bnf';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = { direction: 'desc', field: 'seq_id' };

export const useSearchBnfs = () => {
  const [bnfs, setBnfs] = useState<Bnf[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<BnfSearch>(SEARCH_BNF_FIELDS_DEFAULT_VALUES);
  const [searchFilter, setSearchFilter] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >();

  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();

  const fetchBnfs = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort
  }: {
    newCondition?: BnfSearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const records = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          if (value === 'ALL' && key === 'delete_flg') return;
          if (value === 'ALL' && key === 'admin_check_flg') return;
          return {
            name: dataToFilterBnfObj[key].name,
            value: value,
            type: dataToFilterBnfObj[key].type
          };
        })
        .filter(Boolean);
      setSearchFilter(records);
      const filter = generateSearchFilter(records);
      const models: any = await API.graphql({
        query: searchBnfs,
        variables: {
          ...filter,
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });

      setTotal(models.data.searchBnfs.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchBnfs.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchBnfs.nextToken])));
      }
      setBnfs([...models.data.searchBnfs.items.filter((item) => item != null)]);
      newCondition && setCondition({ ...newCondition });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchBnfs({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchBnfs({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchBnfs({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchBnfs({ nextToken });
  };

  return {
    fetchBnfs,
    loading,
    total,
    pageSize,
    onChangePageSize,
    bnfs,
    setBnfs,
    onChangePage,
    condition,
    page,
    onChangeSort,
    searchFilter
  };
};
