import React, { useState, useEffect, useContext } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { theme } from '../assets/theme/options';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import { convertOperationType } from '../utils/converter';
import { AuthContext } from '../contexts/AuthContext';
import SearchTagBnfHistoryDialog from '../components/tagBnfHistory/SearchTagBnfHistoryDialog';
import { useSearchTagBnfHistories } from '../hooks/useSearchTagBnfHistories';

const columns: GridColDef[] = [
  {
    field: 'operation_type', headerName: '操作種別',
    width: 210,
    valueFormatter: ({ value }) => convertOperationType[value]
  },
  {
    field: 'bnf_seq_id',
    headerName: 'BNFマスターID',
    width: 210,
  },
  {
    field: 'bnf_data_source',
    headerName: 'BNFデータソース',
    width: 210,
  },
  {
    field: 'obic7_commodity_cd',
    headerName: 'OBIC7商品コード',
    width: 210,
  },
  {
    field: 'obic7_commodity_name',
    headerName: 'OBIC7商品名',
    width: 210,
  },
  {
    field: 'bvics_plan_cd_4char',
    headerName: 'BVICS企画コード(4桁)',
    width: 210,
  },
  {
    field: 'bvics_plan_cd_7char',
    headerName: 'BVICS企画コード(7桁)',
    width: 210,
  },
  {
    field: 'bvics_product_name',
    headerName: 'BVICS商品名',
    width: 210,
  },
  { field: 'tag_name', headerName: 'IPタグ（日本語）', width: 210 },
  { field: 'tag_name_en', headerName: 'IPタグ（英語）', width: 210 },
  {
    field: 'updated_user_name',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => params.row.updated_user_name
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: ({ value }) => format(parseISO(value), 'yyyy/MM/dd HH:mm')
  },
];

function TagBnfHistoryList() {
  const [openSearch, setOpenSearch] = useState(false);
  const roleTypes = useContext(AuthContext).roles?.map((role) => role.role?.label);

  const { fetchTagBnfHistories, loading, total, pageSize, onChangePageSize, tagBnfHistories, setTagBnfHistories, onChangePage, condition, setCondition, page, onChangeSort } = useSearchTagBnfHistories()

  useEffect(() => {
    fetchTagBnfHistories({})
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} textAlign={'right'}>
        <PrimaryButton onClick={() => setOpenSearch(true)}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={tagBnfHistories}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .edited_cell': {
              background: theme.colors.editedCell
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openSearch && (
        <SearchTagBnfHistoryDialog
          condition={condition}
          isAdmin={roleTypes?.includes('ADMIN')}
          open={openSearch}
          setOpen={setOpenSearch}
          fetchTagBnfHistories={fetchTagBnfHistories}
        />
      )}
    </Box>
  );
}

export default TagBnfHistoryList;