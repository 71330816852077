import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormCheckBox from '../forms/FormCheckBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import { SEARCH_TAG_BNAM_FIELDS, SEARCH_TAG_BNAM_FIELDS_DEFAULT_VALUES } from '../../consts/tagBnam';
import { tagBnamFields } from '../../types';
import { classificationValidateTagBnam } from '../../hooks/classification';
import { TagBnamSearch } from '../../types/form/tagBnamSearch'
import { fetchTagBnams } from '../../utils/fetchData';

type Props = {
  condition: TagBnamSearch;
  open: boolean;
  setCondition: Function;
  setOpen: Function;
  setBnams: Function;
  setBnamIds: Function;
  setExpQueries: Function;
}

function SearchTagBnamDialog(props: Props) {
  const { condition, open, setCondition, setOpen, setBnams, setBnamIds, setExpQueries } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues: SEARCH_TAG_BNAM_FIELDS_DEFAULT_VALUES });
  const watchIsUnregistered = watch('is_unregistered');

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  useEffect(() => {
    if (watchIsUnregistered) {
      setValue('tags_ja', '')
      setValue('tags_en', '')
      setValue('update_reason', '')
    }
  }, [watchIsUnregistered, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<TagBnamSearch> = async (data) => {
    await fetchTagBnams(data, setBnams, setCondition, onClose, setBnamIds, setExpQueries)
  };

  const onReset = () => {
    reset();
  };

  return (
    <SearchDialog open={open} onCancel={onClose} onSubmit={handleSubmit(onSubmit)} onReset={onReset} height={570} width="md">
      <Box
        sx={{ '& > :not(style)': { m: 2, width: 400 } }}
        width={440}
      >
        {SEARCH_TAG_BNAM_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={row.inputs[0].field}
                  toLabel={row.inputs[0].label}
                  toField={row.inputs[1].field}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {(row.type === 'text') && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateTagBnam(row.field as tagBnamFields, 'search')}
                disabled={['tags_ja', 'tags_en', 'update_reason'].includes(row.field) && watchIsUnregistered}
              />
            )}
            {row.type === 'checkbox' && (
              <FormCheckBox field={row.field} label={row.label} control={control} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchTagBnamDialog;
