import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import { I18n } from 'aws-amplify/utils';

interface FormCheckBoxProps {
  /** ラベル */
  label: string;
  /** デフォルト値 */
  defaultValue?: boolean;
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** ReactFookForm */
  control: any;
  errors?: any;
  /** 必須ラベルの有無 */
  required?: boolean;
  /** 外ラベル */
  inputLabel?: string;
}

// TODO: errors, required(必須ラベルの組み込みは未実装)

function FormCheckBox(props: FormCheckBoxProps) {
  const { label, defaultValue = false, disabled, field, control, errors, required, inputLabel } = props;

  return (
    <>
      {inputLabel && <InputLabel sx={{ fontSize: '12px', marginBottom: '2px' }}>{inputLabel}</InputLabel>}
      <Controller
        name={field}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            disabled={disabled}
            label={I18n.get(label)}
            control={<Checkbox {...field} checked={field.value} />}
            sx={{ display: 'block' }} // ←これ正しい？
          />
        )}
      />
    </>
  );
}

export default FormCheckBox;
