import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormCheckBox from '../forms/FormCheckBox';
import FormTextField from '../forms/FormTextField';
import FormArrayTextField from '../forms/FormArrayTextField';
import FormDialog from '../FormDialog';
import EditConfirmDialog from '../EditConfirmDialog';

import { createGroupCompany } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { classificationValidateGroupCompany, classificationValidateTagTitle } from '../../hooks/classification';
import { sleep } from '../../utils/fetchData';
import { AuthContext } from '../../contexts/AuthContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Country, Currency, GroupCompany } from '../../models';
import { CreateGroupCompanyInput } from '../../API';
import FormSelectBox from '../forms/FormSelectBox';
import { duplicateDetection } from '../../utils/duplicateDetection';
import {
  BRAND_PORTFOLIO_LIST,
  COMPANY_ATTRIBUTE_LIST,
  CONSOLIDATION_LIST,
  CREATE_GROUP_COMPANY_FIELDS,
  EQUITY_METHOD_APPLICATION_LIST,
  MANAGEMENT_BUSINESS_LIST,
  PUBLIC_OR_PRIVATE_LIST,
  UNIT_CLASS_LIST,
  VALIDATE_DUPLICATE_GROUP_COMPANY_FIELDS,
} from '../../consts/groupCompany';
import { ConfirmSubmitGroupCompany } from '../../types/form/GroupCompany';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import PrimaryButton from '../PrimaryButton';
import FormAutocomplete from '../forms/FormAutocomplete';
import { generateSearchFilter, SEARCH_TYPE } from '../../utils/search';
import { searchGroupCompanies } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  groupCompanies: GroupCompany[];
  countries: Country[];
  currencies: Currency[];
  open: boolean;
  setOpen: Function;
  fetchGroupCompanies: Function;
};

function NewGroupCompanyDialog(props: Props) {
  const { groupCompanies, countries, currencies, setOpen, fetchGroupCompanies } = props;
  const [isOpenEditConfirm, setIsOpenEditConfirm] = useState(false);
  const [confirmData, setConfirmData] = useState<ConfirmSubmitGroupCompany>();
  const [record, setRecord] = useState<ConfirmSubmitGroupCompany>();
  const getNextId = useAutoIncrement('GRM');
  const { addNotification } = useContext(NotificationContext);
  const [currentGroupCompanies, setCurrentGroupCompanies] = useState<GroupCompany[]>(groupCompanies);

  console.log({ confirmData });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const { user } = useContext(AuthContext);
  const onClose = () => setOpen(false);

  const onConfirmSubmit: SubmitHandler<ConfirmSubmitGroupCompany> = useCallback(
    async (data) => {
      // 重複チェック
      const duplicationResults = await duplicateDetection(
        'GROUP_COMPANY',
        VALIDATE_DUPLICATE_GROUP_COMPANY_FIELDS,
        data,
        clearErrors,
        setError
      );
      if (duplicationResults.every((result) => !result)) {
        setConfirmData({
          ...data,
          // @ts-ignore
          holding_company_1: data.holding_company_1?.group_company_code,
          // @ts-ignore
          holding_company_2: data.holding_company_2?.group_company_code,
          // @ts-ignore
          holding_company_3: data.holding_company_3?.group_company_code,
          // @ts-ignore
          holding_company_4: data.holding_company_4?.group_company_code,
          // @ts-ignore
          holding_company_5: data.holding_company_5?.group_company_code,
        });
        setRecord(data);
        setIsOpenEditConfirm(true);
      }
    },
    [clearErrors, setError]
  );

  /** ArrayText系は特殊なのでRHFのモジュールを使って初期値をセット */
  const resetArrayTextValues = useCallback(() => {
    setValue('ip_address', [{ value: '' }]);
  }, [setValue]);

  useEffect(() => {
    resetArrayTextValues();
  }, [resetArrayTextValues]);

  const stringToNumber = (text: string | null | number | undefined) => {
    if (typeof text !== 'number' && !text) return null;
    return Number(text);
  };

  const onSubmit = async () => {
    try {
      const ip_address = record['ip_address'].map((v) => v.value);

      const seq_id = await getNextId();
      const formData: CreateGroupCompanyInput = {
        ...record,
        seq_id,
        ip_address,
        delete_flg: false,
        updated_user_id: user.id,
        accounting_period: stringToNumber(record.accounting_period ?? null),
        investment_ratio_1: stringToNumber(record.investment_ratio_1 ?? null),
        investment_ratio_2: stringToNumber(record.investment_ratio_2 ?? null),
        investment_ratio_3: stringToNumber(record.investment_ratio_3 ?? null),
        investment_ratio_4: stringToNumber(record.investment_ratio_4 ?? null),
        investment_ratio_5: stringToNumber(record.investment_ratio_5 ?? null),
        capital: record.capital,
        number_of_employees: stringToNumber(record.number_of_employees ?? null),
        total_investment_ratio: stringToNumber(record.total_investment_ratio ?? null),
        issued_shares_total: stringToNumber(record.issued_shares_total ?? null),
        treasury_stock: stringToNumber(record.treasury_stock ?? null),
        // @ts-ignore
        holding_company_1: record.holding_company_1?.group_company_code ?? null,
        // @ts-ignore
        holding_company_2: record.holding_company_2?.group_company_code ?? null,
        // @ts-ignore
        holding_company_3: record.holding_company_3?.group_company_code ?? null,
        // @ts-ignore
        holding_company_4: record.holding_company_4?.group_company_code ?? null,
        // @ts-ignore
        holding_company_5: record.holding_company_5?.group_company_code ?? null,
      };
      await API.graphql({ query: createGroupCompany, variables: { input: formData } });
      setIsOpenEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'グループ会社マスタの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchGroupCompanies({});
      reset();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'グループ会社マスタの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const options = (name: string) => {
    switch (name) {
      case 'company_attribute':
        return COMPANY_ATTRIBUTE_LIST;
      case 'consolidation':
        return CONSOLIDATION_LIST;
      case 'equity_method_application':
        return EQUITY_METHOD_APPLICATION_LIST;
      case 'unit_class_1':
      case 'unit_class_2':
        return UNIT_CLASS_LIST;
      case 'management_business_1':
      case 'management_business_2':
        return MANAGEMENT_BUSINESS_LIST;
      case 'country_id':
        return countries;
      case 'public_or_private':
        return PUBLIC_OR_PRIVATE_LIST;
      case 'brand_portfolio':
        return BRAND_PORTFOLIO_LIST;
      case 'holding_company_1':
      case 'holding_company_2':
      case 'holding_company_3':
      case 'holding_company_4':
      case 'holding_company_5':
        return currentGroupCompanies;
      case 'currency_id':
        return currencies;
      default:
        return [];
    }
  };

  /** 最大カラム数 */
  const maxColumnOrders = Math.max(...CREATE_GROUP_COMPANY_FIELDS.map((field) => field.column));

  const onGroupCompaniesSearch = async (field: string) => {
    const value = (document.getElementById(field) as HTMLInputElement).value;

    if (!value) return;

    const filter = generateSearchFilter([{ name: 'group_company_code', value, type: SEARCH_TYPE.WILDCARD }]);
    try {
      const models: any = await API.graphql({
        query: searchGroupCompanies,
        variables: { ...filter, sort: { direction: 'desc', field: 'seq_id' }, limit: 500 },
      });
      const groupCompanies = [...models.data.searchGroupCompanies.items] as GroupCompany[];
      let uniqueGroupCompanyCode = [];
      const uniqueGroupCompanies = groupCompanies.reduce((current, groupCompany) => {
        if (!uniqueGroupCompanyCode.includes(groupCompany.group_company_code)) {
          uniqueGroupCompanyCode.push(groupCompany.group_company_code);
          return [...current, groupCompany];
        }
        return current;
      }, []);
      setCurrentGroupCompanies([...uniqueGroupCompanies]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FormDialog
      open
      onCancel={onClose}
      title="グループ会社マスタの新規作成"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onConfirmSubmit)}
      height={650}
      width="xl"
    >
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => {
          return (
            <Box
              sx={{
                '& > :not(style)': { my: 1, mx: 1, width: 300 },
                display: 'flex',
                flexFlow: 'column wrap',
                height: 'inherit',
              }}
              width={330}
              key={i}
            >
              {CREATE_GROUP_COMPANY_FIELDS.map((row) => {
                return (
                  row.column === i + 1 && (
                    <React.Fragment key={row.label}>
                      {row.type === 'text' && (
                        <FormTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          errors={errors}
                          validationRules={classificationValidateGroupCompany(row.field)}
                          required={row?.required}
                        />
                      )}
                      {row.type === 'arrayText' && (
                        <FormArrayTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          errors={errors}
                          validationRules={classificationValidateGroupCompany(row.field)}
                        />
                      )}
                      {row.type === 'select' && (
                        <FormSelectBox
                          label={row.label}
                          content={options(row.field)}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                        />
                      )}
                      {row.type === 'checkbox' && (
                        <FormCheckBox field={row.field} label={row.label} control={control} />
                      )}
                      {(row.type === 'datetime' || row.type === 'date') && (
                        <FormDateTimePicker
                          label={row.label}
                          type={row.type}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                          required={row.required}
                          errors={errors}
                          validationRules={classificationValidateGroupCompany(row.field)}
                        />
                      )}
                      {row.type === 'autoComplete' && (
                        //  && row.optionsName === "name_ja"
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            '& > div': { width: '70%' },
                            '& > button': { marginTop: '10px' },
                          }}
                        >
                          <FormAutocomplete
                            id={row.field}
                            label={row.label}
                            placeholder={row.placeholder}
                            field={row.field}
                            control={control}
                            errors={errors}
                            validationRules={[]}
                            required={row.required}
                            options={options(row.field)}
                            freeSolo
                            includeSearchEnglish
                            isGroupCompany
                          />
                          <PrimaryButton onClick={() => onGroupCompaniesSearch(row.field)}>検索</PrimaryButton>
                        </Box>
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          );
        })}
      </Box>
      <EditConfirmDialog
        fields={CREATE_GROUP_COMPANY_FIELDS}
        onClose={() => setIsOpenEditConfirm(false)}
        onSubmit={onSubmit}
        open={isOpenEditConfirm}
        record={confirmData}
        schemaName="グループ会社マスタ"
        currencies={currencies}
        countries={countries}
        groupCompanies={groupCompanies}
      />
    </FormDialog>
  );
}

export default NewGroupCompanyDialog;
