export const CREATE_TAG_FIELDS = [
  {
    field: 'name_ja',
    label: 'tag_ip-tag-japanese',
    type: 'text',
    placeholder: 'IPタグ(日本語)を入力',
    required: true,
  },
  {
    field: 'name_en',
    label: 'tag_ip-tag-english',
    type: 'text',
    placeholder: 'IPタグ(英語)を入力',
  },
];

export const EDIT_TAG_FIELDS = [
  {
    field: 'seq_id',
    label: 'tag_tag-id',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'name_ja',
    label: 'tag_ip-tag-japanese',
    type: 'text',
    placeholder: 'IPタグ(日本語)を入力',
    required: true,
  },
  {
    field: 'name_en',
    label: 'tag_ip-tag-english',
    type: 'text',
    placeholder: 'IPタグ(英語)を入力',
  },
  {
    field: 'updated_user_id',
    label: 'tag_final-updater',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'updatedAt',
    label: 'tag_final-update-date',
    type: 'datetime',
    placeholder: '',
    disabled: true,
  },
];

export const SEARCH_TAG_FIELDS = [
  {
    field: 'seq_id',
    label: 'tag_tag-id',
    type: 'text',
    placeholder: 'タグIDを入力',
  },
  {
    field: 'name_ja',
    label: 'tag_ip-tag-japanese',
    type: 'text',
    placeholder: 'IPタグ(日本語)を入力',
  },
  {
    field: 'name_en',
    label: 'tag_ip-tag-english',
    type: 'text',
    placeholder: 'IPタグ(英語)を入力',
  },
  {
    field: 'updated_user_id',
    label: 'tag_final-updater',
    type: 'text',
    placeholder: '最終更新者を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'tag_final-update-date' },
      { field: 'to', label: 'tag_final-update-date' },
    ],
  }
];

export const SEARCH_TAG_ADMIN_FIELDS = [
  {
    field: 'seq_id',
    label: 'tag-history_tag-id',
    type: 'text',
    placeholder: 'タグIDを入力',
  },
  {
    field: 'name_ja',
    label: 'tag-history_ip-tag-japanese',
    type: 'text',
    placeholder: 'IPタグ(日本語)を入力',
  },
  {
    field: 'name_en',
    label: 'tag-history_ip-tag-english',
    type: 'text',
    placeholder: 'IPタグ(英語)を入力',
  },
  {
    field: 'updated_user_id',
    label: 'tag-history_final-updater',
    type: 'text',
    placeholder: '最終更新者を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'tag-history_final-update-date' },
      { field: 'to', label: 'tag-history_final-update-date' },
    ],
  },
  {
    field: 'delete_flg',
    label: 'tag_deleted-data',
    type: 'select',
    optionsName: 'delete_flg',
    isBlank: false,
  },
];

export const SEARCH_TAG_FIELDS_DEFAULT_VALUES = {
  seq_id: '',
  name_ja: '',
  name_en: '',
  updated_user_id: '',
  from: null,
  to: null,
  delete_flg: false,
};

export const VALIDATE_DUPLICATE_TAG_FIELDS = ['name_ja', 'name_en'];
