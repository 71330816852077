import { useState } from 'react';
import { searchBnams } from '../graphql/queries';
import usePageSize from './usePageSize';
import { Bnam } from '../models';
import { BnamSearch } from '../types/form/bnam';
import { SEARCH_BNAM_FIELDS_DEFAULT_VALUES } from '../consts/bnam';
import { dataToFilterBnamObj } from '../utils/fetchData';
import { generateSearchFilter } from '../utils/search';
import { generateClient } from 'aws-amplify/api';
import { SearchableBnamSortInput } from '../API';
const API = generateClient();

const defaultSort = { direction: 'asc', field: 'seq_id' };

export const useSearchBnams = () => {
  const [bnams, setBnams] = useState<Bnam[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<BnamSearch>(SEARCH_BNAM_FIELDS_DEFAULT_VALUES);
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();
  const [searchFilter, setSearchFilter] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >();

  const fetchBnams = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
    setExpQueries
  }: {
    newCondition?: BnamSearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
    setExpQueries?: Function;
  }) => {
    try {
      setLoading(true);
      const records = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          console.log([key, value], [key, value], [key, value]);
          if (value === 'ALL' && key === 'delete_flg') return;
          if (value === 'ALL' && key === 'admin_check_flg') return;
          if (value === 'ALL' && key === 'system_update_flg') return;
          return {
            name: dataToFilterBnamObj[key].name,
            value: value,
            type: dataToFilterBnamObj[key].type
          };
        })
        .filter(Boolean);
      setSearchFilter(records);
      setExpQueries && setExpQueries(records);
      const filter = generateSearchFilter(records);
      const models: any = await API.graphql({
        query: searchBnams,
        variables: {
          ...filter,
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });

      setTotal(models.data.searchBnams.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchBnams.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchBnams.nextToken])));
      }

      setBnams([...models.data.searchBnams.items]);
      newCondition && setCondition({ ...newCondition });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchBnams({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchBnams({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchBnams({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchBnams({ nextToken });
  };

  return {
    fetchBnams,
    loading,
    total,
    pageSize,
    onChangePageSize,
    bnams,
    setBnams,
    onChangePage,
    condition,
    page,
    onChangeSort,
    searchFilter
  };
};
