import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import PrimaryButton from '../PrimaryButton';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormAutocomplete from '../forms/FormAutocomplete';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import NewTagDialog from '../tags/NewTagDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { EDIT_TAG_BNAM_FIELDS } from '../../consts/tagBnam';
import { tagBnamFields } from '../../types';
import { classificationValidateTagBnam } from '../../hooks/classification';
import { generateSearchFilter, SEARCH_TYPE } from '../../utils/search';
import { fetchTagBnams, sleep } from '../../utils/fetchData';
import { TagBnamSearch } from '../../types/form/tagBnamSearch';
import { useSearchTags } from '../../hooks/useSearchTags';
import { searchTagsMinimumRelations } from '../../graphql/custom-queries';
import {
  createTagBnamWithoutOtherRelations,
  deleteTagBnamWithoutOtherRelations,
  updateTagBnamWithoutOtherRelations,
} from '../../graphql/custom-mutations';

import type { User } from '../../models';
import type { TagBnam, Tag, Bnam } from '../../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  onClose: Function;
  addedTag: Tag | null;
  open: boolean;
  tags: Tag[];
  bnam: Bnam;
  tagBnam: TagBnam;
  user: User;
  setBnams: Function;
  condition: TagBnamSearch;
  setCondition: Function;
  setBnamIds: Function;
  setExpQueries: Function;
};

type Record = {
  title_name_ja?: string;
  tag?: Tag;
  tag_id?: string;
  update_reason?: string;
};

type StringKeyObj = {
  [key: string]: string | boolean | undefined | Tag;
};

function EditTagBnamDialog(props: Props) {
  const {
    addedTag,
    onClose,
    open,
    tags,
    user,
    setBnams,
    condition,
    setCondition,
    bnam,
    tagBnam,
    setBnamIds,
    setExpQueries,
  } = props;
  const [currentTags, setCurrentTags] = useState<Tag[]>(tags);
  const [confirm, setConfirm] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});

  const { fetchTags } = useSearchTags();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    addedTag && setValue('tag', addedTag);
  }, [addedTag, setValue]);

  const doClose = () => {
    onClose();
    reset();
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<any> = async (data) => {
    // 確認画面で表示されるようにdataを変換して入れる
    data.tag_id = data.tag.id;
    data.tag_name_ja = data.tag.name_ja;
    data.tag_name_en = data.tag.name_en;
    setRecord(data);
    setEditConfirm(true);
  };

  const onUpdate = async () => {
    if (tagBnam) {
      // memo: 既存データにtagBnamがあれば編集
      try {
        const formData = {
          id: tagBnam.id,
          tag_id: record.tag_id,
          _version: tagBnam._version,
          updated_user_id: user.id,
          update_reason: record.update_reason,
        };
        await API.graphql({ query: updateTagBnamWithoutOtherRelations, variables: { input: formData } });
        doClose();
        addNotification({
          type: 'success',
          message: '紐付けの編集に成功しました',
        });
        await sleep(3000); //OpenSearchがアップデートされるまでスリープ
        await fetchTagBnams(condition, setBnams, setCondition, onClose, setBnamIds, setExpQueries);
      } catch (e) {
        addNotification({
          type: 'error',
          message: '紐付けの編集に失敗しました',
        });
        console.log(e);
      }
    } else {
      // memo: なければ新規作成
      try {
        const formData = {
          bnam_id: bnam.id,
          tag_id: record.tag.id,
          updated_user_id: user.id,
          update_reason: record.update_reason,
        };
        await API.graphql({ query: createTagBnamWithoutOtherRelations, variables: { input: formData } });
        setEditConfirm(false);
        doClose();
        addNotification({
          type: 'success',
          message: '紐付けの編集に成功しました',
        });
        await sleep(3000); //OpenSearchがアップデートされるまでスリープ
        await fetchTagBnams(condition, setBnams, setCondition, onClose, setBnamIds, setExpQueries);
        reset();
      } catch (e) {
        addNotification({
          type: 'error',
          message: '紐付けの編集に失敗しました',
        });
        console.log(e);
      }
    }
  };

  const onDelete = async () => {
    try {
      const formData = { id: tagBnam.id, _version: tagBnam._version };
      await API.graphql({ query: deleteTagBnamWithoutOtherRelations, variables: { input: formData } });
      setConfirm(false);
      doClose();
      addNotification({
        type: 'success',
        message: '紐付けの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTagBnams(condition, setBnams, setCondition, onClose, setBnamIds, setExpQueries);
    } catch (e) {
      addNotification({
        type: 'error',
        message: '紐付けの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const onTagSeatch = async () => {
    // react-hook-formのwatchなどではautoComplateのフリーテキストを取得できないのでgetElementByIdで実装
    const value = (document.getElementById('tag') as HTMLInputElement).value;
    if (!value) return;

    const jaFilter = generateSearchFilter([{ name: 'name_ja', value, type: SEARCH_TYPE.WILDCARD }]);
    const enFilter = generateSearchFilter([{ name: 'name_en', value, type: SEARCH_TYPE.WILDCARD }]);
    try {
      const jaModels: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: { ...jaFilter, sort: { direction: 'desc', field: 'seq_id' }, limit: 500 },
      });
      const enModels: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: { ...enFilter, sort: { direction: 'desc', field: 'seq_id' }, limit: 500 },
      });
      setCurrentTags([...jaModels.data.searchTags.items, ...enModels.data.searchTags.items]);
    } catch (e) {
      console.log(e);
    }
  };

  const onConfirmCancel = () => {
    setConfirm(false);
  };

  const onDoConfirm = () => {
    setConfirm(true);
  };

  const onAddTagOpen = useCallback(() => {
    setOpenTag(true);
  }, []);

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'tag':
        return currentTags;
      default:
        return [];
    }
  };

  const field2value: StringKeyObj = {
    seq_id: bnam.seq_id,
    ip_name: bnam.ip_name,
    tag: tagBnam?.tag,
    updated_user_id: tagBnam?.updated_user_id,
    updatedAt: tagBnam?.updatedAt,
    update_reason: tagBnam?.update_reason,
  };

  const oldValue: StringKeyObj = {
    seq_id: bnam.seq_id,
    ip_name: bnam.ip_name,
    tag: tagBnam?.tag,
    updated_user_id: tagBnam?.updated_user_id,
    updatedAt: tagBnam?.updatedAt,
    update_reason: tagBnam?.update_reason,
  };

  if (!bnam) return <></>;

  return (
    <FormDialog
      open={open}
      onCancel={doClose}
      title="BNAMマスター共有タグ付け編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      onConfirm={tagBnam && onDoConfirm}
      height={500}
      width="md"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 350 },
        }}
        width={380}
      >
        {EDIT_TAG_BNAM_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                disabled={row.disabled}
                errors={errors}
                validationRules={classificationValidateTagBnam(row.field as tagBnamFields)}
                value={field2value[row.field]?.toString() || ''}
                required={row.required}
              />
            )}
            {row.type === 'datetime' && (
              <FormDateTimePicker
                label={row.label}
                defaultValue={field2value[row.field]?.toString()}
                type={row.type}
                disabled={row.disabled}
                field={row.field}
                control={control}
              />
            )}
            {row.type === 'autoComplete' && row.optionsName === 'name_ja' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& > div': { width: '70%' },
                  '& > button': { marginTop: '10px' },
                }}
              >
                <FormAutocomplete
                  id={row.field}
                  label={row.label}
                  placeholder={row.placeholder}
                  field={row.field}
                  control={control}
                  disabled={row.disabled}
                  errors={errors}
                  validationRules={classificationValidateTagBnam(row.field as tagBnamFields)}
                  defaultValue={field2value[row.field]}
                  required={row.required}
                  options={options(row.field)}
                  freeSolo
                  includeSearchEnglish
                />
                <PrimaryButton onClick={onTagSeatch}>タグ検索</PrimaryButton>
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
      <Box sx={{ position: 'absolute', bottom: '10px', left: '25px' }} width={220}>
        <PrimaryButton width="auto" onClick={onAddTagOpen}>
          タグの新規作成
        </PrimaryButton>
      </Box>
      {confirm && (
        <ConfirmDialog
          message={`このリレーションのタグ付けを${'\n'}解除してよろしいでしょうか？`}
          open={confirm}
          onCancel={onConfirmCancel}
          onContinue={onDelete}
        />
      )}
      {openTag && user && (
        <NewTagDialog open={openTag} setOpen={setOpenTag} user={user} isTag={false} fetchTags={fetchTags} />
      )}
      {editConfirm && (
        <EditConfirmDialog
          fields={EDIT_TAG_BNAM_FIELDS}
          onClose={onEditConfirmClose}
          onSubmit={() => onUpdate()}
          open={editConfirm}
          record={record}
          schemaName="BNAMマスター共有タグ付け"
          beforeChangeValueData={oldValue}
        />
      )}
    </FormDialog>
  );
}

export default EditTagBnamDialog;
