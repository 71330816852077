import { useState } from 'react';
import { searchCsvJobs } from '../graphql/queries';
import usePageSize from './usePageSize';
import { CsvJob } from '../models';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = [{ direction: 'desc', field: 'createdAt' }];

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

const dataToFilterObj: StringKeyObj = {
  order_id: { name: 'order_id', type: SEARCH_TYPE.WILDCARD },
  name: { name: 'name', type: SEARCH_TYPE.WILDCARD },
  name_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  country_code: { name: 'country_code', type: SEARCH_TYPE.WILDCARD },
  code_two_char: { name: 'code_two_char', type: SEARCH_TYPE.WILDCARD },
  code_three_char: { name: 'code_three_char', type: SEARCH_TYPE.WILDCARD },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
};

export const useSearchCsvJobs = () => {
  const [csvJobs, setCsvJobs] = useState<CsvJob[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();

  const fetchCsvJobs = async ({
    nextToken,
    newLimit,
    newSort,
  }: {
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const filter = generateSearchFilter([
        {
          name: 'table_name',
          value: ['RATE', 'CALENDAR'],
          type: SEARCH_TYPE.OR_EQ,
        },
        {
          name: 'type',
          value: 'IMPORT',
          type: SEARCH_TYPE.EQ,
        },
      ]);
      const models: any = await API.graphql({
        query: searchCsvJobs,
        variables: {
          ...filter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });

      setTotal(models.data.searchCsvJobs.total);
      if (newLimit || newSort) {
        setNextTokens([models.data.searchCsvJobs.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchCsvJobs.nextToken])));
      }
      setCsvJobs([...models.data.searchCsvJobs.items]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchCsvJobs({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchCsvJobs({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchCsvJobs({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchCsvJobs({ nextToken });
  };

  return {
    fetchCsvJobs,
    loading,
    total,
    pageSize,
    onChangePageSize,
    csvJobs,
    setCsvJobs,
    onChangePage,
    page,
    onChangeSort,
  };
};
