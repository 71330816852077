export const CREATE_GENRE_FIELDS = [
  {
    field: "name",
    label: "ジャンル名称",
    type: "text",
    placeholder: "ジャンル名称を入力",
    required: true,
  },
  {
    field: "name_ja",
    label: "ジャンル名称(日本語)",
    type: "text",
    placeholder: "ジャンル名称(日本語)を入力",
    required: false,
  },
  {
    field: "ce_link_id",
    label: "CE-LINKジャンルID",
    type: "text",
    placeholder: "CE-LINKジャンルIDを入力",
    required: false,
  },
];

export const EDIT_GENRE_FIELDS = [
  {
    field: "seq_id",
    label: "ID",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "name",
    label: "ジャンル名称",
    type: "text",
    placeholder: "ジャンル名称を入力",
    required: true,
  },
  {
    field: "name_ja",
    label: "ジャンル名称(日本語)",
    type: "text",
    placeholder: "ジャンル名称(日本語)を入力",
    required: false,
  },
  {
    field: "ce_link_id",
    label: "CE-LINKジャンルID",
    type: "text",
    placeholder: "CE-LINKジャンルIDを入力",
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "最終更新日時",
    type: "datetime",
    placeholder: "",
    disabled: true,
  },
];

export const VALIDATE_DUPLICATE_GENRE_FIELDS = ["name", "ce_link_id"];
