import React, { useCallback, useContext, useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/material';
import { theme } from '../assets/theme/options';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import EditTagBnmlDialog from '../components/tagBnml/EditTagBnmlDialog';
import SearchTagBnmlDialog from '../components/tagBnml/SearchTagBnmlDialog';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { searchBnmls } from '../graphql/queries';
import { onCreateBnml, onUpdateBnml } from '../graphql/subscriptions';
import { AuthContext } from '../contexts/AuthContext';
import { SEARCH_TAG_BNML_FIELDS_DEFAULT_VALUES } from '../consts/tagBnml';
import { INITIAL_EXP_ONLY_DEL_QUERIES_STATE } from '../consts/export';
import { TagBnmlSearch } from '../types/form/tagBnml';
import usePageSize from '../hooks/usePageSize';
import { onCreateTagWithoutRelations } from '../graphql/custom-subscriptions';
import { searchTagsMinimumRelations } from '../graphql/custom-queries';

import type { Bnml, SearchableBnmlSortInput, SearchableTagSortInput, Tag, TagBnml } from '../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const columns: GridColDef[] = [
  { field: 'seq_id', headerName: 'BNMLマスターID', width: 250 },
  {
    field: 'mlics_plan_cd_4char',
    headerName: 'MLICS企画コード(4桁)',
    width: 260
  },
  {
    field: 'mlics_plan_cd_7char',
    headerName: 'MLICS企画コード(7桁)',
    width: 260
  },
  {
    field: 'mlics_commodity_name',
    headerName: 'MLICS商品名',
    width: 260
  },
  {
    field: 'tags_ja',
    headerName: 'IPタグ（日本語）',
    width: 250,
    valueGetter: ({ row }) => {
      const tagBnml = row.tags.items.find((tagBnml: TagBnml) => !tagBnml._deleted);
      return tagBnml?.tag?.name_ja;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <Box sx={{ bgcolor: grey[800], height: 30, lineHeight: '30px', px: 1, color: 'white' }}>未登録</Box>;
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (!v1) return 1;
      if (!v2) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'tags_en',
    headerName: 'IPタグ（英語）',
    width: 250,
    valueGetter: ({ row }) => {
      const tagBnml = row.tags.items.find((tagBnml: TagBnml) => !tagBnml._deleted);
      return tagBnml?.tag?.name_en;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <Box sx={{ bgcolor: grey[800], height: 30, lineHeight: '30px', px: 1, color: 'white' }}>未登録</Box>;
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (!v1) return 1;
      if (!v2) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'updatedAt',
    headerName: 'BNMLマスター最終更新日時',
    width: 250,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

function TagBnmlList() {
  const [bnmls, setBnmls] = useState<Bnml[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [bnml, setBnml] = useState<Bnml | null>(null);
  const [tagBnml, setTagBnml] = useState<TagBnml | null>(null);
  const [addedTag, setAddedTag] = useState<Tag | null>(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<TagBnmlSearch>(SEARCH_TAG_BNML_FIELDS_DEFAULT_VALUES);
  const [expQueries, setExpQueries] = useState(INITIAL_EXP_ONLY_DEL_QUERIES_STATE);
  const [isUploaded, setIsUploaded] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchBnml();
    fetchTags();
    subscribeOnCreateBnml();
    subscribeOnUpdateBnml();
    subscribeOnCreateTag();
  }, []);

  useEffect(() => {
    if (!isUploaded) return;
    fetchBnml();
    setIsUploaded(false);
  }, [isUploaded]);

  const fetchBnml = async () => {
    try {
      const models: any = await API.graphql({
        query: searchBnmls,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 500
        }
      });
      setBnmls(models.data.searchBnmls.items);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTags = async () => {
    try {
      const models: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 500
        }
      });
      setTags(models.data.searchTags.items);
    } catch (e) {
      console.log(e);
    }
  };

  const subscribeOnCreateBnml = () => {
    const client = API.graphql({ query: onCreateBnml });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const bnml = data.onCreateBnml;
          setBnmls((prev) => [bnml, ...prev]);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnUpdateBnml = () => {
    const client = API.graphql({ query: onUpdateBnml });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const bnml = data.onUpdateBnml;
          if (!bnml.delete_flg) {
            // 更新
            setBnmls((bnmls) => bnmls.map((__bnml) => (__bnml.id === bnml.id ? bnml : __bnml)));
          } else {
            // 削除
            setBnmls((bnmls) => bnmls.filter((__bnml) => __bnml.id !== bnml.id));
          }
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnCreateTag = () => {
    const client = API.graphql({ query: onCreateTagWithoutRelations });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const tag = data.onCreateTag;
          setTags((tags) => [tag, ...tags]);
          setAddedTag(tag);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const doOpenEdit = (params: any) => {
    setBnml(params.row);
    const __tagBnml = params.row.tags.items.find((tagBnml: any) => !tagBnml._deleted);
    __tagBnml && setTagBnml(__tagBnml);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const onChangePageSize = (num: number) => {
    setPageSize(num);
  };

  const onCloseEdit = () => {
    setTagBnml(null);
    setBnml(null);
    setAddedTag(null);
    setOpenEdit(false);
  };

  console.log({ bnmls });
  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
          <ExportButton condition={expQueries} tableName="TAG_BNML" type="SEARCH" />
          <ImportButton tableName="TAGBNML" setIsUploaded={setIsUploaded} />
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          rows={bnmls}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onPageSizeChange={(num) => onChangePageSize(num)}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            }
          }}
        />
      </div>
      {bnml && openEdit && user && (
        <EditTagBnmlDialog
          addedTag={addedTag}
          onClose={onCloseEdit}
          open={openEdit}
          tags={tags}
          tagBnml={tagBnml}
          bnml={bnml}
          user={user}
          setBnmls={setBnmls}
          condition={condition}
          setCondition={setCondition}
          setExpQueries={setExpQueries}
        />
      )}
      {openSearch && (
        <SearchTagBnmlDialog
          condition={condition}
          open={openSearch}
          setCondition={setCondition}
          setExpQueries={setExpQueries}
          setOpen={setOpenSearch}
          setBnmls={setBnmls}
        />
      )}
    </Box>
  );
}

export default TagBnmlList;
