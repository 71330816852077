import { useState } from 'react';
import { searchTagTitleCodeHistories } from '../graphql/queries';
import { RelationTitleCodeHistorySearch } from '../types/form';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import usePageSize from './usePageSize';
import { TagTitleCodeHistory } from '../API';
import { SEARCH_TAG_TITLE_CODE_HISTORY_FIELDS_DEFAULT_VALUES } from '../consts/tagTitleCodeHistory';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = { direction: 'desc', field: 'updatedAt' };

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

const dataToFilterObj: StringKeyObj = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.EQ },
  title_code_name: { name: 'title_code_name', type: SEARCH_TYPE.WILDCARD },
  tag_name: { name: 'tag_name', type: SEARCH_TYPE.WILDCARD },
  tag_name_en: { name: 'tag_name_en', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
};

export const useSearchTagTitleCodeHistories = () => {
  const [tagTitleCodeHistories, setTagTitleCodeHistories] = useState<TagTitleCodeHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<RelationTitleCodeHistorySearch>(
    SEARCH_TAG_TITLE_CODE_HISTORY_FIELDS_DEFAULT_VALUES
  );

  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();

  const fetchTagTitleCodeHistories = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: RelationTitleCodeHistorySearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const records = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          if (value === 'ALL' && key === 'delete_flg') return;
          return {
            name: dataToFilterObj[key].name,
            value: value,
            type: dataToFilterObj[key].type,
          };
        })
        .filter(Boolean);
      console.log(records);
      const filter = generateSearchFilter(records);
      const models: any = await API.graphql({
        query: searchTagTitleCodeHistories,
        variables: {
          ...filter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });

      setTotal(models.data.searchTagTitleCodeHistories.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchTagTitleCodeHistories.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchTagTitleCodeHistories.nextToken])));
      }

      setTagTitleCodeHistories([...models.data.searchTagTitleCodeHistories.items]);
      newCondition && setCondition({ ...newCondition });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchTagTitleCodeHistories({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchTagTitleCodeHistories({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchTagTitleCodeHistories({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchTagTitleCodeHistories({ nextToken });
  };

  return {
    fetchTagTitleCodeHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    tagTitleCodeHistories,
    setTagTitleCodeHistories,
    onChangePage,
    condition,
    setCondition,
    page,
    onChangeSort,
  };
};
