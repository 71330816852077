import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import { useSearchCsvJobs } from '../hooks/useSearchCsvImports';
import type { CsvJob } from '../API';
import { convertCsvJobStatus, convertCsvTableName } from '../utils/converter';
import DetailImportDialog from '../components/import/DetailImportDialog';

const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'インポート日時',
    width: 190,
    valueFormatter: (params: GridValueFormatterParams) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
  {
    field: 'updated_by',
    headerName: 'ユーザー',
    width: 300,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    },
  },
  { field: 'table_name', headerName: 'マスタ名', width: 200, valueFormatter: (params: GridValueFormatterParams) => convertCsvTableName[params.value] },
  { field: 'name', headerName: 'ファイル名', width: 380 },
  {
    field: 'status', headerName: 'ステータス', width: 160, valueFormatter: (params: GridValueFormatterParams) => {
      return convertCsvJobStatus[params.value];
    },
  },
  {
    field: 'message',
    headerName: 'メッセージ',
    width: 380,
  },
  {
    field: 'error_log',
    headerName: 'エラーログ',
    width: 380,
  },
];

function ImportAdminList() {
  const [open, setOpen] = useState(false)
  const [row, setRow] = useState<CsvJob | null>(null);

  useEffect(() => {
    fetchCsvJobs({});
    // eslint-disable-next-line
  }, []);

  const { fetchCsvJobs, loading, total, pageSize, onChangePageSize, csvJobs, setCsvJobs, onChangePage, page, onChangeSort } = useSearchCsvJobs()

  const doOpenDetail = (params: any) => {
    setRow(params.row);
    setOpen(true);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
      </Box>
      <div style={{ height: 'calc(100vh - 130px)', width: '100%' }}>
        <DataGridPro
          rows={csvJobs}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onRowClick={doOpenDetail}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          }}

          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {open && row && (
        <DetailImportDialog
          open={open}
          row={row}
          setOpen={setOpen}
          setRow={setRow}
          dialogTitle='管理者CSVインポート状況の詳細'
        />
      )}
    </Box>
  );
}

export default ImportAdminList;
