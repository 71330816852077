import React, { useRef, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import { BusinessEntity, User } from '../../models';
import { EDIT_BUSINESS_ENTITY_FIELDS, VALIDATE_DUPLICATE_BUSINESS_ENTITY_FIELDS } from '../../consts/businessEntity';
import { businessEntityFields } from '../../types';
import { classificationValidateBusinessEntity } from '../../hooks/classification';
import { getBusinessEntity, searchBusinessEntities } from '../../graphql/queries';
import { updateBusinessEntity } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { sleep } from '../../utils/fetchData';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  showDeleted: boolean;
  row: BusinessEntity;
  setOpen: Function;
  fetchBusinessEntities: Function;
  setRow: Function;
  user: User;
};

type Record = {
  name?: string;
  description?: string;
};

function EditBusinessEntityDialog(props: Props) {
  const { open, row, setOpen, setRow, user, fetchBusinessEntities, showDeleted } = props;
  const [confirm, setConfirm] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isRel, setIsRel] = useState(false);
  const onUpdating = useRef(false); // 連打防止用フラグ
  const { checkAndRestore } = useRestore();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const { addNotification } = useContext(NotificationContext);

  const onSubmit: SubmitHandler<any> = async (data) => {
    // 連打回避
    if (onUpdating.current) return;
    onUpdating.current = true;
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection(
      'BUSINESS_ENTITY',
      VALIDATE_DUPLICATE_BUSINESS_ENTITY_FIELDS,
      data,
      clearErrors,
      setError,
      row.id
    );
    // 重複なければ登録
    results.every((result) => !result) && onUpdate(data);
    // 連打回避解除
    if (onUpdating.current) {
      setTimeout(() => {
        onUpdating.current = false;
      }, 2000);
    }
  };

  const onUpdate = async (record: Record) => {
    try {
      const model: any = await API.graphql({ query: getBusinessEntity, variables: { id: row.id } });
      const businessEntity = (model.data as any).getBusinessEntity;
      const formData = {
        id: row.id,
        name: record.name,
        description: record.description,
        _version: businessEntity._version,
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateBusinessEntity, variables: { input: formData } });
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: '事業体マスタの編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchBusinessEntities({ isShowDeleteFlg: showDeleted });
    } catch (e) {
      addNotification({
        type: 'error',
        message: '事業体マスタの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete = async () => {
    try {
      const model: any = await API.graphql({ query: getBusinessEntity, variables: { id: row.id } });
      const businessEntity = (model.data as any).getBusinessEntity;
      const formData = {
        id: row.id,
        _version: businessEntity._version,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateBusinessEntity, variables: { input: formData } });
      closeConfirm();
      onClose();
      addNotification({
        type: 'success',
        message: '事業体マスタの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchBusinessEntities({ isShowDeleteFlg: showDeleted });
    } catch (e) {
      addNotification({
        type: 'error',
        message: '事業体マスタの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const restoreConfirm = () => {
    setAlertMsg(`この事業体マスタを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('businessEntity', row);
      onClose();
      addNotification({
        type: 'success',
        message: '事業体マスタの復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchBusinessEntities({ isShowDeleteFlg: showDeleted });
    } catch (e) {
      addNotification({
        type: 'error',
        message: '事業体マスタの復元に失敗しました',
      });
      console.log(e);
    }
  };

  const onDoConfirm = () => {
    setAlertMsg(`この事業体マスタを削除しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="事業体マスタの編集"
      doText="変更"
      cancelText="キャンセル"
      onSubmit={!row.delete_flg && handleSubmit(onSubmit)}
      onConfirm={row.delete_flg ? restoreConfirm : onDoConfirm}
      height={480}
      width="xs"
      isRestore={row.delete_flg}
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 300 },
        }}
        width={340}
      >
        {EDIT_BUSINESS_ENTITY_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {__row.type === 'text' && (
              <FormTextField
                label={__row.label}
                placeholder={__row.placeholder}
                field={__row.field}
                control={control}
                disabled={row.delete_flg || __row.disabled}
                errors={errors}
                validationRules={classificationValidateBusinessEntity(__row.field as businessEntityFields)}
                value={
                  row[__row.field as keyof Pick<BusinessEntity, 'seq_id' | 'name' | 'description' | 'updated_user_id'>]
                }
                required={row.delete_flg ? false : __row.required}
              />
            )}
            {__row.type === 'datetime' && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<BusinessEntity, 'updatedAt'>]}
                type={__row.type}
                disabled={row.delete_flg || __row.disabled}
                field={__row.field}
                control={control}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      {confirm && (
        <ConfirmDialog
          message={alertMsg}
          open={confirm}
          onCancel={isRel ? undefined : closeConfirm}
          onContinue={isRel ? closeConfirm : row.delete_flg ? onRestore : onDelete}
          type={isRel ? 'error' : 'warning'}
        />
      )}
    </FormDialog>
  );
}

export default EditBusinessEntityDialog;
