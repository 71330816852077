/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://6ms2rvl35fep3is65zpcgeknoa.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_XTWqkw0jF",
    "aws_user_pools_web_client_id": "13gler43fr3svbunthj2neku07",
    "oauth": {
        "domain": "bnemaster-skrit.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://bandainamco-master-mgmt.bandainamco.co.jp/",
        "redirectSignOut": "https://bandainamco-master-mgmt.bandainamco.co.jp/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "bnemaster-20220310044007-hostingbucket-skrit",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://bnemaster-20220310044007-hostingbucket-skrit.s3-website-ap-northeast-1.amazonaws.com",
    "aws_user_files_s3_bucket": "bnemaster-filestore174156-skrit",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
