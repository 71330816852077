export const DETAIL_BNML_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "select",
    optionsName: "operation_type",
    placeholder: "操作種別を選択",
    required: false,
    disabled: true,
  },
  {
    field: "seq_id",
    label: "BNMLマスターID",
    type: "text",
    placeholder: "BNMLマスターIDを入力",
    required: false,
    disabled: true,
  },
  {
    field: "mlics_plan_cd_4char",
    label: "MLICS企画コード(4桁)",
    type: "text",
    placeholder: "MLICS企画コード(4桁)を入力",
    required: false,
    disabled: true,
  },
  {
    field: "mlics_plan_cd_7char",
    label: "MLICS企画コード(7桁)",
    type: "text",
    placeholder: "MLICS企画コード(7桁)を入力",
    required: false,
    disabled: true,
  },
  {
    field: "mlics_commodity_name",
    label: "MLICS商品名",
    type: "text",
    placeholder: "MLICS商品名を入力",
    required: false,
    disabled: true,
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "最終更新者を入力",
    required: false,
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "最終更新日時",
    type: "datetime",
    required: false,
    disabled: true,
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "textarea",
    placeholder: "更新理由を入力",
    required: false,
    disabled: true,
  },
  {
    field: "admin_check_flg",
    label: "管理者チェックフラグ",
    type: "checkbox",
    required: false,
    disabled: true,
  },
  {
    field: "delete_flg",
    label: "削除済データ",
    type: "checkbox",
    required: false,
    disabled: true,
  },
];

export const SEARCH_BNML_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "select",
    placeholder: "操作種別を選択",
    optionsName: "operation_type",
  },
  {
    field: "seq_id",
    label: "BNMLマスターID",
    type: "text",
    placeholder: "BNMLマスターIDを入力",
  },
  {
    field: "mlics_plan_cd_4char",
    label: "MLICS企画コード(4桁)",
    type: "text",
    placeholder: "MLICS企画コード(4桁)を入力",
  },
  {
    field: "mlics_plan_cd_7char",
    label: "MLICS企画コード(7桁)",
    type: "text",
    placeholder: "MLICS企画コード(7桁)を入力",
  },
  {
    field: "mlics_commodity_name",
    label: "MLICS商品名",
    type: "text",
    placeholder: "MLICS商品名を入力",
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "最終更新日時" },
      { field: "to", label: "最終更新日時" },
    ],
  },
  {
    field: "update_reason",
    label: "更新理由",
    type: "textarea",
    placeholder: "更新理由を入力",
  },
  {
    field: "admin_check_flg",
    label: "管理者チェックフラグ",
    type: "select",
    optionsName: "admin_check_flg",
  },
  {
    field: "delete_flg",
    label: "削除済データ",
    type: "select",
    optionsName: "delete_flg",
  },
];

export const SEARCH_BNML_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  seq_id: "",
  mlics_plan_cd_4char: "",
  mlics_plan_cd_7char: "",
  mlics_commodity_name: "",
  updated_user_id: "",
  updatedAt_from: null,
  updatedAt_to: null,
  update_reason: "",
  admin_check_flg: "ALL",
  delete_flg: "ALL",
};
