import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextField from '../forms/FormTextField';
import FormDialog from '../FormDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { createBsp } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { CREATE_BSP_FIELDS, VALIDATE_DUPLICATE_BSP_FIELDS } from '../../consts/bsp';
import { BspFields } from '../../types';
import { classificationValidateBsp } from '../../hooks/classification';
import { User } from '../../models';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { NotificationContext } from '../../contexts/NotificationContext';
import { sleep } from '../../utils/fetchData';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  setOpen: Function;
  user: User;
  fetchBsps: Function;
};

type Record = {
  character_name?: string;
  character_code?: string;
  character_series_name?: string;
  character_series_code?: string;
  update_reason?: string;
};

function NewBspDialog(props: Props) {
  const { open, setOpen, user, fetchBsps } = props;
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const { addNotification } = useContext(NotificationContext);
  const getNextId = useAutoIncrement('BSP');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onPreSubmit: SubmitHandler<any> = async (data) => {
    setRecord(data);
    setEditConfirm(true);
  };

  const onUpdate = async () => {
    try {
      const seq_id = await getNextId();
      const formData = { ...record, seq_id, delete_flg: false, updated_user_id: user.id };
      await API.graphql({ query: createBsp, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'BSPマスターの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchBsps({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BSPマスターの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const onSubmit = () => {
    onUpdate();
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BSPマスターの新規作成"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      height={450}
      width="xs"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 280 },
        }}
        width={340}
      >
        {CREATE_BSP_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateBsp(row.field as BspFields)}
                required={row?.required}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <EditConfirmDialog
        fields={CREATE_BSP_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="BSPマスター"
      />
    </FormDialog>
  );
}

export default NewBspDialog;
