import React, { useState, useEffect, useContext } from 'react';

export default function Maintenance() {
  return (
    <div>
      <h1>ただいまメンテナンス中です</h1>
      <p>
        システムアップデートのためサービスを停止しています。<br />
        ユーザーの皆様にはご不便をおかけしますが、メンテナンス終了まで今しばらくお待ち下さい。<br />
      </p>
    </div>
  );
}
