import React, { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextField from '../forms/FormTextField';
import FormDialog from '../FormDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { BnfFields } from '../../types';
import { classificationValidateBnf } from '../../hooks/classification';
import { User } from '../../models';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { NotificationContext } from '../../contexts/NotificationContext';
import { sleep } from '../../utils/fetchData';
import { CREATE_BNF_FIELDS, VALIDATE_DUPLICATE_BNF_FIELDS } from '../../consts/bnf';
import { createBnf } from '../../graphql/mutations';
import FormSelectBox from '../forms/FormSelectBox';
import { BNF_DATA_SOURCES } from '../../consts/common';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  setOpen: Function;
  user: User;
  fetchBnfs: Function;
};

type Record = {
  bnf_data_source?: string;
  obic7_commodity_cd?: string;
  obic7_commodity_name?: string;
  bvics_plan_cd_4char?: string;
  bvics_plan_cd_7char?: string;
  bvics_product_name?: string;
  update_reason?: string;
};

function NewBnfDialog(props: Props) {
  const { open, setOpen, user, fetchBnfs } = props;
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const { addNotification } = useContext(NotificationContext);
  const getNextId = useAutoIncrement('BNF');
  const [createBnfFields, setCreateBnfFields] = useState(CREATE_BNF_FIELDS);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    watch,
    register,
  } = useForm();

  const selectedBnfDataSource: string = watch('bnf_data_source');

  // FIXME: EditBnfDialogと処理を共通化する
  useEffect(() => {
    if (selectedBnfDataSource === 'OBIC7') {
      register('obic7_commodity_name', { required: '未入力です' });
      register('bvics_product_name', { required: false });

      const newFields = createBnfFields.map((f) => {
        if (f.field === 'obic7_commodity_name') {
          return { ...f, required: true };
        }
        if (f.field === 'bvics_product_name') {
          return { ...f, required: false };
        }
        return f;
      });
      setCreateBnfFields(newFields);
    } else if (selectedBnfDataSource === 'BVICS') {
      register('bvics_product_name', { required: '未入力です' });
      register('obic7_commodity_name', { required: false });

      const newFields = createBnfFields.map((f) => {
        if (f.field === 'bvics_product_name') {
          return { ...f, required: true };
        }
        if (f.field === 'obic7_commodity_name') {
          return { ...f, required: false };
        }
        return f;
      });
      setCreateBnfFields(newFields);
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [selectedBnfDataSource]);

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onPreSubmit: SubmitHandler<any> = async (data) => {
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('BNF', VALIDATE_DUPLICATE_BNF_FIELDS, data, clearErrors, setError);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
  };

  const onUpdate = async () => {
    try {
      const seq_id = await getNextId();
      const formData = {
        ...record,
        seq_id,
        delete_flg: false,
        updated_user_id: user.id,
        admin_check_flg: false,
      };
      await API.graphql({ query: createBnf, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'BNFマスターの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchBnfs({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BNFマスターの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const onSubmit = () => {
    onUpdate();
  };

  const getOptions = (optionName: string) => {
    switch (optionName) {
      default:
      case 'bnf_data_source':
        return BNF_DATA_SOURCES;
    }
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BNFマスターの新規作成"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      height={450}
      width="xs"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 280 },
        }}
        width={340}
      >
        {createBnfFields.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateBnf(row.field)}
                required={row?.required}
              />
            )}
            {row.type === 'select' && row.optionsName && (
              <FormSelectBox
                label={row.label}
                content={getOptions(row.optionsName)}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
                validationRules={classificationValidateBnf(row.field)}
                isBlank={row.isBlank}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <EditConfirmDialog
        fields={createBnfFields}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="BNFマスター"
      />
    </FormDialog>
  );
}

export default NewBnfDialog;
