import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { Currency, Platform, ProductClass, ProductType, ProductTcd, TitleCode, DataSource } from '../../models';
import { CREATE_PRODUCT_TCD_FIELDS } from '../../consts/productTcd';
import { classificationValidateProductTcd } from '../../hooks/classification';
import { createProductTcd } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { CreateProductTcdInput } from '../../API';
import { CE_LINK_SEQ_ID, MULCHI_SEQ_ID } from '../../consts/common';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type ExtendProductTcd = ProductTcd & {
  title_code_name: string;
  data_source_name: string;
};

type Props = {
  currencies: Currency[];
  dataSource: DataSource[];
  platforms: Platform[];
  productClasses: ProductClass[];
  productTypes: ProductType[];
  dialogCloseFunc: Function;
  parentTitleData: TitleCode;
  updateUserId: string;
};

function NewProductTcdDialog(props: Props) {
  const {
    currencies,
    dataSource,
    platforms,
    productClasses,
    productTypes,
    dialogCloseFunc,
    parentTitleData,
    updateUserId,
  } = props;
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<ExtendProductTcd>();
  const celinkDatasource = dataSource.find((datasource) => datasource.seq_id === CE_LINK_SEQ_ID);
  const mulchiDatasource = dataSource.find((datasource) => datasource.seq_id === MULCHI_SEQ_ID);
  const getNextId = useAutoIncrement('PRT');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  const onClose = () => {
    reset();
    dialogCloseFunc(false);
  };

  const onConfirmSubmit: SubmitHandler<ExtendProductTcd> = (data) => {
    setRecord(data);
    setEditConfirm(true);
  };

  const onSubmit = async () => {
    try {
      const seq_id = await getNextId();
      const filterData = (): ProductTcd => {
        delete record.title_code_name;
        delete record.data_source_name;
        // valueが空のプロパティは削除
        Object.keys(record).forEach((key) => {
          if (!record[key]) {
            delete record[key];
          }
        });
        return record;
      };
      const formData: CreateProductTcdInput = {
        ...filterData(),
        seq_id,
        title_code_id: parentTitleData.id,
        // 親のTitleCodeのDataSourceがマルチデータソースの場合CeLinkのDataSourceとして登録
        data_source_id:
          parentTitleData.data_source_id === mulchiDatasource.id ? celinkDatasource.id : parentTitleData.data_source_id,
        delete_flg: false,
        admin_check_flg: false,
        updated_user_id: updateUserId,
        system_update_flg: false,
      };

      await API.graphql({ query: createProductTcd, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'プロダクトの新規作成に成功しました',
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'プロダクトの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const selectOptions = (name: string | undefined) => {
    switch (name) {
      case 'productType':
        return productTypes;
      case 'productClass':
        return productClasses;
      case 'platform':
        return platforms;
      case 'currency':
        return currencies;
      default:
        return [];
    }
  };

  /** 最大カラム数 */
  const maxColumnOrders = Math.max(...CREATE_PRODUCT_TCD_FIELDS.map((field) => field.column));
  const defaultValue = (field: 'title_code_name' | 'data_source_name') => {
    switch (field) {
      case 'data_source_name':
        return parentTitleData.data_source.name;
      case 'title_code_name':
        return parentTitleData.title_code;
      default:
        return '';
    }
  };

  return (
    <FormDialog
      open
      onCancel={onClose}
      title="プロダクトコードの新規作成"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onConfirmSubmit)}
      height={750}
      width="lg"
    >
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => {
          return (
            <Box
              sx={{
                '& > :not(style)': { my: 1, width: 300 },
                display: 'flex',
                flexFlow: 'column wrap',
                height: 'inherit',
              }}
              width={330}
              key={i}
            >
              {CREATE_PRODUCT_TCD_FIELDS.map((row) => {
                const validateField =
                  row.field === 'title_code_name' || row.field === 'data_source_name' ? undefined : row.field;
                return (
                  row.column === i + 1 && (
                    <React.Fragment key={row.field}>
                      {row.type === 'text' && (
                        <FormTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          disabled={row.disabled}
                          value={
                            row.field === 'data_source_name' || row.field === 'title_code_name'
                              ? defaultValue(row.field)
                              : ''
                          }
                          errors={errors}
                          validationRules={classificationValidateProductTcd(validateField)}
                          required={row.required}
                        />
                      )}
                      {row.type === 'date' && (
                        <FormDateTimePicker
                          label={row.label}
                          type={row.type}
                          disabled={row.disabled}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                          errors={errors}
                          errorMessage={'日付形式が無効です'}
                        />
                      )}
                      {row.type === 'select' && (
                        <FormSelectBox
                          label={row.label}
                          content={selectOptions(row.optionsName)}
                          disabled={row.disabled}
                          field={row.field}
                          selectKey={row.field === 'currency_id' ? 'code_three_char' : 'name'}
                          control={control}
                          placeholder={row.placeholder}
                          required={row.required}
                          errors={errors}
                          validationRules={classificationValidateProductTcd(validateField)}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          );
        })}
      </Box>
      <EditConfirmDialog
        currencies={currencies}
        fields={CREATE_PRODUCT_TCD_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        platforms={platforms}
        productClasses={productClasses}
        productTypes={productTypes}
        record={record}
        schemaName="プロダクト"
      />
    </FormDialog>
  );
}

export default NewProductTcdDialog;
