import { useContext, useState } from 'react';
import {
  searchTitles,
  searchProducts,
  searchGenres,
  searchCurrencies,
  searchPlatforms,
  searchProductClasses,
  searchProductTypes,
  getTitle,
  getProduct,
  getGenre,
  getCurrency,
  getPlatform,
  getProductClass,
  getProductType,
  getBsp,
  getRate,
  getBusinessEntity,
  getTitleCode,
  searchProductTcds,
  getProductTcd,
  searchTitleCodes,
  getBnml,
  getBnam,
  getBnf,
  getGroupCompany,
} from '../graphql/queries';
import {
  updateTitle,
  updateProduct,
  updateGenre,
  updateCurrency,
  updatePlatform,
  updateProductClass,
  updateProductType,
  updateBsp,
  updateRate,
  updateTitleCode,
  updateProductTcd,
  updateBusinessEntity,
  updateBnml,
  updateBnam,
  updateBnf,
  updateGroupCompany,
} from '../graphql/mutations';

import { Product } from '../models';
import { AuthContext } from '../contexts/AuthContext';
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import {
  GetTitleQuery,
  GetTitleCodeQuery,
  SearchProductTcdsQuery,
  SyncProductTcdsQueryVariables,
  UpdateTitleCodeInput,
  GetProductQuery,
  GetProductTcdQuery,
  UpdateProductTcdInput,
} from '../API';
import { getTagWithoutRelations } from '../graphql/custom-queries';
import { updateTagWithoutRelations } from '../graphql/custom-mutations';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

export function useRestore() {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  const checkAndRestore = async (tableName: string, data: any) => {
    setLoading(true);

    // 検索ロジック
    const searchDeletedGenres = async (genreId: string) => {
      try {
        const models: any = await API.graphql({
          query: searchGenres,
          variables: {
            filter: { id: { eq: genreId }, delete_flg: { eq: true } },
          },
        });
        return models.data.searchGenres.items;
      } catch (e) {
        console.log(e);
      }
    };

    const searchDeletedProducts = async (titleId: string) => {
      try {
        const models: any = await API.graphql({
          query: searchProducts,
          variables: {
            filter: { title_id: { eq: titleId }, delete_flg: { eq: true } },
          },
        });
        return models.data.searchProducts.items;
      } catch (e) {
        console.log(e);
      }
    };

    const searchDeletedProductTcd = async (titleCodeId: string) => {
      try {
        const filter: SyncProductTcdsQueryVariables = {
          filter: { title_code_id: { eq: titleCodeId }, delete_flg: { eq: true } },
        };
        const models = (await API.graphql<GraphQLQuery<SearchProductTcdsQuery>>({
          query: searchProductTcds,
          variables: filter,
        })) as GraphQLResult<GraphQLQuery<SearchProductTcdsQuery>>;
        return models.data.searchProductTcds.items;
      } catch (e) {
        console.log(e);
      }
    };

    const searchDeletedCurrencies = async (currencyId: string) => {
      try {
        const models: any = await API.graphql({
          query: searchCurrencies,
          variables: {
            filter: { id: { eq: currencyId }, _deleted: { eq: true } },
          },
        });
        return models.data.searchCurrencies.items;
      } catch (e) {
        console.log(e);
      }
    };

    const searchDeletedPlatforms = async (platformId: string) => {
      try {
        const models: any = await API.graphql({
          query: searchPlatforms,
          variables: {
            filter: { id: { eq: platformId }, delete_flg: { eq: true } },
          },
        });
        return models.data.searchPlatforms.items;
      } catch (e) {
        console.log(e);
      }
    };

    const searchDeletedProductClasses = async (productClassId: string) => {
      try {
        const models: any = await API.graphql({
          query: searchProductClasses,
          variables: {
            filter: { id: { eq: productClassId }, delete_flg: { eq: true } },
          },
        });
        return models.data.searchProductClasses.items;
      } catch (e) {
        console.log(e);
      }
    };

    const searchDeletedProductTypes = async (productTypeId: string) => {
      try {
        const models: any = await API.graphql({
          query: searchProductTypes,
          variables: {
            filter: { id: { eq: productTypeId }, delete_flg: { eq: true } },
          },
        });
        return models.data.searchProductTypes.items;
      } catch (e) {
        console.log(e);
      }
    };

    const searchDeletedTitles = async (titleId: string) => {
      try {
        const models: any = await API.graphql({
          query: searchTitles,
          variables: {
            filter: { id: { eq: titleId }, delete_flg: { eq: true } },
          },
        });
        return models.data.searchTitles.items;
      } catch (e) {
        console.log(e);
      }
    };

    const searchDeletedTitleCodes = async (titleCodeId: string) => {
      try {
        const models: any = await API.graphql({
          query: searchTitleCodes,
          variables: {
            filter: { id: { eq: titleCodeId }, delete_flg: { eq: true } },
          },
        });
        return models.data.searchTitleCodes.items;
      } catch (e) {
        console.log(e);
      }
    };

    // 復元ロジック
    const restoreGenre = async (genreId: string) => {
      const model: any = await API.graphql({ query: getGenre, variables: { id: genreId } });
      const genre = model.data.getGenre;
      if (genre) {
        const record = {
          id: genre.id,
          delete_flg: false,
          _version: genre._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateGenre, variables: { input: record } });
        console.log(`GenreRestoreName: ${genre.name}`);
      } else {
        setLoading(false);
        throw new Error(`Genre not found, id: ${genreId}`);
      }
    };

    const restoreProduct = async (productId: string) => {
      const model: any = (await API.graphql({ query: getProduct, variables: { id: productId } })) as GraphQLResult<
        GraphQLQuery<GetProductQuery>
      >;
      const product = model.data.getProduct;
      if (product) {
        const record = {
          id: product.id,
          delete_flg: false,
          _version: product._version,
          updated_user_id: user?.id,
          system_update_flg: false,
        };
        await API.graphql({ query: updateProduct, variables: { input: record } });
        console.log(`ProductRestoreName: ${product.name}`);
      } else {
        setLoading(false);
        throw new Error(`Product not found, id: ${productId}`);
      }
    };

    const restoreProductTcd = async (productTcdId: string) => {
      const model = (await API.graphql<GraphQLQuery<GetProductTcdQuery>>({
        query: getProductTcd,
        variables: { id: productTcdId },
      })) as GraphQLResult<GraphQLQuery<GetProductTcdQuery>>;
      const product = model.data.getProductTcd;
      if (product) {
        const record: UpdateProductTcdInput = {
          id: product.id,
          delete_flg: false,
          _version: product._version,
          updated_user_id: user?.id,
          system_update_flg: false,
        };
        await API.graphql({ query: updateProductTcd, variables: { input: record } });
      } else {
        setLoading(false);
        throw new Error(`Product not found, id: ${productTcdId}`);
      }
    };

    const restoreTitle = async (titleId: string) => {
      const model: any = (await API.graphql({ query: getTitle, variables: { id: titleId } })) as GraphQLResult<
        GraphQLQuery<GetTitleQuery>
      >;
      const title = model.data.getTitle;
      if (title) {
        const record = {
          id: title.id,
          delete_flg: false,
          system_update_flg: false,
          _version: title._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateTitle, variables: { input: record } });
        console.log(`TitleRestoreName: ${title.name_ja}`);
      } else {
        setLoading(false);
        throw new Error(`Title not found, id: ${titleId}`);
      }
    };

    const restoreTitleCode = async (titleCodeId: string) => {
      const model = (await API.graphql<GraphQLQuery<GetTitleCodeQuery>>({
        query: getTitleCode,
        variables: { id: titleCodeId },
      })) as GraphQLResult<GraphQLQuery<GetTitleCodeQuery>>;
      const titleCode = model.data.getTitleCode;
      if (titleCode) {
        const record: UpdateTitleCodeInput = {
          id: titleCode.id,
          delete_flg: false,
          system_update_flg: false,
          _version: titleCode._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateTitleCode, variables: { input: record } });
      } else {
        setLoading(false);
        throw new Error(`Title not found, id: ${titleCodeId}`);
      }
    };

    const restoreCurrency = async (currencyId: string) => {
      const model: any = await API.graphql({ query: getCurrency, variables: { id: currencyId } });
      const currency = model.data.getCurrency;
      if (currency) {
        const record = {
          id: currency.id,
          delete_flg: false,
          _version: currency._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateCurrency, variables: { input: record } });
        console.log(`CurrencyRestoreName: ${currency.name}`);
      } else {
        setLoading(false);
        throw new Error(`Currency not found, id: ${currencyId}`);
      }
    };

    const restorePlatform = async (platformId: string) => {
      const model: any = await API.graphql({ query: getPlatform, variables: { id: platformId } });
      const platform = model.data.getPlatform;
      if (platform) {
        const record = {
          id: platform.id,
          delete_flg: false,
          _version: platform._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updatePlatform, variables: { input: record } });
        console.log(`PlatformRestoreName: ${platform.name}`);
      } else {
        setLoading(false);
        throw new Error(`Platform not found, id: ${platformId}`);
      }
    };

    const restoreProductClass = async (productClassId: string) => {
      const model: any = await API.graphql({ query: getProductClass, variables: { id: productClassId } });
      const productClass = model.data.getProductClass;
      if (productClass) {
        const record = {
          id: productClass.id,
          delete_flg: false,
          _version: productClass._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateProductClass, variables: { input: record } });
        console.log(`ProductClassRestoreName: ${productClass.name}`);
      } else {
        setLoading(false);
        throw new Error(`ProductClass not found, id: ${productClassId}`);
      }
    };

    const restoreProductType = async (productTypeId: string) => {
      const model: any = await API.graphql({ query: getProductType, variables: { id: productTypeId } });
      const productType = model.data.getProductType;
      if (productType) {
        const record = {
          id: productType.id,
          delete_flg: false,
          _version: productType._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateProductType, variables: { input: record } });
        console.log(`ProductTypeRestoreName: ${productType.name}`);
      } else {
        setLoading(false);
        throw new Error(`ProductType not found, id: ${productTypeId}`);
      }
    };

    const restoreTag = async (tagId: string) => {
      const model: any = await API.graphql({ query: getTagWithoutRelations, variables: { id: tagId } });
      const tag = model.data.getTag;
      if (tag) {
        const record = {
          id: tag.id,
          delete_flg: false,
          _version: tag._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateTagWithoutRelations, variables: { input: record } });
        console.log(`TagRestoreName: ${tag.name_ja}`);
      } else {
        setLoading(false);
        throw new Error(`Tag not found, id: ${tagId}`);
      }
    };

    const restoreBsp = async (bspId: string) => {
      const model: any = await API.graphql({ query: getBsp, variables: { id: bspId } });
      const bsp = model.data.getBsp;
      if (bsp) {
        const record = {
          id: bsp.id,
          delete_flg: false,
          _version: bsp._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateBsp, variables: { input: record } });
        console.log(`bspRestoreName: ${bsp.character_series_name}`);
      } else {
        setLoading(false);
        throw new Error(`bsp not found, id: ${bspId}`);
      }
    };

    const restoreBnf = async (bnfId: string) => {
      const model: any = await API.graphql({ query: getBnf, variables: { id: bnfId } });
      const bnf = model.data.getBnf;
      if (bnf) {
        const record = {
          id: bnf.id,
          delete_flg: false,
          _version: bnf._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateBnf, variables: { input: record } });
        console.log(`bnfRestoreId: ${bnf.id}`);
      } else {
        setLoading(false);
        throw new Error(`bnf not found, id: ${bnfId}`);
      }
    };

    const restoreBnml = async (bnmlId: string) => {
      const model: any = await API.graphql({ query: getBnml, variables: { id: bnmlId } });
      const bnml = model.data.getBnml;
      if (bnml) {
        const record = {
          id: bnml.id,
          delete_flg: false,
          _version: bnml._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateBnml, variables: { input: record } });
        console.log(`bnmlRestoreName: ${bnml.mlics_commodity_name}`);
      } else {
        setLoading(false);
        throw new Error(`bsp not found, id: ${bnmlId}`);
      }
    };

    const restoreBnam = async (bnamId: string) => {
      const model: any = await API.graphql({ query: getBnam, variables: { id: bnamId } });
      const bnam = model.data.getBnam;
      if (bnam) {
        const record = {
          id: bnam.id,
          delete_flg: false,
          _version: bnam._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateBnam, variables: { input: record } });
        console.log(`bnamRestoreName: ${bnam.mlics_commodity_name}`);
      } else {
        setLoading(false);
        throw new Error(`bsp not found, id: ${bnamId}`);
      }
    };

    const restoreRate = async (rateId: string) => {
      const model: any = await API.graphql({ query: getRate, variables: { id: rateId } });
      const rate = model.data.getRate;
      if (rate) {
        const record = {
          id: rate.id,
          delete_flg: false,
          _version: rate._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateRate, variables: { input: record } });
        console.log(`rateRestoreName: ${rate.id}`);
      } else {
        setLoading(false);
        throw new Error(`rate not found, id: ${rateId}`);
      }
    };

    const restoreGroupCompany = async (groupCompanyId: string) => {
      const model: any = await API.graphql({ query: getGroupCompany, variables: { id: groupCompanyId } });
      const groupComppany = model.data.getGroupCompany;
      if (groupComppany) {
        const record = {
          id: groupComppany.id,
          delete_flg: false,
          _version: groupComppany._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateGroupCompany, variables: { input: record } });
        console.log(`groupComppanyRestoreName: ${groupComppany.id}`);
      } else {
        setLoading(false);
        throw new Error(`groupComppany not found, id: ${groupCompanyId}`);
      }
    };

    const restoreBusinessEntity = async (businessEntiryId: string) => {
      const model: any = await API.graphql({ query: getBusinessEntity, variables: { id: businessEntiryId } });
      const businessEntiry = model.data.getBusinessEntity;
      if (businessEntiry) {
        const record = {
          id: businessEntiry.id,
          delete_flg: false,
          _version: businessEntiry._version,
          updated_user_id: user?.id,
        };
        await API.graphql({ query: updateBusinessEntity, variables: { input: record } });
        console.log(`businessEntiryRestoreName: ${businessEntiry.id}`);
      } else {
        setLoading(false);
        throw new Error(`businessEntiry not found, id: ${businessEntiry}`);
      }
    };

    // memo: プロダクトの紐づくマスターの削除チェック、復元処理はタイトルでも使用するのでまとめて関数化
    const restoreMastersRelProduct = async (product: Product) => {
      const currencies = product.currency_id ? await searchDeletedCurrencies(product.currency_id) : [];
      currencies.length > 0 && (await restoreCurrency(currencies[0].id));
      const platforms = product.platform_id ? await searchDeletedPlatforms(product.platform_id) : [];
      platforms.length > 0 && (await restorePlatform(platforms[0].id));
      const productClasses = product.product_class_id
        ? await searchDeletedProductClasses(product.product_class_id)
        : [];
      productClasses.length > 0 && (await restoreProductClass(productClasses[0].id));
      const productTypes = product.product_type_id ? await searchDeletedProductTypes(product.product_type_id) : [];
      productTypes.length > 0 && (await restoreProductType(productTypes[0].id));
    };

    // memo: テーブルで処理を分ける
    switch (tableName) {
      case 'tag':
        await restoreTag(data.id);
        break;
      case 'title':
        // 1. タイトル自身を復元
        await restoreTitle(data.id);
        // 2. 紐付くデータのチェック&復元
        const genres = data.genre_id?.length > 0 ? await searchDeletedGenres(data.genre_id[0]) : [];
        genres.length > 0 && (await restoreGenre(genres[0].id));
        // 3. 復元したプロダクト&プロダクトのマスターデータの削除チェック&復元
        const products = await searchDeletedProducts(data.id);
        products.length > 0 &&
          (await Promise.all(
            products.map(async (product: Product) => {
              await restoreProduct(product.id);
              await restoreMastersRelProduct(product);
            })
          ));
        break;
      case 'titleCode':
        // 1. タイトル自身を復元
        await restoreTitleCode(data.id);
        // 3. 復元したプロダクト&プロダクトのマスターデータの削除チェック&復元
        const productTcd = await searchDeletedProductTcd(data.id);
        productTcd.length > 0 &&
          (await Promise.all(
            productTcd.map(async (productTcd) => {
              await restoreProductTcd(productTcd.id);
            })
          ));
        break;
      case 'product':
        // 1. 紐付くタイトルのチェック
        const titles = data.title_id ? await searchDeletedTitles(data.title_id) : [];
        // 2. 紐付くタイトルが削除済みなら処理終了
        if (titles.length > 0) throw new Error('__TITLE_DELETE__');
        // 3. プロダクト自身を復元
        await restoreProductTcd(data.id);
        // 4. 紐付くタイトル以外のマスタデータを復元
        await restoreMastersRelProduct(data);
        break;
      case 'productTcd':
        // 1. 紐付くタイトルコードのチェック
        const titleCodes = data.title_code_id ? await searchDeletedTitleCodes(data.title_code_id) : [];
        // 2. 紐付くタイトルコードが削除済みなら処理終了
        if (titleCodes.length > 0) throw new Error('__TITLE_CODE_DELETE__');
        // 3. プロダクト自身を復元
        await restoreProductTcd(data.id);
        break;
      case 'genre':
        await restoreGenre(data.id);
        break;
      case 'platform':
        await restorePlatform(data.id);
        break;
      case 'productClass':
        await restoreProductClass(data.id);
        break;
      case 'productType':
        await restoreProductType(data.id);
        break;
      case 'currency':
        await restoreCurrency(data.id);
        break;
      case 'bsp':
        await restoreBsp(data.id);
        break;
      case 'bnf':
        await restoreBnf(data.id);
        break;
      case 'bnml':
        await restoreBnml(data.id);
        break;
      case 'bnam':
        await restoreBnam(data.id);
        break;
      case 'rate':
        await restoreRate(data.id);
        break;
      case 'businessEntity':
        await restoreBusinessEntity(data.id);
        break;
      case 'groupCompany':
        await restoreGroupCompany(data.id);
        break;
      default:
        break;
    }
    setLoading(false);
  };

  return { loading, checkAndRestore };
}
