import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import FormAutocomplete from '../forms/FormAutocomplete';
import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { Currency, DataSource, Platform, ProductClass, ProductType, ProductTcd, TitleCode } from '../../models';
import { EDIT_PRODUCT_TCD_FIELDS } from '../../consts/productTcd';
import { BANASUKE_SEQ_ID, MULCHI_SEQ_ID } from '../../consts/common';
import { classificationValidateProductTcd } from '../../hooks/classification';
import { getProductTcd } from '../../graphql/queries';
import { updateProductTcd } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { fetchProductTcd, sleep } from '../../utils/fetchData';
import { ProductTcdSearch } from '../../types/form/productTcd';
import { GetProductTcdQuery, UpdateProductTcdInput } from '../../API';
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  currencies: Currency[];
  dataSources: DataSource[];
  isAdmin?: boolean;
  open: boolean;
  platforms: Platform[];
  productClasses: ProductClass[];
  productTypes: ProductType[];
  selectedRow: ProductTcd;
  titleCodes: TitleCode[];
  updateUserId: string;
  condition: ProductTcdSearch;
  setCondition: Function;
  onChangeFilter: Function;
  setExpQueries: Function;
  onCloseFunc: Function;
};

function EditProductTcdDialog(props: Props) {
  const {
    currencies,
    dataSources,
    isAdmin,
    open,
    platforms,
    productClasses,
    productTypes,
    selectedRow,
    titleCodes,
    updateUserId,
    condition,
    setCondition,
    onChangeFilter,
    setExpQueries,
    onCloseFunc,
  } = props;
  const [confirm, setConfirm] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<ProductTcd>();
  const [alertMsg, setAlertMsg] = useState('');
  const [isRel, setIsRel] = useState(false);
  const { checkAndRestore } = useRestore();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);

  const onClose = () => {
    onCloseFunc();
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<ProductTcd> = async (data) => {
    setRecord(data);
    setEditConfirm(true);
  };

  const onUpdate = async () => {
    try {
      const model = (await API.graphql<GraphQLQuery<GetProductTcdQuery>>({
        query: getProductTcd,
        variables: { id: selectedRow.id },
      })) as GraphQLResult<GraphQLQuery<GetProductTcdQuery>>;
      const productTcd = model.data.getProductTcd;
      const formattedBasePrice = record.base_price ? { base_price: record.base_price } : { base_price: null };
      const formattedData = {
        ...record,
        ...formattedBasePrice,
        title_code_id: record.title_code.id,
      };
      // title_codeは不必要、updatedAtはDB側で更新するので含めない
      const { updatedAt, title_code, ...data } = formattedData;
      const formData: UpdateProductTcdInput = {
        ...data,
        id: selectedRow.id,
        _version: productTcd._version,
        updated_user_id: updateUserId,
        system_update_flg: false,
        data_source_id: record.title_code.data_source_id,
      };
      await API.graphql({ query: updateProductTcd, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'プロダクトの編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchProductTcd(condition, setCondition, onChangeFilter, setExpQueries);
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'プロダクトの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete: SubmitHandler<ProductTcd & { updated_reason: string }> = async (data) => {
    try {
      const model = await API.graphql<GraphQLQuery<GetProductTcdQuery>>({
        query: getProductTcd,
        variables: { id: selectedRow.id },
      });
      const productTcd = model.data.getProductTcd;
      const record: UpdateProductTcdInput = {
        id: selectedRow.id,
        update_reason: data.updated_reason,
        _version: productTcd._version,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
        updated_user_id: updateUserId,
        system_update_flg: false,
      };
      await API.graphql({ query: updateProductTcd, variables: { input: record } });
      closeConfirm();
      onClose();
      addNotification({
        type: 'success',
        message: 'プロダクトの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchProductTcd(condition, setCondition, onChangeFilter, setExpQueries);
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'プロダクトの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const checkRelation = () => {
    // banasuke連携を確認
    const isRelation = selectedRow.data_source?.seq_id === BANASUKE_SEQ_ID;
    setIsRel(isRelation);

    if (isRelation) {
      setAlertMsg(`このプロダクトはバナスケ連携${'\n'}されているため削除できません`);
    } else {
      setAlertMsg(`このプロダクトを削除しても${'\n'}よろしいでしょうか？`);
    }
    setConfirm(true);
  };

  const restoreConfirm = () => {
    setAlertMsg(`このプロダクトを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('productTcd', selectedRow);
      onClose();
      addNotification({
        type: 'success',
        message: 'プロダクトの復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchProductTcd(condition, setCondition, onChangeFilter, setExpQueries);
    } catch (e: any) {
      const message =
        e.message === '__TITLE_CODE_DELETE__'
          ? '親タイトルコードが削除済みの為、復元できませんでした'
          : 'プロダクトの復元に失敗しました';
      addNotification({
        type: 'error',
        message,
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const selectOptions = (name: string | undefined) => {
    switch (name) {
      case 'productType':
        return productTypes;
      case 'productClass':
        return productClasses;
      case 'platform':
        return platforms;
      case 'dataSource':
        return dataSources;
      case 'currency':
        return currencies;
      default:
        return [];
    }
  };

  /** 最大カラム数 */
  const maxColumnOrders = Math.max(...EDIT_PRODUCT_TCD_FIELDS.map((field) => field.column));
  // アドミン権限でなければFieldの最後に設定されているadminCheckFlagを削る
  const fields = isAdmin ? EDIT_PRODUCT_TCD_FIELDS : EDIT_PRODUCT_TCD_FIELDS.slice(0, -1);

  type Field = (typeof fields)[0];
  const isDisable = (__row: Field) => {
    if (selectedRow.delete_flg) return true;
    if (__row?.disabledDataSources?.includes(selectedRow.data_source?.seq_id || '')) return true;
    if (
      selectedRow.data_source.seq_id === BANASUKE_SEQ_ID &&
      selectedRow.title_code.data_source.seq_id === MULCHI_SEQ_ID
    )
      return true;
    return false;
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="プロダクトの編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!selectedRow.delete_flg && handleSubmit(onPreSubmit)}
      onConfirm={selectedRow.delete_flg ? restoreConfirm : checkRelation}
      isRestore={selectedRow.delete_flg}
      height={750}
      width="lg"
    >
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => {
          return (
            <Box
              sx={{
                '& > :not(style)': { my: 1, width: 300 },
                display: 'flex',
                flexFlow: 'column wrap',
                height: 'inherit',
              }}
              width={330}
              key={i}
            >
              {fields.map((__row, idx) => {
                const validateField =
                  __row.field === 'title_code_name' || __row.field === 'data_source_name' ? undefined : __row.field;
                return (
                  __row.column === i + 1 && (
                    <React.Fragment key={idx}>
                      {__row.type === 'text' && (
                        <FormTextField
                          label={__row.label}
                          placeholder={
                            //　disabledDataSourcesの条件の時にはplaceholderをカラ表示にする
                            !__row.disabledDataSources.includes(selectedRow.data_source?.seq_id || '')
                              ? __row.placeholder
                              : ''
                          }
                          field={__row.field}
                          control={control}
                          disabled={isDisable(__row)}
                          errors={errors}
                          validationRules={classificationValidateProductTcd(validateField)}
                          value={selectedRow[__row.field]}
                          required={
                            selectedRow.delete_flg
                              ? false
                              : __row.requiredDataSources.includes(selectedRow.data_source?.seq_id || '')
                          }
                        />
                      )}
                      {(__row.type === 'date' || __row.type === 'datetime') && (
                        <FormDateTimePicker
                          label={__row.label}
                          defaultValue={selectedRow[__row.field]}
                          type={__row.type}
                          disabled={isDisable(__row)}
                          field={__row.field}
                          control={control}
                          placeholder={__row.placeholder}
                          errors={errors}
                          errorMessage={'日付形式が無効です'}
                        />
                      )}
                      {__row.type === 'select' && (
                        <FormSelectBox
                          label={__row.label}
                          content={selectOptions(__row.optionsName)}
                          disabled={isDisable(__row)}
                          defaultValue={selectedRow[__row.field]}
                          field={__row.field}
                          control={control}
                          selectKey={__row.field === 'currency_id' ? 'code_three_char' : 'name'}
                          placeholder={__row.placeholder}
                          required={
                            selectedRow.delete_flg
                              ? false
                              : __row.requiredDataSources.includes(selectedRow.data_source?.seq_id || '')
                          }
                          errors={errors}
                          validationRules={classificationValidateProductTcd(validateField)}
                        />
                      )}
                      {__row.type === 'autoComplete' && (
                        <FormAutocomplete
                          label={__row.label}
                          placeholder={__row.placeholder}
                          field={__row.field}
                          control={control}
                          disabled={isDisable(__row)}
                          errors={errors}
                          validationRules={classificationValidateProductTcd(validateField)}
                          defaultValue={selectedRow.title_code}
                          required={
                            selectedRow.delete_flg
                              ? false
                              : __row.requiredDataSources.includes(selectedRow.data_source?.seq_id || '')
                          }
                          options={__row.field === 'title_code' ? titleCodes : []}
                          isTitleCode
                        />
                      )}
                      {__row.type === 'checkbox' && (
                        <FormCheckBox
                          field={__row.field}
                          label={__row.label}
                          control={control}
                          defaultValue={selectedRow[__row.field] || false}
                          disabled={isDisable(__row)}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          );
        })}
      </Box>
      <ConfirmDialog
        message={alertMsg}
        open={confirm}
        onCancel={isRel ? undefined : closeConfirm}
        onContinue={isRel ? closeConfirm : selectedRow.delete_flg ? onRestore : handleSubmit(onDelete)}
        type={isRel ? 'error' : 'warning'}
        updateReason={
          selectedRow.delete_flg ? (
            <></>
          ) : (
            <Box px={3} mb={1}>
              <FormTextField
                label="更新理由"
                placeholder="更新理由を入力"
                value={selectedRow.update_reason?.toString() || ''}
                field="update_reason"
                control={control}
                errors={errors}
                validationRules={classificationValidateProductTcd('update_reason')}
              />
            </Box>
          )
        }
        isEnsureMessageHeight
      />
      <EditConfirmDialog
        currencies={currencies}
        dataSources={dataSources}
        fields={fields}
        onClose={onEditConfirmClose}
        onSubmit={onUpdate}
        open={editConfirm}
        platforms={platforms}
        productClasses={productClasses}
        productTypes={productTypes}
        record={{ ...record, title_code: record?.title_code?.title_code }}
        schemaName="プロダクト"
        beforeChangeValueData={{
          ...selectedRow,
          title_code: selectedRow.title_code.title_code,
        }}
      />
    </FormDialog>
  );
}

export default EditProductTcdDialog;
