import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormSelectBox from '../forms/FormSelectBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import {
  OPERATION_TYPE_OPTIONS,
  SYSTEM_UPDATE_OPTIONS,
  DELETE_FLG_OPTIONS,
  ADMIN_CHECK_FLG_OPTIONS,
} from '../../consts/common';
import {
  SEARCH_GROUP_COMPANY_HISTORY_FIELDS_DEFAULT_VALUES,
  SEARCH_GROUP_COMPANY_HISTORY_FIELDS,
} from '../../consts/groupCompanyHistory';
import {
  BRAND_PORTFOLIO_LIST,
  COMPANY_ATTRIBUTE_LIST,
  CONSOLIDATION_LIST,
  EQUITY_METHOD_APPLICATION_LIST,
  MANAGEMENT_BUSINESS_LIST,
  PUBLIC_OR_PRIVATE_LIST,
  UNIT_CLASS_LIST,
  GROUP_AGREEMENT_FLG_LIST,
  MANAGEMENT_CONTACT_FLG_LIST,
  SUBCONTRACTING_CONTACT_FLG_LIST,
  SEARCH_GROUP_COMPANY_FIELDS,
  SEARCH_GROUP_COMPANY_FIELDS_DEFAULT_VALUES,
} from '../../consts/groupCompany';
import { GroupCompanyHistoryFields } from '../../types';
import { classificationValidateGroupCompanyHistory } from '../../hooks/classification';
import { GroupCompanyHistorySearch } from '../../types/form/groupCompanyHistory';
import { GroupCompany, Country, Currency } from '../../models';
import { generateSearchFilter, SEARCH_TYPE } from '../../utils/search';
import FormAutocomplete from '../forms/FormAutocomplete';
import PrimaryButton from '../PrimaryButton';
import { searchGroupCompanies } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  condition: GroupCompanyHistorySearch;
  isAdmin: boolean | undefined;
  open: boolean;
  setOpen: Function;
  fetchGroupCompanyHistories: Function;
  countries: Country[];
  currencies: Currency[];
};

function SearchGroupComapanyHistoryDialog(props: Props) {
  const { condition, open, setOpen, fetchGroupCompanyHistories, countries, currencies } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: SEARCH_GROUP_COMPANY_HISTORY_FIELDS_DEFAULT_VALUES,
  });

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]));
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<GroupCompanyHistorySearch> = async (newCondition) => {
    try {
      await fetchGroupCompanyHistories({ newCondition });
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const [currentGroupCompanies, setCurrentGroupCompanies] = useState<GroupCompany[]>();

  const options = (name: string) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      case 'company_attribute':
        return COMPANY_ATTRIBUTE_LIST;
      case 'consolidation':
        return CONSOLIDATION_LIST;
      case 'equity_method_application':
        return EQUITY_METHOD_APPLICATION_LIST;
      case 'unit_class_1':
      case 'unit_class_2':
        return UNIT_CLASS_LIST;
      case 'management_business_1':
      case 'management_business_2':
        return MANAGEMENT_BUSINESS_LIST;
      case 'country':
        return countries;
      case 'public_or_private':
        return PUBLIC_OR_PRIVATE_LIST;
      case 'brand_portfolio':
        return BRAND_PORTFOLIO_LIST;
      case 'holding_company_1':
      case 'holding_company_2':
      case 'holding_company_3':
      case 'holding_company_4':
      case 'holding_company_5':
        return currentGroupCompanies;
      case 'currency':
        return currencies;
      case 'group_agreement_flg':
        return GROUP_AGREEMENT_FLG_LIST;
      case 'management_contract_flg':
        return MANAGEMENT_CONTACT_FLG_LIST;
      case 'subcontracting_contract_flg':
        return SUBCONTRACTING_CONTACT_FLG_LIST;
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      default:
        return [];
    }
  };

  const onGroupCompaniesSearch = async (field: string) => {
    const value = (document.getElementById(field) as HTMLInputElement).value;
    console.log({ value });

    if (!value) return;

    const jaFilter = generateSearchFilter([{ name: 'company_name_ja', value, type: SEARCH_TYPE.WILDCARD }]);
    const enFilter = generateSearchFilter([{ name: 'company_name_en', value, type: SEARCH_TYPE.WILDCARD }]);
    try {
      const jaModels: any = await API.graphql({
        query: searchGroupCompanies,
        variables: {
          ...jaFilter,
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 500,
        },
      });
      const enModels: any = await API.graphql({
        query: searchGroupCompanies,
        variables: {
          ...enFilter,
          sort: { direction: 'desc', field: 'seq_id' },
          limit: 500,
        },
      });
      const groupCompanies = [...jaModels.data.searchTags.items, ...enModels.data.searchTags.items] as GroupCompany[];
      let uniqueGroupCompanyIds = [];
      const uniqueGroupCompanies = groupCompanies.reduce((current, groupCompany) => {
        if (!uniqueGroupCompanyIds.includes(groupCompany.id)) {
          uniqueGroupCompanyIds.push(groupCompany.id);
          return [...current, groupCompany];
        }
        return current;
      }, []);
      setCurrentGroupCompanies([...uniqueGroupCompanies]);
    } catch (e) {
      console.log(e);
    }
  };

  /** 最大カラム数 */
  const maxColumnOrders = Math.max(...SEARCH_GROUP_COMPANY_FIELDS.map((field) => field.column));

  return (
    <SearchDialog
      open={open}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      height={600}
      width="xl"
    >
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => (
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: 300 },
              display: 'flex',
              flexFlow: 'column wrap',
              height: 'inherit',
            }}
            width={330}
            key={i}
          >
            {SEARCH_GROUP_COMPANY_HISTORY_FIELDS.map(
              (row) =>
                row.column === i + 1 && (
                  <React.Fragment key={row.field}>
                    {row.type === 'select' && row.optionsName && (
                      <FormSelectBox
                        label={row.label}
                        content={options(row.optionsName)}
                        field={row.field}
                        control={control}
                        placeholder={row.placeholder}
                        isBlank={row.isBlank}
                      />
                    )}
                    {(row.type === 'datetime' || row.type === 'date') && row.inputs && (
                      <Box sx={{ display: 'flex' }}>
                        <FormDateTimeRangePicker
                          fromLabel={row.inputs[0].label}
                          fromField={row.inputs[0].field}
                          toLabel={row.inputs[0].label}
                          toField={row.inputs[1].field}
                          type={row.type}
                          placeholder={row.placeholder}
                          control={control}
                          watch={watch}
                          errors={errors}
                        />
                      </Box>
                    )}
                    {row.type === 'text' && (
                      <FormTextField
                        label={row.label}
                        placeholder={row.placeholder}
                        field={row.field}
                        control={control}
                        errors={errors}
                        validationRules={classificationValidateGroupCompanyHistory(
                          row.field as GroupCompanyHistoryFields
                        )}
                      />
                    )}
                    {row.type === 'autoComplete' && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          '& > div': { width: '70%' },
                          '& > button': { marginTop: '10px' },
                        }}
                      >
                        <FormAutocomplete
                          id={row.field}
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          errors={errors}
                          validationRules={[]}
                          required={row.required}
                          options={options(row.field)}
                          freeSolo
                          includeSearchEnglish
                        />
                        <PrimaryButton onClick={() => onGroupCompaniesSearch(row.field)}>検索</PrimaryButton>
                      </Box>
                    )}
                  </React.Fragment>
                )
            )}
          </Box>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchGroupComapanyHistoryDialog;
