import React, { useCallback, useState, useEffect, useContext } from 'react';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { theme } from '../assets/theme/options';
import { format, parseISO } from 'date-fns';
import DetailGroupCompanyHistoryDialog from '../components/groupCompanyHistory/DetailGroupCompanyHistoryDialog';
import SearchGroupCompanyHistoryDialog from '../components/groupCompanyHistory/SearchGroupCompanyHistoryDialog';
import PrimaryButton from '../components/PrimaryButton';
import { filterColumnsForGeneralRole } from '../utils/filterColumns';
import { GroupCompanyHistory, Currency, Country, GroupCompany } from '../models';
import { convertOperationType } from '../utils/converter';
import { AuthContext } from '../contexts/AuthContext';
import { useEditedColumnClass, useIsDeletedOrRestoreColumn } from '../hooks/useEditedColumnClass';
import { useSearchGroupCompanyHistories } from '../hooks/useSearchGourpCompanyHistories';
import { searchCurrencies, searchCountries, searchGroupCompanies } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

function GroupCompanyHistoryList() {
  const columns: GridColDef[] = [
    {
      field: 'operation_type',
      headerName: '操作種別',
      width: 210,
      valueFormatter: (params: any) => {
        return convertOperationType[params.value];
      },
    },
    {
      field: 'seq_id',
      headerName: 'グループ会社ID',
      width: 210,
    },
    {
      field: 'group_company_code',
      headerName: 'グループ会社コード',
      width: 190,
    },
    {
      field: 'valid_start_date',
      headerName: '有効開始日',
      width: 190,
    },
    {
      field: 'valid_end_date',
      headerName: '有効終了日',
      width: 190,
    },
    {
      field: 'stravis_code',
      headerName: 'Stravisコード',
      width: 190,
    },
    {
      field: 'company_name_ja',
      headerName: '会社名（日本語）',
      width: 190,
    },
    {
      field: 'company_name_en',
      headerName: '会社名（英語）',
      width: 190,
    },
    {
      field: 'abbreviation',
      headerName: '略称',
      width: 190,
    },
    {
      field: 'company_attribute',
      headerName: '会社属性',
      width: 190,
    },
    {
      field: 'consolidation',
      headerName: '連結・非連結',
      width: 190,
    },
    {
      field: 'equity_method_application',
      headerName: '持分法適用・非適用',
      width: 190,
    },
    {
      field: 'unit_class_1',
      headerName: 'ユニット区分1',
      width: 190,
    },
    {
      field: 'unit_class_2',
      headerName: 'ユニット区分2',
      width: 190,
    },
    {
      field: 'management_business_1',
      headerName: '管理事業1',
      width: 190,
    },
    {
      field: 'management_business_2',
      headerName: '管理事業2',
      width: 190,
    },
    {
      field: 'country_name',
      headerName: '国/地域',
      width: 190,
      valueFormatter: (params: any) => {
        return params.value?.name;
      },
    },
    {
      field: 'accounting_period',
      headerName: '決算期',
      width: 190,
    },
    {
      field: 'public_or_private',
      headerName: '公開/非公開',
      width: 190,
    },
    {
      field: 'group_agreement_flg',
      headerName: 'グループ規約適用',
      width: 190,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />),
    },
    {
      field: 'management_contract_flg',
      headerName: '経営管理契約',
      width: 190,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />),
    },
    {
      field: 'subcontracting_contract_flg',
      headerName: '業務委託契約',
      width: 190,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />),
    },
    {
      field: 'brand_portfolio',
      headerName: 'ブランドポートフォリオ',
      width: 190,
    },
    {
      field: 'holding_company_1',
      headerName: '保有会社1',
      width: 190,
      valueFormatter: (param: any) => {
        console.log({ param });
        return getGroupCompanyName(param.value);
      },
    },
    {
      field: 'investment_ratio_1',
      headerName: '出資比率1',
      width: 190,
    },
    {
      field: 'holding_company_2',
      headerName: '保有会社2',
      width: 190,
      valueFormatter: (param: any) => {
        console.log({ param });
        return getGroupCompanyName(param.value);
      },
    },
    {
      field: 'investment_ratio_2',
      headerName: '出資比率2',
      width: 190,
    },
    {
      field: 'holding_company_3',
      headerName: '保有会社3',
      width: 190,
      valueFormatter: (param: any) => {
        console.log({ param });
        return getGroupCompanyName(param.value);
      },
    },
    {
      field: 'investment_ratio_3',
      headerName: '出資比率3',
      width: 190,
    },
    {
      field: 'holding_company_4',
      headerName: '保有会社4',
      width: 190,
      valueFormatter: (param: any) => {
        console.log({ param });
        return getGroupCompanyName(param.value);
      },
    },
    {
      field: 'investment_ratio_4',
      headerName: '出資比率4',
      width: 190,
    },
    {
      field: 'holding_company_5',
      headerName: '保有会社5',
      width: 190,
      valueFormatter: (param: any) => {
        console.log({ param });
        return getGroupCompanyName(param.value);
      },
    },
    {
      field: 'investment_ratio_5',
      headerName: '出資比率5',
      width: 190,
    },
    {
      field: 'total_investment_ratio',
      headerName: '合計出資比率',
      width: 190,
    },
    {
      field: 'issued_shares_total',
      headerName: '発行済株式総数',
      width: 190,
    },
    {
      field: 'treasury_stock',
      headerName: '自己株',
      width: 190,
    },
    {
      field: 'nominee_shares',
      headerName: '名義貸株式',
      width: 190,
    },
    {
      field: 'capital',
      headerName: '資本金',
      width: 190,
    },
    {
      field: 'currency_name',
      headerName: '現地通貨',
      width: 190,
      valueFormatter: (params: any) => {
        return params.value?.code_three_char;
      },
    },
    {
      field: 'number_of_employees',
      headerName: '従業員数',
      width: 190,
    },
    {
      field: 'establishment_date',
      headerName: '設立日',
      width: 190,
    },
    {
      field: 'representative_director_1_position',
      headerName: '代表取締役1役職名',
      width: 190,
    },
    {
      field: 'representative_director_1_name',
      headerName: '代表取締役1氏名',
      width: 190,
    },
    {
      field: 'representative_director_2_position',
      headerName: '代表取締役2役職名',
      width: 190,
    },
    {
      field: 'representative_director_2_name',
      headerName: '代表取締役2氏名',
      width: 190,
    },
    {
      field: 'representative_director_3_position',
      headerName: '代表取締役3役職名',
      width: 190,
    },
    {
      field: 'representative_director_3_name',
      headerName: '代表取締役3氏名',
      width: 190,
    },
    {
      field: 'business_description',
      headerName: '業務内容',
      width: 190,
    },
    {
      field: 'address_1',
      headerName: '住所1',
      width: 190,
    },
    {
      field: 'address_2',
      headerName: '住所2',
      width: 190,
    },
    {
      field: 'notes',
      headerName: '備考',
      width: 190,
    },
    {
      field: 'ip_address',
      headerName: 'IPアドレス',
      width: 190,
      renderCell: (params) => (params.value == '[]' ? '' : params.value?.replace('[', '')?.replace(']', '')),
    },
    {
      field: 'delete_flg',
      headerName: '削除済データ',
      width: 190,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />),
    },
    {
      field: 'updated_user_name',
      headerName: '最終更新者',
      width: 210,
    },
    {
      field: 'updatedAt',
      headerName: '最終更新日時',
      width: 210,
      valueFormatter: (params) => format(parseISO(params.value), 'yyyy/MM/dd HH:mm'),
    },
  ];

  const [row, setRow] = useState<GroupCompanyHistory | null>(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [countries, setCountries] = useState<Currency[]>([]);
  const [groupCompanies, setGroupCompanies] = useState<GroupCompany[]>([]);
  const { user, roles } = useContext(AuthContext);
  const roleTypes = roles?.map((role) => role.role?.label);

  const {
    fetchGroupCompanyHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    groupCompanyHistories,
    setGroupCompanyHistories,
    onChangePage,
    condition,
    setCondition,
    page,
    onChangeSort,
  } = useSearchGroupCompanyHistories();

  useEffect(() => {
    fetchCurrencies();
    fetchCountries();
    fetchGroupCompanies();
    fetchGroupCompanyHistories({});
    // eslint-disable-next-line
  }, []);

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const removeColumns = [];
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns);

  const doOpenDetail = (params: any) => {
    params.row.ip_address =
      params.row.ip_address == '[]' ? '' : params.row.ip_address?.replace('[', '')?.replace(']', '');
    params.row.old_ip_address =
      params.row.old_ip_address == '[]' ? '' : params.row.old_ip_address?.replace('[', '')?.replace(']', '');
    setRow(params.row);
    setOpenDetail(true);
  };

  const getGroupCompanyName = (id: string | undefined) => {
    if (!id) return null;
    console.log(groupCompanies.find((groupCompany) => groupCompany.id === id)?.company_name_ja);
    return groupCompanies.find((groupCompany) => groupCompany.id === id)?.company_name_ja;
  };

  const fetchCurrencies = async () => {
    try {
      const models: any = await API.graphql({ query: searchCurrencies, variables: { limit: 500 } });
      const items = models?.data?.searchCurrencies?.items as Currency[];
      setCurrencies(items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchGroupCompanies = async () => {
    try {
      const models: any = await API.graphql({ query: searchGroupCompanies, variables: { limit: 500 } });
      const result = models?.data?.searchGroupCompanies?.items as GroupCompany[];
      console.log({ result });
      setGroupCompanies(result);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCountries = async () => {
    try {
      const models: any = await API.graphql({ query: searchCountries, variables: { limit: 500 } });
      const result = models?.data?.searchCountries?.items as Country[];
      setCountries(result);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} textAlign={'right'}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={groupCompanyHistories}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onRowClick={doOpenDetail}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .edited_cell': {
              background: theme.colors.editedCell,
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openSearch && (
        <SearchGroupCompanyHistoryDialog
          condition={condition}
          isAdmin={roleTypes?.includes('ADMIN')}
          open={openSearch}
          setOpen={setOpenSearch}
          fetchGroupCompanyHistories={fetchGroupCompanyHistories}
          currencies={currencies}
          countries={countries}
        />
      )}
      {openDetail && row && user && (
        <DetailGroupCompanyHistoryDialog
          open={openDetail}
          row={row}
          setOpen={setOpenDetail}
          setRow={setRow}
          user={user}
          groupCompanies={groupCompanies}
        />
      )}
    </Box>
  );
}

export default GroupCompanyHistoryList;
