import { useState } from 'react';
import { searchRates } from '../graphql/queries';
import usePageSize from './usePageSize';
import { Rate } from '../models';
import { generateSearchFilter } from '../utils/search';
import { SEARCH_RATE_FIELDS_DEFAULT_VALUES } from '../consts/rate';
import { RateSearch } from '../types/form/rate';
import { INITIAL_EXP_ONLY_DEL_QUERIES_STATE } from '../consts/export';
import { dataToFilterRateObj } from '../utils/fetchData';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = { direction: 'desc', field: 'seq_id' };

export const useSearchRates = () => {
  const [rates, setRates] = useState<Rate[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>(defaultSort);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<RateSearch>(SEARCH_RATE_FIELDS_DEFAULT_VALUES);
  const [expQueries, setExpQueries] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >(INITIAL_EXP_ONLY_DEL_QUERIES_STATE);

  const fetchRates = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: RateSearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const searchQueries = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          if (value === 'ALL' && key === 'delete_flg') return;
          return {
            name: dataToFilterRateObj[key].name,
            value: value,
            type: dataToFilterRateObj[key].type,
          };
        })
        .filter(Boolean);
      const filter = generateSearchFilter(searchQueries);
      const models: any = await API.graphql({
        query: searchRates,
        variables: {
          ...filter,
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null,
        },
      });
      setTotal(models.data.searchRates.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchRates.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchRates.nextToken])));
      }
      newCondition && setCondition({ ...newCondition });
      // exportで使用するクエリをセット
      newCondition && setExpQueries(searchQueries);
      setRates([...models.data.searchRates.items]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchRates({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchRates({ newSort: defaultSort });
    } else {
      const sortField = sortModel[0].field === 'updated_by' ? 'updated_user_id' : sortModel[0].field;
      const newSort = { direction: sortModel[0].sort, field: sortField };
      setSort({ ...newSort });
      fetchRates({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchRates({ nextToken });
  };

  return {
    fetchRates,
    loading,
    total,
    pageSize,
    onChangePageSize,
    rates,
    setRates,
    onChangePage,
    page,
    onChangeSort,
    condition,
    expQueries,
    setExpQueries,
  };
};
