export const SEARCH_CURRENCY_FIELDS_DEFAULT_VALUES = {
  order_id: '',
  currency_code: '',
  code_three_char: '',
  name: '',
  name_en: '',
  digit_number: '',
  currency_symbol: '',
  update_reason: '',
  from: null,
  to: null
};

export const SEARCH_CURRENCY_FIELDS = [
  {
    field: 'order_id',
    label: 'currency_order_id',
    type: 'text',
    placeholder: '表示順を入力',
  },
  {
    field: 'currency_code',
    label: 'currency_currency-code',
    type: 'text',
    placeholder: '通貨コードを入力',
  },
  {
    field: 'code_three_char',
    label: 'currency_code-three-char',
    type: 'text',
    placeholder: 'コード（3文字）を入力',
  },
  {
    field: 'name',
    label: 'currency_name-japanese',
    type: 'text',
    placeholder: '通貨名（日本語）を入力',
  },
  {
    field: 'name_en',
    label: 'currency_name-english',
    type: 'text',
    placeholder: '通貨名（英語）を入力',
  },
  {
    field: 'digit_number',
    label: 'currency_digit-number',
    type: 'text',
    placeholder: '有効桁数を入力',
  },
  {
    field: 'currency_symbol',
    label: 'currency_currency-symbol',
    type: 'text',
    placeholder: '通貨記号を入力',
  },
  {
    field: 'update_reason',
    label: 'currency_update-reason',
    type: 'text',
    placeholder: '更新理由を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'currency_final-update-date' },
      { field: 'to', label: 'currency_final-update-date' },
    ],
  }
];