import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormCheckBox from '../forms/FormCheckBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import { SEARCH_TAG_BNF_FIELDS, SEARCH_TAG_BNF_FIELDS_DEFAULT_VALUES } from '../../consts/tagBnf';
import { tagBnfFields } from '../../types';
import { classificationValidateTagBnf } from '../../hooks/classification';
import { TagBnfSearch } from '../../types/form/tagBnf'
import { fetchTagBnf } from '../../utils/fetchData';
import FormSelectBox from '../forms/FormSelectBox';
import { Bnf } from '../../models';
import { BNF_DATA_SOURCES } from '../../consts/common';

type Props = {
  condition: TagBnfSearch;
  open: boolean;
  setCondition: Function;
  setExpQueries: Function
  setOpen: Function;
  setBnfs: Function;
}

function SearchTagBnfDialog(props: Props) {
  const { condition, open, setCondition, setExpQueries, setOpen, setBnfs } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues: SEARCH_TAG_BNF_FIELDS_DEFAULT_VALUES });
  const watchIsUnregistered = watch('is_unregistered');

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  useEffect(() => {
    if (watchIsUnregistered) {
      setValue('tags_ja', '')
      setValue('tags_en', '')
      setValue('update_reason', '')
    }
  }, [watchIsUnregistered, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<TagBnfSearch> = async (data) => {
    await fetchTagBnf(setBnfs, data, setCondition, setExpQueries)
    onClose()
  };

  const onReset = () => {
    reset();
  };

  const getOptions = (optionName: string) => {
    switch (optionName) {
      default:
      case "bnf_data_source":
        return BNF_DATA_SOURCES
    }
  }

  return (
    <SearchDialog open={open} onCancel={onClose} onSubmit={handleSubmit(onSubmit)} onReset={onReset} height={580} width="sm">
      <Box
        sx={{ '& > :not(style)': { m: 2, width: 400 } }}
        width={440}
      >
        {SEARCH_TAG_BNF_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {(row.type === 'date') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={row.inputs[0].field}
                  toLabel={row.inputs[0].label}
                  toField={row.inputs[1].field}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {(row.type === 'text') && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateTagBnf(row.field as tagBnfFields, 'search')}
                disabled={['tags_ja', 'tags_en', 'update_reason'].includes(row.field) && watchIsUnregistered}
              />
            )}
            {row.type === 'checkbox' && (
              <FormCheckBox field={row.field} label={row.label} control={control} />
            )}
            {row.type === "select" && row.optionsName && (
              <FormSelectBox
                label={row.label}
                content={getOptions(row.optionsName)}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
                defaultValue={row[row.field as keyof Pick<Bnf, 'bnf_data_source'>] || false}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchTagBnfDialog;
