import React, { useContext, useEffect, useState, memo } from 'react';
import { SQS, S3 } from 'aws-sdk';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import { onUpdateCsvJob } from '../graphql/subscriptions';
import { createCsvJob } from '../graphql/mutations';
import { AuthContext } from '../contexts/AuthContext';
import { CsvJob } from '../models';
import { generateSearchFilter } from '../utils/search';
import { useCredentials } from '../hooks/useCredentials';
import awsOptions from '../aws-options';
import awsExports from '../aws-exports';
import { Box, Stack } from '@mui/material';
import StepBar from './StepBar';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

interface ExportButtonProps {
  condition?: { name: string; type: string; value: any }[];
  tableName:
    | 'TAG'
    | 'TITLE'
    | 'PRODUCT'
    | 'TAG_TITLE'
    | 'CURRENCY'
    | 'GENRE'
    | 'PLATFORM'
    | 'PRODUCT_CLASS'
    | 'PRODUCT_TYPE'
    | 'BSP'
    | 'BNML'
    | 'TAG_BSP'
    | 'BNAM'
    | 'TAG_BNAM'
    | 'COUNTRY'
    | 'RATE'
    | 'CALENDAR'
    | 'PRODUCT_TCD'
    | 'TITLE_CODE'
    | 'TAG_TITLE_CODE'
    | 'BUSINESS_ENTITY'
    | 'BNAM'
    | 'TAG_BNML'
    | 'TAG_BNAM'
    | 'BNF'
    | 'TAG_BNF'
    | 'BNF'
    | 'GROUP_COMPANY';
  type: 'SEARCH' | 'ALL';
}

type JobStatus = 'STANDBY' | 'DOING' | 'DONE' | 'ERROR';

const steps = [
  '待機中', // STANDBY
  '処理中', // DOING
  '完了' // DONE
];

function ExportButton(props: ExportButtonProps) {
  const { condition = [], tableName, type } = props;
  const [job, setJob] = useState<CsvJob | null>(null);
  const [step, setStep] = useState(0);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    useCredentials();
    subscribeOnUpdateJob();
  }, []);

  useEffect(() => {
    job && setStep((prev) => changeStep(job.status as JobStatus, prev));
    job?.status === 'ERROR' && alertErrorAndResetJob(job?.message ?? '');
  }, [job]);

  const changeStep = (status: JobStatus, currentStep: number): number => {
    switch (status) {
      case 'STANDBY':
        return 0;
      case 'DOING':
        return 1;
      case 'DONE':
        return 2;
      case 'ERROR':
        return currentStep;
      default:
        const _: never = status;
    }
  };

  const subscribeOnUpdateJob = () => {
    const client = API.graphql({ query: onUpdateCsvJob });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const updated: CsvJob = data.onUpdateCsvJob;
          setJob((prev) => (prev?.id === updated.id ? updated : prev));
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const alertErrorAndResetJob = (message: string) => {
    alert(`エラー:\n${message ? message : '不明なエラーが発生しました'}`);
    setJob(null);
  };

  const handleClickExport = async () => {
    // 1. jobの作成
    const newJob = await newCsvJob();
    setJob(newJob);
    // 2. キューの送信
    newJob && sendMessage({ id: newJob.id, type });
  };
  const newCsvJob = async () => {
    try {
      const filter = generateSearchFilter(condition);
      const fileName = fileNameEditor();
      console.log({ filter });
      const record = {
        type: 'EXPORT',
        table_name: tableName,
        search_condition: JSON.stringify(filter),
        updated_user_id: user?.id,
        status: 'STANDBY',
        name: fileName
      };
      const model: any = await API.graphql({ query: createCsvJob, variables: { input: record } });
      const newJob = (model.data as any).createCsvJob;
      return newJob;
    } catch (e) {
      console.log(e);
    }
  };

  const sendMessage = async (message: { id: string; type: string }) => {
    try {
      const sqs = new SQS();
      const { QueueUrl } = await sqs
        .getQueueUrl({
          QueueName: 'exportCsv-' + awsOptions.amplify_backend_name
        })
        .promise();
      if (!QueueUrl) {
        alertErrorAndResetJob('リクエスト送信準備エラー\n担当者にお問い合わせください');
        return;
      }
      const MessageBody = JSON.stringify({ message });
      const result = await sqs.sendMessage({ MessageBody, QueueUrl }).promise();
      console.log({ result });
    } catch (e) {
      console.error(e);
      alertErrorAndResetJob('リクエスト送信エラー\n担当者にお問い合わせください');
    }
  };

  const handleClickDownloadCsv = () => {
    downloadCsv();
    setJob(null);
  };

  const downloadCsv = async () => {
    if (!job) {
      alert('ダウンロードエラー\n担当者にお問い合わせください');
      return;
    }
    try {
      const s3 = new S3();
      const result = await s3
        .getObject({
          Bucket: awsExports.aws_user_files_s3_bucket,
          Key: `csv/export/${tableName.toLowerCase()}/${job.id}.csv`
        })
        .promise();
      console.log({ result });
      downloadBlob(result.Body, job?.name ?? '(ファイル名読み込みエラー）.csv', result.ContentType);
    } catch (e) {
      console.log(e);
      alertErrorAndResetJob('ファイル取得エラー\n担当者にお問い合わせください');
    }
  };

  const downloadBlob = (blob: any, fileName: string, contentType?: string) => {
    const url = URL.createObjectURL(
      new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), blob], {
        type: contentType
      })
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  };

  const fileNameEditor = (): string => {
    const now = format(new Date(), 'yyyyMMddHHmmss', { locale: ja });
    switch (type) {
      case 'SEARCH':
        if (tableName === 'TITLE') return `Title_Exp_${now}.csv`;
        if (tableName === 'TITLE_CODE') return `TitleCode_Exp_${now}.csv`;
        if (tableName === 'PRODUCT') return `Product_Exp_${now}.csv`;
        if (tableName === 'PRODUCT_TCD') return `ProductTcd_Exp_${now}.csv`;
        if (tableName === 'TAG_BSP') return `TagBsp_Exp_${now}.csv`;
        if (tableName === 'TAG_BNML') return `TagBnml_Exp_${now}.csv`;
        if (tableName === 'TAG_BNAM') return `TagBnam_Exp_${now}.csv`;
        if (tableName === 'TAG_BNF') return `TagBnf_Exp_${now}.csv`;
        if (tableName === 'CALENDAR') return `Calendar_Exp_${now}.csv`;
        if (tableName === 'RATE') return `Rate_Exp_${now}.csv`;
        if (tableName === 'TAG_TITLE_CODE') return `TagTitleCode_${now}.csv`;
        if (tableName === 'BSP') return `Bsp_Exp_${now}.csv`;
        if (tableName === 'BNML') return `Bnml_Exp_${now}.csv`;
        if (tableName === 'BNAM') return `Bnam_Exp_${now}.csv`;
        if (tableName === 'BNF') return `Bnf_Exp_${now}.csv`;
        if (tableName === 'GROUP_COMPANY') return `GroupCompany_${now}.csv`;
        return '';
      case 'ALL':
        if (tableName === 'TAG') return `Tag_${now}.csv`;
        if (tableName === 'TAG_TITLE') return `TagTitle_${now}.csv`;
        if (tableName === 'TITLE') return `Title_${now}.csv`;
        if (tableName === 'PRODUCT') return `Product_${now}.csv`;
        if (tableName === 'PRODUCT_TCD') return `ProductTcd_${now}.csv`;
        if (tableName === 'CURRENCY') return `Currency_${now}.csv`;
        if (tableName === 'GENRE') return `Genre_${now}.csv`;
        if (tableName === 'PLATFORM') return `Platform_${now}.csv`;
        if (tableName === 'PRODUCT_CLASS') return `ProductClass_${now}.csv`;
        if (tableName === 'PRODUCT_TYPE') return `ProductType_${now}.csv`;
        if (tableName === 'BSP') return `Bsp_${now}.csv`;
        if (tableName === 'BNML') return `BNML_${now}.csv`;
        if (tableName === 'TAG_BSP') return `TagBsp_${now}.csv`;
        if (tableName === 'TAG_BNML') return `TagBnml_${now}.csv`;
        if (tableName === 'BNF') return `Bnf_${now}.csv`;
        if (tableName === 'TAG_BNF') return `TagBnf_${now}.csv`;
        if (tableName === 'BNAM') return `Bnam_${now}.csv`;
        if (tableName === 'TAG_BNAM') return `TagBnam_${now}.csv`;
        if (tableName === 'COUNTRY') return `Country_${now}.csv`;
        if (tableName === 'CALENDAR') return `Calendar_${now}.csv`;
        if (tableName === 'RATE') return `Rate_${now}.csv`;
        if (tableName === 'TITLE_CODE') return `TitleCode_${now}.csv`;
        if (tableName === 'TAG_TITLE_CODE') return `TagTitleCode_${now}.csv`;
        if (tableName === 'BUSINESS_ENTITY') return `BusinessEntity_${now}.csv`;
        if (tableName === 'GROUP_COMPANY') return `GroupCompany_${now}.csv`;
        return '';
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <LoadingButton
        onClick={job?.status === 'DONE' ? handleClickDownloadCsv : handleClickExport}
        loading={job?.status === 'DOING' || job?.status === 'STANDBY'}
        disabled={job?.status === 'ERROR'}
        startIcon={type === 'ALL' && <DownloadIcon />}
        variant="contained"
        sx={{ width: 120, height: 40 }}
      >
        {job?.status === 'DONE' ? 'ダウンロード' : 'エクスポート'}
      </LoadingButton>
      {job && (
        <Box sx={{ width: 360, height: 40, p: 1 }}>
          <StepBar steps={steps} activeStep={step} completed={job.status === 'DONE'} error={job.status === 'ERROR'} />
        </Box>
      )}
    </Stack>
  );
}

export default memo(ExportButton);
