import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Backdrop } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { User, Bsp } from '../../models';
import { EDIT_BSP_FIELDS, VALIDATE_DUPLICATE_BSP_FIELDS } from '../../consts/bsp';
import { BspFields } from '../../types';
import { classificationValidateBsp } from '../../hooks/classification';
import { getBsp } from '../../graphql/queries';
import { updateBsp } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { sleep } from '../../utils/fetchData';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  row: Bsp;
  setOpen: Function;
  setRow: Function;
  fetchBsps: Function;
  user: User;
};

type Record = {
  bsp_master_id?: string;
  character_name?: string;
  character_code?: string;
  character_series_name?: string;
  character_series_code?: string;
  updated_user_id?: string;
  updatedAt?: string;
  update_reason?: string;
};

function EditBspDialog(props: Props) {
  const { open, row, setOpen, setRow, user, fetchBsps } = props;
  const [confirm, setConfirm] = useState(false);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isRel, setIsRel] = useState(false);
  const { loading, checkAndRestore } = useRestore();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();
  const fields = EDIT_BSP_FIELDS;
  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const onConfirmCancel = () => {
    setConfirm(false);
  };

  // 「確認」ボタンの処理
  const onPreSubmit: SubmitHandler<Record> = async (data) => {
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('BSP', VALIDATE_DUPLICATE_BSP_FIELDS, data, clearErrors, setError, row.id);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
  };

  // 確認編集画面の「登録」の処理
  const onSubmit = () => {
    setCanSubmit(true);
  };

  const onUpdate = async () => {
    try {
      const model: any = await API.graphql({ query: getBsp, variables: { id: row.id } });
      const bsp = (model.data as any).getBsp;
      const formData = {
        id: row.id,
        character_name: record.character_name,
        character_code: record.character_code,
        character_series_name: record.character_series_name,
        character_series_code: record.character_series_code,
        _version: bsp._version,
        updated_user_id: user.id,
        update_reason: record.update_reason,
      };
      await API.graphql({ query: updateBsp, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'BSPマスターの編集に成功しました',
      });
      await sleep(3000);
      await fetchBsps({});
      reset();
    } catch (e) {
      addNotification({
        type: 'success',
        message: 'BSPマスターの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete: SubmitHandler<Record> = async (data) => {
    await deleteBsp(data);
    setConfirm(false);
    onClose();
    await sleep(3000);
    await fetchBsps({});
  };

  const deleteBsp = async (data: Record) => {
    try {
      const model: any = await API.graphql({ query: getBsp, variables: { id: row.id } });
      const bsp = (model.data as any).getBsp;
      const formData = {
        id: row.id,
        _version: bsp._version,
        updated_user_id: user.id,
        update_reason: data.update_reason,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
      };
      await API.graphql({ query: updateBsp, variables: { input: formData } });
      reset();
      addNotification({
        type: 'success',
        message: 'BSPマスターの削除に成功しました',
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BSPマスターの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const checkRelation = () => {
    const isRelation: boolean = (row.tags as any).items.some((item: any) => !item._deleted);
    setIsRel(isRelation);
    if (isRelation) {
      setAlertMsg(`このBSPマスターはタグと紐付け${'\n'}されていますが削除してよろしいでしょうか？`);
    } else {
      setAlertMsg(`このBSPマスターを削除しても${'\n'}よろしいでしょうか？`);
    }
    setConfirm(true);
  };

  const restoreConfirm = () => {
    setAlertMsg(`このBSPマスターを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('bsp', row);
      onClose();
      addNotification({
        type: 'success',
        message: 'BSPマスターの復元に成功しました',
      });
      await sleep(3000);
      await fetchBsps({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'BSPマスターの復元に失敗しました',
      });
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BSPマスターの編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!row.delete_flg && handleSubmit(onPreSubmit)}
      onConfirm={row.delete_flg ? restoreConfirm : checkRelation}
      isRestore={row.delete_flg}
      height={600}
      width="md"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 300 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 530,
        }}
        width={980}
      >
        {EDIT_BSP_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {(__row.type === 'text' || __row.type === 'textarea') && (
              <FormTextField
                label={__row.label}
                placeholder={__row.placeholder}
                field={__row.field}
                control={control}
                disabled={row.delete_flg || __row.disabled}
                errors={errors}
                validationRules={classificationValidateBsp(__row.field as BspFields)}
                value={row[__row.field as keyof Pick<Bsp, 'updated_user_id'>]}
                required={row.delete_flg ? false : __row.required}
                isTextArea={__row.type === 'textarea'}
              />
            )}
            {__row.type === 'datetime' && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<Bsp, 'updatedAt'>]}
                type={__row.type}
                disabled={row.delete_flg || __row.disabled}
                field={__row.field}
                control={control}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <ConfirmDialog
        message={alertMsg}
        open={confirm}
        onCancel={onConfirmCancel}
        onContinue={row.delete_flg ? onRestore : handleSubmit(onDelete)}
        type={isRel ? 'error' : 'warning'}
        updateReason={
          row.delete_flg ? (
            <></>
          ) : (
            <Box px={3} mb={1}>
              <FormTextField
                label="更新理由"
                placeholder="更新理由を入力"
                value={row.update_reason?.toString() || ''}
                field="update_reason"
                control={control}
                errors={errors}
                validationRules={classificationValidateBsp('update_reason')}
              />
            </Box>
          )
        }
      />
      <EditConfirmDialog
        fields={fields}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="BSPマスター"
        beforeChangeValueData={row}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormDialog>
  );
}

export default EditBspDialog;
