import React from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';

import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import { Country } from '../../models';

import { EDIT_CALENDAR_FIELDS } from '../../consts/calendar';
import { calendarFields } from '../../types';
import { classificationValidateCalendar } from '../../hooks/classification';
import { Calendar } from '../../API';
import FormSelectBox from '../forms/FormSelectBox';

type Props = {
  onClose: Function;
  open: boolean;
  row: Calendar;
  setRow: Function;
  countries: Country[];
};

function EditCalendarDialog({ onClose, open, row, setRow, countries }: Props) {
  const {
    control,
    formState: { errors },
  } = useForm();

  const doClose = () => {
    setRow(null);
    onClose();
  };

  return (
    <FormDialog
      open={open}
      onCancel={doClose}
      title="祝日カレンダーマスタの詳細"
      cancelText="キャンセル"
      height={450}
      width="lg"
    >
      <Box
        display={'flex'}
        sx={{
          '& > :not(style)': { my: 1, width: 335 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 'inherit',
        }}
        width={700}
      >
        {EDIT_CALENDAR_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {__row.type === 'select' && (
              <FormSelectBox
                label={__row.label}
                content={countries}
                defaultValue={
                  row[__row.field as keyof Pick<Calendar, 'country_id'>]
                }
                field={__row.field}
                control={control}
                selectKey="name"
                placeholder={__row.placeholder}
                errors={errors}
                disabled
                validationRules={classificationValidateCalendar(
                  __row.field as calendarFields
                )}
              />
            )}
            {__row.type === 'text' && (
              <FormTextField
                label={__row.label}
                placeholder={__row.placeholder}
                field={__row.field}
                control={control}
                disabled
                errors={errors}
                validationRules={classificationValidateCalendar(
                  __row.field as calendarFields
                )}
                value={
                  row[
                  __row.field as keyof Pick<
                    Calendar,
                    | 'year'
                    | 'month'
                    | 'day'
                    | 'day_of_week'
                    | 'name'
                    | 'update_reason'
                    | 'updated_user_id'
                    | 'country_id'
                  >
                  ]
                }
              />
            )}
            {(__row.type === 'datetime' || __row.type === 'date') && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={
                  row[__row.field as keyof Pick<Calendar, 'calendar_date' | 'updatedAt'>]
                }
                type={__row.type}
                disabled
                field={__row.field}
                control={control}
              />
            )}
            {__row.type === 'checkbox' && (
              <FormCheckBox
                field={__row.field}
                defaultValue={
                  row[__row.field as keyof Pick<Calendar, 'holiday_flg'>]
                }
                label={__row.label}
                control={control}
                disabled
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormDialog>
  );
}

export default EditCalendarDialog;
