import React from 'react';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import { Currency } from '../../models';
import { rateFields } from '../../types';
import { classificationValidateRate } from '../../hooks/classification';
import { EDIT_RATE_FIELDS } from '../../consts/rate';
import FormSelectBox from '../forms/FormSelectBox';
import { Rate } from '../../API';

type Props = {
  open: boolean;
  row: Rate;
  setOpen: Function;
  setRow: Function;
  currencies: Currency[]
}

function EditRateDialog(props: Props) {
  const { open, currencies, row, setOpen, setRow } = props;

  const {
    control,
    formState: { errors },
  } = useForm();


  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="レートマスタの詳細"
      cancelText="キャンセル"
      height={420}
      width="lg"
      isRestore={row.delete_flg}
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 350 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: '-webkit-fill-available',
        }}
        width={1100}
      >
        {EDIT_RATE_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {__row.type === 'select' && (
              <FormSelectBox
                label={__row.label}
                content={currencies}
                defaultValue={row[__row.field as keyof Pick<Rate, 'converted_currency_id' | 'source_currency_id'>]}
                field={__row.field}
                control={control}
                selectKey={__row.optionsName === 'currency' ? 'code_three_char' : 'name'}
                errors={errors}
                disabled
                validationRules={classificationValidateRate(__row.field as rateFields)}
              />
            )}
            {__row.type === 'text' && (
              <FormTextField
                label={__row.label}
                field={__row.field}
                control={control}
                disabled
                errors={errors}
                validationRules={classificationValidateRate(__row.field as rateFields)}
                value={row[__row.field as keyof Pick<Rate, 'seq_id' | 'source_currency_id' | 'converted_currency_id' | 'exchange_rate' | 'company_name' | 'usage' | 'description' | 'exchange_rate_type' | 'updated_user_id'>]}
              />
            )}
            {__row.type === 'datetime' && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<Rate, 'updatedAt'>]}
                type={__row.type}
                disabled={row.delete_flg || __row.disabled}
                field={__row.field}
                control={control}
              />
            )}
            {__row.type === 'date' && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<Rate, 'from_date' | 'to_date'>]}
                type={__row.type}
                field={__row.field}
                control={control}
                errors={errors}
                disabled
                validationRules={classificationValidateRate(__row.field as rateFields)}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormDialog>
  );
}

export default EditRateDialog;
