import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { NotificationContext } from '../../contexts/NotificationContext';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormRadioGroup from '../forms/FormRadioGroup';
import FormArrayTextField from '../forms/FormArrayTextField';
import FormDialog from '../FormDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { Genre, DataSource } from '../../models';
import { createTitle } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID, CE_LINK_SEQ_ID, MULCHI_SEQ_ID } from '../../consts/common';
import { CREATE_TITLE_FIELDS, RESET_TITLE_FIELDS, VALIDATE_DUPLICATE_TITLE_FIELDS } from '../../consts/title';
import { titleFields } from '../../types';
import { classificationValidateTitle } from '../../hooks/classification';
import FormSelectChip from '../forms/FormSelectChip';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { sleep } from '../../utils/fetchData';
import { AuthContext } from '../../contexts/AuthContext';
import { TitleSearch } from '../../types/form/title';
import { fetchTitles } from '../../utils/fetchData';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  dataSources: DataSource[];
  genres: Genre[];
  open: boolean;
  setOpen: Function;
  condition: TitleSearch;
  setCondition: Function;
  onChangeFilter: Function;
  setExpQueries: Function;
};

type Record = {
  data_sorce_id?: string;
  name_ja?: string;
  name_en?: string;
  search_name?: string;
  banasuke_title_id?: string;
  ce_link_key_code?: string;
  update_reason?: string;
  genre_id?: [];
  project_cd?: [];
  sap_title_code?: [];
  bn_connect_title_id?: [];
};

type SelectGenre = {
  id: string;
  name: string;
};

function NewTitleDialog(props: Props) {
  const { dataSources, genres, open, setOpen, condition, setCondition, onChangeFilter, setExpQueries } = props;
  const [typeId, setTypeId] = useState<string | undefined>(MASTER_SYSTEM_SEQ_ID);
  const [editConfirm, setEditConfirm] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [selectGenres, setSelectGenres] = useState<SelectGenre[]>([]);

  const getNextId = useAutoIncrement('TTL');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm();
  const watchId = watch('data_source_id')
    ? watch('data_source_id')
    : dataSources?.find((dataSource) => dataSource.name === 'own')?.id;
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setValue('project_cd', [{ value: '' }]);
    setValue('sap_title_code', [{ value: '' }]);
    setValue('bn_connect_title_id', [{ value: '' }]);
    setValue('genre_id', []);
    setSelectGenres([]);
  }, [watchId, setValue]);

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (watchId) {
      const target = dataSources.find((dataSource) => dataSource.id === watchId);
      target && setTypeId(target.seq_id);
    }
  }, [dataSources, watchId]);

  useEffect(() => {
    if (!canSubmit) return;
    onUpdate();
    // eslint-disable-next-line
  }, [canSubmit]);

  useEffect(() => {
    resetValues();
    // eslint-disable-next-line
  }, [typeId]);

  const onClose = () => {
    setOpen(false);
  };

  const onPreSubmit: SubmitHandler<any> = async (data) => {
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('TITLE', VALIDATE_DUPLICATE_TITLE_FIELDS, data, clearErrors, setError);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
  };

  const onSubmit = () => {
    setCanSubmit(true);
  };

  const onUpdate = async () => {
    try {
      const genreIds =
        record['genre_id'] && record['genre_id']?.length > 0 ? record['genre_id'].map((value: any) => value.id) : null;
      const projectCds =
        record['project_cd'] && record['project_cd']?.length > 0
          ? record['project_cd'].map((value: any) => value.value)
          : null;
      const sapTitleCodes =
        record['sap_title_code'] && record['sap_title_code']?.length > 0
          ? record['sap_title_code'].map((value: any) => value.value)
          : null;
      const bnConnectTitleIds =
        record['bn_connect_title_id'] && record['bn_connect_title_id']?.length > 0
          ? record['bn_connect_title_id'].map((value: any) => value.value)
          : null;
      const seq_id = await getNextId();
      const formData = {
        ...record,
        seq_id,
        project_cd: projectCds,
        sap_title_code: sapTitleCodes,
        bn_connect_title_id: bnConnectTitleIds,
        genre_id: genreIds,
        admin_check_flg: false,
        delete_flg: false,
        updated_user_id: user.id,
        system_update_flg: false,
      };
      await API.graphql({ query: createTitle, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'タイトルの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTitles(condition, setCondition, onChangeFilter, setExpQueries);
      reset();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'タイトルの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const resetValues = () => {
    RESET_TITLE_FIELDS.map((field) => resetField(field.field, field.value));
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const rename = (__dataSources: DataSource[]) => {
    const dataSourses = [...__dataSources].sort((a, b) => {
      if (a.seq_id < b.seq_id) return -1;
      else if (a.seq_id > b.seq_id) return 1;
      return 0;
    });
    return dataSourses.map((dataSource: any) => {
      if (dataSource.seq_id === MASTER_SYSTEM_SEQ_ID) {
        return { ...dataSource, name: 'タイトル新規作成' };
      } else if (dataSource.seq_id === BANASUKE_SEQ_ID) {
        return { ...dataSource, name: 'アプリタイトル/NE' };
      } else if (dataSource.seq_id === CE_LINK_SEQ_ID) {
        return { ...dataSource, name: '家庭用タイトル/CE' };
      } else if (dataSource.seq_id === MULCHI_SEQ_ID) {
        return { ...dataSource, name: 'マルチタイトル/NE&CE' };
      }
    });
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'genre':
        return genres;
      case 'dataSource':
        return rename(dataSources);
      default:
        return [];
    }
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="タイトルの新規作成"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      height={705}
      width="lg"
    >
      <Box pl={3}>
        <FormRadioGroup
          content={options(CREATE_TITLE_FIELDS[0].optionsName)}
          field={CREATE_TITLE_FIELDS[0].field}
          control={control}
        />
      </Box>
      <Divider variant="middle" />
      <Box display={'flex'}>
        {[1, 2, 3].map((num) => {
          return (
            <Box
              sx={{
                '& > :not(style)': { my: 1, width: 300 },
                display: 'flex',
                flexFlow: 'column wrap',
                height: 'inherit',
              }}
              width={330}
              key={num}
            >
              {CREATE_TITLE_FIELDS.slice(1).map((row, idx) => {
                return (
                  row.column == num && (
                    <React.Fragment key={idx}>
                      {row.type === 'select' && row.optionsName && (
                        <FormSelectBox
                          label={row.label}
                          content={options(row.optionsName)}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                        />
                      )}
                      {(row.type === 'text' || row.type === 'textarea') && (
                        <FormTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          isTextArea={row.type === 'textarea'}
                          field={row.field}
                          control={control}
                          disabled={typeId ? row.typeIds?.includes(typeId) : false}
                          errors={errors}
                          validationRules={classificationValidateTitle(row.field as titleFields)}
                          required={row?.required}
                        />
                      )}
                      {row.type === 'arrayText' && (
                        <FormArrayTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          errors={errors}
                          validationRules={classificationValidateTitle(row.field as titleFields)}
                        />
                      )}
                      {row.type === 'chip' && (
                        <FormSelectChip
                          label={row.label}
                          content={options(row.optionsName)}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                          setValue={setValue}
                          genres={selectGenres}
                          setGenres={setSelectGenres}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          );
        })}
      </Box>
      <EditConfirmDialog
        fields={CREATE_TITLE_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="タイトル"
        genres={genres}
        dataSources={dataSources}
      />
    </FormDialog>
  );
}

export default NewTitleDialog;
