import React from 'react';
import { Box, FormHelperText, InputLabel, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import jaLocale from 'date-fns/locale/ja';
import { format, parseISO } from 'date-fns';
import { theme } from '../../assets/theme/options';
import { Controller } from 'react-hook-form';
import { inputType, validationRules } from '../../types';
import createValidationRules from '../../hooks/createValidationRules';
import { I18n } from 'aws-amplify/utils';
import RequiredLabel from './RequiredLabel';

interface FormDateTimePickerProps {
  /** ラベル */
  label: string;
  /** デフォルト値 */
  defaultValue?: string;
  /** DateTimePickerかDateTimePickerか */
  type: inputType;
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** placeholder */
  placeholder?: string;
  /** ReactHookForm */
  control: any;
  errors?: any;

  maxDate?: Date;
  minDate?: Date;
  hasError?: boolean;
  errorMessage?: string;
  /** 編集済み色 */
  isEdited?: boolean;
  /** 必須ラベルの有無 */
  required?: boolean;
  /** バリデーションの内容 */
  validationRules?: validationRules[] | string[];
}

function FormDateTimePicker(props: FormDateTimePickerProps) {
  return (
    <Box position={'relative'}>
      {props.required && <RequiredLabel />}
      <InputLabel sx={{ fontSize: '12px', marginBottom: '2px' }}>{I18n.get(props.label)}</InputLabel>
      <Controller
        name={props.field}
        control={props.control}
        defaultValue={props?.defaultValue || null}
        rules={{
          // 入力内容が日付として読み取れない状態を通さないためのチェック
          validate: {
            dateCheck: (value) => value?.toString() !== 'Invalid Date',
          },
          ...(props.validationRules && createValidationRules(props.validationRules)),
        }}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale}>
            {props.type === 'date' ? (
              <DatePicker
                {...field}
                disabled={props.disabled}
                onChange={(e) => {
                  if (e === null) {
                    field.onChange(null);
                    return;
                  }
                  field.onChange(String(e));
                  const isDateType = !isNaN(Date.parse(String(e)));
                  if (!isDateType) return;
                  field.onChange(format(e, 'yyyy-MM-dd'));
                }}
                mask="____/__/__"
                minDate={props.minDate}
                maxDate={props.maxDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    sx={{
                      background: props.disabled
                        ? props.isEdited
                          ? theme.colors.editedCell
                          : theme.colors.disabled
                        : '',
                    }}
                    size="small"
                    inputProps={{ ...params.inputProps, placeholder: props.placeholder }}
                    error={props?.hasError || !!props.errors?.[props.field]}
                    helperText={
                      (props.hasError && props.errorMessage) || (props.errors?.[props.field] && props.errorMessage)
                    }
                  />
                )}
              />
            ) : (
              <DateTimePicker
                {...field}
                disabled={props.disabled}
                onChange={(e: any) => {
                  if (e === null) {
                    field.onChange(null);
                    return;
                  }
                  field.onChange(String(e));
                  const isDateType = !isNaN(Date.parse(String(e)));
                  if (!isDateType) return;
                  const parseDate = parseISO(format(e, 'yyyy-MM-dd HH:mm'));
                  const formattedUtcDate = new Date(parseDate).toISOString();
                  field.onChange(formattedUtcDate);
                }}
                mask="____/__/__ __:__"
                minDate={props.minDate}
                maxDate={props.maxDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    sx={{ background: props.disabled ? theme.colors.disabled : '' }}
                    size="small"
                    inputProps={{ ...params.inputProps, placeholder: props.placeholder }}
                    error={props.hasError || !!props.errors?.[props.field]}
                    helperText={
                      (props.hasError && props.errorMessage) || (props.errors?.[props.field] && props.errorMessage)
                    }
                  />
                )}
              />
            )}
            {props.errors?.[props.field]?.message && (
              <FormHelperText error>{props.errors[props.field].message}</FormHelperText>
            )}
          </LocalizationProvider>
        )}
      />
    </Box>
  );
}

export default FormDateTimePicker;
