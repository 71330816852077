import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { searchTitleCodes } from '../graphql/queries';

import PrimaryButton from '../components/PrimaryButton';
import EditTagTitleCodeDialog from '../components/tagTitleCodes/EditTagTitleCodeDialog';
import SearchTagTitleCodeDialog from '../components/tagTitleCodes/SearchTagTitleCodeDialog';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { AuthContext } from '../contexts/AuthContext';
import { grey } from '@mui/material/colors';
import { TagTitleCodeSearch } from '../types/form/tagTitleCodeSearch';
import { SEARCH_TAG_TITLE_CODE_FIELDS_DEFAULT_VALUES } from '../consts/tagTitleCode';
import { onCreateTitleCode, onUpdateTitleCode } from '../graphql/subscriptions';
import usePageSize from '../hooks/usePageSize';
import { INITIAL_EXP_ONLY_DEL_QUERIES_STATE } from '../consts/export';
import { onCreateTagWithoutRelations } from '../graphql/custom-subscriptions';
import { searchTagsMinimumRelations } from '../graphql/custom-queries';

import type { TagTitle, TitleCode } from '../models';
import type { SearchableTitleCodeSortInput, Tag, TagTitleCode } from '../API';

import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type ExTagTitle = TagTitle & {
  _deleted: boolean | null;
};

const columns: GridColDef[] = [
  {
    field: 'seq_id',
    headerName: 'タイトルコードID',
    width: 190
  },
  {
    field: 'name_ja',
    headerName: 'タイトルコード名称',
    width: 210
  },
  {
    field: 'tags_ja',
    headerName: 'IPタグ（日本語）',
    width: 210,
    valueGetter: ({ row }) => {
      const tagTitle = row.tags.items.find((tagTitle: ExTagTitle) => !tagTitle._deleted);
      return tagTitle?.tag?.name_ja;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return (
          <Box
            sx={{
              bgcolor: grey[800],
              height: 30,
              lineHeight: '30px',
              px: 1,
              color: 'white'
            }}
          >
            未登録
          </Box>
        );
      }
      return value;
    }
  },
  {
    field: 'tag_en',
    headerName: 'IPタグ（英語）',
    width: 210,
    valueGetter: ({ row }) => {
      const tagTitle = row.tags.items.find((tagTitle: ExTagTitle) => !tagTitle._deleted);
      return tagTitle?.tag?.name_en;
    },
    renderCell: ({ value }) => {
      if (value === undefined) {
        return (
          <Box
            sx={{
              bgcolor: grey[800],
              height: 30,
              lineHeight: '30px',
              px: 1,
              color: 'white'
            }}
          >
            未登録
          </Box>
        );
      }
      return value;
    },
    sortComparator: (v1, v2) => {
      if (v1 === undefined) return 1;
      if (v2 === undefined) return -1;
      if (v1 === '') return 1;
      if (v2 === '') return -1;
      if (v1 === null) return 1;
      if (v2 === null) return -1;
      if (v1 > v2) return 1;
      return -1;
    }
  },
  {
    field: 'updatedAt',
    headerName: 'タイトルコード最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

function TagTitleCodeList() {
  const [titleCodes, setTitleCodes] = useState<TitleCode[]>([]);
  const [titleCode, setTitleCode] = useState<TitleCode | null>(null);
  const [total, setTotal] = useState(0);
  const { pageSize, setPageSize } = usePageSize();
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [condition, setCondition] = useState<TagTitleCodeSearch>(SEARCH_TAG_TITLE_CODE_FIELDS_DEFAULT_VALUES);
  const [loading, setLoading] = useState<boolean>(false);

  // 検索関連
  const [openSearch, setOpenSearch] = useState(false);

  // 編集関連
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useContext(AuthContext);
  const [tagTitleCode, setTagTitleCode] = useState<TagTitleCode | null>(null);
  const [tags, setTags] = useState<Tag[]>([]);
  const [addedTag, setAddedTag] = useState<Tag | null>(null);

  // インポート/エクスポートボタン関連
  const [titleCodeIds, setTitleCodeIds] = useState<string[] | undefined>(undefined);
  const [isUploaded, setIsUploaded] = useState(false);
  const [expQueries, setExpQueries] = useState<
    {
      name: string;
      value: string | number | boolean;
      type: string;
    }[]
  >(INITIAL_EXP_ONLY_DEL_QUERIES_STATE);

  useEffect(() => {
    fetchTitleCodes({});
    fetchTags();
    subscribeOnCreateTitleCode();
    subscribeOnUpdateTitleCode();
    subscribeOnCreateTag();
    // eslint-disable-next-line
  }, []);

  const fetchTitleCodes = async ({ nextToken, newLimit }: { nextToken?: string | null; newLimit?: number }) => {
    try {
      setLoading(true);
      const models: any = await API.graphql({
        query: searchTitleCodes,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'seq_id' },
          limit: pageSize,
          nextToken: nextToken ?? null
        }
      });
      setTotal(models.data.searchTitleCodes.total);
      if (newLimit) {
        setNextTokens([models.data.searchTitleCodes.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchTitleCodes.nextToken])));
      }
      setTitleCodes(models?.data?.searchTitleCodes.items);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const models: any = await API.graphql({
        query: searchTagsMinimumRelations,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'desc', field: 'updatedAt' },
          limit: 500
        }
      });
      setTags(models.data.searchTags.items);
    } catch (e) {
      console.log(e);
    }
  };

  const subscribeOnCreateTitleCode = () => {
    const client = API.graphql({ query: onCreateTitleCode });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const titleCode = data.onCreateTitleCode;
          setTitleCodes((prev) => [titleCode, ...prev]);
        }
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnUpdateTitleCode = () => {
    const client = API.graphql({ query: onUpdateTitleCode });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const titleCode = data.onUpdateTitleCode;
          if (!titleCode.delete_flg) {
            // 更新
            setTitleCodes((titleCodes) =>
              titleCodes.map((__titleCode) => (__titleCode.id === titleCode.id ? titleCode : __titleCode))
            );
          } else {
            // 削除
            setTitleCodes((titleCodes) => titleCodes.filter((__titleCode) => __titleCode.id !== titleCode.id));
          }
        }
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnCreateTag = () => {
    const client = API.graphql({ query: onCreateTagWithoutRelations });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const tag = data.onCreateTag;
          setTags((tags) => [tag, ...tags]);
          setAddedTag(tag);
        }
      });
      return () => subscription.unsubscribe();
    }
  };

  const doOpenEdit = (params: any) => {
    setTitleCode(params.row);
    const __tagTitleCode = params.row.tags.items.find((tagTitleCode: any) => !tagTitleCode._deleted);
    __tagTitleCode && setTagTitleCode(__tagTitleCode);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const onChangePageSize = (num: number) => {
    setPageSize(num);
    setPage(0);
    fetchTitleCodes({ newLimit: num });
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchTitleCodes({ nextToken });
  };

  const onCloseEdit = () => {
    setTagTitleCode(null);
    setTitleCode(null);
    setAddedTag(null);
    setOpenEdit(false);
  };

  useEffect(() => {
    if (!isUploaded) return;
    fetchTitleCodes({});
    setIsUploaded(false);
    // eslint-disable-next-line
  }, [isUploaded]);

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, width: 900 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: 800
            }}
          >
            <ExportButton condition={expQueries} tableName="TAG_TITLE_CODE" type="SEARCH" />
            <ImportButton tableName="TAG_TITLE_CODE" setIsUploaded={setIsUploaded} />
          </Box>
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          rows={titleCodes}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          rowCount={total || 0}
          disableSelectionOnClick
          onRowClick={loading ? undefined : doOpenEdit}
          pagination
          paginationMode="server"
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none'
            }
          }}
          loading={loading}
        />
      </div>
      {openEdit && user && (
        <EditTagTitleCodeDialog
          addedTag={addedTag}
          onClose={onCloseEdit}
          open={openEdit}
          tags={tags}
          tagTitleCode={tagTitleCode}
          titleCode={titleCode}
          user={user}
          setTitleCodes={setTitleCodes}
          condition={condition}
          setCondition={setCondition}
          setTitleCodeIds={setTitleCodeIds}
          setExpQueries={setExpQueries}
          setLoading={setLoading}
        />
      )}
      {openSearch && (
        <SearchTagTitleCodeDialog
          condition={condition}
          open={openSearch}
          setCondition={setCondition}
          setOpen={setOpenSearch}
          setTitleCodes={setTitleCodes}
          setTitleCodeIds={setTitleCodeIds}
          setExpQueries={setExpQueries}
        />
      )}
    </Box>
  );
}

export default TagTitleCodeList;
