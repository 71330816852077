import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import FormCheckBox from '../forms/FormCheckBox';
import FormTextField from '../forms/FormTextField';
import FormRadioGroup from '../forms/FormRadioGroup';
import FormArrayTextField from '../forms/FormArrayTextField';
import FormDialog from '../FormDialog';
import FormSelectChip2 from '../forms/FormSelectChip2';
import EditConfirmDialog from '../EditConfirmDialog';

import { createTitleCode } from '../../graphql/mutations';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { MASTER_SYSTEM_SEQ_ID, VALIDITY_OPTIONS } from '../../consts/common';
import {
  CREATE_TITLE_CODE_FIELDS,
  TITLE_CODE_RESET_VALUES,
  VALIDATE_DUPLICATE_TITLE_CODE_FIELDS,
} from '../../consts/titleCode';
import { classificationValidateTitleCode } from '../../hooks/classification';
import { fetchTitleCodes, sleep } from '../../utils/fetchData';
import { AuthContext } from '../../contexts/AuthContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Genre, DataSource, Platform, BusinessEntity } from '../../models';
import { useRenameDataSource } from '../../hooks/useRenameDataSource';
import { CreateTitleCodeInput } from '../../API';
import { ConfirmSubmitTitleCode, ConfirmTitleCodeData, SearchConditionTitleCode } from '../../types/form/titleCode';
import { useConvertingArrayTextForConfirm } from '../../hooks/useConvertingArrayTextForConfirm';
import FormSelectBox from '../forms/FormSelectBox';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  dataSources: DataSource[];
  genres: Genre[];
  platforms: Platform[];
  businessEntity: BusinessEntity[];
  setOpen: Function;
  condition: SearchConditionTitleCode;
  setCondition: Function;
  onChangeFilter: Function;
  setExpQueries: Function;
};

function NewTitleCodeDialog(props: Props) {
  const {
    dataSources,
    genres,
    platforms,
    businessEntity,
    setOpen,
    condition,
    setCondition,
    onChangeFilter,
    setExpQueries,
  } = props;
  const [typeId, setTypeId] = useState(MASTER_SYSTEM_SEQ_ID);
  const [isOpenEditConfirm, setIsOpenEditConfirm] = useState(false);
  const [confirmData, setConfirmData] = useState<ConfirmTitleCodeData>();
  const [record, setRecord] = useState<ConfirmSubmitTitleCode>();
  const getNextId = useAutoIncrement('TCD');
  const { addNotification } = useContext(NotificationContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const { user } = useContext(AuthContext);
  const onClose = () => setOpen(false);

  const onConfirmSubmit: SubmitHandler<ConfirmSubmitTitleCode> = useCallback(
    async (data) => {
      // 重複チェック
      const duplicationResults = await duplicateDetection(
        'TITLE_CODE',
        VALIDATE_DUPLICATE_TITLE_CODE_FIELDS,
        data,
        clearErrors,
        setError
      );
      if (duplicationResults.every((result) => !result)) {
        // EditConfirmDialog用にChipsデータの書き換え
        const genre_ids = useConvertingArrayTextForConfirm(data.genre_ids, genres);
        const platform_ids = useConvertingArrayTextForConfirm(data.platform_ids, platforms);
        setConfirmData({ ...data, genre_ids, platform_ids });
        setRecord(data);
        setIsOpenEditConfirm(true);
      }
    },
    [genres, platforms, clearErrors, setError]
  );

  const onSubmit = async () => {
    try {
      // データをPOSTできるように整形
      const project_cd = record['project_cd'].map((v) => v.value);
      const wbs = record['wbs'].map((v) => v.value);
      const bn_connect_title_ids = record['bn_connect_title_ids'].map((v) => v.value);
      const bundle_source_title_code_ids = record['bundle_source_title_code_ids'].map((v) => v.value);
      const porting_source_title_code_ids = record['porting_source_title_code_ids'].map((v) => v.value);

      const seq_id = await getNextId();
      const formData: CreateTitleCodeInput = {
        ...record,
        seq_id,
        project_cd,
        wbs,
        bn_connect_title_ids,
        bundle_source_title_code_ids,
        porting_source_title_code_ids,
        valid_flg: false,
        admin_check_flg: false,
        delete_flg: false,
        updated_user_id: user.id,
        system_update_flg: false,
      };
      await API.graphql({ query: createTitleCode, variables: { input: formData } });
      setIsOpenEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'タイトルコードの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTitleCodes(condition, setCondition, onChangeFilter, setExpQueries);
      reset();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'タイトルコードの新規作成に失敗しました',
      });
      console.log(e);
    }
  };

  const options = useCallback(
    (name: string) => {
      switch (name) {
        case 'genre':
          return genres;
        case 'dataSource':
          return useRenameDataSource(dataSources);
        case 'platform':
          return platforms;
        case 'businessEntity':
          return businessEntity;
        default:
          return [];
      }
    },
    [dataSources, genres, platforms, businessEntity]
  );

  /** ArrayText系は特殊なのでRHFのモジュールを使って初期値をセット */
  const resetArrayTextValues = useCallback(() => {
    setValue('project_cd', [{ value: '' }]);
    setValue('wbs', [{ value: '' }]);
    setValue('bn_connect_title_ids', [{ value: '' }]);
    setValue('bundle_source_title_code_ids', [{ value: '' }]);
    setValue('porting_source_title_code_ids', [{ value: '' }]);
  }, [setValue]);

  // データソースの値が変更された場合に値をリセットする
  const watchId = watch('data_source_id')
    ? watch('data_source_id')
    : dataSources?.find((dataSource) => dataSource.name === 'own')?.id;
  useEffect(() => {
    if (watchId) {
      // DataSourceによってDisableにするFieldが動的に変更する
      const target = dataSources.find((dataSource) => dataSource.id === watchId);
      setTypeId(target.seq_id || MASTER_SYSTEM_SEQ_ID);

      // DataSourceが変わったらDataSource以外の値をリセット
      TITLE_CODE_RESET_VALUES.forEach((value) => resetField(value.field, value.value));
      resetArrayTextValues();
    }
  }, [watchId, dataSources, resetField, resetArrayTextValues]);

  useEffect(() => {
    resetArrayTextValues();
  }, [resetArrayTextValues]);

  /** 最大カラム数 */
  const maxColumnOrders = Math.max(...CREATE_TITLE_CODE_FIELDS.map((field) => field.column));

  return (
    <FormDialog
      open
      onCancel={onClose}
      title="タイトルコードの新規作成"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onConfirmSubmit)}
      height={600}
      width="xl"
    >
      <Box pl={3}>
        <FormRadioGroup
          content={options(CREATE_TITLE_CODE_FIELDS[0].optionsName)}
          field={CREATE_TITLE_CODE_FIELDS[0].field}
          control={control}
        />
      </Box>
      <Divider variant="middle" />
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => {
          return (
            <Box
              sx={{
                '& > :not(style)': { my: 1, mx: 1, width: 300 },
                display: 'flex',
                flexFlow: 'column wrap',
                height: 'inherit',
              }}
              width={330}
              key={i}
            >
              {CREATE_TITLE_CODE_FIELDS.slice(1).map((row) => {
                return (
                  row.column === i + 1 && (
                    <React.Fragment key={row.label}>
                      {row.type === 'text' && (
                        <FormTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          disabled={row.typeIds?.includes(typeId)}
                          errors={errors}
                          validationRules={classificationValidateTitleCode(row.field)}
                          required={row?.required}
                        />
                      )}
                      {row.type === 'arrayText' && (
                        <FormArrayTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          errors={errors}
                          validationRules={classificationValidateTitleCode(row.field)}
                        />
                      )}
                      {row.type === 'select' && row.optionsName && (
                        <FormSelectBox
                          label={row.label}
                          content={options(row.optionsName)}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                        />
                      )}
                      {row.type === 'chip' && (
                        // TitleCodeに完全移行され次第、FormSelectChip2を正式なFormSelectChipとして採用
                        <FormSelectChip2
                          label={row.label}
                          models={options(row.optionsName)}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                        />
                      )}
                      {row.type === 'checkbox' && (
                        <FormCheckBox field={row.field} label={row.label} control={control} />
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          );
        })}
      </Box>
      <EditConfirmDialog
        fields={CREATE_TITLE_CODE_FIELDS}
        onClose={() => setIsOpenEditConfirm(false)}
        onSubmit={onSubmit}
        open={isOpenEditConfirm}
        record={confirmData}
        schemaName="タイトルコード"
        genres={genres}
        dataSources={dataSources}
        businessEntity={businessEntity}
      />
    </FormDialog>
  );
}

export default NewTitleCodeDialog;
