import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormDialog from '../FormDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import { useAutoIncrement } from '../../hooks/useAutoIncrement';
import { CREATE_TAG_FIELDS, VALIDATE_DUPLICATE_TAG_FIELDS } from '../../consts/tag';
import { tagFields } from '../../types';
import { classificationValidateTag } from '../../hooks/classification';
import { User } from '../../models';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { NotificationContext } from '../../contexts/NotificationContext';
import { sleep } from '../../utils/fetchData';
import { createTagWithoutRelations } from '../../graphql/custom-mutations';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  setOpen: Function;
  user: User;
  isTag: boolean;
  fetchTags: Function;
};

type Record = {
  name_ja?: string;
  name_en?: string;
};

function NewTagDialog(props: Props) {
  const { open, setOpen, user, fetchTags, isTag } = props;
  const [editConfirm, setEditConfirm] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [record, setRecord] = useState<Record>({});
  const { addNotification } = useContext(NotificationContext);
  const getNextId = useAutoIncrement('TAG');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onPreSubmit: SubmitHandler<any> = async (data) => {
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('TAG', VALIDATE_DUPLICATE_TAG_FIELDS, data, clearErrors, setError);
    // 重複なければ登録
    if (results.every((result) => !result)) {
      setRecord(data);
      setEditConfirm(true);
    }
  };

  const onUpdate = async () => {
    try {
      setIsDisable(true);
      const seq_id = await getNextId();
      const formData = { ...record, seq_id, delete_flg: false, updated_user_id: user.id };
      await API.graphql({ query: createTagWithoutRelations, variables: { input: formData } });
      setEditConfirm(false);
      onClose();
      setIsDisable(false);
      reset();
      addNotification({
        type: 'success',
        message: 'IPタグの新規作成に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTags({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'IPタグの新規作成に失敗しました',
      });
      setEditConfirm(false);
      console.log(e);
    }
  };

  const onEditConfirmClose = () => {
    setEditConfirm(false);
  };

  const onSubmit = () => {
    onUpdate();
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="IPタグの新規作成"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={handleSubmit(onPreSubmit)}
      height={450}
      width="xs"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 280 },
        }}
        width={340}
      >
        {CREATE_TAG_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateTag(row.field as tagFields)}
                required={row?.required}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <EditConfirmDialog
        fields={CREATE_TAG_FIELDS}
        onClose={onEditConfirmClose}
        onSubmit={onSubmit}
        open={editConfirm}
        record={record}
        schemaName="IPタグ"
        disabledSubmit={isDisable}
      />
    </FormDialog>
  );
}

export default NewTagDialog;
