import { GridColDef } from '@mui/x-data-grid-pro';

export const filterColumnsForGeneralRole = (roleTypes: string[], columns: GridColDef[], targetColumns: string[]) => {
  let filterdColumns = columns
  const isAdminRole = roleTypes.includes("ADMIN")

  if (!isAdminRole) {
    targetColumns.forEach((targetColumn) => {
      filterdColumns = filterdColumns.filter(column => column.field != targetColumn)
    })
  }

  return filterdColumns
}