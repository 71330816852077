export const EDIT_CALENDAR_FIELDS = [
  {
    field: 'calendar_date',
    label: 'calendar_calendar-date',
    type: 'date',
    placeholder: '',
    required: true,
    disabled: true,
  },
  {
    field: 'year',
    label: 'calendar_year',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'month',
    label: 'calendar_month',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'day',
    label: 'calendar_day',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'day_of_week',
    label: 'calendar_day-of-week',
    type: 'text',
    placeholder: '',
    disabled: true,
  },
  {
    field: 'holiday_flg',
    label: 'calendar_holiday-flg',
    type: 'checkbox',
  },

  {
    field: 'name',
    label: 'calendar_name',
    type: 'text',
    placeholder: '日付名称を入力',
    disabled: false,
  },
  {
    field: 'country_id',
    label: 'calendar_country-id',
    type: 'select',
    placeholder: '国名を入力',
    disabled: false,
    optionsName: 'name',
  },
  {
    field: 'updated_user_id',
    label: 'calendar_final-updater',
    type: 'text',
    placeholder: '最終更新者を入力',
    disabled: true,
  },
  {
    field: 'updatedAt',
    label: 'calendar_updated-at',
    type: 'datetime',
    placeholder: '最終更新日時を入力',
    disabled: true,
  },
  {
    field: 'update_reason',
    label: 'country_update-reason',
    type: 'text',
    placeholder: '更新理由を入力',
  },
];

export const SEARCH_CALENDAR_FIELDS = [
  {
    field: 'calendar_date',
    label: '',
    type: 'date',
    placeholder: '日付を入力',
    inputs: [
      { field: 'calendar_date_from', label: 'calendar_calendar-date' },
      { field: 'calendar_date_to', label: 'calendar_calendar-date' },
    ],
  },
  {
    field: 'year',
    label: 'calendar_year',
    type: 'text',
    placeholder: '年を入力',
  },
  {
    field: 'month',
    label: 'calendar_month',
    type: 'text',
    placeholder: '月を入力',
  },
  {
    field: 'day',
    label: 'calendar_day',
    type: 'text',
    placeholder: '日を入力',
  },
  {
    field: 'day_of_week',
    label: 'calendar_day-of-week',
    type: 'select',
    placeholder: '曜日を選択',
    optionsName: 'dayOfWeek',
  },
  {
    field: 'holiday_flg',
    label: 'calendar_holiday-flg',
    type: 'select',
    placeholder: '祝日フラグを選択',
    optionsName: 'holidayFlag',
    isBlank: false,
  },
  {
    field: 'name',
    label: 'calendar_name',
    type: 'text',
    placeholder: '日付名称を入力',
  },
  {
    field: 'country_id',
    label: 'calendar_country-id',
    type: 'select',
    placeholder: '国名を入力',
    optionsName: 'country',
  },
  {
    field: 'update_reason',
    label: 'country_update-reason',
    type: 'text',
    placeholder: '更新理由を入力',
  },
  {
    field: 'updated_user_id',
    label: 'calendar_final-updater',
    type: 'text',
    placeholder: '最終更新者を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: 'calendar_updated-at' },
      { field: 'to', label: 'calendar_updated-at' },
    ],
  },
  {
    field: 'delete_flg',
    label: 'calendar_deleted-data',
    type: 'select',
    optionsName: 'delete_flg',
    isBlank: false,
    placeholder: '',
  },
];

export const SEARCH_CALENDAR_FIELDS_DEFAULT_VALUES = {
  calendar_date_from: null,
  calendar_date_to: null,
  year: '',
  month: '',
  day: '',
  day_of_week: '',
  holiday_flg: 'ALL',
  name: '',
  country_id: '',
  update_reason: '',
  updated_user_id: '',
  from: null,
  to: null,
  delete_flg: false,
};

export const DAY_OF_WEEK_OPTIONS = [
  { id: 'sun', name: 'Sun' },
  { id: 'mon', name: 'Mon' },
  { id: 'tue', name: 'Tue' },
  { id: 'wed', name: 'Wed' },
  { id: 'thu', name: 'Thu' },
  { id: 'fri', name: 'Fri' },
  { id: 'sat', name: 'Sat' },
];

export const HOLIDAY_FLG_OPTIONS = [
  { id: true, name: '祝日' },
  { id: false, name: '祝日以外' },
  { id: 'ALL', name: '全データ' },
];
