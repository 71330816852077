import React from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import { TitleHistory } from '../../models';
import { OPERATION_TYPE_OPTIONS } from '../../consts/common';
import { DETAIL_TITLE_HISTORY_FIELDS } from '../../consts/titleHistory';

type Props = {
  open: boolean;
  row: TitleHistory;
  setOpen: Function;
  setRow: Function;
}

function DetaiTitleHistoryDialog(props: Props) {
  const { open, row, setOpen, setRow } = props;
  const {
    formState: { errors },
    control,
  } = useForm();

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      default:
        return [];
    }
  };

  const isEditedValue = (field: keyof TitleHistory) => {
    if (field === 'data_source_name' || field === 'updated_user_id') return false
    const selectFields = ['genre_name']
    return selectFields.includes(field) ? row[(field.replace('name', 'id') as keyof TitleHistory)] !== row[(`old_${field.replace('name', 'id')}` as keyof TitleHistory)] : row[(field as keyof TitleHistory)] !== row[(`old_${field}` as keyof TitleHistory)]
  }

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="タイトル履歴の詳細"
      doText=""
      cancelText="キャンセル"
      height={570}
      width="lg"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 300 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 530,
        }}
        width={980}
      >
        {DETAIL_TITLE_HISTORY_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {(__row.type === 'text' || __row.type === 'textarea') && (
              <FormTextField
                label={__row.label}
                field={__row.field}
                control={control}
                disabled
                errors={errors}
                validationRules={[]}
                value={row[__row.field as keyof Pick<TitleHistory, 'seq_id' | 'name_ja' | 'name_en' | 'search_name' | 'genre_name' | 'sap_title_code' | 'update_reason' | 'data_source_name' | 'bn_connect_title_id' | 'banasuke_title_id' | 'ce_link_key_code' | 'updated_user_id'>]}
                isTextArea={__row.type === 'textarea'}
                isEdited={row.operation_type === 'EDIT' && isEditedValue((__row.field as keyof TitleHistory))}
              />
            )}
            {(__row.type === 'datetime' || __row.type === 'date') && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<TitleHistory, 'updatedAt' | 'deleted_at'>]}
                type={__row.type}
                disabled
                field={__row.field}
                control={control}
              />
            )}
            {__row.type === 'select' && __row.optionsName && (
              <FormSelectBox
                label={__row.label}
                content={options(__row.optionsName)}
                disabled
                defaultValue={row[__row.field as keyof Pick<TitleHistory, 'operation_type'>]}
                field={__row.field}
                control={control}
              />
            )}
            {__row.type === 'checkbox' && (
              <FormCheckBox
                field={__row.field}
                label={__row.label}
                control={control}
                defaultValue={row[__row.field as keyof Pick<TitleHistory, 'admin_check_flg' | 'delete_flg' | 'system_update_flg'>]}
                disabled
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormDialog>
  );
}

export default DetaiTitleHistoryDialog;
