import React, { useState, useEffect, useContext } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { theme } from '../assets/theme/options';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import { convertOperationType } from '../utils/converter';
import { AuthContext } from '../contexts/AuthContext';
import { useSearchTagBnamHistories } from '../hooks/useSearchTagBnamHistories';
import SearchTagBnamHistoryDialog from '../components/tagBnamHistory/SearchTagBnamHistoryDialog';


const columns: GridColDef[] = [
  {
    field: 'operation_type', headerName: '操作種別',
    width: 210,
    valueFormatter: ({ value }) => convertOperationType[value]
  },
  {
    field: 'bnam_id',
    headerName: 'BNAMマスターID',
    width: 190,
  },
  {
    field: 'ip_name',
    headerName: 'IP名称',
    width: 210,
  },
  { field: 'tag_name', headerName: 'IPタグ（日本語）', width: 210 },
  { field: 'tag_name_en', headerName: 'IPタグ（英語）', width: 210 },
  {
    field: 'updated_user_name',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => params.row.updated_user_name
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: ({ value }) => format(parseISO(value), 'yyyy/MM/dd HH:mm')
  },
];

function TagBnamHistoryList() {
  const [openSearch, setOpenSearch] = useState(false);
  const roleTypes = useContext(AuthContext).roles?.map((role) => role.role?.label);

  const { fetchTagBnamHistories, loading, total, pageSize, onChangePageSize, tagBnamHistories, setTagBnamHistories, onChangePage, condition, setCondition, page, onChangeSort } = useSearchTagBnamHistories()

  useEffect(() => {
    fetchTagBnamHistories({})
    // eslint-disable-next-line
  }, []);

  console.log({ tagBnamHistories })

  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} textAlign={'right'}>
        <PrimaryButton onClick={() => setOpenSearch(true)}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={tagBnamHistories}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .edited_cell': {
              background: theme.colors.editedCell
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openSearch && (
        <SearchTagBnamHistoryDialog
          condition={condition}
          isAdmin={roleTypes?.includes('ADMIN')}
          open={openSearch}
          setOpen={setOpenSearch}
          fetchTagBnamHistories={fetchTagBnamHistories}
        />
      )}
    </Box>
  );
}

export default TagBnamHistoryList;