import React, { useCallback, useState, useEffect, useContext } from 'react';
import { DataGridPro, GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { theme } from '../assets/theme/options';
import { format, parseISO } from 'date-fns';

import DetaiProductHistoryDialog from '../components/productHistories/DetailProductHistoryDialog';
import SearchProductHistoryDialog from '../components/productHistories/SearchProductHistoryDialog';
import PrimaryButton from '../components/PrimaryButton';
import {
  searchCurrencies,
  listDataSources,
  searchProductClasses,
  searchProductTypes,
  searchPlatforms
} from '../graphql/queries';
import { Currency, DataSource, Platform, ProductType, ProductClass, ProductHistory } from '../models';
import { convertOperationType } from '../utils/converter';
import { AuthContext } from '../contexts/AuthContext';
import { useEditedColumnClass, useIsDeletedOrRestoreColumn } from '../hooks/useEditedColumnClass';
import { sortByOrderIdAndCode } from '../utils/sortByOrderIdAndCode';
import { useSearchProductHistories } from '../hooks/useSearchProductHistories';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { filterColumnsForGeneralRole } from '../utils/filterColumns';
import { generateClient } from 'aws-amplify/api';
import { SearchablePlatformSortInput, SearchableProductClassSortInput, SearchableProductTypeSortInput } from '../API';
const API = generateClient();

const columns: GridColDef[] = [
  {
    field: 'operation_type',
    headerName: '操作種別',
    width: 210,
    valueFormatter: (params: any) => {
      return convertOperationType[params.value];
    }
  },
  { field: 'seq_id', headerName: 'プロダクトID', width: 210 },
  {
    field: 'product_key',
    headerName: 'プロダクトキー',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'name',
    headerName: 'プロダクト名',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'common_name',
    headerName: 'プロダクト共通名',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'title_name',
    headerName: 'タイトル名',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'product_type_name',
    headerName: 'プロダクト種別名',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'product_class_name',
    headerName: 'プロダクト区分名',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'platform_name',
    headerName: 'プラットフォーム名',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'base_price',
    headerName: 'ベースプライス',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'currency_name',
    headerName: '通貨',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'release_status',
    headerName: '配信ステータス',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  {
    field: 'info_release_date',
    headerName: '情報初出日',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'preorder_start_date',
    headerName: '予約開始日',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'release_date',
    headerName: '発売日',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'service_start_date',
    headerName: 'サービス開始日',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'service_end_date',
    headerName: 'サービス終了日',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'support_end_date',
    headerName: 'サポート終了日',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'sku_number_apple',
    headerName: 'sku_number_apple',
    width: 210
  },
  { field: 'package_name_google', headerName: 'package_name_google', width: 210 },
  {
    field: 'bank_app_id',
    headerName: 'bank_app_id',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  { field: 'data_source_name', headerName: 'データソース名', width: 210 },
  {
    field: 'system_update_flg',
    headerName: '自動更新フラグ',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
    renderCell: ({ value }) => {
      return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'admin_check_flg',
    headerName: '管理者チェック済フラグ',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params),
    renderCell: ({ value }) => {
      return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 210,
    cellClassName: (params: GridCellParams) => useIsDeletedOrRestoreColumn(params),
    renderCell: ({ value }) => {
      return value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'updated_reason',
    headerName: '更新理由',
    width: 210,
    cellClassName: (params: GridCellParams) => useEditedColumnClass(params)
  },
  { field: 'updated_user_name', headerName: '最終更新者', width: 210 },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: GridValueFormatterParams) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

function ProductHistoryList() {
  const [row, setRow] = useState<ProductHistory | null>(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [productClasses, setProductClasses] = useState<ProductClass[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const roleTypes = useContext(AuthContext).roles?.map((role) => role.role?.label);

  const {
    fetchProductHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    productHistories,
    onChangePage,
    page,
    onChangeSort,
    condition
  } = useSearchProductHistories();

  useEffect(() => {
    fetchProductHistories({});
    fetchDataSources();
    fetchCurrencies();
    fetchPlatforms();
    fetchProductClasses();
    fetchProductTypes();
    // eslint-disable-next-line
  }, []);

  // 一般ユーザの時は管理者チェックフラグのカラムを非表示にする
  const removeColumns = ['admin_check_flg'];
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns);

  const fetchDataSources = async () => {
    try {
      const models: any = await API.graphql({ query: listDataSources });
      // memo: searchクエリがないのでフロントでseq_idを利用してsort
      const sorted = (models.data as any).listDataSources.items.sort(
        (a: any, b: any) => a.seq_id.replace(/[^0-9^\.]/g, '') - b.seq_id.replace(/[^0-9^\.]/g, '')
      );
      setDataSources(sorted);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const models: any = await API.graphql({ query: searchCurrencies, variables: { limit: 500 } });
      const items = models?.data?.searchCurrencies?.items as Currency[];
      const result = sortByOrderIdAndCode<Currency>(items, 'code_three_char');
      setCurrencies(result);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const models: any = await API.graphql({
        query: searchPlatforms,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setPlatforms(models.data.searchPlatforms.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductClasses = async () => {
    try {
      const models: any = await API.graphql({
        query: searchProductClasses,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setProductClasses(models.data.searchProductClasses.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductTypes = async () => {
    try {
      const models: any = await API.graphql({
        query: searchProductTypes,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setProductTypes(models.data.searchProductTypes.items);
    } catch (e) {
      console.log(e);
    }
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  const doOpenDetail = (params: any) => {
    setRow(params.row);
    setOpenDetail(true);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} textAlign={'right'}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={productHistories}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onRowClick={doOpenDetail}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            },
            '& .edited_cell': {
              background: theme.colors.editedCell
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openSearch && (
        <SearchProductHistoryDialog
          condition={condition}
          currencies={currencies}
          dataSources={dataSources}
          isAdmin={roleTypes?.includes('ADMIN')}
          open={openSearch}
          platforms={platforms}
          productClasses={productClasses}
          productTypes={productTypes}
          setOpen={setOpenSearch}
          fetchProductHistories={fetchProductHistories}
        />
      )}
      {openDetail && row && (
        <DetaiProductHistoryDialog open={openDetail} row={row} setOpen={setOpenDetail} setRow={setRow} />
      )}
    </Box>
  );
}

export default ProductHistoryList;
