import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import { useSearchTitleCodesCsvJobs } from '../hooks/useSearchTitleCodesCsvImports';
import type { CsvJob } from '../API';
import { onCreateCsvJob, onUpdateCsvJob } from '../graphql/subscriptions';
import { convertCsvJobStatus } from '../utils/converter';
import DetailImportDialog from '../components/import/DetailImportDialog';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'インポート日時',
    width: 190,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  },
  {
    field: 'updated_by',
    headerName: 'ユーザー',
    width: 300,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    }
  },
  { field: 'name', headerName: 'ファイル名', width: 380 },
  {
    field: 'status',
    headerName: 'ステータス',
    width: 160,
    valueFormatter: (params: any) => {
      return convertCsvJobStatus[params.value];
    }
  },
  {
    field: 'message',
    headerName: 'メッセージ',
    width: 380
  },
  {
    field: 'error_log',
    headerName: 'エラーログ',
    width: 380
  }
];

function ImportTitleCodeList() {
  const {
    fetchCsvJobs,
    loading,
    total,
    pageSize,
    onChangePageSize,
    csvJobs,
    setCsvJobs,
    onChangePage,
    page,
    onChangeSort
  } = useSearchTitleCodesCsvJobs();
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState<CsvJob | null>(null);

  useEffect(() => {
    fetchCsvJobs({});
    subscribeOnCreate();
    subscribeOnUpdate();
    // eslint-disable-next-line
  }, []);

  const subscribeOnCreate = () => {
    const client = API.graphql({ query: onCreateCsvJob });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const csvJob = data.onCreateCsvJob;
          if (csvJob.table_name !== 'TITLE_CODE') return;
          setCsvJobs((prev) => [csvJob, ...prev]);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const subscribeOnUpdate = () => {
    const client = API.graphql({ query: onUpdateCsvJob });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const csvJob = data.onUpdateCsvJob;
          if (csvJob.table_name !== 'TITLE_CODE') return;
          setCsvJobs((csvJobs) => csvJobs.map((__csvJob) => (__csvJob.id === csvJob.id ? csvJob : __csvJob)));
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const doOpenDetail = (params: any) => {
    setRow(params.row);
    setOpen(true);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}></Box>
      <div style={{ height: 'calc(100vh - 130px)', width: '100%' }}>
        <DataGridPro
          rows={csvJobs}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          onRowClick={doOpenDetail}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            }
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {open && row && (
        <DetailImportDialog
          open={open}
          row={row}
          setOpen={setOpen}
          setRow={setRow}
          dialogTitle="タイトルコードCSVインポート状況の詳細"
        />
      )}
    </Box>
  );
}

export default ImportTitleCodeList;
