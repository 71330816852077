import React, { useCallback, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import PrimaryButton from '../components/PrimaryButton';

const StyledTitle = styled('div')(({ theme }) => ({
  background: theme.colors.defaultHeaderBg,
  height: 44,
  lineHeight: 2.7,
  padding: '0 20px',
}));

export interface SearchDialogProps {
  open: boolean;
  onCancel: Function;
  onSubmit?: Function;
  onReset?: Function;
  children: ReactNode;
  height?: any;
  width?: "xs" | "sm" | "md" | "lg" | "xl";
  isFullScreen?: boolean
  title?: string
}

function SearchDialog(props: SearchDialogProps) {
  const { open, onSubmit, onCancel, onReset, children, height, width, isFullScreen = false, title } = props;

  const doSubmit = useCallback(() => {
    onSubmit && onSubmit();
  }, [onSubmit]);

  const doCancel = () => {
    onCancel();
  };

  const doReset = useCallback(() => {
    onReset && onReset();
  }, [onReset]);

  return (
    <Dialog open={open} onClose={doCancel} maxWidth={width} fullScreen={isFullScreen}>
      <StyledTitle>
        <SearchIcon sx={{ display: 'inline-flex', verticalAlign: 'middle' }} />
        {title ? title : '検索'}
        <IconButton
          onClick={doCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 3,
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledTitle>
      <DialogContent
        sx={{
          height: height,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        {onReset && (
          <PrimaryButton variant="outlined" onClick={doReset}>
            リセット
          </PrimaryButton>
        )}
        {onSubmit && (
          <PrimaryButton variant="contained" onClick={doSubmit}>
            実行
          </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default SearchDialog;
