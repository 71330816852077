import React, { useContext, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import { Genre, User } from '../../models';
import { EDIT_GENRE_FIELDS, VALIDATE_DUPLICATE_GENRE_FIELDS } from '../../consts/genre';
import { genreFields } from '../../types';
import { classificationValidateGenre } from '../../hooks/classification';
import { getGenre, searchTitles } from '../../graphql/queries';
import { updateGenre } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { duplicateDetection } from '../../utils/duplicateDetection';
import { sleep } from '../../utils/fetchData';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  showDeleted: boolean;
  row: Genre;
  setOpen: Function;
  setRow: Function;
  user: User;
  fetchGenres: Function;
};

type Record = {
  name?: string;
  name_ja?: string;
  ce_link_id?: string;
};

function EditGenreDialog(props: Props) {
  const { open, row, setOpen, setRow, user, fetchGenres, showDeleted } = props;
  const [confirm, setConfirm] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isRel, setIsRel] = useState(false);
  const onUpdating = useRef(false); // 連打防止用フラグ
  const { checkAndRestore } = useRestore();
  const { addNotification } = useContext(NotificationContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    // 連打回避
    if (onUpdating.current) return;
    onUpdating.current = true;
    // バリデーションチェック（重複）
    let results: boolean[] = [];
    results = await duplicateDetection('GENRE', VALIDATE_DUPLICATE_GENRE_FIELDS, data, clearErrors, setError, row.id);
    // 重複なければ登録
    results.every((result) => !result) && onUpdate(data);
    // 連打回避解除
    if (onUpdating.current) {
      setTimeout(() => {
        onUpdating.current = false;
      }, 2000);
    }
  };

  const onUpdate = async (record: Record) => {
    try {
      const model: any = await API.graphql({ query: getGenre, variables: { id: row.id } });
      const genre = (model.data as any).getGenre;
      const formData = {
        id: row.id,
        name: record.name,
        name_ja: record.name_ja,
        ce_link_id: record.ce_link_id,
        _version: genre._version,
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateGenre, variables: { input: formData } });
      onClose();
      reset();
      addNotification({
        type: 'success',
        message: 'ジャンルの編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchGenres({ isShowDeleteFlg: showDeleted });
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'ジャンルの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete = async () => {
    try {
      const model: any = await API.graphql({ query: getGenre, variables: { id: row.id } });
      const genre = (model.data as any).getGenre;
      const formData = {
        id: row.id,
        _version: genre._version,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateGenre, variables: { input: formData } });
      closeConfirm();
      onClose();
      addNotification({
        type: 'success',
        message: 'ジャンルの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchGenres({ isShowDeleteFlg: showDeleted });
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'ジャンルの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const checkRelation = async () => {
    // タイトルと紐づいたジャンルがないか確認
    const isRelation: boolean | undefined = await checkTitleHasGenre();
    if (typeof isRelation !== 'boolean') {
      alert('ジャンルの削除に失敗しました。詳しくは管理者にお問合せください。');
    } else {
      setIsRel(isRelation);
      if (isRelation) {
        setAlertMsg(`このジャンルはタイトルと紐付け${'\n'}されているため削除できません`);
      } else {
        setAlertMsg(`このジャンルを削除しても${'\n'}よろしいでしょうか？`);
      }
      setConfirm(true);
    }
  };

  const checkTitleHasGenre = async () => {
    try {
      const models: any = await API.graphql({
        query: searchTitles,
        variables: {
          filter: { genre_id: { eq: row.id }, delete_flg: { ne: true } },
        },
      });
      return models.data.searchTitles.items.length > 0;
    } catch (e) {
      console.log(e);
    }
  };

  const restoreConfirm = () => {
    setAlertMsg(`このジャンルを復元しても${'\n'}よろしいでしょうか？`);
    setConfirm(true);
  };

  const onRestore = async () => {
    setConfirm(false);
    try {
      await checkAndRestore('genre', row);
      onClose();
      addNotification({
        type: 'success',
        message: 'ジャンルの復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      fetchGenres({ isShowDeleteFlg: showDeleted });
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'ジャンルの復元に失敗しました',
      });
      console.log(e);
    }
  };

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="ジャンルマスタの編集"
      doText="変更"
      cancelText="キャンセル"
      onSubmit={!row.delete_flg && handleSubmit(onSubmit)}
      onConfirm={row.delete_flg ? restoreConfirm : checkRelation}
      height={480}
      width="xs"
      isRestore={row.delete_flg}
    >
      <Box
        sx={{
          '& > :not(style)': { m: 2, width: 280 },
        }}
        width={340}
      >
        {EDIT_GENRE_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {__row.type === 'text' && (
              <FormTextField
                label={__row.label}
                placeholder={__row.placeholder}
                field={__row.field}
                control={control}
                disabled={row.delete_flg || __row.disabled}
                errors={errors}
                validationRules={classificationValidateGenre(__row.field as genreFields)}
                value={row[__row.field as keyof Pick<Genre, 'seq_id' | 'name' | 'ce_link_id' | 'updated_user_id'>]}
                required={row.delete_flg ? false : __row.required}
              />
            )}
            {__row.type === 'datetime' && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<Genre, 'updatedAt'>]}
                type={__row.type}
                disabled={row.delete_flg || __row.disabled}
                field={__row.field}
                control={control}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      {confirm && (
        <ConfirmDialog
          message={alertMsg}
          open={confirm}
          onCancel={isRel ? undefined : closeConfirm}
          onContinue={isRel ? closeConfirm : row.delete_flg ? onRestore : onDelete}
          type={isRel ? 'error' : 'warning'}
        />
      )}
    </FormDialog>
  );
}

export default EditGenreDialog;
