import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import PrimaryButton from '../components/PrimaryButton';
import { AuthContext } from '../contexts/AuthContext';
import SearchCountryDialog from '../components/country/SearchCountryDialog';
import { useSearchCountries } from '../hooks/useSearchCountries';

const columns: GridColDef[] = [
  {
    field: 'order_id',
    headerName: '表示順',
    width: 223,
  },
  {
    field: 'country_code',
    headerName: '国コード',
    width: 223,
  },
  {
    field: 'code_two_char',
    headerName: 'コード（2文字）',
    width: 223,
  },
  {
    field: 'code_three_char',
    headerName: 'コード（3文字）',
    width: 223,
  },
  {
    field: 'name',
    headerName: '国名（日本語）',
    width: 223,
  },
  {
    field: 'name_en',
    headerName: '国名（英語）',
    width: 223,
  },
  {
    field: 'update_reason',
    headerName: '更新理由',
    width: 223,
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 223,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function CountryManagement() {
  const [openSearch, setOpenSearch] = useState(false)
  const { user } = useContext(AuthContext);

  const { fetchCountries, loading, total, pageSize, onChangePageSize, countries, setCountries, onChangePage, page, onChangeSort, condition } = useSearchCountries()

  useEffect(() => {
    fetchCountries({})
    // eslint-disable-next-line
  }, []);

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'end' }}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 180px)', width: '100%' }}>
        <DataGridPro
          rows={countries}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {user && openSearch &&
        <SearchCountryDialog
          condition={condition}
          open={openSearch}
          setOpen={setOpenSearch}
          fetchCountries={fetchCountries}
        />}
    </Box >
  );
}

export default CountryManagement;
