export const DETAIL_BSP_HISTORY_FIELDS = [
  {
    field: 'operation_type',
    label: '操作種別',
    type: 'select',
    optionsName: 'operation_type',
    placeholder: '操作種別を選択',
    required: false,
    disabled: true,
  },
  {
    field: 'seq_id',
    label: 'BSPマスターID',
    type: 'text',
    placeholder: 'キャラクター名を入力',
    required: false,
    disabled: true,
  },
  {
    field: 'character_name',
    label: 'キャラクター名',
    type: 'text',
    placeholder: 'キャラクターコードを入力',
    required: false,
    disabled: true,
  },
  {
    field: 'character_code',
    label: 'キャラクターコード',
    type: 'text',
    placeholder: 'キャラクターシリーズネームを入力',
    required: false,
    disabled: true,
  },
  {
    field: 'character_series_name',
    label: 'キャラクターシリーズ名称',
    type: 'text',
    placeholder: 'キャラクターシリーズ名称を入力',
    required: false,
    disabled: true,
  },
  {
    field: 'character_series_code',
    label: 'キャラクターシリーズコード',
    type: 'text',
    placeholder: 'キャラクターシリーズコードを入力',
    required: false,
    disabled: true,
  },
  {
    field: 'updated_user_id',
    label: '最終更新者',
    type: 'text',
    placeholder: '最終更新者を入力',
    required: false,
    disabled: true,
  },
  {
    field: 'updatedAt',
    label: '最終更新日時',
    type: 'datetime',
    required: false,
    disabled: true,
  },
  {
    field: 'update_reason',
    label: '更新理由',
    type: 'textarea',
    placeholder: '更新理由を入力',
    required: false,
    disabled: true,
  },
  {
    field: 'system_update_flg',
    label: '自動更新フラグ',
    type: 'checkbox',
    required: false,
    disabled: true,
  },
  {
    field: 'delete_flg',
    label: '削除済データ',
    type: 'checkbox',
    optionsName: 'delete_flg',
    disabled: true,
  },
];

export const SEARCH_BSP_HISTORY_FIELDS = [
  {
    field: 'operation_type',
    label: '操作種別',
    type: 'select',
    placeholder: '操作種別を選択',
    optionsName: 'operation_type',
  },
  {
    field: 'seq_id',
    label: 'BSPマスターID',
    type: 'text',
    placeholder: 'BSPマスターIDを入力',
  },
  {
    field: 'character_name',
    label: 'キャラクター名',
    type: 'text',
    placeholder: 'キャラクター名を入力',
  },
  {
    field: 'character_code',
    label: 'キャラクターコード',
    type: 'text',
    placeholder: 'キャラクターコードを入力',
  },
  {
    field: 'character_series_name',
    label: 'キャラクターシリーズ名称',
    type: 'text',
    placeholder: 'キャラクターシリーズ名称を入力',
  },
  {
    field: 'character_series_code',
    label: 'キャラクターシリーズコード',
    type: 'text',
    placeholder: 'キャラクターシリーズコードを入力',
  },
  {
    field: 'updated_user_id',
    label: '最終更新者',
    type: 'text',
    placeholder: '最終更新者を入力',
  },
  {
    field: 'updatedAt',
    label: '',
    type: 'datetime',
    placeholder: '日付を入力',
    inputs: [
      { field: 'from', label: '最終更新日時' },
      { field: 'to', label: '最終更新日時' },
    ],
  },
  {
    field: 'update_reason',
    label: '更新理由',
    type: 'textarea',
    placeholder: '更新理由を入力',
  },
  {
    field: 'system_update_flg',
    label: '自動更新フラグ',
    type: 'select',
    optionsName: 'system_update_flg',
    isBlank: false,
  },
];

export const SEARCH_BSP_HISTORY_FIELDS_DEFAULT_VALUES = {
  operation_type: '',
  seq_id: '',
  character_name: '',
  character_code: '',
  character_series_name: '',
  character_series_code: '',
  updated_user_id: '',
  updatedAt_from: null,
  updatedAt_to: null,
  update_reason: '',
  system_update_flg: 'ALL',
};
