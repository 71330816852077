import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, Box, InputLabel } from '@mui/material';

import RequiredLabel from './RequiredLabel';
import createValidationRules from '../../hooks/createValidationRules';
import { validationRules } from '../../types';
import { I18n } from 'aws-amplify/utils';

interface FormAutocompleteProps {
  /** ラベル */
  label: string;
  /** デフォルト値 */
  defaultValue?: {};
  /** disabledかどうか */
  disabled?: boolean;
  /** field名 */
  field: string;
  /** ReactFookForm */
  control: any;
  errors?: any;
  /** 必須ラベルの有無 */
  required?: boolean;
  /** 選択肢 */
  options: {}[];
  /** プレースホルダー */
  placeholder?: string;
  /** バリデーションの内容 */
  validationRules: validationRules[] | string[];
  /** id */
  id?: string;
  /** 自由入力 */
  freeSolo?: boolean;
  /** 英語検索も含めるか */
  includeSearchEnglish?: boolean;
  isTitleCode?: boolean;
  isGroupCompany?: boolean;
}

function FormAutocomplete(props: FormAutocompleteProps) {
  const {
    label,
    defaultValue = null,
    disabled = false,
    field,
    control,
    freeSolo,
    errors,
    required,
    options,
    placeholder,
    id,
    includeSearchEnglish,
    isTitleCode,
    isGroupCompany,
  } = props;

  return (
    <Box position={'relative'}>
      {required && <RequiredLabel />}
      <InputLabel sx={{ fontSize: '12px', marginBottom: '2px' }}>{I18n.get(label)}</InputLabel>
      <Controller
        control={control}
        name={field}
        defaultValue={defaultValue}
        rules={createValidationRules(props.validationRules)}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            id={id}
            onChange={(_, item) => {
              onChange(item);
            }}
            freeSolo={freeSolo}
            disabled={disabled}
            value={value}
            options={options}
            getOptionLabel={(item) =>
              isGroupCompany
                ? item.group_company_code ?? ''
                : isTitleCode
                ? item.title_code
                : item.name_en && includeSearchEnglish
                ? `${item.name_ja}（${item.name_en}）`
                : item.name_ja ?? value
            }
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  <div>
                    {isGroupCompany
                      ? option.group_company_code ?? ''
                      : isTitleCode
                      ? option.title_code
                      : `${option.name_ja}${option.name_en && includeSearchEnglish ? `（${option.name_en}）` : ''}`}
                  </div>
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                error={!!errors[field]}
                helperText={errors[field]?.message || null}
                placeholder={placeholder}
              />
            )}
          />
        )}
      />
    </Box>
  );
}

export default FormAutocomplete;
