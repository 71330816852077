import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { inputType } from '../../types';
import FormDateTimePicker from './FormDateTimePicker';
import { I18n } from 'aws-amplify/utils';
interface FormDateTimeRangePickerProps {
  /** ラベル */
  fromLabel: string;
  toLabel: string;
  /** field名 */
  fromField: string;
  toField: string;
  /** DateTimePickerかDateTimePickerか */
  type: inputType;
  /** placeholder */
  placeholder?: string;
  /** ReactHookForm */
  control: any;
  watch: any;
  errors?: any;
}

function FormDateTimeRangePicker({
  fromLabel,
  toLabel,
  fromField,
  toField,
  type,
  placeholder,
  control,
  watch,
  errors,
}: FormDateTimeRangePickerProps) {
  const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);
  const [hasError, setHasError] = useState(false);
  const errorMessage = '日付形式が無効です';
  const fromDateFiled = watch(fromField as any);
  const toDateFiled = watch(toField as any);

  useEffect(() => {
    const isMinDateType = !isNaN(Date.parse(String(fromDateFiled)));
    const isMaxDateType = !isNaN(Date.parse(String(toDateFiled)));

    if (isMinDateType) {
      setMinDate(new Date(fromDateFiled));
    }

    if (isMaxDateType) {
      setMaxDate(new Date(toDateFiled));
    }
  }, [fromDateFiled, toDateFiled]);

  useEffect(() => {
    if (minDate === undefined || maxDate === undefined) return;
    if (minDate > maxDate) {
      setHasError(true);
      return;
    }
    setHasError(false);
  }, [minDate, maxDate]);

  return (
    <React.Fragment>
      <FormDateTimePicker
        label={I18n.get(fromLabel)}
        type={type}
        field={fromField}
        control={control}
        placeholder={placeholder}
        maxDate={maxDate}
        hasError={hasError}
        errorMessage={errorMessage}
        errors={errors}
      />
      <Box pt={3} px={2}>
        〜
      </Box>
      <FormDateTimePicker
        label={I18n.get(toLabel)}
        type={type}
        field={toField}
        control={control}
        placeholder={placeholder}
        minDate={minDate}
        hasError={hasError}
        errorMessage={errorMessage}
        errors={errors}
      />
    </React.Fragment>
  );
}

export default FormDateTimeRangePicker;
