import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Backdrop, Button } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import PrimaryButton from '../../components/PrimaryButton';
import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormArrayTextField from '../forms/FormArrayTextField';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import FormSelectChip2 from '../forms/FormSelectChip2';
import NewProductTcdDialog from '../productTcds/NewProductTcdDialog';

import { BANASUKE_SEQ_ID } from '../../consts/common';
import { classificationValidateTitle, classificationValidateTitleCode } from '../../hooks/classification';
import { getTitleCode, searchProductTcds, getProductTcd } from '../../graphql/queries';
import { deleteTagTitle, updateTitleCode, updateProductTcd } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { fetchTitleCodes, sleep } from '../../utils/fetchData';
import { EDIT_TITLE_CODE_FIELDS, VALIDATE_DUPLICATE_TITLE_CODE_FIELDS } from '../../consts/titleCode';
import { ConfirmSubmitTitleCode, ConfirmTitleCodeData, SearchConditionTitleCode } from '../../types/form/titleCode';
import { useConvertingArrayTextForConfirm } from '../../hooks/useConvertingArrayTextForConfirm';
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import {
  GetTitleCodeQuery,
  UpdateTitleCodeInput,
  SearchProductTcdsQuery,
  UpdateProductTcdInput,
  GetTagTitleCodeQuery,
  GetProductTcdQuery,
  DeleteTagTitleCodeInput,
  SearchableProductTcdFilterInput,
  SyncProductTcdsQueryVariables,
  SyncProductTcdsQuery,
  SearchProductTcdsQueryVariables,
} from '../../API';
import { createSearchParams, Link } from 'react-router-dom';
import { duplicateDetection } from '../../utils/duplicateDetection';

import type {
  TitleCode,
  DataSource,
  ProductTcd,
  Platform,
  ProductClass,
  ProductType,
  Currency,
  Genre,
  BusinessEntity,
} from '../../models';
import type { TagTitleCode } from '../../API';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  currencies: Currency[];
  dataSources: DataSource[];
  genres: Genre[];
  isAdmin: boolean;
  open: boolean;
  platforms: Platform[];
  businessEntity: BusinessEntity[];
  productClasses: ProductClass[];
  productTypes: ProductType[];
  selectedRow: TitleCode;
  setOpen: Function;
  updateUserId: string;
  condition: SearchConditionTitleCode;
  setCondition: Function;
  onChangeFilter: Function;
  setExpQueries: Function;
};

function EditTitleCodeDialog(props: Props) {
  const {
    currencies,
    dataSources,
    genres,
    isAdmin,
    open,
    platforms,
    businessEntity,
    productClasses,
    productTypes,
    selectedRow,
    setOpen,
    updateUserId,
    condition,
    setCondition,
    onChangeFilter,
    setExpQueries,
  } = props;
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isOpenEditConfirm, setIsOpenEditConfirm] = useState(false);
  const [confirmData, setConfirmData] = useState<ConfirmTitleCodeData>();
  const [record, setRecord] = useState<ConfirmSubmitTitleCode>();
  const [alertMsg, setAlertMsg] = useState('');
  const [productTcds, setProductTcds] = useState<ProductTcd[]>([]);
  const [tagTitleCode, setTagTitleCode] = useState<TagTitleCode | null>(null);
  const [openCreatePrdDialog, setOpenCreatePrdDialog] = useState(false);
  const [oldData, setOldData] = useState<ConfirmTitleCodeData>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);
  const { loading, checkAndRestore } = useRestore();
  const onClose = () => setOpen(false);

  const onConfirmSubmit: SubmitHandler<ConfirmSubmitTitleCode> = async (data) => {
    // 重複チェック
    const duplicationResults = await duplicateDetection(
      'TITLE_CODE',
      VALIDATE_DUPLICATE_TITLE_CODE_FIELDS,
      data,
      clearErrors,
      setError,
      selectedRow.id
    );
    if (duplicationResults.every((result) => !result)) {
      // EditConfirmDialog用にChipsデータのフォーマット
      const genre_ids = useConvertingArrayTextForConfirm(data.genre_ids, genres);
      const platform_ids = useConvertingArrayTextForConfirm(data.platform_ids, platforms);
      setConfirmData({ ...data, genre_ids, platform_ids });

      setRecord(data);
      setIsOpenEditConfirm(true);
    }
  };

  const onSubmit = async () => {
    try {
      const model = await API.graphql<GraphQLQuery<GetTitleCodeQuery>>({
        query: getTitleCode,
        variables: { id: selectedRow.id },
      });
      const { _version } = model.data.getTitleCode;
      // データをPOSTできるように整形
      const project_cd = record['project_cd'].map((v) => v.value);
      const wbs = record['wbs'].map((v) => v.value);
      const bn_connect_title_ids = record['bn_connect_title_ids'].map((v) => v.value);
      const bundle_source_title_code_ids = record['bundle_source_title_code_ids'].map((v) => v.value);
      const porting_source_title_code_ids = record['porting_source_title_code_ids'].map((v) => v.value);
      // updatedAtはDB側で更新するので含めない
      const { updatedAt, ...data } = record;
      const formData: UpdateTitleCodeInput = {
        ...data,
        id: selectedRow.id,
        project_cd,
        wbs,
        bn_connect_title_ids,
        bundle_source_title_code_ids,
        porting_source_title_code_ids,
        updated_user_id: updateUserId,
        _version,
        system_update_flg: false,
      };
      await API.graphql({ query: updateTitleCode, variables: { input: formData } });
      setIsOpenEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'タイトルコードの編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTitleCodes(condition, setCondition, onChangeFilter, setExpQueries);
      reset();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'タイトルコードの編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete: SubmitHandler<ConfirmSubmitTitleCode> = async (data) => {
    if (productTcds.length > 0) {
      await Promise.all(productTcds.map((productTcd) => deleteProductTcd(productTcd)));
    }
    if (tagTitleCode) await deleteTagTitleCode(tagTitleCode);
    await deleteTitleCode(data);
    setIsDeleteConfirm(false);
    onClose();
  };

  // 関連するプロダクトを削除
  const deleteProductTcd = async (productTcd: ProductTcd) => {
    try {
      const model = (await API.graphql<GraphQLQuery<GetProductTcdQuery>>({
        query: getProductTcd,
        variables: { id: productTcd.id },
      })) as GraphQLResult<GraphQLQuery<GetProductTcdQuery>>;
      const __productTcd = model.data.getProductTcd;
      const formData: UpdateProductTcdInput = {
        id: __productTcd.id,
        _version: __productTcd._version,
        delete_flg: true,
        deleted_at: new Date().toISOString(),
        updated_user_id: updateUserId,
      };
      await API.graphql({ query: updateProductTcd, variables: { input: formData } });
    } catch (e) {
      console.log(e);
    }
  };

  // 関連するタグタイトルを削除
  const deleteTagTitleCode = async (tagTitleCode: TagTitleCode) => {
    try {
      const formData: DeleteTagTitleCodeInput = {
        id: tagTitleCode.id,
        _version: tagTitleCode._version,
      };
      await API.graphql({ query: deleteTagTitle, variables: { input: formData } });
    } catch (e) {
      console.log(e);
    }
  };

  // 対象のタイトルを削除
  const deleteTitleCode = async (data: ConfirmSubmitTitleCode) => {
    try {
      const model = await API.graphql<GraphQLQuery<GetTitleCodeQuery>>({
        query: getTitleCode,
        variables: { id: selectedRow.id },
      });
      const titleCode = model.data.getTitleCode;
      const formData: UpdateTitleCodeInput = {
        id: selectedRow.id,
        update_reason: data.update_reason,
        _version: titleCode._version,
        delete_flg: true,
        system_update_flg: false,
        deleted_at: new Date().toISOString(),
        updated_user_id: updateUserId,
      };
      await API.graphql({ query: updateTitleCode, variables: { input: formData } });
      onClose();
      addNotification({
        type: 'success',
        message: 'タイトルコードの削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTitleCodes(condition, setCondition, onChangeFilter, setExpQueries);
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'タイトルコードの削除に失敗しました',
      });
      console.log(e);
    }
  };

  const checkRelation = async () => {
    // TCDプロダクトとの紐付け確認
    const filter: SearchProductTcdsQueryVariables = {
      filter: {
        title_code_id: { eq: selectedRow.id },
        delete_flg: { ne: true },
      },
    };
    const models = await API.graphql<GraphQLQuery<SearchProductTcdsQuery>>({
      query: searchProductTcds,
      variables: filter,
    });
    const productTcds = models.data.searchProductTcds.items as unknown as ProductTcd[];
    setProductTcds(productTcds);
    const isPrdTcdRel = models.data.searchProductTcds.items.length > 0;
    // タグタイトルとのリレーション確認
    const isTagRel = (selectedRow.tags as any).items.some((item: any) => {
      if (!item._deleted) setTagTitleCode(item);
      return !item._deleted;
    });

    classifyMsg(isPrdTcdRel, isTagRel);
    setIsDeleteConfirm(true);
  };

  const classifyMsg = (isPrdRel: boolean, isTagRel: boolean) => {
    if (!isPrdRel && !isTagRel) {
      setAlertMsg(`このタイトルコードを削除しても${'\n'}よろしいでしょうか？`);
    }
    if (isPrdRel && !isTagRel) {
      setAlertMsg(`このタイトルコードは紐づいている${'\n'}プロダクトがありますが${'\n'}削除してもよろしいでしょうか？`);
    }
    if (!isPrdRel && isTagRel) {
      setAlertMsg(`このタイトルコードはリレーションで${'\n'}タグ付けされてますが${'\n'}削除してもよろしいでしょうか？`);
    }
    if (isPrdRel && isTagRel) {
      setAlertMsg(
        `このタイトルコードは紐づいているプロダクトがあり${'\n'}リレーションでタグ付けされてますが${'\n'}削除してもよろしいでしょうか？`
      );
    }
  };

  const restoreConfirm = () => {
    setAlertMsg(`このタイトルコードを復元しても${'\n'}よろしいでしょうか？`);
    setIsDeleteConfirm(true);
  };

  const onRestore = async () => {
    try {
      await checkAndRestore('titleCode', selectedRow);
      onClose();
      addNotification({
        type: 'success',
        message: 'タイトルコードの復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchTitleCodes(condition, setCondition, onChangeFilter, setExpQueries);
      onClose();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'タイトルコードの復元に失敗しました',
      });
      console.log(e);
    }
    setIsDeleteConfirm(false);
  };

  const options = useCallback(
    (name: string) => {
      switch (name) {
        case 'genre':
          return genres;
        case 'dataSource':
          return dataSources;
        case 'platform':
          return platforms;
        case 'businessEntity':
          return businessEntity;
        default:
          return [];
      }
    },
    [dataSources, genres, platforms, businessEntity]
  );

  /** ArrayTextが特殊なので整形 */
  const formatArrayText = useCallback(
    (fieldName: string, contents: string[]) => {
      contents.length
        ? setValue(
            fieldName,
            contents.map((content) => ({ value: content }))
          )
        : setValue(fieldName, [{ value: '' }]);
    },
    [setValue]
  );
  useEffect(() => {
    formatArrayText('project_cd', selectedRow.project_cd);
    formatArrayText('wbs', selectedRow.wbs);
    formatArrayText('bn_connect_title_ids', selectedRow.bn_connect_title_ids);
    formatArrayText('bundle_source_title_code_ids', selectedRow.bundle_source_title_code_ids);
    formatArrayText('porting_source_title_code_ids', selectedRow.porting_source_title_code_ids);
  }, [
    selectedRow.project_cd,
    selectedRow.wbs,
    selectedRow.bn_connect_title_ids,
    selectedRow.bundle_source_title_code_ids,
    selectedRow.porting_source_title_code_ids,
    formatArrayText,
  ]);

  // EditConfirmDialogの変更箇所比較用にフォーマット
  useEffect(() => {
    const project_cd = selectedRow.project_cd.map((v) => ({ value: v }));
    const wbs = selectedRow.wbs.map((v) => ({ value: v }));
    const bn_connect_title_ids = selectedRow.bn_connect_title_ids.map((v) => ({
      value: v,
    }));
    const bundle_source_title_code_ids = selectedRow.bundle_source_title_code_ids.map((v) => ({ value: v }));
    const porting_source_title_code_ids = selectedRow.porting_source_title_code_ids.map((v) => ({ value: v }));
    const genre_ids = useConvertingArrayTextForConfirm(selectedRow.genre_ids, genres);
    const platform_ids = useConvertingArrayTextForConfirm(selectedRow.platform_ids, platforms);
    setOldData({
      ...selectedRow,
      genre_ids,
      platform_ids,
      project_cd,
      wbs,
      bn_connect_title_ids,
      bundle_source_title_code_ids,
      porting_source_title_code_ids,
    });
  }, [genres, platforms, selectedRow]);

  // 管理者のみadmin_check_flgを表示
  const defaultFields = isAdmin ? EDIT_TITLE_CODE_FIELDS : EDIT_TITLE_CODE_FIELDS.slice(0, -1);
  // 最大カラム数
  const maxColumnOrders = Math.max(...defaultFields.map((field) => field.column));
  // 選択したTitleCodeに紐づくProductTcdを取得するためのクエリを作成
  const productTcdPageQueryParams: string = createSearchParams({
    titleCodeId: selectedRow.id,
  }).toString();

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="タイトルコードの編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!selectedRow.delete_flg && handleSubmit(onConfirmSubmit)}
      onConfirm={selectedRow.delete_flg ? restoreConfirm : checkRelation}
      height={800}
      width="xl"
      isRestore={selectedRow.delete_flg}
    >
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => {
          return (
            <Box
              sx={{
                '& > :not(style)': { my: 1, mx: 1, width: 300 },
                display: 'flex',
                flexFlow: 'column wrap',
                height: 'inherit',
              }}
              width={330}
              key={i}
            >
              {defaultFields.map((row) => {
                return (
                  row.column === i + 1 && (
                    <React.Fragment key={row.field}>
                      {row.type === 'text' && (
                        <FormTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          disabled={
                            selectedRow.delete_flg ||
                            row.typeIds?.includes(selectedRow.data_source?.seq_id || '') ||
                            row.field === 'updated_user_id' ||
                            row.field === 'seq_id'
                          }
                          errors={errors}
                          validationRules={classificationValidateTitleCode(row.field)}
                          value={selectedRow[row.field] ? selectedRow[row.field].toString() : undefined}
                          required={selectedRow.delete_flg ? false : row.required}
                        />
                      )}
                      {row.type === 'datetime' && (
                        <FormDateTimePicker
                          label={row.label}
                          defaultValue={selectedRow[row.field].toString()}
                          type={row.type}
                          disabled
                          field={row.field}
                          control={control}
                        />
                      )}
                      {row.type === 'select' && row.optionsName && (
                        <FormSelectBox
                          label={row.label}
                          content={options(row.optionsName)}
                          disabled={selectedRow.delete_flg || row.field === 'data_source_id'}
                          defaultValue={selectedRow[row.field]?.toString()}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                        />
                      )}
                      {row.type === 'checkbox' && (
                        <FormCheckBox
                          field={row.field}
                          label={row.label}
                          control={control}
                          defaultValue={!!selectedRow[row.field]}
                          disabled={selectedRow.delete_flg}
                        />
                      )}
                      {row.type === 'arrayText' && (
                        <FormArrayTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          errors={errors}
                          validationRules={classificationValidateTitleCode(row.field)}
                          disabled={selectedRow.delete_flg}
                        />
                      )}
                      {row.type === 'chip' && (
                        <FormSelectChip2
                          label={row.label}
                          models={options(row.optionsName)}
                          field={row.field}
                          control={control}
                          defaultValue={
                            row.field === 'genre_ids' || row.field === 'platform_ids'
                              ? selectedRow[row.field]
                              : undefined
                          }
                          disabled={selectedRow.delete_flg}
                          placeholder={row.placeholder}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: '15px',
          left: '25px',
          display: 'flex',
        }}
      >
        {!selectedRow.delete_flg && (
          // 右クリック時に別タブ表示の選択肢を表示させるためにLinkを使用。
          // material-uiのButtonを使用しつつreact-router-domのstateを指定するためにcomponentにLinkを指定。
          <Button
            variant="contained"
            component={Link}
            to={`/product/tcds?${productTcdPageQueryParams}`}
            state={{
              titleCodeId: selectedRow.id,
              titleCodeName: selectedRow.title_code,
            }}
            sx={{ marginRight: '16px' }}
          >
            プロダクト一覧を表示
          </Button>
        )}
        {!selectedRow.delete_flg && selectedRow.data_source?.seq_id !== BANASUKE_SEQ_ID && (
          <PrimaryButton width="auto" onClick={() => setOpenCreatePrdDialog(true)}>
            プロダクトの新規作成
          </PrimaryButton>
        )}
      </Box>
      {isDeleteConfirm && (
        <ConfirmDialog
          message={alertMsg}
          open={isDeleteConfirm}
          onCancel={() => setIsDeleteConfirm(false)}
          onContinue={selectedRow.delete_flg ? onRestore : handleSubmit(onDelete)}
          updateReason={
            selectedRow.delete_flg ? (
              <></>
            ) : (
              <Box px={3} mb={1}>
                <FormTextField
                  label="更新理由"
                  placeholder="更新理由を入力"
                  value={selectedRow.update_reason?.toString() || ''}
                  field="update_reason"
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateTitle('update_reason')}
                />
              </Box>
            )
          }
        />
      )}
      {isOpenEditConfirm && (
        <EditConfirmDialog
          fields={defaultFields}
          onClose={() => setIsOpenEditConfirm(false)}
          onSubmit={onSubmit}
          open={isOpenEditConfirm}
          record={confirmData}
          schemaName="タイトルコード"
          genres={genres}
          dataSources={dataSources}
          businessEntity={businessEntity}
          beforeChangeValueData={oldData}
        />
      )}
      {selectedRow && openCreatePrdDialog && (
        <NewProductTcdDialog
          currencies={currencies}
          dataSource={dataSources}
          platforms={platforms}
          productClasses={productClasses}
          productTypes={productTypes}
          dialogCloseFunc={setOpenCreatePrdDialog}
          parentTitleData={selectedRow}
          updateUserId={updateUserId}
        />
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormDialog>
  );
}

export default EditTitleCodeDialog;
