import React from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import { ProductHistory } from '../../models';
import { OPERATION_TYPE_OPTIONS } from '../../consts/common'
import { DETAIL_PRODUCT_HISTORY_FIELDS } from '../../consts/productHistory';

type Props = {
  open: boolean;
  row: ProductHistory;
  setOpen: Function;
  setRow: Function;
}

function DetaiProductHistoryDialog(props: Props) {
  const { open, row, setOpen, setRow } = props;
  const {
    formState: { errors },
    control,
  } = useForm();

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      default:
        return [];
    }
  };

  const isEditedValue = (field: keyof ProductHistory) => {
    if (field === 'data_source_name' || field === 'updated_user_id') return false
    const selectFields = ['title_name', 'product_type_name', 'product_class_name', 'platform_name', 'currency_name']

    if (selectFields.includes(field)) {
      if (field === 'currency_name' && row.currency_id === "<empty>" && !row.old_currency_id) return false
      return row[(field.replace('name', 'id') as keyof ProductHistory)] !== row[(`old_${field.replace('name', 'id')}` as keyof ProductHistory)]
    } else {
      if (row[(field as keyof ProductHistory)] === "" && row[(`old_${field}` as keyof ProductHistory)] === null) return false
      return row[(field as keyof ProductHistory)] !== row[(`old_${field}` as keyof ProductHistory)]
    }
  }

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="プロダクト履歴の詳細"
      doText=""
      cancelText="キャンセル"
      height='inherit'
      isFullScreen
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 330 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 'inherit',
        }}
      >
        {DETAIL_PRODUCT_HISTORY_FIELDS.map((__row, idx) => {
          return (
            <React.Fragment key={idx}>
              {(__row.type === 'text' || __row.type === 'textarea') && (
                <FormTextField
                  label={__row.label}
                  field={__row.field}
                  control={control}
                  disabled
                  errors={errors}
                  validationRules={[]}
                  value={row[__row.field as keyof Pick<ProductHistory, 'seq_id' | 'product_key' | 'name' | 'common_name' | 'title_name' | 'product_type_name' | 'product_class_name' | 'market' | 'platform_name' | 'data_source_name' | 'base_price' | 'currency_name' | 'release_status' | 'sku_number_apple' | 'app_id' | 'package_name_google' | 'bank_app_id' | 'updated_reason' | 'updated_user_id'>]}
                  isTextArea={__row.type === 'textarea'}
                  isEdited={row.operation_type === 'EDIT' && isEditedValue((__row.field as keyof ProductHistory))}
                />
              )}
              {(__row.type === 'datetime' || __row.type === 'date') && (
                <FormDateTimePicker
                  label={__row.label}
                  defaultValue={row[__row.field as keyof Pick<ProductHistory, 'info_release_date' | 'preorder_start_date' | 'release_date' | 'service_start_date' | 'service_end_date' | 'support_end_date' | 'updatedAt' | 'deleted_at'>]}
                  type={__row.type}
                  disabled
                  field={__row.field}
                  control={control}
                  isEdited={row.operation_type === 'EDIT' && isEditedValue((__row.field as keyof ProductHistory))}
                />
              )}
              {__row.type === 'select' && __row.optionsName && (
                <FormSelectBox
                  label={__row.label}
                  content={options(__row.optionsName)}
                  disabled
                  defaultValue={row[__row.field as keyof Pick<ProductHistory, 'operation_type'>]}
                  field={__row.field}
                  control={control}
                />
              )}
              {__row.type === 'checkbox' && (
                <FormCheckBox
                  field={__row.field}
                  label={__row.label}
                  control={control}
                  defaultValue={row[__row.field as keyof Pick<ProductHistory, 'admin_check_flg' | 'delete_flg'>]}
                  disabled
                />
              )}
            </React.Fragment>
          )
        })}
      </Box>
    </FormDialog>
  );
}

export default DetaiProductHistoryDialog;
