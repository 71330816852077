export const CREATE_PRODUCT_TYPE_FIELDS = [
  {
    field: "name",
    label: "プロダクト種別名称",
    type: "text",
    placeholder: "プロダクト種別名称を入力",
    required: true,
  },
  {
    field: "name_ja",
    label: "プロダクト種別名称(日本語)",
    type: "text",
    placeholder: "プロダクト種別名称(日本語)を入力",
    required: false,
  },
];

export const EDIT_PRODUCT_TYPE_FIELDS = [
  {
    field: "seq_id",
    label: "ID",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "name",
    label: "プロダクト種別名称",
    type: "text",
    placeholder: "プロダクト種別名称を入力",
    required: true,
  },
  {
    field: "name_ja",
    label: "プロダクト種別名称(日本語)",
    type: "text",
    placeholder: "プロダクト種別名称(日本語)を入力",
    required: false,
  },
  {
    field: "updated_user_id",
    label: "最終更新者",
    type: "text",
    placeholder: "",
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "最終更新日時",
    type: "datetime",
    placeholder: "",
    disabled: true,
  },
];

export const VALIDATE_DUPLICATE_PRODUCT_TYPE_FIELDS = ["name"];
