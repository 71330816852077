import React, { useState, useEffect, ReactNode, createContext } from 'react';
import { I18N_VOCABULARIES } from '../assets/i18n/vocabularies';
import { LOCAL_STORAGE_LANGUAGE } from '../consts/common';
import { I18n } from 'aws-amplify/utils';

/** 言語切り替えが出てきた時に有効にする初期表示処理
 * １：ローカルストレージに言語がセットされている場合はそれを利用
 * ２：ない場合はブラウザ言語を利用
 */
I18n.putVocabularies(I18N_VOCABULARIES);
I18n.setLanguage(localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || window.navigator.language);
interface ContextInterface {
  language: string;
  changeLanguage: (language: any) => void;
}
interface Props {
  children: ReactNode;
}

const defaultState = {
  language: localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || window.navigator.language,
  changeLanguage: (lang: string) => {},
};

const LanguageContext = React.createContext<ContextInterface>(defaultState);

function LanguageProvider(props: Props) {
  const [language, setLanguage] = useState(defaultState.language);
  const changeLanguage = (lang: string) => {
    console.log(lang);
    I18n.setLanguage(lang);
    setLanguage(lang);
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE, lang);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
}

export { LanguageContext, LanguageProvider };
