import { validationRules, validationRulesContent } from '../types';

const createValidationRules = (v: validationRules[] | string[]) => {
  const validationRules: validationRulesContent = new Object();
  v.includes('required') && (validationRules.required = '未入力です');
  v.includes('tagRequired') && (validationRules.required = 'タグ名が未入力です');
  v.includes('maxlen2') && (validationRules.maxLength = { value: 2, message: '2文字以内で入力してください' });
  v.includes('maxlen3') && (validationRules.maxLength = { value: 3, message: '3文字以内で入力してください' });
  v.includes('maxlen4') && (validationRules.maxLength = { value: 4, message: '4文字以内で入力してください' });
  v.includes('maxlen6') && (validationRules.maxLength = { value: 6, message: '6文字以内で入力してください' });
  v.includes('maxlen7') && (validationRules.maxLength = { value: 7, message: '7文字以内で入力してください' });
  v.includes('maxlen8') && (validationRules.maxLength = { value: 8, message: '8文字以内で入力してください' });
  v.includes('maxlen10') && (validationRules.maxLength = { value: 10, message: '10文字以内で入力してください' });
  v.includes('maxlen16') && (validationRules.maxLength = { value: 16, message: '16文字以内で入力してください' });
  v.includes('maxlen20') && (validationRules.maxLength = { value: 20, message: '20文字以内で入力してください' });
  v.includes('maxlen32') && (validationRules.maxLength = { value: 32, message: '32文字以内で入力してください' });
  v.includes('maxlen64') && (validationRules.maxLength = { value: 64, message: '64文字以内で入力してください' });
  v.includes('maxlen128') && (validationRules.maxLength = { value: 128, message: '128文字以内で入力してください' });
  v.includes('maxlen256') && (validationRules.maxLength = { value: 256, message: '256文字以内で入力してください' });
  v.includes('maxlen512') && (validationRules.maxLength = { value: 512, message: '512文字以内で入力してください' });
  v.includes('maxlen1024') && (validationRules.maxLength = { value: 1024, message: '1024文字以内で入力してください' });
  v.includes('number') &&
    (validationRules.pattern = {
      value: /^([1-9][0-9]{0,5}|0)(\.[0-9]{1,2})?$/,
      message: '半角数字かつ小数点第二位までで入力してください',
    });
  v.includes('integer') &&
    (validationRules.pattern = {
      value: /^[0-9]+$/,
      message: '半角数字で入力してください',
    });
  v.includes('alphanumeric') &&
    (validationRules.pattern = {
      value: /^[a-zA-Z0-9]+$/,
      message: '半角英数字で入力してください',
    });
  v.includes('alphanumericAndSymbol') &&
    (validationRules.pattern = {
      value: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
      message: '半角英数字記号で入力してください',
    });
  v.includes('alphanumericAndSymbolAndSpaceAndFullWidthSpace') &&
    (validationRules.pattern = {
      // 空白は許可するがタブや改行は許可しないため\sは使用不可
      // @ts-ignore  //@TODO
      value: /^[a-zA-Z0-9!-/:-@¥[-`{-~ \u3000]*$/u,
      message: '半角英数字記号で入力してください',
    });
  v.includes('invalidTabAndNewLine') &&
    (validationRules.pattern = {
      value: /^[^\t\n\r\f]*$/,
      message: '特殊文字（改行、TAB）が含まれています',
    });
  v.includes('minnum') && (validationRules.min = { value: 0, message: '入力最小数は0です' });
  v.includes('maxnum') && (validationRules.max = { value: 99999, message: '入力最大数は99999です' });
  v.includes('dateMaxnum') && (validationRules.max = { value: 31, message: '入力最大数は31です' });
  v.includes('monthMaxnum') && (validationRules.max = { value: 12, message: '入力最大数は12です' });

  return validationRules;
};

export default createValidationRules;
