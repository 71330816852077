import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import { BusinessEntity } from '../models';
import NewBusinessEntityDialog from '../components/businessEntity/NewBusinessEntityDialog';
import PrimaryButton from '../components/PrimaryButton';
import { AuthContext } from '../contexts/AuthContext';
import { useSearchBusinessEntities } from '../hooks/useSearchBusinessEntities';
import EditBusinessEntityDialog from '../components/businessEntity/EditBusinessEntityDialog';

const columns: GridColDef[] = [
  {
    field: 'seq_id',
    headerName: 'ID',
    width: 223,
  },
  {
    field: 'name',
    headerName: '事業体名',
    width: 223,
  },
  {
    field: 'description',
    headerName: '概要',
    width: 223,
  },
  {
    field: 'updated_user_id',
    headerName: '最終更新者',
    width: 223,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    },
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 223,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
  {
    field: 'delete_flg',
    headerName: '削除フラグ',
    width: 223,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
];

function BusinessEntityManagement() {
  const [row, setRow] = useState<BusinessEntity | null>(null);
  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const { user } = useContext(AuthContext);

  const { fetchBusinessEntities, loading, total, pageSize, onChangePageSize, businessEntities, onChangePage, page, onChangeSort, onChangeShowDeleted, showDeleted } = useSearchBusinessEntities()

  useEffect(() => {
    fetchBusinessEntities({})
    // eslint-disable-next-line
  }, []);

  const doOpenNew = useCallback(() => {
    setOpenNew(true);
  }, []);

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
        <PrimaryButton onClick={doOpenNew}>新規作成</PrimaryButton>
        <FormControlLabel
          control={<Checkbox onChange={onChangeShowDeleted} checked={showDeleted} />}
          label="削除済みデータを表示"
        />
      </Box>
      <div style={{ height: 'calc(100vh - 180px)', width: '100%' }}>
        <DataGridPro
          rows={businessEntities}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {user && openNew && <NewBusinessEntityDialog open={openNew} setOpen={setOpenNew} user={user} fetchBusinessEntities={fetchBusinessEntities} showDeleted={showDeleted} />}
      {row && user && openEdit && <EditBusinessEntityDialog open={openEdit} row={row} setOpen={setOpenEdit} setRow={setRow} user={user} fetchBusinessEntities={fetchBusinessEntities} showDeleted={showDeleted} />}
    </Box>
  );
}

export default BusinessEntityManagement;
