import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { convertOperationType } from '../utils/converter';
import { format, parseISO } from 'date-fns';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { SEARCH_BNF_ADMIN_FIELDS, SEARCH_BNF_FIELDS_DEFAULT_VALUES } from './bnf';
import { Bnf } from '../models';
import { useEditedColumnClass } from '../hooks/useEditedColumnClass';

type BNF_HISTORY_FIELDS = {
  field: keyof Bnf | "operation_type"
  label: string
  type: string
  optionsName?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  isBlank?: boolean
  inputs?: { field: string, label: string }[],
}[]

export const BNF_HISTORY_COLMNS: GridColDef[] = [
  {
    field: 'operation_type', headerName: '操作種別',
    width: 210,
    valueFormatter: (params) => convertOperationType[params.value]
  },
  { field: 'seq_id', headerName: 'BNFマスターID', width: 210 },
  { field: 'bnf_data_source', headerName: 'BNFデータソース', width: 210, cellClassName: (params) => useEditedColumnClass(params) },
  { field: 'obic7_commodity_cd', headerName: 'OBIC7商品コード', width: 210, cellClassName: (params) => useEditedColumnClass(params) },
  { field: 'obic7_commodity_name', headerName: 'OBIC7商品名', width: 210, cellClassName: (params) => useEditedColumnClass(params) },
  { field: 'bvics_plan_cd_4char', headerName: 'BVICS企画コード(4桁)', width: 210, cellClassName: (params) => useEditedColumnClass(params) },
  { field: 'bvics_plan_cd_7char', headerName: 'BVICS企画コード(7桁)', width: 210, cellClassName: (params) => useEditedColumnClass(params) },
  { field: 'bvics_product_name', headerName: 'BVICS製品名', width: 210, cellClassName: (params) => useEditedColumnClass(params) },
  {
    field: 'admin_check_flg',
    headerName: '管理者チェックフラグ',
    width: 210,
    renderCell: (params: GridCellParams) => params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />,
    cellClassName: (params) => useEditedColumnClass(params)
  },
  {
    field: 'delete_flg',
    headerName: '削除フラグ',
    width: 210,
    renderCell: (params: GridCellParams) => params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />,
    cellClassName: (params) => useEditedColumnClass(params)
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params) => format(parseISO(params.value), 'yyyy/MM/dd HH:mm')
  },
];

export const SEARCH_BNF_HISTORY_FIELDS: BNF_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "select",
    optionsName: "operation_type",
    placeholder: "操作種別を選択",
    required: false,
    disabled: true,
  },
  ...SEARCH_BNF_ADMIN_FIELDS
]

export const DETAIL_BNF_HISTORY_FIELDS: BNF_HISTORY_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "select",
    optionsName: "operation_type",
    disabled: true,
  },
  ...SEARCH_BNF_ADMIN_FIELDS.map(field => {
    switch (field.field) {
      case "updatedAt":
        return { ...field, label: "最終更新日時", disabled: true }
      case "admin_check_flg":
      case "delete_flg":
        return { ...field, type: "checkbox", disabled: true }
      default:
        return { ...field, disabled: true }
    }
  })
]

export const SEARCH_BNF_HISTORIES_FIELDS_DEFAULT_VALUES = { operation_type: "", ...SEARCH_BNF_FIELDS_DEFAULT_VALUES }