import React, { useState, useEffect, ReactNode, createContext } from 'react';
import { is_localhost } from '../utils/app';
import { is_maintenance_available } from '../utils/app';
import Maintenance from "../pages/Maintenance";
import { useCredentials } from '../hooks/useCredentials';

const localhost: boolean = is_localhost();
interface ContextInterface {
  maintenance: boolean;
}
interface Props {
  children: ReactNode;
}

const defaultState = {
  maintenance: localhost ? true : false,
};

const MaintenanceContext = React.createContext<ContextInterface>(defaultState);

function MaintenanceProvider(props: Props) {
  const [maintenance, setMaintenance] = useState(defaultState.maintenance);
  useCredentials();

  useEffect(() => {
    const getMaintenance = async () => {
      if (!is_maintenance_available()) {
        setMaintenance(false);
        return;
      }
      else {
        console.log("AAAAAAAA");
        try {
          console.log("BBBBBBB");
          const rslt = await fetch('maintenance.json?t=' + (new Date()).getTime())
          console.log("CCCCCCCCCC");
          console.log(await rslt.clone().json());
          setMaintenance(await rslt.clone().json());

        } catch (err) {
          console.log("EEEEEEEEEE2");
          console.log(err);
          setMaintenance(false);
        }
      }
    }
    getMaintenance()
  }, [])

  return (
    <MaintenanceContext.Provider
      value={{
        maintenance,
      }}
    >
      {maintenance && <Maintenance />}
      {!maintenance && props.children}
    </MaintenanceContext.Provider>
  );
}

export { MaintenanceContext, MaintenanceProvider };
