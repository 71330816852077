export const CREATE_RATE_FIELDS = [
  {
    field: "source_currency_id",
    label: "rate_source-currency-id",
    type: "select",
    placeholder: "換算前通貨を入力",
    required: true,
    optionsName: "currency",
  },
  {
    field: "converted_currency_id",
    label: "rate_converted-currency-id",
    type: "select",
    placeholder: "換算後通貨を入力",
    required: true,
    optionsName: "currency",
  },
  {
    field: "from_date",
    label: "rate_from-date",
    type: "date",
    placeholder: "有効開始日を入力",
    required: true,
  },
  {
    field: "to_date",
    label: "rate_to-date",
    type: "date",
    placeholder: "有効終了日を入力",
    required: true,
  },
  {
    field: "exchange_rate",
    label: "rate_exchange-rate",
    type: "text",
    placeholder: "換算レートを入力",
    required: true,
  },
  {
    field: "company_name",
    label: "rate_company-name",
    type: "text",
    placeholder: "会社名を入力",
    required: true,
  },
  {
    field: "usage",
    label: "rate_usage",
    type: "text",
    placeholder: "用途を入力",
  },
  {
    field: "description",
    label: "rate_description",
    type: "text",
    placeholder: "説明を入力",
  },
  {
    field: "exchange_rate_type",
    label: "rate_exchange-rate-type",
    type: "text",
    placeholder: "換算レート typeを入力",
    required: true,
  },
  {
    field: "update_reason",
    label: "rate_update-reason",
    type: "text",
    placeholder: "更新理由を入力",
  },
];

export const EDIT_RATE_FIELDS = [
  {
    field: "seq_id",
    label: "rate_seq-id",
    type: "text",
    placeholder: "レートコードを入力",
    required: true,
    disabled: true,
  },
  {
    field: "source_currency_id",
    label: "rate_source-currency-id",
    type: "select",
    placeholder: "換算前通貨を入力",
    required: true,
    optionsName: "currency",
  },
  {
    field: "converted_currency_id",
    label: "rate_converted-currency-id",
    type: "select",
    placeholder: "換算後通貨を入力",
    required: true,
    optionsName: "currency",
  },
  {
    field: "from_date",
    label: "rate_from-date",
    type: "date",
    placeholder: "有効開始日を入力",
    required: true,
  },
  {
    field: "to_date",
    label: "rate_to-date",
    type: "date",
    placeholder: "有効終了日を入力",
    required: true,
  },
  {
    field: "exchange_rate",
    label: "rate_exchange-rate",
    type: "text",
    placeholder: "換算レートを入力",
    required: true,
  },
  {
    field: "company_name",
    label: "rate_company-name",
    type: "text",
    placeholder: "会社名を入力",
    required: true,
  },
  {
    field: "usage",
    label: "rate_usage",
    type: "text",
    placeholder: "用途を入力",
  },
  {
    field: "description",
    label: "rate_description",
    type: "text",
    placeholder: "説明を入力",
  },
  {
    field: "exchange_rate_type",
    label: "rate_exchange-rate-type",
    type: "text",
    placeholder: "換算レート typeを入力",
    required: true,
  },
  {
    field: "updated_user_id",
    label: "rate_updated-user-id",
    type: "text",
    placeholder: "最終更新者を入力",
    disabled: true,
  },
  {
    field: "updatedAt",
    label: "rate_updated-at",
    type: "datetime",
    placeholder: "最終更新日時を入力",
    disabled: true,
  },
  {
    field: "update_reason",
    label: "rate_update-reason",
    type: "text",
    placeholder: "更新理由を入力",
  },
];

export const SEARCH_RATE_FIELDS = [
  {
    field: "seq_id",
    label: "rate_seq-id",
    type: "text",
    placeholder: "レートコードを入力",
  },
  {
    field: "source_currency_id",
    label: "rate_source-currency-id",
    type: "select",
    placeholder: "換算前通貨を入力",
    optionsName: "currency",
  },
  {
    field: "converted_currency_id",
    label: "rate_converted-currency-id",
    type: "select",
    placeholder: "換算後通貨を入力",
    optionsName: "currency",
  },
  {
    field: "from_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "rate_from-date" },
      { field: "to", label: "rate_from-date" },
    ],
  },
  {
    field: "to_date",
    label: "",
    type: "date",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "rate_to-date" },
      { field: "to", label: "rate_to-date" },
    ],
  },
  {
    field: "exchange_rate",
    label: "rate_exchange-rate",
    type: "text",
    placeholder: "換算レートを入力",
  },
  {
    field: "company_name",
    label: "rate_company-name",
    type: "text",
    placeholder: "会社名を入力",
  },
  {
    field: "usage",
    label: "rate_usage",
    type: "text",
    placeholder: "用途を入力",
  },
  {
    field: "description",
    label: "rate_description",
    type: "text",
    placeholder: "説明を入力",
  },
  {
    field: "exchange_rate_type",
    label: "rate_exchange-rate-type",
    type: "text",
    placeholder: "換算レート typeを入力",
  },
  {
    field: "updated_user_id",
    label: "rate_updated-user-id",
    type: "text",
    placeholder: "最終更新者を入力",
  },
  {
    field: "updatedAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "rate_updated-at" },
      { field: "to", label: "rate_updated-at" },
    ],
  },
  {
    field: "update_reason",
    label: "rate_update-reason",
    type: "text",
    placeholder: "更新理由を入力",
  },
  {
    field: "delete_flg",
    label: "rate_deleted-data",
    type: "select",
    optionsName: "delete_flg",
    isBlank: false,
  },
];

export const SEARCH_RATE_FIELDS_DEFAULT_VALUES = {
  seq_id: "",
  source_currency_id: "",
  converted_currency_id: "",
  from_date_from: null,
  from_date_to: null,
  to_date_from: null,
  to_date_to: null,
  exchange_rate: null,
  company_name: "",
  usage: "",
  description: "",
  exchange_rate_type: "",
  updated_user_id: "",
  updatedAt_from: null,
  updatedAt_to: null,
  update_reason: "",
  delete_flg: false,
};
