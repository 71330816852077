import React, { useCallback, useState, useEffect, useContext } from 'react';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { theme } from '../assets/theme/options';
import { useLocation, useSearchParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import EditProductDialog from '../components/products/EditProductDialog';
import SearchProductDialog from '../components/products/SearchProductDialog';
import PrimaryButton from '../components/PrimaryButton';
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import {
  searchTitles,
  searchCurrencies,
  listDataSources,
  searchProductClasses,
  searchProductTypes,
  searchPlatforms
} from '../graphql/queries';
import { Product, Title, Currency, DataSource, Platform, ProductType, ProductClass } from '../models';
import { onCreateProduct } from '../graphql/subscriptions';
import { AuthContext } from '../contexts/AuthContext';
import { INITIAL_EXP_QUERIES_STATE } from '../consts/export';
import { SEARCH_PRODUCT_FIELDS_DEFAULT_VALUES } from '../consts/product';
import { ProductSearch } from '../types/form/product';
import usePageSize from '../hooks/usePageSize';
import { useSearchProducts } from '../hooks/useSearchProducts';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import { sortByOrderIdAndCode } from '../utils/sortByOrderIdAndCode';
import { filterColumnsForGeneralRole } from '../utils/filterColumns';
import { generateClient } from 'aws-amplify/api';
import {
  SearchablePlatformSortInput,
  SearchableProductClassSortInput,
  SearchableProductTypeSortInput,
  SearchableTitleSortInput
} from '../API';
const API = generateClient();

const columns: GridColDef[] = [
  { field: 'seq_id', headerName: 'プロダクトID', width: 190 },
  { field: 'product_key', headerName: 'プロダクトキー', width: 210 },
  {
    field: 'title_id',
    headerName: 'タイトルID',
    width: 190,
    valueGetter: (params: any) => {
      return params.row?.title?.seq_id;
    }
  },
  {
    field: 'title',
    headerName: 'タイトル名',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value?.name_ja;
    }
  },
  { field: 'name', headerName: 'プロダクト名', width: 210 },
  { field: 'common_name', headerName: 'プロダクト共通名', width: 210 },
  {
    field: 'product_type',
    headerName: 'プロダクト種別名',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value?.name;
    }
  },
  {
    field: 'product_class',
    headerName: 'プロダクト区分名',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value?.name;
    }
  },
  {
    field: 'platform',
    headerName: 'プラットフォーム名',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value?.name;
    }
  },
  {
    field: 'market',
    headerName: 'マーケット',
    width: 210
  },
  {
    field: 'data_source',
    headerName: 'データソース名',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value?.name;
    }
  },
  // MEMO: base_price: Floatだからsortできないっぽいので画面上でソートできないようにする
  { field: 'base_price', headerName: 'ベースプライス', width: 210, sortable: false },
  {
    field: 'currency',
    headerName: '通貨',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value?.code_three_char;
    }
  },
  {
    field: 'release_status',
    headerName: '配信ステータス',
    width: 210
  },
  {
    field: 'info_release_date',
    headerName: '情報初出日',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'preorder_start_date',
    headerName: '予約開始日',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'release_date',
    headerName: '発売日',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'service_start_date',
    headerName: 'サービス開始日',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'service_end_date',
    headerName: 'サービス終了日',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  {
    field: 'support_end_date',
    headerName: 'サポート終了日',
    width: 210,
    valueFormatter: (params: any) => {
      return params.value && format(parseISO(params.value), 'yyyy/MM/dd');
    }
  },
  { field: 'sku_number_apple', headerName: 'sku_number_apple', width: 210 },
  { field: 'app_id', headerName: 'App_ID', width: 210 },
  { field: 'package_name_google', headerName: 'package_name_google', width: 210 },
  { field: 'bank_app_id', headerName: 'bank_app_id', width: 210 },
  {
    field: 'system_update_flg',
    headerName: '自動更新フラグ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'admin_check_flg',
    headerName: '管理者チェック済フラグ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    }
  },
  {
    field: 'updated_by',
    headerName: '最終更新者',
    width: 210,
    valueGetter: (params) => {
      return params.row.updated_by?.name;
    }
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    }
  }
];

function ProductList() {
  const [titles, setTitles] = useState<Title[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [productClasses, setProductClasses] = useState<ProductClass[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [row, setRow] = useState<Product | null>(null);
  const [condition, setCondition] = useState<ProductSearch>(SEARCH_PRODUCT_FIELDS_DEFAULT_VALUES);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [expQueries, setExpQueries] = useState(INITIAL_EXP_QUERIES_STATE);
  const [isUploaded, setIsUploaded] = useState(false);

  const { pageSize } = usePageSize();
  const {
    searchPrds,
    onChangePageSize,
    onChangePage,
    onChangeSort,
    onChangeFilter,
    setRows,
    total,
    page,
    loading,
    rows
  } = useSearchProducts();
  const { user, roles } = useContext(AuthContext);

  const roleTypes = roles?.map((role) => role.role?.label);

  const [searchParams] = useSearchParams();
  const titleId = searchParams.get('titleId');
  const location = useLocation();
  const locationState = location?.state as null | { titleId: string; titleName: string };
  const titleName = locationState?.titleName;

  useEffect(() => {
    !titleId && searchPrds({});
    fetchTitles();
    fetchDataSources();
    fetchCurrencies();
    fetchPlatforms();
    fetchProductClasses();
    fetchProductTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!titleId) return;
    searchProductsFromTitleList(titleId);
    subscribeOnCreatePrd(titleId);
    setCondition((prev) => ({ ...prev, title_name: titleName }));
    // eslint-disable-next-line
  }, [titleId]);

  useEffect(() => {
    if (!isUploaded) return;
    if (titleId) {
      searchProductsFromTitleList(titleId);
    } else {
      searchPrds({});
    }
    setIsUploaded(false);
    // eslint-disable-next-line
  }, [titleId, isUploaded]);

  // 一般ユーザの時は削除済データのカラムを非表示にする
  const removeColumns = ['delete_flg'];
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns);

  const subscribeOnCreatePrd = (titleId: string) => {
    const client = API.graphql({ query: onCreateProduct });
    if ('subscribe' in client) {
      const subscription = client.subscribe({
        next: ({ data }: any) => {
          const product = data.onCreateProduct;
          titleId === product.title_id && setRows((prev) => [product, ...prev]);
        },
        error: (error) => console.error(error)
      });
      return () => subscription.unsubscribe();
    }
  };

  const fetchTitles = async () => {
    let items = [];
    let hasNextPage = true;
    let nextToken = null;
    while (hasNextPage) {
      try {
        const models: any = await API.graphql({
          query: searchTitles,
          variables: {
            filter: { delete_flg: { ne: true } },
            //@ts-ignore
            sort: { direction: 'asc', field: 'name_ja' },
            limit: 650,
            nextToken: nextToken
          }
        });
        items.push(...models.data.searchTitles.items);
        nextToken = models.data.searchTitles.nextToken;
        if (!nextToken) {
          hasNextPage = false;
        }
      } catch (e) {
        console.error(e);
      }
    }
    setTitles(items);
  };

  const fetchDataSources = async () => {
    try {
      const models: any = await API.graphql({ query: listDataSources });
      // memo: searchクエリがないのでフロントでseq_idを利用してsort
      const sorted = models.data.listDataSources.items.sort(
        (a: any, b: any) => a.seq_id.replace(/[^0-9^\.]/g, '') - b.seq_id.replace(/[^0-9^\.]/g, '')
      );
      setDataSources(sorted);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const models: any = await API.graphql({ query: searchCurrencies, variables: { limit: 500 } });
      const items = models?.data?.searchCurrencies?.items as Currency[];
      const result = sortByOrderIdAndCode<Currency>(items, 'code_three_char');
      setCurrencies(result);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const models: any = await API.graphql({
        query: searchPlatforms,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setPlatforms(models.data.searchPlatforms.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductClasses = async () => {
    try {
      const models: any = await API.graphql({
        query: searchProductClasses,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setProductClasses(models.data.searchProductClasses.items);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductTypes = async () => {
    try {
      const models: any = await API.graphql({
        query: searchProductTypes,
        variables: {
          filter: { delete_flg: { ne: true } },
          //@ts-ignore
          sort: { direction: 'asc', field: 'seq_id' }
        }
      });
      setProductTypes(models.data.searchProductTypes.items);
    } catch (e) {
      console.log(e);
    }
  };

  const searchProductsFromTitleList = (titleId: string) => {
    const queries = [
      { name: 'title_id', value: titleId, type: SEARCH_TYPE.EQ },
      { name: 'delete_flg', value: true, type: SEARCH_TYPE.NE },
      { name: 'admin_check_flg', value: true, type: SEARCH_TYPE.EQ }
    ];
    const { filter } = generateSearchFilter(queries);
    onChangeFilter(filter);
  };

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true);
  }, []);

  if (!roleTypes) return <></>;
  console.log({ rows });
  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
          <ExportButton
            condition={titleId ? [...expQueries, { name: 'title_id', type: 'EQ', value: titleId }] : expQueries}
            tableName="PRODUCT"
            type="SEARCH"
          />
          <ImportButton tableName="PRODUCT" setIsUploaded={setIsUploaded} />
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>
      <div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
        <DataGridPro
          columns={filterdColumns}
          disableColumnFilter
          disableSelectionOnClick
          hideFooterPagination={loading}
          loading={loading}
          onRowClick={doOpenEdit}
          onPageChange={onChangePage}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onSortModelChange={onChangeSort}
          page={page}
          pageSize={pageSize}
          pagination
          paginationMode="server"
          rowCount={total || 0}
          rows={rows}
          rowsPerPageOptions={[50, 100, 150]}
          sortingMode="server"
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            }
          }}
        />
      </div>
      {row && user && (
        <EditProductDialog
          currencies={currencies}
          dataSources={dataSources}
          isAdmin={roleTypes?.includes('ADMIN')}
          platforms={platforms}
          productClasses={productClasses}
          productTypes={productTypes}
          open={openEdit}
          row={row}
          setOpen={setOpenEdit}
          setRow={setRow}
          titles={titles}
          user={user}
          condition={condition}
          setCondition={setCondition}
          onChangeFilter={onChangeFilter}
          setExpQueries={setExpQueries}
        />
      )}
      {openSearch && (
        <SearchProductDialog
          condition={condition}
          currencies={currencies}
          dataSources={dataSources}
          isAdmin={roleTypes?.includes('ADMIN')}
          platforms={platforms}
          productClasses={productClasses}
          productTypes={productTypes}
          onChangeFilter={onChangeFilter}
          open={openSearch}
          setCondition={setCondition}
          setExpQueries={setExpQueries}
          setOpen={setOpenSearch}
          titleName={titleName}
        />
      )}
    </Box>
  );
}

export default ProductList;
