export const EDIT_DATALAKEJOB_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "text",
    placeholder: "操作種別を入力",
    required: false,
    disabled: true,
  },
  {
    field: "status",
    label: "実行結果",
    type: "text",
    placeholder: "実行結果を入力",
    required: false,
    disabled: false,
  },
  {
    field: "message",
    label: "メッセージ",
    type: "text",
    placeholder: "メッセージを入力",
    required: false,
    disabled: false,
  },
  {
    field: "error_log",
    label: "エラーログ",
    type: "text",
    placeholder: "エラーログを入力",
    required: true,
    disabled: false,
  },
  {
    field: "createdAt",
    label: "実行日時",
    type: "datetime",
    required: false,
    disabled: true,
  },
];

export const SEARCH_DATALAKEJOB_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "text",
    placeholder: "操作種別を入力",
    required: false,
    disabled: false,
  },
  {
    field: "status",
    label: "実行結果",
    type: "text",
    placeholder: "実行結果を入力",
    required: false,
    disabled: false,
  },
  {
    field: "message",
    label: "メッセージ",
    type: "text",
    placeholder: "メッセージを入力",
    required: false,
    disabled: false,
  },
  {
    field: "error_log",
    label: "エラーログ",
    type: "text",
    placeholder: "エラーログを入力",
    required: false,
    disabled: false,
  },
  {
    field: "createdAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "title_final-update-date" },
      { field: "to", label: "title_final-update-date" },
    ],
  },
];

export const SEARCH_DATALAKEJOB_ADMIN_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "text",
    placeholder: "操作種別を入力",
    required: false,
    disabled: false,
  },
  {
    field: "status",
    label: "実行結果",
    type: "text",
    placeholder: "実行結果を入力",
    required: false,
    disabled: false,
  },
  {
    field: "message",
    label: "メッセージ",
    type: "text",
    placeholder: "メッセージを入力",
    required: false,
    disabled: false,
  },
  {
    field: "error_log",
    label: "エラーログ",
    type: "text",
    placeholder: "エラーログを入力",
    required: false,
    disabled: false,
  },
  {
    field: "createdAt",
    label: "",
    type: "datetime",
    placeholder: "日付を入力",
    inputs: [
      { field: "from", label: "title_final-update-date" },
      { field: "to", label: "title_final-update-date" },
    ],
  },
];

export const CREATE_DATALAKEJOB_FIELDS = [
  {
    field: "operation_type",
    label: "操作種別",
    type: "text",
    placeholder: "操作種別を入力",
    required: false,
    disabled: true,
  },
  {
    field: "status",
    label: "実行結果",
    type: "text",
    placeholder: "実行結果を入力",
    required: false,
    disabled: false,
  },
  {
    field: "message",
    label: "メッセージ",
    type: "text",
    placeholder: "メッセージを入力",
    required: false,
    disabled: false,
  },
  {
    field: "error_log",
    label: "エラーログ",
    type: "text",
    placeholder: "エラーログを入力",
    required: true,
    disabled: false,
  },
  {
    field: "createdAt",
    label: "実行日時",
    type: "datetime",
    required: false,
    disabled: true,
  },
];

export const SEARCH_DATALAKEJOB_FIELDS_DEFAULT_VALUES = {
  operation_type: "",
  status: "",
  message: "",
  error_log: "",
  createdAt: null,
};
