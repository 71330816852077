import React from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import { TitleCodeHistory } from '../../models';
import { OPERATION_TYPE_OPTIONS } from '../../consts/common';
import { DETAIL_TITLE_CODE_HISTORY_FIELDS } from '../../consts/titleCodeHistory';

type Props = {
  open: boolean;
  row: TitleCodeHistory;
  setOpen: Function;
  setRow: Function;
}

function DetaiTitleCodeHistoryDialog(props: Props) {
  const { open, row, setOpen, setRow } = props;
  const {
    formState: { errors },
    control,
  } = useForm();

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      default:
        return [];
    }
  };

  const isEditedValue = (field: keyof TitleCodeHistory) => {
    if (field === 'data_source_name' || field === 'updated_user_id') return false
    const selectFields = ['genre_name', 'business_entity_name']
    return selectFields.includes(field) ? row[(field.replace('name', 'id') as keyof TitleCodeHistory)] !== row[(`old_${field.replace('name', 'id')}` as keyof TitleCodeHistory)] : row[(field as keyof TitleCodeHistory)] !== row[(`old_${field}` as keyof TitleCodeHistory)]
  }

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="タイトルコード履歴の詳細"
      doText=""
      cancelText="キャンセル"
      height='inherit'
      isFullScreen
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 335 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 'inherit',
        }}
      >
        {DETAIL_TITLE_CODE_HISTORY_FIELDS.map((__row, idx) => (
          <React.Fragment key={idx}>
            {(__row.type === 'text' || __row.type === 'textarea') && (
              <FormTextField
                label={__row.label}
                field={__row.field}
                control={control}
                disabled
                errors={errors}
                validationRules={[]}
                value={row[__row.field as keyof Pick<TitleCodeHistory,
                  "operation_type"
                  | "seq_id"
                  | "title_code"
                  | "name_ja"
                  | "name_en"
                  | "sap_title_code_name"
                  | "search_name"
                  | "project_cd"
                  | "wbs"
                  | "bn_connect_title_ids"
                  | "ce_link_key_code"
                  | "ce_link_title_name_ja"
                  | "ce_link_title_name_en"
                  | "genre_names"
                  | "banasuke_title_id"
                  | "banasuke_title_name_ja"
                  | "bundle_source_title_code_ids"
                  | "porting_source_title_code_ids"
                  | "platform_ids"
                  | "business_entity_id"
                  | "data_source_name"
                  | "update_reason"
                  | "updated_user_id"
                >]}
                isTextArea={__row.type === 'textarea'}
                isEdited={row.operation_type === 'EDIT' && isEditedValue((__row.field as keyof TitleCodeHistory))}
              />
            )}
            {(__row.type === 'datetime' || __row.type === 'date') && (
              <FormDateTimePicker
                label={__row.label}
                defaultValue={row[__row.field as keyof Pick<TitleCodeHistory, 'updatedAt' | 'deleted_at'>]}
                type={__row.type}
                disabled
                field={__row.field}
                control={control}
              />
            )}
            {__row.type === 'select' && __row.optionsName && (
              <FormSelectBox
                label={__row.label}
                content={options(__row.optionsName)}
                disabled
                defaultValue={row[__row.field as keyof Pick<TitleCodeHistory, 'operation_type'>]}
                field={__row.field}
                control={control}
              />
            )}
            {__row.type === 'checkbox' && (
              <FormCheckBox
                field={__row.field}
                label={__row.label}
                control={control}
                defaultValue={row[__row.field as keyof Pick<TitleCodeHistory, 'admin_check_flg' | 'delete_flg' | 'system_update_flg' | 'official_name_flg'>]}
                disabled
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </FormDialog>
  );
}

export default DetaiTitleCodeHistoryDialog;
