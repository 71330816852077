import { useState } from 'react';

import usePageSize from './usePageSize';

import { searchTitleCodes } from '../graphql/queries';
import { TitleCode } from '../models';
import { generateClient } from 'aws-amplify/api';
import { SearchableTitleCodeSortInput } from '../API';
const API = generateClient();

interface StringKeyObj {
  [key: string]: string;
}

export const useSearchTitleCodes = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<{}>({
    delete_flg: { ne: true },
    admin_check_flg: { eq: true }
  });
  const [sort, setSort] = useState<{}>({
    direction: 'desc',
    field: 'updatedAt'
  });
  const { pageSize, setPageSize } = usePageSize();

  const searchTitleCodesFetch = async ({
    nextToken,
    newLimit,
    newFilter,
    newSort
  }: {
    nextToken?: string | null;
    newLimit?: number;
    newFilter?: {};
    newSort?: {};
  }) => {
    setLoading(true);
    try {
      const models: any = await API.graphql({
        query: searchTitleCodes,
        variables: {
          filter: newFilter ?? filter,
          //@ts-ignore
          sort: newSort ?? sort,
          limit: newLimit ?? pageSize,
          nextToken: nextToken ?? null
        }
      });

      setTotal(models.data.searchTitleCodes.total);
      if (newLimit || newFilter || newSort) {
        setNextTokens([models.data.searchTitleCodes.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchTitleCodes.nextToken])));
      }
      setRows(models.data.searchTitleCodes.items);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    searchTitleCodesFetch({ newLimit });
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    searchTitleCodesFetch({ nextToken });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      searchTitleCodesFetch({
        newSort: { direction: 'desc', field: 'updatedAt' }
      });
    } else {
      if (sortModel[0].field === 'tags') {
        return;
      }
      const newSort = {
        direction: sortModel[0].sort,
        field: field2searchField[sortModel[0].field]
      };
      setSort({ ...newSort });
      searchTitleCodesFetch({ newSort });
    }
  };

  const onChangeFilter = (newFilter: {}) => {
    setFilter(newFilter);
    searchTitleCodesFetch({ newFilter });
  };

  const field2searchField: StringKeyObj = {
    seq_id: 'seq_id',
    tags: 'tag_id',
    title_code: 'title_code',
    name_ja: 'name_ja',
    name_en: 'name_en',
    official_name_flg: 'official_name_flg',
    sap_title_code_name: 'sap_title_code_name',
    search_name: 'search_name',
    project_cd: 'project_cd',
    wbs: 'wbs',
    bn_connect_title_ids: 'bn_connect_title_ids',
    ce_link_key_code: 'ce_link_key_code',
    ce_link_title_name_ja: 'ce_link_title_name_ja',
    ce_link_title_name_en: 'ce_link_title_name_en',
    genre_ids: 'genre_ids',
    banasuke_title_id: 'banasuke_title_id',
    banasuke_title_name_ja: 'banasuke_title_name_ja',
    bundle_source_title_code_ids: 'bundle_source_title_code_ids',
    porting_source_title_code_ids: 'porting_source_title_code_ids',
    platform_ids: 'platform_ids',
    valid_flg: 'valid_flg',
    business_entity: 'business_entity_id',
    data_source_name: 'data_source_id',
    system_update_flg: 'system_update_flg',
    admin_check_flg: 'admin_check_flg',
    delete_flg: 'delete_flg',
    updated_by: 'updated_by',
    updatedAt: 'updatedAt'
  };

  return {
    searchTitleCodesFetch,
    onChangePageSize,
    onChangePage,
    onChangeSort,
    onChangeFilter,
    setRows,
    loading,
    total,
    pageSize,
    rows,
    page
  };
};
