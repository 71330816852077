import React, { useCallback, useState, useEffect, useContext } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { AuthContext } from '../contexts/AuthContext';
import ExecuteDatalakeButton from '../components/ExecuteDatalakeButton';
import { theme } from '../assets/theme/options';
import { useSearchDataLakeJobs } from '../hooks/useSearchDataLakeJobs';



const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: '実行日時',
    width: 210,
    valueFormatter: (params) => format(parseISO(params.value), 'yyyy/MM/dd HH:mm')
  },
  { field: 'status', headerName: '実行結果', width: 190 },
];

function DatalakeManagement() {
  const { roles } = useContext(AuthContext);
  const { fetchDatalakeJobs, loading, total, pageSize, onChangePageSize, datalakeJobs, setDatalakeJobs, onChangePage, condition, page, onChangeSort, searchFilter } = useSearchDataLakeJobs()

  const roleTypes = roles?.map((role) => role.role?.label);

  useEffect(() => {
    fetchDatalakeJobs({})
    // eslint-disable-next-line
  }, []);

  if (!roleTypes) return <></>;
  return (
    <Box sx={{ p: 1 }}>
      <Box my={theme.spacing(2)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1, width: 800 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
            <ExecuteDatalakeButton
            />
          </Box>
        </Box>
      </Box>

      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={datalakeJobs}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
    </Box>
  );
}

export default DatalakeManagement;
