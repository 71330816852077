import { useState } from 'react';
import { searchTitleCodeHistories } from '../graphql/queries';
import usePageSize from './usePageSize';
import { Country } from '../models';
import { generateSearchFilter, SEARCH_TYPE } from '../utils/search';
import { SEARCH_TITLE_CODE_HISTORY_FIELDS_DEFAULT_VALUES } from '../consts/titleCodeHistory';
import { TitleCodeHistorySearch } from '../types/form/titleCodeHistory';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

const defaultSort = [{ direction: 'desc', field: 'updatedAt' }];

type StringKeyObj = {
  [key: string]: { name: string; type: string };
};

const dataToFilterObj: StringKeyObj = {
  operation_type: { name: 'operation_type', type: SEARCH_TYPE.EQ },
  seq_id: { name: 'seq_id', type: SEARCH_TYPE.WILDCARD },
  title_code: { name: 'title_code', type: SEARCH_TYPE.WILDCARD },
  sap_title_code_name: { name: 'sap_title_code_name', type: SEARCH_TYPE.WILDCARD },
  bundle_source_title_code_ids: { name: 'bundle_source_title_code_ids', type: SEARCH_TYPE.WILDCARD },
  wbs: { name: 'wbs', type: SEARCH_TYPE.WILDCARD },
  data_source_name: { name: 'data_source_name', type: SEARCH_TYPE.WILDCARD },
  business_entity_id: { name: 'business_entity_id', type: SEARCH_TYPE.WILDCARD },
  platform_ids: { name: 'platform_ids', type: SEARCH_TYPE.UUID_WILDCARD },
  porting_source_title_code_ids: { name: 'porting_source_title_code_ids', type: SEARCH_TYPE.WILDCARD },
  banasuke_title_name_ja: { name: 'banasuke_title_name_ja', type: SEARCH_TYPE.WILDCARD },
  genre_ids: { name: 'genre_ids', type: SEARCH_TYPE.UUID_WILDCARD },
  ce_link_title_name_en: { name: 'ce_link_title_name_en', type: SEARCH_TYPE.WILDCARD },
  ce_link_title_name_ja: { name: 'ce_link_title_name_ja', type: SEARCH_TYPE.WILDCARD },
  bn_connect_title_ids: { name: 'bn_connect_title_ids', type: SEARCH_TYPE.WILDCARD },
  name_ja: { name: 'name_ja', type: SEARCH_TYPE.WILDCARD },
  name_en: { name: 'name_en', type: SEARCH_TYPE.WILDCARD },
  search_name: { name: 'search_name', type: SEARCH_TYPE.WILDCARD },
  genre_id: { name: 'genre_id', type: SEARCH_TYPE.WILDCARD },
  update_reason: { name: 'update_reason', type: SEARCH_TYPE.WILDCARD },
  project_cd: { name: 'project_cd', type: SEARCH_TYPE.WILDCARD },
  sap_title_code: { name: 'sap_title_code', type: SEARCH_TYPE.WILDCARD },
  data_source_id: { name: 'data_source_id', type: SEARCH_TYPE.WILDCARD },
  bn_connect_title_id: { name: 'bn_connect_title_id', type: SEARCH_TYPE.WILDCARD },
  banasuke_title_id: { name: 'banasuke_title_id', type: SEARCH_TYPE.WILDCARD },
  ce_link_key_code: { name: 'ce_link_key_code', type: SEARCH_TYPE.WILDCARD },
  updated_user_id: { name: 'updated_user_id', type: SEARCH_TYPE.UUID_WILDCARD },
  from: { name: 'updatedAt', type: SEARCH_TYPE.GTE },
  to: { name: 'updatedAt', type: SEARCH_TYPE.LTE },
  delete_flg: { name: 'delete_flg', type: SEARCH_TYPE.EQ },
  admin_check_flg: { name: 'admin_check_flg', type: SEARCH_TYPE.EQ },
  system_update_flg: { name: 'system_update_flg', type: SEARCH_TYPE.EQ },
  official_name_flg: { name: 'official_name_flg', type: SEARCH_TYPE.EQ },
  valid_flg: { name: 'valid_flg', type: SEARCH_TYPE.EQ },
};

export const useSearchTitleCodeHistories = () => {
  const [titleCodeHistories, setTitleCodeHistories] = useState<Country[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [nextTokens, setNextTokens] = useState<string[]>([]);
  const [sort, setSort] = useState<{}>(defaultSort);
  const [firstFetch, setFirstFetch] = useState(true);
  const { pageSize, setPageSize } = usePageSize();
  const [condition, setCondition] = useState<TitleCodeHistorySearch>(SEARCH_TITLE_CODE_HISTORY_FIELDS_DEFAULT_VALUES);

  const fetchTitleCodeHistories = async ({
    newCondition,
    nextToken,
    newLimit,
    newSort,
  }: {
    newCondition?: TitleCodeHistorySearch;
    nextToken?: string;
    newLimit?: number;
    newSort?: {};
  }) => {
    try {
      setLoading(true);
      const queries = Object.entries(newCondition ?? condition)
        .map(([key, value]) => {
          if (value === 'ALL' && key === 'admin_check_flg') return;
          if (value === 'ALL' && key === 'system_update_flg') return;
          if (value === 'ALL' && key === 'official_name_flg') return;
          if (value === 'ALL' && key === 'valid_flg') return;
          if (value === 'ALL' && key === 'delete_flg') return;
          return {
            name: dataToFilterObj[key].name,
            value: value,
            type: dataToFilterObj[key].type,
          };
        })
        .filter(Boolean);
      const filter = generateSearchFilter(queries);

      const defaultFilter = { admin_check_flg: { eq: true } };

      const searchCondition = firstFetch
        ? {
            filter: defaultFilter,
            sort: newSort ?? sort,
            limit: newLimit ?? pageSize,
            nextToken: nextToken ?? null,
          }
        : {
            ...filter,
            sort: newSort ?? sort,
            limit: newLimit ?? pageSize,
            nextToken: nextToken ?? null,
          };

      const models: any = await API.graphql({ query: searchTitleCodeHistories, variables: searchCondition });

      setTotal(models.data.searchTitleCodeHistories.total);
      if (newCondition || newLimit || newSort) {
        setNextTokens([models.data.searchTitleCodeHistories.nextToken]);
      } else {
        setNextTokens((prev) => Array.from(new Set([...prev, models.data.searchTitleCodeHistories.nextToken])));
      }
      newCondition && setCondition({ ...newCondition });
      setTitleCodeHistories([...models.data.searchTitleCodeHistories.items]);
      setFirstFetch(false);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePageSize = (newLimit: number) => {
    setPageSize(newLimit);
    setPage(0);
    fetchTitleCodeHistories({ newLimit });
  };

  const onChangeSort = (sortModel: any) => {
    setPage(0);
    if (!sortModel.length) {
      fetchTitleCodeHistories({ newSort: defaultSort });
    } else {
      const newSort = { direction: sortModel[0].sort, field: sortModel[0].field };
      setSort({ ...newSort });
      fetchTitleCodeHistories({ newSort });
    }
  };

  const onChangePage = (newPage: number) => {
    let nextToken = null;
    setPage((currentPage) => {
      if (currentPage < newPage) {
        nextToken = nextTokens[currentPage];
      } else {
        setNextTokens((tokens: string[]) => {
          tokens.pop();
          nextToken = tokens[newPage - 1];
          return [...tokens];
        });
      }
      return newPage;
    });
    fetchTitleCodeHistories({ nextToken });
  };

  return {
    fetchTitleCodeHistories,
    loading,
    total,
    pageSize,
    onChangePageSize,
    titleCodeHistories,
    setTitleCodeHistories,
    onChangePage,
    page,
    onChangeSort,
    condition,
  };
};
