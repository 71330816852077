import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormDialog from '../FormDialog';
import { User, BnamHistory } from '../../models';
import { OPERATION_TYPE_OPTIONS } from '../../consts/common'
import { useEditedColumn } from '../../hooks/useEditedColumnClass';
import { DETAIL_BNAM_HISTORY_FIELDS } from '../../consts/bnamHistory';

type Props = {
  open: boolean;
  row: BnamHistory;
  setOpen: Function;
  setRow: Function;
  user: User;
}

function DetaiBnamHistoryDialog(props: Props) {
  const {
    open,
    row,
    setOpen,
    setRow,
  } = props;
  const {
    control,
    formState: { errors },
  } = useForm();

  const onClose = () => {
    setRow(null);
    setOpen(false);
  };

  const options = (name: string | undefined) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      default:
        return [];
    }
  }

  console.log({ row });
  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="BNAMマスター履歴の詳細"
      doText=""
      cancelText="キャンセル"
      height={450}
      width="md"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 300 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 430,
        }}
        width={700}
      >
        {DETAIL_BNAM_HISTORY_FIELDS.map((__row, idx) => {
          return (
            <React.Fragment key={idx}>
              {(__row.type === 'text' || __row.type === 'textarea') && (
                <FormTextField
                  label={__row.label}
                  field={__row.field}
                  control={control}
                  disabled={__row.disabled}
                  errors={errors}
                  validationRules={[]}
                  value={row[__row.field as keyof Pick<BnamHistory, 'update_reason' | 'updated_user_id'>]}
                  isTextArea={__row.type === 'textarea'}
                  isEdited={useEditedColumn(row, __row.field)}
                />
              )}
              {(__row.type === 'datetime' || __row.type === 'date') && (
                <FormDateTimePicker
                  label={__row.label}
                  defaultValue={row[__row.field as keyof Pick<BnamHistory, 'updatedAt' | 'deleted_at'>]}
                  type={__row.type}
                  disabled={__row.disabled}
                  field={__row.field}
                  control={control}
                  isEdited={useEditedColumn(row, __row.field)}
                />
              )}
              {__row.type === 'select' && __row.optionsName && (
                <FormSelectBox
                  label={__row.label}
                  content={options(__row.optionsName)}
                  defaultValue={row[__row.field as keyof Pick<BnamHistory, 'operation_type'>]}
                  field={__row.field}
                  control={control}
                  disabled={__row.disabled}
                />
              )}
              {__row.type === 'checkbox' && (
                <FormCheckBox
                  field={__row.field}
                  label={__row.label}
                  control={control}
                  defaultValue={row[__row.field as keyof Pick<BnamHistory, 'delete_flg'>]}
                  disabled={__row.disabled}
                />
              )}
            </React.Fragment>
          )
        })}
      </Box>
    </FormDialog>
  );
}

export default DetaiBnamHistoryDialog;
