import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormSelectBox from '../forms/FormSelectBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import { DELETE_FLG_OPTIONS } from '../../consts/common';
import { SEARCH_RATE_FIELDS, SEARCH_RATE_FIELDS_DEFAULT_VALUES } from '../../consts/rate';
import { RateSearch } from '../../types/form/rate';
import { classificationValidateRate } from '../../hooks/classification';
import { rateFields } from '../../types';
import { Currency } from '../../models';

type Props = {
  condition: RateSearch;
  open: boolean;
  setOpen: Function;
  currencies: Currency[]
  fetchRates: Function
}

function SearchRateDialog(props: Props) {
  const { condition, currencies, open, setOpen, fetchRates } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_RATE_FIELDS_DEFAULT_VALUES });

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
    setValue('exchange_rate', '')
  };

  const onSubmit: SubmitHandler<RateSearch> = async (newCondition) => {
    await fetchRates({ newCondition })
    setOpen(false);
  };

  const onReset = () => {
    reset();
    setValue('exchange_rate', '')
  };

  const options = (name: string) => {
    switch (name) {
      case 'currency':
        return currencies;
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <SearchDialog
      open={open}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
      height={420}
      width="xl"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 350 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: '-webkit-fill-available',
        }}
        width={1100}
      >
        {SEARCH_RATE_FIELDS.map((row, idx) => (
          <React.Fragment key={idx}>
            {row.type === 'select' && row?.optionsName && (
              <FormSelectBox
                label={row.label}
                content={options(row.optionsName)}
                field={row.field}
                control={control}
                selectKey={row.optionsName === 'currency' ? 'code_three_char' : 'name'}
                placeholder={row.placeholder}
                isBlank={row.isBlank}
              />
            )}
            {(row.type === 'datetime' || row.type === 'date') && row?.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={`${row.field}_${row.inputs[0].field}`}
                  toLabel={row.inputs[1].label}
                  toField={`${row.field}_${row.inputs[1].field}`}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {row.type === 'text' && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateRate(row.field as rateFields, 'search')}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </SearchDialog >
  );
}

export default SearchRateDialog;
