import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Backdrop, Button } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotificationContext } from '../../contexts/NotificationContext';

import PrimaryButton from '../PrimaryButton';
import FormSelectBox from '../forms/FormSelectBox';
import FormTextField from '../forms/FormTextField';
import FormDateTimePicker from '../forms/FormDateTimePicker';
import FormCheckBox from '../forms/FormCheckBox';
import FormArrayTextField from '../forms/FormArrayTextField';
import FormDialog from '../FormDialog';
import ConfirmDialog from '../ConfirmDialog';
import EditConfirmDialog from '../EditConfirmDialog';
import FormSelectChip2 from '../forms/FormSelectChip2';
import NewProductTcdDialog from '../productTcds/NewProductTcdDialog';

import { BANASUKE_SEQ_ID } from '../../consts/common';
import { classificationValidateTitle, classificationValidateGroupCompany } from '../../hooks/classification';
import { getGroupCompany, searchProductTcds, getProductTcd, searchGroupCompanies } from '../../graphql/queries';
import { deleteTagTitle, updateGroupCompany, updateProductTcd } from '../../graphql/mutations';
import { useRestore } from '../../hooks/useRestore';
import { sleep } from '../../utils/fetchData';
import { ConfirmSubmitGroupCompany } from '../../types/form/GroupCompany';
import { useConvertingArrayTextForConfirm } from '../../hooks/useConvertingArrayTextForConfirm';
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import {
  GetGroupCompanyQuery,
  UpdateGroupCompanyInput,
  SearchProductTcdsQuery,
  UpdateProductTcdInput,
  GetProductTcdQuery,
  SearchableProductTcdFilterInput,
  SyncProductTcdsQueryVariables,
  SyncProductTcdsQuery,
  SearchProductTcdsQueryVariables,
  GetGroupCompanyQueryVariables,
} from '../../API';
import { createSearchParams, Link } from 'react-router-dom';
import { duplicateDetection } from '../../utils/duplicateDetection';

import type {
  GroupCompany,
  DataSource,
  ProductTcd,
  Platform,
  ProductClass,
  ProductType,
  Currency,
  Genre,
  User,
  Country,
} from '../../models';
import {
  BRAND_PORTFOLIO_LIST,
  COMPANY_ATTRIBUTE_LIST,
  CONSOLIDATION_LIST,
  UPDATE_GROUP_COMPANY_FIELDS,
  EQUITY_METHOD_APPLICATION_LIST,
  MANAGEMENT_BUSINESS_LIST,
  PUBLIC_OR_PRIVATE_LIST,
  UNIT_CLASS_LIST,
  VALIDATE_DUPLICATE_GROUP_COMPANY_FIELDS,
} from '../../consts/groupCompany';
import FormAutocomplete from '../forms/FormAutocomplete';
import { generateSearchFilter, SEARCH_TYPE } from '../../utils/search';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

type Props = {
  open: boolean;
  selectedRow: GroupCompany;
  setOpen: Function;
  user: User;
  fetchGroupCompanies: Function;
  groupCompanies: GroupCompany[];
  countries: Country[];
  currencies: Currency[];
};

function EditGroupCompanyDialog(props: Props) {
  const { open, selectedRow, setOpen, fetchGroupCompanies, user, groupCompanies, countries, currencies } = props;
  console.log({ selectedRow });
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isOpenEditConfirm, setIsOpenEditConfirm] = useState(false);
  const [confirmData, setConfirmData] = useState<ConfirmSubmitGroupCompany>();
  const [record, setRecord] = useState<ConfirmSubmitGroupCompany>();
  const [alertMsg, setAlertMsg] = useState('');
  const [currentGroupCompanies, setCurrentGGroupCompanies] = useState<GroupCompany[]>(groupCompanies);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const { addNotification } = useContext(NotificationContext);
  const { loading, checkAndRestore } = useRestore();
  const onClose = () => setOpen(false);

  const onConfirmSubmit: SubmitHandler<ConfirmSubmitGroupCompany> = async (data) => {
    // データをPOSTできるように整形
    const submitData: ConfirmSubmitGroupCompany = {
      ...data,
      // @ts-expect-error
      holding_company_1: data.holding_company_1?.group_company_code ?? null,
      // @ts-expect-error
      holding_company_2: data.holding_company_2?.group_company_code ?? null,
      // @ts-expect-error
      holding_company_3: data.holding_company_3?.group_company_code ?? null,
      // @ts-expect-error
      holding_company_4: data.holding_company_4?.group_company_code ?? null,
      // @ts-expect-error
      holding_company_5: data.holding_company_5?.group_company_code ?? null,
      accounting_period: stringToNumber(data.accounting_period ?? null),
      investment_ratio_1: stringToNumber(data.investment_ratio_1 ?? null),
      investment_ratio_2: stringToNumber(data.investment_ratio_2 ?? null),
      investment_ratio_3: stringToNumber(data.investment_ratio_3 ?? null),
      investment_ratio_4: stringToNumber(data.investment_ratio_4 ?? null),
      investment_ratio_5: stringToNumber(data.investment_ratio_5 ?? null),
      capital: data.capital,
      number_of_employees: stringToNumber(data.number_of_employees ?? null),
      total_investment_ratio: stringToNumber(data.total_investment_ratio ?? null),
      issued_shares_total: stringToNumber(data.issued_shares_total ?? null),
      treasury_stock: stringToNumber(data.treasury_stock ?? null),
    };
    // 重複チェック
    const duplicationResults = await duplicateDetection(
      'GROUP_COMPANY',
      VALIDATE_DUPLICATE_GROUP_COMPANY_FIELDS,
      submitData,
      clearErrors,
      setError,
      selectedRow.id
    );

    if (duplicationResults.every((result) => !result)) {
      setConfirmData({ ...submitData });
      setRecord({ ...submitData });
      console.log({ confirmData, selectedRow, submitData, data });
      setIsOpenEditConfirm(true);
    }
  };

  const stringToNumber = (text: string | null | number | undefined) => {
    if (typeof text !== 'number' && !text) return null;
    return Number(text);
  };

  const onSubmit = async () => {
    try {
      const model = await API.graphql<GraphQLQuery<GetGroupCompanyQuery>>({
        query: getGroupCompany,
        variables: { id: selectedRow.id },
      });
      const { _version } = model.data.getGroupCompany;

      // updatedAtはDB側で更新するので含めない
      const { updatedAt, ...data } = record;
      const ip_address = data['ip_address'].map((v) => v.value);
      // @ts-expect-error
      const formData: UpdateGroupCompanyInput = {
        ...data,
        ip_address,
        id: selectedRow.id,
        updated_user_id: user.id,
        _version,
      };
      await API.graphql({ query: updateGroupCompany, variables: { input: formData } });
      setIsOpenEditConfirm(false);
      onClose();
      addNotification({
        type: 'success',
        message: 'グループ会社の編集に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchGroupCompanies({});
      reset();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'グループ会社の編集に失敗しました',
      });
      console.log(e);
    }
  };

  const onDelete: SubmitHandler<ConfirmSubmitGroupCompany> = async (data) => {
    await deleteGroupCompany(data);
    setIsDeleteConfirm(false);
    onClose();
  };

  const deleteGroupCompany = async (data: ConfirmSubmitGroupCompany) => {
    try {
      const model = await API.graphql<GraphQLQuery<GetGroupCompanyQuery>>({
        query: getGroupCompany,
        variables: { id: selectedRow.id },
      });
      const GroupCompany = model.data.getGroupCompany;
      const formData: UpdateGroupCompanyInput = {
        id: selectedRow.id,
        update_reason: data.update_reason,
        _version: GroupCompany._version,
        delete_flg: true,
        updated_user_id: user.id,
      };
      await API.graphql({ query: updateGroupCompany, variables: { input: formData } });
      onClose();
      addNotification({
        type: 'success',
        message: 'グループ会社の削除に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchGroupCompanies({});
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'グループ会社の削除に失敗しました',
      });
      console.log(e);
    }
  };

  const checkRelation = () => {
    setAlertMsg(`このグループ会社を削除しても${'\n'}よろしいでしょうか？`);
    setIsDeleteConfirm(true);
  };

  const restoreConfirm = () => {
    setAlertMsg(`このグループ会社を復元しても${'\n'}よろしいでしょうか？`);
    setIsDeleteConfirm(true);
  };

  const onRestore = async () => {
    try {
      await checkAndRestore('groupCompany', selectedRow);
      onClose();
      addNotification({
        type: 'success',
        message: 'グループ会社の復元に成功しました',
      });
      await sleep(3000); //OpenSearchがアップデートされるまでスリープ
      await fetchGroupCompanies({});
      onClose();
    } catch (e) {
      addNotification({
        type: 'error',
        message: 'グループ会社の復元に失敗しました',
      });
      console.log(e);
    }
    setIsDeleteConfirm(false);
  };

  const options = (name: string) => {
    switch (name) {
      case 'company_attribute':
        return COMPANY_ATTRIBUTE_LIST;
      case 'consolidation':
        return CONSOLIDATION_LIST;
      case 'equity_method_application':
        return EQUITY_METHOD_APPLICATION_LIST;
      case 'unit_class_1':
      case 'unit_class_2':
        return UNIT_CLASS_LIST;
      case 'management_business_1':
      case 'management_business_2':
        return MANAGEMENT_BUSINESS_LIST;
      case 'country_id':
        return countries;
      case 'public_or_private':
        return PUBLIC_OR_PRIVATE_LIST;
      case 'brand_portfolio':
        return BRAND_PORTFOLIO_LIST;
      case 'holding_company_1':
      case 'holding_company_2':
      case 'holding_company_3':
      case 'holding_company_4':
      case 'holding_company_5':
        return currentGroupCompanies;
      case 'currency_id':
        return currencies;
      default:
        return [];
    }
  };

  const formatArrayText = useCallback(
    (fieldName: string, contents: string[]) => {
      contents?.length
        ? setValue(
            fieldName,
            contents.map((content) => ({ value: content }))
          )
        : setValue(fieldName, [{ value: '' }]);
    },
    [setValue]
  );

  useEffect(() => {
    formatArrayText('ip_address', selectedRow.ip_address);
  }, [selectedRow.ip_address, formatArrayText]);

  /** 最大カラム数 */
  const maxColumnOrders = Math.max(...UPDATE_GROUP_COMPANY_FIELDS.map((field) => field.column));

  const onGroupCompaniesSearch = async (field: string) => {
    const value = (document.getElementById(field) as HTMLInputElement).value;

    if (!value) return;

    const filter = generateSearchFilter([{ name: 'group_company_code', value, type: SEARCH_TYPE.WILDCARD }]);
    try {
      const models: any = await API.graphql({
        query: searchGroupCompanies,
        variables: { ...filter, sort: { direction: 'desc', field: 'seq_id' }, limit: 500 },
      });
      const groupCompanies = [...models.data.searchGroupCompanies.items] as GroupCompany[];
      let uniqueGroupCompanyCode = [];
      const uniqueGroupCompanies = groupCompanies.reduce((current, groupCompany) => {
        if (!uniqueGroupCompanyCode.includes(groupCompany.group_company_code)) {
          uniqueGroupCompanyCode.push(groupCompany.group_company_code);
          return [...current, groupCompany];
        }
        return current;
      }, []);
      setCurrentGGroupCompanies([...uniqueGroupCompanies]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FormDialog
      open={open}
      onCancel={onClose}
      title="グループ会社の編集"
      doText="確認"
      cancelText="キャンセル"
      onSubmit={!selectedRow.delete_flg && handleSubmit(onConfirmSubmit)}
      onConfirm={selectedRow.delete_flg ? restoreConfirm : checkRelation}
      height={800}
      width="xl"
      isRestore={selectedRow.delete_flg}
    >
      <Box display={'flex'}>
        {[...Array(maxColumnOrders)].map((_, i) => {
          return (
            <Box
              sx={{
                '& > :not(style)': { my: 1, mx: 1, width: 300 },
                display: 'flex',
                flexFlow: 'column wrap',
                height: 'inherit',
              }}
              width={330}
              key={i}
            >
              {UPDATE_GROUP_COMPANY_FIELDS.map((row) => {
                return (
                  row.column === i + 1 && (
                    <React.Fragment key={row.field}>
                      {row.type === 'text' && (
                        <FormTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          disabled={selectedRow.delete_flg || row.field === 'updated_user_id' || row.field === 'seq_id'}
                          errors={errors}
                          validationRules={classificationValidateGroupCompany(row.field)}
                          value={selectedRow[row.field] ? selectedRow[row.field].toString() : undefined}
                          required={selectedRow.delete_flg ? false : row.required}
                        />
                      )}
                      {(row.type === 'datetime' || row.type === 'date') && (
                        <FormDateTimePicker
                          label={row.label}
                          defaultValue={selectedRow[row.field]?.toString()}
                          type={row.type}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                          required={row.required}
                          errors={errors}
                          disabled={selectedRow.delete_flg}
                          validationRules={classificationValidateGroupCompany(row.field)}
                        />
                      )}
                      {row.type === 'select' && (
                        <FormSelectBox
                          label={row.label}
                          content={options(row.field)}
                          disabled={selectedRow.delete_flg}
                          defaultValue={selectedRow[row.field]?.toString()}
                          field={row.field}
                          control={control}
                          placeholder={row.placeholder}
                        />
                      )}
                      {row.type === 'checkbox' && (
                        <FormCheckBox
                          field={row.field}
                          label={row.label}
                          control={control}
                          defaultValue={!!selectedRow[row.field]}
                          disabled={selectedRow.delete_flg}
                        />
                      )}
                      {row.type === 'arrayText' && (
                        <FormArrayTextField
                          label={row.label}
                          placeholder={row.placeholder}
                          field={row.field}
                          control={control}
                          errors={errors}
                          validationRules={classificationValidateGroupCompany(row.field)}
                          disabled={selectedRow.delete_flg}
                        />
                      )}
                      {row.type === 'autoComplete' && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            '& > div': { width: '70%' },
                            '& > button': { marginTop: '10px' },
                          }}
                        >
                          <FormAutocomplete
                            id={row.field}
                            label={row.label}
                            placeholder={row.placeholder}
                            field={row.field}
                            control={control}
                            errors={errors}
                            validationRules={[]}
                            required={row.required}
                            options={options(row.field)}
                            // @ts-ignore
                            defaultValue={groupCompanies.find(
                              (groupCompany) => selectedRow[row.field] === groupCompany.group_company_code
                            )}
                            freeSolo
                            includeSearchEnglish
                            isGroupCompany
                          />
                          <PrimaryButton onClick={() => onGroupCompaniesSearch(row.field)}>検索</PrimaryButton>
                        </Box>
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Box>
          );
        })}
      </Box>

      {isDeleteConfirm && (
        <ConfirmDialog
          message={alertMsg}
          open={isDeleteConfirm}
          onCancel={() => setIsDeleteConfirm(false)}
          onContinue={selectedRow.delete_flg ? onRestore : handleSubmit(onDelete)}
          updateReason={
            selectedRow.delete_flg ? (
              <></>
            ) : (
              <Box px={3} mb={1}>
                <FormTextField
                  label="更新理由"
                  placeholder="更新理由を入力"
                  value={selectedRow.update_reason?.toString() || ''}
                  field="update_reason"
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateGroupCompany('update_reason')}
                />
              </Box>
            )
          }
        />
      )}
      {isOpenEditConfirm && (
        <EditConfirmDialog
          fields={UPDATE_GROUP_COMPANY_FIELDS}
          onClose={() => setIsOpenEditConfirm(false)}
          onSubmit={onSubmit}
          open={isOpenEditConfirm}
          record={confirmData}
          schemaName="グループ会社マスタ"
          currencies={currencies}
          countries={countries}
          groupCompanies={groupCompanies}
          beforeChangeValueData={selectedRow}
        />
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormDialog>
  );
}

export default EditGroupCompanyDialog;
