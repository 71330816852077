import React, { useCallback, useState, useEffect, useContext } from 'react';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { format, parseISO } from 'date-fns';
import NewBspDialog from '../components/bsp/NewBspDialog';
import EditBspDialog from '../components/bsp/EditBspDialog';
import SearchBspDialog from '../components/bsp/SearchBspDialog';
import PrimaryButton from '../components/PrimaryButton';
import { Bsp } from '../models';
import { AuthContext } from '../contexts/AuthContext';
import { useSearchBsps } from '../hooks/useSearchBsps';
import { filterColumnsForGeneralRole } from '../utils/filterColumns'
import ExportButton from '../components/ExportButton';
import ImportButton from '../components/ImportButton';
import { theme } from '../assets/theme/options';



const columns: GridColDef[] = [
  { field: 'seq_id', headerName: 'BSPマスターID', width: 150 },
  {
    field: 'character_name',
    headerName: 'キャラクター名',
    width: 260,
  },
  { field: 'character_code', headerName: 'キャラクターコード', width: 150 },
  { field: 'character_series_name', headerName: 'キャラクターシリーズ名称', width: 260 },
  {
    field: 'character_series_code',
    headerName: 'キャラクターシリーズコード',
    width: 210,
  },
  {
    field: 'system_update_flg',
    headerName: '自動更新フラグ',
    width: 150,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
  {
    field: 'delete_flg',
    headerName: '削除済データ',
    width: 210,
    renderCell: (params: GridCellParams) => {
      return params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    },
  },
  {
    field: 'updatedAt',
    headerName: '最終更新日時',
    width: 210,
    valueFormatter: (params: any) => {
      return format(parseISO(params.value), 'yyyy/MM/dd HH:mm');
    },
  },
];

function BspList() {
  const [openNew, setOpenNew] = useState(false);
  const [row, setRow] = useState<Bsp | null>(null)
  const [openEdit, setOpenEdit] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { user, roles } = useContext(AuthContext);
  const { fetchBsps, loading, total, pageSize, onChangePageSize, bsps, setBsps, onChangePage, condition, page, onChangeSort, searchFilter } = useSearchBsps()
  const [isUploaded, setIsUploaded] = useState(false)

  const roleTypes = roles?.map((role) => role.role?.label);

  useEffect(() => {
    fetchBsps({})
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isUploaded) return
    fetchBsps({})
    setIsUploaded(false)
    // eslint-disable-next-line
  }, [isUploaded])

  // 一般ユーザの時は削除済データのカラムを非表示にする
  const removeColumns = ["delete_flg"]
  const filterdColumns = filterColumnsForGeneralRole(roleTypes, columns, removeColumns)

  const doOpenNew = useCallback(() => {
    setOpenNew(true);
  }, []);

  const doOpenEdit = (params: any) => {
    setRow(params.row);
    setOpenEdit(true);
  };

  const doOpenSearch = useCallback(() => {
    setOpenSearch(true)
  }, [])


  if (!roleTypes) return <></>;
  return (
    <Box sx={{ p: 1 }}>
      {/* <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box> */}



      <Box my={theme.spacing(2)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, width: 900 }}>
          <PrimaryButton onClick={doOpenNew}>新規作成</PrimaryButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 800 }}>
            <ExportButton
              condition={searchFilter}
              tableName="BSP"
              type='SEARCH'
            />
            <ImportButton tableName="BSP" setIsUploaded={setIsUploaded} />
          </Box>
        </Box>
        <PrimaryButton onClick={doOpenSearch}>検索</PrimaryButton>
      </Box>

      <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
        <DataGridPro
          rows={bsps}
          columns={filterdColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[50, 100, 150]}
          disableSelectionOnClick
          onRowClick={doOpenEdit}
          pagination
          sx={{
            '& .MuiDataGridPro-cell:focus-within': {
              outline: 'none',
            },
          }}
          // サーバーページネーション関連の処理
          page={page}
          onPageSizeChange={(num) => onChangePageSize(num)}
          onPageChange={onChangePage}
          onSortModelChange={onChangeSort}
          rowCount={total || 0}
          loading={loading}
          hideFooterPagination={loading}
          paginationMode="server"
          sortingMode="server"
        />
      </div>
      {openNew && user && (
        <NewBspDialog
          open={openNew}
          setOpen={setOpenNew}
          user={user}
          fetchBsps={fetchBsps}
        />

      )}
      {row && user && (
        <EditBspDialog
          open={openEdit}
          row={row}
          setOpen={setOpenEdit}
          setRow={setRow}
          user={user}
          fetchBsps={fetchBsps}
        />
      )
      }
      <SearchBspDialog
        condition={condition}
        isAdmin={roleTypes.includes('ADMIN')}
        open={openSearch}
        setOpen={setOpenSearch}
        fetchBsps={fetchBsps}
      />
    </Box>
  );
}

export default BspList;
