import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormTextField from '../forms/FormTextField';
import FormSelectBox from '../forms/FormSelectBox';
import FormDateTimeRangePicker from '../forms/FormDateTimeRangePicker';
import SearchDialog from '../SearchDialog';
import {
  OPERATION_TYPE_OPTIONS,
  SYSTEM_UPDATE_OPTIONS,
  DELETE_FLG_OPTIONS,
  ADMIN_CHECK_FLG_OPTIONS
} from '../../consts/common'
import {
  SEARCH_BNAM_HISTORY_FIELDS_DEFAULT_VALUES,
  SEARCH_BNAM_HISTORY_FIELDS
} from '../../consts/bnamHistory';
import { BnamHistoryFields } from '../../types';
import { classificationValidateBnamHistory } from '../../hooks/classification';
import { BnamHistorySearch } from '../../types/form/bnamHistory';

type Props = {
  condition: BnamHistorySearch;
  isAdmin: boolean | undefined
  open: boolean;
  setOpen: Function;
  fetchBnamHistories: Function;
}

function SearchBnamHistoryDialog(props: Props) {
  const {
    condition,
    open,
    setOpen,
    fetchBnamHistories
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({ defaultValues: SEARCH_BNAM_HISTORY_FIELDS_DEFAULT_VALUES });

  useEffect(() => {
    open && Object.entries(condition).forEach((cond: any) => setValue(cond[0], cond[1]))
  }, [open, condition, setValue]);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit: SubmitHandler<BnamHistorySearch> = async (newCondition) => {
    try {
      await fetchBnamHistories({ newCondition })
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  }

  const options = (name: string) => {
    switch (name) {
      case 'operation_type':
        return OPERATION_TYPE_OPTIONS;
      case 'system_update_flg':
        return SYSTEM_UPDATE_OPTIONS;
      case 'delete_flg':
        return DELETE_FLG_OPTIONS;
      case 'admin_check_flg':
        return ADMIN_CHECK_FLG_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <SearchDialog
      open={open}
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      height={400}
      width="md"
    >
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: 400 },
          display: 'flex',
          flexFlow: 'column wrap',
          height: 330,
        }}
        width={870}
      >
        {SEARCH_BNAM_HISTORY_FIELDS.map((row, idx) => (
          <Box key={idx}>
            {row.type === 'select' && row.optionsName && (
              <FormSelectBox
                label={row.label}
                content={options(row.optionsName)}
                field={row.field}
                control={control}
                placeholder={row.placeholder}
                isBlank={row.isBlank}
              />
            )}
            {(row.type === 'date' || row.type === 'datetime') && row.inputs && (
              <Box sx={{ display: 'flex' }}>
                <FormDateTimeRangePicker
                  fromLabel={row.inputs[0].label}
                  fromField={`${row.field}_${row.inputs[0].field}`}
                  toLabel={row.inputs[1].label}
                  toField={`${row.field}_${row.inputs[1].field}`}
                  type={row.type}
                  placeholder={row.placeholder}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              </Box>
            )}
            {((row.type === 'text' || row.type === 'textarea') && !row.inputs) && (
              <FormTextField
                label={row.label}
                placeholder={row.placeholder}
                field={row.field}
                control={control}
                errors={errors}
                validationRules={classificationValidateBnamHistory(row.field as BnamHistoryFields)}
              />
            )}
            {(row.type === 'text' && row.inputs) && (
              <Box sx={{ display: 'flex' }}>
                <FormTextField
                  label={row.inputs[0].label}
                  placeholder={row.placeholder}
                  field={`${row.field}_${row.inputs[0].field}`}
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateBnamHistory(row.field as BnamHistoryFields)}
                />
                <Box pt={3} px={2}>〜</Box>
                <FormTextField
                  label={row.inputs[1].label}
                  placeholder={row.placeholder}
                  field={`${row.field}_${row.inputs[1].field}`}
                  control={control}
                  errors={errors}
                  validationRules={classificationValidateBnamHistory(row.field as BnamHistoryFields)}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </SearchDialog>
  );
}

export default SearchBnamHistoryDialog;
