import { incrementSequence, createSequence } from '../graphql/mutations';
import { getSequence } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
const API = generateClient();

export function useAutoIncrement(sequence_prefix_id: string) {
  const getNextId = async () => {
    const res: any = await API.graphql({
      query: getSequence,
      variables: {
        id: sequence_prefix_id,
      },
    });
    const seq = res.data.getSequence;
    let nextId: string | undefined;
    if (!seq) {
      const res: any = await API.graphql({
        query: createSequence,
        variables: {
          input: {
            id: sequence_prefix_id,
            table_label: sequence_prefix_id,
            current_number: 1,
          },
        },
      });
      const newSeq = res.data.createSequence;
      nextId = sequence_prefix_id + ('' + newSeq.current_number).padStart(10, '0');
    } else {
      const increment_seq = {
        id: seq.id,
        _version: seq._version,
      };
      const newseq: any = await API.graphql({ query: incrementSequence, variables: { input: increment_seq } });
      nextId = sequence_prefix_id + ('' + newseq.data.incrementSequence.current_number).padStart(10, '0');
    }
    return nextId;
  };
  return getNextId;
}
