import { DATA_SOURCES_SEQ_CODE, MASTER_SYSTEM_SEQ_ID, BANASUKE_SEQ_ID, CE_LINK_SEQ_ID, MULCHI_SEQ_ID } from "../consts/common";
import { DataSource } from "../models";

export const useRenameDataSource = (dataSources: DataSource[]) => {
  const sortedDataSources = [...dataSources].sort((a, b) => parseInt(a.seq_id.replace(DATA_SOURCES_SEQ_CODE, "")) - parseInt(b.seq_id.replace("DTS", "")));
  return sortedDataSources.map((dataSource: DataSource) => {
    if (dataSource.seq_id === MASTER_SYSTEM_SEQ_ID) {
      return { ...dataSource, name: 'タイトル新規作成' };
    } else if (dataSource.seq_id === BANASUKE_SEQ_ID) {
      return { ...dataSource, name: 'アプリタイトル/NE' };
    } else if (dataSource.seq_id === CE_LINK_SEQ_ID) {
      return { ...dataSource, name: '家庭用タイトル/CE' };
    } else if (dataSource.seq_id === MULCHI_SEQ_ID) {
      return { ...dataSource, name: 'マルチタイトル/NE&CE' }
    }
  });
};